import { MouseEvent } from 'react';
import { DocumentViewerObjectResizerWrapper, DocumentViewerPageObjectResizeDirection } from '../../../document-viewer';

export const FlowEditorFieldResizerButton = ({ direction }: { direction: DocumentViewerPageObjectResizeDirection }) => (
    <DocumentViewerObjectResizerWrapper direction={direction}>
        <button
            type={'button'}
            className={`c-flow-editor-field-object__resize-btn c-flow-editor-field-object__resize-btn--${direction}`}
        />
    </DocumentViewerObjectResizerWrapper>
);
export const FlowEditorFieldDeleteButton = ({ onClick: onClickProp }: { onClick: () => void; }) => {

    const onClick = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        onClickProp();
    };

    return (
        <button
            data-no-action={''}
            type={'button'}
            onClick={onClick}
            className={'c-flow-editor-field-object__delete-btn'}
        >
            <i className='fa-solid fa-xmark'></i>
        </button>
    );
};
