import { NdsIconFont } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types/designsystem.enums';
import classNames from 'classnames';
import { HTMLAttributes, PropsWithChildren } from 'react';
import { useTranslation } from '~contexts/i18n';
import { LanguageDataLabel, PropsWithClassNames } from '~models';
import Typography, { TypographyToken } from '../typography';
import './error.scss';

export interface ErrorProps extends PropsWithClassNames<HTMLAttributes<HTMLDivElement>> {
    /** if simple string has to be passed it can be done as param instead children to make code a bit cleaner */
    text?: string;
    i18nKey?: LanguageDataLabel<'error'>,
    icon?: string | false;
    size?: keyof typeof TokenMap;
}

const TokenMap = {
    'xs': TypographyToken.MobileBylineXs,
    'sm': TypographyToken.MobileBylineSm,
};

const MediumTokenMap = {
    'xs': TypographyToken.DesktopBylineXs,
    'sm': TypographyToken.DesktopBylineSm,
};

function Error({
    i18nKey,
    text,
    icon = 'fa-solid-exclamation-circle',
    size = 'xs',
    className,
    children,
    ...props
}: PropsWithChildren<ErrorProps>) {
    const { t } = useTranslation('error');

    return (
        <div className={classNames('c-error', className)} {...props}>
            {icon && <NdsIconFont fontName={icon} size={SizesEnums.MEDIUM} className={'c-error__icon'} />}
            <Typography
                tagName={'div'}
                token={TokenMap[size]}
                mediumScreenToken={MediumTokenMap[size]}
            >
                {i18nKey ? t(i18nKey) : <>{text}{children}</>}
            </Typography>
        </div>
    );
}

export default Error;