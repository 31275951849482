import { useCallback } from 'react';
import { DocumentViewerPageModel, DocumentViewerPagePosition } from '~components/document-viewer';
import { Offset } from '~models';

export const useDocumentViewerGetPageFromPosition = (
    pagePositions: DocumentViewerPagePosition[],
    pages: DocumentViewerPageModel[],
    scale: number,
    justifyPages: boolean,
) => {
    return useCallback((position: Offset, usePrevIfNotFound?: boolean) => {
        if (!pagePositions.length) {
            return null;
        }
        let pagePos: DocumentViewerPagePosition | undefined = undefined;

        const first = pagePositions[0];
        const last = pagePositions[pagePositions.length - 1];

        if (position.top < first.offset.top) {
            pagePos = { ...first };
        } else if (position.top > last.offset.top + last.size.height) {
            pagePos = { ...last };
        } else {
            for (const page of pagePositions) {
                if (position.top >= page.offset.top && position.top < (page.offset.top + page.size.height)) {
                    pagePos = page;
                    break;
                }
            }
            if (!pagePos && usePrevIfNotFound) {
                for (const page of pagePositions) {
                    if (position.top >= page.offset.top) {
                        pagePos = page;
                    } else {
                        break;
                    }
                }
            }
        }

        if (!pagePos) {
            return null;
        }
        const page = pages[pagePos.absolutePageIndex];

        const onPagePos = {
            top: (position.top - pagePos.offset.top) / scale / (justifyPages ? page.scale : 1),
            left: (position.left - pagePos.offset.left) / scale / (justifyPages ? page.scale : 1),
        };

        return {
            originalSize: { ...page.originalSize },
            size: { ...pagePos.size },
            offset: { ...pagePos.offset },
            onPagePos: {
                top: Math.min(Math.max(onPagePos.top, 0), page.originalSize.height),
                left: Math.min(Math.max(onPagePos.left, 0), page.originalSize.width),
            },
            scale: page.scale,
            documentId: page.documentId,
            absPageIndex: pagePos.absolutePageIndex,
            documentIndex: page.documentIndex,
            pageIndex: page.pageIndex,
        };
    }, [pagePositions, pages, scale, justifyPages]);
};
