export enum Permission {
    AccessDocumentPortal = 'access-document-portal',
    AccessDocumentPortal_ActionChangeKeyPairPerSigningField = 'access-document-portal.action-change-keypair-per-signing-field',
    AccessSettings_ActionManageServer = 'access-settings.action-manage-server',
    AccessSettings_ActionManageTenant = 'access-settings.action-manage-tenant',
    AccessSignerPortal = 'access-signatair-portal',
    AccessSignerPortal_ActionBulkSignDocument = 'access-signatair-portal.action-bulksign-document',
    AccessTemplateGroupsPortal = 'access-templategroups-portal',
    AccessTemplatesPortal = 'access-templates-portal',
    AccessUserManagementPortal = 'access-usermanagement-portal',
    AccessUserManagementPortal_ActionCreateDocumentGroup = 'access-usermanagement-portal.action-create-document-group',
    AccessUserManagementPortal_ActionCreateTemplateGroup = 'access-usermanagement-portal.action-create-template-group',
    AccessUserManagementPortal_ActionCreateUser = 'access-usermanagement-portal.action-create-user',
    AccessUserManagementPortal_ActionCreateUserGroup = 'access-usermanagement-portal.action-create-user-group',
    AccessUserManagementPortal_ActionDeleteDocumentGroup = 'access-usermanagement-portal.action-delete-document-group',
    AccessUserManagementPortal_ActionDeleteTemplateGroup = 'access-usermanagement-portal.action-delete-template-group',
    AccessUserManagementPortal_ActionDeleteUser = 'access-usermanagement-portal.action-delete-user',
    AccessUserManagementPortal_ActionDeleteUserGroup = 'access-usermanagement-portal.action-delete-user-group',
    AccessUserManagementPortal_ActionEditDocumentGroup = 'access-usermanagement-portal.action-edit-document-group',
    AccessUserManagementPortal_ActionEditTemplateGroup = 'access-usermanagement-portal.action-edit-template-group',
    AccessUserManagementPortal_ActionEditUser = 'access-usermanagement-portal.action-edit-user',
    AccessUserManagementPortal_ActionEditUserGRoup = 'access-usermanagement-portal.action-edit-user-group',
    AccessUserManagementPortal_ActionViewDocumentGroup = 'access-usermanagement-portal.action-view-document-group',
    AccessUserManagementPortal_ActionViewTemplateGroup = 'access-usermanagement-portal.action-view-template-group',
    AccessUserManagementPortal_ActionViewUser = 'access-usermanagement-portal.action-view-user',
    AccessUserManagementPortal_ActionViewUserGroup = 'access-usermanagement-portal.action-view-user-group',
    General_ActionCreateSharedContact = 'general.action-create-shared-contact',
    General_ActionCreateSharedContactGroup = 'general.action-create-shared-contact-group',
    General_ActionDeleteSharedContact = 'general.action-delete-shared-contact',
    General_ActionDeleteSharedContactGroup = 'general.action-delete-shared-contact-group',
    General_ActionEditSharedContact = 'general.action-edit-shared-contact',
    General_ActionEditSharedContactGroup = 'general.action-edit-shared-contact-group',
    General_ActionViewSharedContact = 'general.action-view-shared-contact',
    General_ActionViewSharedContactGroup = 'general.action-view-shared-contact-group',
    AccessSettings = 'access-settings',
}

export type PermissionCodes = `${Permission}`[];