import { SortDirectionEnum, SortTableData } from '@gonitro/rcl/lib/_types';
import { EnhancedClassName, Guid, I18nLabelObject } from '~models';

export type TableFilterPrimitiveValue = string | number | boolean | undefined;
export type TableFilterArrayValue<T extends TableFilterPrimitiveValue[] = TableFilterPrimitiveValue[]> = T;
export type TableFilterObjectValue<T extends Record<string, NonNullable<TableFilterPrimitiveValue>> = Record<string, NonNullable<TableFilterPrimitiveValue>>> = T;

export type TableFilterValue =
    TableFilterPrimitiveValue
    | TableFilterArrayValue
    | TableFilterObjectValue;

export enum TableFilterValueType {
    Reserved,
    Primitive,
    Array,
    Object,
}

export enum TableFilterValueSubType {
    String,
    Number,
    Boolean,
}

export type TableFiltersDefinition = {
    type: Exclude<TableFilterValueType, TableFilterValueType.Reserved | TableFilterValueType.Object>;
    /** required for storing value in queryParam as it tells what value type is stored and to be able to do proper value parsing */
    subType: TableFilterValueSubType;
    /** access key for value stored in filterValues */
    key: Exclude<string, 'page' | 'show' | 'sort'>;
    /** key that is used to send value to be */
    apiKey?: string;
    apiTransform?: (value: TableFilterPrimitiveValue) => TableFilterPrimitiveValue;
    /** key can be altered with queryParamKey to store value in queryParams */
    queryParamKey?: string;
} | {
    type: TableFilterValueType.Object;
    /** required for storing value in queryParam as it tells what value type is stored and to be able to do proper value parsing */
    subType: TableFilterValueSubType;
    /**
     * key is also a path for specific object value. One nesting level is supported. Use '.' as delimiter
     * e.g. date.before means  filtersValue['date'] = {before: someValue, ...}; Use filterDefinition entries to provide more properties
     */
    key: Exclude<string, 'page' | 'show' | 'sort'>;
    apiTransform?: (value: TableFilterPrimitiveValue) => TableFilterPrimitiveValue;
    /** key that is used to send value to be. If api key includes '.' object is send to be, if not, value is assigned directly as it was primitive */
    apiKey?: string;
    /** for object type value each prop of object has own entry in queryParams so queryParamKey must be defined */
    queryParamKey: string;
} | {
    type?: TableFilterValueType.Reserved;
    subType?: never;
    // page, show and sort are reserved for table needs but their apiKey and queryParamKey can be customized
    key: 'page' | 'show' | 'sort';
    /** key that is used to send value to be */
    apiKey?: string;
    /** key can be altered with queryParamKey to store value in queryParams */
    queryParamKey?: string;
}

export interface FetchTableDataDelegate<TData> {
    (params: {
        page: number;
        sortField: string;
        sortOrder: SortDirectionEnum;
        filterValues: Record<string, TableFilterValue>
        maxQuantity: number;
    }, signal?: AbortSignal): Promise<{
        totalItems: number;
        items: TData[];
        itemsPerPage: number; // needed?
        nextPage: number;
    }>
}

export type TableDataGeneric<TRowIdKey extends number | string = 'id', TProps extends Record<string | number, any> = Record<string | number, any>> =
    TProps
    & (| Record<TRowIdKey, Guid | number>);

export enum TableColumnSpecificWidth {
    Icon = 'icon',
    Button = 'button',
    Selection = 'selection',
    Auto = 'auto',
}

export interface TableColumnDefinition {
    /**
     * Main column identifier.
     * Used as data object access key for cell content if `dataKey` isn't defined and renderColumns[key] not passed to builder.
     * Used for sorting if `sortingKey` isn't used and column is sortable.
     */
    readonly key: string;
    /**
     * Column key used for fetching cell content if it's different from main column key.
     * Ignored if there is specific content in renderColumns passed to builder
     */
    dataKey?: string;
    /**
     * Column key used for sorting (typically for BE) if it's different from main column key.
     * Ignored if column isn't sortable
     */
    sortingKey?: string;

    className?: EnhancedClassName,
    /** i18n label ns and key pair that will be translated and shown in header cell, can be overwritten by label */
    i18nLabel?: I18nLabelObject;
    /** Already translated label shown in header cell, overwrites i18nLabel */
    label?: string;
    /** Is column sortable? */
    sortable?: boolean;
    /** in which direction column should be sorted first, default is ASC */
    initialSortOrder?: SortDirectionEnum.ASC | SortDirectionEnum.DESC;

    width: number | TableColumnSpecificWidth; // percent or specific - TODO

    /** Should content be truncated and ellipsis added if content is too long */
    useEllipsis?: boolean;
}

export interface TableDefinition<TRowIdKey extends string | number> {
    /** key of unique identifier in table data */
    readonly rowIdKey: TRowIdKey,
    /** default number of items to be rendered in table */
    readonly itemsPerPage: number,
    /**
     * if number of rendered items can be changed put a list of values here (sorted).
     * It must include itemsPerPage value too
     */
    readonly itemsPerPageOptions?: number[];
    /** rows can be selected or not */
    readonly selectable?: boolean;
    /** allows to remember selected items on multiple pages */
    readonly multiPageSelectable?: true;
    /** optional classname to add to header row */
    readonly headerClassName?: EnhancedClassName,
    /** optional classname to add to each data row */
    readonly rowClassName?: EnhancedClassName,
    /** if table is sortable it tells which column is sorted by default and in what direction */
    readonly initialSorting?: SortTableData,
    /** definition of columns */
    readonly columns: TableColumnDefinition[];
    /** definition of filters */
    readonly filters?: TableFiltersDefinition[];
    /**
     * data refresh interval (milliseconds)
     * 0 to disable
     * defaults to @ref DEFAULT_REFRESH_INTERVAL_MS=5000
     */
    readonly pollInterval?: number;
}