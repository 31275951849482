import { Checkbox, NdsIconFont } from '@gonitro/rcl';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { TextBox, TextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { SortableItemUIProps } from '@progress/kendo-react-sortable';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import Typography, { TypographyToken } from '~components/typography';
import { ApiErrors, PdfTargetFormat, PdfTargetFormatLabel } from '~constants';
import { useSetFlowState } from '~contexts/flow';
import { FlowActionType } from '~contexts/flow/flow.types';
import { useTranslation } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import { FileUtils, ValidateUtils } from '~lib';
import { FlowInfoDocument } from '~models';
import './flowDocumentsListItem.scss';

export interface FlowDocumentsListItemProps extends SortableItemUIProps {
    dataItem: FlowInfoDocument;
}

function FlowDocumentsListItem({
    dataItem: document,
    forwardRef,
    attributes,
    style,
    isDragged,
    isDragCue,
}: FlowDocumentsListItemProps) {
    const { t } = useTranslation('flow');
    const { t: tError } = useTranslation('error');
    const {
        documentConversionSettings: { allowedTargetFormats, isTargetConversionListVisibleInFrontEnd },
        warningsSettings: { recommendedDocumentSize },
    } = useSettings();
    const setFlowState = useSetFlowState();

    const targetFormatValues = useMemo(() => {
        return allowedTargetFormats.map((el) => {
            return {
                value: el,
                label: PdfTargetFormatLabel[el],
            };
        }).sort((a, b) => a.value < b.value ? -1 : 1);
    }, [allowedTargetFormats]);

    const onDeleteClick = useCallback(() => {
        setFlowState(FlowActionType.DeleteDocument, document.localId);
    }, [document.localId, setFlowState]);

    const onOptionalClick = useCallback((_: any, value: boolean) => {
        setFlowState(FlowActionType.UpdateDocument, {
            localId: document.localId,
            isOptional: value,
        });
    }, [document.localId, setFlowState]);

    const canEditConversionTarget = isTargetConversionListVisibleInFrontEnd && targetFormatValues.length > 1 && document.isNew && !document.templateFile;

    const onTargetFormatChange = useCallback((event: DropDownListChangeEvent) => {
        if (!canEditConversionTarget) {
            return;
        }
        setFlowState(FlowActionType.UpdateDocument, {
            localId: document.localId,
            conversionTargetFormat: parseInt(event.value.value),
        });
    }, [canEditConversionTarget, document.localId, setFlowState]);

    const onDocumentNameChange = useCallback((event: TextBoxChangeEvent) => {
        setFlowState(FlowActionType.UpdateDocument, {
            localId: document.localId,
            documentName: event.value as string,
        });
    }, [document.localId, setFlowState]);

    const { fileSize, fileSizeWarning } = useMemo(() => {
        return {
            fileSize: FileUtils.fileSizeToString(document.size),
            fileSizeWarning: FileUtils.convertFileSize(document.size, FileUtils.FileSizeUnit.MB) > recommendedDocumentSize,
        };
    }, [document.size, recommendedDocumentSize]);

    const { hasError, hasWarning, infoText } = useMemo(() => {
        let infoText: string | undefined;
        let hasError: boolean = false; // TODO - needed when saving package will be implemented
        let hasWarning: boolean = false;

        // TODO add error for failed conversion on save
        if (document.error) {
            hasError = true;

            switch (document.error) {
                case ApiErrors.DocumentPasswordProtected:
                    infoText = tError('document-is-password-protected');
                    break;
                case ApiErrors.DocumentInvalidSourceFileType:
                case ApiErrors.DocumentInvalidTargetFileType:
                case ApiErrors.DocumentPdfPortfolio:
                case ApiErrors.DocumentUploadDoesNotComplyToSpec:
                default:
                    infoText = tError('document-unable-to-convert');
                    break;
            }
        } else if (
            document.documentName.length < ValidateUtils.documentNameMinLength
            || document.documentName.length > ValidateUtils.documentNameMaxLength
        ) {
            hasError = true;
            infoText = t('document-name-length-validation', {
                min: ValidateUtils.documentNameMinLength,
                max: ValidateUtils.documentNameMaxLength,
            });
        } else if (document.isNew && [PdfTargetFormat.PDFA1A, PdfTargetFormat.PDFA2A].includes(document.conversionTargetFormat)) {
            hasWarning = true;
            infoText = t('warning-converting-might-fail-signatures-removed');
        }

        return {
            hasError,
            hasWarning,
            infoText,
        };
    }, [
        document.conversionTargetFormat,
        document.documentName.length,
        document.error,
        document.isNew,
        t,
        tError,
    ]);


    // drag cue is ghost rendered under mouse pointer which looks bad on that list and could be too heavy with all content of item (input, select etc.)
    // so it is just not rendered at all
    return isDragCue
        ? null
        : (
            <div
                className={classNames('c-flow-documents-list-item', { 'c-flow-documents-list-item--dragged': isDragged })}
                ref={forwardRef}
                {...attributes}
                style={style}
            >
                <div className={'c-flow-documents-list-item__info-row'}>
                    <div className={'c-flow-documents-list-item__grip'}>
                        <NdsIconFont
                            fontName={'fa-regular-grip-lines-vertical'}
                        />
                    </div>
                    <div className={'c-flow-documents-list-item__document'}>
                        <TextBox
                            value={document.documentName}
                            onChange={onDocumentNameChange}
                            minLength={ValidateUtils.documentNameMinLength}
                            maxLength={ValidateUtils.documentNameMaxLength}
                            valid={document.documentName.length >= ValidateUtils.documentNameMinLength && document.documentName.length <= ValidateUtils.documentNameMaxLength}
                        />
                    </div>
                    <div className={'c-flow-documents-list-item__size'}>
                        <Typography
                            token={TypographyToken.DesktopTagXs}
                            text={fileSize}
                            className={classNames(
                                'c-flow-documents-list-item__size-content',
                                { 'c-flow-documents-list-item__size-content--warning': fileSizeWarning },
                            )}
                        />
                    </div>
                    {isTargetConversionListVisibleInFrontEnd && (
                        <div className={'c-flow-documents-list-item__conversion'}>
                            <DropDownList
                                data={targetFormatValues}
                                dataItemKey='value'
                                textField='label'
                                value={targetFormatValues.find(el => el.value === document.conversionTargetFormat)}
                                onChange={onTargetFormatChange}
                                adaptive
                                disabled={!canEditConversionTarget}
                                style={{ width: '100%' }}
                                valueMap={(value) => value.value}
                                header={
                                    <div className={'c-flow-documents-list-item__conversion-header'}>{t('select-conversion')}</div>}
                            />
                        </div>
                    )}
                    <div className={'c-flow-documents-list-item__optional'}>
                        <Checkbox
                            name={document.localId}
                            label={t('optional')}
                            value={document.isOptional}
                            onChange={onOptionalClick}
                        />
                    </div>
                    <div className={'c-flow-documents-list-item__delete'}>
                        <Button
                            iconClass={'fa fa-solid fa-minus-circle'}
                            fillMode={'flat'}
                            onClick={onDeleteClick}
                        />
                    </div>
                </div>
                {(hasError || hasWarning) && (
                    <div
                        className={classNames(
                            'c-flow-documents-list-item__message',
                            {
                                'c-flow-documents-list-item__message--warning': hasWarning,
                                'c-flow-documents-list-item__message--error': hasError,
                            },
                        )}
                    >
                        <Typography token={TypographyToken.DesktopDescriptionSm} text={infoText} />
                    </div>
                )}
            </div>
        );
}

export default FlowDocumentsListItem;
