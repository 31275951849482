import { Icon, IconProps } from '@progress/kendo-react-common';
import { ActorType } from '~constants';
import { FieldType } from '~constants/field-type';
import { FlowStakeholderModel, StakeholderType } from '~models';

export interface StakeholderDisplayNameProps {
    stakeholder?: FlowStakeholderModel;
}

export const StakeholderDisplayName = ({ stakeholder }: StakeholderDisplayNameProps) => {
    let name: string;

    if (stakeholder?.type === StakeholderType.Person) {
        name = `${stakeholder.firstName ?? ''} ${stakeholder.lastName ?? ''}`.trim();

        return <>{name.length ? name : stakeholder.emailAddress}</>;
    }
    if (stakeholder?.type === StakeholderType.Group) {
        return <>{stakeholder.name ?? ''}</>;
    }

    return null;
};

export interface FieldTypeIconProps extends IconProps {
    type: FieldType | ActorType.Approver;
    alternate?: boolean;
}

export const FieldTypeIcon = ({ type, alternate, ...props }: FieldTypeIconProps) => {
    switch (type) {
        case FieldType.TextBox:
            return <Icon {...props} className={'fa-solid fa-text'} />;
        case FieldType.CheckBox:
            return <Icon {...props} className={'fa-regular fa-square-check'} />;
        case FieldType.RadioGroup:
            return <Icon {...props} className={'fa-regular fa-circle-dot'} />;
        case FieldType.SigningField:
            return <Icon {...props} className={'fa-solid fa-signature'} />;
        case ActorType.Approver:
            return alternate
                ? <Icon {...props} className={'fa-regular fa-circle-check'} /> :
                <Icon {...props} className={'fa-solid fa-circle-check'} />;
    }
};
