import { DateFilterEnum } from '@gonitro/rcl/lib/_types/filter';
import { LanguageDataLabel } from '~models';


export const DateFilterOptionsI18n: Record<DateFilterEnum, LanguageDataLabel<'base'>> = {
    [DateFilterEnum.THIS_WEEK]: 'filter-this-week',
    [DateFilterEnum.LAST_WEEK]: 'filter-last-week',
    [DateFilterEnum.THIS_MONTH]: 'filter-this-month',
    [DateFilterEnum.LAST_MONTH]: 'filter-last-month',
    [DateFilterEnum.THIS_YEAR]: 'filter-this-year',
    [DateFilterEnum.LAST_YEAR]: 'filter-last-year',
    [DateFilterEnum.ALL_TIME]: 'filter-all-time',
    [DateFilterEnum.CUSTOM_DATE]: 'filter-custom',
};

export const DateFilterOptions = [
    {
        label: DateFilterOptionsI18n[DateFilterEnum.ALL_TIME],
        value: DateFilterEnum.ALL_TIME,
    },
    {
        label: DateFilterOptionsI18n[DateFilterEnum.THIS_WEEK],
        value: DateFilterEnum.THIS_WEEK,
    },
    {
        label: DateFilterOptionsI18n[DateFilterEnum.LAST_WEEK],
        value: DateFilterEnum.LAST_WEEK,
    },
    {
        label: DateFilterOptionsI18n[DateFilterEnum.THIS_MONTH],
        value: DateFilterEnum.THIS_MONTH,
    },
    {
        label: DateFilterOptionsI18n[DateFilterEnum.LAST_MONTH],
        value: DateFilterEnum.LAST_MONTH,
    },
    {
        label: DateFilterOptionsI18n[DateFilterEnum.THIS_YEAR],
        value: DateFilterEnum.THIS_YEAR,
    },
    {
        label: DateFilterOptionsI18n[DateFilterEnum.LAST_YEAR],
        value: DateFilterEnum.LAST_YEAR,
    },
    {
        label: DateFilterOptionsI18n[DateFilterEnum.CUSTOM_DATE],
        value: DateFilterEnum.CUSTOM_DATE,
    },
];