import { SortDirectionEnum } from '@gonitro/rcl/lib/_types';
import {
    TableColumnSpecificWidth,
    TableDefinition,
    TableFilterValueSubType,
    TableFilterValueType,
} from '~contexts/table/table.types';

export const DocumentGroupsTableDefinition: TableDefinition<'documentGroupId'> = {
    rowIdKey: 'documentGroupId',
    itemsPerPage: 20,
    itemsPerPageOptions: [20, 50, 100],
    initialSorting: {
        key: 'name',
        direction: SortDirectionEnum.ASC,
    },
    columns: [
        {
            key: 'name',
            i18nLabel: {
                ns: 'user-management',
                key: 'user-management-portal-table-col-title-name',
            },
            width: TableColumnSpecificWidth.Selection,
            useEllipsis: true,
            sortable: true,
        },
        {
            key: 'action',
            width: TableColumnSpecificWidth.Button,
        },
    ],
    filters: [
        {
            type: TableFilterValueType.Primitive,
            subType: TableFilterValueSubType.String,
            key: 'name',
        },
    ],
};
