import { LanguageDataLabel } from '~models';
import { BaseRoutes, NestedRoutes } from './routes';

export enum UserManagementTabsEnum {
  Profile = 'Profile',
  UserGroups = 'UserGroups',
  Signature = 'Signature',
  OutOfOffice = 'OutOfOffice'
}

export enum ManageDocumentGroupsPanelTabsEnum {
    UserGroups = 'UserGroups',
    Themes = 'Themes',

}

export const UserManagementTabsI18n: Record<UserManagementTabsEnum, LanguageDataLabel<'user-management'>> = {
    [UserManagementTabsEnum.Profile]: 'user-management-edit-user-panel-tab-profile',
    [UserManagementTabsEnum.UserGroups]: 'user-management-edit-user-panel-tab-user-groups',
    [UserManagementTabsEnum.Signature]: 'user-management-edit-user-panel-tab-signature',
    [UserManagementTabsEnum.OutOfOffice]: 'user-management-edit-user-panel-tab-out-of-office',
};

export const ManageDocumentGroupsPanelI18n: Record<ManageDocumentGroupsPanelTabsEnum, LanguageDataLabel<'user-management'>> = {
    [ManageDocumentGroupsPanelTabsEnum.UserGroups]: 'manage-document-groups-user-groups-tab',
    [ManageDocumentGroupsPanelTabsEnum.Themes]: 'manage-document-groups-themes-tab',
};

export enum UserManagementPortalTabsEnum {
    Users = 'Users',
    UserGroups = 'UserGroups',
    DocumentGroups = 'DocumentGroups'
  }

export const editUserPanelTabs = [
    {
        key: UserManagementTabsEnum.Profile,
        i18n: {
            ns: 'user-management',
            key: UserManagementTabsI18n.Profile,
        },
    },
    {
        key: UserManagementTabsEnum.UserGroups,
        i18n: {
            ns: 'user-management',
            key: UserManagementTabsI18n.UserGroups,
        },
    },
    {
        key: UserManagementTabsEnum.Signature,
        i18n: {
            ns: 'user-management',
            key: UserManagementTabsI18n.Signature,
        },
    },
    {
        key: UserManagementTabsEnum.OutOfOffice,
        i18n: {
            ns: 'user-management',
            key: UserManagementTabsI18n.OutOfOffice,
        },
    },
];

export const USER_MANAGEMENT_PORTAL_TABS = [
    {
        key: UserManagementPortalTabsEnum.Users,
        i18n: {
            ns: 'user-management',
            key: 'user-management-portal-users-nav-tab',
        },
        url: BaseRoutes.Settings + '/' + NestedRoutes.Users,
    },
    {
        key: UserManagementPortalTabsEnum.UserGroups,
        i18n: {
            ns: 'user-management',
            key: 'user-management-portal-user-groups-nav-tab',
        },
        url: BaseRoutes.Settings + '/' + NestedRoutes.UserGroups,
    },
    {
        key: UserManagementPortalTabsEnum.DocumentGroups,
        i18n: {
            ns: 'user-management',
            key: 'user-management-portal-document-groups-nav-tab',
        },
        url: BaseRoutes.Settings + '/' + NestedRoutes.DocumentGroups,
    },
];

export enum UserGroupsManagementTabsEnum {
    General = 'General',
    UserProfiles = 'UserProfiles',
    DocumentGroups = 'DocumentGroups',
    AlternativeUserGroupNames = 'AlternativeUserGroupNames'
  }

export const UserGroupsManagementTabsI18n: Record<UserGroupsManagementTabsEnum, LanguageDataLabel<'user-management'>> = {
    [UserGroupsManagementTabsEnum.General]: 'user-management-user-group-panel-tab-general',
    [UserGroupsManagementTabsEnum.UserProfiles]: 'user-management-user-group-panel-tab-user-profiles',
    [UserGroupsManagementTabsEnum.DocumentGroups]: 'user-management-user-group-panel-tab-document-groups',
    [UserGroupsManagementTabsEnum.AlternativeUserGroupNames]: 'user-management-user-group-panel-tab-alternative-group-names',
};

export const userGroupPanelTabs = [
    {
        key: UserGroupsManagementTabsEnum.General,
        i18n: {
            ns: 'user-management',
            key: UserGroupsManagementTabsI18n.General,
        },
    },
    {
        key: UserGroupsManagementTabsEnum.UserProfiles,
        i18n: {
            ns: 'user-management',
            key: UserGroupsManagementTabsI18n.UserProfiles,
        },
    },
    {
        key: UserGroupsManagementTabsEnum.DocumentGroups,
        i18n: {
            ns: 'user-management',
            key: UserGroupsManagementTabsI18n.DocumentGroups,
        },
    },
    {
        key: UserGroupsManagementTabsEnum.AlternativeUserGroupNames,
        i18n: {
            ns: 'user-management',
            key: UserGroupsManagementTabsI18n.AlternativeUserGroupNames,
        },
    },
];

export const manageDocumentGroupsPanelTabs = [
    {
        key: ManageDocumentGroupsPanelTabsEnum.UserGroups,
        i18n: {
            ns: 'user-management',
            key: 'manage-document-groups-user-groups-tab',
        },
    },
    {
        key: ManageDocumentGroupsPanelTabsEnum.Themes,
        i18n: {
            ns: 'user-management',
            key: 'manage-document-groups-themes-tab',
        },
    },
];
