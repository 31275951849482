import './../../resources/flags-sprite/flags.scss';
import './countryPrefixFlag.scss';

//TODO Import after flag PR merged

export interface CountryPrefixFlagProps {
    countryCode: string;
}
function CountryPrefixFlag({ countryCode }: CountryPrefixFlagProps) {
    return <div className={`c-country-prefix-flag flag--${countryCode.toLowerCase()}`} />;
}


export default CountryPrefixFlag;