import { useContext } from 'react';
import { SetLanguageFunction } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import { Language, LanguageProfile } from '~models';
import { I18nContext } from '../i18n.context';

export const useI18nContext = () => {
    return useContext(I18nContext);
};

// it only reuses settings context but as related to i18n it fits here
export const useDefaultLanguageCodes = () => {
    const settings = useSettings();

    return {
        masterLanguage: settings.localizationSettings.languageOptions.masterLanguage,
        defaultUserLanguage: settings.localizationSettings.languageOptions.defaultUserLanguage,
    };
};

export const useCurrentLanguage: () => { languageCode: Language, languageProfile: LanguageProfile } = () => {
    const context = useContext(I18nContext);

    return {
        languageCode: context.language?.isoCultureCode ?? '', // shorthand
        languageProfile: context.language,
    };
};

export const useLanguages = () => {
    const { availableLanguages, language, setLanguage } = useContext(I18nContext);

    return {
        availableLanguages,
        language,
        setLanguage,
    };
};

export const useSetLanguage: () => SetLanguageFunction = () => {
    const context = useContext(I18nContext);

    return context.setLanguage;
};

export const useIsLanguageAvailable = () => {
    const { isLanguageAvailable } = useContext(I18nContext);

    return isLanguageAvailable;
};