import { AppSessionStorage, FileUtils } from '~lib';
import { Language } from '~models';
import { CloudStorageScenario } from './cloud-storage.scenario';

// this is taken form old portal and somehow works, but could be typed, maybe rewritten
export default class GoogleDriveScenario extends CloudStorageScenario {
    private static initialized: boolean = false;

    private picker?: any;

    private get oauthToken(): string | undefined {
        return AppSessionStorage.get('cloud.googleDrive.oauthToken') ?? undefined;
    }

    private set oauthToken(value) {
        AppSessionStorage.set('cloud.googleDrive.oauthToken', value);
    }

    // private picker: null;

    initialize() {

        if (!GoogleDriveScenario.initialized) {
            const gapiAuthLoaded = () => {/* notify? */
            };

            const gapiPickerLoaded = () => {/* notify? */
            };

            FileUtils.loadScript(
                'gapi',
                'https://apis.google.com/js/api.js',
                {},
                () => {
                    global.gapi.load('auth', { callback: gapiAuthLoaded });
                    global.gapi.load('picker', { callback: gapiPickerLoaded });
                },
            );

            GoogleDriveScenario.initialized = true;
        }
    }


    /**
     * Show the file picker
     */
    showFilePicker(language: Language) {
        this.handleGoogleDriveAuthentication()
            .then((oauthToken) => {
                const view = new google.picker.DocsView(google.picker.ViewId.DOCS).setMode(google.picker.DocsViewMode.LIST);

                view.setMimeTypes(this.allowedMimeTypes.join(','));
                view.setIncludeFolders(true);

                const picker = new google.picker.PickerBuilder()
                    .setAppId(this.cloudSettings.google.drive.key)
                    .setOAuthToken(oauthToken)
                    .setLocale(language)
                    .setCallback(this.handleFilesSelected.bind(this))
                    .addView(view);

                if (this.allowMultipleFiles) {
                    picker.enableFeature(google.picker.Feature.MULTISELECT_ENABLED);
                }

                this.picker = picker.build();
                this.picker.setVisible(true);
            });
    }

    /**
     * Authenticates the user and retrieves the OAuth token to access the google drive api.
     * If the user is already authenticated the previous OAuth token is used.
     *
     * @return {Promise} resolves to OAuth token
     */
    handleGoogleDriveAuthentication(): Promise<string> {
        if (!this.oauthToken) {
            return new Promise((resolve, reject) => {
                global.gapi.auth.authorize({
                    client_id: this.cloudSettings.google.drive.clientId,
                    scope: 'https://www.googleapis.com/auth/drive.readonly',
                    immediate: false,
                }, (authResult: any) => {
                    if (!authResult || authResult.error) {
                        reject(authResult.error);

                        return;
                    }
                    this.oauthToken = authResult.access_token;
                    resolve(authResult.access_token);
                });
            });
        } else {
            return Promise.resolve(this.oauthToken);
        }
    }

    handleFilesSelected(data: any) {
        if (data[google.picker.Response.ACTION] === google.picker.Action.PICKED) {
            this.handleGoogleDriveAuthentication()
                .then(googleOAuthToken => {
                    const selectedFiles = data.docs.map((file: any) => {
                        // update download url of the cloud file... (copied this from the old implementation)
                        file.url = `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`;
                        file.authToken = googleOAuthToken;

                        return {
                            name: file.name,
                            size: file.sizeBytes ?? 0,
                            mimeType: file.mimeType || 'application/octet-stream',
                            token: googleOAuthToken,
                            url: `https://www.googleapis.com/drive/v3/files/${file.id}?alt=media`,
                        };
                    });

                    this.notifyFileSelection(selectedFiles);
                })
                .then(() => {
                    if (this.picker != null) {
                        this.picker.dispose();
                        this.picker = null;
                    }
                });
        } else if (data[google.picker.Response.ACTION] === google.picker.Action.CANCEL) {
            if (this.picker != null) {
                this.picker.dispose();
                this.picker = null;
            }
        }
    }

}
