import { Button } from '@progress/kendo-react-buttons';
import { DragAndDrop } from '@progress/kendo-react-common';
import { AutoCompleteChangeEvent } from '@progress/kendo-react-dropdowns';
import { PanelBar, PanelBarItem, PanelBarSelectEventArguments } from '@progress/kendo-react-layout';
import classNames from 'classnames';
import FlowEditorActor, { FlowRecipientDragData } from 'components/flow/flow-editor/flow-editor-left-pane/flow-editor-recipients/flow-editor-actor';
import { cloneDeep } from 'lodash';
import React, { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import FlowEditorLeftPaneSearch from '~components/flow/flow-editor/flow-editor-left-pane/flow-editor-left-pane-search';
import FlowEditorAddRecipientModal
    from '~components/flow/flow-editor/flow-editor-left-pane/flow-editor-recipients/flow-editor-add-recipient-modal';
import Typography, { TypographyToken } from '~components/typography';
import { ActorType, IS_DEVELOPMENT_ENV } from '~constants';
import { FieldType } from '~constants/field-type';
import {
    useAddActor,
    useAddElement,
    useAddStakeholder,
    useFlowContext,
    useFlowState,
    useGetStakeholderInfo,
    useGetStakeholderInfoCallback,
    useMoveActor,
    useMoveActorElement, useRefreshStakeholders,
    useUndecidedStakeholderId,
} from '~contexts/flow/flow.hooks';
import { FlowEntityType, FlowGroupTitleI18nKey, FlowGroupType, FlowType } from '~contexts/flow/flow.types';
import { FlowUtils } from '~contexts/flow/flow.utils';
import { useTranslation } from '~contexts/i18n';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import {
    ContactBucketItemModel,
    DefinedLocationModel,
    FlowActorCommonModel,
    FlowActorModel,
    FlowApproverModel,
    FlowFormFillerModel,
    FlowReceiverModel,
    FlowSignerModel,
    Guid,
    Offset,
    StakeholderType,
} from '~models';
import './flowEditorRecipients.scss';

export interface FlowEditorRecipientsProps {

}

export type LocalFormFillerModel = FlowFormFillerModel & {
    approverActor?: FlowApproverModel;
}

const GroupTitle = ({ type, onAddClick }: {
    type: FlowGroupType,
    onAddClick: (type: FlowGroupType) => void
}) => {
    const { t } = useTranslation('flow');
    const onClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();

        onAddClick(type);
    }, [onAddClick, type]);

    return (
        <div className={'c-flow-editor-recipients__group-header'}>
            <Typography
                token={TypographyToken.MobileTagSm}
                className={'c-flow-editor-recipients__group-header-text'}
                dangerouslySetInnerHTML={{ __html: t(FlowGroupTitleI18nKey[type]) }}
            />
            <Button
                iconClass={'fa-solid fa-plus'}
                type={'button'}
                fillMode={'flat'}
                onClick={onClick}
            />
        </div>
    );
};

function sortActors<T extends FlowActorCommonModel>(getStakeholderInfo: useGetStakeholderInfoCallback, step: T[]) {
    return step.sort((a, b) => {
        const sA = getStakeholderInfo(a.stakeholderLocalId);
        const sB = getStakeholderInfo(b.stakeholderLocalId);

        if (!sA) {
            return -1;
        }
        if (!sB) {
            return 1;
        }
        const nameA = sA.type === StakeholderType.Person ? (sA.firstName || '' + sA.lastName).toLowerCase() : sA.name.toLowerCase();
        const nameB = sB.type === StakeholderType.Person ? (sB.firstName || '' + sB.lastName).toLowerCase() : sB.name.toLowerCase();

        return nameA < nameB ? -1 : 1;
    });
}

function filterNamesPredicate(actor: FlowActorModel | LocalFormFillerModel, search: string | undefined, getStakeholderInfo: useGetStakeholderInfoCallback) {
    if (!search || !search.length) {
        return true;
    }
    const s = getStakeholderInfo(actor.stakeholderLocalId) as any;

    const str = s ? ((s.firstName ?? '').trim() + (s.lastName ?? '').trim() + (s.name ?? '').trim()).toLowerCase() : '';

    return str.search(search) > -1;
}

function FlowEditorRecipients(props: FlowEditorRecipientsProps) {
    const { t } = useTranslation('flow');
    const { t: tBase } = useTranslation('base');
    const { flow } = useFlowState();
    const { setFocusedEntity, flowType, documentViewerRef } = useFlowContext();
    const [filteredNames] = useState<string[]>([]);
    const [filterValue, setFilterValue] = useState<string>();
    const [actorShouldShowAddFieldsPopup, setActorShouldShowAddFieldsPopup] = useState<{
        actorId: string,
        handle: () => void
    }>();
    const onSearchChange = useCallback((event: AutoCompleteChangeEvent) => {
        if (!event.value) {
            setFilterValue(undefined);
        } else {
            setFilterValue(event.value.toLowerCase());
        }
    }, []);

    const getStakeholderInfo = useGetStakeholderInfo();
    const [addRecipientType, setAddRecipientType] = useState<FlowGroupType>();

    const onAddClick = useCallback((type: FlowGroupType) => {
        setAddRecipientType(type);
    }, []);
    const addStakeholderCallback = useAddStakeholder();
    const refreshStakeholdersCallback = useRefreshStakeholders();
    const addActorCallback = useAddActor();
    const addElementCallback = useAddElement();
    const undecidedId = useUndecidedStakeholderId();

    const addRecipientCallback = useCallback(async (type: FlowGroupType, refreshStakeholder: boolean, contact?: ContactBucketItemModel) => {
        let stakeholderLocalId = undecidedId;
        let focusStakeholder = true;

        if (contact) {
            stakeholderLocalId = await addStakeholderCallback(contact);
        }

        switch (type) {
            case FlowGroupType.FormFillersApprovers: {
                const {
                    groupIndex,
                    actorId,
                } = addActorCallback(ActorType.FormFiller, stakeholderLocalId, 'last');

                setExpanded(value => {
                    const newVal = [...value];

                    if (!newVal.includes('recipients-ffa')) {
                        newVal.push('recipients-ffa');
                    }
                    if (!newVal.includes('recipients-ffa-' + groupIndex)) {
                        newVal.push('recipients-ffa-' + groupIndex);
                    }

                    return newVal;
                });

                setActorShouldShowAddFieldsPopup({
                    actorId,
                    handle: () => {
                        try {
                            setActorShouldShowAddFieldsPopup(undefined);
                        } catch (e) {
                        }
                    },
                });

                break;
            }
            case FlowGroupType.Signers: {
                const {
                    actorId,
                    groupIndex,
                    groupOffset,
                    actorExists,
                } = addActorCallback(ActorType.Signer, stakeholderLocalId, 'last');

                if (!actorExists) {
                    let newFieldLocalId: string;

                    if (flowType === FlowType.Package && documentViewerRef.current && documentViewerRef.current.container) {
                        const pageInfo = documentViewerRef.current.getPageFromVisiblePosition({
                            left: documentViewerRef.current.container.clientWidth / 2,
                            top: documentViewerRef.current.container.clientHeight / 2,
                        }, true);

                        if (!pageInfo) {
                            return;
                        }
                        const size = FlowUtils.fieldDefaultSize(FieldType.SigningField);

                        const newPos = {
                            top: pageInfo.onPagePos.top - size.height / 2,
                            left: pageInfo.onPagePos.left - size.width / 2,
                        };

                        if (newPos.top + size.height > pageInfo.originalSize.height) {
                            newPos.top = pageInfo.originalSize.height - size.height;
                        }
                        if (newPos.left + size.width > pageInfo.originalSize.width) {
                            newPos.left = pageInfo.originalSize.width - size.width;
                        }
                        const location: DefinedLocationModel = {
                            pageNumber: pageInfo.pageIndex + 1,
                            documentNumber: pageInfo.documentIndex + 1,
                            width: size.width,
                            height: size.height,
                            left: newPos.left,
                            top: newPos.top,
                            isLocked: false,
                        };

                        newFieldLocalId = addElementCallback(FieldType.SigningField, actorId, location);
                    } else {
                        newFieldLocalId = addElementCallback(FieldType.SigningField, actorId);
                    }
                    focusStakeholder = false;
                    setFocusedEntity(FlowEntityType.SigningField, newFieldLocalId, { selectedActor: actorId });
                }
                setExpanded(value => {
                    const newVal = [...value];

                    if (!newVal.includes('recipients-s')) {
                        newVal.push('recipients-s');
                    }
                    if (!newVal.includes('recipients-s-' + (groupIndex - groupOffset))) {
                        newVal.push('recipients-s-' + (groupIndex - groupOffset));
                    }

                    return newVal;
                });
                setActorShouldShowAddFieldsPopup({
                    actorId,
                    handle: () => {
                        try {
                            setActorShouldShowAddFieldsPopup(undefined);
                        } catch (e) {
                        }
                    },
                });
                break;
            }
            case FlowGroupType.Receivers: {
                addActorCallback(ActorType.Receiver, stakeholderLocalId, 'last');
                setExpanded(value => {
                    const newVal = [...value];

                    if (!newVal.includes('recipients-r')) {
                        newVal.push('recipients-r');
                    }

                    return newVal;
                });
                break;
            }

        }
        if (focusStakeholder) {
            setFocusedEntity(FlowEntityType.Stakeholder, stakeholderLocalId);
        }
        setAddRecipientType(undefined);

        if (refreshStakeholder) {
            await refreshStakeholdersCallback();
        }
    }, [
        addActorCallback,
        addElementCallback,
        addStakeholderCallback,
        documentViewerRef,
        flowType,
        refreshStakeholdersCallback,
        setFocusedEntity,
        undecidedId,
    ]);

    const [expanded, setExpanded] = useState<string[]>([]);
    const isInitialized = useRef(false);
    const handleSelect = (event: PanelBarSelectEventArguments) => {
        if (event.expandedItems) {
            setExpanded(event.expandedItems);
        }
    };

    // ===================== PARSE FLOW TO GROUPS & STEPS

    const { ffa, s, r } = useMemo(() => {
        const initiallyOpenedSignerSteps: string[] = [];

        const ffa: (LocalFormFillerModel | FlowApproverModel)[][] = [];
        const s: FlowSignerModel[][] = [];
        const r: FlowReceiverModel[][] = [];
        let si = 0;
        for (const group of flow) {
            if (!group.length) {
                continue;
            }
            switch (group[0].actorType) {
                case ActorType.Approver:
                case ActorType.FormFiller: {
                    const cloned = cloneDeep(group) as (LocalFormFillerModel | FlowApproverModel)[];

                    ffa.push(sortActors(getStakeholderInfo, cloned.filter((el) => filterNamesPredicate(el, filterValue, getStakeholderInfo))));
                    break;
                }
                case ActorType.Signer: {
                    initiallyOpenedSignerSteps.push(`recipients-s-${si}`);
                    si++;

                    const cloned = cloneDeep(group) as FlowSignerModel[];

                    s.push(sortActors(getStakeholderInfo, cloned.filter((el) => filterNamesPredicate(el, filterValue, getStakeholderInfo))));
                    break;
                }
                case ActorType.Receiver: {
                    const cloned = cloneDeep(group) as FlowReceiverModel[];

                    r.push(sortActors(getStakeholderInfo, cloned.filter((el) => filterNamesPredicate(el, filterValue, getStakeholderInfo))));
                    break;
                }
            }
        }
        for (let i = 0; i < ffa.length; i++) {
            const step = ffa[i];
            const approvers = step.filter((el) => el.actorType === ActorType.Approver) as FlowApproverModel[];
            const idsToFilterOut: Guid[] = [];
            for (const actor of step) {
                if (actor.actorType === ActorType.Approver) {
                    continue;
                }
                const found = approvers.find(el => el.stakeholderLocalId === actor.stakeholderLocalId);

                if (found) {
                    actor.approverActor = found;
                    idsToFilterOut.push(found.localId);
                }
            }
            ffa[i] = step.filter(el => !idsToFilterOut.includes(el.localId));
        }

        if (!isInitialized.current) {
            isInitialized.current = true;

            if (initiallyOpenedSignerSteps.length) {
                setExpanded(['recipients-s', ...initiallyOpenedSignerSteps]);
            }
        }

        return {
            ffa,
            s,
            r,
        };
    }, [filterValue, flow, getStakeholderInfo]);

    const contentRef = useRef<HTMLDivElement>(null);
    const portalRef = useRef<HTMLDivElement>(null);

    const [draggingInGroup, setDraggingInGroup] = useState<FlowGroupType>();
    const [draggingFromStep, setDraggingFromStep] = useState<number>();
    const [draggingType, setDraggingType] = useState<'actor' | 'field'>();
    const [dragData, setDragData] = useState<FlowRecipientDragData>();
    const [draggedOverStep, setDraggedOverStep] = useState<string>();
    const [stepToExpand, setStepToExpand] = useState<string>();

    useEffect(() => {
        if (!draggedOverStep || expanded.includes(draggedOverStep)) {
            return;
        }
        setStepToExpand(draggedOverStep);
        const timeout = setTimeout(() => {
            setStepToExpand(undefined);
            setExpanded((current) => {

                return [...current, draggedOverStep];
            });
        }, 1000);

        return () => {
            setStepToExpand(undefined);
            clearTimeout(timeout);
        };
    }, [expanded, draggedOverStep]);


    // ========================================= MOVE CALLBACKS

    const shuffleExpandedStepsAfterMove = useCallback((dragGroup: FlowGroupType, fromStep: number, toStep: number, wasAlone: boolean) => {
        setExpanded(value => {
            let newVal = [...value];

            if (wasAlone) { // reindex opened steps
                newVal = newVal.filter(el => el !== `recipients-${dragGroup}-${fromStep}`);
                const openedSteps = newVal.filter(el => el.startsWith(`recipients-${dragGroup}-`));
                for (const opened of openedSteps) {
                    const i = parseInt(opened.split('-')[2]);

                    if (isNaN(i) || i < fromStep) {
                        continue;
                    }
                    newVal = newVal.filter(el => el !== `recipients-${dragGroup}-${i}`);

                    if (!newVal.includes(`recipients-${dragGroup}-${i - 1}`)) {
                        newVal.push(`recipients-${dragGroup}-${i - 1}`);
                    }
                }
            }

            if (!newVal.includes(`recipients-${dragGroup}-${toStep}`)) {
                newVal.push(`recipients-${dragGroup}-${toStep}`);
            }

            return newVal;
        });
    }, []);

    const moveActorCallback = useMoveActor();

    const moveActor = useCallback((dragData: FlowRecipientDragData, targetStep: number | 'new') => {
        const indexes = FlowUtils.findFlowActorGroupIndexes(flow, dragData.group);
        const fromIndex = dragData.step + indexes.first;

        let moveInfo: {
            wasAlone: boolean;
            groupIndex: number;
            groupOffset: number;
        } | null;

        let wasAlone = false;

        if (dragData.nestedApprover && dragData.nestedApprover.localId !== dragData.actor.localId) { // there is nested approver in actor and this approver is not actual actor
            wasAlone = flow[fromIndex].length === 2;
            const toNextStep = targetStep === 'new' || targetStep > dragData.step;

            if (fromIndex === indexes.last && targetStep === 'new' && wasAlone) {
                return; // moving formfiller and approver actor from last group to new group does nothing so do nothing
            }
            moveInfo = moveActorCallback(dragData.nestedApprover.localId, targetStep, true);

            if (!moveInfo) {
                return;
            }
            moveInfo = moveActorCallback(dragData.actor.localId, moveInfo.groupIndex - (toNextStep && wasAlone ? 1 : 0), false);

            if (!moveInfo) {
                return;
            }
        } else {
            if (fromIndex === indexes.last && targetStep === 'new' && flow[fromIndex].length === 1) {
                return; // moving single actor from last group to new group does nothing so do nothing
            }
            moveInfo = moveActorCallback(dragData.actor.localId, targetStep, true);

            if (!moveInfo) {
                return;
            }
            wasAlone = moveInfo?.wasAlone;
        }

        shuffleExpandedStepsAfterMove(dragData.group, dragData.step, moveInfo.groupIndex - moveInfo.groupOffset, wasAlone);
    }, [flow, moveActorCallback, shuffleExpandedStepsAfterMove]);

    const moveActorFieldCallback = useMoveActorElement();
    const moveField = useCallback((dragData: FlowRecipientDragData, targetStep: number | 'new') => {
        if (!dragData.fieldId) {
            throw new Error('missing fieldId in drag data');
        }
        const ret = dragData.fieldId && dragData.nestedApprover && dragData.fieldId === dragData.nestedApprover.localId
            ? moveActorCallback(dragData.nestedApprover.localId, targetStep, true)// dragging approver 'field' which is actually an actor
            : moveActorFieldCallback(dragData.actor.localId, dragData.fieldId, targetStep, true);

        if (!ret) {
            return;
        }

        shuffleExpandedStepsAfterMove(dragData.group, dragData.step, ret.groupIndex - ret.groupOffset, ret.wasAlone);
    }, [moveActorCallback, moveActorFieldCallback, shuffleExpandedStepsAfterMove]);

    // ========================================= DRAG HANDLERS

    const onDragStart = useCallback((group: FlowGroupType, step: number, type: 'actor' | 'field', data: any) => {
        setDraggingInGroup(group);
        setDraggingFromStep(step);
        setDraggingType(type);
        setDragData(data);
    }, []);

    const onDrag = useCallback(({ left, top }: Offset) => {
        if (!draggingInGroup) {
            return;
        }
        if (left === 0 && top === 0) {
            setDraggedOverStep(undefined);

            return;
        }
        const els = document.elementsFromPoint(left, top);
        for (const el of els) {
            if (el.id.startsWith(`recipients-${draggingInGroup}-`)) {
                setDraggedOverStep(el.id);
            }
        }
    }, [draggingInGroup]);
    const onDragEnd = useCallback(async () => {
        setDraggingInGroup(undefined);
        setDraggingFromStep(undefined);
        setDraggingType(undefined);
        setDragData(undefined);
        setStepToExpand(undefined);
        setDraggedOverStep(undefined);
        try {

            const target = draggedOverStep!.split('-')[2];
            const targetStep = target === 'drop' ? 'new' : parseInt(target);

            if (!dragData || dragData.step === targetStep) {
                return;
            }
            if (dragData?.dragType === 'actor') {
                moveActor(dragData, targetStep);
            } else {
                moveField(dragData, targetStep);
            }
        } catch (e) {
        }

    }, [dragData, draggedOverStep, moveField, moveActor]);

    // ===================== DRAG HANDLERS END


    return (
        <div className={'c-flow-editor-recipients c-flow-editor-left-pane-component'}>
            <div className={'c-flow-editor-left-pane-component__search'}>
                <FlowEditorLeftPaneSearch
                    disabled={!s.length && !ffa.length && !r.length}
                    data={filteredNames}
                    placeholder={t('search-recipients')}
                    onChange={onSearchChange}
                />
            </div>
            <div className={'c-flow-editor-left-pane-component__content'} ref={contentRef}>
                <DragAndDrop>
                    <PanelBar
                        className={'c-flow-editor-recipients__panelbar'}
                        isControlled
                        onSelect={handleSelect}
                        expanded={expanded}
                    >
                        <PanelBarItem
                            id={'recipients-ffa'}
                            className={classNames('c-flow-editor-recipients__group c-flow-editor-recipients__group--ffa', {
                                'c-flow-editor-recipients__group--dragging-in': draggingInGroup === FlowGroupType.FormFillersApprovers,
                                'c-flow-editor-recipients__group--dragging-out': draggingInGroup && draggingInGroup !== FlowGroupType.FormFillersApprovers,
                            })}
                            itemType={'group'}
                            title={<GroupTitle
                                type={FlowGroupType.FormFillersApprovers}
                                onAddClick={onAddClick}
                            />}
                        >
                            {ffa.map((group, idx) => {
                                const container = contentRef.current?.querySelector<HTMLElement>('.c-flow-editor-recipients__group--ffa .k-panelbar-group');
                                const id = `recipients-ffa-${idx}`;

                                return (
                                    <PanelBarItem
                                        id={id}
                                        key={idx}
                                        itemType={'step'}
                                        className={classNames('c-flow-editor-recipients__step', { 'c-flow-editor-recipients__step--to-expand': stepToExpand === id })}
                                        title={<Typography
                                            token={TypographyToken.DesktopTagXs}
                                            text={t('step-x', { x: idx + 1 }) + (IS_DEVELOPMENT_ENV ? `; gIdx= ${idx}; absIdx= ${idx}` : '')}
                                        />}
                                    >
                                        <div>
                                            {group.map((el, sidx) => (
                                                <FlowEditorActor
                                                    actor={el}
                                                    group={FlowGroupType.FormFillersApprovers}
                                                    step={idx}
                                                    key={sidx}
                                                    dragContainer={container}
                                                    portalContainer={portalRef.current}
                                                    paneContainer={contentRef.current}
                                                    onDragStart={onDragStart}
                                                    onDrag={onDrag}
                                                    onDragEnd={onDragEnd}
                                                    shouldShowAddFieldsPopup={actorShouldShowAddFieldsPopup?.actorId === el.localId ? actorShouldShowAddFieldsPopup.handle : undefined}
                                                />
                                            ))}
                                            {draggedOverStep === id && idx !== draggingFromStep && (
                                                <div className={'c-flow-editor-recipients__step-drop-here'}>
                                                    <Typography
                                                        token={TypographyToken.DesktopDescriptionBoldSm}
                                                        className={'c-flow-editor-recipients__step-drop-here-content'}
                                                        tagName={'div'}
                                                    >
                                                        {draggingType === 'actor' && t('existing-step-drop-recipient-instruction')}
                                                        {draggingType === 'field' && t('existing-step-drop-element-instruction')}
                                                    </Typography>
                                                </div>
                                            )}
                                            {!group.length && filterValue && filterValue.length > 0 && (
                                                <Typography
                                                    token={TypographyToken.DesktopDescriptionSm}
                                                    className={'c-flow-editor-recipients__step-no-results'}
                                                    tagName={'div'}
                                                    text={tBase('no-results')}
                                                />
                                            )}
                                        </div>
                                    </PanelBarItem>
                                );
                            })}
                            {ffa.length > 0 && expanded.findIndex(el => el.startsWith('recipients-ffa-') && el !== 'recipients-ffa-drop') > -1 && (
                                <PanelBarItem
                                    id={'recipients-ffa-drop'}
                                    className={classNames('c-flow-editor-recipients__droparea', { 'c-flow-editor-recipients__droparea--active': 'recipients-ffa-drop' === draggedOverStep })}
                                    itemType={'droparea'}
                                    title={(
                                        <div className={'c-flow-editor-recipients__droparea-content'}>
                                            <i className={'fa-solid fa-plus'} />
                                            <Typography
                                                token={TypographyToken.UiMenuXs}
                                                text={t('new-step-dropzone-instruction')}
                                            />
                                        </div>
                                    )}
                                />
                            )}
                        </PanelBarItem>
                        <PanelBarItem
                            id={'recipients-s'}
                            className={classNames('c-flow-editor-recipients__group c-flow-editor-recipients__group--s', {
                                'c-flow-editor-recipients__group--dragging-in': draggingInGroup === FlowGroupType.Signers,
                                'c-flow-editor-recipients__group--dragging-out': draggingInGroup && draggingInGroup !== FlowGroupType.Signers,
                            })}
                            itemType={'group'}
                            title={<GroupTitle type={FlowGroupType.Signers} onAddClick={onAddClick} />}
                        >
                            {s.map((group, idx) => {
                                const container = contentRef.current?.querySelector<HTMLElement>('.c-flow-editor-recipients__group--s .k-panelbar-group');

                                const id = `recipients-s-${idx}`;

                                return (
                                    <PanelBarItem
                                        id={id}
                                        key={idx}
                                        itemType={'step'}
                                        className={classNames('c-flow-editor-recipients__step', { 'c-flow-editor-recipients__step--to-expand': stepToExpand === id })}
                                        title={<Typography
                                            token={TypographyToken.DesktopTagXs}
                                            text={t('step-x', { x: idx + 1 + ffa.length }) + (IS_DEVELOPMENT_ENV ? `; gIdx= ${idx}; absIdx= ${idx + ffa.length}` : '')}
                                        />}
                                    >
                                        <div>
                                            {group.map((el, sidx) => (
                                                <FlowEditorActor
                                                    actor={el}
                                                    group={FlowGroupType.Signers}
                                                    step={idx}
                                                    key={sidx}
                                                    dragContainer={container}
                                                    portalContainer={portalRef.current}
                                                    paneContainer={contentRef.current}
                                                    onDragStart={onDragStart}
                                                    onDrag={onDrag}
                                                    onDragEnd={onDragEnd}
                                                    shouldShowAddFieldsPopup={actorShouldShowAddFieldsPopup?.actorId === el.localId ? actorShouldShowAddFieldsPopup.handle : undefined}
                                                />
                                            ))}
                                            {draggedOverStep === id && idx !== draggingFromStep && (
                                                <div className={'c-flow-editor-recipients__step-drop-here'}>
                                                    <div className={'c-flow-editor-recipients__step-drop-here-content'}>
                                                        {draggingType === 'actor' && 'Drop here to move recipient to this step'}
                                                        {draggingType === 'field' && 'Drop here to move element to this step'}
                                                    </div>
                                                </div>
                                            )}
                                            {!group.length && filterValue && filterValue.length > 0 && (
                                                <Typography
                                                    token={TypographyToken.DesktopDescriptionSm}
                                                    className={'c-flow-editor-recipients__step-no-results'}
                                                    tagName={'div'}
                                                    text={tBase('no-results')}
                                                />
                                            )}
                                        </div>
                                    </PanelBarItem>
                                );
                            })}
                            {s.length > 0 && expanded.findIndex(el => el.startsWith('recipients-s-') && el !== 'recipients-s-drop') > -1 && (
                                <PanelBarItem
                                    id={'recipients-s-drop'}
                                    className={classNames('c-flow-editor-recipients__droparea', { 'c-flow-editor-recipients__droparea--active': 'recipients-s-drop' === draggedOverStep })}
                                    itemType={'droparea'}
                                    title={(
                                        <div className={'c-flow-editor-recipients__droparea-content'}>
                                            <i className={'fa-solid fa-plus'} />
                                            <Typography
                                                token={TypographyToken.UiMenuXs}
                                                text={t('new-step-dropzone-instruction')}
                                            />
                                        </div>
                                    )}
                                />
                            )}
                        </PanelBarItem>
                        <PanelBarItem
                            id={'recipients-r'}
                            className={classNames('c-flow-editor-recipients__group c-flow-editor-recipients__group--r', {
                                'c-flow-editor-recipients__group--hide-content': !r.length,
                                'c-flow-editor-recipients__group--dragging-out': draggingInGroup,
                            })}
                            title={<GroupTitle type={FlowGroupType.Receivers} onAddClick={onAddClick} />}
                        >
                            <div>
                                {r.length > 0 && (
                                    <>
                                        {r.map((group, idx) => {
                                            return group.map((el) => {
                                                return <FlowEditorActor
                                                    actor={el}
                                                    key={el.localId}
                                                    group={FlowGroupType.Receivers}
                                                    step={idx}
                                                />;
                                            });
                                        })}
                                        {!r[0].length && filterValue && filterValue.length && (
                                            <Typography
                                                token={TypographyToken.DesktopDescriptionSm}
                                                className={'c-flow-editor-recipients__step-no-results'}
                                                tagName={'div'}
                                                text={tBase('no-results')}
                                            />
                                        )}
                                    </>
                                )}
                            </div>
                        </PanelBarItem>
                    </PanelBar>
                    <div className={'c-flow-editor-recipients__portal'} ref={portalRef}></div>
                </DragAndDrop>
            </div>
            <div className={'c-flow-editor-left-pane-component__add'}>
                <Button
                    themeColor={'secondary'}
                    className={'c-flow-editor-left-pane-component__add-btn'}
                    onClick={() => onAddClick(FlowGroupType.Signers)}
                >{t('add-recipient-btn')}</Button>
            </div>

            <OverlayPortal type={'modal'} visible={!!addRecipientType}>
                {() => <FlowEditorAddRecipientModal
                    type={addRecipientType}
                    onCloseClick={() => setAddRecipientType(undefined)}
                    addClickCallback={addRecipientCallback}
                    canAddRecipientWithoutContactInfo={flowType === FlowType.Template}
                />}
            </OverlayPortal>
        </div>
    );
}

export default FlowEditorRecipients;
