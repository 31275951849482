import { useMemo } from 'react';
import { ContactGroupType, ContactTypeI18n, Permission } from '~constants';
import { PortalTableFiltersDefinition } from '~containers/portal-table/portal-table-filters/portal-table-filters';
import { useHasPermission } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';

export const useContactsTableFilters = () => {
    const { t: tBase } = useTranslation('base');
    const { t: tContacts } = useTranslation('contacts');
    const hasViewSharedContactGroupPermission = useHasPermission(Permission.General_ActionViewSharedContactGroup);

    return useMemo<PortalTableFiltersDefinition>(() => {
        const baseFilterTypes = [ContactGroupType.Personal];

        const filters: PortalTableFiltersDefinition = {
            searchAll: {
                type: 'single',
                key: 'searchString',
                placeholder: tBase('search-input-placeholder'),
            },
            dropdowns: [],
        };


        if (hasViewSharedContactGroupPermission) {
            baseFilterTypes.push(ContactGroupType.Shared);
        }

        if (baseFilterTypes.length > 1) {
            filters.dropdowns?.push({
                type: 'multi',
                key: 'types',
                options: baseFilterTypes.map((type) => ({
                    value: type,
                    label: tContacts(ContactTypeI18n[type]),
                })),
                defaultOption: '*',
                translations: {
                    label: tContacts('contact-group-type-label'),
                    allItems: tContacts('contact-group-type-all'),
                    none: tContacts('contact-group-type-none'),
                },
            });
        }

        return filters;
    }, [hasViewSharedContactGroupPermission, tBase, tContacts]);
};
