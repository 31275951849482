export enum ArchiveProvider {
    NONE = 0,
    DOCCLE = 1,
}

export type Size = {
    width: number;
    height: number;
}

export type Offset = {
    top: number;
    left: number;
}

export type Point = {
    x: number;
    y: number;
}
