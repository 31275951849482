import { NormalizedDragEvent, useDraggable } from '@progress/kendo-react-common';
import { Popup } from '@progress/kendo-react-popup';
import classNames from 'classnames';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FieldTypeIcon } from '~components/flow/flow-util-components';
import { ActorType } from '~constants';
import { FieldType } from '~constants/field-type';
import {
    useAddActor,
    useAddElement, useDeleteActor,
    useFlowContext,
    useStakeholderColorClass,
    useUndecidedStakeholderId,
} from '~contexts/flow/flow.hooks';
import { FlowUtils } from '~contexts/flow/flow.utils';
import { useTranslation } from '~contexts/i18n';
import { useAbortEffect } from '~hooks/effects';
import { DomUtils } from '~lib';
import { DefinedLocationModel, FlowActorModel, Offset, Size } from '~models';
import './flowEditorAddFieldsPopup.scss';


const Types: (FieldType | ActorType.Approver)[] = [
    FieldType.TextBox,
    FieldType.CheckBox,
    FieldType.RadioGroup,
    ActorType.Approver,
    FieldType.SigningField,
];

export interface FlowEditorAddFieldsPopupProps {
    actor?: FlowActorModel;
    hide: () => void;
    anchor?: HTMLElement | null;
    appendTo?: HTMLElement | null;
    paneContainer?: HTMLElement | null;
}

function FlowEditorAddFieldsPopup({ hide, actor, anchor, appendTo, paneContainer }: FlowEditorAddFieldsPopupProps) {
    const colorClass = useStakeholderColorClass(actor?.stakeholderLocalId);
    const undecidedId = useUndecidedStakeholderId();
    const { flowState: { flow } } = useFlowContext();
    const { t } = useTranslation('flow');

    useAbortEffect((signal: AbortSignal) => {
        if (!actor || !paneContainer || !anchor) {
            return;
        }

        paneContainer.addEventListener('scroll', (event) => {
            hide();
        }, { signal });
        window.addEventListener('mousedown', (ev) => {
            const inPopup = DomUtils.findParent(ev.target as HTMLElement, (el) => {
                return el === appendTo;
            }, appendTo?.parentNode as HTMLElement);
            const inActor = DomUtils.findParent(ev.target as HTMLElement, (el) => {
                return el === anchor;
            }, anchor?.parentNode as HTMLElement);

            if (!inPopup && !inActor) {
                hide();
            }
        }, { signal });

    }, [
        actor,
        anchor,
        appendTo,
        hide,
        paneContainer,
    ]);

    const types = useMemo(() => {
        if (!actor) {
            return Types;
        }
        const thisInfo = FlowUtils.findActor(flow, actor.localId);
        const actors = FlowUtils
            .findStakeholderActors(flow, actor.stakeholderLocalId ?? undecidedId)
            .filter(el => el.stepIndex === thisInfo?.stepIndex);

        if (actors.find(el => el.actor.actorType === ActorType.Approver)) {
            return Types.filter(el => el !== ActorType.Approver);
        }

        return Types;
    }, [actor, flow, undecidedId]);

    const popupRef = useRef<Popup>();

    const validatePosition = useCallback((pos: Offset) => {
        const rect = popupRef.current?.element.getBoundingClientRect();

        if (!rect) {
            return false;
        }
        const inX = pos.left >= rect.left && pos.left <= rect.right;
        const inY = pos.top >= rect.top && pos.top <= rect.bottom;

        return !(inX && inY);
    }, []);

    return (
        <Popup
            onMouseDownOutside={hide}
            appendTo={appendTo}
            anchor={anchor}
            margin={{
                horizontal: 16,
                vertical: -32,
            }}
            show={!!actor}
            className={classNames('c-flow-editor-add-fields-popup', colorClass)}
            popupAlign={{
                horizontal: 'left',
                vertical: 'top',
            }}
            anchorAlign={{
                horizontal: 'right',
                vertical: 'top',
            }}
            ref={popupRef}
        >
            <div className={'c-flow-editor-add-fields-popup__title'}>{t('add-field-title')}</div>
            <div className={'c-flow-editor-add-fields-popup__content'}>
                {types.map((el) => (
                    <DraggableField
                        key={el}
                        type={el}
                        actor={actor}
                        validatePosition={validatePosition}
                    />
                ))}
            </div>
        </Popup>
    );
}

interface DraggableFieldProps {
    type: FieldType | ActorType.Approver;
    actor?: FlowActorModel;
    validatePosition: (pos: Offset) => boolean;
}

const DraggableField = ({ type, actor, validatePosition }: DraggableFieldProps) => {
    const { t } = useTranslation('flow');
    const { documentViewerRef, flowState: { flow } } = useFlowContext();

    const [dragged, setDragged] = useState<boolean>(false);
    const [relativeOffset, setRelativeOffset] = useState<Offset>();
    const [limits, setLimits] = useState<{ max: Offset, min: Offset }>();
    const [position, setPosition] = useState<Offset>({
        left: 0,
        top: 0,
    });
    const [size, setSize] = useState<Size>({
        width: 0,
        height: 0,
    });

    const ref = useRef<HTMLDivElement>(null);
    const onPress = useCallback((event: NormalizedDragEvent) => {
        if (!documentViewerRef.current || !ref.current) {
            return;
        }

        const rect = ref.current.getBoundingClientRect();
        const docViewerRect = documentViewerRef.current.container?.getBoundingClientRect();

        if (!docViewerRect) {
            return;
        }
        setLimits({
            min: {
                left: docViewerRect.left,
                top: docViewerRect.top,
            },
            max: {
                left: docViewerRect.right - rect.width,
                top: docViewerRect.bottom - rect.height,
            },
        });
        setSize({
            width: rect.width,
            height: rect.height,
        });
        setRelativeOffset({
            left: event.clientX - rect.left,
            top: event.clientY - rect.top,
        });
        setPosition({
            left: rect.left,
            top: rect.top,
        });
        setDragged(true);
    }, [documentViewerRef]);

    const onDrag = useCallback((event: NormalizedDragEvent) => {
        if (!limits || !relativeOffset) {
            return;
        }
        setPosition({
            left: Math.min(limits.max.left, Math.max(limits.min.left, event.clientX - relativeOffset.left)),
            top: Math.min(limits.max.top, Math.max(limits.min.top, event.clientY - relativeOffset.top)),
        });
    }, [limits, relativeOffset]);

    const addElement = useAddElement();
    const addActor = useAddActor();
    const deleteActor = useDeleteActor();

    const onRelease = useCallback((event: NormalizedDragEvent) => {
        setDragged(false);
        setLimits(undefined);
        setRelativeOffset(undefined);

        if (!dragged || !documentViewerRef.current || !actor) {
            return;
        }
        if (!validatePosition({
            left: event.clientX,
            top: event.clientY,
        })) {
            return;
        }

        if (type === ActorType.Approver) {
            const info = FlowUtils.findActor(flow, actor.localId);

            if (!info) {
                return;
            }

            let addedIndex: number | undefined;

            if (info.actor.actorType !== ActorType.Signer) {

                addedIndex = addActor(ActorType.Approver, actor.stakeholderLocalId, info.stepIndex).groupIndex;
            } else {
                const indexes = FlowUtils.findFlowActorGroupIndexes(flow, ActorType.Approver);

                addedIndex = addActor(ActorType.Approver, actor.stakeholderLocalId, indexes.last < 0 ? 'new' : indexes.last).groupIndex;
            }
            const emptyFormFiller = flow[addedIndex].find(el => (
                el.actorType === ActorType.FormFiller
                && el.elements.length === 0
                && el.stakeholderLocalId === actor.stakeholderLocalId
            ));

            if (emptyFormFiller) {
                deleteActor(emptyFormFiller.localId);
            }

            return;
        }

        const rect = documentViewerRef.current.container?.getBoundingClientRect();

        if (!rect) {
            return;
        }
        const pageInfo = documentViewerRef.current.getPageFromVisiblePosition({
            left: position.left - rect.left,
            top: position.top - rect.top,
        });

        if (!pageInfo) {
            return;
        }

        // ====== SIZE
        const size = FlowUtils.fieldDefaultSize(type);
        const newPos = { ...pageInfo.onPagePos };

        if (newPos.left + size.width > pageInfo.originalSize.width) {
            newPos.left = pageInfo.originalSize.width - size.width;
        }
        if (newPos.top + size.height > pageInfo.originalSize.height) {
            newPos.top = pageInfo.originalSize.height - size.height;
        }
        const location: DefinedLocationModel = {
            pageNumber: pageInfo.pageIndex + 1,
            documentNumber: pageInfo.documentIndex + 1,
            width: size.width,
            height: size.height,
            left: newPos.left,
            top: newPos.top,
            isLocked: false,
        };


        // ====== WHERE TO ADD

        // if adding signing field from formfiller/approver group (actor)
        if (type === FieldType.SigningField && actor.actorType !== ActorType.Signer) {
            const id = addActor(ActorType.Signer, actor.stakeholderLocalId, 'last');

            addElement(type, id.actorId, location);
        } else if (type !== FieldType.SigningField && actor.actorType === ActorType.Signer) { // or form field from signer group (actor)
            const id = addActor(ActorType.FormFiller, actor.stakeholderLocalId, 'last');

            addElement(type, id.actorId, location);
        } else if (actor.actorType === ActorType.Approver) { // optionally it can be approver actor but adding form field (signing field is handled in previous if)

            const info = FlowUtils.findActor(flow, actor.localId);

            if (!info) {
                console.warn('error adding form filler from approver actor');

                return;
            }

            const id = addActor(ActorType.FormFiller, actor.stakeholderLocalId, info.stepIndex);

            addElement(type, id.actorId, location);
        } else if (actor.actorType === ActorType.Receiver) {
            const id = addActor(ActorType.FormFiller, actor.stakeholderLocalId, 'last');

            addElement(type, id.actorId, location);
        } else {
            addElement(type, actor.localId, location);
        }

    }, [
        dragged,
        actor,
        addActor,
        addElement,
        deleteActor,
        documentViewerRef,
        flow,
        position.left,
        position.top,
        type,
        validatePosition,
    ]);

    useDraggable(ref, {
        onPress,
        onDrag,
        onRelease,
    });

    const { text, icon } = useMemo(() => {
        switch (type) {
            case FieldType.TextBox:
                return {
                    text: t('field-object-label-input'),
                    icon: <FieldTypeIcon type={FieldType.TextBox} />,
                };
            case FieldType.CheckBox:
                return {
                    text: t('field-object-label-check'),
                    icon: <FieldTypeIcon type={FieldType.CheckBox} />,
                };
            case FieldType.RadioGroup:
                return {
                    text: t('field-object-label-radio'),
                    icon: <FieldTypeIcon type={FieldType.RadioGroup} />,
                };
            case FieldType.SigningField:
                return {
                    text: t('field-object-label-signature'),
                    icon: <FieldTypeIcon type={FieldType.SigningField} />,
                };
            case ActorType.Approver:
                return {
                    text: t('field-object-label-approval'),
                    icon: <FieldTypeIcon type={ActorType.Approver} />,
                };
        }
    }, [t, type]);

    return (
        <>
            {dragged && <div
                className={'c-flow-editor-add-fields-popup__field c-flow-editor-add-fields-popup__field--ghost'}
                style={size}
            ></div>}
            <div
                className={classNames('c-flow-editor-add-fields-popup__field', { 'c-flow-editor-add-fields-popup__field--is-dragging': dragged })}
                ref={ref}
                style={dragged
                    ? {
                        ...size,
                        ...position,
                    }
                    : {}}
            >
                <span className={'c-flow-editor-add-fields-popup__grip'}>
                    <i className={'fa-solid fa-grip-lines-vertical'} />
                </span>
                <span className={'c-flow-editor-add-fields-popup__icon'}>
                    {icon}
                </span>
                <span className={'c-flow-editor-add-fields-popup__text'}>{text}</span>
            </div>
        </>
    );
};

export default FlowEditorAddFieldsPopup;
