/* eslint-disable react-hooks/rules-of-hooks */
import { NdsButton, NdsIconFont } from '@gonitro/rcl';
import { NdsButtonStylesEnum, NdsButtonTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import { DateTimePicker } from '@progress/kendo-react-dateinputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { SettingsApi } from '~api/settings.api';
import LegalNotice from '~components/legal-notice/legalNotice';
import Typography, { TypographyToken } from '~components/typography';
import { useApi } from '~contexts/api';
import { useAppContext } from '~contexts/app';
import { useFlowContext, useFlowState, useSetFlowState } from '~contexts/flow';
import { FlowError } from '~contexts/flow/flow.error';
import { FlowActionType, FlowType } from '~contexts/flow/flow.types';
import { useLanguages, useTranslation } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import { useAsyncEffect } from '~hooks/effects';
import { Guid } from '~models';
import './flowEditorPackageProperties.scss';


export interface FlowEditorPackagePropertiesProps {

}

interface ThemesPropsState {
    defaultTheme?: {
        name: string,
        themeId: Guid,
    },
    themes: {
        name: string,
        themeId: Guid,
    }[],
}

function FlowEditorPackageProperties(props: FlowEditorPackagePropertiesProps) {
    const { flowType, hasError, getErrorInfo } = useFlowContext();
    const { packageName, templateName, properties, isLegalNoticeEnabled } = useFlowState();
    const { t } = useTranslation('flow');
    const setFlowState = useSetFlowState();

    const { availableLanguages } = useLanguages();
    const { workspaces } = useAppContext();
    const settings = useSettings();

    const settingsApi = useApi(SettingsApi);
    const [themesProps, setThemesProps] = useState<ThemesPropsState>({
        defaultTheme: undefined,
        themes: [],
    });

    const packageNameError = useMemo<{ text: any }>(() => {
        if (hasError(FlowError.PackageNameCannotBeEmpty, 'Package')) {
            return getErrorInfo(FlowError.PackageNameCannotBeEmpty, 'Package');
        }
    }, [hasError, getErrorInfo]);

    const redirectUrlError = useMemo<{ text: any }>(() => {
        if (hasError(FlowError.PackageRedirectUrlValidUrl, 'Package')) {
            return getErrorInfo(FlowError.PackageRedirectUrlValidUrl, 'Package');
        }
    }, [hasError, getErrorInfo]);

    const callbackUrlError = useMemo<{ text: any }>(() => {
        if (hasError(FlowError.PackageCallbackUrlValidUrl, 'Package')) {
            return getErrorInfo(FlowError.PackageCallbackUrlValidUrl, 'Package');
        }
    }, [hasError, getErrorInfo]);

    const notificationCallbackUrlError = useMemo<{ text: any }>(() => {
        if (hasError(FlowError.PackageNotificationUrlValidUrl, 'Package')) {
            return getErrorInfo(FlowError.PackageNotificationUrlValidUrl, 'Package');
        }
    }, [hasError, getErrorInfo]);

    useAsyncEffect(
        () => async () => {
            if (properties != null) {
                const result = await settingsApi.getThemesPerDocumentGroup({ documentGroupId: properties.documentGroupId });

                setThemesProps(result);
            }
        },
        [properties, settingsApi],
    );

    const [isExpiryDateChecked, setIsExpiryDateChecked] = useState(properties?.expirationTimeStamp != null);
    const [isSendAutomaticRemindersChecked, setIsSendAutomaticRemindersChecked] = useState(properties?.automaticReminder?.isSendAutomaticRemindersEnabled);
    const [isActionUrlExpirationChecked, setIsActionUrlExpirationChecked] = useState(properties?.isActionUrlExpirationEnabled);

    return (
        <div className={'c-flow-editor-package-properties c-flow-editor-right-pane-component'}>
            <PanelBar>
                <PanelBarItem
                    title={flowType === FlowType.Package ? 'Package name' : 'Template name'}
                    expanded
                >
                    <Input
                        value={flowType === FlowType.Package ? packageName : templateName}
                        onChange={(e) => {
                            if (flowType === FlowType.Package) {
                                setFlowState(FlowActionType.UpdatePackageName, e.value);
                            } else {
                                setFlowState(FlowActionType.UpdateTemplateName, e.value);
                            }
                        }}
                    />
                    {packageNameError &&
                        <Typography
                            className={'flow-error-text'}
                            text={t(packageNameError.text)}
                            token={TypographyToken.DesktopDescriptionSm}
                        />
                    }
                </PanelBarItem>

                <PanelBarItem
                    title={'language'}
                    expanded
                >
                    <DropDownList
                        data={availableLanguages}
                        value={availableLanguages.find((language) => language.isoCultureCode === properties?.language)}
                        dataItemKey='isoCultureCode'
                        textField='nativeName'
                        onChange={(e) => setFlowState(FlowActionType.UpdateProperties, {
                            ...properties,
                            language: e.value.isoCultureCode,
                        })}
                    />
                </PanelBarItem>
                <PanelBarItem
                    title={'Document group'}
                    expanded
                >
                    <DropDownList
                        data={workspaces}
                        value={workspaces.find((workspace) => workspace.workspaceId === properties?.documentGroupId)}
                        dataItemKey='workspaceId'
                        textField='workspaceName'
                        onChange={(e) => setFlowState(FlowActionType.UpdateProperties, {
                            ...properties,
                            documentGroupId: e.value.workspaceId,
                        })}
                    />
                </PanelBarItem>
                <PanelBarItem
                    title={'theme'}
                    expanded
                >
                    <DropDownList
                        data={themesProps.themes}
                        value={themesProps.themes.find((theme) => theme.themeId === properties?.themeId)}
                        defaultValue={themesProps.defaultTheme?.themeId}
                        dataItemKey='themeId'
                        textField='name'
                        onChange={(e) => setFlowState(FlowActionType.UpdateProperties, {
                            ...properties,
                            themeId: e.value.themeId,
                        })}
                    />
                </PanelBarItem>
                <PanelBarItem
                    title={'Advanced settings'}
                    className={'c-flow-editor-package-properties-settings-panel-bar'}
                >
                    {isLegalNoticeEnabled && <div className={'c-flow-editor-package-properties-setting'}>
                        <LegalNotice
                            legalNoticeCode={properties?.legalNoticeDefaultCode}
                            legalNoticeText={properties?.legalNoticeDefaultText}
                            onLegalNoticeChange={(legalNotice) => {
                                setFlowState(FlowActionType.UpdateProperties, {
                                    ...properties,
                                    legalNoticeDefaultCode: legalNotice?.legalNoticeCode,
                                    legalNoticeDefaultText: legalNotice?.legalNoticeText,
                                });
                            }}
                        />
                    </div>}
                    {(settings.customizationSettings.isActionUrlExpirationEnabled || settings.customizationSettings.isActionUrlExpirationVisibleInFrontend) &&
                        <div className={'c-flow-editor-package-properties-setting'}>
                            <div className={'c-flow-editor-package-properties-setting-switch-container'}>
                                <Switch
                                    checked={isExpiryDateChecked}
                                    size={'small'}
                                    onChange={() => setIsExpiryDateChecked(!isExpiryDateChecked)}
                                />
                                <Typography
                                    token={TypographyToken.MobileDescriptionMd}
                                    text={t('set-expiry-date')}
                                    className={'c-flow-editor-package-properties-setting-switch-container_label'}
                                />
                            </div>
                            {
                                isExpiryDateChecked && <div>
                                    <FieldWrapper>
                                        <Label>
                                            {t('expiry-date')}
                                        </Label>
                                        <DateTimePicker
                                            min={new Date()}
                                            format='dd/MM/yyyy HH:mm'
                                            value={properties?.expirationTimeStamp ? new Date(properties?.expirationTimeStamp) : null}
                                            onChange={(e) => {
                                                if (e.value) {
                                                    const dateISOString = DateTime.fromJSDate(e.value).toISO();

                                                    setFlowState(FlowActionType.UpdateProperties, {
                                                        ...properties,
                                                        expirationTimeStamp: dateISOString != null ? dateISOString : undefined,
                                                    });
                                                }
                                            }}
                                        />
                                    </FieldWrapper>
                                    {
                                        settings.customizationSettings.automaticReminderSettings.isSendExpirationRemindersVisibleInFrontend  &&
                                        <div className={'c-flow-editor-package-properties-setting-switch-container'}>
                                            <Switch
                                                checked={properties?.expirationReminder?.isSendExpirationRemindersEnabled ? true : false}
                                                size={'small'}
                                                onChange={() => {
                                                    setFlowState(FlowActionType.UpdateProperties, {
                                                        ...properties,
                                                        expirationReminder: {
                                                            isSendExpirationRemindersEnabled: !properties?.expirationReminder?.isSendExpirationRemindersEnabled,
                                                            daysBeforeExpirationReminder: properties?.expirationReminder?.daysBeforeExpirationReminder ?? 1,
                                                        },
                                                    });
                                                }}
                                            />
                                            <Typography
                                                token={TypographyToken.MobileDescriptionMd}
                                                text={t('send-expiration-reminders')}
                                                className={'c-flow-editor-package-properties-setting-switch-container_label'}
                                            />
                                        </div>
                                    }
                                    <FieldWrapper>
                                        <Label>
                                            {t('send-reminder-days-before-expiry')}
                                        </Label>
                                        <Input
                                            value={properties?.expirationReminder?.daysBeforeExpirationReminder}
                                            type='number'
                                            onChange={(e) => {
                                                setFlowState(FlowActionType.UpdateProperties, {
                                                    ...properties,
                                                    expirationReminder: {
                                                        isSendExpirationRemindersEnabled: true,
                                                        daysBeforeExpirationReminder: Number(e.value),
                                                    },
                                                });
                                            }}
                                        />
                                    </FieldWrapper>
                                </div>
                            }
                        </div>}
                    {(settings.customizationSettings.automaticReminderSettings.isSendAutomaticRemindersVisibleInFrontend) &&
                        <div className={'c-flow-editor-package-properties-setting'}>
                            <div className={'c-flow-editor-package-properties-setting-switch-container'}>
                                <Switch
                                    checked={isSendAutomaticRemindersChecked}
                                    size={'small'}
                                    onChange={() => setIsSendAutomaticRemindersChecked(!isSendAutomaticRemindersChecked)}
                                />
                                <Typography
                                    token={TypographyToken.MobileDescriptionMd}
                                    text={t('send-automatic-reminders')}
                                    className={'c-flow-editor-package-properties-setting-switch-container_label'}
                                />
                            </div>
                            {
                                isSendAutomaticRemindersChecked && <div>
                                    <FieldWrapper>
                                        <Label>
                                            {t('days-before-the-first-reminder')}
                                        </Label>
                                        <Input
                                            value={properties?.automaticReminder?.daysBeforeFirstReminder}
                                            type='number'
                                        />
                                    </FieldWrapper>
                                    <FieldWrapper>
                                        <Label>
                                            {t('days-between-consecutiver-reminders')}
                                        </Label>
                                        <Input
                                            value={properties?.automaticReminder?.repeatReminders}
                                            type='number'
                                        />
                                    </FieldWrapper>
                                </div>
                            }
                        </div>}
                    {/* TODO IsSmsOtpAuthenticationVisibleInFrontend  */}
                    <div className={'c-flow-editor-package-properties-setting'}>
                        <div className={'c-flow-editor-package-properties-setting-switch-container'}>
                            <Switch
                                checked={properties?.isSmsOtpAuthenticationEnabled ? true : false}
                                size={'small'}
                                onChange={(e) => {
                                    setFlowState(FlowActionType.UpdateProperties, {
                                        ...properties,
                                        isSmsOtpAuthenticationEnabled: !properties?.isSmsOtpAuthenticationEnabled,
                                    });
                                }}
                            />
                            <Typography
                                token={TypographyToken.MobileDescriptionMd}
                                text={t('enable-sms-otp-authentication')}
                                className={'c-flow-editor-package-properties-setting-switch-container_label'}
                            />
                        </div>
                    </div>
                    {/* TODO IsMailOtpAuthenticationVisibleInFrontend */}
                    <div className={'c-flow-editor-package-properties-setting'}>
                        <div className={'c-flow-editor-package-properties-setting-switch-container'}>
                            <Switch
                                checked={properties?.isMailOtpAuthenticationEnabled ? true : false}
                                size={'small'}
                                onChange={() => {
                                    setFlowState(FlowActionType.UpdateProperties, {
                                        ...properties,
                                        isMailOtpAuthenticationEnabled: !properties?.isMailOtpAuthenticationEnabled,
                                    });
                                }}
                            />
                            <Typography
                                token={TypographyToken.MobileDescriptionMd}
                                text={t('enable-email-otp-authentication')}
                                className={'c-flow-editor-package-properties-setting-switch-container_label'}
                            />
                        </div>
                    </div>
                    {settings.customizationSettings.isDownloadUnsignedFilesVisibleInFrontEnd &&
                        <div className={'c-flow-editor-package-properties-setting'}>
                            <div className={'c-flow-editor-package-properties-setting-switch-container'}>
                                <Switch
                                    checked={properties?.downloadUnsignedFiles ? true : false}
                                    size={'small'}
                                    onChange={() => {
                                        setFlowState(FlowActionType.UpdateProperties, {
                                            ...properties,
                                            downloadUnsignedFiles: !properties?.downloadUnsignedFiles,
                                        });
                                    }}
                                />
                                <Typography
                                    token={TypographyToken.MobileDescriptionMd}
                                    text={t('allow-download-unsigned-documents')}
                                    className={'c-flow-editor-package-properties-setting-switch-container_label'}
                                />
                            </div>
                        </div>}
                    {settings.customizationSettings.isReassignVisibleInFrontEnd &&
                        <div className={'c-flow-editor-package-properties-setting'}>
                            <div className={'c-flow-editor-package-properties-setting-switch-container'}>
                                <Switch
                                    checked={properties?.reassignEnabled ? true : false}
                                    size={'small'}
                                    onChange={() => {
                                        setFlowState(FlowActionType.UpdateProperties, {
                                            ...properties,
                                            reassignEnabled: !properties?.reassignEnabled,
                                        });
                                    }}
                                />
                                <Typography
                                    token={TypographyToken.MobileDescriptionMd}
                                    text={t('allow-all-recipients-to-reassign-package')}
                                    className={'c-flow-editor-package-properties-setting-switch-container_label'}
                                />
                            </div>
                        </div>}
                    {settings.customizationSettings.isActionUrlExpirationVisibleInFrontend &&
                        <div className={'c-flow-editor-package-properties-setting'}>
                            <div className={'c-flow-editor-package-properties-setting-switch-container'}>
                                <Switch
                                    checked={isActionUrlExpirationChecked}
                                    size={'small'}
                                    onChange={() => setIsActionUrlExpirationChecked(!isActionUrlExpirationChecked)}
                                />
                                <Typography
                                    token={TypographyToken.MobileDescriptionMd}
                                    text={t('expire-all-sent-action-links')}
                                    className={'c-flow-editor-package-properties-setting-switch-container_label'}
                                />
                            </div>
                            {
                                isActionUrlExpirationChecked && <div>
                                    <FieldWrapper>
                                        <Label>
                                            {t('expiration-period-days')}
                                        </Label>
                                        <Input
                                            value={properties?.actionUrlExpirationPeriod}
                                            type='number'
                                            onChange={(e) => {
                                                setFlowState(FlowActionType.UpdateProperties, {
                                                    ...properties,
                                                    actionUrlExpirationPeriod: Number(e.value),
                                                });
                                            }}
                                        />
                                    </FieldWrapper>
                                </div>
                            }
                        </div>}
                    {settings.customizationSettings.isRedirectUrlVisibleInFrontend &&
                        <div className={'c-flow-editor-package-properties-setting'}>
                            <div className={'c-flow-editor-package-properties-setting-switch-container'}>
                                <Switch
                                    checked={properties?.isDefaultRedirectUrlEnabled ? true : false}
                                    size={'small'}
                                    onChange={() => {
                                        setFlowState(FlowActionType.UpdateProperties, {
                                            ...properties,
                                            isDefaultRedirectUrlEnabled: !properties?.isDefaultRedirectUrlEnabled,
                                        });
                                    }}
                                />
                                <Typography
                                    token={TypographyToken.MobileDescriptionMd}
                                    text={t('enable-redirect-url')}
                                    className={'c-flow-editor-package-properties-setting-switch-container_label'}
                                />
                            </div>
                            {
                                properties?.isDefaultRedirectUrlEnabled && <div>
                                    <FieldWrapper>
                                        <Label>
                                            {t('enter-valid-url-advanced-settings')}
                                        </Label>
                                        <Input
                                            value={properties?.defaultRedirectUrl}
                                            onChange={(e) => {
                                                setFlowState(FlowActionType.UpdateProperties, {
                                                    ...properties,
                                                    defaultRedirectUrl: e.value,
                                                });
                                            }}
                                        />
                                        {redirectUrlError &&
                                            <Typography
                                                className={'flow-error-text'}
                                                text={t(redirectUrlError.text)}
                                                token={TypographyToken.DesktopDescriptionSm}
                                            />
                                        }
                                    </FieldWrapper>
                                </div>
                            }
                        </div>}
                    {settings.customizationSettings.isCallbackUrlVisibleInFrontend &&
                        <div className={'c-flow-editor-package-properties-setting'}>
                            <div className={'c-flow-editor-package-properties-setting-switch-container'}>
                                <Switch
                                    checked={properties?.isCallbackUrlEnabled ? true : false}
                                    size={'small'}
                                    onChange={() => {
                                        setFlowState(FlowActionType.UpdateProperties, {
                                            ...properties,
                                            isCallbackUrlEnabled: !properties?.isCallbackUrlEnabled,
                                        });
                                    }}
                                />
                                <Typography
                                    token={TypographyToken.MobileDescriptionMd}
                                    text={t('enable-callback-url')}
                                    className={'c-flow-editor-package-properties-setting-switch-container_label'}
                                />
                            </div>
                            {
                                properties?.isCallbackUrlEnabled && <div>
                                    <FieldWrapper>
                                        <Label>
                                            {t('enter-valid-url-advanced-settings')}
                                        </Label>
                                        <Input
                                            value={properties?.callbackUrl}
                                            onChange={(e) => {
                                                setFlowState(FlowActionType.UpdateProperties, {
                                                    ...properties,
                                                    callbackUrl: e.value,
                                                });
                                            }}
                                        />
                                        {callbackUrlError &&
                                            <Typography
                                                className={'flow-error-text'}
                                                text={t(callbackUrlError.text)}
                                                token={TypographyToken.DesktopDescriptionSm}
                                            />
                                        }
                                    </FieldWrapper>
                                </div>
                            }
                        </div>}
                    {settings.customizationSettings.isNotificationCallbackUrlVisibleInFrontend &&
                        <div className={'c-flow-editor-package-properties-setting'}>
                            <div className={'c-flow-editor-package-properties-setting-switch-container'}>
                                <Switch
                                    checked={properties?.isNotificationCallbackUrlEnabled ? true : false}
                                    size={'small'}
                                    onChange={() => {
                                        setFlowState(FlowActionType.UpdateProperties, {
                                            ...properties,
                                            isNotificationCallbackUrlEnabled: !properties?.isNotificationCallbackUrlEnabled,
                                        });
                                    }}
                                />
                                <Typography
                                    token={TypographyToken.MobileDescriptionMd}
                                    text={t('enable-notification-callback-url')}
                                    className={'c-flow-editor-package-properties-setting-switch-container_label'}
                                />
                            </div>
                            {
                                properties?.isNotificationCallbackUrlEnabled && <div>
                                    <FieldWrapper>
                                        <Label>
                                            {t('enter-valid-url-advanced-settings')}
                                        </Label>
                                        <Input
                                            value={properties?.notificationCallbackUrl}
                                            onChange={(e) => {
                                                setFlowState(FlowActionType.UpdateProperties, {
                                                    ...properties,
                                                    notificationCallbackUrl: e.value,
                                                });
                                            }}
                                        />
                                        {notificationCallbackUrlError &&
                                            <Typography
                                                className={'flow-error-text'}
                                                text={t(notificationCallbackUrlError.text)}
                                                token={TypographyToken.DesktopDescriptionSm}
                                            />
                                        }
                                    </FieldWrapper>
                                </div>
                            }
                        </div>}
                    {settings.customizationSettings.isAddInitiatorAsReceiverVisibleInFrontEnd &&
                        <div className={'c-flow-editor-package-properties-setting'}>
                            <div className={'c-flow-editor-package-properties-setting-switch-container'}>
                                <Switch
                                    checked={properties?.addInitiatorAsReceiver ? true : false}
                                    size={'small'}
                                    onChange={(e) => {
                                        setFlowState(FlowActionType.UpdateProperties, {
                                            ...properties,
                                            addInitiatorAsReceiver: !properties?.addInitiatorAsReceiver,
                                        });
                                    }}
                                />
                                <Typography
                                    token={TypographyToken.MobileDescriptionMd}
                                    text={t('add-yourself-as-receiver-of-package')}
                                    className={'c-flow-editor-package-properties-setting-switch-container_label'}
                                />
                            </div>
                        </div>}
                </PanelBarItem>
                {settings.customizationSettings.isEditSignerMessageVisibleInFrontend && <PanelBarItem
                    title={t('email-sent-to-signers')}
                >
                    <NdsButton
                        size={SizesEnums.SMALL}
                        label={'Change email sent for signers'}
                        buttonType={NdsButtonTypesEnum.SECONDARY}
                        buttonStyle={NdsButtonStylesEnum.ALT}
                        leftIcon='fa-light-paper-plane'
                    />
                </PanelBarItem>}
            </PanelBar>
        </div>
    );
}

FlowEditorPackageProperties.Header = () => {
    const { flowType } = useFlowContext();
    const { t } = useTranslation('flow');

    return (
        <div className={'c-flow-editor-right-pane-component-header'}>
            <div className={'c-flow-editor-right-pane-component-header__icon'}>
                <NdsIconFont fontName={'fa-regular-box'} />
            </div>
            <div className={'c-flow-editor-right-pane-component-header__title'}>
                {flowType === FlowType.Package ? t('package-properties') : t('template-properties')}
            </div>
            <div className={'c-flow-editor-right-pane-component-header__action'}></div>
        </div>
    );
};

export default FlowEditorPackageProperties;
