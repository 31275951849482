import { DateTime } from 'luxon';
import { useCallback, useMemo } from 'react';
import { PortalsApi } from '~api/portals.api';
import BulkActionsModal from '~components/bulk-actions-modal';
import { ExtendFormValues } from '~components/bulk-actions-modal/bulkActionsModal';
import { FlowAction } from '~constants';
import { useApi } from '~contexts/api';
import { ModalPortal } from '~contexts/overlay';
import { useTableSelections } from '~contexts/table';
import { Guid } from '~models';
import { PackagesTableDataModel } from '~models/portals.models';
import './documentPortalBulkModal.scss';


export type DocumentPortalBulkActionData = {
    action: FlowAction;
    ids: Guid[];
};

export interface DocumentPortalBulkModalProps {
    bulkActionData?: DocumentPortalBulkActionData;
    onModalClosed: () => void;
}

const DocumentPortalBulkModal = ({
    bulkActionData,
    onModalClosed,
}: DocumentPortalBulkModalProps) => {
    const portalsApi = useApi(PortalsApi);

    const { getSelectedRowsData } = useTableSelections();
    const items = useMemo<{ id: Guid, name: string; }[] | null>(() => {
        if (!bulkActionData) {
            return null;
        }

        return getSelectedRowsData<'packageId', PackagesTableDataModel>()
            .filter(el => bulkActionData.ids.includes(el.packageId))
            .map((el) => ({
                id: el.packageId,
                name: el.documentName,
            }));
    }, [bulkActionData, getSelectedRowsData]);

    const handleOnActionClick = useCallback(async (packageIds: string[], action: FlowAction, data?: ExtendFormValues) => {

        let formattedDateTime;

        if (data) {
            // Could be a util function for extends need to check locales are correct
            const [day, month, year] = data.date.split('/');
            const hours = parseInt(data.hour);
            const minutes = parseInt(data.minutes);

            const dateTime = DateTime.fromObject({
                year: parseInt(year),
                month: parseInt(month),
                day: parseInt(day),
                hour: hours,
                minute: minutes,
            });

            const utcDateTime = dateTime.toUTC();

            formattedDateTime = utcDateTime.toISO()!;
        }


        switch (action) {
            case FlowAction.Extend:
                await portalsApi.runBulkActionForPackageIds({
                    packageIds,
                    action: 'expirationtimestamp',
                    expirationTimestamp: formattedDateTime,
                });

                return;
            case FlowAction.Revoke:
                await portalsApi.runBulkActionForPackageIds({
                    packageIds,
                    action: 'revoke',
                });

                return;
            case FlowAction.RequestAuditTrail:
                await portalsApi.runBulkActionForPackageIds({
                    packageIds,
                    action: 'audittrail',
                    language: 'en',
                });

                return;
            case FlowAction.SendToSigners:
                await portalsApi.runBulkActionForPackageIds({
                    packageIds,
                    action: 'end',
                    target: 'toSigners',
                });

                return;
            case FlowAction.SendReminder:
                await portalsApi.runBulkActionForPackageIds({
                    packageIds,
                    action: 'reminders',
                });

                return;
            case FlowAction.End:
                await portalsApi.runBulkActionForPackageIds({
                    packageIds,
                    action: 'end',
                    target: 'toFinished',
                });

                return;
            case FlowAction.Download:
                await portalsApi.runBulkActionForPackageIds({
                    packageIds,
                    action: 'download',
                });

                return;
            case FlowAction.Delete:
                await portalsApi.runBulkActionForPackageIds({
                    packageIds,
                    action: 'delete',
                });

                return;
        }
    }, [portalsApi]);


    return (
        <ModalPortal visible={items !== null}>
            {items && (
                <BulkActionsModal
                    action={bulkActionData!.action}
                    items={items}
                    handleOnClickAction={handleOnActionClick}
                    handleCloseModal={onModalClosed}
                />
            )}
        </ModalPortal>
    );
};

export default DocumentPortalBulkModal;
