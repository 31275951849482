import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { Navigate, useLocation, useMatch } from 'react-router-dom';
import { ContactsApi } from '~api/contacts.api';
import { CloudContactProviderEnum } from '~constants/cloud-provider';
import { BaseRoutes } from '~constants/routes';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { useShowNotification } from '~contexts/overlay';
import { useSettings } from '~contexts/settings';
import { CloudContactsProviderState } from '~models/cloud.models';
import { AppContext, AppContextType } from './app.context';
import { mapDocumentGroupsToWorkspaces } from './app.helpers';
import { FeatureToggle } from './app.types';
import { useDocumentGroups, useHasDocumentGroupPermissionFunc, useHasPermissionFunc } from '../auth';

export interface AppContextProviderProps {
    children: ReactNode;
}

export function AppContextProvider({ children, ...props }: AppContextProviderProps) {
    const location = useLocation();
    const { t: tContacts } = useTranslation('contacts');

    // navigation & workspaces
    const documentGroups = useDocumentGroups();
    const hasPermissionFunc = useHasPermissionFunc();
    const hasGroupPermissionFunc = useHasDocumentGroupPermissionFunc();

    const matchDocPortalWithId = useMatch(BaseRoutes.DocumentPortal + '/:id');
    const matchTemplatePortalWithId = useMatch(BaseRoutes.TemplatePortal + '/:id');

    const {
        workspaces,
        myWorkspace,
        sharedWorkspaces,
    } = useMemo(() => (
        mapDocumentGroupsToWorkspaces(
            documentGroups, hasPermissionFunc, hasGroupPermissionFunc,
        )
    ), [documentGroups, hasPermissionFunc, hasGroupPermissionFunc]);


    const actualWorkspacePath = (matchDocPortalWithId?.params.id ?? matchTemplatePortalWithId?.params.id) ?? undefined;
    const actualWorkspaceId = actualWorkspacePath ? parseInt(actualWorkspacePath.split('-')[0]) : undefined;
    const [expandedNavWorkspaces, setExpandedNavWorkspaces] = useState<number[]>(actualWorkspaceId ? [actualWorkspaceId] : []);

    const lastPortalPath = useRef<string>('/'); // used when navigating back from fullpage views
    const lastWorkspaceId = useRef<number | null>(
        actualWorkspaceId ?? // initially it takes found id if user entered page directly to some portal which is found
        (
            myWorkspace // if not it takes firstly default workspace if it exists
                ? myWorkspace.workspaceId
                : workspaces.length // or first on the other workspaces list
                    ? workspaces[0].workspaceId
                    : null // without permissions there is no id
        ),
    );

    if (matchDocPortalWithId || matchTemplatePortalWithId) {
        lastPortalPath.current = location.pathname + location.search;
    }
    if (actualWorkspaceId) {
        lastWorkspaceId.current = actualWorkspaceId;
    }

    const toggleNavWorkspace = useCallback((workspaceId: number, forceState?: boolean) => {
        // validate if workspace exist & is accessible = is defined in sharedWorkspaces
        if (!sharedWorkspaces.find(({ workspaceId: id }) => id === workspaceId)) {
            return;
        }
        setExpandedNavWorkspaces(expanded => {
            const add = () => [...expanded, workspaceId];
            const remove = () => expanded.filter((id => id !== workspaceId));

            const isExpanded = expanded.includes(workspaceId);
            const isActuallyUsed = workspaceId === actualWorkspaceId;

            if (forceState !== undefined) { // separately handle forceState
                if (isExpanded && !forceState) {
                    return remove();
                } else if (!isExpanded && forceState) {
                    return add();
                }
            }

            if (isExpanded && !isActuallyUsed) { // collapse only if not actually used
                return remove();
            } else if (!isExpanded) {
                return add();
            }

            return expanded;
        });
    }, [actualWorkspaceId, sharedWorkspaces]);


    // ================================================================================================== CLOUD CONTACTS
    const settings = useSettings();
    const [cloudContactsState, setCloudContactsState] = useState<AppContextType['cloudContactsState']>([
        {
            provider: CloudContactProviderEnum.Google,
            isEnabled: settings.cloudSettings.google.oauthSettings.isEnabled,
            isInitialized: false,
            isAccountLinked: false,
            syncingInProgress: false,
            lastFailedSync: undefined,
            lastSuccessfulSync: undefined,
        },
        {
            provider: CloudContactProviderEnum.Microsoft,
            isEnabled: settings.cloudSettings.microsoft.oauthSettings.isEnabled,
            isInitialized: false,
            isAccountLinked: false,
            syncingInProgress: false,
            lastFailedSync: undefined,
            lastSuccessfulSync: undefined,
        },
    ]);
    const cloudContactsStatusCheckPending = useRef({
        [CloudContactProviderEnum.Microsoft]: false,
        [CloudContactProviderEnum.Google]: false,
    });
    const fireOnSyncNotification = useRef(false);
    const showNotification = useShowNotification();

    const updateCloudContactsState = useCallback((
        provider: CloudContactProviderEnum,
        partial: Partial<CloudContactsProviderState>,
        inStateUpdateCallback?: () => void,
    ) => {
        setCloudContactsState((current) => {
            const idx = current.findIndex(el => el.provider === provider);
            const newState = current.slice();

            newState.splice(idx, 1, {
                ...current[idx],
                ...partial,

            });
            inStateUpdateCallback?.();

            if (
                fireOnSyncNotification.current
                && current.some(el => el.syncingInProgress)
                && newState.every(el => el.syncingInProgress)
            ) {
                fireOnSyncNotification.current = false;
                showNotification({
                    type: 'success',
                    title: tContacts('cloud-contacts-synced'),
                    hideAfterMs: 3000,
                });
            }

            return newState;
        });
    }, [showNotification, tContacts]);

    const contactsApi = useApi(ContactsApi);
    const checkCloudContactsStatus = useCallback((provider?: CloudContactProviderEnum) => {
        for (const state of cloudContactsState) {
            if (provider && provider !== state.provider) {
                return;
            }
            if (!state.isEnabled || cloudContactsStatusCheckPending.current[state.provider] || (state.isInitialized && !state.isAccountLinked)) {
                continue;
            }
            cloudContactsStatusCheckPending.current[state.provider] = true;
            contactsApi.checkStatusCloudProvider({ contactsProvider: state.provider }).then((result) => {
                updateCloudContactsState(state.provider, {
                    isInitialized: true,
                    isAccountLinked: result.isAccountLinked,
                    syncingInProgress: result.isInProgress,
                    lastFailedSync: result.lastFailedSync,
                    lastSuccessfulSync: result.lastSuccessfulSync,
                }, () => {
                    cloudContactsStatusCheckPending.current[state.provider] = false;
                });

                if (result.isInProgress) {
                    setTimeout(() => {
                        checkCloudContactsStatus(state.provider);
                    }, 5000);
                }
            });
        }
    }, [cloudContactsState, contactsApi, updateCloudContactsState]);

    const syncCloudContacts = useCallback((withNotification = false) => {
        for (const state of cloudContactsState) {
            if (state.isInitialized && state.isAccountLinked && !state.syncingInProgress) {
                contactsApi.syncContacts({ contactsProvider: state.provider }).then(() => {
                    updateCloudContactsState(state.provider, { syncingInProgress: true });
                    checkCloudContactsStatus();
                    fireOnSyncNotification.current = withNotification;
                });
            }
        }
    }, [checkCloudContactsStatus, cloudContactsState, contactsApi, updateCloudContactsState]);

    // feature toggles... tbd when needed
    const [features] = useState<FeatureToggle[]>([]);

    const contextValue = useMemo<AppContextType>(() => ({
        actualWorkspaceId,
        actualWorkspacePath,
        workspaces,
        myWorkspace,
        sharedWorkspaces,
        expandedNavWorkspaces,
        toggleNavWorkspace,
        features,
        lastPortalPath: lastPortalPath.current,
        lastWorkspaceId: lastWorkspaceId.current,
        cloudContactsState,
        checkCloudContactsStatus,
        syncCloudContacts,
    }), [
        actualWorkspaceId,
        actualWorkspacePath,
        workspaces,
        myWorkspace,
        sharedWorkspaces,
        expandedNavWorkspaces,
        toggleNavWorkspace,
        features,
        cloudContactsState,
        checkCloudContactsStatus,
        syncCloudContacts,
    ]);

    if (actualWorkspaceId) {
        const base = matchDocPortalWithId ? BaseRoutes.DocumentPortal : matchTemplatePortalWithId ? BaseRoutes.TemplatePortal : undefined;

        if (!base) { // there is workspace id but route doesn't match docportal nor template portal
            return <Navigate to={{ pathname: BaseRoutes.NotFound }} />;
        }

        const found = workspaces.find(({ workspaceId }) => actualWorkspaceId === workspaceId);

        if (!found) { // workspace id not found in available workspaces so user doesn't have access to it and should be redirected to not found
            return <Navigate
                to={{ pathname: BaseRoutes.NotFound }}
                state={{ issuedWorkspaceId: actualWorkspaceId }}
                replace
            />;
        }

        // workspace is accessible but url seems to be different than expected. Let replace that path
        if (actualWorkspacePath !== found.workspaceIdPath) {
            return <Navigate
                to={{
                    pathname: `${base}/${found.workspaceIdPath}`,
                    search: location.search,
                }}
                replace
            />;
        }
    }

    return (
        <AppContext.Provider value={contextValue}>
            {children}
        </AppContext.Provider>
    );
}
