import { AutoCompleteChangeEvent } from '@progress/kendo-react-dropdowns';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import ButtonWithLoader from '~components/button-with-loader';
import { useFlowContext } from '~contexts/flow';
import { useSaveFlowDetails, useSaveFlowWorkflow } from '~contexts/flow/flow.hooks/save.flow.hooks';
import { FlowStep } from '~contexts/flow/flow.types';
import { useTranslation } from '~contexts/i18n';
import Typography, { TypographyToken } from '../../../../typography';
import FlowEditorLeftPaneSearch from '../flow-editor-left-pane-search';
import './flowEditorDocumentsPreview.scss';

export interface FlowEditorDocumentsPreviewProps {

}

function FlowEditorDocumentsPreview(props: FlowEditorDocumentsPreviewProps) {
    const { visibleDocument, setVisibleDocument, flowState: { detailDocuments }, navigateToStep } = useFlowContext();
    const { t } = useTranslation('flow');
    const [showedDocuments, setShowedDocuments] = useState(detailDocuments);
    const [filteredNames, setFilteredNames] = useState(detailDocuments.map(doc => doc.documentName));
    const [isSaving, setLoading] = useState(false);
    const saveDetails = useSaveFlowDetails();
    const saveWorkflow = useSaveFlowWorkflow();

    const manageDocumentsClick = useCallback(async () => {
        setLoading(true);
        try {
            await saveDetails();
            await saveWorkflow();
            navigateToStep(FlowStep.Documents);
        } catch (e) {
            console.warn('errors while saving package state');
        }
    }, [navigateToStep, saveDetails, saveWorkflow]);

    const onSearchChange = useCallback((event: AutoCompleteChangeEvent) => {
        if (!event.value) {
            setShowedDocuments(detailDocuments);
            setFilteredNames(detailDocuments.map(doc => doc.documentName));

            return;
        }
        const filteredDocuments = detailDocuments.filter((doc) =>
            doc.documentName.toLowerCase().search(event.value.toLowerCase()) > -1);

        setShowedDocuments(filteredDocuments);
        setFilteredNames(filteredDocuments.map(el => el.documentName));
    }, [detailDocuments]);

    return (
        <div className={'c-flow-editor-documents-preview c-flow-editor-left-pane-component'}>
            <div className={'c-flow-editor-left-pane-component__search'}>
                <FlowEditorLeftPaneSearch
                    data={filteredNames}
                    placeholder={t('documents-preview-search-placeholder')}
                    onChange={onSearchChange}
                />
            </div>
            <div className={'c-flow-editor-left-pane-component__content'}>
                {(detailDocuments.length > 0 && showedDocuments.length === 0) && (
                    <Typography
                        token={TypographyToken.DesktopDescriptionSm}
                        className={'c-flow-editor-documents-preview__list-no-data'}
                        tagName={'div'}
                        text={t('documents-preview-no-data-for-filter-value')}
                    />
                )}
                <PanelBar>
                    {showedDocuments.map((doc) => {
                        return (
                            <PanelBarItem
                                key={doc.documentId}
                                title={<span title={doc.documentName}>{doc.documentName}</span>}
                                expanded
                            >
                                <div className={'c-flow-editor-documents-preview__thumbnail-wrapper'}>
                                    <Typography
                                        token={TypographyToken.DesktopBylineXs}
                                        text={t('page', { count: doc.pageCount })}
                                        className={'c-flow-editor-documents-preview__page'}
                                        tagName={'div'}
                                    />

                                    <button
                                        type={'button'}
                                        className={classNames(
                                            'c-flow-editor-documents-preview__thumbnail',
                                            { 'c-flow-editor-documents-preview__thumbnail--selected': visibleDocument?.documentId === doc.documentId },
                                        )}
                                        onClick={(event) => {
                                            event.currentTarget.blur();
                                            setVisibleDocument(doc);
                                        }}
                                    >
                                        <img
                                            alt={'document thumbnail'}
                                            src={`data:image/png;base64,${doc.thumbnail}`}
                                            className={'c-flow-editor-documents-preview__thumbnail-image'}
                                        />
                                    </button>
                                </div>
                            </PanelBarItem>
                        );
                    })}
                </PanelBar>
            </div>
            <div className={'c-flow-editor-left-pane-component__add'}>
                <ButtonWithLoader
                    showLoader={isSaving}
                    themeColor={'secondary'}
                    className={'c-flow-editor-left-pane-component__add-btn'}
                    onClick={manageDocumentsClick}
                >{t('documents-preview-add-documents-btn')}</ButtonWithLoader>
            </div>
        </div>
    );
}

export default FlowEditorDocumentsPreview;
