import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { useCallback, useState } from 'react';
import { AuthApi } from '~api/auth.api';
import { GetRegisterCloudUserInfo } from '~api/auth.models';
import Loader from '~components/loader';
import Typography, { TypographyToken } from '~components/typography';
import { BaseRoutes } from '~constants/routes';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { useAsyncEffect } from '~hooks/effects';
import { useNavigateWithQueryParams, useQueryParams } from '~hooks/router';
import { AbortedError } from '~models';
import RegisterCloudForm from './register-cloud-form';
import './register-cloud.view.scss';

export interface RegisterCloudViewProps {
}

type LocationParams = {
    token: string,
    state: string,
    conversionToken: string,
}

function RegisterCloudView() {
    const { t } = useTranslation('unauthorized');
    const { state, token, conversionToken } = useQueryParams<LocationParams>();
    const authApi = useApi(AuthApi);
    const navigate = useNavigateWithQueryParams();
    const [cloudUserData, setCloudUserData] = useState<GetRegisterCloudUserInfo.Result>();
    const [loading, setLoading] = useState(true);

    const goToRegisterPage = useCallback(() => {
        navigate(BaseRoutes.Register, { queryParams: { token } });
    }, [navigate, token]);

    useAsyncEffect(() => async (signal) => {
        if (!state) {
            return navigate(BaseRoutes.Login); // without state this screen won't allow to do anything
        }
        if (cloudUserData) {
            return;
        }
        try {
            const response = await authApi.getRegisterCloudUserInfo({ state }, signal);

            setCloudUserData(response);
            setLoading(false);
        } catch (e) {
            if (e instanceof AbortedError) {
                return;
            }

            // 'normal' error happen probably because of expired state, not a case that could happen in typical flow
            // but let user start again, register will check if token is valid
            goToRegisterPage();
        }
    }, [
        authApi,
        cloudUserData,
        goToRegisterPage,
        navigate,
        state,
        token,
    ]);

    return (
        <div className={'v-register-cloud'}>
            <div className={'v-register__header'}>
                <Typography
                    tagName={'h1'}
                    token={TypographyToken.MobileHeaderLg}
                    mediumScreenToken={TypographyToken.DesktopHeaderLg}
                    text={t('title-register-cloud-account-confirm-data')}
                />
            </div>
            <div className='v-register__content'>
                {loading || !cloudUserData
                    ? (
                        <Loader size={SizesEnums.XLARGE} />
                    )
                    : (
                        <RegisterCloudForm
                            token={token}
                            conversionToken={conversionToken}
                            state={state!}
                            defaultValues={cloudUserData}
                            goToRegisterPage={goToRegisterPage}
                        />

                    )}
            </div>
        </div>
    );
}

export default RegisterCloudView;