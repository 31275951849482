import { Input } from '@progress/kendo-react-inputs';
import { Error } from '@progress/kendo-react-labels';
import { useId } from 'react';
import { useFlowContext, useFlowState, useSetFlowState } from '~contexts/flow';
import { FlowError } from '~contexts/flow/flow.error';
import { FlowActionType } from '~contexts/flow/flow.types';
import { useTranslation } from '~contexts/i18n';
import './flowDocumentsTemplateName.scss';

export interface FlowDocumentsTemplateNameProps {

}

function FlowDocumentsTemplateName(props: FlowDocumentsTemplateNameProps) {
    const id = useId();
    const id2 = useId();
    const { t } = useTranslation('flow');
    const { templateName } = useFlowState();
    const setFlowState = useSetFlowState();
    const { hasError } = useFlowContext();

    const notUnique = hasError(FlowError.TemplateNameNotUnique);

    return (
        <div className={'c-flow-documents-template-name'}>
            <Input
                id={id}
                ariaDescribedBy={id2}
                value={templateName ?? ''}
                onChange={(e) => setFlowState(FlowActionType.SetTemplateName, e.value)}
                placeholder={t('input-template-name')}
                valid={!notUnique}
            />
            <Error id={id2}>{notUnique && t('input-template-name-non-unique-error')}</Error>
        </div>
    );
}

export default FlowDocumentsTemplateName;
