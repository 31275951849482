import { Modal, ModalContent, ModalFooter, NdsButton } from '@gonitro/rcl';
import { BkgModesEnum, NdsButtonTypesEnum, SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types';
import { DateTime } from 'luxon';
import { useCallback, useRef, useState } from 'react';
import BulkActionsModalItem from '~components/bulk-actions-modal-item';
import Typography, { TypographyToken } from '~components/typography';
import { FlowAction, FlowActionI18n } from '~constants';
import { useTranslation } from '~contexts/i18n';
import { Guid, LanguageDataLabel } from '~models';
import ExtendActionBulk from './extend-action-bulk';
import './bulkActionsModal.scss';

export interface BulkActionsModalProps {
    handleOnClickAction: (packageIds: string[], action: FlowAction, data?: ExtendFormValues) => Promise<void>;
    items: { id: Guid, name: string; }[];
    isSigningPortal?: boolean;
    action: FlowAction;
    handleCloseModal: () => void;
}

export type ExtendFormValues = {
    date: string;
    hour: string;
    minutes: string;
};

// Define your ButtonI18n mapping here or import it if defined elsewhere
const ButtonI18n: any = {
    [FlowAction.Delete]: 'bulk-delete-button',
    [FlowAction.Download]: 'bulk-download-button',
    [FlowAction.SendToSigners]: 'bulk-send-to-signers-button',
    [FlowAction.SendReminder]: 'bulk-send-reminder-button',
    [FlowAction.End]: 'bulk-end-button',
    [FlowAction.RequestAuditTrail]: 'bulk-request-audit-trail-button',
    [FlowAction.Revoke]: 'bulk-revoke-button',
    [FlowAction.Extend]: 'bulk-extend-button',
};

function BulkActionsModal({ handleOnClickAction, handleCloseModal, items, action, isSigningPortal }: BulkActionsModalProps) {
    const { t: tBase } = useTranslation('base');
    const { t: tFlow } = useTranslation('flow');

    // Initial state that sets all elements to selected
    const [selectedItems, setSelectedItems] = useState<{ [key: string]: boolean; }>(() => {
        const initialSelectedItems: { [key: string]: boolean; } = {};

        items.forEach(({ id }) => {
            initialSelectedItems[id] = true; // Initialize all actionId as selected
        });

        return initialSelectedItems;
    });

    const formRef = useRef<HTMLFormElement>(null);

    const [isValidForm, setIsValidForm] = useState(false);
    const [error, setError] = useState<LanguageDataLabel<'error'>>();


    // Callback that sets if the form is valid or not for EXTEND action
    const handleIsValidForm = useCallback((isValid: boolean) => {
        setIsValidForm(isValid);
    }, []);

    //Set the number of selected items, needed also for disabling the button
    const itemsCount = Object.values(selectedItems).filter(Boolean).length;

    // Function to handle selection changes from ListItem components.
    const handleSelectionChange = useCallback((id: Guid) => {
        setSelectedItems((prevSelectedItems) => ({
            ...prevSelectedItems,
            [id]: !prevSelectedItems[id],
        }));
    }, []);

    // Function to handle the action click, processes only selected packageItems to emit to parent and close the modal
    const onClickAction = useCallback(
        (data?: ExtendFormValues) => {
            if (data && action === FlowAction.Extend) {
                const { date, hour, minutes } = data;

                const expirationTime = DateTime.fromFormat(`${date} ${hour} ${minutes}`, 'dd/MM/yyyy HH mm').toUTC();

                if (expirationTime.toMillis() < Date.now() && isValidForm) {
                    setError('extend-error-message-date');

                    return;
                }
            }

            setError(undefined);
            const itemsToProcess = Object.entries(selectedItems).filter(([key, value]) => value).map(([key]) => key);

            handleOnClickAction(itemsToProcess, action, data);
            handleCloseModal();

        },
        [
            handleOnClickAction,
            action,
            handleCloseModal,
            selectedItems,
            isValidForm,
        ],
    );

    // Logic for enabling and disabling button in case of Extend action need also the validation form otherwise it checks the itemsCount
    const isFormValid = !(action === FlowAction.Extend && !isValidForm) && itemsCount > 0;

    return (
        <Modal
            headerLabel={tFlow('bulk-label', { action: tFlow(FlowActionI18n[action]) })}
            bkgMode={BkgModesEnum.LIGHT}
            size={SizesEnums.MEDIUM}
            onCloseClick={handleCloseModal}
            withClose={true}
            className={'c-bulk-actions-modal'}
            renderContent={
                <ModalContent>
                    <div>
                        {action === FlowAction.Extend && (
                            <ExtendActionBulk
                                submitForm={(data: ExtendFormValues) => onClickAction(data)}
                                formRef={formRef}
                                handleIsValidForm={handleIsValidForm}
                                error={error}
                            />
                        )}
                        {(action === FlowAction.Download || action === FlowAction.RequestAuditTrail) && (
                            <div className={'c-bulk-actions-modal__info-warn'}>
                                <Typography
                                    className={'c-bulk-actions-modal__aditional-info-text'}
                                    tagName='p'
                                    token={TypographyToken.DesktopDescriptionSm}
                                    text={tFlow('warn-download-action')}
                                />
                            </div>
                        )}
                        <div className={'c-bulk-actions-modal__content'}>
                            <Typography
                                text={tFlow('packages-are-selected-to', {
                                    count: itemsCount,
                                    action: tFlow(FlowActionI18n[action]),
                                })}
                                token={TypographyToken.DesktopDescriptionSm}
                            />
                            {items.map(({ id, name }) => (
                                <BulkActionsModalItem
                                    isSigningPortal={isSigningPortal}
                                    key={id}
                                    onSelectionChange={handleSelectionChange}
                                    isSelected={selectedItems[id]}
                                    actionId={id}
                                    name={name}
                                />
                            ))}
                        </div>
                    </div>
                </ModalContent>
            }
            renderFooter={
                <ModalFooter>
                    <div className={'c-bulk-actions-modal__footer-buttons'}>
                        <NdsButton
                            size={SizesEnums.SMALL}
                            label={tBase('cancel')}
                            buttonType={NdsButtonTypesEnum.NEUTRAL}
                            onClick={handleCloseModal}
                        ></NdsButton>
                        <NdsButton
                            state={isFormValid ? StatesEnum.DEFAULT : StatesEnum.DISABLED}
                            size={SizesEnums.SMALL}
                            label={tFlow(ButtonI18n[action])}
                            onClick={() =>
                                action === FlowAction.Extend ? formRef.current?.requestSubmit() : onClickAction()
                            }
                        ></NdsButton>
                    </div>
                </ModalFooter>
            }
        />
    );
}

export default BulkActionsModal;
