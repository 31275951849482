import { NdsIconFont } from '@gonitro/rcl';
import { SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types';
import classNames from 'classnames';
import { MouseEventHandler, ReactNode, useCallback, useState } from 'react';
import { useDropdown } from '../menu-dropdown.context';
import SubMenu from '../sub-menu/sub-menu';
import './menu-item.scss';


export interface MenuItemProps {
    primaryText?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    icon?: string;
    children?: ReactNode;
    size?: SizesEnums.MEDIUM | SizesEnums.LARGE;
    state?: StatesEnum.DEFAULT | StatesEnum.DISABLED | StatesEnum.SELECTED;
    subMenu?: boolean;
}

function MenuItem({ primaryText, onClick, icon, children, size = SizesEnums.LARGE, subMenu, state }: MenuItemProps) {
    const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

    const { closeDropDown, contentAlign, hasDropdown } = useDropdown();

    const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
        if (onClick) {
            onClick(event);
        }
        hasDropdown && closeDropDown();
    }, [closeDropDown, hasDropdown, onClick]);

    const handleIsSubMenuOpen = useCallback((mousedOver: boolean) => {
        setIsSubMenuOpen(mousedOver);
    }, []);

    return (
        <div
            className={classNames(
                'c-menu-item',
                `c-menu-item__size-${size}`,
                { 'c-menu-item--selected': state === StatesEnum.SELECTED },

            )}
            onMouseOver={() => handleIsSubMenuOpen(true)}
            onMouseLeave={() => handleIsSubMenuOpen(false)}
        >
            {children && subMenu
                ? <div className='c-menu-item__hover-menu'>
                    <div>
                        {icon && <NdsIconFont size={size ? size : SizesEnums.MEDIUM} className='c-menu-item__icon' fontName={icon} />}
                        {primaryText}
                    </div>
                    {subMenu && <NdsIconFont size={SizesEnums.SMALL} fontName={'fa-solid-chevron-right'} />}

                </div> :
                <button
                    type='button'
                    disabled={state === StatesEnum.DISABLED}
                    onClick={handleClick}
                    className={classNames(
                        {
                            'c-menu-item__button': !subMenu,
                            'c-menu-item__sub-item-button': subMenu,
                        },
                        { 'c-menu-item__button--disabled': state === StatesEnum.DISABLED },
                    )}>
                    {icon && <NdsIconFont size={SizesEnums.MEDIUM} className='c-menu-item__icon' fontName={icon} />}
                    {primaryText}
                </button>}
            {!subMenu && (
                <div>{children}</div>
            )}
            {subMenu && isSubMenuOpen && children && (
                <SubMenu isSubMenu={isSubMenuOpen} contentAlign={contentAlign}>
                    {children}
                </SubMenu>
            )}
        </div>
    );
}

export default MenuItem;
