export enum PdfTargetFormat {
    PDF = 1,
    PDFA1A = 2,
    PDFA2A = 3,
}

export const PdfTargetFormatLabel = {
    [PdfTargetFormat.PDF]: 'PDF',
    [PdfTargetFormat.PDFA1A]: 'PDF/A-1',
    [PdfTargetFormat.PDFA2A]: 'PDF/A-2',
};
