import { HttpResponse } from '~lib/http/http.response';
import { CustomError } from '~models';
import { ResponseError } from '~models/errors/response.error';

export class HttpError extends CustomError {
    public response: HttpResponse;
    public body?: ResponseError[] | any;
    public hasJsonBody: boolean = false;
    public reason: string;

    public constructor(response: HttpResponse,
        body: string) {
        const reason = `${response.status} ${response.statusText}`;

        super(`${response.request.method} ${response.request.url} failed: ${reason}`);

        this.response = response;
        try {
            this.body = JSON.parse(body) as ResponseError[];
            this.hasJsonBody = this.body.length > 0;
        } catch {
            this.body = body;
        }
        this.reason = reason;

        Object.setPrototypeOf(this, HttpError.prototype);
    }

    public getErrorCode() {
        return this.hasJsonBody ? (this.body[0] as ResponseError).ErrorCode : undefined;
    }
}
