import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { ReactNode, useMemo, useState } from 'react';
import { SettingsApi } from '~api/settings.api';
import Loader from '~components/loader';
import { useApi } from '~contexts/api';
import { useAuthContext } from '~contexts/auth';
import { useAsyncEffect } from '~hooks/effects';
import { SettingsModel, SigningType, SigningTypes } from '~models';
import { SettingsContext, SettingsContextType } from './settings.context';

export interface SettingsContextProviderProps {
    children: ReactNode;
}

export function SettingsContextProvider({ children, ...props }: SettingsContextProviderProps) {
    const [settings, setSettings] = useState<SettingsModel>();
    const [signingTypes, setSigningTypes] = useState<SigningTypes>([]);
    const { isAuthenticated } = useAuthContext();
    const settingsApi = useApi(SettingsApi);

    useAsyncEffect(() => async (signal) => {
        try {
            if (!isAuthenticated) {
                const newSettings = await settingsApi.readSettings(signal);

                setSettings(newSettings);
                setSigningTypes([]); // need to clear list to make allLoaded condition works, it must be fetched on login
            } else {
                const [newSettings, signingTypes] = await Promise.all([settingsApi.readSettings(signal), settingsApi.readSigningTypes(signal)]);

                setSettings(newSettings);
                setSigningTypes(SigningType.signingTypesMapper(signingTypes, newSettings));
            }

        } catch (e) {
            if (signal.aborted) {
                return;
            }
        }
    }, [isAuthenticated, settingsApi]);

    const contextValue = useMemo(() => ({
        settings,
        signingTypes,
    }), [signingTypes, settings]);

    const allLoaded = contextValue.settings !== undefined && (!isAuthenticated || contextValue.signingTypes.length !== 0);

    if (!allLoaded) {
        return (
            <Loader size={SizesEnums.XLARGE} center />
        );
    }

    return (
        <SettingsContext.Provider value={contextValue as SettingsContextType}>
            {children}
        </SettingsContext.Provider>
    );
}
