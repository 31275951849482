import { NdsIconFont } from '@gonitro/rcl';
import { Badge, BadgeContainer } from '@progress/kendo-react-indicators';
import { useImperativeHandle, useState } from 'react';
import { FlowContextType, useFlowContext } from '~contexts/flow';
import FlowEditorDocumentsPreview from './flow-editor-documents-preview';
import FlowEditorErrors from './flow-editor-errors';
import FlowEditorRecipients from './flow-editor-recipients';
import './flowEditorLeftPane.scss';
import './flowEditorLeftPaneComponent.scss';

export interface FlowEditorLeftPaneRef {
    goToTab: (tab: typeof Tabs[number]['name']) => void;
}

export interface FlowEditorLeftPaneProps {

}

const Tabs = [
    {
        name: 'recipients',
        icon: 'fa-regular-users',
        Component: FlowEditorRecipients,
        iconBadge: null,
        className: null,
    },
    {
        name: 'documents',
        icon: 'fa-regular-files',
        Component: FlowEditorDocumentsPreview,
        iconBadge: null,
        className: null,
    },
    {
        name: 'errors',
        icon: 'fa-regular-circle-exclamation',
        className: (context: FlowContextType) => context.hasError() ? 'c-flow-editor-left-pane__selector-button--error' : '',
        Component: FlowEditorErrors,
        iconBadge: (context: FlowContextType) => {
            return context.hasError() ?
                <Badge themeColor={'error'} size={'small'} style={{ position: 'absolute' }} />
                : null;
        },

    },
] as const;

function FlowEditorLeftPane(props: FlowEditorLeftPaneProps) {
    const [selectedTab, setSelectedTab] = useState(0);
    const context = useFlowContext();

    useImperativeHandle(context.leftPaneRef, () => {
        return {
            goToTab(name) {
                const idx = Tabs.findIndex(el => el.name === name);

                if (idx > -1) {
                    setSelectedTab(idx);
                }
            },
        };
    }, []);

    return (
        <div className={'c-flow-editor-left-pane'}>
            <div className={'c-flow-editor-left-pane__selector'}>
                {context.hasError() && (
                    <div className={'c-flow-editor-left-pane__selector-errors-ghost'} />
                )}
                <div className={'c-flow-editor-left-pane__selector-ghost'} style={{ top: `${selectedTab * 2.5}rem` }} />
                {Tabs.map(({ icon, iconBadge, className }, idx) => (
                    <button
                        type={'button'}
                        key={idx}
                        className={`c-flow-editor-left-pane__selector-button ${className?.(context)}`}
                        onClick={() => setSelectedTab(idx)}
                        disabled={idx === selectedTab}
                    >
                        <BadgeContainer>
                            <NdsIconFont fontName={icon} />
                            {iconBadge?.(context)}
                        </BadgeContainer>
                    </button>
                ))}
            </div>
            <div className={'c-flow-editor-left-pane__content'}>
                {Tabs.map(({ Component }, idx) => (
                    <div
                        key={idx}
                        className={'c-flow-editor-left-pane__content-component'}
                        style={{ left: `${selectedTab * -100}%` }}
                    >
                        <Component />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default FlowEditorLeftPane;
