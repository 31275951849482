export enum UserStatusEnum {
  Active = 'Active',
  InActive = 'InActive',
  Invited = 'Invited',
}

// TODO: create translation labels
// eg.:
// export const UserStatusI18n: Record<UserStatusEnum, LanguageDataLabel<'portal'>> = {
// [UserStatusEnum.Active]: 'user-status-active'
// }
