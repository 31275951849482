import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PortalsApi } from '~api/portals.api';
import QuickActionsModal from '~components/quick-actions-modal';
import QuickActionsTable from '~components/quick-actions-table';
import { QuickAction } from '~components/quick-actions-table/quickActionsTable';
import { DocumentGroupPermission, FlowAction, FlowStatusEnum } from '~constants';
import { BaseRoutes, NestedRoutes } from '~constants/routes';
import { useApi } from '~contexts/api';
import { useWorkspaceId } from '~contexts/app';
import { useHasDocumentGroupPermissionFunc } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { useShowNotification } from '~contexts/overlay';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { LanguageDataLabel } from '~models';
import { TemplateListTableDataModel } from '~models/portals.models';

export interface TemplatePortalQuickActionsProps {
    data: TemplateListTableDataModel;
    onClick: (actionType: FlowAction, data: TemplateListTableDataModel) => void;
}

type ActionFunctions = {
    [key in FlowAction]?: () => Promise<boolean> | void | Promise<void>;
};

const AdditionalInformationI18n: Partial<Record<FlowAction, LanguageDataLabel<'flow'>>> = {
    [FlowAction.Delete]: 'delete-warning-additional-info-template',
    [FlowAction.Duplicate]: 'delete-warning-additional-info-template',
};

const WarningMessageDialogI18n: Partial<Record<FlowAction, LanguageDataLabel<'flow'>>> = {
    [FlowAction.Delete]: 'delete-warning-message-template',
    [FlowAction.Duplicate]: 'delete-warning-message-template',
};

export const TemplateNotificationMessageI18N: any = {
    [FlowAction.Delete]: 'delete-template',
    [FlowAction.Duplicate]: 'duplicate-template',
};

export const TemplateNotificationSuccessMessageI18N:  any= {
    [FlowAction.Delete]: 'delete-template-success-message',
    [FlowAction.Duplicate]: 'duplicate-template-success-message',
};

export const TemplateNotificationSuccessTitleI18N:  any = {
    [FlowAction.Delete]: 'delete-template-success-title',
    [FlowAction.Duplicate]: 'duplicate-template-success-title',
};

export const TemplateNotificationErrorTitleI18N:  any = {
    [FlowAction.Delete]: 'delete-template-error-title',
    [FlowAction.Duplicate]: 'duplicate-template-error-title',
};

function TemplatePortalQuickActions({ data }: TemplatePortalQuickActionsProps) {
    const { t: tNotifications } = useTranslation('notifications');
    const portalsApi = useApi(PortalsApi);
    const navigate = useNavigate();

    const workspaceId = useWorkspaceId()!;
    const hasDocGroupPermission = useHasDocumentGroupPermissionFunc();
    const [quickActionModalData, setQuickActionModalData] = useState<{
        data: TemplateListTableDataModel | undefined,
        actionType: FlowAction | undefined;
    }>({
        data: undefined,
        actionType: undefined,
    });
    const showNotification = useShowNotification();

    const quickActions = useMemo((): QuickAction<FlowAction>[][] => {

        const parentGroup: QuickAction<FlowAction>[][] = [];
        const group1 = [];

        if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.ViewTemplate) && data.status === FlowStatusEnum.Ready && !data.isActionNeeded) {
            group1.push(
                {
                    icon: 'fa-solid-arrow-right',
                    type: FlowAction.Use,
                },
            );
        }
        if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.CreateTemplate)) {
            group1.push(
                {
                    icon: 'fa-solid-edit',
                    type: FlowAction.Edit,
                },
                {
                    icon: 'fa-solid-copy',
                    type: FlowAction.Duplicate,
                },
            );
        }
        if (group1.length) {
            parentGroup.push(group1);
        }

        if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.DeleteTemplate)) {
            parentGroup.push([
                {
                    icon: 'fa-solid-trash',
                    type: FlowAction.Delete,
                },
            ]);
        }

        return parentGroup;
    }, [data.isActionNeeded, data.status, hasDocGroupPermission, workspaceId]);


    const triggerNotification = useCallback((type: string, actionType: FlowAction, data: TemplateListTableDataModel, icon?: string, isUnknown?: boolean) => {
        const notificationMessages: any = {
            pending: {
                type: 'pending',
                icon: icon? icon : 'fa-regular fa-circle-stop',
                title: tNotifications('pending-title'),
                message: tNotifications(TemplateNotificationMessageI18N[actionType], { documentName: data.name }),
                hideAfterMs: 2000,
            },
            success: {
                type: 'success',
                title: tNotifications(TemplateNotificationSuccessTitleI18N[actionType], { documentName: data.name }),
                message: tNotifications(TemplateNotificationSuccessMessageI18N[actionType], { documentName: data.name }),
                hideAfterMs: 2000,
            },
            error: {
                type: 'error',
                title: isUnknown?  tNotifications('unknown-error-title'): tNotifications(TemplateNotificationErrorTitleI18N[actionType], { documentName: data.name }),
                message: isUnknown? tNotifications('unknown-error-message'): null,
                hideAfterMs: 2000,
            },
        };

        showNotification(notificationMessages[type]);
    }, [showNotification, tNotifications]);

    const handleActionClick = useCallback(async(actionType: FlowAction) => {
        closeModal();
        const templateId = data.templateId;
        const actionFunctions: ActionFunctions = {
            [FlowAction.Duplicate]: () => portalsApi.duplicateTemplate({ templateId }),
            [FlowAction.Delete]: () => portalsApi.deleteTemplate({ templateId }),
            [FlowAction.Edit]: () => {
                switch (data.status) {
                    case FlowStatusEnum.ProcessingFailed:
                        navigate(`${BaseRoutes.Template}/${NestedRoutes.Documents}/${templateId}`);
                        break;
                    case FlowStatusEnum.Processing:
                        navigate(`${BaseRoutes.Template}/${NestedRoutes.Processing}/${templateId}`);
                        break;
                    case FlowStatusEnum.Ready:
                    default:
                        navigate(`${BaseRoutes.Template}/${NestedRoutes.Flow}/${templateId}`, { state: { isActionNeeded: data.isActionNeeded } });
                        break;
                }
            },
            [FlowAction.Use]: () => {
                navigate(`${BaseRoutes.Package}/${NestedRoutes.Documents}`, { state: { templateToUse: data } });
            },
        };
        const actionFunc = actionFunctions[actionType];

        if (actionFunc) {
            const iconPending = actionType === FlowAction.Duplicate ? 'fa-regular fa-copy' : 'fa-regular fa-trash';

            if (actionType in TemplateNotificationMessageI18N) {
                triggerNotification('pending', actionType, data, iconPending);
            }
            try{
                const response = await actionFunc();

                if(actionType in TemplateNotificationMessageI18N && response) {
                    setTimeout(() => {
                        triggerNotification('success', actionType, data);
                    }, 2300);
                }
            } catch (e) {
                if(actionType in TemplateNotificationMessageI18N) {
                    setTimeout(() => {
                        triggerNotification('error', actionType, data);
                    }, 2300);
                }
            }
        } else {
            setTimeout(() => {
                triggerNotification('error', actionType, data, undefined, true);
            }, 2300);
            console.error('Unsupported action type:', actionType);
        }
    }, [data, navigate, portalsApi, triggerNotification]);

    const QuickActionsNoModal = [FlowAction.Duplicate, FlowAction.Edit, FlowAction.Use];

    const openQuickActionsModal = (actionType: FlowAction) => {
        if (QuickActionsNoModal.includes(actionType)) {
            handleActionClick(actionType);
        } else {
            setQuickActionModalData({
                data: data,
                actionType: actionType,
            });
        }
    };

    const closeModal = () => {
        setQuickActionModalData({
            data: undefined,
            actionType: undefined,
        });
    };

    return (
        <>
            <QuickActionsTable actions={quickActions} handleActionClick={openQuickActionsModal} />
            <OverlayPortal id='documentPortalQuickActionsModal' type={'modal'} visible={quickActionModalData.data !== undefined}>
                {({ close }) =>
                    <QuickActionsModal
                        data={quickActionModalData.data}
                        actionType={quickActionModalData.actionType}
                        handleCloseModal={() => {
                            closeModal(); close();
                        }}
                        handleClickConfirm={() => {
                            handleActionClick(quickActionModalData.actionType!);
                        }}
                        additionalInformation={AdditionalInformationI18n[quickActionModalData.actionType!]}
                        warningMessage={WarningMessageDialogI18n[quickActionModalData.actionType!]}
                    />
                }
            </OverlayPortal>
        </>
    );
}

export default TemplatePortalQuickActions;
