import { RefObject, useCallback } from 'react';
import { Offset } from '~models';
import { DocumentViewerPagePosition } from '../types';

export function useDocumentViewerScrollTrackerHandler(
    containerRef: RefObject<HTMLDivElement>,
    pagePositions: DocumentViewerPagePosition[],
    onScroll: (inViewportIndexes: number[], fullyVisibleIndexes: number[]) => void,
    pageGutterPx: number,
) {
    return useCallback(({ top }: Offset) => {
        if (!pagePositions.length || !containerRef.current || pagePositions.some(el => !el.size.height)) {
            return; // if no positions are defined or ref is unavailable (it's for typechecking) or some sizes are not ready
        }
        const bottom = top + containerRef.current.clientHeight;

        let i: number = pagePositions.length - 1;

        const inViewportIndexes: number[] = [];
        const fullyVisibleIndexes: number[] = [];

        if (pagePositions[0].offset.top > top) { // first page starts after scroll top position so nothing to lookup
            i = 0;
        } else { // find first visible page index from end (TODO maybe it would be improvement to do search by splitting array to half and half and half... maybe)
            for (; i >= 0; i--) {
                if (top >= (pagePositions[i].offset.top - pagePositions[i].headerHeight - pageGutterPx)) {
                    break;
                }
            }
        }

        for (; i < pagePositions.length; i++) {
            const { offset, size } = pagePositions[i];

            if (offset.top >= top && (offset.top + size.height) <= bottom) {
                fullyVisibleIndexes.push(i);
            }
            inViewportIndexes.push(i);

            if (bottom < (offset.top + size.height)) {
                break;
            }
        }

        onScroll(inViewportIndexes, fullyVisibleIndexes);
    }, [containerRef, onScroll, pageGutterPx, pagePositions]);
}
