import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import { DocumentViewerPaginatorComponent } from '../types';
import './documentViewerPagination.scss';

export interface DocumentViewerPaginationProps extends HTMLAttributes<HTMLDivElement> {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
    Paginator: DocumentViewerPaginatorComponent;
}

function DocumentViewerPagination({
    currentPage,
    totalPages,
    onPageChange,
    Paginator,
    ...props
}: DocumentViewerPaginationProps) {
    return (
        <div {...props} className={classNames('c-document-viewer-pagination', props.className)}>
            <Paginator
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={onPageChange}
            />
        </div>
    );
}

export default DocumentViewerPagination;
