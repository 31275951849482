import { SortDirectionEnum } from '@gonitro/rcl/lib/_types';
import {
    TableColumnSpecificWidth,
    TableDefinition,
    TableFilterValueSubType,
    TableFilterValueType,
} from '~contexts/table/table.types';

export const ContactGroupsTableDefinition: TableDefinition<'id'> = {
    rowIdKey: 'id',
    itemsPerPage: 20,
    itemsPerPageOptions: [20, 50, 100],
    selectable: false,
    pollInterval: 0,
    initialSorting: {
        key: 'name',
        direction: SortDirectionEnum.DESC,
    },
    columns: [
        {
            key: 'name',
            dataKey: 'name',
            i18nLabel: {
                ns: 'contacts',
                key: 'contact-group-table-col-title-name',
            },
            sortable: true,
            width: TableColumnSpecificWidth.Auto,
        },
        {
            key: 'code',
            i18nLabel: {
                ns: 'contacts',
                key: 'contact-group-table-col-title-code',
            },
            sortable: true,
            width: TableColumnSpecificWidth.Auto,
        },
        {
            key: 'type',
            i18nLabel: {
                ns: 'contacts',
                key: 'contact-group-table-col-title-type',
            },
            width: 20,
            sortable: false,
        },
        {
            key: 'action',
            width: TableColumnSpecificWidth.Button,
        },
    ],
    filters: [
        {
            type: TableFilterValueType.Array,
            subType: TableFilterValueSubType.String,
            key: 'types',
            queryParamKey: 'types',
            apiKey: 'types',
        },
        {
            type: TableFilterValueType.Primitive,
            subType: TableFilterValueSubType.String,
            key: 'searchString',
        },
    ],
};
