import { DateFilterEnum } from '@gonitro/rcl/lib/_types/filter';
import { useMemo } from 'react';
import { FlowStatusEnum, FlowStatusI18n } from '~constants';
import { PortalTableFiltersDefinition } from '~containers/portal-table/portal-table-filters/portal-table-filters';
import { useTranslation } from '~contexts/i18n';


const FilterStatuses = [
    FlowStatusEnum.Pending,
    FlowStatusEnum.Received,
    FlowStatusEnum.Signed,
    FlowStatusEnum.Rejected,
    FlowStatusEnum.Archived,
    FlowStatusEnum.Failed,
];

export const useSignerTableFilters = () => {
    const { t: tBase } = useTranslation('base');
    const { t: tFlow } = useTranslation('flow');
    const { t: tPortals } = useTranslation('portals');

    return useMemo<PortalTableFiltersDefinition>(() => {
        return {
            searchAll: {
                type: 'single', // TODO should be multi
                key: 'name',
                placeholder: tBase('search-input-placeholder'),
            },
            dropdowns: [
                {
                    type: 'single',
                    key: 'status',
                    options: FilterStatuses.map(status => ({
                        value: status,
                        label: tFlow(FlowStatusI18n[status]),
                    })),
                    defaultOption: FlowStatusEnum.All,
                    translations: {
                        label: tPortals('status-filter-label'),
                        allItems: tPortals('status-filter-label-all'),
                        none: tPortals('status-filter-label-none'),
                    },
                },
                {
                    type: 'date',
                    key: 'dateRange',
                    options: 'default',
                    defaultOption: DateFilterEnum.ALL_TIME,
                },
            ],
        };
    }, [tBase, tFlow, tPortals]);
};