import { NormalizedDragEvent } from '@progress/kendo-react-common';
import { Dispatch, MutableRefObject, RefObject, SetStateAction, useCallback } from 'react';
import { CommonUtils } from '~lib';
import { Offset, Size } from '~models';
import { DocumentViewerPageObjectHelpers } from './documentViewerPageObject.helpers';
import { DocumentViewerPageModel, DocumentViewerPagePosition } from '../types';

export const useDocumentViewerPageObjectDragCallback = (
    ref: RefObject<HTMLDivElement>,
    containerRef: RefObject<HTMLDivElement>,
    currentPageRef: MutableRefObject<number>,
    dragTresholdExceededRef: MutableRefObject<boolean>,
    pages: DocumentViewerPageModel[],
    justifyPages: boolean,
    objectOffset: Offset,
    setDragged: Dispatch<SetStateAction<boolean>>,
    GRAB_LOCK_TRESHOLD_DISTANCE = 0,
) => {

    return useCallback((
        event: NormalizedDragEvent,
        initialPosition: Offset,
        pagePositions: DocumentViewerPagePosition[],
    ) => {

        if (!ref?.current || !containerRef?.current) {
            return;
        }

        // TODO when mouse goes out of container scrolling is stopped, would be nice to keep scrolling it
        // const rect = containerRef.current!.getBoundingClientRect();
        // const overContainer = {
        //     overTop: event.pageY < rect.top,
        //     overLeft: event.pageX < rect.left,
        //     overBottom: event.pageY > rect.bottom,
        //     overRight: event.pageX > rect.right,
        // };
        // concept: verify if mouse is out of bound (and which)
        // if occurs turn on some interval to scroll container
        // and turn it off when mouse is inside container
        // smoothness should be adjusted experimentally


        const objectSizeLocal: Size = {
            width: CommonUtils.toPx(ref.current.style.width),
            height: CommonUtils.toPx(ref.current.style.height),
        };

        const initialPageIndex = currentPageRef.current;

        /** IMPORTANT - top must be verified before left as it can alter currentPageRef */
        let top = DocumentViewerPageObjectHelpers.getOptimizedTop(
            event.clientY - initialPosition.top + event.scrollY,
            objectSizeLocal,
            currentPageRef,
            pagePositions,
            event.pageY,
            containerRef.current,
        );

        const newPageIndex = currentPageRef.current;
        let newSize: Size | undefined = undefined;

        if (initialPageIndex !== newPageIndex) {
            const initialPage = pages[initialPageIndex];
            const newPage = pages[newPageIndex];

            if (initialPage.scale !== newPage.scale) {
                if (justifyPages) {
                    newSize = {
                        width: objectSizeLocal.width / initialPage.scale * newPage.scale,
                        height: objectSizeLocal.height / initialPage.scale * newPage.scale,
                    };
                } else {

                    newSize = { ...objectSizeLocal };
                }

                const newPagePosition = pagePositions[newPageIndex];

                if (newSize.height > newPagePosition.size.height || newSize.width > newPagePosition.size.width) {
                    let newerScale: number;

                    if (newSize.height / newPagePosition.size.height > newSize.width / newPagePosition.size.width) { // height too big (or more big than width)
                        newerScale = newPagePosition.size.height / newSize.height;
                    } else { // width too big (or more big than height)
                        newerScale = newPagePosition.size.width / newSize.width;
                    }

                    newSize = {
                        width: newSize.width * newerScale,
                        height: newSize.height * newerScale,
                    };
                }
                if (newSize.height !== objectSizeLocal.height || newSize.width !== objectSizeLocal.width) {
                    // recalculate top again for new size
                    top = DocumentViewerPageObjectHelpers.getOptimizedTop(
                        event.clientY - initialPosition.top + event.scrollY,
                        newSize,
                        currentPageRef,
                        pagePositions,
                        event.pageY,
                        containerRef.current,
                    );
                }
            }
        }

        const left = DocumentViewerPageObjectHelpers.getOptimizedLeft(
            event.clientX - initialPosition.left + event.scrollX,
            newSize ?? objectSizeLocal,
            currentPageRef,
            pagePositions,
        );

        if (
            dragTresholdExceededRef.current
            || DocumentViewerPageObjectHelpers.offsetsDistance(objectOffset, {
                top,
                left,
            }) > GRAB_LOCK_TRESHOLD_DISTANCE
        ) {
            dragTresholdExceededRef.current = true;
            setDragged(true);
            ref.current.style.left = `${left}px`;
            ref.current.style.top = `${top}px`;

            if (newSize) {
                ref.current.style.setProperty('--object-width', `${newSize.width}px`);
                ref.current.style.setProperty('--object-height', `${newSize.height}px`);
                ref.current.style.width = `${newSize.width}px`;
                ref.current.style.height = `${newSize.height}px`;
            }
        }
    }, [
        GRAB_LOCK_TRESHOLD_DISTANCE,
        containerRef,
        currentPageRef,
        dragTresholdExceededRef,
        justifyPages,
        objectOffset,
        pages,
        ref,
        setDragged,
    ]);
};
