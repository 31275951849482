import classNames from 'classnames';
import React, { HTMLAttributes, useMemo } from 'react';
import { AnyTypeToFlowEntityType } from '~components/flow/flow-editor/flow-editor-left-pane/flow-editor-recipients/types';
import { FieldTypeIcon } from '~components/flow/flow-util-components';
import Typography, { TypographyToken } from '~components/typography';
import { ActorType } from '~constants';
import { FieldType } from '~constants/field-type';
import { useFlowContext } from '~contexts/flow';
import { FlowEntityType } from '~contexts/flow/flow.types';
import { useTranslation } from '~contexts/i18n';
import { FlowApproverModel, FlowFieldModel } from '~models';
import './flowEditorField.scss';

export interface FlowEditorFieldProps extends HTMLAttributes<HTMLDivElement> {
    element: FlowFieldModel | FlowApproverModel;
    unplaced?: boolean;
    isPressed?: boolean;
    isDragged?: boolean;
    isDragging?: boolean;
}

function FlowEditorField({
    element,
    unplaced,
    isPressed,
    isDragged,
    isDragging,
    className,
    ...rest
}: FlowEditorFieldProps) {
    const { t } = useTranslation('flow');
    const { focusedEntityType, focusedEntityId } = useFlowContext();

    const text = useMemo(() => {
        if ('type' in element) {
            if (element.type === FieldType.SigningField) {
                return t('field-object-label-signature');
            } else if (element.name) {
                return element.name;
            } else {
                switch (element.type) {
                    case FieldType.TextBox:
                        return t('field-object-label-input');
                    case FieldType.CheckBox:
                        return t('field-object-label-check');
                    case FieldType.RadioGroup:
                        return t('field-object-label-radio');
                }
            }
        } else {
            return t('field-object-label-approval');
        }
    }, [element, t]);


    const entityType = 'type' in element ? AnyTypeToFlowEntityType[element.type] : FlowEntityType.Approver;

    return (
        <>
            <div
                key={element.localId}
                data-row={'field'}
                data-id={element.localId}
                className={classNames(
                    'c-flow-editor-field',
                    {
                        'c-flow-editor-field--unplaced': unplaced,
                        'c-flow-editor-field--is-pressed': isPressed,
                        'c-flow-editor-field--is-dragged': isDragged,
                        'c-flow-editor-field--is-dragging': isDragging,
                        'c-flow-editor-field--active': entityType === focusedEntityType && element.localId === focusedEntityId,
                    },
                    className,
                )}
                {...rest}
            >
                <div className={'c-flow-editor-field__icon'}>
                    <FieldTypeIcon type={'type' in element ? element.type : ActorType.Approver} />
                </div>
                <Typography
                    className={'c-flow-editor-field__text'}
                    token={TypographyToken.UiMenuSm}
                    text={text}
                    tagName={'div'}
                />
                <button type={'button'} className={'c-flow-editor-field__drag'} data-drag={'field'}>
                    <i className='fa-solid fa-grip-lines-vertical fa-rotate-90'></i>
                </button>
            </div>
        </>
    );
}

export default FlowEditorField;
