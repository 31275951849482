import { useCallback } from 'react';
import { Control, RegisterOptions, useFormState } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import { InternalFieldName } from 'react-hook-form/dist/types/fields';
import { UseFormRegister, UseFormRegisterReturn } from 'react-hook-form/dist/types/form';
import { FieldPath } from 'react-hook-form/dist/types/path';
import { useTranslateValidationError } from './translate-validation-error';

export type EnhancedRegisterOptions<
    TFieldValues extends FieldValues,
    TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = RegisterOptions<TFieldValues, TFieldName> & {
    fieldLabel?: string; // translated field name to use in validation error labels
    noErrorTranslation?: boolean; // omit error translating
}


export type UseEnhancedFormRegister<TFieldValues extends FieldValues> =
    <TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
        name: TFieldName,
        options?: EnhancedRegisterOptions<TFieldValues, TFieldName>,
    ) => UseEnhancedFormRegisterReturn<TFieldName>;

export interface UseEnhancedFormRegisterReturn<TFieldName extends InternalFieldName
    = InternalFieldName> extends UseFormRegisterReturn<TFieldName> {
    translatedError?: string,
}

export const useEnhancedRegister = <TFieldValues extends FieldValues, TContext = any>(
    register: UseFormRegister<TFieldValues>,
    control: Control<TFieldValues, TContext>,
) => {
    const state = useFormState({ control });
    const translateError = useTranslateValidationError<TFieldValues>();

    return useCallback<UseEnhancedFormRegister<TFieldValues>>((
        inputType,
        options,
    ) => {
        const error = state.errors[inputType];
        const translatedError = translateError(error, options);

        return {
            ...register(inputType, options),
            translatedError,
        };
    }, [register, state.errors, translateError]);
};