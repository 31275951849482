import classNames from 'classnames';
import Typography, { TypographyToken } from '~components/typography';
import { useCurrentLanguage } from '~contexts/i18n';
import { DomUtils } from '~lib';
import { SigningType } from '~models';
import './flowSigningMethodCard.scss';

export interface FlowSigningMethodCardProps {
    signingMethod: SigningType,
    isSelected: boolean,
    isDisabled: boolean,
    onSigningMethodClick: () => void
}

function FlowSigningMethodCard({ signingMethod, isDisabled, isSelected, onSigningMethodClick }: FlowSigningMethodCardProps) {
    const language = useCurrentLanguage();

    return (
        <div className={'c-flow-signing-method-card'}>
            <div
                className={classNames(
                    'c-flow-signing-method-card-content',
                    { 'c-flow-signing-method-card-content--selected': isSelected },
                    { 'c-flow-signing-method-card-content--disabled': isDisabled },
                )}
                onClick={() => {
                    if (!isDisabled) {
                        onSigningMethodClick();
                    }
                }}
            >
                <div className={'c-flow-signing-method-card-content__icon'}>
                    {signingMethod && signingMethod.icon &&
                        <div dangerouslySetInnerHTML={{ __html: DomUtils.b64ToSvg(signingMethod.icon) }} />
                    }
                </div>

                <Typography
                    token={isDisabled ? TypographyToken.MobileDescriptionMd : TypographyToken.MobileDescriptionBoldMd}
                    text={signingMethod?.displayNames[language.languageCode]}
                />
            </div>
        </div>
    );
}

export default FlowSigningMethodCard;
