import { NdsButton } from '@gonitro/rcl';
import { NdsButtonStylesEnum, NdsButtonTypesEnum } from '@gonitro/rcl/lib/_types';
import Typography, { TypographyToken } from '~components/typography';
import { ComboboxContactModel } from '~models/contacts.models';
import './slectedContactToGroupCombobox.scss';

export interface SlectedContactToGroupComboboxProps {
    contact: ComboboxContactModel;
    handleUnselectContact: () => void;
    handleEditContact: () => void;
}

const SlectedContactToGroupCombobox = ({
    contact,
    handleEditContact,
    handleUnselectContact,
}: SlectedContactToGroupComboboxProps) => {
    return (
        <div className='c-slected-contact-to-group-combobox__wrapper'>
            <Typography
                token={TypographyToken.UiFormsLabelSm}
                text={`${contact.firstName ? contact.firstName: ''} ${contact.lastName}`}
                className='c-slected-contact-to-group-combobox__contact-info'
            ></Typography>
            <Typography
                token={TypographyToken.UiFormsLabelSm}
                text={contact.emailAddress}
                className='c-slected-contact-to-group-combobox__contact-info'
            ></Typography>
            <div>
                <NdsButton
                    buttonStyle={NdsButtonStylesEnum.DEFAULT}
                    buttonType={NdsButtonTypesEnum.NEUTRAL}
                    rightIcon='fa-solid-edit'
                    onClick={handleEditContact}
                />
                <NdsButton
                    buttonStyle={NdsButtonStylesEnum.DEFAULT}
                    buttonType={NdsButtonTypesEnum.NEUTRAL}
                    rightIcon='fa-solid-minus-circle'
                    onClick={handleUnselectContact}
                />
            </div>
        </div>
    );
};

export default SlectedContactToGroupCombobox;
