import { CheckboxValueIs, TableCell, TableRow, TableSort } from '@gonitro/rcl';
import { CheckboxDetailedValue, CheckboxValue } from '@gonitro/rcl/lib/checkbox';
import classNames from 'classnames';
import { ChangeEvent, useCallback, useMemo } from 'react';
import { Translation } from '~contexts/i18n';
import { useTableContext, useTableSelections } from '~contexts/table';
import { TableDataGeneric, TableDefinition } from '~contexts/table/table.types';
import { LanguageDataLabel } from '~models';
import { TableBuilderHelpers } from '../tableBuilder.helpers';
import './tableHeaderBuilder.scss';

export interface TableHeaderBuilderProps<TRowIdKey extends string> {
    definition: TableDefinition<TRowIdKey>;
}

function TableHeaderBuilder<TRowIdKey extends string, TData extends TableDataGeneric<TRowIdKey>>({ definition }: TableHeaderBuilderProps<TRowIdKey>) {
    const { pageData, sortConfig, sortChange, isRowSelectable } = useTableContext<TRowIdKey, TData>();

    const selectablePageData = useMemo(() => {
        if (!isRowSelectable) {
            return pageData;
        }

        return pageData.filter(isRowSelectable);
    }, [isRowSelectable, pageData]);

    const { selectedRows, selectRows, unselectRows } = useTableSelections();
    const onSelectAllClick = useCallback((event: ChangeEvent<HTMLInputElement>, value: CheckboxValue) => {
        if (CheckboxValueIs.Truthy(value)) {
            selectRows(selectablePageData.map(el => el[definition.rowIdKey]));
        } else if (CheckboxValueIs.Falsy(value)) {
            unselectRows(pageData.map(el => el[definition.rowIdKey]));
        } else {
            console.warn('something is wrong with selections');
        }
    }, [
        selectRows,
        selectablePageData,
        definition.rowIdKey,
        unselectRows,
        pageData,
    ]);

    const thisPageSelectedRows = useMemo(() => (
        selectedRows.filter(id => pageData.find(el => id === el[definition.rowIdKey]))
    ), [definition.rowIdKey, pageData, selectedRows]);


    const isSelected = thisPageSelectedRows.length === 0
        ? CheckboxDetailedValue.Unchecked
        : thisPageSelectedRows.length === selectablePageData.length
            ? CheckboxDetailedValue.Checked
            : CheckboxDetailedValue.Indeterminate;

    return (
        <TableRow
            className={classNames('c-table-header-builder', definition.headerClassName)}
            selectable={definition.selectable}
            header
            isSelected={isSelected}
            checkboxName='selectAll'
            onRowSelectChange={onSelectAllClick}
        >
            {definition.columns.map((colDef) => {
                const label = colDef.label ?? (colDef.i18nLabel
                    ? <Translation
                        namespace={colDef.i18nLabel.ns}
                        label={colDef.i18nLabel?.key as LanguageDataLabel<typeof colDef.i18nLabel.ns>}
                    />
                    : null);

                return (
                    <TableCell
                        key={colDef.key}
                        header
                        style={{ width: TableBuilderHelpers.colWidth(colDef.width) }}
                    >
                        {label}
                        {colDef.sortable && (
                            <TableSort
                                sortConfig={sortConfig}
                                sortKey={colDef.sortingKey ?? colDef.key}
                                onCellSortClick={sortChange}
                            />
                        )}
                    </TableCell>
                );
            })}
        </TableRow>
    );
}

export default TableHeaderBuilder;