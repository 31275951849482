import { Button, ButtonProps } from '@progress/kendo-react-buttons';
import { Loader, LoaderProps } from '@progress/kendo-react-indicators';
import classNames from 'classnames';
import './buttonWithLoader.scss';

export interface ButtonWithLoaderProps extends ButtonProps {
    showLoader?: boolean;
    loaderProps?: LoaderProps;
}

function ButtonWithLoader({ children, showLoader, className, loaderProps, ...props }: ButtonWithLoaderProps) {
    return (

        <Button {...props} className={classNames(className, 'c-button-with-loader')}>
            <span style={{ visibility: showLoader ? 'hidden' : 'unset' }}>{children}</span>
            {showLoader && <Loader {...loaderProps} className={'c-button-with-loader__loader'} />}
        </Button>
    );
}

export default ButtonWithLoader;
