import { useEffect, useRef, useState } from 'react';
import { UserManagementApi } from '~api/user-management.api';
import { Permission } from '~constants';
import { useApi } from '~contexts/api';
import { LanguageDataLabel } from '~models';

export interface GeneralToggle {
    permission: string;
    label: LanguageDataLabel<'user-management'>;
    hasPermission: boolean;
    disabled: boolean;
    parentId?: string;
    relatedIds?: string[];
}

const useGeneralToggles = (initialToggles: GeneralToggle[], userGroupId?: number) => {
    const [generalToggles, setGeneralToggles] = useState<GeneralToggle[]>(initialToggles);

    const initialResponseToggles = useRef<GeneralToggle[]>();

    const userManagementApi = useApi(UserManagementApi);


    useEffect(() => {
        if (userGroupId) {
            const fetchToggles = async () => {
                const permissionsResponse = await userManagementApi.getUserGroupPermissions({ userGroupId });

                const updatedPermissions = initialToggles.map(toggle => {
                    const matchingPermission = permissionsResponse.find(
                        permissionObj => permissionObj.permission === toggle.permission,
                    );

                    if (matchingPermission) {
                        return {
                            ...toggle,
                            hasPermission: matchingPermission.hasPermission,
                        };
                    }

                    return toggle;
                });

                const updatedDisabledToggles = updateDisabledToggles(updatedPermissions);

                initialResponseToggles.current = updatedDisabledToggles;

                setGeneralToggles(updatedDisabledToggles);
            };

            fetchToggles();
        } else {
            setGeneralToggles(initialToggles);
        }
    }, [userGroupId, initialToggles, userManagementApi]);


    // TODO move this logic into disabled function in later PR
    const handleGeneralToggleChange = (selectedToggle: GeneralToggle): void => {
        setGeneralToggles((prevToggles) => {
            let updatedToggles = prevToggles.map((toggle) =>
                selectedToggle.permission === toggle.permission
                    ? {
                        ...toggle,
                        hasPermission: !toggle.hasPermission,
                    }
                    : toggle);

            // Related shared contact group rule
            if (selectedToggle.permission === Permission.General_ActionCreateSharedContactGroup && !selectedToggle.hasPermission) {
                updatedToggles = updatedToggles.map((toggle) => {
                    if (toggle.relatedIds?.includes('sharedContactGroup')) {

                        return {
                            ...toggle,
                            hasPermission: true,
                        };
                    }

                    return toggle;
                });
            }

            // Related shared contacts rule
            if (selectedToggle.permission === Permission.General_ActionCreateSharedContact && !selectedToggle.hasPermission) {
                updatedToggles = updatedToggles.map((toggle) => {
                    if (toggle.relatedIds?.includes('sharedContacts')){
                        return {
                            ...toggle,
                            hasPermission: true,
                        };
                    }

                    return toggle;
                });
            }

            // delete shared contact group rule
            if (selectedToggle.permission === Permission.General_ActionDeleteSharedContactGroup && !selectedToggle.hasPermission) {
                updatedToggles = updatedToggles.map((toggle) => {
                    if (toggle.permission === Permission.General_ActionViewSharedContactGroup) {
                        return {
                            ...toggle,
                            hasPermission: true,
                        };
                    }

                    return toggle;
                });
            }

            // delete shared contact rule
            if (selectedToggle.permission === Permission.General_ActionDeleteSharedContact && !selectedToggle.hasPermission) {
                updatedToggles = updatedToggles.map((toggle) => {
                    if (toggle.permission === Permission.General_ActionViewSharedContact) {
                        return {
                            ...toggle,
                            hasPermission: true,
                        };
                    }

                    return toggle;
                });
            }

            // Edit shared contact rule
            if (selectedToggle.permission === Permission.General_ActionEditSharedContact && !selectedToggle.hasPermission) {
                updatedToggles = updatedToggles.map((toggle) => {
                    if (toggle.permission === Permission.General_ActionViewSharedContact) {
                        return {
                            ...toggle,
                            hasPermission: true,
                        };
                    }

                    return toggle;
                });
            }

            // Access to Document Portal rule
            if (selectedToggle.permission === Permission.AccessDocumentPortal_ActionChangeKeyPairPerSigningField
                && !selectedToggle.hasPermission) {
                updatedToggles = updatedToggles.map((toggle) =>
                    toggle.permission === selectedToggle.parentId
                        ? {
                            ...toggle,
                            hasPermission: true,
                        }
                        : toggle);
            }

            if (selectedToggle.permission === Permission.AccessDocumentPortal && selectedToggle.hasPermission) {
                updatedToggles = updatedToggles.map((toggle) =>
                    toggle.permission === Permission.AccessDocumentPortal_ActionChangeKeyPairPerSigningField
                        ? {
                            ...toggle,
                            hasPermission: false,
                        }
                        : toggle);
            }

            // Access to User Management rules

            if (selectedToggle.parentId === Permission.AccessUserManagementPortal && !selectedToggle.hasPermission) {
                updatedToggles = updatedToggles.map((toggle) =>
                    toggle.permission === Permission.AccessUserManagementPortal
                        ? {
                            ...toggle,
                            hasPermission: true,
                        }
                        : toggle);
            }

            if (selectedToggle.permission === Permission.AccessUserManagementPortal && selectedToggle.hasPermission) {
                updatedToggles = updatedToggles.map((toggle) =>
                    toggle.parentId === Permission.AccessUserManagementPortal
                        ? {
                            ...toggle,
                            hasPermission: false,
                        }
                        : toggle);
            }


            // Access Settings rule
            if (selectedToggle.permission === Permission.AccessSettings_ActionManageTenant && !selectedToggle.hasPermission) {
                updatedToggles = updatedToggles.map((toggle) =>
                    toggle.permission === selectedToggle.parentId
                        ? {
                            ...toggle,
                            hasPermission: true,
                        }
                        : toggle);
            }

            if (selectedToggle.permission === Permission.AccessSettings && selectedToggle.hasPermission) {
                updatedToggles = updatedToggles.map((toggle) =>
                    toggle.permission === Permission.AccessSettings_ActionManageTenant
                        ? {
                            ...toggle,
                            hasPermission: false,
                        }
                        : toggle);
            }


            updatedToggles = updateDisabledToggles(updatedToggles);

            return updatedToggles;
        });
    };

    const updateDisabledToggles = (updatedToggles: GeneralToggle[]) => {
        updatedToggles = updatedToggles.map((toggle) => {
            const shouldDisableToggle = updatedToggles.some(
                (tog) =>
                    (tog.permission === Permission.General_ActionCreateSharedContactGroup ||
                        tog.permission === Permission.General_ActionEditSharedContactGroup) &&
                    tog.hasPermission,
            );

            if (toggle.permission === Permission.General_ActionViewSharedContact && shouldDisableToggle) {
                return {
                    ...toggle,
                    hasPermission: true,
                    disabled: true,
                };
            }
            if (toggle.permission === Permission.General_ActionViewSharedContact && !shouldDisableToggle) {
                return {
                    ...toggle,
                    disabled: false,
                };
            }

            return toggle;
        });

        updatedToggles = updatedToggles.map((toggle) => {
            const shouldDisableToggle = updatedToggles.some(
                (tog) => tog.relatedIds?.includes('viewDocumentGroups') && tog.hasPermission,
            );

            if (toggle.permission === Permission.AccessUserManagementPortal_ActionViewDocumentGroup && shouldDisableToggle) {
                return {
                    ...toggle,
                    hasPermission: true,
                    disabled: true,
                };
            }
            if (toggle.permission === Permission.AccessUserManagementPortal_ActionViewDocumentGroup && !shouldDisableToggle) {
                return {
                    ...toggle,
                    disabled: false,
                };
            }

            return toggle;
        });

        updatedToggles = updatedToggles.map((toggle) => {
            const shouldDisableToggle = updatedToggles.some(
                (tog) => tog.relatedIds?.includes('viewUsers') && tog.hasPermission,
            );

            if (toggle.permission === Permission.AccessUserManagementPortal_ActionViewUser && shouldDisableToggle) {
                return {
                    ...toggle,
                    hasPermission: true,
                    disabled: true,
                };
            }
            if (toggle.permission === Permission.AccessUserManagementPortal_ActionViewUser && !shouldDisableToggle) {
                return {
                    ...toggle,
                    disabled: false,
                };
            }

            return toggle;
        });

        updatedToggles = updatedToggles.map((toggle) => {
            const shouldDisableToggle = updatedToggles.some(
                (tog) => tog.relatedIds?.includes('viewUserGroups') && tog.hasPermission,
            );

            if (toggle.permission === Permission.AccessUserManagementPortal_ActionViewUserGroup && shouldDisableToggle) {
                return {
                    ...toggle,
                    hasPermission: true,
                    disabled: true,
                };
            }
            if (toggle.permission === Permission.AccessUserManagementPortal_ActionViewUserGroup && !shouldDisableToggle) {
                return {
                    ...toggle,
                    disabled: false,
                };
            }

            return toggle;
        });

        return updatedToggles;
    };

    return {
        generalToggles,
        handleGeneralToggleChange,
        initialResponseToggles,
    };
};

export default useGeneralToggles;
