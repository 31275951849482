import { PropsWithChildren } from 'react';
import { useTranslation } from '~contexts/i18n';
import { FlowCheckboxFieldModel, FlowFieldModel } from '~models';
import { ReactComponent as CheckedSvg } from '~resources/icons/field-checkbox-checked.svg';
import { ReactComponent as UncheckedSvg } from '~resources/icons/field-checkbox-unchecked.svg';
import Typography, { TypographyToken } from '../../../../typography';
import './flowEditorCheckboxFieldObject.scss';

export interface FlowEditorCheckboxFieldObjectProps extends PropsWithChildren {
    data: FlowFieldModel,
}

function FlowEditorCheckboxFieldObject({ data: dataProp, children }: FlowEditorCheckboxFieldObjectProps) {
    const { t } = useTranslation('flow');
    const data = dataProp as FlowCheckboxFieldModel;

    return (
        <div className={'c-flow-editor-checkbox-field-object'}>
            <div className={'c-flow-editor-checkbox-field-object__ghost c-flow-editor-field-object'}>
                <div className={'c-flow-editor-checkbox-field-object__content'}>
                    <Typography
                        className={'c-flow-editor-field-object__text'}
                        token={TypographyToken.UiFormsLabelSm}
                        text={data.name ?? t('field-object-label-check')}
                    />
                </div>
                {children}
            </div>
            <label className={'c-flow-editor-checkbox-field-object__input'}>
                {data.checkedByDefault ? <CheckedSvg /> : <UncheckedSvg />}
            </label>
        </div>
    );
}

export default FlowEditorCheckboxFieldObject;
