import { DateFilterEnum } from '@gonitro/rcl/lib/_types/filter';
import { useMemo } from 'react';
import { PortalTableFiltersDefinition } from '~containers/portal-table/portal-table-filters/portal-table-filters';
import { useTranslation } from '~contexts/i18n';


export const useTemplateTableFilters = () => {
    const { t: tBase } = useTranslation('base');

    return useMemo<PortalTableFiltersDefinition>(() => {
        return {
            searchAll: {
                type: 'single', // TODO should be multi
                key: 'searchString',
                placeholder: tBase('search-input-placeholder'),
            },
            dropdowns: [
                {
                    type: 'date',
                    key: 'dateRange',
                    options: 'default',
                    defaultOption: DateFilterEnum.ALL_TIME,
                },
            ],
        };
    }, [tBase]);
};