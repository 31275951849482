import { Button } from '@progress/kendo-react-buttons';
import { Icon, NormalizedDragEvent, useDraggable } from '@progress/kendo-react-common';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import FlowEditorAddFieldsPopup
    from '~components/flow/flow-editor/flow-editor-left-pane/flow-editor-recipients/flow-editor-add-fields-popup';
import FlowEditorAddRecipientModal
    from '~components/flow/flow-editor/flow-editor-left-pane/flow-editor-recipients/flow-editor-add-recipient-modal';
import FlowEditorField
    from '~components/flow/flow-editor/flow-editor-left-pane/flow-editor-recipients/flow-editor-field';
import { LocalFormFillerModel } from '~components/flow/flow-editor/flow-editor-left-pane/flow-editor-recipients/flowEditorRecipients';
import { AnyTypeToFlowEntityType } from '~components/flow/flow-editor/flow-editor-left-pane/flow-editor-recipients/types';
import { StakeholderDisplayName } from '~components/flow/flow-util-components';
import Typography, { TypographyToken } from '~components/typography';
import { ActorType } from '~constants';
import { FieldType } from '~constants/field-type';
import { FlowError } from '~contexts/flow/flow.error';
import {
    useAddStakeholder,
    useFlowContext,
    useGetStakeholderColorClass,
    useGetStakeholderInfo,
    useRefreshStakeholders,
    useSetFlowState,
    useUndecidedStakeholderId,
} from '~contexts/flow/flow.hooks';
import { useActorElements } from '~contexts/flow/flow.hooks/actor.flow.hooks';
import { FlowActionType, FlowEntityType, FlowGroupType } from '~contexts/flow/flow.types';
import { FlowUtils } from '~contexts/flow/flow.utils';
import { useTranslation } from '~contexts/i18n';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { DomUtils, PromiseUtils } from '~lib';
import {
    ContactBucketItemModel,
    DefinedLocationModel,
    FlowActorModel,
    FlowApproverModel,
    FlowFieldModel,
    FlowFormFillerModel,
    Guid,
    Offset,
    Size,
} from '~models';
import './flowEditorActor.scss';

export type FlowRecipientDragData = {
    dragType: 'actor' | 'field';
    group: FlowGroupType;
    step: number;
    actor: FlowActorModel,
    nestedApprover?: FlowApproverModel;
} & ({
    dragType: 'actor';
    fieldId?: undefined;
} | {
    dragType: 'field';
    fieldId: Guid;
});

export interface FlowEditorRecipientsActorProps {
    actor: Exclude<FlowActorModel, FlowFormFillerModel> | LocalFormFillerModel;
    dragContainer?: HTMLElement | null;
    portalContainer?: HTMLDivElement | null;
    paneContainer?: HTMLElement | null;
    onDragStart?: (group: FlowGroupType, step: number, type: 'actor' | 'field', data: FlowRecipientDragData) => void;
    onDrag?: (mousePos: Offset) => void;
    onDragEnd?: () => void;
    group: FlowGroupType;
    step: number;
    shouldShowAddFieldsPopup?: () => void;
}

function FlowEditorActor({
    actor,
    dragContainer,
    portalContainer,
    paneContainer,
    group,
    step,
    onDragStart: onDragStartProp,
    onDrag: onDragProp,
    onDragEnd: onDragEndProp,
    shouldShowAddFieldsPopup,
}: FlowEditorRecipientsActorProps) {
    const { t } = useTranslation('flow');
    const setFlowState = useSetFlowState();
    const { hasError, focusedEntityType, focusedEntityId, setFocusedEntity, documentViewerRef } = useFlowContext();
    const getStakeholderInfo = useGetStakeholderInfo();
    const stakeholder = getStakeholderInfo(actor.stakeholderLocalId);
    const getColorClass = useGetStakeholderColorClass();
    const elements = useActorElements(actor);

    const {
        unplacedElements,
        placedElements,
        approverElement,
    } = useMemo(() => {
        const unplacedElements: FlowFieldModel[] = [];
        const placedElements: (FlowFieldModel)[] = [];
        let approverElement: FlowApproverModel | undefined = undefined;

        if (actor.actorType === ActorType.Approver) {
            approverElement = actor;
        }
        if (actor.actorType === ActorType.FormFiller && actor.approverActor) {
            approverElement = actor.approverActor;
        }
        for (const el of elements) {
            if (el.location || (el.type === FieldType.RadioGroup && el.options.every(opt => opt.location))) {
                placedElements.push(el);
            } else {
                unplacedElements.push(el);
            }
        }

        return {
            unplacedElements,
            placedElements,
            approverElement,
        };
    }, [actor, elements]);

    const [addFieldsPopoverInfo, setAddFieldsPopoverInfo] = useState<FlowActorModel>();

    useEffect(() => {
        if (focusedEntityId !== stakeholder?.localId) {
            setAddFieldsPopoverInfo(undefined);
        }
    }, [focusedEntityId, stakeholder?.localId]);

    useEffect(() => {
        if (shouldShowAddFieldsPopup) {
            setAddFieldsPopoverInfo(actor);
            shouldShowAddFieldsPopup();
        }
    }, [shouldShowAddFieldsPopup, actor]);

    const ref = useRef<HTMLDivElement>(null);

    const [pressed, setPressed] = useState<boolean>(false);
    const [dragged, setDragged] = useState<boolean>(false);
    const [relativeOffset, setRelativeOffset] = useState<Offset | null>(null);
    const unplacedElementDragHelpers = useRef<Record<string, any>>({});
    const [position, setPosition] = useState<Offset>({
        left: 0,
        top: 0,
    });
    const [initialPosition, setInitialPosition] = useState<Offset>();
    const [size, setSize] = useState<Size>({
        width: 0,
        height: 0,
    });

    const dragSourceId = useRef<string>();
    const draggedElement = useRef<FlowFieldModel | FlowApproverModel>();
    const draggingUnplaced = useRef(false);
    const dragType = useRef<'actor' | 'field'>();

    const onPress = useCallback((event: NormalizedDragEvent) => {
        let isActor = false;
        let isField = false;
        let buttonType: 'actor' | 'field' | undefined = undefined;
        let sourceId: string | undefined = undefined;
        let field: FlowFieldModel | FlowApproverModel | undefined = undefined;

        DomUtils.findParent(event.originalEvent.target as HTMLElement, (element: HTMLElement) => {
            const dataSet = (element as HTMLElement).dataset;

            if (dataSet.drag === 'actor') {
                buttonType = 'actor';
            }
            if (dataSet.drag === 'field') {
                buttonType = 'field';
            }
            if (dataSet.row === 'actor') {
                sourceId = dataSet.id!;
                isActor = true;
            }
            if (dataSet.row === 'field') {
                sourceId = dataSet.id!;
                isField = true;
            }
            if (dataSet.button === 'addStakeholder') {
                setAddRecipientType(group);

                return true;
            }

            return isField || isActor;
        }, event.originalEvent.currentTarget as HTMLElement);

        if (isActor) {
            if (!buttonType) {
                setAddFieldsPopoverInfo((val) => val ? undefined : actor);
            } else {
                setAddFieldsPopoverInfo(undefined);
            }

            if (stakeholder) {
                setFocusedEntity(FlowEntityType.Stakeholder, stakeholder?.localId, { selectedActor: actor.localId });
            }
        }
        if (isField) {
            switch (actor.actorType) {
                case ActorType.Approver:
                    field = actor;
                    setFocusedEntity(FlowEntityType.Approver, actor.localId);
                    break;
                case ActorType.Signer:
                case ActorType.FormFiller: {
                    if (approverElement && approverElement.localId === sourceId) {
                        field = approverElement;
                        setFocusedEntity(FlowEntityType.Approver, approverElement.localId);
                    } else {
                        const element = elements.find(el => el.localId === sourceId)!;

                        field = element;

                        if (!element.location) {
                            draggingUnplaced.current = true;
                        } else if (!buttonType) { // if just clicked on placed field (not dragging)
                            documentViewerRef.current?.scrollToPageObject(element.localId);
                        }
                        setFocusedEntity(AnyTypeToFlowEntityType[element.type], element.localId);
                    }
                    break;
                }
            }
        }

        if (!buttonType || !sourceId || !ref.current) {
            return;
        }

        const source = buttonType === 'actor' ? ref.current : ref.current.querySelector(`[data-id="${sourceId}"][data-row="field"]`);

        if (!source) {
            return;
        }
        dragSourceId.current = sourceId;
        draggedElement.current = field;
        dragType.current = buttonType;
        setPressed(true);

        const rect = source.getBoundingClientRect();

        setSize({
            width: rect.width,
            height: rect.height,
        });
        setRelativeOffset({
            left: event.clientX - rect.left,
            top: event.clientY - rect.top,
        });

        setPosition({
            left: rect.left,
            top: rect.top,
        });
        setInitialPosition({
            left: rect.left,
            top: rect.top,
        });
        onDragStartProp?.(group, step, buttonType, {
            dragType: buttonType,
            actor,
            fieldId: buttonType === 'field' ? sourceId : undefined,
            group,
            step,
            nestedApprover: approverElement,
        });
        setDragged(true);
    }, [
        actor,
        approverElement,
        documentViewerRef,
        elements,
        group,
        onDragStartProp,
        setFocusedEntity,
        stakeholder,
        step,
    ]);

    const onDrag = useCallback((event: NormalizedDragEvent) => {
        if (!dragSourceId.current || !relativeOffset || !initialPosition) {
            return;
        }
        const dragRect = dragContainer!.getBoundingClientRect();

        if (draggingUnplaced.current) {
            const leftStepOrViewerMargin = dragRect.right;
            const stepsMinTop = dragRect.top;
            const stepsMaxTop = dragRect.bottom - size.height;

            let newLeft = Math.max(initialPosition.left, event.clientX - relativeOffset.left);
            let newTop = event.clientY - relativeOffset.top;

            const calcOverViewer = () => {
                if (documentViewerRef.current?.container) {
                    const viewerRect = documentViewerRef.current.container.getBoundingClientRect();

                    newLeft = Math.min(Math.max(newLeft, viewerRect.left), viewerRect.right - size.width);
                    newTop = Math.min(Math.max(newTop, viewerRect.top), viewerRect.bottom - size.height);
                }
                onDragProp?.({
                    left: 0,
                    top: 0,
                });
            };
            const calcOverSteps = () => {
                if (Math.abs(newTop - initialPosition.top) < 16) {
                    newTop = initialPosition.top;
                }
                if (Math.abs(newLeft - initialPosition.left) < 16) {
                    newLeft = initialPosition.left;
                }
                newTop = Math.min(Math.max(newTop, dragRect.top), dragRect.top + dragRect.height - 32);
                onDragProp?.({
                    left: initialPosition.left,
                    top: event.pageY,
                });
            };

            if (newLeft > leftStepOrViewerMargin) { // over docviewer
                unplacedElementDragHelpers.current.overViewer = true;
                calcOverViewer();
            } else if (newTop < stepsMinTop || newTop > stepsMaxTop) {
                if (unplacedElementDragHelpers.current.overViewer) {
                    calcOverViewer();
                } else {
                    calcOverSteps();
                }
            } else {
                unplacedElementDragHelpers.current.overViewer = false;
                calcOverSteps();
            }

            unplacedElementDragHelpers.current.pos = {
                left: newLeft,
                top: newTop,
            };
            setPosition({
                left: newLeft,
                top: newTop,
            });

        } else {

            let newTop = Math.min(Math.max(event.clientY - relativeOffset.top, dragRect.top), dragRect.top + dragRect.height - 32);

            if (Math.abs(newTop - initialPosition.top) < 16) {
                newTop = initialPosition.top;
            }
            setPosition((current) => ({
                left: current.left,
                top: newTop,
            }));
            onDragProp?.({
                left: event.pageX,
                top: event.pageY,
            });
        }
    }, [
        documentViewerRef,
        dragContainer,
        initialPosition,
        onDragProp,
        relativeOffset,
        size.height,
        size.width,
    ]);

    const placeUnplacedField = useCallback(() => {
        if (!documentViewerRef.current) {
            return;
        }
        const rect = documentViewerRef.current.container?.getBoundingClientRect();

        if (!rect) {
            return;
        }
        const pageInfo = documentViewerRef.current.getPageFromVisiblePosition({
            left: unplacedElementDragHelpers.current.pos.left - rect.left,
            top: unplacedElementDragHelpers.current.pos.top - rect.top,
        });


        if (pageInfo && draggedElement.current && 'type' in draggedElement.current) {
            const el = draggedElement.current;
            const size = FlowUtils.fieldDefaultSize(el.type);
            const newPos = { ...pageInfo.onPagePos };

            if (newPos.left + size.width > pageInfo.originalSize.width) {
                newPos.left = pageInfo.originalSize.width - size.width;
            }
            if (newPos.top + size.height > pageInfo.originalSize.height) {
                newPos.top = pageInfo.originalSize.height - size.height;
            }
            const location: DefinedLocationModel = {
                pageNumber: pageInfo.pageIndex + 1,
                documentNumber: pageInfo.documentIndex + 1,
                width: size.width,
                height: size.height,
                left: newPos.left,
                top: newPos.top,
                isLocked: false,
            };

            if (el.type === FieldType.RadioGroup) {
                for (const opt of el.options) {
                    setFlowState(FlowActionType.MovePageObject, {
                        localId: el.localId,
                        optionId: opt.localId,
                        type: el.type,
                        position: location,
                    });
                }
            } else {
                setFlowState(FlowActionType.MovePageObject, {
                    localId: el.localId,
                    type: el.type,
                    position: location,
                });
            }

        }
    }, [documentViewerRef, setFlowState]);

    const onRelease = useCallback((event: NormalizedDragEvent) => {
        if (draggingUnplaced.current && unplacedElementDragHelpers.current.overViewer) {
            placeUnplacedField();
        }
        draggingUnplaced.current = false;
        dragSourceId.current = undefined;
        draggedElement.current = undefined;
        unplacedElementDragHelpers.current = {};
        dragType.current = undefined;
        setDragged(false);
        setRelativeOffset(null);
        setPressed(false);
        setInitialPosition(undefined);
        onDragEndProp?.();
    }, [onDragEndProp, placeUnplacedField]);

    useDraggable(ref, {
        onPress,
        onDrag,
        onRelease,
    });


    const [addRecipientType, setAddRecipientType] = useState<FlowGroupType>();

    const addStakeholderCallback = useAddStakeholder();
    const refreshStakeholdersCallback = useRefreshStakeholders();
    const undecidedId = useUndecidedStakeholderId();

    const addRecipientCallback = useCallback(async (type: FlowGroupType, refreshStakeholder: boolean, contact?: ContactBucketItemModel) => {
        setAddRecipientType(undefined); // must be before await
        let stakeholderLocalId = undecidedId;

        if (contact) {
            stakeholderLocalId = await addStakeholderCallback(contact);
        }
        await PromiseUtils.wait();

        setFlowState(FlowActionType.SetActorStakeholder, {
            actorId: actor.localId,
            stakeholderId: stakeholderLocalId,
        });

        if (refreshStakeholder) {
            await refreshStakeholdersCallback();
        }
    }, [
        actor.localId,
        addStakeholderCallback,
        refreshStakeholdersCallback,
        setFlowState,
        undecidedId,
    ]);

    const Content = () => {
        return (
            <>
                <div
                    data-row={'actor'}
                    data-id={actor.localId}
                    className={classNames('c-flow-editor-actor__row c-flow-editor-actor__row--stakeholder',
                        {
                            'c-flow-editor-actor__row--active': FlowEntityType.Stakeholder === focusedEntityType && stakeholder?.localId === focusedEntityId,
                            'c-flow-editor-actor__row--receiver': actor.actorType === ActorType.Receiver,
                        })}
                >
                    {stakeholder && (
                        <>
                            <Typography
                                token={TypographyToken.DesktopDescriptionBoldSm}
                                className={'c-flow-editor-actor__row-text'}
                            >
                                <StakeholderDisplayName stakeholder={stakeholder} />
                            </Typography>
                            {hasError(FlowError.MissingSmsOtpNumber, stakeholder.localId) && (
                                <div className={'c-flow-editor-actor__row-status c-flow-editor-actor__row-status--error'}>
                                    <Icon className={'fa-solid fa-circle fa-2xs'} />
                                </div>
                            )}
                        </>
                    )}
                    {!stakeholder && (
                        <>
                            <div className={'c-flow-editor-actor__row-icon'}>
                                <Button
                                    data-button={'addStakeholder'}
                                    iconClass={'fa-solid fa-circle-plus'}
                                    fillMode={'flat'}
                                    size={'small'}
                                />
                            </div>
                            <Typography
                                className={'c-flow-editor-actor__row-text'}
                                token={TypographyToken.DesktopDescriptionBoldSm}
                                text={t('add-recipient-btn')}
                            />
                        </>
                    )}
                    {actor.actorType !== ActorType.Receiver && (
                        <button data-drag={'actor'} type={'button'} className={'c-flow-editor-actor__row-drag'}>
                            <i className='fa-solid fa-grip-lines'></i>
                        </button>
                    )}
                </div>
                {unplacedElements.length > 0 && (
                    <>
                        <Typography
                            className={'c-flow-editor-actor__row c-flow-editor-actor__row--unplaced-text'}
                            token={TypographyToken.MobileTagSm}
                            tagName={'div'}
                            text={t('unplaced-fields')}
                        />
                        <div className={'c-flow-editor-actor__unplaced-fields'}>
                            {unplacedElements.map((el, idx) => {
                                return (
                                    <FlowEditorField
                                        key={el.localId}
                                        element={el}
                                        unplaced
                                        isPressed={pressed && draggedElement.current?.localId === el.localId}
                                        isDragged={dragged && draggedElement.current?.localId === el.localId}
                                    />
                                );
                            })}
                        </div>
                    </>
                )}
                {(placedElements.length > 0 || approverElement) && (
                    <div className={'c-flow-editor-actor__placed-fields'}>
                        {placedElements.map((el, idx) => {
                            return (
                                <FlowEditorField
                                    key={el.localId}
                                    element={el}
                                    isPressed={pressed && draggedElement.current?.localId === el.localId}
                                    isDragged={dragged && draggedElement.current?.localId === el.localId}
                                />
                            );
                        })}
                        {approverElement && (
                            <FlowEditorField
                                element={approverElement}
                                isPressed={pressed && draggedElement.current?.localId === approverElement.localId}
                                isDragged={dragged && draggedElement.current?.localId === approverElement.localId}
                            />
                        )}
                    </div>
                )}
            </>
        );
    };

    return (
        <>
            <div
                ref={ref}
                className={classNames(
                    'c-flow-editor-actor',
                    getColorClass(actor.stakeholderLocalId), {
                        'c-flow-editor-actor--is-pressed': dragType.current === 'actor' && pressed,
                        'c-flow-editor-actor--is-dragged': dragType.current === 'actor' && dragged,
                    },
                )}
            >
                <Content />
                <FlowEditorAddFieldsPopup
                    actor={addFieldsPopoverInfo}
                    anchor={ref.current}
                    hide={() => setAddFieldsPopoverInfo(undefined)}
                    appendTo={portalContainer}
                    paneContainer={paneContainer}
                />
            </div>
            {dragType.current === 'actor' && dragged && createPortal((
                <div
                    className={classNames(
                        'c-flow-editor-actor c-flow-editor-actor--is-pressed c-flow-editor-actor--is-dragging',
                        getColorClass(actor.stakeholderLocalId),
                    )}
                    style={{
                        'top': position.top,
                        'left': position.left,
                        'width': size.width,
                        'height': size.height,
                    }}
                >
                    <Content />
                </div>
            ), portalContainer!)}
            {dragType.current === 'field' && dragged && createPortal((
                <FlowEditorField
                    element={draggedElement.current!}
                    isPressed={true}
                    isDragging={true}
                    style={{
                        'top': position.top,
                        'left': position.left,
                        'width': size.width,
                        'height': size.height,
                    }}
                    className={getColorClass(actor.stakeholderLocalId)}
                />
            ), portalContainer!)}
            {!stakeholder && (
                <OverlayPortal type={'modal'} visible={!!addRecipientType}>
                    {() => <FlowEditorAddRecipientModal
                        type={addRecipientType}
                        fixedType
                        onCloseClick={() => setAddRecipientType(undefined)}
                        addClickCallback={addRecipientCallback}
                    />}
                </OverlayPortal>
            )}
        </>
    );
}

export default FlowEditorActor;
