import { useCallback } from 'react';
import { PortalsApi } from '~api/portals.api';
import { GetActionTokensModel } from '~api/portals.models';
import { FlowAction } from '~constants';
import { useApi } from '~contexts/api';
import { AppUrlsUtils } from '~lib/app-urls.utils';
import { LanguageDataLabel } from '~models';
import { SigningSessionListItemModel, SigningSessionTableDataModel } from '~models/portals.models';
import { useRedirectFunc } from './redirect';

type ActionFunctions = {
    [key in FlowAction]?: () => Promise<boolean> | Promise<void>;
};


export const useSignerQuickActions = ( closeModal: () => void, data?: SigningSessionTableDataModel) => {
    const redirectFunc = useRedirectFunc();
    const portalsApi = useApi(PortalsApi);


    // Define QuickActionsNoModal as an array of actions that don’t require a modal
    const QuickActionsNoModal = [
        FlowAction.Edit,
        FlowAction.Download,
        FlowAction.RequestAuditTrail,
        FlowAction.Preview,
        FlowAction.Sign,
    ];

    const AdditionalInformationI18n: Partial<Record<FlowAction, LanguageDataLabel<'flow'>>> = {
        [FlowAction.Delete]: 'delete-warning-additional-info',
        [FlowAction.SoftDelete]: 'delete-warning-additional-info',
    };


    const WarningMessageDialogI18n: Partial<Record<FlowAction, LanguageDataLabel<'flow'>>> = {
        [FlowAction.Delete]: 'delete-warning-message',
        [FlowAction.SoftDelete]: 'delete-warning-message',
    };


    const getTokensAndRedirect = useCallback(
        async (data: SigningSessionListItemModel, actionType: FlowAction) => {
            const { actionId, personId, packageId } = data;
            try {
                const actionMap: any = {
                    [FlowAction.Approve]: 'Approve',
                    [FlowAction.Sign]: 'Sign',
                    [FlowAction.FillOutForms]: 'FormFill',
                    [FlowAction.Preview]: 'Preview',
                };
                const action: string = actionMap[actionType];

                const response: GetActionTokensModel.Result = await portalsApi.getActionTokens({ actionId });

                let url = AppUrlsUtils.getBaseRedirectUrl(action, actionId, personId, response.token, response.expiryTag);

                if (actionType === FlowAction.Preview) {
                    url += '&preview=true';
                } else if (actionType === FlowAction.Download) {
                    url = AppUrlsUtils.getDownloadUrl(packageId, response.token, response.expiryTag);
                }

                redirectFunc(url);
            } catch (error) {
                console.error('Error fetching tokens:', error);
            }
        },
        [portalsApi, redirectFunc],
    );

    const runQuickActionClick = useCallback(
        (actionType: FlowAction) => {
            closeModal();

            if(data) {
                const packageId = data.packageId;
                const actionMap: ActionFunctions = {
                    [FlowAction.Delete]: () => portalsApi.softDeletePackage({ packageId }),
                    [FlowAction.Download]: () => getTokensAndRedirect(data, actionType),
                    [FlowAction.Approve]: () => getTokensAndRedirect(data, actionType),
                    [FlowAction.FillOutForms]: () => getTokensAndRedirect(data, actionType),
                    [FlowAction.Preview]: () => getTokensAndRedirect(data, actionType),
                    [FlowAction.Sign]: () => getTokensAndRedirect(data, actionType),
                };
                const actionFunc = actionMap[actionType];

                if (actionFunc) {
                    actionFunc().catch((error: string) => console.error('Error executing action:', error));
                } else {
                    console.error('Unsupported action type:', actionType);
                }
            }
        },
        [closeModal, data, getTokensAndRedirect, portalsApi],
    );

    return {
        QuickActionsNoModal,
        AdditionalInformationI18n,
        WarningMessageDialogI18n,
        getTokensAndRedirect,
        runQuickActionClick,
    };
};