import { NdsBadge, NdsIconFont, SidePanel } from '@gonitro/rcl';
import { NdsBadgeTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import { SvgIcon } from '@progress/kendo-react-common';
import { ComboBox, ComboBoxChangeEvent, ListItemProps } from '@progress/kendo-react-dropdowns';
import { Input, InputPrefix, Switch } from '@progress/kendo-react-inputs';
import { searchIcon } from '@progress/kendo-svg-icons';
import { cloneElement, useCallback, useRef, useState } from 'react';
import { Controller, FormProvider } from 'react-hook-form';
import { PortalsApi } from '~api/portals.api';
import { UserManagementApi } from '~api/user-management.api';
import NavTabs from '~components/nav-tabs';
import Typography, { TypographyToken } from '~components/typography';
import { DocumentGroupPermission, DocumentGroupPermissionCodes, manageDocumentGroupsPanelTabs, ManageDocumentGroupsPanelTabsEnum } from '~constants';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { useAsyncEffect } from '~hooks/effects';
import { useEnhancedForm } from '~hooks/enhanced-form';
import useNavTabs from '~hooks/tabs';
import { useVirtualizedCombobox } from '~hooks/virtualizedCombobox';
import { PageableListParams } from '~models/pageable-list.models';
import { DocumentGroupThemesModel, UserGroupsListItemModel } from '~models/user-management.models';
import Footer from './footer/footer';
import './manage-document-groups-panel.scss';

interface ManageDocumentGroupsPanelProps {
    documentGroupName?: string;
    documentGroupId?: number;
    initialUserGroups?: UserGroupsListItemModel[];
    initialThemes?: {
        defaultTheme: DocumentGroupThemesModel;
        themes: DocumentGroupThemesModel[];
    };
    onClosePanel: () => void;
}

export type FormValues = {
    name: string;
}

interface hardCodedPermissionsType {
    hasPermission: boolean;
    permissionId: number;
    permission: DocumentGroupPermissionCodes;
}

const hardCodedPermissions = [
    {
        hasPermission: false,
        permissionId: 1,
        permission: [DocumentGroupPermission.Upload],
    },
    {
        hasPermission: false,
        permissionId: 2,
        permission: [DocumentGroupPermission.Sign],
    },
    {
        hasPermission: false,
        permissionId: 4,
        permission: [DocumentGroupPermission.Download],
    },
    {
        hasPermission: false,
        permissionId: 8,
        permission: [DocumentGroupPermission.Delete],
    },
    {
        hasPermission: false,
        permissionId: 16,
        permission: [DocumentGroupPermission.View],
    },
    {
        hasPermission: false,
        permissionId: 32,
        permission: [DocumentGroupPermission.Audit],
    },
    {
        hasPermission: false,
        permissionId: 64,
        permission: [DocumentGroupPermission.ViewTemplate],
    },
    {
        hasPermission: false,
        permissionId: 128,
        permission: [DocumentGroupPermission.CreateTemplate],
    },
    {
        hasPermission: false,
        permissionId: 256,
        permission: [DocumentGroupPermission.DeleteTemplate],
    },
];

const ManageDocumentGroupsPanel = ({ documentGroupName, documentGroupId, initialUserGroups, initialThemes, onClosePanel }:ManageDocumentGroupsPanelProps) => {
    const formRef = useRef<HTMLFormElement>(null);
    const portalsApi = useApi(PortalsApi);
    //TODO: add real conditions, will do in subtask CEP-15564
    const filterTabConditions = {
        [ManageDocumentGroupsPanelTabsEnum.UserGroups]: true,
        [ManageDocumentGroupsPanelTabsEnum.Themes]: true,
    };
    const { t } = useTranslation('user-management');
    const { currentTab, filteredTabs, selectTabHandler } = useNavTabs(manageDocumentGroupsPanelTabs, filterTabConditions);
    const userManagementApi = useApi(UserManagementApi);
    const formMethods = useEnhancedForm<FormValues>({ defaultValues: { name: documentGroupName ?? '' } });
    const { handleSubmit, control } = formMethods;

    const [selectedUserGroups, setSelectedUserGroups] = useState<UserGroupsListItemModel[]>();
    const [themes, setThemes] = useState<DocumentGroupThemesModel[]>();
    const [defaultTheme, setDefaultTheme] = useState<DocumentGroupThemesModel>();
    const [selectedThemes, setSelectedThemes] = useState<DocumentGroupThemesModel[]>();


    const removeSelectedUserGroup = (userGroup: UserGroupsListItemModel) => {
        const filteredSelecteduserGroups = selectedUserGroups?.filter((selectedUserGroup: UserGroupsListItemModel) => userGroup.userGroupId !== selectedUserGroup.userGroupId);

        setSelectedUserGroups(filteredSelecteduserGroups);
    };

    const handleSelectOrRemoveUserGroupItem = (userGroup: UserGroupsListItemModel) => {
        const id = userGroup.userGroupId;

        if (selectedUserGroups && selectedUserGroups.map((group: UserGroupsListItemModel) => group.userGroupId).includes(id)) {
            removeSelectedUserGroup(userGroup);
        } else if (!selectedUserGroups) {
            setSelectedUserGroups([
                {
                    ...userGroup,
                    permissions: hardCodedPermissions,
                },
            ]);
        } else {
            const newSelectedUserGroups = [
                ...selectedUserGroups,
                {
                    ...userGroup,
                    permissions: hardCodedPermissions,
                },
            ];

            setSelectedUserGroups(newSelectedUserGroups);
        }
    };

    const removeSelectedTheme = (theme: DocumentGroupThemesModel) => {
        const filteredThemes = selectedThemes?.filter((selectedTheme: DocumentGroupThemesModel) => theme.themeId !== selectedTheme.themeId);

        setSelectedThemes(filteredThemes);
    };

    const handleSelectOrRemoveThemeItem = (theme: DocumentGroupThemesModel) => {
        const id = theme.themeId;

        if (theme.themeId === defaultTheme?.themeId) {
            return;
        } else if (selectedThemes && selectedThemes.map((selectedTheme: DocumentGroupThemesModel) => selectedTheme.themeId).includes(id)) {
            removeSelectedTheme(theme);
        } else if (!selectedThemes) {
            setSelectedThemes([theme]);
        } else {
            const newSelectedThemes = [...selectedThemes, theme];

            setSelectedThemes(newSelectedThemes);
        }
    };

    const handleToggleSwitch = (ugId: number, permissionId: number) => {
        setSelectedUserGroups((prev?: UserGroupsListItemModel[]) => {
            return prev?.map((group: UserGroupsListItemModel) => {
                if (ugId === group.userGroupId) {
                    return {
                        ...group,
                        permissions: group.permissions.map((permission: hardCodedPermissionsType) => {
                            if (permission.permissionId === permissionId) {
                                return {
                                    ...permission,
                                    hasPermission: !permission.hasPermission,
                                };
                            } else {
                                return permission;
                            }
                        }),
                    };
                } else {
                    return group;
                }
            });
        });
    };

    const fetchUserGroups = useCallback(async (data: PageableListParams, signal?: AbortSignal) => {
        const userGroupResponse = await portalsApi.getUserGroups({ ...data });

        return userGroupResponse;
    }, [portalsApi]);

    useAsyncEffect(() => async () => {
        const response = await portalsApi.getThemes();

        const initialDefaultTheme = response.filter((theme: DocumentGroupThemesModel) => theme.name === 'System theme');

        if (initialUserGroups) {
            setSelectedUserGroups(initialUserGroups);
        }

        if (initialThemes) {
            setSelectedThemes(initialThemes.themes);
            setDefaultTheme(initialThemes.defaultTheme);
        } else {
            setSelectedThemes(initialDefaultTheme);
            setDefaultTheme(initialDefaultTheme[0]);
        }


        setThemes(response);
    }, [
        documentGroupId,
        initialUserGroups,
        initialThemes,
        portalsApi,
        setSelectedUserGroups,
    ]);


    const renderUserGroupItem = (itemProps: ListItemProps) => {
        return (
            <div className={'user-group-tab__user-group-combo-list-item'}>
                <div className='user-group-tab__combo-list-item-title'>
                    <Typography
                        className='user-group-tab__combo-list-item-name'
                        token={TypographyToken.UiMenuSm}
                        text={`${itemProps.dataItem.name}`}
                    ></Typography>
                </div>
                {
                    selectedUserGroups &&
                    selectedUserGroups.map((group: UserGroupsListItemModel) => group.userGroupId === itemProps.dataItem.userGroupId &&
                        <NdsIconFont
                            key={group.userGroupId}
                            className='user-group-tab__combo-check-icon'
                            fontName='fa-solid-check'
                        />)
                }
            </div>);
    };

    const renderThemeItem = (li: React.ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
        const itemChildren = (
            <div className={'user-group-tab__user-group-combo-list-item'}>
                <div className='user-group-tab__combo-list-item-title'>
                    <Typography
                        className='user-group-tab__combo-list-item-name'
                        token={TypographyToken.UiMenuSm}
                        text={`${itemProps.dataItem.name}`}
                    ></Typography>
                </div>
                {
                    selectedThemes &&
                    selectedThemes.map((theme: DocumentGroupThemesModel) => theme.themeId === itemProps.dataItem.themeId &&
                        <NdsIconFont
                            key={theme.themeId}
                            className='user-group-tab__combo-check-icon'
                            fontName='fa-solid-check'
                        />)
                }

            </div>);

        return cloneElement(li, li.props, itemChildren);
    };


    const onSubmitCallback = useCallback(async ({ ...data }: FormValues) => {
        let id = documentGroupId;

        if (id == null) {
            // create document group if not editing
            id = (await userManagementApi.createDocumentGroup({ name: data.name })).documentGroupId;
        } else {
            // update document group name
            await userManagementApi.updateDocumentGroup({
                name: data.name,
                documentGroupId: id,
            });
        }

        const removedUserGroups = initialUserGroups?.filter((x) => {
            return selectedUserGroups?.findIndex((y) => x.userGroupId === y.userGroupId) === -1;
        });

        if (removedUserGroups) {
            for (let i = 0; i < removedUserGroups.length; i++) {
                const removedUserGroup = removedUserGroups[i];
                for (let j = 0; j < hardCodedPermissions.length; j++) {
                    const permission = hardCodedPermissions[j];

                    await userManagementApi.updateUserGroupPermissionChangesInDocumentGroup({
                        userGroupId: removedUserGroup.userGroupId,
                        documentGroupId: id,
                        permissionId: permission.permissionId,
                        hasPermission: false,
                    });
                }
            }
        }

        if (selectedUserGroups) {
            for (let i = 0; i < selectedUserGroups.length; i++) {
                const group = selectedUserGroups[i];
                const initialGroup = initialUserGroups?.find((x) => x.userGroupId === group.userGroupId);
                for (let j = 0; j < group.permissions.length; j++) {
                    const permission = group.permissions[j];

                    if (initialGroup) {
                        const initialPermissionValue = initialGroup.permissions.find((x) => x.permissionId === permission.permissionId);

                        if (initialPermissionValue == null || initialPermissionValue?.hasPermission !== permission.hasPermission) {
                            await userManagementApi.updateUserGroupPermissionChangesInDocumentGroup({
                                userGroupId: group.userGroupId,
                                documentGroupId: id,
                                permissionId: permission.permissionId,
                                hasPermission: permission.hasPermission,
                            });
                        }
                    } else {
                        await userManagementApi.updateUserGroupPermissionChangesInDocumentGroup({
                            userGroupId: group.userGroupId,
                            documentGroupId: id,
                            permissionId: permission.permissionId,
                            hasPermission: permission.hasPermission,
                        });
                    }
                }
            }
        }

        await userManagementApi.updateUserGroupThemeChangesInDocumentGroup({
            documentGroupId: id,
            defaultTheme: { themeId: defaultTheme!.themeId },
            themes: selectedThemes!.map((selectedTheme: DocumentGroupThemesModel) => {
                return {
                    name: selectedTheme.name,
                    themeId: selectedTheme.themeId,
                };
            }),
        });

        onClosePanel();
    }, [
        defaultTheme,
        documentGroupId,
        initialUserGroups,
        onClosePanel,
        selectedThemes,
        selectedUserGroups,
        userManagementApi,
    ]);


    const PAGE_SIZE = 10;
    const userGroupComboboxProps = useVirtualizedCombobox<UserGroupsListItemModel>({
        pageSize: PAGE_SIZE,
        apiFilterKey: 'name',
        fetchData: fetchUserGroups,
        valueKey: 'userGroupId',
        labelKey: 'name',
        onChange: (e) => handleSelectOrRemoveUserGroupItem(e!),
        value: '',
        RenderItem: renderUserGroupItem,
    });

    return (
        <SidePanel
            onClosePanel={onClosePanel}
            className='manage-documents-panel'
        >
            <FormProvider {...formMethods} >
                <form
                    className={'manage-documents-panel__form'}
                    onSubmit={handleSubmit(onSubmitCallback)}
                    ref={formRef}
                >
                    <Typography token={TypographyToken.DesktopHeaderXs}>{t('manage-document-groups-create-document-group')}</Typography>
                    <div>
                        <Typography token={TypographyToken.DesktopDescriptionSm}>{t('manage-document-groups-document-group-name')}</Typography>
                        <Controller
                            control={control}
                            name='name'
                            rules={{ required: true }}
                            render={({ field: { onChange, value } }) => {
                                return (
                                    <Input value={value} onChange={(e) => onChange(e)}/>
                                );
                            }}
                        />
                    </div>
                    <NavTabs
                        navTabs={filteredTabs}
                        selectTabHandler={selectTabHandler}
                        currentTab={currentTab}
                    />
                    <div className={'manage-documents-panel__tab-container'}>
                        {
                            currentTab.key === ManageDocumentGroupsPanelTabsEnum.UserGroups &&
                            <>
                                <Typography token={TypographyToken.DesktopDescriptionSm}>{t('manage-document-groups-search-for-user-groups')}</Typography>
                                <ComboBox
                                    popupSettings={{ height: 203 }}
                                    clearButton={false}
                                    suggest
                                    {...userGroupComboboxProps}
                                    prefix={() => (
                                        <InputPrefix orientation='horizontal'>
                                            <SvgIcon
                                                icon={searchIcon}
                                            />
                                        </InputPrefix>
                                    )}
                                />
                                {
                                    selectedUserGroups && selectedUserGroups.length > 0 &&
                                    selectedUserGroups.map((userGroup: UserGroupsListItemModel) => {
                                        return (
                                            <SelectedUserGroupPanel
                                                key={userGroup.userGroupId}
                                                userGroup={userGroup}
                                                removeSelectedUserGroupHandler={() => removeSelectedUserGroup(userGroup)}
                                                setSelectedUserGroups={handleToggleSwitch}
                                            />
                                        );
                                    })
                                }
                            </>
                        }
                        {
                            currentTab.key === ManageDocumentGroupsPanelTabsEnum.Themes &&
                            <>
                                <Typography token={TypographyToken.DesktopDescriptionSm}>{t('manage-document-groups-search-for-themes')}</Typography>
                                <ComboBox
                                    itemRender={renderThemeItem}
                                    onChange= {(e: ComboBoxChangeEvent) => handleSelectOrRemoveThemeItem(e.value)}
                                    value={''}
                                    suggest
                                    data={themes}
                                    clearButton={false}
                                    prefix={() => (
                                        <InputPrefix orientation='horizontal'>
                                            <SvgIcon
                                                icon={searchIcon}
                                            />
                                        </InputPrefix>
                                    )}
                                />
                                {
                                    selectedThemes && selectedThemes.length > 0 &&
                                    selectedThemes.map((theme: DocumentGroupThemesModel) => {
                                        return (
                                            <SelectedThemePanel
                                                key={theme.themeId}
                                                selectedTheme={theme}
                                                defaultTheme={defaultTheme!}
                                                setDefaultThemeHandler={() => setDefaultTheme(theme)}
                                                removeSelectedThemeHandler={() => removeSelectedTheme(theme)} />
                                        );
                                    })
                                }
                            </>
                        }
                    </div>
                    <Footer closePanel={onClosePanel} handleOnSaveClick={() => formRef?.current?.requestSubmit()} />
                </form>
            </FormProvider>
        </SidePanel>
    );
};

export default ManageDocumentGroupsPanel;

interface SelectedUserGroupPanelProps {
    userGroup: UserGroupsListItemModel;
    removeSelectedUserGroupHandler: () => void;
    setSelectedUserGroups: (usergroupId: number, permissionId: number) => void;
}

const SelectedUserGroupPanel = ({ userGroup, removeSelectedUserGroupHandler, setSelectedUserGroups }: SelectedUserGroupPanelProps) => {
    const [isUserGroupPermissionsOpen, setIsUserGroupPermissionsOpen] = useState(false);
    const { t } = useTranslation('user-management');

    return (
        <div className='manage-documents-panel__document-group-panel'>
            <div className={'manage-documents-panel__document-group-panel-header'}>
                <button
                    type={'button'}
                    className={'manage-documents-panel__panel-button'}
                    onClick={() => setIsUserGroupPermissionsOpen(!isUserGroupPermissionsOpen)}
                >
                    <NdsIconFont
                        className={'manage-documents-panel__caret-icon'}
                        size={SizesEnums.MEDIUM}
                        fontName={isUserGroupPermissionsOpen ? 'fa-solid-caret-down': 'fa-solid-caret-right'}
                    />
                    <span>
                        <Typography token={TypographyToken.DesktopDescriptionSm}>{userGroup.name}</Typography>
                    </span>
                </button>
                <button
                    type={'button'}
                    className='manage-documents-panel__remove-selected-list-item'>
                    <NdsIconFont
                        onClick={removeSelectedUserGroupHandler}
                        size={SizesEnums.SMALL}
                        fontName='fa-solid-minus-circle' />
                </button>
            </div>
            {
                isUserGroupPermissionsOpen &&
                    <>
                        <Typography
                            token={TypographyToken.DesktopTagXs}
                            className={'manage-documents-panel__permissions-panel-title'}>
                            {t('manage-document-groups-permissions-title')}
                        </Typography>
                        <div className='manage-documents-panel__document-group-permissions-panel'>
                            {
                                userGroup.permissions.map((permission) => {
                                    return (
                                        <div key={permission.permissionId} className={'manage-documents-panel__permission-container'}>
                                            <Typography
                                                token={TypographyToken.DesktopDescriptionSm}
                                            >
                                                {permission.permission}
                                            </Typography>
                                            <Switch defaultChecked={permission.hasPermission} onChange={() => setSelectedUserGroups(userGroup.userGroupId, permission.permissionId)} />
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </>
            }
        </div>
    );
};

interface SelectedThemePanelProps {
    selectedTheme: DocumentGroupThemesModel;
    defaultTheme: DocumentGroupThemesModel;
    removeSelectedThemeHandler: () => void;
    setDefaultThemeHandler: () => void;
}

const SelectedThemePanel = ({ selectedTheme, defaultTheme, removeSelectedThemeHandler, setDefaultThemeHandler }: SelectedThemePanelProps) => {
    const { t } = useTranslation('user-management');

    return (
        <div className={'manage-documents-panel__theme-panel'}>
            <span>
                <Typography token={TypographyToken.DesktopDescriptionSm}>{selectedTheme.name}</Typography>
            </span>
            <div className={'manage-documents-panel__theme-actions'}>
                {
                    defaultTheme.themeId === selectedTheme.themeId ?
                        <div>
                            <NdsBadge text={t('manage-document-groups-default-theme-tag')} state={'success'} badgeType={NdsBadgeTypesEnum.STATUS}  />
                        </div>
                        :
                        <button
                            type={'button'}
                            onClick={setDefaultThemeHandler}
                            className='manage-documents-panel__set-default-theme-button'
                        >
                            <Typography token={TypographyToken.UiFormsLabelXs}>{t('manage-document-groups-set-as-default-theme')}</Typography>
                        </button>
                }
                {
                    defaultTheme.themeId !== selectedTheme.themeId &&
                        <button
                            type={'button'}
                            className='manage-documents-panel__remove-selected-list-item'
                        >
                            <NdsIconFont
                                onClick={removeSelectedThemeHandler}
                                size={SizesEnums.SMALL}
                                fontName='fa-solid-minus-circle' />
                        </button>
                }
            </div>
        </div>

    );
};
