import { ActorType } from '~constants';
import { FieldType } from '~constants/field-type';
import { StringUtils } from '~lib';
import {
    FieldModel,
    FlowActorModel,
    FlowFieldModel,
    FlowModel,
    FlowRadioGroupFieldOptionModel,
    FlowStakeholderModel, Language,
    LegalNoticeModel,
    StakeholderModel,
    WorkflowActorModel,
    WorkflowFormFillerActorModel,
    WorkflowModel,
    WorkflowSignerActorModel,
    WorkflowStepModel,
} from '~models';
import { PackageDraftDetails } from '~models/package.models';
import { FlowActionType, FlowReducerFunc } from '../flow.types';

export type FlowReducerEditorInitActionPayloads = {
    [FlowActionType.SetInitialDetails]: PackageDraftDetails & { language: Language }; // | TemplateDraftDetails
    [FlowActionType.SetInitialLegalNoticeConfig]: {
        isLegalNoticeEnabled: boolean;
        legalNotices: LegalNoticeModel[]
    };
    [FlowActionType.SetInitialFlow]: {
        stakeholders: StakeholderModel[],
        workflow: WorkflowModel,
        elements: FieldModel[]
    };
}

export const SetInitialDetailsReducer: FlowReducerFunc<FlowActionType.SetInitialDetails> = (
    state,
    payload,
) => {
    return {
        ...state,
        packageName: payload.packageName,
        templateName: payload.templateName,
        workspaceId: payload.documentGroupId, // we keep an original reference to the documentGroupId we came from, so we can go back here if the user decides to close without saving
        detailDocuments: payload.documents,
        properties: {
            actionUrlExpirationPeriod: payload.actionUrlExpirationPeriod,
            addInitiatorAsReceiver: payload.addInitiatorAsReceiver,
            automaticReminder: payload.automaticReminder,
            callbackUrl: payload.callbackUrl,
            defaultRedirectUrl: payload.defaultRedirectUrl,
            downloadUnsignedFiles: payload.downloadUnsignedFiles,
            expirationReminder: payload.expirationReminder,
            isActionUrlExpirationEnabled: payload.isActionUrlExpirationEnabled,
            IsCallbackUrlEnabled: payload.IsCallbackUrlEnabled,
            isDefaultRedirectUrlEnabled: payload.isDefaultRedirectUrlEnabled,
            isMailOtpAuthenticationEnabled: payload.isMailOtpAuthenticationEnabled,
            isNotificationCallbackUrlEnabled: payload.isNotificationCallbackUrlEnabled,
            isSmsOtpAuthenticationEnabled: payload.isSmsOtpAuthenticationEnabled,
            notificationCallbackUrl: payload.notificationCallbackUrl,
            reassignEnabled: payload.reassignEnabled,
            themeId: payload.themeId,
            language: payload.language,
            documentGroupId: payload.documentGroupId, // this value will be used within the dropdown of the package properties
            expirationTimeStamp: payload.expirationTimestamp,
            legalNoticeDefaultCode: payload.legalNoticeDefaultCode,
            legalNoticeDefaultText: payload.legalNoticeDefaultText,
        },
    };
};

export const SetInitialLegalNoticeConfigReducer: FlowReducerFunc<FlowActionType.SetInitialLegalNoticeConfig> = (
    state,
    payload,
) => {
    return {
        ...state,
        isLegalNoticeEnabled: payload.isLegalNoticeEnabled,
        legalNotices: payload.legalNotices,
    };
};

export const SetInitialFlowReducer: FlowReducerFunc<FlowActionType.SetInitialFlow> = (
    state,
    payload,
) => {
    const stakeholders: FlowStakeholderModel[] = payload.stakeholders.map((el) => ({
        ...el,
        localId: StringUtils.guid(),
    }));

    const elements: FlowFieldModel[] = payload.elements.map((el) => {
        const stakeholderLocalId = stakeholders
            .find(stakeholder => stakeholder.id === el.stakeholderId)?.localId;

        if (el.type === FieldType.RadioGroup) {
            return {
                ...el,
                localId: StringUtils.guid(),
                stakeholderLocalId,
                options: el.options.map<FlowRadioGroupFieldOptionModel>(el => ({
                    ...el,
                    localId: StringUtils.guid(),
                })),
            };
        } else {
            return {
                ...el,
                stakeholderLocalId,
                localId: StringUtils.guid(),
            };
        }
    });

    const flow: FlowModel = payload.workflow.map((step: WorkflowStepModel) => {
        return step.map<FlowActorModel>((actor: WorkflowActorModel) => {
            const stakeholderLocalId = stakeholders
                .find(stakeholder => stakeholder.id === actor.stakeholderId)?.localId;
            switch (actor.type.toUpperCase()) {
                case ActorType.Approver:
                    return {
                        localId: StringUtils.guid(),
                        actorType: ActorType.Approver,
                        id: actor.id,
                        stakeholderLocalId,
                    };
                case ActorType.FormFiller:
                    return {
                        localId: StringUtils.guid(),
                        actorType: ActorType.FormFiller,
                        id: actor.id,
                        stakeholderLocalId,
                        elements: (actor as WorkflowFormFillerActorModel).elements.map(({ id }) =>
                            elements.find(el => el.id === id)!.localId),
                    };
                case ActorType.Signer:
                    return {
                        localId: StringUtils.guid(),
                        actorType: ActorType.Signer,
                        id: actor.id,
                        stakeholderLocalId,
                        elements: (actor as WorkflowSignerActorModel).elements.map(({ id }) =>
                            elements.find(el => el.id === id)!.localId),
                    };
                case ActorType.Receiver:
                default:
                    return {
                        localId: StringUtils.guid(),
                        actorType: ActorType.Receiver,
                        id: actor.id,
                        stakeholderLocalId,
                    };
            }
        });
    });

    return {
        ...state,
        stakeholders,
        elements,
        flow,
    };
};
