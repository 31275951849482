import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { SaveWorkflowModel } from '~api/package.models';
import { FieldType } from '~constants/field-type';
import { useFlowContext } from '~contexts/flow';
import { FlowFieldModel, Guid, StakeholderType } from '~models';

export const useSaveFlowDetails = () => {
    const { api, flowState: { packageName, templateName, properties } } = useFlowContext();

    return useCallback((signal?: AbortSignal) => {
        return api.saveDetails({
            ...properties!,
            packageName: packageName,
            templateName: templateName,
        }, signal);
    }, [api, packageName, properties, templateName]);
};


export const useSaveFlowWorkflow = () => {
    const { api, flowState: { flow, stakeholders, elements } } = useFlowContext();

    return useCallback((signal?: AbortSignal) => {
        const getStakeholderId = (stakeholderLocalId?: Guid) => {
            return stakeholders.find((el) => stakeholderLocalId ? el.localId === stakeholderLocalId : el.type === StakeholderType.Undecided)!.id!;
        };


        const data: SaveWorkflowModel.Body = flow.map(step => (
            step.map((actor) => {
                if ('elements' in actor) {
                    return {
                        type: actor.actorType,
                        id: actor.id,
                        stakeholderId: getStakeholderId(actor.stakeholderLocalId),
                        elements: actor.elements.map((elId) => {
                            const element = elements.find(el => el.localId === elId);

                            if (!element) {
                                return null as unknown as FlowFieldModel; // it will be filtered out
                            }
                            const common = {
                                type: element.type,
                                id: element.id,
                                location: element.location,
                                markerType: element.markerType,
                                markerId: element.markerId,
                            };

                            if (element.type === FieldType.SigningField) {
                                return {
                                    ...common,
                                    signingMethods: element.signingMethods,
                                    legalNoticeCode: element.legalNoticeCode,
                                    legalNoticeText: element.legalNoticeText,
                                };
                            }
                            const formFieldCommon = {
                                ...common,
                                name: element.name,
                                label: element.label,
                                toolTipLabel: element.toolTipLabel,
                                isRequired: element.isRequired,
                            };
                            switch (element.type) {
                                case FieldType.TextBox:
                                    return {
                                        ...formFieldCommon,
                                        defaultValue: element.defaultValue,
                                        isMultiline: element.isMultiline,
                                        isOverridable: element.isOverridable,
                                        charLimit: element.charLimit,
                                    };
                                case FieldType.CheckBox:
                                    return {
                                        ...formFieldCommon,
                                        checkedByDefault: element.checkedByDefault,
                                    };
                                case FieldType.RadioGroup:
                                    return {
                                        ...formFieldCommon,
                                        options: cloneDeep(element.options),
                                    };
                            }

                            return null as unknown as FlowFieldModel; // it will be filtered out
                        }).filter(Boolean),
                    };
                } else {
                    return {
                        type: actor.actorType,
                        id: actor.id,
                        stakeholderId: getStakeholderId(actor.stakeholderLocalId),
                    };
                }
            })
        ));

        return api.saveWorkflow(data, signal);
    }, [api, elements, flow, stakeholders]);
};
