import { Sortable, SortableOnNavigateEvent } from '@progress/kendo-react-sortable';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useFlowContext } from '~contexts/flow';
import { FlowActionType } from '~contexts/flow/flow.types';
import { FlowInfoDocument } from '~models';
import FlowDocumentsListItem from './flow-documents-list-item';
import './flowDocumentsList.scss';

export interface FlowDocumentsListProps {

}

function FlowDocumentsList(props: FlowDocumentsListProps) {
    const { flowState: { infoDocuments }, setFlowState } = useFlowContext();
    const [isDragging, setDragging] = useState<boolean>(false);

    const onReorder = (event: SortableOnNavigateEvent) => {
        setFlowState(FlowActionType.SetDocuments, event.newState as FlowInfoDocument[]);
    };

    const onDragStart = useCallback(() => {
        setDragging(true);
    }, []);
    const onDragEnd = useCallback(() => {
        setDragging(false);
    }, []);

    return (
        <Sortable
            className={classNames('c-flow-documents-list', { 'c-flow-documents-list--drag-inside': isDragging })}
            idField={'localId'}
            data={infoDocuments}
            itemUI={FlowDocumentsListItem}
            emptyItemUI={() => null}
            onDragOver={onReorder}
            onNavigate={onReorder}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
        />
    );
}

export default FlowDocumentsList;
