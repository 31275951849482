import { Modal, ModalContent, ModalFooter } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { Button } from '@progress/kendo-react-buttons';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { useCallback, useState } from 'react';
import ButtonWithLoader from '~components/button-with-loader';
import Error from '~components/error';
import { ApiErrors } from '~constants';
import { useTranslation } from '~contexts/i18n';
import { HttpError } from '~lib/http/http.error';
import './setTemplateNameModal.scss';

export interface SetTemplateNameModalProps {
    onConfirm: (name: string) => Promise<void>;
    onClose: () => void;
    initialValue: string;
}

export default function SetTemplateNameModal({ onConfirm, onClose, initialValue = '' }: SetTemplateNameModalProps) {
    const { t } = useTranslation('flow');
    const { t: tBase } = useTranslation('base');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>();
    const [templateName, setTemplateName] = useState(initialValue);

    const onConfirmClick = useCallback(async () => {
        setLoading(true);
        setError(undefined);
        try {
            await onConfirm(templateName);
        } catch (e) {
            if (e instanceof HttpError && e.getErrorCode()?.startsWith(ApiErrors.TemplateNameNotUnique)) {
                setError('not-unique');
            } else {
                setError('generic');
            }
            setLoading(false);
        }
    }, [onConfirm, templateName]);

    return (
        <Modal
            size={SizesEnums.SMALL}
            withClose={!loading}
            className={'set-template-name-modal'}
            headerLabel={t('save-as-template-modal-title')}
            onCloseClick={onClose}
            renderContent={
                <ModalContent className={'set-template-name-modal__content'}>
                    <form>
                        <FieldWrapper>
                            <Label>
                                {t('input-template-name')}
                            </Label>
                            <div className={'k-form-field-wrap'}>
                                <Input
                                    valid={!error}
                                    value={templateName}
                                    disabled={loading}
                                    onChange={(e) => setTemplateName(e.value)}
                                    maxLength={150}
                                />
                            </div>
                        </FieldWrapper>
                        {error && (
                            <div className={'set-template-name-modal__error'}>
                                {error === 'not-unique' && <Error text={t('input-template-name-non-unique-error')} />}
                                {error === 'generic' && <Error i18nKey={'something-went-wrong'} />}
                            </div>
                        )}
                    </form>
                </ModalContent>
            }

            renderFooter={
                <ModalFooter>
                    <Button onClick={onClose} disabled={loading}>{tBase('cancel')}</Button>
                    <ButtonWithLoader
                        themeColor={'primary'}
                        disabled={loading || !templateName?.length}
                        showLoader={loading}
                        onClick={onConfirmClick}
                        loaderProps={{ themeColor: 'light' }}
                    >{tBase('confirm')}</ButtonWithLoader>
                </ModalFooter>
            }
        />
    );
}
