/* eslint-disable no-console */ //TODO delete this line once consoles are deleted
import { Permission } from '~constants';
import { useHasPermission, useHasPermissionBulk } from '~contexts/auth';
import { IfHasPermission } from '~contexts/auth/auth.components';

const AuthExample = ({ silent }: { silent?: boolean }) => {
    const hasAccessDocPortal = useHasPermission(Permission.AccessDocumentPortal);
    const permissions = useHasPermissionBulk([Permission.AccessSettings, Permission.AccessSettings_ActionManageServer]);

    if (!silent) {
        console.log('single perm with useHasPermission hook - \'AccessDocumentPortal\':', hasAccessDocPortal);
        console.log('many perms with useHasPermissionBulk hook - \'AccessSettings\':', permissions[Permission.AccessSettings]);
        console.log('many perms with useHasPermissionBulk hook - \'AccessSettings_ActionManageServer\':', permissions[Permission.AccessSettings_ActionManageServer]);
    }

    return (
        <div>
            AuthExamples, check console also
            <IfHasPermission
                permission={Permission.AccessSignerPortal}
                noPermissionRender={'You cannot access signer portal, login first'}
            >
                If you see this it means that you can AccessSignerPortal
            </IfHasPermission>
        </div>
    );
};

export default AuthExample;