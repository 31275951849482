import classNames from 'classnames';
import { ReactNode, useEffect, useRef } from 'react';
import './sub-menu.scss';


export interface SubMenuProps {
    button?: (toggleDropdown: () => void) => ReactNode;
    children: ReactNode;
    isSubMenu?: boolean;
    contentAlign?: 'left' | 'right' | undefined;
}

function SubMenu({ children, isSubMenu, contentAlign = 'right' }: SubMenuProps) {


    const subMenuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isSubMenu && subMenuRef.current) {
            const rect = subMenuRef.current.getBoundingClientRect();

            subMenuRef.current.style.setProperty('--menu-dropdown-element-width', `-${rect.width}px`);
        }
    }, [isSubMenu]);


    return (
        <div
            ref={subMenuRef}
            className={classNames('c-sub-menu', {
                'c-sub-menu__dropdown-menu-left': contentAlign === 'left',
                'c-sub-menu__dropdown-menu-right': contentAlign === 'right',
            })}>
            {children}
        </div>
    );

}

export default SubMenu;
