import { NdsButton } from '@gonitro/rcl';
import { NdsButtonTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import { useMemo } from 'react';
import { ContactType, Permission } from '~constants';
import { useHasPermission } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { useTableSelections } from '~contexts/table';
import { ContactBasicModel } from '~models/contact.model';
import './contactsViewHeaderButton.scss';

export enum ContactsHeaderButtonActionType {
    Copy,
    Share,
    ImportContact,
    AddContact,
}

export interface ContactsViewHeaderButtonProps {
    onAddContact: () => void;
    onImportContactsClick: () => void;
    onCopyContactClick: (contactsToCopy: ContactBasicModel[]) => void;
    onSharePersonalContactsClick: (contactsToCopy: ContactBasicModel[]) => void;
}

const ContactsViewHeaderButton = ({
    onAddContact,
    onImportContactsClick,
    onCopyContactClick,
    onSharePersonalContactsClick,
}: ContactsViewHeaderButtonProps) => {
    const { t } = useTranslation('contacts');
    const hasCreateSharedContactPermission = useHasPermission(Permission.General_ActionCreateSharedContact);
    const hasViewSharedContactPermission = useHasPermission(Permission.General_ActionViewSharedContact);
    const { getSelectedRowsData } = useTableSelections();
    const [isAnyPersonalContactSelected, isAnySharedContactSelected] = useMemo(() => {
        const selectedRowsData = getSelectedRowsData<'contactId', ContactBasicModel>();
        const isAnyPersonalContactSelected = selectedRowsData.some((contact) => contact.type === ContactType.Personal);
        const isAnySharedContactSelected = selectedRowsData.some((contact) => contact.type === ContactType.Shared);

        return [isAnyPersonalContactSelected, isAnySharedContactSelected];
    }, [getSelectedRowsData]);

    // Method to manage the button that was clicked
    const onButtonClick = (type: ContactsHeaderButtonActionType) => {
        const selectedRowsData = getSelectedRowsData<'contactId', ContactBasicModel>();
        switch (type) {
            case ContactsHeaderButtonActionType.ImportContact:
                return onImportContactsClick();
            case ContactsHeaderButtonActionType.Copy: {
                const contactsToCopyToPersonal: ContactBasicModel[] = selectedRowsData.filter((contact) => contact.type === ContactType.Shared);

                return onCopyContactClick(contactsToCopyToPersonal);
            }
            case ContactsHeaderButtonActionType.Share: {
                const contactsToCopyToPersonal: ContactBasicModel[] = selectedRowsData.filter((contact) => contact.type === ContactType.Personal);

                return onSharePersonalContactsClick(contactsToCopyToPersonal);
            }
        }
    };

    return (
        <div className='c-contacts__header-buttons'>
            {hasViewSharedContactPermission && isAnySharedContactSelected && (
                <NdsButton
                    label={t('copy-contacts-button')}
                    size={SizesEnums.SMALL}
                    buttonType={NdsButtonTypesEnum.SECONDARY}
                    leftIcon={'fa-solid-copy'}
                    onClick={() => onButtonClick(ContactsHeaderButtonActionType.Copy)}
                />
            )}
            {hasCreateSharedContactPermission && isAnyPersonalContactSelected && (
                <NdsButton
                    label={t('share-contacts-button')}
                    size={SizesEnums.SMALL}
                    buttonType={NdsButtonTypesEnum.SECONDARY}
                    leftIcon={'fa-regular-share-from-square'}
                    onClick={() => onButtonClick(ContactsHeaderButtonActionType.Share)}
                />
            )}
            <NdsButton
                label={t('import-contacts-button')}
                size={SizesEnums.SMALL}
                buttonType={NdsButtonTypesEnum.SECONDARY}
                onClick={() => onButtonClick(ContactsHeaderButtonActionType.ImportContact)}
            />
            <NdsButton
                label={t('add-contact-button')}
                size={SizesEnums.SMALL}
                buttonType={NdsButtonTypesEnum.PRIMARY}
                onClick={onAddContact}
            />
        </div>
    );
};
export default ContactsViewHeaderButton;
