import { SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types/designsystem.enums';
import { NavLink, NavLinkProps } from 'react-router-dom';
import MenuItem from '~components/menu-dropdown/menu-item/menu-item';
import './sidebar-link.scss';

interface SidebarLinkProps extends NavLinkProps {
    title: string;
    icon?: string;
    badge?: number;
}

function SidebarLink({ title, icon = 'fa-regular-folder', badge, ...props }: SidebarLinkProps) {
    return (
        <NavLink {...props} className={'c-sidebar-link'}>
            {({ isActive }) => {
                return <MenuItem
                    primaryText={title}
                    icon={icon}
                    size={SizesEnums.MEDIUM}
                    state={isActive ? StatesEnum.SELECTED : StatesEnum.DEFAULT}
                />;
            }}
        </NavLink>
    );
}

export default SidebarLink;
