import { Navigate } from 'react-router-dom';
import { BaseRoutes } from '~constants/routes';
import { useNavWorkspaces } from '~contexts/app';

export interface DashboardViewProps {
}

function DashboardView() {
    const { myWorkspace, sharedWorkspaces } = useNavWorkspaces();

    let path: string = BaseRoutes.DocumentPortal; // if nothing more specific found go to docportal and let it handle unknown ws id

    if (myWorkspace?.hasDocumentsPermission) {
        path = myWorkspace.documentsPath;
    } else if (myWorkspace?.hasTemplatesPermission) {
        path = myWorkspace.templatesPath;
    } else {
        const firstDocsLink = sharedWorkspaces.find(ws => ws.hasDocumentsPermission);
        const firstTplsLink = sharedWorkspaces.find(ws => ws.hasTemplatesPermission);

        if (firstDocsLink) {
            path = firstDocsLink.documentsPath;
        } else if (firstTplsLink) {
            path = firstTplsLink.templatesPath;
        } else {
            path = BaseRoutes.SignerPortal;
        }
    }

    return (
        <Navigate to={path} replace />
    );
}

export default DashboardView;
