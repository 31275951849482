import { debounce } from 'lodash';
import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { useAbortEffect } from '~hooks/effects';
import { Guid } from '~models';
import DocumentViewerPage from './documentViewerPage';
import { DocumentViewerHeaderComponent, DocumentViewerPageModel, DocumentViewerPagePosition } from '../types';

export interface DocumentViewerPagesWrapperProps {
    pages: DocumentViewerPageModel[];
    pagePositions: DocumentViewerPagePosition[];
    firstRenderIndex: number;
    middleRenderIndex: number;
    lastRenderIndex: number;
    requestPagesQuantity: number;
    debounceTime?: number;
    fetchDocumentPages: (documentId: Guid, startIndex: number, amount: number, signal?: AbortSignal) => Promise<string[]>;
    PageHeaderComponent?: DocumentViewerHeaderComponent;
    DocumentHeaderComponent?: DocumentViewerHeaderComponent;
}

const findFirstFetchIndex = (requestQuarterQuantity: number, middleRenderIndex: number, firstRenderIndex: number) => {
    // find first fetch index - typically (middleRender - half of request size) but it cannot be lower
    // than 0 (Math.max). Also, this value cannot be greater than firstRenderIndex (Math.min)
    let index = Math.min(
        Math.max(middleRenderIndex - requestQuarterQuantity * 2, 0),
        firstRenderIndex,
    );

    // if there is less than 1/4 of requestPagesQuantity so requestQuarterQuantity to the beginning
    // include the preceding pages
    if (index - requestQuarterQuantity <= 0) {
        index = 0;
    }

    return index;
};
const findLastFetchIndex = (requestQuarterQuantity: number, middleRenderIndex: number, lastRenderIndex: number, maxIndex: number) => {
    // find last fetch index - typically (middleRender + half of request size) but it cannot be greater
    // than page quantity (Math.min). Also, this value cannot be lower than lastRenderIndex (Math.max)
    let index = Math.max(
        Math.min(middleRenderIndex + requestQuarterQuantity * 2, maxIndex),
        lastRenderIndex,
    );

    // if there is less than 1/4 of requestPagesQuantity so requestQuarterQuantity to the end
    // include the following pages
    if (index + requestQuarterQuantity >= maxIndex) {
        index = maxIndex;
    }

    return index;
};

enum PAGE_STATE {
    Empty,
    Loading,
    Loaded,
}

function DocumentViewerPages({
    pages,
    pagePositions,
    requestPagesQuantity,
    firstRenderIndex,
    middleRenderIndex,
    lastRenderIndex,
    debounceTime = 400,
    fetchDocumentPages,
    PageHeaderComponent,
    DocumentHeaderComponent,
}: DocumentViewerPagesWrapperProps) {
    const [pageCache, setPageCache] = useState<string[]>(new Array(pages.length));
    const pageState = useRef<Array<PAGE_STATE>>((new Array(pages.length)).fill(PAGE_STATE.Empty));

    const setPagesData = useCallback((startIdx: number, data: string[]) => {
        setPageCache((current) => {
            const newArr = current.slice();

            newArr.splice(startIdx, data.length, ...data);
            pageState.current.splice(startIdx, data.length, ...(new Array(data.length)).fill(PAGE_STATE.Loaded));

            return newArr;
        });
    }, []);

    const loadPages = useMemo(() => debounce((
        firstRenderIndex: number,
        middleRenderIndex: number,
        lastRenderIndex: number,
        signal: AbortSignal,
    ) => {
        // get quarter number of items to fetch using predefined requestPagesQuantity, but it can't be lower than rendered
        // items quantity so basic request indexes are
        // [ middleRenderIndex - requestQuarterQuantity * 2 ; middleRenderIndex + requestQuarterQuantity * 2 ]
        // then it is checked if more than 1/4 of extra items that are not rendered in any way should be fetched
        // If so then fetch quantity is extended to requestQuarterQuantity * 4 in that way, otherwise nothing in that way will be fetched
        const requestQuarterQuantity = Math.ceil(Math.max(requestPagesQuantity, lastRenderIndex - firstRenderIndex + 1) / 4);

        // find indexes
        const firstFetchIndex = findFirstFetchIndex(requestQuarterQuantity, middleRenderIndex, firstRenderIndex);
        const lastFetchIndex = findLastFetchIndex(requestQuarterQuantity, middleRenderIndex, lastRenderIndex, pages.length - 1);


        // find page indexes that has to be fetched = don't fetch already fetched pages
        const pagesToFetch: number[] = [];
        for (let i = firstFetchIndex; i <= lastFetchIndex; i++) {
            if (pageState.current[i] === PAGE_STATE.Empty) {
                pagesToFetch.push(i);
            }
        }

        // stop if nothing to fetch
        if (!pagesToFetch.length) {
            return;
        }

        // find real edge fetch indexes
        const realFirstFetchIndex = pagesToFetch[0];
        const realLastFetchIndex = pagesToFetch[pagesToFetch.length - 1];

        // if there are less than 1/4 of requestPagesQuantity of pages to fetch and all are before first or behind last
        // rendered page just don't fetch, value is too small to bother. It will be fetched later. It's slow scroll case
        if (pagesToFetch.length < requestQuarterQuantity) {
            if (realFirstFetchIndex > firstFetchIndex && pagesToFetch.every(idx => idx > lastRenderIndex)) {
                return;
            }
            if (realLastFetchIndex < lastFetchIndex && pagesToFetch.every(idx => idx < firstRenderIndex)) {
                return;
            }
        }

        // if there are fewer pages to fetch than max allowed, try to fetch more in one call cycle up to requestPagesQuantity
        if (pagesToFetch.length < requestQuarterQuantity * 4) {
            // starts with first page or scrolling down case, include indexes that are not fetched until first fetched one occurs - that case
            // is also initially checked by second condition
            if ((
                realFirstFetchIndex > firstFetchIndex
                || firstFetchIndex === 0
            ) && realLastFetchIndex === lastFetchIndex
            ) {
                const numOfItemsToAdd = 4 * requestQuarterQuantity - pagesToFetch.length;
                for (
                    let i = 1;
                    i <= numOfItemsToAdd;
                    i++
                ) {
                    const idxToAdd = realLastFetchIndex + i;

                    if (idxToAdd < pages.length && pageState.current[idxToAdd] === PAGE_STATE.Empty) {
                        pagesToFetch.push(idxToAdd);
                    } else {
                        break;
                    }
                }
            }
            // ends with last page or scrolling up case, include indexes that are not fetched until first fetched one occurs - that case
            // is also initially checked by second condition
            if ((
                realLastFetchIndex < lastFetchIndex
                || lastFetchIndex === pages.length - 1
            ) && realFirstFetchIndex === firstFetchIndex
            ) {
                const numOfItemsToAdd = 4 * requestQuarterQuantity - pagesToFetch.length;
                for (
                    let i = 1;
                    i <= numOfItemsToAdd;
                    i++
                ) {
                    const idxToAdd = realFirstFetchIndex - i;

                    if (idxToAdd >= 0 && pageState.current[idxToAdd] === PAGE_STATE.Empty) {
                        pagesToFetch.unshift(idxToAdd);
                    } else {
                        break;
                    }
                }
            }
        }

        // create map which pages in which documents has to be fetched
        const documentPagesToFetch: Record<Guid, {
            startIndex: number,
            amount: number,
            startAbsolutePageIndex: number
        }> = {};
        for (const index of pagesToFetch) {
            const page = pages[index];

            pageState.current[index] = PAGE_STATE.Loading;

            if (documentPagesToFetch[page.documentId]) {
                documentPagesToFetch[page.documentId].amount++;
            } else {
                documentPagesToFetch[page.documentId] = {
                    startAbsolutePageIndex: index,
                    startIndex: page.pageIndex,
                    amount: 1,
                };
            }
        }

        for (const [docId, info] of Object.entries(documentPagesToFetch)) {
            pageState.current.splice(info.startAbsolutePageIndex, info.amount, ...(new Array(info.amount)).fill(PAGE_STATE.Loading));
            fetchDocumentPages(docId, info.startIndex, info.amount, signal).then(data => {
                setPagesData(info.startAbsolutePageIndex, data);
            }).catch((e) => {
                pageState.current.splice(info.startAbsolutePageIndex, info.amount, ...(new Array(info.amount)).fill(PAGE_STATE.Empty));

                if (!signal.aborted) {
                    console.error('Error when fetching pages', e);
                }
            });
        }
    }, debounceTime), [
        debounceTime,
        fetchDocumentPages,
        pages,
        requestPagesQuantity,
        setPagesData,
    ]);

    useAbortEffect((signal) => {
        loadPages(firstRenderIndex, middleRenderIndex, lastRenderIndex, signal);
    }, [middleRenderIndex, lastRenderIndex, firstRenderIndex, loadPages]);

    const renderedPages: ReactNode[] = [];
    for (let i = firstRenderIndex; i <= lastRenderIndex; i++) {
        const Component = pages[i].pageIndex ? PageHeaderComponent : DocumentHeaderComponent;

        renderedPages.push(
            <DocumentViewerPage
                key={i}
                page={pages[i]}
                position={pagePositions[i]}
                pageData={pageCache[i]}
                HeaderComponent={Component}
            />,
        );
    }

    return <>{renderedPages}</>;
}

export default DocumentViewerPages;
