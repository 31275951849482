import { ReactNode, useMemo, useRef } from 'react';
import Http, { HttpHeadersManager, HttpRequest } from '~lib/http';
import { HttpMethod } from '~lib/http/http.enums';
import { HttpError } from '~lib/http/http.error';
import { ApiContext, ApiContextType } from './api.context';
import { ApiConstructor } from './api.models';


export interface ApiContextProviderProps {
    children: ReactNode;
    apiConstructors: ApiConstructor[];
    baseUrl?: string;
}

export function ApiContextProvider({ children, apiConstructors, baseUrl }: ApiContextProviderProps) {
    const errorProcessors = useRef<Record<string, (error: HttpError) => void | boolean>>({});
    const errorCounter = useRef(0);

    const http = useMemo(() => {
        return new Http((request: HttpRequest) => {
            if (request.method === HttpMethod.Get) {
                request.addResponseProcessor(response => {
                    const csrfToken = response.headers[HttpHeadersManager.CSRFToken.toLowerCase()];

                    if (csrfToken) {
                        HttpHeadersManager.setCommonHeader(HttpHeadersManager.CSRFToken, csrfToken);
                    }
                });
            }

            request.addErrorProcessor((error: HttpError) => {
                errorCounter.current++;

                for (const func of Object.values(errorProcessors.current)) {
                    if (func(error)) {
                        return true;
                    }
                }
            });

            return request;
        }, baseUrl);
    }, [baseUrl]);

    const contextValue = useMemo<ApiContextType>(() => {
        const apis = [];

        for (const apiCtor of apiConstructors) {
            apis.push(new apiCtor(http));
        }

        return {
            apis,
            registerErrorProcessor: (id, func) => {
                errorProcessors.current[id] = func;
            },
            unregisterErrorProcessor: (id) => {
                delete errorProcessors.current[id];
            },
        };
    }, [http, apiConstructors]);

    return (
        <ApiContext.Provider value={contextValue}>
            {children}
        </ApiContext.Provider>
    );
}
