import { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { AuthApi } from '~api/auth.api';
import Typography, { TypographyToken } from '~components/typography';
import { BaseRoutes } from '~constants/routes';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { useCompanyName } from '~contexts/settings';
import LostPasswordForm from './lost-password-form';
import './lost-password.view.scss';

export interface LostPasswordViewProps {
}

const ACTIVATE_RESEND_TIMEOUT = 30000; // ms; BE limit = 2 per minute & 5 per hour, 30 seconds cooldown is enough

function LostPasswordView() {
    const { t } = useTranslation('unauthorized');
    const authApi = useApi(AuthApi);
    const companyName = useCompanyName();
    const usedEmail = useRef('');

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [clickHereActive, setClickHereActive] = useState(false);

    const sendResetLink = useCallback(async (email?: string) => {
        if (formSubmitted && !clickHereActive) {
            return;
        }
        if (email) {
            usedEmail.current = email;
        }
        setClickHereActive(false);
        try {
            await authApi.initPasswordReset({ email: usedEmail.current });
            setFormSubmitted(true);
        } catch (e) {
            if (email) {
                throw e; // if called from form throw to handle it there
            }
            // otherwise act as nothing wrong happened, if entered email is wrong user never get email
        }
    }, [authApi, clickHereActive, formSubmitted]);

    useEffect(() => {
        if (!formSubmitted || clickHereActive) {
            return;
        }
        const timeout = setTimeout(() => {
            setClickHereActive(true);
        }, ACTIVATE_RESEND_TIMEOUT);

        return () => {
            clearTimeout(timeout);
        };
    }, [clickHereActive, formSubmitted]);

    return (
        <div className='v-lost-password'>
            {!formSubmitted
                ? (
                    <>
                        <div className={'v-lost-password__header'}>
                            <Typography
                                tagName={'h1'}
                                token={TypographyToken.MobileHeaderLg}
                                mediumScreenToken={TypographyToken.DesktopHeaderLg}
                                text={t('title-forgot-your-password')}
                            />
                            <Typography
                                tagName={'p'}
                                token={TypographyToken.MobileDescriptionLg}
                                mediumScreenToken={TypographyToken.DesktopDescriptionLg}
                                text={t('description-forgot-your-password')}
                            />
                        </div>
                        <div className='v-lost-password__content'>
                            <LostPasswordForm
                                onSubmit={sendResetLink}
                            />
                            <Typography
                                tagName={'p'}
                                token={TypographyToken.MobileDescriptionSm}
                                mediumScreenToken={TypographyToken.DesktopDescriptionSm}
                                className={'v-lost-password__extra-action'}
                            >
                                {t('already-have-account', { companyName })}&nbsp;
                                <Link to={BaseRoutes.Login} className='v-lost-password__extra-action-link'>
                                    <Typography
                                        token={TypographyToken.UiButtonsSm}
                                        onClick={() => sendResetLink()}
                                        text={t('log-in')}
                                    />
                                </Link>
                            </Typography>
                        </div>
                    </>
                )
                : (
                    <>
                        <div className={'v-lost-password__header'}>
                            <Typography
                                tagName={'h1'}
                                token={TypographyToken.MobileHeaderLg}
                                mediumScreenToken={TypographyToken.DesktopHeaderLg}
                                text={t('title-reset-password-link-sent')}
                            />
                            <Typography
                                tagName={'p'}
                                token={TypographyToken.MobileDescriptionLg}
                                mediumScreenToken={TypographyToken.DesktopDescriptionLg}
                                text={t('description-reset-password-link-sent')}
                            />
                            <Typography
                                tagName={'p'}
                                token={TypographyToken.MobileDescriptionLg}
                                mediumScreenToken={TypographyToken.DesktopDescriptionLg}
                                text={t('description-click-link-in-email-to-reset-password')}
                            />
                        </div>
                        <div className='v-lost-password__content v-lost-password__content--sent'>
                            <Typography
                                tagName={'p'}
                                token={TypographyToken.MobileDescriptionSm}
                                mediumScreenToken={TypographyToken.DesktopDescriptionSm}
                                className={'v-lost-password__extra-action'}
                            >
                                <Typography
                                    tagName={'button'}
                                    token={TypographyToken.UiButtonsSm}
                                    type='button'
                                    className='v-lost-password__extra-action-link'
                                    onClick={() => sendResetLink()}
                                    text={t('click-here-')}
                                />
                                &nbsp;{t('-if-you-didnt-get-email-from-company', { companyName })}
                            </Typography>
                            <Typography
                                tagName={'p'}
                                token={TypographyToken.MobileDescriptionSm}
                                mediumScreenToken={TypographyToken.DesktopDescriptionSm}
                                text={t('check-spam-folder-if-no-email-received')}
                                className={'v-lost-password__extra-action'}
                            />
                        </div>
                    </>
                )
            }
        </div>
    );
}

export default LostPasswordView;