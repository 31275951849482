import { ComponentType, ForwardedRef } from 'react';
import { Guid, Offset, Size } from '~models';

export type DocumentViewerDocumentModel = {
    name: string;
    size: number;
    id: Guid;
    pageSizes: Size[];
}

export type DocumentViewerPageModel = {
    index: string;
    documentName: string;
    documentTotalPages: number;
    originalSize: Size; // real size of page
    unifiedSize: Size; // unified size across
    scale: number;
    documentId: Guid;
    pageId: Guid;
    documentIndex: number; // how many documents are before
    pageIndex: number; // how many pages (of this doc) are before
    absolutePageIndex: number; // how many pages are before
}

export type DocumentViewerPagePosition = {
    size: Size;
    offset: Offset;
    absolutePageIndex: number;
    headerHeight: number;
}

export type OnVisiblePageChangeParams = {
    visiblePages: DocumentViewerPageModel[],
    fullyVisiblePages: DocumentViewerPageModel[],

}

export type DocumentViewerPaginatorComponentProps = {
    totalPages: number;
    currentPage: number;
    onPageChange: (page: number) => void;
}

export type DocumentViewerPaginatorComponent = ComponentType<DocumentViewerPaginatorComponentProps>

export type DocumentViewerHeaderComponentProps = {
    page: DocumentViewerPageModel;
}
export type DocumentViewerHeaderComponent = ComponentType<DocumentViewerHeaderComponentProps>


export type DocumentViewerPageObjectModel<T = any> = {
    id: string;
    /**
     * any data that will be passed back in events
     */
    data?: T;
    /**
     * If position is  undefined whole element will be ignored
     */
    Component: DocumentViewerPageObjectComponent<T>;
    position: {
        document: number | string;
        page: number;
        size: Size;
        offset: Offset
    };
    /** @default [{width: 10, height: 10}] */
    minSize?: Size;
    /** Enables dragging or resizing object */
    canMove?: boolean;
    /** If defined field width/height ratio will be preserved when resizing  */
    keepRatio?: number;
}

export type DocumentViewerPageObjectComponentCallbacksRef = {
    onClick: () => void;
    onPositionChange: (data: { id: string, offset: Offset, size: Size, page: DocumentViewerPageModel }) => void;
}

export enum DocumentViewerPageObjectResizeDirection {
    TopLeft = 'top-left',
    TopRight = 'top-right',
    BottomRight = 'bottom-right',
    BottomLeft = 'bottom-left',
}

export type DocumentViewerPageObjectComponentProps<T = any> = {
    /** id of page object, so same as localId of field (or option in case of radio group) */
    id: string;
    data: T;
    isLocked: boolean;
    activeResizing?: DocumentViewerPageObjectResizeDirection;
    isDragged: boolean;
    isPressed: boolean;
    callbacksRef: ForwardedRef<DocumentViewerPageObjectComponentCallbacksRef | null>
}
export type DocumentViewerPageObjectComponent<T = any> = ComponentType<DocumentViewerPageObjectComponentProps<T>>
