import { ContactOrigin } from '~constants/contact-origin';

export namespace ContactUtils {
    export const getTypeIcon = (origin: ContactOrigin, isShared: boolean) => {
        if (origin === ContactOrigin.Local) {
            return isShared ? 'fa-solid-users' : 'fa-solid-user';
        }

        return origin === ContactOrigin.Google ? 'fa-brands-google' : 'fa-brands-microsoft';
    };
}
