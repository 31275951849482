import { useCallback, useMemo } from 'react';
import QuickActionsTable from '~components/quick-actions-table';
import { QuickAction } from '~components/quick-actions-table/quickActionsTable';
import { UserManagementAction } from '~constants/user-management-action';
import { UserStatusEnum } from '~constants/user-status';
import { UsersTableDataModel } from '~models/user-management.models';

export interface UsersViewQuickActionsProps {
    data: UsersTableDataModel;
    // data is any because no model has been set for user by user id yet
    onClickQuickAction: (data: any, action: UserManagementAction) => void; // TODO change any to specific model
}

//TODO: Create a handleActionClick function similar to what can be found in document-portal-quick-actions.tsx
type ActionFunctions = {
    [key in UserManagementAction]?: () => Promise<boolean> | void | Promise<void>;
};

const UsersViewQuickActions = ({ data, onClickQuickAction }: UsersViewQuickActionsProps) => {
    const adminCanEditUsers = true;
    const adminCanInvite = true;
    const adminCanDelete = true;

    const quickActions = useMemo((): QuickAction<UserManagementAction>[][] => {
        const pendingParentGroup: QuickAction<UserManagementAction>[][] = [];

        //TODO: change this to switch case

        if (adminCanEditUsers && (data.status === UserStatusEnum.Active || data.status === UserStatusEnum.InActive)) {
            pendingParentGroup.push([
                {
                    icon: 'fa-solid-edit',
                    type: UserManagementAction.Edit,
                },
            ]);

            if (adminCanDelete) {
                pendingParentGroup.push([
                    {
                        icon: 'fa-solid-trash',
                        type: UserManagementAction.Delete,
                    },
                ]);
            }
        }

        if (adminCanInvite && data.status === UserStatusEnum.Invited) {
            //Icon for Reinvite action is unknown yet
            pendingParentGroup.push([
                {
                    icon: 'fa-solid-pen',
                    type: UserManagementAction.Reinvite,
                },
            ]);
            pendingParentGroup.push([
                {
                    icon: 'fa-solid-trash',
                    type: UserManagementAction.Delete,
                },
            ]);
        }

        return pendingParentGroup;
    }, [adminCanDelete, adminCanEditUsers, adminCanInvite, data.status]);


    const handleQuickActionClick = useCallback(
        (actionType: UserManagementAction) => {

            const actionFunctions: ActionFunctions = {
                [UserManagementAction.Edit]: () => onClickQuickAction(data, actionType),
                [UserManagementAction.Delete]: () => onClickQuickAction(data, actionType),
                [UserManagementAction.Reinvite]: () => onClickQuickAction(data, actionType),
            };

            const actionFunc = actionFunctions[actionType];

            if (actionFunc) {
                actionFunc();
            } else {
                console.error('Unsupported action type:', actionType);
            }

        }, [data, onClickQuickAction],
    );

    const openQuickActionsModal = (actionType: UserManagementAction) => {
        handleQuickActionClick(actionType);
    };

    return (
        <QuickActionsTable
            actions={quickActions}
            handleActionClick={openQuickActionsModal}
        />
    );
};

export default UsersViewQuickActions;
