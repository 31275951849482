import { SidePanel } from '@gonitro/rcl';
import { TextBox } from '@progress/kendo-react-inputs';
import { differenceWith, isEqual } from 'lodash';
import { useCallback, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { UserManagementApi } from '~api/user-management.api';
import Loader from '~components/loader';
import NavTabs from '~components/nav-tabs';
import Typography, { TypographyToken } from '~components/typography';
import { Actions, userGroupPanelTabs, UserGroupsManagementTabsEnum } from '~constants';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { useEnhancedForm } from '~hooks/enhanced-form';
import useNavTabs from '~hooks/tabs';
import { DocumentGroupsListItemModel, UserGroupUsersListModel } from '~models/user-management.models';
import AlternateNameTab from './alternate-name-tab/alternate-name-tab';
import DocumentGroupTab from './document-group-tab/document-group-tab';
import { initialDocumentGroupToggles } from './document-group-tab/document-group-toggle-definition';
import GeneralTab from './general-tab/general-tab';
import { initialToggles } from './general-tab/toggle-definition';
import useGeneralToggles from './general-tab/useGeneralToggles';
import UserProfileTab from './user-profile-tab/user-profile-tab';
import UserManagementModal from '../../user-management-modal/user-management-modal';
import EditUserFooter from '../../users-view/edit-user-panel/edit-user-footer/edit-user-footer';
import './user-group-panel.scss';


export interface CreateUserGroupPanelProps {
    userGroupInfo: { userGroupId: number, teamName: string; } | undefined;
    closePanel: () => void;
}

type FormValues = {
    teamName: string;
    alternateName: string;
};

export interface SelectedDocumentGroupsState extends DocumentGroupsListItemModel {
    initialPermissions?: {
        hasPermission: boolean;
        permissionId: number;
        permission: string;
    }[];
}

const UserGroupPanel = ({ userGroupInfo, closePanel }: CreateUserGroupPanelProps) => {
    const formRef = useRef<HTMLFormElement>(null);
    const userManagementApi = useApi(UserManagementApi);
    const { t } = useTranslation('user-management');
    const { t: tBase } = useTranslation('base');


    const { userGroupId, teamName } = userGroupInfo || {};

    const { initialResponseToggles, generalToggles, handleGeneralToggleChange } = useGeneralToggles(initialToggles, userGroupId);

    const [isLoading, setLoading] = useState(false);
    const [selectedUsersForUserGroup, setSelectedUsersForUserGroup] = useState<UserGroupUsersListModel[]>([]);
    const [selectedDocumentGroups, setSelectedDocumentGroups] = useState<SelectedDocumentGroupsState[]>([]);
    const [alternateNames, setAlternateNames] = useState<string[]>([]);
    const [hasLoadedAlternateNames, setHasLoadedAlternateNames] = useState(false);
    const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

    const editedMembersRef = useRef<UserGroupUsersListModel[]>([]);
    const initialAlternateNamesRef = useRef<string[]>([]);
    const alternateNameChangesRef = useRef<{ name: string, status: 'add' | 'delete'; }[]>([]);

    const filterTabConditions = {};
    const {
        currentTab,
        selectTabHandler,
    } = useNavTabs(userGroupPanelTabs, filterTabConditions);

    const {
        handleSubmit,
        control,
        formState: { errors, isDirty },
    } = useEnhancedForm<FormValues>({
        mode: 'all',
        defaultValues: {
            teamName: teamName || '',
            alternateName: '',
        },
    });

    const handleSetAlternateNames = useCallback((name: string) => {

        if (initialAlternateNamesRef.current.includes(name) && !alternateNameChangesRef.current.some((change => change.name === name))) {
            alternateNameChangesRef.current = [
                ...alternateNameChangesRef.current,
                {
                    name,
                    status: 'delete',
                },
            ];
        } else {
            alternateNameChangesRef.current = alternateNameChangesRef.current.filter((change) => change.name !== name);
        }
        if (alternateNames.includes(name)) {
            const filteredAlternateNames = alternateNames.filter((altName) => name !== altName);

            setAlternateNames(filteredAlternateNames);
        } else {
            setAlternateNames(prev => [...prev, name]);

            if (!initialAlternateNamesRef.current.includes(name)) {
                alternateNameChangesRef.current = [
                    ...alternateNameChangesRef.current,
                    {
                        name,
                        status: 'add',
                    },
                ];
            }

        }
    }, [alternateNames]);

    const setInitialAlternateNames = useCallback((initialAlternateNames: string[]) => {
        initialAlternateNamesRef.current = initialAlternateNames;
        setAlternateNames(initialAlternateNames);
    }, []);

    const handleHasLoadedAlternateNames = useCallback((loaded: boolean) => {
        setHasLoadedAlternateNames(loaded);
    }, []);


    const handleOnSaveClick = useCallback(() => {
        formRef.current?.requestSubmit();
    }, []);

    const confirmationModalOnClickHandler = useCallback((type: Actions) => {
        const closeModalAndPanel = () => {
            setIsConfirmationModalOpen(false);
            closePanel();
        };
        switch (type) {
            case Actions.PRIMARY_ACTION:
                handleOnSaveClick();
                break;
            case Actions.CANCEL:
                setIsConfirmationModalOpen(false);
                break;
            case Actions.SECONDARY_ACTION:
                closeModalAndPanel();
                break;
            default:
                break;
        }
    }, [closePanel, handleOnSaveClick]);

    const compareGeneralToggleChanges = useCallback(() => {
        const togglesToCompare = userGroupId ? initialResponseToggles.current : initialToggles;
        const toggleChanges = differenceWith(generalToggles, togglesToCompare!, isEqual);

        return toggleChanges;
    }, [generalToggles, initialResponseToggles, userGroupId]);

    const compareDocumentGroupChanges = useCallback((): boolean => {
        let hasDifferences = false;

        for (const group of selectedDocumentGroups) {
            const togglesToCompare = userGroupId && group.initialPermissions
                ? group.initialPermissions
                : initialDocumentGroupToggles;

            const differences = differenceWith(group.permissions, togglesToCompare || [], isEqual);

            if (differences.length > 0) {
                hasDifferences = true;
                break;
            }
        }

        return hasDifferences;
    }, [selectedDocumentGroups, userGroupId]);


    const onSubmitCallback = useCallback(async (data: FormValues) => {
        setLoading(true);
        let createdUserGroupId: number;

        if (userGroupId) {
            createdUserGroupId = userGroupId;
        } else {
            createdUserGroupId = (await userManagementApi.createUserGroup({ name: data.teamName })).userGroupId;
        }
        await userManagementApi.updateUserGroupName({
            userGroupId: createdUserGroupId,
            name: data.teamName,
        });
        const userGroupMemberRequests =
            editedMembersRef.current.map((user) =>
                userManagementApi.updateUserGroupMember({
                    userId: user.userId,
                    userGroupId: createdUserGroupId,
                    isMember: user.isMember,
                }));

        await Promise.all(userGroupMemberRequests);


        const generalToggleRequests =
            compareGeneralToggleChanges().map((toggle) =>
                userManagementApi.updateUserGroupPermission({
                    hasPermission: toggle.hasPermission,
                    userGroupId: createdUserGroupId,
                    permissionId: toggle.permission,
                }));

        await Promise.all(generalToggleRequests);


        // Promise.all is causing server 500 errors when using the below API
        for (const group of selectedDocumentGroups) {
            const togglesToCompare = userGroupId && group.initialPermissions ? group.initialPermissions : initialDocumentGroupToggles;

            const permissionDifferences = differenceWith(group.permissions, togglesToCompare, isEqual);

            for (const permission of permissionDifferences) {
                await userManagementApi.updateDocumentGroupPermission({
                    userGroupId: createdUserGroupId,
                    documentGroupId: group.documentGroupId,
                    permissionId: permission.permissionId,
                    hasPermission: permission.hasPermission,
                });
            }
        }

        let alternateNamesDeleteRequests: Promise<boolean>[] = [];

        if (userGroupId) {
            alternateNamesDeleteRequests = alternateNameChangesRef.current
                .filter((change) => change.status === 'delete')
                .map((change) =>
                    userManagementApi.deleteAlternateName({
                        name: change.name,
                        userGroupId,
                    }));
        }

        const alternateNamesAddRequests =
            alternateNameChangesRef.current.map((change) =>
                change.status === 'add' && userManagementApi.createAlternateUserName({
                    name: change.name,
                    userGroupId: createdUserGroupId,
                }));

        await Promise.all(alternateNamesAddRequests);
        await Promise.all(alternateNamesDeleteRequests);
        setLoading(false);
        closePanel();

    }, [
        userGroupId,
        userManagementApi,
        compareGeneralToggleChanges,
        closePanel,
        selectedDocumentGroups,
    ]);


    return (
        <SidePanel className='user-group-panel' onClosePanel={closePanel}>
            {isLoading
                ? <Loader center /> :
                <form
                    className={'user-group-panel__form'}
                    onSubmit={handleSubmit(onSubmitCallback)}
                    ref={formRef}
                >
                    <Typography
                        token={TypographyToken.DesktopHeaderXs}
                        className={'user-group-panel__title'}>{t('create-user-group-panel-header')}
                    </Typography>
                    <fieldset>
                        <Controller
                            name="teamName"
                            control={control}
                            defaultValue=""
                            rules={{ required: t('team-name-is-required-error-message') }}
                            render={({ field: { onChange, value } }) => (
                                <TextBox
                                    value={value}
                                    onChange={(e) => onChange(e.value)}
                                    placeholder={t('team-name-text-label')}
                                />
                            )}
                        />
                        {errors
                            && <Typography
                                token={TypographyToken.DesktopDescriptionMd}
                                text={
                                    errors.teamName?.message}
                                className={'user-group-panel__error-hint'}
                            />
                        }
                    </fieldset>
                    <NavTabs
                        navTabs={userGroupPanelTabs}
                        selectTabHandler={selectTabHandler}
                        currentTab={currentTab}
                    />

                    {currentTab.key === UserGroupsManagementTabsEnum.General && (
                        <GeneralTab handleToggleChange={handleGeneralToggleChange} toggles={generalToggles} />
                    )}
                    {currentTab.key === UserGroupsManagementTabsEnum.UserProfiles && (
                        <UserProfileTab
                            userGroupId={userGroupId}
                            editedMembersRef={editedMembersRef}
                            selectedUsersForUserGroup={selectedUsersForUserGroup}
                            setSelectedUsersForUserGroup={setSelectedUsersForUserGroup}
                        />
                    )}
                    {currentTab.key === UserGroupsManagementTabsEnum.DocumentGroups && (
                        <DocumentGroupTab
                            userGroupId={userGroupId}
                            selectedDocumentGroups={selectedDocumentGroups}
                            setSelectedDocumentGroups={setSelectedDocumentGroups}
                        />
                    )}
                    {currentTab.key === UserGroupsManagementTabsEnum.AlternativeUserGroupNames && (
                        <AlternateNameTab
                            userGroupId={userGroupId}
                            alternateNames={alternateNames}
                            handleSetAlternateNames={handleSetAlternateNames}
                            setInitialAlternateNames={setInitialAlternateNames}
                            handleHasLoadedAlternateNames={handleHasLoadedAlternateNames}
                            hasLoadedAlternateNames={hasLoadedAlternateNames}
                        />
                    )}
                    <EditUserFooter
                        closePanel={() => {
                            (isDirty
                                || editedMembersRef.current.length
                                || compareGeneralToggleChanges().length
                                || compareDocumentGroupChanges()
                                || alternateNameChangesRef.current.length)
                                ?
                                setIsConfirmationModalOpen(true)
                                :
                                closePanel();
                        }}
                        handleOnSaveClick={handleOnSaveClick}
                    />
                </form >
            }
            <OverlayPortal id={'userManagementErrorModal'} type={'modal'} visible={isConfirmationModalOpen}>
                {({ close }) => (
                    <UserManagementModal
                        close={close}
                        isExtraActionModal={true}
                        onButtonClick={confirmationModalOnClickHandler}
                        modalTitleLabel={t('confirm-save-modal-title')}
                        modalContentLabel={t('confirm-save-modal-message')}
                        cancelButtonLabel={tBase('cancel')}
                        secondaryActionButtonLabel={tBase('dont-save-button')}
                        primaryActionButtonLabel={tBase('save-button')}
                        loading={isLoading}
                    />)}

            </OverlayPortal>

        </SidePanel >
    );
};

export default UserGroupPanel;
