import { RefObject, useEffect } from 'react';

const useClickOutside = (ref: RefObject<HTMLElement>, setShowElement: (show: boolean) => void) => {

    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (!ref.current || ref?.current?.contains(event.target as Node)) {
                return;
            }
            setShowElement(false);
        };

        window.addEventListener('mousedown', handleClick);

        return () => {
            window.removeEventListener('mousedown', handleClick);
        };
    }, [ref, setShowElement]);
};

export default useClickOutside;
