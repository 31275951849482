import { NdsButton, NdsDivider } from '@gonitro/rcl';
import { BkgModesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import { Fragment } from 'react';
import MenuDropdown from '~components/menu-dropdown/menu-dropdown';
import MenuGroup from '~components/menu-dropdown/menu-group/menu-group';
import MenuItem from '~components/menu-dropdown/menu-item/menu-item';
import { FlowAction, FlowActionI18n } from '~constants';
import { UserManagementAction, UserManagementActionI18n } from '~constants/user-management-action';
import { useTranslation } from '~contexts/i18n';
import './quickActionsTable.scss';

export type QuickAction<TActionType> = {
    icon: string;
    type: TActionType;
};

export interface QuickActionsTableProps<TActionType> {
    actions: QuickAction<TActionType>[][];
    handleActionClick: (type: TActionType) => void;
}

function QuickActionsTable<TActionType extends FlowAction | UserManagementAction>({ actions, handleActionClick }: QuickActionsTableProps<TActionType>) {
    const { t: tFlow } = useTranslation('flow');
    const { t: tUserManagement } = useTranslation('user-management');

    return (
        <div className={'c-quick-actions-table'} onClick={(e) => e.stopPropagation()}>
            {actions.length > 0 &&
                <MenuDropdown
                    button={(toggleDropdown: () => void) => (
                        <NdsButton
                            bgMode={BkgModesEnum.LIGHT}
                            size={SizesEnums.XSMALL}
                            left-icon='fa-solid-ellipsis-vertical'
                            slot='button'
                            button-type='neutral'
                            button-style='alt'
                            onClick={toggleDropdown}
                        />
                    )}
                >
                    {actions.map((group, idx) => {
                        return <Fragment key={idx}>
                            <MenuGroup>
                                {group.map((el, idx) =>
                                    <MenuItem
                                        key={el.type}
                                        primaryText={tFlow(FlowActionI18n[el.type]) || tUserManagement(UserManagementActionI18n[el.type])}
                                        icon={el.icon}
                                        onClick={() => handleActionClick(el.type)}
                                    />)}
                            </MenuGroup>
                            {idx < actions.length - 1 && (
                                <NdsDivider bgMode={BkgModesEnum.LIGHT} slot={'menu'} />
                            )}
                        </Fragment>;
                    })}
                </MenuDropdown>}
        </div>
    );
}

export default QuickActionsTable;
