/**
* Codes are based on: https://www.iso.org/obp/ui/#search/code/
* Pngs from: https://github.com/hjnilsson/country-flags (100px);
*/
import { CountryInfo } from '~models';

export const countryInfoList: CountryInfo[] = [
    {
        countryName: 'Andorra',
        countryCode: 'AD',
        phonePrefix: '+376',
    },
    {
        countryName: 'United Arab Emirates',
        countryCode: 'AE',
        phonePrefix: '+971',
    },
    {
        countryName: 'Afghanistan',
        countryCode: 'AF',
        phonePrefix: '+93',
    },
    {
        countryName: 'Antigua and Barbuda',
        countryCode: 'AG',
        phonePrefix: '+1268',
    },
    {
        countryName: 'Anguilla',
        countryCode: 'AI',
        phonePrefix: '+1264',
    },
    {
        countryName: 'Albania',
        countryCode: 'AL',
        phonePrefix: '+355',
    },
    {
        countryName: 'Armenia',
        countryCode: 'AM',
        phonePrefix: '+374',
    },
    {
        countryName: 'Angola',
        countryCode: 'AO',
        phonePrefix: '+244',
    },
    {
        countryName: 'Antarctica',
        countryCode: 'AQ',
        phonePrefix: '+672',
    },
    {
        countryName: 'Argentina',
        countryCode: 'AR',
        phonePrefix: '+54',
    },
    {
        countryName: 'American Samoa',
        countryCode: 'AS',
        phonePrefix: '+1684',
    },
    {
        countryName: 'Austria',
        countryCode: 'AT',
        phonePrefix: '+43',
    },
    {
        countryName: 'Australia',
        countryCode: 'AU',
        phonePrefix: '+61',
    },
    {
        countryName: 'Aruba',
        countryCode: 'AW',
        phonePrefix: '+297',
    },
    {
        countryName: 'Åland Islands',
        countryCode: 'AX',
        phonePrefix: '+35818',
    },
    {
        countryName: 'Azerbaijan',
        countryCode: 'AZ',
        phonePrefix: '+994',
    },
    {
        countryName: 'Bosnia and Herzegovina',
        countryCode: 'BA',
        phonePrefix: '+387',
    },
    {
        countryName: 'Barbados',
        countryCode: 'BB',
        phonePrefix: '+1246',
    },
    {
        countryName: 'Bangladesh',
        countryCode: 'BD',
        phonePrefix: '+880',
    },
    {
        countryName: 'Belgium',
        countryCode: 'BE',
        phonePrefix: '+32',
    },
    {
        countryName: 'Burkina Faso',
        countryCode: 'BF',
        phonePrefix: '+226',
    },
    {
        countryName: 'Bulgaria',
        countryCode: 'BG',
        phonePrefix: '+359',
    },
    {
        countryName: 'Bahrain',
        countryCode: 'BH',
        phonePrefix: '+973',
    },
    {
        countryName: 'Burundi',
        countryCode: 'BI',
        phonePrefix: '+257',
    },
    {
        countryName: 'Benin',
        countryCode: 'BJ',
        phonePrefix: '+229',
    },
    {
        countryName: 'Saint Barthélemy',
        countryCode: 'BL',
        phonePrefix: '+590',
    },
    {
        countryName: 'Bermuda',
        countryCode: 'BM',
        phonePrefix: '+1441',
    },
    {
        countryName: 'Brunei',
        countryCode: 'BN',
        phonePrefix: '+673',
    },
    {
        countryName: 'Bolivia',
        countryCode: 'BO',
        phonePrefix: '+591',
    },
    {
        countryName: 'British Antarctic Territory',
        countryCode: 'BQ',
        phonePrefix: '+6721',
    },
    {
        countryName: 'Brazil',
        countryCode: 'BR',
        phonePrefix: '+55',
    },
    {
        countryName: 'Bahamas',
        countryCode: 'BS',
        phonePrefix: '+1242',
    },
    {
        countryName: 'Bhutan',
        countryCode: 'BT',
        phonePrefix: '+975',
    },
    {
        countryName: 'Botswana',
        countryCode: 'BW',
        phonePrefix: '+267',
    },
    {
        countryName: 'Belarus',
        countryCode: 'BY',
        phonePrefix: '+375',
    },
    {
        countryName: 'Belize',
        countryCode: 'BZ',
        phonePrefix: '+501',
    },
    {
        countryName: 'Canada',
        countryCode: 'CA',
        phonePrefix: '+1',
    },
    {
        countryName: 'Cocos [Keeling] Islands',
        countryCode: 'CC',
        phonePrefix: '+61',
    },
    {
        countryName: 'Congo - Kinshasa',
        countryCode: 'CD',
        phonePrefix: '+243',
    },
    {
        countryName: 'Central African Republic',
        countryCode: 'CF',
        phonePrefix: '+236',
    },
    {
        countryName: 'Congo - Brazzaville',
        countryCode: 'CG',
        phonePrefix: '+242',
    },
    {
        countryName: 'Switzerland',
        countryCode: 'CH',
        phonePrefix: '+41',
    },
    {
        countryName: 'Côte d’Ivoire',
        countryCode: 'CI',
        phonePrefix: '+225',
    },
    {
        countryName: 'Cook Islands',
        countryCode: 'CK',
        phonePrefix: '+682',
    },
    {
        countryName: 'Chile',
        countryCode: 'CL',
        phonePrefix: '+56',
    },
    {
        countryName: 'Cameroon',
        countryCode: 'CM',
        phonePrefix: '+237',
    },
    {
        countryName: 'China',
        countryCode: 'CN',
        phonePrefix: '+86',
    },
    {
        countryName: 'Colombia',
        countryCode: 'CO',
        phonePrefix: '+57',
    },
    {
        countryName: 'Costa Rica',
        countryCode: 'CR',
        phonePrefix: '+506',
    },
    {
        countryName: 'Cuba',
        countryCode: 'CU',
        phonePrefix: '+53',
    },
    {
        countryName: 'Cape Verde',
        countryCode: 'CV',
        phonePrefix: '+238',
    },
    {
        countryName: 'Christmas Island',
        countryCode: 'CX',
        phonePrefix: '+61',
    },
    {
        countryName: 'Cyprus',
        countryCode: 'CY',
        phonePrefix: '+357',
    },
    {
        countryName: 'Czech Republic',
        countryCode: 'CZ',
        phonePrefix: '+420',
    },
    {
        countryName: 'Germany',
        countryCode: 'DE',
        phonePrefix: '+49',
    },
    {
        countryName: 'Djibouti',
        countryCode: 'DJ',
        phonePrefix: '+253',
    },
    {
        countryName: 'Denmark',
        countryCode: 'DK',
        phonePrefix: '+45',
    },
    {
        countryName: 'Dominica',
        countryCode: 'DM',
        phonePrefix: '+1767',
    },
    {
        countryName: 'Algeria',
        countryCode: 'DZ',
        phonePrefix: '+213',
    },
    {
        countryName: 'Ecuador',
        countryCode: 'EC',
        phonePrefix: '+593',
    },
    {
        countryName: 'Estonia',
        countryCode: 'EE',
        phonePrefix: '+372',
    },
    {
        countryName: 'Egypt',
        countryCode: 'EG',
        phonePrefix: '+20',
    },
    {
        countryName: 'Western Sahara',
        countryCode: 'EH',
        phonePrefix: '+212',
    },
    {
        countryName: 'Eritrea',
        countryCode: 'ER',
        phonePrefix: '+291',
    },
    {
        countryName: 'Spain',
        countryCode: 'ES',
        phonePrefix: '+34',
    },
    {
        countryName: 'Ethiopia',
        countryCode: 'ET',
        phonePrefix: '+251',
    },
    {
        countryName: 'Finland',
        countryCode: 'FI',
        phonePrefix: '+358',
    },
    {
        countryName: 'Fiji',
        countryCode: 'FJ',
        phonePrefix: '+679',
    },
    {
        countryName: 'Falkland Islands',
        countryCode: 'FK',
        phonePrefix: '+500',
    },
    {
        countryName: 'Micronesia',
        countryCode: 'FM',
        phonePrefix: '+691',
    },
    {
        countryName: 'Faroe Islands',
        countryCode: 'FO',
        phonePrefix: '+298',
    },
    {
        countryName: 'France',
        countryCode: 'FR',
        phonePrefix: '+33',
    },
    {
        countryName: 'Gabon',
        countryCode: 'GA',
        phonePrefix: '+241',
    },
    {
        countryName: 'Grenada',
        countryCode: 'GD',
        phonePrefix: '+1473',
    },
    {
        countryName: 'Georgia',
        countryCode: 'GE',
        phonePrefix: '+995',
    },
    {
        countryName: 'French Guiana',
        countryCode: 'GF',
        phonePrefix: '+594',
    },
    {
        countryName: 'Guernsey',
        countryCode: 'GG',
        phonePrefix: '+441481',
    },
    {
        countryName: 'Ghana',
        countryCode: 'GH',
        phonePrefix: '+233',
    },
    {
        countryName: 'Gibraltar',
        countryCode: 'GI',
        phonePrefix: '+350',
    },
    {
        countryName: 'Greenland',
        countryCode: 'GL',
        phonePrefix: '+299',
    },
    {
        countryName: 'Gambia',
        countryCode: 'GM',
        phonePrefix: '+220',
    },
    {
        countryName: 'Guinea',
        countryCode: 'GN',
        phonePrefix: '+224',
    },
    {
        countryName: 'Guadeloupe',
        countryCode: 'GP',
        phonePrefix: '+590',
    },
    {
        countryName: 'Equatorial Guinea',
        countryCode: 'GQ',
        phonePrefix: '+240',
    },
    {
        countryName: 'Greece',
        countryCode: 'GR',
        phonePrefix: '+30',
    },
    {
        countryName: 'South Georgia and the South Sandwich Islands',
        countryCode: 'GS',
        phonePrefix: '+500',
    },
    {
        countryName: 'Guatemala',
        countryCode: 'GT',
        phonePrefix: '+502',
    },
    {
        countryName: 'Guam',
        countryCode: 'GU',
        phonePrefix: '+1671',
    },
    {
        countryName: 'Guinea-Bissau',
        countryCode: 'GW',
        phonePrefix: '+245',
    },
    {
        countryName: 'Guyana',
        countryCode: 'GY',
        phonePrefix: '+592',
    },
    {
        countryName: 'Hong Kong SAR of China',
        countryCode: 'HK',
        phonePrefix: '+852',
    },
    {
        countryName: 'Honduras',
        countryCode: 'HN',
        phonePrefix: '+504',
    },
    {
        countryName: 'Croatia',
        countryCode: 'HR',
        phonePrefix: '+385',
    },
    {
        countryName: 'Haiti',
        countryCode: 'HT',
        phonePrefix: '+509',
    },
    {
        countryName: 'Hungary',
        countryCode: 'HU',
        phonePrefix: '+36',
    },
    {
        countryName: 'Indonesia',
        countryCode: 'ID',
        phonePrefix: '+62',
    },
    {
        countryName: 'Ireland',
        countryCode: 'IE',
        phonePrefix: '+353',
    },
    {
        countryName: 'Israel',
        countryCode: 'IL',
        phonePrefix: '+972',
    },
    {
        countryName: 'Isle of Man',
        countryCode: 'IM',
        phonePrefix: '+441624',
    },
    {
        countryName: 'India',
        countryCode: 'IN',
        phonePrefix: '+91',
    },
    {
        countryName: 'British Indian Ocean Territory',
        countryCode: 'IO',
        phonePrefix: '+246',
    },
    {
        countryName: 'Iraq',
        countryCode: 'IQ',
        phonePrefix: '+964',
    },
    {
        countryName: 'Iran',
        countryCode: 'IR',
        phonePrefix: '+98',
    },
    {
        countryName: 'Iceland',
        countryCode: 'IS',
        phonePrefix: '+354',
    },
    {
        countryName: 'Italy',
        countryCode: 'IT',
        phonePrefix: '+39',
    },
    {
        countryName: 'Jersey',
        countryCode: 'JE',
        phonePrefix: '+441534',
    },
    {
        countryName: 'Jamaica',
        countryCode: 'JM',
        phonePrefix: '+1876',
    },
    {
        countryName: 'Jordan',
        countryCode: 'JO',
        phonePrefix: '+962',
    },
    {
        countryName: 'Japan',
        countryCode: 'JP',
        phonePrefix: '+81',
    },
    {
        countryName: 'Kenya',
        countryCode: 'KE',
        phonePrefix: '+254',
    },
    {
        countryName: 'Kyrgyzstan',
        countryCode: 'KG',
        phonePrefix: '+996',
    },
    {
        countryName: 'Cambodia',
        countryCode: 'KH',
        phonePrefix: '+855',
    },
    {
        countryName: 'Kiribati',
        countryCode: 'KI',
        phonePrefix: '+686',
    },
    {
        countryName: 'Comoros',
        countryCode: 'KM',
        phonePrefix: '+269',
    },
    {
        countryName: 'Saint Kitts and Nevis',
        countryCode: 'KN',
        phonePrefix: '+1869',
    },
    {
        countryName: 'South Korea',
        countryCode: 'KP',
        phonePrefix: '+82',
    },
    {
        countryName: 'Kuwait',
        countryCode: 'KW',
        phonePrefix: '+965',
    },
    {
        countryName: 'Cayman Islands',
        countryCode: 'KY',
        phonePrefix: '+1345',
    },
    {
        countryName: 'Kazakhstan',
        countryCode: 'KZ',
        phonePrefix: '+7',
    },
    {
        countryName: 'Laos',
        countryCode: 'LA',
        phonePrefix: '+856',
    },
    {
        countryName: 'Lebanon',
        countryCode: 'LB',
        phonePrefix: '+961',
    },
    {
        countryName: 'Saint Lucia',
        countryCode: 'LC',
        phonePrefix: '+1758',
    },
    {
        countryName: 'Liechtenstein',
        countryCode: 'LI',
        phonePrefix: '+423',
    },
    {
        countryName: 'Sri Lanka',
        countryCode: 'LK',
        phonePrefix: '+94',
    },
    {
        countryName: 'Liberia',
        countryCode: 'LR',
        phonePrefix: '+231',
    },
    {
        countryName: 'Lesotho',
        countryCode: 'LS',
        phonePrefix: '+266',
    },
    {
        countryName: 'Lithuania',
        countryCode: 'LT',
        phonePrefix: '+370',
    },
    {
        countryName: 'Luxembourg',
        countryCode: 'LU',
        phonePrefix: '+352',
    },
    {
        countryName: 'Latvia',
        countryCode: 'LV',
        phonePrefix: '+371',
    },
    {
        countryName: 'Libya',
        countryCode: 'LY',
        phonePrefix: '+218',
    },
    {
        countryName: 'Morocco',
        countryCode: 'MA',
        phonePrefix: '+212',
    },
    {
        countryName: 'Monaco',
        countryCode: 'MC',
        phonePrefix: '+377',
    },
    {
        countryName: 'Moldova',
        countryCode: 'MD',
        phonePrefix: '+373',
    },
    {
        countryName: 'Montenegro',
        countryCode: 'ME',
        phonePrefix: '+382',
    },
    {
        countryName: 'Saint Martin',
        countryCode: 'MF',
        phonePrefix: '+590',
    },
    {
        countryName: 'Madagascar',
        countryCode: 'MG',
        phonePrefix: '+261',
    },
    {
        countryName: 'Marshall Islands',
        countryCode: 'MH',
        phonePrefix: '+692',
    },
    {
        countryName: 'Macedonia',
        countryCode: 'MK',
        phonePrefix: '+389',
    },
    {
        countryName: 'Mali',
        countryCode: 'ML',
        phonePrefix: '+223',
    },
    {
        countryName: 'Myanmar [Burma]',
        countryCode: 'MM',
        phonePrefix: '+95',
    },
    {
        countryName: 'Mongolia',
        countryCode: 'MN',
        phonePrefix: '+976',
    },
    {
        countryName: 'Macau SAR China',
        countryCode: 'MO',
        phonePrefix: '+853',
    },
    {
        countryName: 'Northern Mariana Islands',
        countryCode: 'MP',
        phonePrefix: '+1670',
    },
    {
        countryName: 'Martinique',
        countryCode: 'MQ',
        phonePrefix: '+596',
    },
    {
        countryName: 'Mauritania',
        countryCode: 'MR',
        phonePrefix: '+222',
    },
    {
        countryName: 'Montserrat',
        countryCode: 'MS',
        phonePrefix: '+1664',
    },
    {
        countryName: 'Malta',
        countryCode: 'MT',
        phonePrefix: '+356',
    },
    {
        countryName: 'Mauritius',
        countryCode: 'MU',
        phonePrefix: '+230',
    },
    {
        countryName: 'Maldives',
        countryCode: 'MV',
        phonePrefix: '+960',
    },
    {
        countryName: 'Malawi',
        countryCode: 'MW',
        phonePrefix: '+265',
    },
    {
        countryName: 'Mexico',
        countryCode: 'MX',
        phonePrefix: '+52',
    },
    {
        countryName: 'Malaysia',
        countryCode: 'MY',
        phonePrefix: '+60',
    },
    {
        countryName: 'Mozambique',
        countryCode: 'MZ',
        phonePrefix: '+258',
    },
    {
        countryName: 'Namibia',
        countryCode: 'NA',
        phonePrefix: '+264',
    },
    {
        countryName: 'New Caledonia',
        countryCode: 'NC',
        phonePrefix: '+687',
    },
    {
        countryName: 'Niger',
        countryCode: 'NE',
        phonePrefix: '+227',
    },
    {
        countryName: 'Norfolk Island',
        countryCode: 'NF',
        phonePrefix: '+6723',
    },
    {
        countryName: 'Nigeria',
        countryCode: 'NG',
        phonePrefix: '+234',
    },
    {
        countryName: 'Nicaragua',
        countryCode: 'NI',
        phonePrefix: '+505',
    },
    {
        countryName: 'Netherlands',
        countryCode: 'NL',
        phonePrefix: '+31',
    },
    {
        countryName: 'Norway',
        countryCode: 'NO',
        phonePrefix: '+47',
    },
    {
        countryName: 'Nepal',
        countryCode: 'NP',
        phonePrefix: '+977',
    },
    {
        countryName: 'Nauru',
        countryCode: 'NR',
        phonePrefix: '+674',
    },
    {
        countryName: 'Niue',
        countryCode: 'NU',
        phonePrefix: '+683',
    },
    {
        countryName: 'New Zealand',
        countryCode: 'NZ',
        phonePrefix: '+64',
    },
    {
        countryName: 'Oman',
        countryCode: 'OM',
        phonePrefix: '+968',
    },
    {
        countryName: 'Panama',
        countryCode: 'PA',
        phonePrefix: '+507',
    },
    {
        countryName: 'Peru',
        countryCode: 'PE',
        phonePrefix: '+51',
    },
    {
        countryName: 'French Polynesia',
        countryCode: 'PF',
        phonePrefix: '+689',
    },
    {
        countryName: 'Papua New Guinea',
        countryCode: 'PG',
        phonePrefix: '+675',
    },
    {
        countryName: 'Philippines',
        countryCode: 'PH',
        phonePrefix: '+63',
    },
    {
        countryName: 'Pakistan',
        countryCode: 'PK',
        phonePrefix: '+92',
    },
    {
        countryName: 'Poland',
        countryCode: 'PL',
        phonePrefix: '+48',
    },
    {
        countryName: 'Saint Pierre and Miquelon',
        countryCode: 'PM',
        phonePrefix: '+508',
    },
    {
        countryName: 'Pitcairn Islands',
        countryCode: 'PN',
        phonePrefix: '+64',
    },
    {
        countryName: 'Palestinian Territories',
        countryCode: 'PS',
        phonePrefix: '+970',
    },
    {
        countryName: 'Portugal',
        countryCode: 'PT',
        phonePrefix: '+351',
    },
    {
        countryName: 'Palau',
        countryCode: 'PW',
        phonePrefix: '+680',
    },
    {
        countryName: 'Paraguay',
        countryCode: 'PY',
        phonePrefix: '+595',
    },
    {
        countryName: 'Qatar',
        countryCode: 'QA',
        phonePrefix: '+974',
    },
    {
        countryName: 'Réunion',
        countryCode: 'RE',
        phonePrefix: '+262',
    },
    {
        countryName: 'Romania',
        countryCode: 'RO',
        phonePrefix: '+40',
    },
    {
        countryName: 'Serbia',
        countryCode: 'RS',
        phonePrefix: '+381',
    },
    {
        countryName: 'Russia',
        countryCode: 'RU',
        phonePrefix: '+7',
    },
    {
        countryName: 'Rwanda',
        countryCode: 'RW',
        phonePrefix: '+250',
    },
    {
        countryName: 'Saudi Arabia',
        countryCode: 'SA',
        phonePrefix: '+966',
    },
    {
        countryName: 'Solomon Islands',
        countryCode: 'SB',
        phonePrefix: '+677',
    },
    {
        countryName: 'Seychelles',
        countryCode: 'SC',
        phonePrefix: '+248',
    },
    {
        countryName: 'Sudan',
        countryCode: 'SD',
        phonePrefix: '+249',
    },
    {
        countryName: 'Sweden',
        countryCode: 'SE',
        phonePrefix: '+46',
    },
    {
        countryName: 'Singapore',
        countryCode: 'SG',
        phonePrefix: '+65',
    },
    {
        countryName: 'Saint Helena',
        countryCode: 'SH',
        phonePrefix: '+290',
    },
    {
        countryName: 'Slovenia',
        countryCode: 'SI',
        phonePrefix: '+386',
    },
    {
        countryName: 'Svalbard and Jan Mayen',
        countryCode: 'SJ',
        phonePrefix: '+47',
    },
    {
        countryName: 'Slovakia',
        countryCode: 'SK',
        phonePrefix: '+421',
    },
    {
        countryName: 'Sierra Leone',
        countryCode: 'SL',
        phonePrefix: '+232',
    },
    {
        countryName: 'San Marino',
        countryCode: 'SM',
        phonePrefix: '+378',
    },
    {
        countryName: 'Senegal',
        countryCode: 'SN',
        phonePrefix: '+221',
    },
    {
        countryName: 'Somalia',
        countryCode: 'SO',
        phonePrefix: '+252',
    },
    {
        countryName: 'Suriname',
        countryCode: 'SR',
        phonePrefix: '+597',
    },
    {
        countryName: 'São Tomé and Príncipe',
        countryCode: 'ST',
        phonePrefix: '+239',
    },
    {
        countryName: 'El Salvador',
        countryCode: 'SV',
        phonePrefix: '+503',
    },
    {
        countryName: 'Syria',
        countryCode: 'SY',
        phonePrefix: '+963',
    },
    {
        countryName: 'Swaziland',
        countryCode: 'SZ',
        phonePrefix: '+268',
    },
    {
        countryName: 'Turks and Caicos Islands',
        countryCode: 'TC',
        phonePrefix: '+1649',
    },
    {
        countryName: 'Chad',
        countryCode: 'TD',
        phonePrefix: '+235',
    },
    {
        countryName: 'Togo',
        countryCode: 'TG',
        phonePrefix: '+228',
    },
    {
        countryName: 'Thailand',
        countryCode: 'TH',
        phonePrefix: '+66',
    },
    {
        countryName: 'Tajikistan',
        countryCode: 'TJ',
        phonePrefix: '+992',
    },
    {
        countryName: 'Tokelau',
        countryCode: 'TK',
        phonePrefix: '+690',
    },
    {
        countryName: 'Timor-Leste',
        countryCode: 'TL',
        phonePrefix: '+670',
    },
    {
        countryName: 'Turkmenistan',
        countryCode: 'TM',
        phonePrefix: '+993',
    },
    {
        countryName: 'Tunisia',
        countryCode: 'TN',
        phonePrefix: '+216',
    },
    {
        countryName: 'Tonga',
        countryCode: 'TO',
        phonePrefix: '+676',
    },
    {
        countryName: 'Turkey',
        countryCode: 'TR',
        phonePrefix: '+90',
    },
    {
        countryName: 'Trinidad and Tobago',
        countryCode: 'TT',
        phonePrefix: '+1868',
    },
    {
        countryName: 'Tuvalu',
        countryCode: 'TV',
        phonePrefix: '+688',
    },
    {
        countryName: 'Taiwan',
        countryCode: 'TW',
        phonePrefix: '+886',
    },
    {
        countryName: 'Tanzania',
        countryCode: 'TZ',
        phonePrefix: '+255',
    },
    {
        countryName: 'Ukraine',
        countryCode: 'UA',
        phonePrefix: '+380',
    },
    {
        countryName: 'Uganda',
        countryCode: 'UG',
        phonePrefix: '+256',
    },
    {
        countryName: 'United Kingdom',
        countryCode: 'UK',
        phonePrefix: '+44',
    },
    {
        countryName: 'United States',
        countryCode: 'US',
        phonePrefix: '+1',
    },
    {
        countryName: 'Uruguay',
        countryCode: 'UY',
        phonePrefix: '+598',
    },
    {
        countryName: 'Uzbekistan',
        countryCode: 'UZ',
        phonePrefix: '+998',
    },
    {
        countryName: 'Vatican City',
        countryCode: 'VA',
        phonePrefix: '+379',
    },
    {
        countryName: 'Saint Vincent and the Grenadines',
        countryCode: 'VC',
        phonePrefix: '+1784',
    },
    {
        countryName: 'Venezuela',
        countryCode: 'VE',
        phonePrefix: '+58',
    },
    {
        countryName: 'British Virgin Islands',
        countryCode: 'VG',
        phonePrefix: '+1284',
    },
    {
        countryName: 'U.S. Virgin Islands',
        countryCode: 'VI',
        phonePrefix: '+1340',
    },
    {
        countryName: 'Vietnam',
        countryCode: 'VN',
        phonePrefix: '+84',
    },
    {
        countryName: 'Vanuatu',
        countryCode: 'VU',
        phonePrefix: '+678',
    },
    {
        countryName: 'Wallis and Futuna',
        countryCode: 'WF',
        phonePrefix: '+681',
    },
    {
        countryName: 'Samoa',
        countryCode: 'WS',
        phonePrefix: '+685',
    },
    {
        countryName: 'Yemen',
        countryCode: 'YE',
        phonePrefix: '+967',
    },
    {
        countryName: 'Mayotte',
        countryCode: 'YT',
        phonePrefix: '+262',
    },
    {
        countryName: 'South Africa',
        countryCode: 'ZA',
        phonePrefix: '+27',
    },
    {
        countryName: 'Zambia',
        countryCode: 'ZM',
        phonePrefix: '+260',
    },
    {
        countryName: 'Zimbabwe',
        countryCode: 'ZW',
        phonePrefix: '+263',
    },
];

//List should be sorted by countryName (more user friendly)
countryInfoList.sort((lhs, rhs) => {
    return lhs.countryName.localeCompare(rhs.countryName);
});

// Create a dictionary from our country info list for quick lookups!
export const countryInfoDict = countryInfoList.reduce<{ [code: string]: CountryInfo; }>((dict, countryInfoObj) => {
    dict[countryInfoObj.countryCode] = countryInfoObj;

    return dict;
}, {});
