import { FlowActionType, FlowReducerFunc } from '~contexts/flow/flow.types';
import { StringUtils } from '~lib';
import { FlowStakeholderModel, StakeholderModel, StakeholderType } from '~models';

export type FlowReducerStakeholderActionPayloads = {
    [FlowActionType.AddStakeholder]: StakeholderModel | FlowStakeholderModel;
    [FlowActionType.UpdateStakeholder]: FlowStakeholderModel;
    [FlowActionType.SetStakeholders]: StakeholderModel[] | FlowStakeholderModel[];
}

export const AddStakeholderReducer: FlowReducerFunc<FlowActionType.AddStakeholder> = (
    state,
    payload,
) => {
    let data: FlowStakeholderModel;

    if (!('localId' in payload)) {
        data = {
            ...payload,
            localId: StringUtils.guid(),
        };
    } else {
        data = payload;
    }

    return {
        ...state,
        stakeholders: [...state.stakeholders, data],
    };
};

export const UpdateStakeholderReducer: FlowReducerFunc<FlowActionType.UpdateStakeholder> = (
    state,
    payload,
) => {
    const stakeholders = state.stakeholders.slice();

    if (payload.type !== StakeholderType.Undecided && 'localId' in payload) {
        const index = stakeholders.findIndex((stakeholder) => {
            return stakeholder.localId === payload.localId;
        });

        if (index > -1) {
            stakeholders[index] = payload;
        }
    }

    return {
        ...state,
        stakeholders,
    };
};

export const SetStakeholdersReducer: FlowReducerFunc<FlowActionType.SetStakeholders> = (
    state,
    payload,
) => {
    const orgStakeholders = state.stakeholders;
    const stakeholders: FlowStakeholderModel[] = payload.map((newStakeholder) => {
        const localId = 'localId' in newStakeholder && newStakeholder.localId
            ? newStakeholder.localId
            : orgStakeholders.find(el => el.id === newStakeholder.id)?.localId ?? StringUtils.guid();

        return {
            ...newStakeholder,
            localId,
        };
    });


    return {
        ...state,
        stakeholders,
    };
};
