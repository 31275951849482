import { cloneDeep } from 'lodash';
import { ActorType } from '~constants';
import { FieldType } from '~constants/field-type';
import { ActorTypeToFlowGroupType, FlowGroupType } from '~contexts/flow/flow.types';
import { FlowActorModel, FlowModel, Guid, Size } from '~models';

export namespace FlowUtils {

    export const findFlowActorGroupIndexes = (flow: FlowModel, actorType: ActorType | FlowGroupType) => {
        const indexes = findFlowActorGroupsIndexes(flow);

        if (Object.values(ActorType).includes(actorType as ActorType)) {
            return indexes[ActorTypeToFlowGroupType[actorType as ActorType]];
        } else {
            return indexes[actorType as FlowGroupType];
        }
    };
    export const findFlowActorGroupsIndexes = (flow: FlowModel) => {
        let firstFfaIndex = -1;
        let lastFfaIndex = -1;
        let asNewFfaIndex = flow.length;
        let firstSIndex = -1;
        let lastSIndex = -1;
        let asNewSIndex = flow.length;
        let firstRIndex = -1;
        let lastRIndex = -1;
        let asNewRIndex = flow.length;

        let prevGroupType: FlowGroupType | null = null;
        for (let i = 0; i < flow.length; i++) {
            const groupType = ActorTypeToFlowGroupType[flow[i][0].actorType];

            if (!prevGroupType || prevGroupType !== groupType) {
                switch (groupType) {
                    case FlowGroupType.FormFillersApprovers:
                        firstFfaIndex = i;
                        lastFfaIndex = i;
                        break;
                    case FlowGroupType.Signers:
                        asNewFfaIndex = i;
                        firstSIndex = i;
                        lastSIndex = i;
                        break;
                    case FlowGroupType.Receivers:
                        if (firstSIndex < 0) {
                            asNewFfaIndex = i;
                        }
                        firstRIndex = i;
                        lastRIndex = i;
                        asNewSIndex = i;
                        asNewRIndex = i; // receivers can have only one step
                        break;
                }
            } else {
                switch (groupType) {
                    case FlowGroupType.FormFillersApprovers:
                        lastFfaIndex = i;
                        break;
                    case FlowGroupType.Signers:
                        lastSIndex = i;
                        break;
                    case FlowGroupType.Receivers:
                        lastRIndex = i;
                        break;
                }
            }

            prevGroupType = groupType;
        }

        return {
            [FlowGroupType.FormFillersApprovers]: {
                first: firstFfaIndex,
                last: lastFfaIndex,
                asNew: asNewFfaIndex,
            },
            [FlowGroupType.Signers]: {
                first: firstSIndex,
                last: lastSIndex,
                asNew: asNewSIndex,
            },
            [FlowGroupType.Receivers]: {
                first: firstRIndex,
                last: lastRIndex,
                asNew: asNewRIndex,
            },
        };
    };

    export type FindActorResult = {
        actor: FlowActorModel,
        stepIndex: number,
        actorIndex: number,
        isAlone: boolean,
    }
    export const findActor = (flow: FlowModel, actorId: Guid): FindActorResult | null => {
        for (let i = 0; i < flow.length; i++) {
            for (let j = 0; j < flow[i].length; j++) {
                if (flow[i][j].localId === actorId) {
                    return {
                        actor: cloneDeep(flow[i][j]),
                        stepIndex: i,
                        actorIndex: j,
                        isAlone: flow[i].length === 1,
                    };
                }
            }
        }

        return null;
    };

    export const findActorByElementId = (flow: FlowModel, elementId: Guid): FindActorResult | null => {
        for (let i = 0; i < flow.length; i++) {
            for (let j = 0; j < flow[i].length; j++) {
                const actor = flow[i][j];

                if (actor.actorType === ActorType.FormFiller || actor.actorType === ActorType.Signer) {
                    if (actor.elements && actor.elements.length > 0) {
                        for (let z = 0; z < actor.elements.length; z++) {
                            if (actor.elements[z] === elementId) {
                                return {
                                    actor: cloneDeep(flow[i][j]),
                                    stepIndex: i,
                                    actorIndex: j,
                                    isAlone: flow[i].length === 1,
                                };
                            }
                        }
                    }
                }
            }
        }

        return null;
    };

    export const findStakeholderActors = (flow: FlowModel, stakeholderLocalId?: Guid): FindActorResult[] => {
        const findings: FindActorResult[] = [];
        for (let i = 0; i < flow.length; i++) {
            for (let j = 0; j < flow[i].length; j++) {
                if (flow[i][j].stakeholderLocalId === stakeholderLocalId) {
                    findings.push({
                        actor: cloneDeep(flow[i][j]),
                        stepIndex: i,
                        actorIndex: j,
                        isAlone: flow[i].length === 1,
                    });
                }
            }
        }

        return findings;
    };


    export const fieldDefaultSize = (type: FieldType): Size => {
        switch (type) {
            case FieldType.TextBox: {
                return {
                    width: 265,
                    height: 32,
                };
            }
            case FieldType.CheckBox:
                return {
                    width: 32,
                    height: 32,
                };
            case FieldType.RadioGroup:
                return {
                    width: 32,
                    height: 32,
                };
            case FieldType.SigningField:
                return {
                    width: 265,
                    height: 165,
                };
        }
    };
}
