import { Modal, ModalContent, ModalFooter, NdsButton } from '@gonitro/rcl';
import { BkgModesEnum, NdsButtonTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import classNames from 'classnames';
import { useState } from 'react';
import { UserManagementApi } from '~api/user-management.api';
import Loader from '~components/loader';
import Typography, { TypographyToken } from '~components/typography';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { useTableContext } from '~contexts/table';
import { DocumentGroupsListTableDataModel } from '~models/user-management.models';
import './deleteDocumentGroupModal.scss';

export interface DeleteDocumentGroupModalProps {
    onCloseModalClick: () => void;
    documentGroup?: DocumentGroupsListTableDataModel;
    openDeleteModal: boolean;
}

function DeleteDocumentGroupModal({ onCloseModalClick, documentGroup, openDeleteModal }: DeleteDocumentGroupModalProps) {
    const userManagementApi = useApi(UserManagementApi);
    const { t: tBase } = useTranslation('base');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { refresh } = useTableContext();

    const handleDeleteDocumentGroup = async (close: () => void) => {
        if (documentGroup?.documentGroupId) {
            setIsLoading(true);
            try {
                await userManagementApi.deleteDocumentGroup({ documentGroupId: documentGroup.documentGroupId });
                refresh();
                onCloseModalClick();
                close();
            } catch (error) {
                console.error('Error deleting the contact group:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <OverlayPortal id={`deleteDocumentGroupModal-${documentGroup?.documentGroupId}`} type={'modal'} visible={openDeleteModal}>
            {({ close }) => (
                <Modal
                    headerLabel={'Delete document group'}
                    bkgMode={BkgModesEnum.LIGHT}
                    size={SizesEnums.SMALL}
                    onCloseClick={() => {
                        onCloseModalClick();
                        close();
                    }}
                    withClose={true}
                    className={'c-delete-document-group-modal'}
                    renderContent={
                        <ModalContent>
                            <div className={'c-delete-document-group-modal__content'}>
                                <Typography
                                    className={'c-delete-document-group-modal__warning-message'}
                                    tagName='div'
                                    token={TypographyToken.DesktopDescriptionLg}
                                    text={'Are you sure you want to delete this document group?'}
                                />
                                <div className={'c-delete-document-group-modal__item'}>
                                    <div className={'c-delete-document-group-modal__info'}>
                                        <Typography
                                            className={'c-delete-document-group-modal__name'}
                                            tagName='div'
                                            token={TypographyToken.UiMenuMd}
                                            text={documentGroup?.name}
                                        />
                                    </div>
                                </div>
                            </div>
                        </ModalContent>
                    }
                    renderFooter={
                        <ModalFooter>
                            <NdsButton
                                size={SizesEnums.SMALL}
                                label={tBase('cancel')}
                                buttonType={NdsButtonTypesEnum.NEUTRAL}
                                onClick={() => {
                                    onCloseModalClick();
                                    close();
                                }}
                            />
                            <div className={classNames('c-loader-wrapper', { 'c-loader-wrapper--show': isLoading })}>
                                <NdsButton
                                    size={SizesEnums.SMALL}
                                    label={tBase('delete')}
                                    onClick={() => handleDeleteDocumentGroup(close)}
                                />
                                <Loader center size={SizesEnums.SMALL} />
                            </div>

                        </ModalFooter>
                    }
                />)}
        </OverlayPortal>
    );
}

export default DeleteDocumentGroupModal;
