import { HTMLAttributes, PropsWithChildren } from 'react';
import Typography, { TypographyToken } from '~components/typography';
import { ReactComponent as NoResultsIcon } from './../../resources/noDataLightLarge.svg';
import './noResultsCreatedYet.scss';

export interface NoResultsCreatedProps extends HTMLAttributes<HTMLElement>, PropsWithChildren {
    title: string;
    description: string;
}

function NoResultsCreatedYet({ title, description, children }: NoResultsCreatedProps) {
    return (
        <div className={'c-no-results-created-yet'}>
            <div className={'c-no-results-created-yet__inner'}>
                <NoResultsIcon />
                <div className={'c-no-results-created-yet__info'}>
                    <Typography
                        token={TypographyToken.MobileHeaderSm}
                        mediumScreenToken={TypographyToken.DesktopHeaderSm}
                    >
                        {title}
                    </Typography>

                    <Typography
                        token={TypographyToken.MobileDescriptionMd}
                        mediumScreenToken={TypographyToken.DesktopDescriptionMd}
                    >
                        {description}
                    </Typography>
                    {children && (
                        <div className={'c-no-results-created-yet__children'}>
                            {children}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default NoResultsCreatedYet;
