import { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { AppContextProvider } from '~contexts/app';
import { useAuthContext } from '~contexts/auth';

export interface AuthorizedPageContainerProps extends PropsWithChildren {

}

/**
 *
 * @param children
 * @param props
 * @constructor
 */
export function AuthorizedPageContainer({ children, ...props }: AuthorizedPageContainerProps) {
    const authContext = useAuthContext();
    const location = useLocation();

    if (!authContext.isAuthenticated) {
        return <Navigate to={'/login'} state={{ from: location.pathname + (location.search ?? '') }} replace />;
    }

    // layout component props are just example that this ct drives layout container stuff
    return (
        <AppContextProvider>
            {children}
        </AppContextProvider>
    );
}
