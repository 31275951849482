
import { UrlUtil } from '~lib';
import buildQueryParams = UrlUtil.buildQueryParams;

export namespace AppUrlsUtils {

    export const getBaseRedirectUrl = (
        action: string,
        actionId: string,
        personId: string,
        token: string,
        expiryTag: string,
    ): string => {
        return `public/actioninit?${buildQueryParams({
            type: action,
            actionId: actionId,
            personId: personId,
            expiryTag:expiryTag,
            token:token,
        })}`;
    };

    export const getDownloadUrl = (packageId: string, token: string, expiryTag: string): string => {
        return `downloadapi/services/packages/${packageId}/download/${token}?${buildQueryParams({
            fromPortal: 'true',
            expiryTag: expiryTag,
        })}`;
    };
}
