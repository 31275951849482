import { LanguageDataLabel } from '~models';

export enum ContactConflictProperties {
     Title = 'personalTitle',
     FirstName = 'firstName',
     LastName = 'lastName',
     BirthDate = 'birthDate',
     EmailAddress = 'emailAddress',
     PhoneNumber = 'phoneNumber',
     Language = 'language',
     ExternalReference = 'externalRefference'
}

export interface ContactConflictFieldProperties {
    keyTranslation: LanguageDataLabel<'contacts'>;
    key: string;
}

export const ContactConflictFields: Record<ContactConflictProperties, ContactConflictFieldProperties> = {
    [ContactConflictProperties.EmailAddress]: {
        keyTranslation: 'contact-field-email-address',
        key: 'emailAddress',
    },
    [ContactConflictProperties.LastName]: {
        keyTranslation: 'contact-field-last-name',
        key: 'lastName',
    },
    [ContactConflictProperties.FirstName]: {
        keyTranslation: 'contact-field-first-name',
        key: 'firstName',
    },
    [ContactConflictProperties.Title]: {
        keyTranslation: 'contact-field-title',
        key: 'personalTitle',
    },

    [ContactConflictProperties.BirthDate]: {
        keyTranslation: 'contact-field-birthDate',
        key: 'birthDate',
    },
    [ContactConflictProperties.PhoneNumber]: {
        keyTranslation: 'contact-field-phone-number',
        key: 'phoneNumber',
    },
    [ContactConflictProperties.Language]: {
        keyTranslation: 'contact-field-language',
        key: 'language',
    },
    [ContactConflictProperties.ExternalReference]: {
        keyTranslation: 'external-reference-label',
        key: 'externalReference',
    },
};