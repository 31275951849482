import { NdsButton } from '@gonitro/rcl';
import { NdsButtonTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import classNames from 'classnames';
import { HTMLAttributes, PropsWithChildren, ReactNode } from 'react';
import { useFlowContext } from '~contexts/flow';
import { FlowStep } from '~contexts/flow/flow.types';
import FlowEditorDocumentViewerControls from '../flow-editor/flow-editor-document-viewer-controls';
import './flowViewWrapper.scss';

export interface FlowViewWrapperProps extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
    headerCenter: ReactNode;
    footer?: ReactNode;
    onCloseClick: () => void;
    scrollable?: boolean;
    blurred?: boolean;
}

function FlowViewWrapper({
    headerCenter,
    footer,
    children,
    onCloseClick,
    scrollable,
    blurred,
    className,
    ...props
}: FlowViewWrapperProps) {
    const { step, flowState: { detailDocuments } } = useFlowContext();

    return (
        <div className={classNames('c-flow-view-wrapper', { 'c-flow-view-wrapper--blurred': blurred }, className)} {...props}>
            <div className={'c-flow-view-wrapper__header'}>
                <div className={'c-flow-view-wrapper__header-col'}>
                    <NdsButton
                        leftIcon={'fa-regular-xmark'}
                        buttonType={NdsButtonTypesEnum.NEUTRAL}
                        size={SizesEnums.SMALL}
                        onClick={onCloseClick}
                    />
                </div>
                <div className={'c-flow-view-wrapper__header-col c-flow-view-wrapper__header-col'}>
                    {headerCenter}
                </div>
                <div className={'c-flow-view-wrapper__header-col'}>
                    {step === FlowStep.Detail && (
                        <FlowEditorDocumentViewerControls disabled={detailDocuments.length === 0} />
                    )}
                </div>
            </div>

            <div className={classNames('c-flow-view-wrapper__content', { 'c-flow-view-wrapper__content--scrollable': scrollable })}>{children}</div>

            {footer && <div className={'c-flow-view-wrapper__footer'}>{footer}</div>}
        </div>
    );
}

export default FlowViewWrapper;
