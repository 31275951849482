import { useForm } from 'react-hook-form';
import { FieldValues, UseFormProps, UseFormReturn } from 'react-hook-form/dist/types';
import { UseEnhancedFormRegister, useEnhancedRegister } from './enhanced-register';
import { UseFormRegisterCheckbox, useRegisterCheckbox } from './register-checkbox';
import { UseFormRegisterNdsInput, useRegisterNdsInput } from './register-nds-input';
import { UseFormRegisterNdsSelect, useRegisterNdsSelect } from './register-nds-select';

export type UseEnhancedForm = <
    TFieldValues extends FieldValues = FieldValues,
    TContext = any,
    TTransformedValues extends FieldValues | undefined = undefined
> (props?: UseFormProps<TFieldValues, TContext>) => UseEnhancedFormReturn<TFieldValues, TContext, TTransformedValues>

export interface UseEnhancedFormReturn<TFieldValues extends FieldValues, TContext, TTransformedValues extends FieldValues | undefined> extends UseFormReturn<TFieldValues, TContext, TTransformedValues> {
    enhancedRegister: UseEnhancedFormRegister<TFieldValues>; // mostly for internal or very specific usage
    registerCheckbox: UseFormRegisterCheckbox<TFieldValues>;
    registerNdsInput: UseFormRegisterNdsInput<TFieldValues>;
    registerNdsSelect: UseFormRegisterNdsSelect<TFieldValues>;
}


export const useEnhancedForm: UseEnhancedForm = <
    TFieldValues extends FieldValues = FieldValues,
    TContext = any,
    TTransformedValues extends FieldValues | undefined = undefined
>(props?: UseFormProps<TFieldValues, TContext>): UseEnhancedFormReturn<TFieldValues, TContext, TTransformedValues> => {
    const {
        control,
        setValue,
        register,
        ...rest
    } = useForm<TFieldValues, TContext, TTransformedValues>(props);
    // } = useForm<TFieldValues, TContext, TTransformedValues>({
    //     ...props,
    //     resolver: async (values, context) => { TODO full custom validation with nice translated messages returned
    //         console.log(values, context);
    //
    //         return {
    //             values,
    //             errors: [],
    //         };
    //     },
    // });

    const enhancedRegister = useEnhancedRegister<TFieldValues, TContext>(register, control);

    const registerCheckbox: UseFormRegisterCheckbox<TFieldValues> = useRegisterCheckbox<TFieldValues>(enhancedRegister, control);
    const registerNdsInput: UseFormRegisterNdsInput<TFieldValues> = useRegisterNdsInput<TFieldValues>(enhancedRegister, control);
    const registerNdsSelect: UseFormRegisterNdsSelect<TFieldValues> = useRegisterNdsSelect<TFieldValues>(enhancedRegister, control, setValue);

    // TODO extend register with translation feature like in registerNdsInput

    return {
        control,
        setValue,
        register,
        enhancedRegister,
        registerCheckbox,
        registerNdsInput,
        registerNdsSelect,
        ...rest,
    };
};