import { NdsButton, NdsInput } from '@gonitro/rcl';
import { NdsButtonTypesEnum } from '@gonitro/rcl/lib/_types';
import { NdsInputTypes, StatesEnum } from '@gonitro/rcl/lib/_types/designsystem.enums';
import { useCallback, useRef, useState } from 'react';
import { AuthApi } from '~api/auth.api';
import Error from '~components/error';
import { BaseRoutes } from '~constants/routes';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { useEnhancedForm } from '~hooks/enhanced-form';
import { useNavigateWithQueryParams } from '~hooks/router';
import { HttpError } from '~lib/http/http.error';
import { LanguageDataLabel } from '~models';
import './convert-local-to-cloud-form.scss';

export interface ConvertLocalToCloudFormProps {
    state: string;
}

type FormValues = {
    password: string;
};

function ConvertLocalToCloudForm({ state }: ConvertLocalToCloudFormProps) {
    const { t } = useTranslation('unauthorized');
    const authApi = useApi(AuthApi);
    const formRef = useRef<HTMLFormElement>(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<LanguageDataLabel<'error'>>();
    const navigate = useNavigateWithQueryParams();

    const {
        registerNdsInput,
        handleSubmit,
        formState: { isValid },
    } = useEnhancedForm<FormValues>({ mode: 'all' });

    const onSubmitCallback = useCallback(async ({ password }: FormValues) => {
        setLoading(true);
        try {
            const conversionToken = await authApi.convertToCloudPasswordConfirm({
                state,
                password,
            });

            navigate(BaseRoutes.RegisterCloud, {
                queryParams: {
                    state,
                    conversionToken,
                },
            });

        } catch (error) {
            setLoading(false);

            if (error instanceof HttpError) {
                const code = error.getErrorCode();

                if (code === 'User.LoginFailed') {
                    return setError('incorrect-password');
                } else if (code === 'OpenIdConnect.SessionNotFound') {
                    return setError('session-expired');
                }
            }
            setError('something-went-wrong');
        }
    }, [authApi, navigate, state]);

    return (
        <form
            ref={formRef}
            onSubmit={handleSubmit(onSubmitCallback)}
            noValidate
            className={'convert-local-to-cloud-form'}
        >
            <NdsInput
                required
                {...registerNdsInput('password', { required: true })}
                label={t('password')}
                inputType={NdsInputTypes.PASSWORD}
            />
            {error && <Error i18nKey={error} />}
            <NdsButton
                className='convert-local-to-cloud-form__button'
                onClick={() => formRef?.current?.requestSubmit()}
                label={t('btn-switch-to-cloud-account')}
                state={isValid && !loading ? StatesEnum.DEFAULT : StatesEnum.DISABLED}
                display-block
            />
            <NdsButton
                className='convert-local-to-cloud-form__button'
                onClick={() => navigate(BaseRoutes.Login)}
                label={t('btn-cancel')}
                buttonType={NdsButtonTypesEnum.SECONDARY}
                display-block
            />
        </form>
    );
}

export default ConvertLocalToCloudForm;
