import { LocationInfo } from '~models';

export namespace UrlUtil {

    /**
     * Matches the start of any url with the following capturing groups:
     *
     * * [0] The entire url
     * * [1] Protocol: everything before `://` including the `:`
     * * [2] Host: everything after `://` and before the first `:` or `/` behind it
     * * [3] Ignore this one
     * * [4] Port: everything after the second `:` and before the first `/` behind it
     * * [5] Path: everything after the host/port and before the first `?`
     * * [6] Query: everything after the first `?` and before the first `#`
     * * [7] Hash: everything after the first `#`
     */
    const urlRegex = /^(.+:)\/\/(.+?)(\/|:|$)(\d+)?\/?([^?]*)?\??([^#]*)?#?(.*)?$/;

    /**
     * Get information about either the given location. If you are looking for info about the current
     * location, look at `LocationService` instead.
     *
     * @param href Optionally a href to dissect
     */
    export function getLocationInfo<TQueryParams extends object = Record<string, string>>(href: string): LocationInfo<TQueryParams> {
        const match = urlRegex.exec(href);

        if (match == null) {
            throw Error(`Malformed href (${href})`);
        }

        const path = match[5];
        const query = match[6];

        return {
            href,
            protocol: match[1] ?? '',
            host: match[2] ?? '',
            port: match[4],
            path,
            getPathSegments: () => computePathSegments(path),
            getQueryParams: () => computeQueryParams(query),
            hash: match[7],
        };
    }

    /**
     * Splits the given url into different sections and decodes it.
     * @param path A string representing a url path without query or fragments
     */
    export function computePathSegments(path?: string | null): string[] {
        if (path == null) {
            return [];
        }

        return path.split('/').filter(item => !!item).map(item => decodeURIComponent(item));
    }

    /**
     * Creates an object from the given query param string for easier handling. Also decodes it.
     * @param search The query param string
     */
    export function computeQueryParams<T extends object = Record<string, string>>(search: string): T {
        if (!search || search === '?') {
            return {} as T;
        }

        // Remove leading '?', split by & and remove empty elements
        if (search != null && search.startsWith('?')) {
            search = search.slice(1);
        }
        const queryParamStrings = search.split('&').filter(item => !!item);

        if (queryParamStrings.length === 0) {
            return {} as T;
        }

        // Per item, split by '='
        const queryParamTuples = queryParamStrings.map(qps => qps.split('=', 2).filter(item => !!item));

        // Iterate every item, make sure it's decoded and place it in the dictionary to return
        const result: Record<string, string> = {};
        for (const queryParamTuple of queryParamTuples) {
            const key = decodeURIComponent(queryParamTuple[0]);
            const value = decodeURIComponent(queryParamTuple[1]);

            result[key] = value;
        }

        return result as T;
    }

    interface CreateUrlInfo {
        /** The base of the url, can contain everything a url can contain (e.g. query params) */
        base?: string;
        /** The protocol to use or overwrite from `base` */
        protocol?: string;
        /** The host to use or overwrite from `base` */
        host?: string;
        /** The port to use or overwrite from `base` */
        port?: string;
        /** The path to use or overwrite from `base` */
        path?: string;
        /** The query params for the url. Defaults to merge with base. */
        queryParams?: QueryParams;
        /** The hash to use or overwrite from `base` */
        hash?: string;
    }

    export function buildUrl(info: CreateUrlInfo, options?: { replaceQueryParams?: boolean; }) {
        const baseInfo = info.base ? getLocationInfo(info.base) : null;
        const baseQueryParams = baseInfo?.getQueryParams();

        // Compute location info, taking first the info from `info`, if not available, that from `info.base`
        const locationInfo = {
            protocol: info.protocol ?? baseInfo?.protocol,
            host: info.host ?? baseInfo?.host,
            port: info.port ?? baseInfo?.port,
            path: info.path ?? baseInfo?.path,
            queryParams: options?.replaceQueryParams
                ? info.queryParams
                : {
                    ...baseQueryParams,
                    ...info.queryParams,
                },
            hash: info.hash ?? baseInfo?.hash,
        };

        // Check if we can create a valid url form this info
        if (locationInfo.protocol == null || locationInfo.host == null) {
            throw Error('Invalid URL info. Protocol and host are required');
        }

        // Build up the url
        let url = `${locationInfo.protocol}//${locationInfo.host}`;

        if (locationInfo.port != null) {
            url += ':' + locationInfo.port;
        }
        if (locationInfo.path != null) {
            if (!locationInfo.path.startsWith('/')) {
                url += '/';
            }
            url += locationInfo.path;
        }
        if (locationInfo.queryParams != null) {
            const params = buildQueryParams(locationInfo.queryParams);

            if (params) {
                url += '?' + params;
            }
        }
        if (locationInfo.hash != null) {
            url += '#' + locationInfo.hash;
        }

        return url;
    }

    export interface QueryParams {
        [key: string]: string | number | null | undefined;
    }

    /**
     * Builds the query param string, without a leading `'?'`
     * @param queryParams The query params in object notation
     */
    export function buildQueryParams(queryParams: QueryParams): string {
        if (!queryParams) {
            return '';
        }

        const queryValues: string[] = [];
        for (const key in queryParams) {
            const value = queryParams[key];

            if (value != null) {
                queryValues.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
            }
        }

        return queryValues.join('&');
    }

    export async function imageUrlToDataUrl(): Promise<string> {
        return 'take from wysiwys if needed';
    }
}
