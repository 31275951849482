import { TOptionsBase } from 'i18next';
import { ReactNode } from 'react';
import { useTranslation } from '~contexts/i18n/i18n.hooks';
import { I18nextNamespaceName, LanguageDataLabel } from '~models';
import { TranslateFunctionValues } from './i18n.types';

export interface TranslationProps<TNamespaceName extends I18nextNamespaceName = I18nextNamespaceName> {
    namespace: TNamespaceName;
    label: LanguageDataLabel<TNamespaceName>;
    values?: TranslateFunctionValues;

    /** Additional options for `i18next` */
    options?: TOptionsBase;

    fallback?: ReactNode;
}

export function Translation<TNamespaceName extends I18nextNamespaceName>({
    namespace,
    label,
    values,
    options,
    fallback = '',
}: TranslationProps<TNamespaceName>) {
    const { t, ready } = useTranslation(namespace);

    const translated = ready
        ? t(
            label, values, options,
        )
        : fallback;

    return <>{translated}</>;
}