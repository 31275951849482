import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { PortalsApi } from '~api/portals.api';
import { FlowAction, FlowStatusEnum } from '~constants';
import { BaseRoutes, NestedRoutes } from '~constants/routes';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { useShowNotification } from '~contexts/overlay';
import { UrlUtil } from '~lib';
import { AppUrlsUtils } from '~lib/app-urls.utils';
import { LanguageDataLabel } from '~models';
import { PackagesTableDataModel } from '~models/portals.models';
import { useRedirectFunc } from './redirect';

export const useDocumentQuickActions = ( data: PackagesTableDataModel, closeModal: () => void) => {
    const navigate = useNavigate();
    const redirectFunc = useRedirectFunc();
    const portalsApi = useApi(PortalsApi);
    const showNotification = useShowNotification();
    const { t: tNotifications } = useTranslation('notifications');

    // Define QuickActionsNoModal as an array of actions that don’t require a modal
    const QuickActionsNoModal = [
        FlowAction.Edit,
        FlowAction.Download,
        FlowAction.RequestAuditTrail,
        FlowAction.Preview,
        FlowAction.Sign,
    ];

    const AdditionalInformationI18n: Partial<Record<FlowAction, LanguageDataLabel<'flow'>>> = {
        [FlowAction.Delete]: 'delete-warning-additional-info',
        [FlowAction.SoftDelete]: 'delete-warning-additional-info',
        [FlowAction.SendToSigners]: 'send-to-signers-warning-additional-info',
        [FlowAction.SendReminder]: 'send-reminder-warning-additional-info',
        [FlowAction.Revoke]: 'revoke-warning-additional-info',
    };

    const WarningMessageDialogI18n: Partial<Record<FlowAction, LanguageDataLabel<'flow'>>> = {
        [FlowAction.End]: 'end-warning-message',
        [FlowAction.Delete]: 'delete-warning-message',
        [FlowAction.SoftDelete]: 'delete-warning-message',
        [FlowAction.SendToSigners]: 'send-to-signers-warning-message',
        [FlowAction.SendReminder]: 'send-reminder-warning-message',
        [FlowAction.Revoke]: 'revoke-warning-message',
    };

    const NotificationMessageI18N: any = useMemo(() => ({
        [FlowAction.End]: 'ending',
        [FlowAction.Delete]: 'delete',
        [FlowAction.SoftDelete]: 'delete',
        [FlowAction.SendToSigners]: 'send-to-signers',
        [FlowAction.SendReminder]: 'send-reminder',
        [FlowAction.Revoke]: 'send-revoke',
    }), []);

    const NotificationSuccessMessageI18N: any =  useMemo(() => ({
        [FlowAction.End]: 'ending-success-message',
        [FlowAction.Delete]: 'delete-success-message',
        [FlowAction.SoftDelete]: 'delete-success-message',
        [FlowAction.SendToSigners]: 'send-to-signers-success-message',
        [FlowAction.SendReminder]: 'send-reminder-success-message',
        [FlowAction.Revoke]: 'send-revoke-success-message',
    }), []);

    const NotificationSuccessTitleI18N: any  =  useMemo(() => ({
        [FlowAction.End]: 'ending-success-title',
        [FlowAction.Delete]: 'delete-success-title',
        [FlowAction.SoftDelete]: 'delete-success-title',
        [FlowAction.SendToSigners]: 'send-to-signers-success-title',
        [FlowAction.SendReminder]: 'send-reminder-success-title',
        [FlowAction.Revoke]: 'send-revoke-success-title',
    }), []);

    const NotificationErrorTitleI18N: any  =  useMemo(() => ({
        [FlowAction.End]: 'ending-error-title',
        [FlowAction.Delete]: 'delete-error-title',
        [FlowAction.SoftDelete]: 'delete-error-title',
        [FlowAction.SendToSigners]: 'send-to-signers-error-title',
        [FlowAction.SendReminder]: 'send-reminder-error-title',
        [FlowAction.Revoke]: 'send-revoke-error-title',
    }), []);

    const triggerNotification = useCallback((type: string, actionType: FlowAction, data: PackagesTableDataModel, unknownError? : boolean) => {
        const notificationMessages: any = {
            pending: {
                type: 'pending',
                icon: 'fa-regular fa-circle-stop',
                title: tNotifications('pending-title'),
                message: tNotifications(NotificationMessageI18N[actionType], { documentName: data.documentName }),
                hideAfterMs: 2000,
            },
            success: {
                type: 'success',
                title: tNotifications(NotificationSuccessTitleI18N[actionType], { documentName: data.documentName }),
                message: tNotifications(NotificationSuccessMessageI18N[actionType], { documentName: data.documentName }),
                hideAfterMs: 2000,
            },
            error: {
                type: 'error',
                title: unknownError ? tNotifications('unknown-error-title') : tNotifications(NotificationErrorTitleI18N[actionType], { documentName: data.documentName }),
                message: unknownError? tNotifications('unknown-error-message') : null,
                hideAfterMs: 2000,
            },
        };

        showNotification(notificationMessages[type]);
    }, [
        NotificationErrorTitleI18N,
        NotificationMessageI18N,
        NotificationSuccessMessageI18N,
        NotificationSuccessTitleI18N,
        showNotification,
        tNotifications,
    ]);

    const getTokensAndRedirect = useCallback(
        async (packageId: string, actionType: FlowAction) => {
            try {
                const response = await portalsApi.getPackageDetails({ packageId });
                const { token, expiryTag, previewToken, previewExpiryTag } = response;

                const urlParams: UrlUtil.QueryParams = { packageId };

                switch (actionType) {
                    case FlowAction.Sign:
                        if (token && expiryTag) {
                            urlParams.token = token;
                            urlParams.expiryTag = expiryTag;
                            urlParams.f2f = 'true';
                        }
                        break;
                    case FlowAction.Preview:
                        if (previewToken && previewExpiryTag) {
                            urlParams.token = previewToken;
                            urlParams.expiryTag = previewExpiryTag;
                            urlParams.preview = 'true';
                        }
                        break;
                    default:
                        console.error('Invalid action type');

                        return;
                }

                redirectFunc(`signinit?${UrlUtil.buildQueryParams(urlParams)}`);
            } catch (error) {
                console.error('Failed to get tokens:', error);
            }
        },
        [portalsApi, redirectFunc],
    );

    const downloadFunction = useCallback(
        async (packageId: string) => {
            try {
                const response = await portalsApi.getDownloadPackageToken({ packageId });

                if (response) {
                    const { token, expiryTag } = response;

                    redirectFunc(AppUrlsUtils.getDownloadUrl(packageId, token, expiryTag));
                } else {
                    console.error('Required token information is missing');
                }
            } catch (error) {
                console.error(error);
            }
        },
        [portalsApi, redirectFunc],
    );

    const handleActionClick = useCallback(
        async(actionType: FlowAction) => {
            closeModal();
            const packageId = data.packageId;

            const actionFunctions: any = {
                [FlowAction.Download]: () => downloadFunction(packageId),
                [FlowAction.SendReminder]: () => portalsApi.sendPackageReminder({ packageId }),
                [FlowAction.Revoke]: () => portalsApi.revokePackage({ packageId }),
                [FlowAction.End]: () => portalsApi.endPackage({
                    packageId,
                    endPackage: true,
                }),
                [FlowAction.Edit]: () => {
                    switch (data.status) {
                        case FlowStatusEnum.ProcessingFailed:
                            navigate(`${BaseRoutes.Package}/${NestedRoutes.Documents}/${packageId}`);
                            break;
                        case FlowStatusEnum.Processing:
                            navigate(`${BaseRoutes.Package}/${NestedRoutes.Processing}/${packageId}`);
                            break;
                        case FlowStatusEnum.Draft:
                        default:
                            navigate(`${BaseRoutes.Package}/${NestedRoutes.Flow}/${packageId}`, { state: { isActionNeeded: data.isActionNeeded } });
                            break;
                    }
                },
                [FlowAction.Delete]: () => portalsApi.deletePackage({ packageId }),
                [FlowAction.Preview]: () => getTokensAndRedirect(packageId, actionType),
                [FlowAction.SendToSigners]: () => portalsApi.endPackage({ packageId }),
                [FlowAction.Sign]: () => getTokensAndRedirect(packageId, actionType),
                [FlowAction.RequestAuditTrail]: () => redirectFunc(`portalapi/v1/packages/${encodeURIComponent(packageId)}/audittrail`),
            };

            const actionFunc = actionFunctions[actionType];

            if (actionFunc) {
                if (actionType in NotificationMessageI18N) {
                    triggerNotification('pending', actionType, data);
                }
                try{
                    const response = await actionFunc();

                    if(actionType in NotificationMessageI18N && response) {
                        setTimeout(() => {
                            triggerNotification('success', actionType, data);
                        }, 2300);
                    }
                } catch (e) {
                    if(actionType in NotificationMessageI18N) {
                        setTimeout(() => {
                            triggerNotification('error', actionType, data);
                        }, 2300);
                    }
                }
            } else {
                setTimeout(() => {
                    triggerNotification('error', actionType, data, true);
                }, 2300);
                console.error('Unsupported action type:', actionType);
            }
        },
        [
            closeModal,
            data,
            downloadFunction,
            portalsApi,
            navigate,
            getTokensAndRedirect,
            redirectFunc,
            NotificationMessageI18N,
            triggerNotification,
        ],
    );

    return {
        QuickActionsNoModal,
        AdditionalInformationI18n,
        WarningMessageDialogI18n,
        getTokensAndRedirect,
        downloadFunction,
        handleActionClick,
    };
};