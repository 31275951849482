import { NdsBadge } from '@gonitro/rcl';
import { NdsBadgeStatesEnum, NdsBadgeTypesEnum } from '@gonitro/rcl/lib/_types';
import { UserStatusEnum } from '~constants/user-status';

export interface UserStatusBadgeProps {
  status: UserStatusEnum;
  icon?: string;
}

const UserStatusBadge = ({ status, icon }: UserStatusBadgeProps) => {
    const mapStatusToState: Record<UserStatusEnum, NdsBadgeStatesEnum> = {
        [UserStatusEnum.Active]: NdsBadgeStatesEnum.SUCCESS,
        [UserStatusEnum.InActive]: NdsBadgeStatesEnum.NEUTRAL,
        [UserStatusEnum.Invited]: NdsBadgeStatesEnum.PENDING,
    };

    return (
        <NdsBadge
            text={UserStatusEnum[status]}
            badgeType={NdsBadgeTypesEnum.STATUS}
            state={mapStatusToState[status]}
            icon={icon}
        />
    );
};

export default UserStatusBadge;
