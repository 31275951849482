import { FlowPropertiesModel } from '~models';
import { FlowActionType, FlowReducerFunc } from '../flow.types';

export type FlowReducerPropertiesActionPayloads = {
    [FlowActionType.UpdatePackageName]: string;
    [FlowActionType.UpdateTemplateName]: string;
    [FlowActionType.UpdateProperties]: NonNullable<Partial<FlowPropertiesModel>>;
}

export const UpdatePackageNameReducer: FlowReducerFunc<FlowActionType.UpdatePackageName> = (
    state,
    payload,
) => {
    return {
        ...state,
        packageName: payload,
    };
};
export const UpdateTemplateNameReducer: FlowReducerFunc<FlowActionType.UpdateTemplateName> = (
    state,
    payload,
) => {
    return {
        ...state,
        templateName: payload,
    };
};
export const UpdatePropertiesReducer: FlowReducerFunc<FlowActionType.UpdateProperties> = (
    state,
    payload,
) => {
    return {
        ...state,
        properties: {
            ...state.properties,
            ...payload,
        } as FlowPropertiesModel,
    };
};
