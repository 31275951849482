import { AbortedError } from '~models';

export namespace PromiseUtils {
    /**
     * Create a promise that resolves after `ms` milliseconds. Is a wrapper around `setTimeout` for ease of use.
     *
     * @param ms Amount of milliseconds to wait
     * @param signal abort signal
     * @returns A promise
     */
    export function wait(ms?: number, signal?: AbortSignal) {

        return new Promise<void>((resolve, reject) => {
            const controller = new AbortController();

            // Resolve this promise after ms
            const handle = setTimeout(() => {
                resolve();
                controller.abort(); // Make sure to unsubscribe to the signal to avoid memory leaks
            }, ms);

            // Reject when aborted
            signal?.addEventListener(
                'abort', () => {
                    reject(new AbortedError(signal));
                    clearTimeout(handle);
                }, { signal: controller.signal },
            );

        });
    }

    /**
     * Returns a promise that never resolves...
     */
    export function forever(signal?: AbortSignal) {
        return new Promise<never>((resolve, reject) => {
            signal?.addEventListener('abort', () => reject());
        });
    }

    export interface DeferredOptions {
        preventReset?: boolean;
        signal?: AbortSignal;
    }

    /**
     * A wrapper around a Promise to more easily resolve a value at some later time
     */
    export class Deferred<T> implements Promise<T> {
        /** The underlying promise */
        public promise: Promise<T>;

        /**
         * If `false`, will always reset the underlying promise when this one is resolved or rejected.
         * If `true`, will not auto-reset. You can still reset the promise by using the `forceReset` option in
         * `resolve` and `reject` or by manually calling `reset`.
         */
        public preventReset: boolean;
        [Symbol.toStringTag]!: string;
        private _resolve!: (value: T | PromiseLike<T>) => void;
        private _reject!: (reason?: any) => void;
        private aborter = new AbortController();

        public constructor(options?: DeferredOptions) {
            this.preventReset = options?.preventReset ?? false;
            this.promise = new Promise((resolve, reject) => {
                this._resolve = resolve;
                this._reject = reject;
            });
            const signal = options?.signal;

            signal?.addEventListener('abort', () => this.reject(new AbortedError(signal)), { signal: this.aborter.signal });
        }

        /**
         * Resolves the underlying promise
         * @param value The value to resolve to promise to
         * @param options Additional options
         */
        public resolve(value: T | PromiseLike<T>, options?: { forceReset?: boolean; }) {
            this._resolve(value);
            this.resetIfConfigured(options?.forceReset);
        }

        /**
         * Rejects the underlying promise
         * @param reason The reject reason
         * @param options Additional options
         */
        public reject(reason?: any, options?: { forceReset?: boolean; }) {
            this._reject(reason);
            this.resetIfConfigured(options?.forceReset);
        }

        /**
         * Recreates the underlying promise
         */
        public reset() {
            this.promise = new Promise((resolve, reject) => {
                this._resolve = resolve;
                this._reject = reject;
            });
            this.aborter.abort();
            this.aborter = new AbortController();
        }

        //---------------------------------------------------------------------------------
        // Below is the promise implementation so we can use `Deferred` and `Promise` interchangeably

        public then<TResult1 = T, TResult2 = never>(
            onfulfilled?: ((value: T) => TResult1 | PromiseLike<TResult1>) | null,
            onrejected?: ((reason: any) => TResult2 | PromiseLike<TResult2>) | null,
        ) {
            return this.promise.then(onfulfilled, onrejected);
        }

        public catch<TResult = never>(onrejected?: ((reason: any) => TResult | PromiseLike<TResult>) | undefined | null) {
            return this.promise.catch(onrejected);
        }

        public finally(onfinally?: (() => void) | undefined | null) {
            return this.promise.finally(onfinally);
        }

        /** Calls `this.reset()` if configured to do so */
        private resetIfConfigured(localResetOption: boolean | undefined) {
            if (localResetOption || !this.preventReset) {
                this.reset();
            }
        }


    }
}