import { useCallback, useMemo } from 'react';
import { PortalsApi } from '~api/portals.api';
import BulkActionsModal from '~components/bulk-actions-modal';
import { ExtendFormValues } from '~components/bulk-actions-modal/bulkActionsModal';
import { FlowAction } from '~constants';
import { useApi } from '~contexts/api';
import { ModalPortal } from '~contexts/overlay';
import { useTableSelections } from '~contexts/table';
import { Guid } from '~models';
import { SigningSessionListItemModel } from '~models/portals.models';
import './signerPortalBulkModal.scss';

export type SignerPortalBulkActionData = {
    action: FlowAction;
    ids: Guid[];
}

export interface SignerPortalBulkModalProps {
    bulkActionData?: SignerPortalBulkActionData;
    onModalClosed: () => void
}

const SignerPortalBulkModal = ({
    bulkActionData,
    onModalClosed,
}: SignerPortalBulkModalProps) => {
    const portalsApi = useApi(PortalsApi);

    const { getSelectedRowsData } = useTableSelections();
    const items = useMemo<{ id: Guid, name: string }[] | null>(() => {
        if (!bulkActionData) {
            return null;
        }

        return getSelectedRowsData<'actionId', SigningSessionListItemModel>()
            .filter(el => bulkActionData.ids.includes(el.actionId))
            .map((el) => ({
                id: el.actionId,
                name: el.packageName,
            }));
    }, [bulkActionData, getSelectedRowsData]);

    const handleOnActionClick = useCallback(async (actionIds: string[], action: FlowAction, data?: ExtendFormValues) => {
        const packageIds = getSelectedRowsData<'actionId', SigningSessionListItemModel>()
            .filter(el => actionIds.includes(el.actionId))
            .map((el) => el.packageId);

        switch (action) {
            case FlowAction.Download:
                await portalsApi.runBulkActionForPackageIds({
                    packageIds,
                    action: 'download',
                });

                return;
            case FlowAction.Delete:
                await portalsApi.runBulkActionForPackageIds({
                    packageIds,
                    action: 'delete',
                });

                return;
        }
    },
    [getSelectedRowsData, portalsApi]);

    return (
        <ModalPortal visible={items !== null}>
            {items && (
                <BulkActionsModal
                    isSigningPortal={true}
                    action={bulkActionData!.action}
                    items={items}
                    handleOnClickAction={handleOnActionClick}
                    handleCloseModal={onModalClosed}
                />
            )}
        </ModalPortal>
    );
};

export default SignerPortalBulkModal;
