import { NdsIconFont } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import Typography, { TypographyToken } from '~components/typography';
import { useTranslation } from '~contexts/i18n';
import './noResultsFound.scss';

export interface NoResultsFoundProps extends HTMLAttributes<HTMLDivElement> {

}

function NoResultsFound({ className, ...props }: NoResultsFoundProps) {
    const { t } = useTranslation('base');

    return (
        <div {...props} className={classNames('c-no-results-found', className)}>
            <div className={'c-no-results-found__icon-wrapper'}>
                <div className={'c-no-results-found__icon'}>
                    <NdsIconFont fontName={'fa-solid-question'} size={SizesEnums.MEDIUM} />
                </div>
            </div>
            <div className={'c-no-results-found__info'}>
                <Typography
                    token={TypographyToken.MobileHeaderSm}
                    mediumScreenToken={TypographyToken.DesktopHeaderSm}
                >{t('no-results-title')}</Typography>

                <Typography
                    token={TypographyToken.MobileDescriptionMd}
                    mediumScreenToken={TypographyToken.DesktopDescriptionMd}
                >{t('no-results-description')}</Typography>
            </div>
        </div>
    );
}

export default NoResultsFound;
