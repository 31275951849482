import { ChangeEvent, HTMLAttributes, MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { FileUtils } from '~lib';
import './fileUpload.scss';

export type FileUploadRef = {
    openPrompt: () => void;
    _processFiles: (files: File[]) => void;
    _mimeTypes: string[];
}

export interface FileUploadProps extends HTMLAttributes<HTMLInputElement> {
    inputRef?: MutableRefObject<FileUploadRef | undefined>;
    mimeTypes?: string[];
    multiple?: boolean;
    onUpload?: (files: File[]) => void;
}

const FileUpload = ({
    inputRef: externalRef,
    multiple,
    mimeTypes,
    onUpload,
    ...props
}: FileUploadProps) => {

    const inputRef = useRef<HTMLInputElement>(null);

    const processFiles = useCallback((files: File[]) => {
        if (!multiple) {
            files = [files[0]];
        }

        files = files.filter((file) =>
            FileUtils.validateFileType(file, mimeTypes ?? []));

        if (!files.length) {
            return;
        }
        onUpload?.(files);
    }, [mimeTypes, multiple, onUpload]);

    useEffect(() => {
        if (!externalRef) {
            return;
        }
        externalRef.current = {
            openPrompt: () => inputRef.current?.click(),
            _processFiles: processFiles,
            _mimeTypes: mimeTypes ?? [],
        };
    }, [externalRef, mimeTypes, multiple, processFiles]);

    const onChangeCallback = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.length) {
            processFiles([...event.target.files]);
            event.target.value = '';
        }
    }, [processFiles]);

    return (
        <input
            {...props}
            type={'file'}
            style={{ display: 'none' }}
            onChange={onChangeCallback}
            accept={mimeTypes?.join(',')}
            ref={inputRef}
            multiple={multiple}
        />
    );
};

export default FileUpload;