import { RefObject, useMemo } from 'react';
import { DocumentViewerPageModel, DocumentViewerPagePosition } from '../types';

export type DocumentViewerPagePositionsValue = {
    pagePositions: DocumentViewerPagePosition[];
    contentHeight: number;
}

export function useDocumentViewerPagePositions(
    contentGhostRef: RefObject<HTMLDivElement>,
    pages: DocumentViewerPageModel[],
    leftOffset: number,
    scale: number,
    preDocumentGutterPx: number,
    pageGutterPx: number,
    prePageGutterPx: number,
    justifyPages: boolean,
): DocumentViewerPagePositionsValue {
    return useMemo(() => {
        const topOffset: number = contentGhostRef.current?.offsetTop ?? 0;

        let pageTopOffset: number = topOffset;
        let documentIndex: number = -1;

        const pagePositions: DocumentViewerPagePosition[] = new Array(pages.length);

        for (const page of pages) {

            let headerGutter: number;

            if (page.documentIndex !== documentIndex) {
                pageTopOffset += preDocumentGutterPx;
                headerGutter = preDocumentGutterPx;
                documentIndex = page.documentIndex;
            } else {
                headerGutter = preDocumentGutterPx;
                pageTopOffset += prePageGutterPx;
            }
            const width = page.unifiedSize.width * scale;
            const height = page.unifiedSize.height * scale;

            pagePositions[page.absolutePageIndex] = {
                size: {
                    width,
                    height,
                },
                offset: {
                    top: pageTopOffset,
                    left: leftOffset + (justifyPages ? 0 : (page.scale - 1) * width / 2),
                },
                absolutePageIndex: page.absolutePageIndex,
                headerHeight: headerGutter,
            };
            pageTopOffset += height;

            if (page !== pages[pages.length - 1]) {
                pageTopOffset += pageGutterPx;
            }
        }

        return {
            pagePositions,
            contentHeight: pageTopOffset - topOffset,
        };
    }, [
        contentGhostRef,
        preDocumentGutterPx,
        leftOffset,
        pageGutterPx,
        prePageGutterPx,
        pages,
        scale,
        justifyPages,
    ]);
}
