import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useAbortEffect, useAsyncEffect } from '~hooks/effects';
import { TableContext, TableContextType } from './table.context';
import { createFilterValuesDto } from './table.helpers';
import {
    useInternalTableFilterValues,
    useInternalTableItemsPerPage,
    useInternalTablePaging,
    useInternalTableSelections,
    useInternalTableSorting,
} from './table.hooks.internal';
import { FetchTableDataDelegate, TableDataGeneric, TableDefinition } from './table.types';

const DEFAULT_REFRESH_INTERVAL_MS = 5000;

export interface TableContextProviderProps<TRowIdKey extends string | number, TData extends TableDataGeneric<TRowIdKey>> extends PropsWithChildren {
    readonly definition: TableDefinition<TRowIdKey>;
    data?: TData[];
    multiPageSelection?: boolean;
    fetchData: FetchTableDataDelegate<TData>;
    /**
     * Callback to determine if specific row is selectable or not.
     * Function must be 'static' = defined outside of component or wrapped in useCallback
     */
    isRowSelectable?: (data: TData) => boolean;
}


export function TableContextProvider<
    TRowIdKey extends string,
    TData extends TableDataGeneric<TRowIdKey>,
>({
    children,
    data: initialData,
    definition,
    isRowSelectable,
    fetchData,
}: TableContextProviderProps<TRowIdKey, TData>) {
    const { pathname } = useLocation();

    const [data, setData] = useState<TData[]>(initialData || []);
    const [totalItems, setTotalItems] = useState<number>(-1);

    // polling helper refs
    const localPollInterval = useRef(definition.pollInterval ?? DEFAULT_REFRESH_INTERVAL_MS);
    const lastRefreshTimestamp = useRef(Date.now());
    const isFetchingData = useRef(false);
    const mustRefresh = useRef(false);

    const {
        sortConfig,
        sortChange,
    } = useInternalTableSorting(definition);

    const {
        page,
        setPagePublicCallback, // for external = context value use - validates passed page number
        setPageInternalCallback, // for internal use - no validation so no update on totalPages change (prevents doubled api call)
        totalPages,
        setTotalPages,
    } = useInternalTablePaging(definition);

    const {
        itemsPerPage,
        itemsPerPageOptions,
        setItemsPerPageCallback,
    } = useInternalTableItemsPerPage(definition);

    const [pageData, setPageData] = useState<TData[]>(initialData?.slice(0, itemsPerPage) || []);


    const {
        selectedRows,
        selectRows,
        unselectRows,
        unselectAllRows,
    } = useInternalTableSelections();

    const { filterValues, setFilterValue } = useInternalTableFilterValues(definition);

    const [focusedRow, setFocusedRow] = useState<TData | undefined>();

    // raw state and setState are used in context value to avoid extra dependencies
    const [rowsInProgress, setRowsInProgress] = useState<TData[TRowIdKey][]>([]);

    /// =================================================================================== PAGE CHANGE EFFECTS

    useEffect(() => {
        setTotalItems(-1);
        setPageData(initialData?.slice(0, itemsPerPage) || []);
        setData(initialData || []);
        setRowsInProgress([]);
        mustRefresh.current = false;
        isFetchingData.current = false;
        lastRefreshTimestamp.current = Date.now();
    }, [initialData, itemsPerPage, pathname, unselectAllRows]);

    useEffect(() => {
        if (definition.selectable && !definition.multiPageSelectable) {
            unselectAllRows();
        }
    }, [unselectAllRows, page, definition.selectable, definition.multiPageSelectable]);

    const prevPathName = useRef(pathname);

    useEffect(() => {
        if (prevPathName.current !== pathname) {
            unselectAllRows();
        }
    }, [pathname, unselectAllRows]);

    const setFocusedRowCallback = useCallback((prop: TData | undefined) => {
        if (typeof prop === 'object') {
            setFocusedRow(prop);
        } else {
            setFocusedRow(undefined);
        }
    }, []);

    /// =================================================================================== MAIN FETCH DATA CALLBACK
    const fetchDataCallback = useCallback(async (signal?: AbortSignal) => {
        const fetch = () =>
            fetchData(
                {
                    page,
                    sortField: sortConfig.key,
                    sortOrder: sortConfig.direction,
                    filterValues: createFilterValuesDto(filterValues, definition.filters),
                    maxQuantity: itemsPerPage,
                },
                signal,
            );

        try {
            isFetchingData.current = true;
            const response = await fetch();

            setTotalPages(Math.ceil(response.totalItems / response.itemsPerPage));
            setTotalItems(response.totalItems);

            // need to check if result is empty as it's possible that page is too high
            if (!response.items.length && page > 1) {
                setPageInternalCallback(Math.ceil(response.totalItems / response.itemsPerPage));

                return;
            }
            setPageData(response.items);

            setData((prev) => {
                if (!prev.length) {
                    const arr = new Array(response.totalItems);

                    arr.splice((page - 1) * itemsPerPage, response.items.length, ...response.items);

                    return arr;
                } else {
                    prev.splice((page - 1) * itemsPerPage, response.items.length, ...response.items);

                    return prev;
                }
            });
            lastRefreshTimestamp.current = Date.now();
            isFetchingData.current = false;
        } catch (e) {
            if (signal?.aborted) {
                return;
            }
            throw e;
        }
    }, [
        definition.filters,
        fetchData,
        filterValues,
        itemsPerPage,
        page,
        setPageInternalCallback,
        setTotalPages,
        sortConfig.direction,
        sortConfig.key,
    ]);

    const refreshCallback = useCallback(() => {
        if (!definition.pollInterval) {
            fetchDataCallback();
        } else {
            mustRefresh.current = true;
        }
    }, [definition.pollInterval, fetchDataCallback]);

    /// =================================================================================== CONTEXT VALUE
    const contextValue = useMemo<TableContextType<TRowIdKey, TData>>(
        () => ({
            definition,
            data,
            pageData,
            totalItems,

            refresh: refreshCallback,

            itemsPerPage,
            itemsPerPageOptions,
            setItemsPerPage: setItemsPerPageCallback,

            totalPages,
            page,
            setPage: setPagePublicCallback,

            sortConfig,
            sortChange,

            selectedRows,
            selectRows,
            unselectRows,
            unselectAllRows,
            isRowSelectable,

            filterValues,
            setFilterValue,

            focusedRow,
            setFocusedRow: setFocusedRowCallback as TableContextType['setFocusedRow'],

            rowsInProgress,
            setRowsInProgress,
        }), [
            definition,
            data,
            pageData,
            totalItems,
            refreshCallback,
            itemsPerPage,
            itemsPerPageOptions,
            setItemsPerPageCallback,
            totalPages,
            page,
            setPagePublicCallback,
            sortConfig,
            sortChange,
            selectedRows,
            selectRows,
            unselectRows,
            unselectAllRows,
            isRowSelectable,
            filterValues,
            setFilterValue,
            focusedRow,
            setFocusedRowCallback,
            rowsInProgress,
        ],
    );


    /// =================================================================================== FETCH DATA LOGIC EFFECTS

    // initial fetch
    useAsyncEffect(
        () => async (signal) => {
            await fetchDataCallback(signal);
        },
        [fetchDataCallback, refreshCallback],
    );

    // polling
    useEffect(() => {
        if (definition.pollInterval === 0) { // don't poll if disabled in definition
            return;
        }

        const aborter = new AbortController();
        const intervalId = setInterval(() => {
            if (!localPollInterval.current) { // locally stored value blocks polling if browser tab is hidden (user is on different one)
                return;
            }

            if (
                !mustRefresh.current
                && (lastRefreshTimestamp.current + localPollInterval.current > Date.now() || isFetchingData.current)
            ) {
                return;
            }
            mustRefresh.current = false;
            fetchDataCallback(aborter.signal);
        }, 500);

        return () => {
            clearInterval(intervalId);
            aborter.abort();
        };
    }, [definition.pollInterval, fetchDataCallback]);

    // disable polling on page change

    useAbortEffect((signal) => {
        document.addEventListener(
            'visibilitychange', async () => {
                if (document.visibilityState === 'hidden') {
                    localPollInterval.current = 0;
                } else {
                    localPollInterval.current = definition.pollInterval ?? DEFAULT_REFRESH_INTERVAL_MS;
                }

            }, { signal },
        );
    }, [definition.pollInterval]);

    /// =================================================================================== RENDER :)
    return (
        <TableContext.Provider value={contextValue as TableContextType}>
            {children}
        </TableContext.Provider>
    );
}
