import { NdsButton } from '@gonitro/rcl';
import { NdsButtonTypesEnum } from '@gonitro/rcl/lib/_types';
import { SizesEnums } from '@gonitro/rcl/lib/_types/designsystem.enums';
import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { AuthApi } from '~api/auth.api';
import Error from '~components/error';
import Loader from '~components/loader';
import Typography, { TypographyToken } from '~components/typography';
import { RegisterLocationResult } from '~constants';
import { BaseRoutes } from '~constants/routes';
import { useApi } from '~contexts/api';
import { useCurrentLanguage, useTranslation } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import { useAsyncEffect } from '~hooks/effects';
import { useNavigateWithQueryParams, useQueryParams } from '~hooks/router';
import RegisterForm from './register-form';
import './register.view.scss';

export interface RegisterViewProps {
}

type RegisterLocation = {
    token?: string;
    result?: RegisterLocationResult;
    state?: string;
    error?: string;
}


function RegisterView() {
    const { t } = useTranslation('unauthorized');
    const queryParams = useQueryParams<RegisterLocation>();
    const authApi = useApi(AuthApi);
    const { languageCode } = useCurrentLanguage();
    const {
        applicationAuthenticationSettings: {
            isLocalRegistrationEnabled,
            cloudAccountProvidersSettings,
        },
    } = useSettings();
    const navigate = useNavigateWithQueryParams();
    const [expiredError, setExpiredError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [basicUserData, setBasicUserData] = useState<{ email: string, company?: string }>();

    const onCloudRegisterClick = useCallback(async (providerName: string) => {
        setLoading(true);
        try {
            window.location.href = await authApi.getCloudAccountProviderRedirectUrl({
                providerName,
                language: languageCode,
                token: queryParams.token,
            });

        } catch (e) {
            setCloudRegisterError(true);
            setLoading(false);
        }
    }, [authApi, languageCode, queryParams.token]);

    useAsyncEffect(() => async (signal: AbortSignal) => {
        if (queryParams.result === RegisterLocationResult.Registered) {
            navigate(BaseRoutes.Login);

            return;
        }
        if (queryParams.state) {
            if (queryParams.result === RegisterLocationResult.ConvertToCloudAccount) {
                navigate(BaseRoutes.ConvertLocalAccountToCloud, {
                    replace: true,
                    queryParams: {
                        token: queryParams.token,
                        state: queryParams.state,
                    },
                });
            } else {
                navigate(BaseRoutes.RegisterCloud, {
                    replace: true,
                    queryParams: {
                        token: queryParams.token,
                        state: queryParams.state,
                    },
                });
            }
        }
        if (basicUserData || queryParams.state) {
            return;
        }
        if (!queryParams.token) {
            return navigate(BaseRoutes.Login);
        }

        try {
            const result = await authApi.getRegisterUserInfo({ token: queryParams.token }, signal);

            if (!result.email) { // no email means invitation not found
                return navigate(BaseRoutes.Login);
            } else {

                if (!isLocalRegistrationEnabled && cloudAccountProvidersSettings.length === 1) {
                    // redirect to only one possible provider
                    await onCloudRegisterClick(cloudAccountProvidersSettings[0].name);
                }

                setBasicUserData(result);
            }
            setLoading(false);
        } catch (e) {
            if (signal.aborted) {
                return;
            }
        }

    }, [
        basicUserData,
        authApi,
        queryParams.state,
        queryParams.token,
        navigate,
        isLocalRegistrationEnabled,
        cloudAccountProvidersSettings,
        onCloudRegisterClick,
        queryParams.result,
    ]);

    const [cloudRegisterError, setCloudRegisterError] = useState(false);

    const cloudProviders: { name: string, displayName: string; }[] = useMemo(() => {
        return cloudAccountProvidersSettings
            .filter((el) => el.enabled && el.isVisible)
            .map((el) => ({
                name: el.name,
                displayName: el.snippet.value,
            }));
    }, [cloudAccountProvidersSettings]);


    if (loading) {
        return (
            <Wrapper>
                <Loader size={SizesEnums.XLARGE} />
            </Wrapper>
        );
    }
    if (expiredError) {
        return (
            <Wrapper>
                <Error i18nKey={'register-user-link-expired'} />
            </Wrapper>
        );
    }

    const canShowCloudProviderRegistration = cloudAccountProvidersSettings.length > 0;
    const canShowLocalRegistrationForm = !!(
        isLocalRegistrationEnabled
        && basicUserData
        && queryParams.token
    );

    return (
        <Wrapper>
            {cloudRegisterError && (
                <Error i18nKey={'something-went-wrong'} />
            )}
            {canShowCloudProviderRegistration && (
                <>
                    <Typography
                        tagName={'p'}
                        token={TypographyToken.MobileDescriptionLg}
                        mediumScreenToken={TypographyToken.DesktopDescriptionLg}
                        text={t('select-cloud-provider-to-complete-registration')}
                    />
                    {cloudProviders.map((provider) => (
                        <NdsButton
                            key={provider.name}
                            buttonType={NdsButtonTypesEnum.SECONDARY}
                            size={SizesEnums.SMALL}
                            displayBlock
                            label={t('sign-up-with-sso', { providerName: provider.displayName })}
                            onClick={() => onCloudRegisterClick(provider.name)}
                        />
                    ))}
                </>
            )}
            {canShowCloudProviderRegistration && canShowLocalRegistrationForm && (
                <div className='v-register__divider'>{t('or')}</div>
            )}
            {canShowLocalRegistrationForm && (
                <>
                    <Typography
                        tagName={'p'}
                        token={TypographyToken.MobileDescriptionLg}
                        mediumScreenToken={TypographyToken.DesktopDescriptionLg}
                        text={t('create-local-account')}
                    />
                    <RegisterForm
                        {...basicUserData}
                        token={queryParams.token!}
                        setExpiredError={() => setExpiredError(true)}
                    />
                </>
            )}
        </Wrapper>
    );
}

const Wrapper = ({ children }: PropsWithChildren) => {
    const { t } = useTranslation('unauthorized');

    return (
        <div className='v-register'>
            <div className={'v-register__header'}>
                <Typography
                    tagName={'h1'}
                    token={TypographyToken.MobileHeaderLg}
                    mediumScreenToken={TypographyToken.DesktopHeaderLg}
                    text={t('title-register-account')}
                />
            </div>
            <div className='v-register__content'>
                {children}
            </div>
        </div>
    );
};

export default RegisterView;