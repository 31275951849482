import { i18n } from 'i18next';
import { createContext } from 'react';
import { SetLanguageFunction } from '~contexts/i18n/i18n.types';
import { Language, LanguageProfile, LanguageProfiles } from '~models';

export type I18nContextType = {
    i18next: i18n;
    /** The primary language configured by the user */
    language: LanguageProfile;
    /** All available languages */
    availableLanguages: LanguageProfiles;
    setLanguage: SetLanguageFunction;
    isLanguageAvailable: (lang: Language | LanguageProfile) => boolean;
    registerLocale: (key: string, translations: Record<Language, string>) => void;
}

export const I18nContext = createContext<I18nContextType>({} as I18nContextType);

export default I18nContext;