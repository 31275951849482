import { ReactNode } from 'react';
import './menu-group.scss';

export interface MenuItemProps {
    children: ReactNode;
    menuWidth?: string | number;
}

function MenuGroup({ children, menuWidth }: MenuItemProps) {

    return (
        <div style={{ minWidth: menuWidth }} className="c-menu-group">
            {children}
        </div>
    );

}

export default MenuGroup;
