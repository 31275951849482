import { NdsButton, NdsInput } from '@gonitro/rcl';
import { NdsInputTypes, StatesEnum } from '@gonitro/rcl/lib/_types/designsystem.enums';
import { KeyboardEvent, useCallback, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import Error from '~components/error';
import Loader from '~components/loader';
import { useLogin } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { useEnhancedForm } from '~hooks/enhanced-form';
import { HttpError } from '~lib/http/http.error';
import { LanguageDataLabel } from '~models';
import './login-form.scss';

export interface LoginFormProps {
    setLoading: (bool: boolean) => void,
    loading: boolean;
}

type FormValues = {
    email: string;
    password: string;
};

function LoginForm({ setLoading, loading, ...props }: LoginFormProps) {
    const { t } = useTranslation('unauthorized');
    const login = useLogin();
    const formRef = useRef<HTMLFormElement>(null);
    const [generalError, setGeneralError] = useState<LanguageDataLabel<'error'>>();

    const {
        registerNdsInput,
        handleSubmit,
        formState: { isValid },
        setError,
    } = useEnhancedForm<FormValues>({
        mode: 'all',
        defaultValues: {
            email: '',
            password: '',
        },
    });

    const onSubmitCallback = useCallback(async (data: FormValues) => {
        setLoading(true);
        try {
            await login(data.email, data.password);
            setLoading(false);
        } catch (e) {
            setLoading(false);

            if (e instanceof HttpError) {
                const code = e.getErrorCode();

                if (code === 'User.LoginFailed') {
                    setError('email', { type: 'empty' });
                    setError('password', { type: 'empty' });

                    return setGeneralError('incorrect-credentials');

                }
            }
            setGeneralError('something-went-wrong');
        }
    }, [login, setLoading, setError]);

    const handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            // Call the function to handle form submission
            formRef?.current?.requestSubmit();
        }
    };

    return (
        <form
            className={'login-form'}
            ref={formRef}
            onSubmit={handleSubmit(onSubmitCallback)}
        >
            {loading && <Loader center />}
            {generalError && <Error i18nKey={generalError} />}
            <div className={'login-form__input-wrapper'}>
                <NdsInput
                    required
                    id='email'
                    {...registerNdsInput('email', {
                        required: true,
                        fieldLabel: t('email-address'),
                    })
                    }
                    label={t('email-address')}
                    inputType={NdsInputTypes.EMAIL}

                />
                <NdsInput
                    required
                    id='password'
                    {...registerNdsInput('password', { required: true })}
                    label={t('password')}
                    inputType={NdsInputTypes.PASSWORD}
                    onKeyDown={handleKeyPress}
                />
            </div>
            <div className='login-form__remember-forgot-container'>
                <Link to='/lost-password' className='login-form__forgot-password'>{t('forgot-password')}</Link>
            </div>
            <NdsButton
                id='continue'
                className={'login-form__button'}
                onClick={() => formRef?.current?.requestSubmit()}
                label={t('btn-continue')}
                state={isValid ? StatesEnum.DEFAULT : StatesEnum.DISABLED}
                displayBlock
            />
        </form>
    );
}

export default LoginForm;