import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { PropsWithChildren, Reducer, useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react';
import { useMatch } from 'react-router-dom';
import { DocumentViewerRef } from '~components/document-viewer';
import { AnyTypeToFlowEntityType } from '~components/flow/flow-editor/flow-editor-left-pane/flow-editor-recipients/types';
import { FlowEditorLeftPaneRef } from '~components/flow/flow-editor/flow-editor-left-pane/flowEditorLeftPane';
import Loader from '~components/loader';
import { ApiErrors } from '~constants';
import { FieldType } from '~constants/field-type';
import { useSettings } from '~contexts/settings';
import { useAsyncEffect } from '~hooks/effects';
import { useLocationState } from '~hooks/router';
import { ValidateUtils } from '~lib';
import { HttpError } from '~lib/http/http.error';
import {
    FlowDetailDocument,
    FlowFieldModel,
    FlowPropertiesModel,
    FlowStakeholderModel,
    SettingsModel,
    StakeholderType,
} from '~models';
import { useLastWorkspaceId } from '../app';
import { useFlowApiWrapper } from './flow.api-wrapper';
import { FlowContext, FlowContextType, FlowDocumentViewerConfigType, FlowStateType } from './flow.context';
import { useFlowDispatcher } from './flow.dispatcher';
import { FlowError, FlowErrorTextI18n, FlowErrorTitleI18n } from './flow.error';
import { useFlowInternalStakeholderColors } from './flow.hooks-internal';
import {
    useFlowInternalErrors,
    useFlowInternalFocusedEntity,
    useFlowInternalNavigateToPortal,
    useFlowInternalNavigateToStep,
    useFlowInternalTypeAndStep,
} from './flow.hooks.internal';
import { flowReducer } from './flow.reducer';
import { FlowActionType, FlowErrorStateType, FlowStateAction, FlowStep, FlowType } from './flow.types';

export interface FlowContextProviderProps extends PropsWithChildren {
}

interface FlowStateError {
    error: FlowError,
    errorInfo?: Record<string, any>
}

function validatePackageName(packageName?: string) {
    const errors: FlowStateError[] = [];

    if (packageName === '' || packageName == null) {
        errors.push({
            error: FlowError.PackageNameCannotBeEmpty,
            errorInfo: {
                type: FlowErrorStateType.Package,
                entityId: 'Package', // ??
                errorTitle: FlowErrorTitleI18n[FlowError.PackageNameCannotBeEmpty],
                text: FlowErrorTextI18n[FlowError.PackageNameCannotBeEmpty],
            },
        });
    } else if (packageName.length > 150) {
        errors.push({
            error: FlowError.PackageNameBetweenLength,
            errorInfo: {
                type: FlowErrorStateType.Package,
                entityId: 'Package', // ??
                errorTitle: FlowErrorTitleI18n[FlowError.PackageNameBetweenLength],
                text: FlowErrorTextI18n[FlowError.PackageNameBetweenLength],
            },
        });
    }

    return errors;
}

function validatePackageProperties(settings: SettingsModel, properties?: FlowPropertiesModel) {
    if (!properties) {
        return [];
    }

    const errors: FlowStateError[] = [];

    if (settings.customizationSettings.isRedirectUrlVisibleInFrontend && properties.isDefaultRedirectUrlEnabled) {
        if (properties.defaultRedirectUrl != null && properties.defaultRedirectUrl !== '' && !ValidateUtils.url(properties.defaultRedirectUrl ?? '')) {
            errors.push({
                error: FlowError.PackageRedirectUrlValidUrl,
                errorInfo: {
                    type: FlowErrorStateType.Package,
                    entityId: 'Package', // ??
                    errorTitle: FlowErrorTitleI18n[FlowError.PackageRedirectUrlValidUrl],
                    text: FlowErrorTextI18n[FlowError.PackageRedirectUrlValidUrl],
                },
            });
        }
    }

    if (settings.customizationSettings.isCallbackUrlVisibleInFrontend && properties.isCallbackUrlEnabled) {
        if (properties.callbackUrl != null && properties.callbackUrl !== '' && !ValidateUtils.url(properties.callbackUrl)) {
            errors.push({
                error: FlowError.PackageCallbackUrlValidUrl,
                errorInfo: {
                    type: FlowErrorStateType.Package,
                    entityId: 'Package', // ??
                    errorTitle: FlowErrorTitleI18n[FlowError.PackageCallbackUrlValidUrl],
                    text: FlowErrorTextI18n[FlowError.PackageCallbackUrlValidUrl],
                },
            });
        }
    }

    if (settings.customizationSettings.isNotificationCallbackUrlVisibleInFrontend && properties.isNotificationCallbackUrlEnabled) {
        if (properties.notificationCallbackUrl != null && properties.notificationCallbackUrl !== '' && !ValidateUtils.url(properties.notificationCallbackUrl ?? '')) {
            errors.push({
                error: FlowError.PackageNotificationUrlValidUrl,
                errorInfo: {
                    type: FlowErrorStateType.Package,
                    entityId: 'Package', // ??
                    errorTitle: FlowErrorTitleI18n[FlowError.PackageNotificationUrlValidUrl],
                    text: FlowErrorTextI18n[FlowError.PackageNotificationUrlValidUrl],
                },
            });
        }
    }

    return errors;
}

function validateStakeholders(stakeholders?: FlowStakeholderModel[], properties?: FlowPropertiesModel) {
    if (!stakeholders || !properties) {
        return [];
    }

    const errors: FlowStateError[] = [];

    if (properties.isSmsOtpAuthenticationEnabled) {
        stakeholders.forEach((stakeholder) => {
            if (stakeholder.type === StakeholderType.Person) {
                if (!stakeholder.phoneNumber) {
                    let errorTitle = '';

                    if (stakeholder.firstName != null || stakeholder.lastName != null) {
                        errorTitle = `${stakeholder.firstName ? stakeholder.firstName : ''} ${stakeholder.lastName}`;
                    } else if (stakeholder.emailAddress != null) {
                        errorTitle = stakeholder.emailAddress;
                    }

                    errors.push({
                        error: FlowError.StakeholderMissingPhoneNumberForAuthentication,
                        errorInfo: {
                            type: FlowErrorStateType.Stakeholder,
                            entityId: stakeholder.localId,
                            errorTitle,
                            text: FlowErrorTextI18n[FlowError.StakeholderMissingPhoneNumberForAuthentication],
                        },
                    });
                }
            }
        });
    }

    return errors;
}

function validateElements(elements?: FlowFieldModel[], stakeholders?: FlowStakeholderModel[]) {
    if (!elements || !stakeholders) {
        return [];
    }

    const errors: FlowStateError[] = [];

    elements.forEach((element) => {
        if (element.type === FieldType.TextBox) {
            if (!element.name) {
                errors.push({
                    error: FlowError.FieldMissingUniqueName,
                    errorInfo: {
                        type: FlowErrorStateType.Element,
                        entityId: element.localId,
                        entityType: AnyTypeToFlowEntityType[FieldType.TextBox],
                        errorTitle: FlowErrorTitleI18n[FlowError.FieldMissingUniqueName],
                        icon: 'fa-solid fa-text',
                        text: FlowErrorTextI18n[FlowError.FieldMissingUniqueName],
                    },
                });
            }
        } else if (element.type === FieldType.CheckBox) {
            if (!element.name) {
                errors.push({
                    error: FlowError.FieldMissingUniqueName,
                    errorInfo: {
                        type: FlowErrorStateType.Element,
                        entityId: element.localId,
                        entityType: AnyTypeToFlowEntityType[FieldType.CheckBox],
                        errorTitle: FlowErrorTitleI18n[FlowError.FieldMissingUniqueName],
                        icon: 'fa-regular fa-square-check',
                        text: FlowErrorTextI18n[FlowError.FieldMissingUniqueName],
                    },
                });
            }
        } else if (element.type === FieldType.RadioGroup) {
            if (!element.name) {
                errors.push({
                    error: FlowError.FieldMissingUniqueName,
                    errorInfo: {
                        type: FlowErrorStateType.Element,
                        entityId: element.localId,
                        entityType: AnyTypeToFlowEntityType[FieldType.RadioGroup],
                        errorTitle: FlowErrorTitleI18n[FlowError.FieldMissingUniqueName],
                        icon: 'fa-regular fa-circle-dot',
                        text: FlowErrorTextI18n[FlowError.FieldMissingUniqueName],
                    },
                });
            }
        } else if (element.type === FieldType.SigningField) {
            const stakeholder = stakeholders.find((stakeholder) => stakeholder.localId === element.stakeholderLocalId);

            if (!stakeholder || element?.signingMethods == null) {
                return;
            }

            const hasSMSSigningMethod = element?.signingMethods.some((signingMethod) => {
                const behaviour = signingMethod.name.split(':')[0];

                if (behaviour.toUpperCase() === 'SMSCODE' || behaviour.toUpperCase() === 'SMS') {
                    return true;
                }

                return false;
            });

            if (hasSMSSigningMethod && stakeholder?.type === StakeholderType.Person && stakeholder?.phoneNumber == null) {
                errors.push({
                    error: FlowError.StakeholderMissingPhoneNumberForSMSSigning,
                    errorInfo: {
                        type: FlowErrorStateType.Element,
                        entityId: element.localId,
                        entityType: AnyTypeToFlowEntityType[FieldType.SigningField],
                        errorTitle: FlowErrorTitleI18n[FlowError.StakeholderMissingPhoneNumberForSMSSigning],
                        icon: 'fa-solid fa-signature',
                        text: FlowErrorTextI18n[FlowError.StakeholderMissingPhoneNumberForSMSSigning],
                    },
                });
            }
        }
    });

    return errors;
}

function validateFlowState(flowType: FlowType, flowState: FlowStateType, settings: SettingsModel): FlowStateError[] {

    let errors: FlowStateError[] = [];

    if (flowType === FlowType.Package) {
        const packageNameErrors = validatePackageName(flowState.packageName);

        errors = errors.concat(packageNameErrors);
    }

    const packagePropertiesErrors = validatePackageProperties(settings, flowState.properties);

    errors = errors.concat(packagePropertiesErrors);

    const stakeholderErrors = validateStakeholders(flowState.stakeholders, flowState.properties);

    errors = errors.concat(stakeholderErrors);

    const elementErrors = validateElements(flowState.elements, flowState.stakeholders);

    errors = errors.concat(elementErrors);

    return errors;
}


export function FlowContextProvider({ children }: FlowContextProviderProps) {
    const settings = useSettings();
    const [isReady, setIsReady] = useState<boolean>(false);
    const [isNew, setIsNew] = useState<boolean>(false);
    const [isProcessing, setProcessing] = useState<boolean>(false);

    const historyState = useLocationState<{ isActionNeeded?: boolean }>();
    const documentViewerRef = useRef<DocumentViewerRef>(null);
    const leftPaneRef = useRef<FlowEditorLeftPaneRef>(null);
    const { flowType, flowStep } = useFlowInternalTypeAndStep();
    const { typePath, id } = useMatch('/:typePath/:stepPath/:id?')!.params;

    const [isActionNeeded, setIsActionNeeded] = useState<boolean>(false);
    const [visibleDocument, setVisibleDocument] = useState<FlowDetailDocument>();

    const lastWorkspaceId = useLastWorkspaceId()!; // if null it means there is no permission so page cannot be entered = it's always there

    const [flowState, dispatch] = useReducer<Reducer<FlowStateType, FlowStateAction>>(flowReducer, {
        flowId: id,
        workspaceId: lastWorkspaceId,
        infoDocuments: [],
        deletedDocuments: [],
        detailDocuments: [],
        isLegalNoticeEnabled: false,
        legalNotices: [],
        flow: [],
        elements: [],
        stakeholders: [],
    });

    const api = useFlowApiWrapper(flowType, flowState.flowId);
    const setFlowState = useFlowDispatcher(dispatch);

    const { errors, setError, clearError, clearErrors, getErrorInfo, hasError } = useFlowInternalErrors();

    const navigateToPortal = useFlowInternalNavigateToPortal({
        flowState,
        flowType,
    });
    const navigateToStep = useFlowInternalNavigateToStep({
        flowState,
        flowTypePath: typePath!,
    });

    const [documentViewerConfig, setDocumentViewerConfig] = useState<FlowDocumentViewerConfigType>({
        zoomLevel: 100,
        justifyPages: true,
        adjustViewerToScreen: false,
    });
    const setDocumentViewerConfigCallback = useCallback((config: Partial<FlowDocumentViewerConfigType>) => {
        setDocumentViewerConfig(current => ({
            ...current,
            ...config,
        }));
    }, []);

    const stakeholderColors = useFlowInternalStakeholderColors(flowState.flowId, flowState.stakeholders);

    const {
        focusedEntityId,
        focusedEntityType,
        focusedEntityData,
        setFocusedEntity,
    } = useFlowInternalFocusedEntity();

    useEffect(() => {
        setIsReady(false);
    }, [flowStep, historyState.isActionNeeded]);

    useEffect(() => {
        if (id && id !== flowState.flowId) {
            setFlowState(FlowActionType.SetFlowId, id);
            setIsReady(false);
        }
        if (id || flowState.flowId) {
            setIsNew(false);
        }
    }, [flowState.flowId, id, setFlowState]);

    useEffect(() => {
        if (!flowState.flowId && flowStep) {
            setIsNew(true);
            setIsReady(true);
        }
    }, [flowState.flowId, flowStep]);

    const fetchBasicData = useCallback(async (signal?: AbortSignal) => {
        if (flowStep === FlowStep.Documents) {
            try {
                const info = await api.getBasicInfo(signal);

                setFlowState(FlowActionType.SetDocuments, info.documents);
                setIsReady(true);
            } catch (e) {
                if (signal?.aborted) {
                    return;
                }
                if (e instanceof HttpError) {
                    const code = e.getErrorCode();

                    if (code === ApiErrors.PackageInvalidStatusProcessing) {
                        navigateToStep(FlowStep.Processing);

                        return;
                    } else if (code?.startsWith(ApiErrors.PackageInvalidStatusGeneric)) {
                        navigateToPortal();

                        return;
                    }
                }
                throw e;
            }

        } else if (flowStep === FlowStep.Detail) {
            if (historyState.isActionNeeded) {
                setIsActionNeeded(true);
                setIsReady(true);

                return;
            } else {
                setIsActionNeeded(false);
            }
            try {
                const result = await Promise.all([
                    api.getDetails(signal),
                    api.getAvailableLegalNotices(signal),
                    api.getStakeholders(signal),
                    api.getWorkflow(signal),
                    api.getElements(signal),
                ]);

                setFlowState(FlowActionType.SetInitialDetails, result[0]);
                setFlowState(FlowActionType.SetInitialLegalNoticeConfig, result[1]);
                setFlowState(FlowActionType.SetInitialFlow, {
                    stakeholders: result[2],
                    workflow: result[3].queryResult,
                    elements: result[4],
                });

                setIsReady(true);
            } catch (e) {
                if (signal?.aborted) {
                    return;
                }

                if (e instanceof HttpError) {
                    const code = e.getErrorCode();

                    if (code === ApiErrors.PackageInvalidStatusProcessingFailed) {
                        navigateToStep(FlowStep.Documents);

                        return;
                    } else if (code?.startsWith(ApiErrors.PackageInvalidStatusGeneric)) {
                        navigateToPortal();

                        return;
                    }
                }
                throw e;
            }

        } else {
            setIsReady(true);
        }
    }, [
        api,
        flowStep,
        historyState.isActionNeeded,
        navigateToPortal,
        navigateToStep,
        setFlowState,
    ]);

    useAsyncEffect(() => async (signal: AbortSignal) => {
        if (isReady || !flowState.flowId) {
            return;
        }
        await fetchBasicData(signal);
    }, [fetchBasicData, flowState.flowId, isReady]);

    useEffect(() => {
        if (flowStep === FlowStep.Detail) {
            const flowStateErrors = validateFlowState(flowType, flowState, settings);

            clearErrors();
            flowStateErrors.forEach((flowStateError) => {
                setError(flowStateError.error, flowStateError.errorInfo);
            });
        }
    }, [
        clearErrors,
        setError,
        flowState,
        flowStep,
        flowType,
        settings,
    ]);

    const contextValue = useMemo<FlowContextType>(() => ({
        flowType,
        isNew,
        isProcessing,
        setProcessing,
        isActionNeeded,
        flowState,
        setFlowState,
        api,
        errors,
        setError,
        clearError,
        clearErrors,
        getErrorInfo,
        hasError,
        step: flowStep,
        navigateToStep,
        navigateToPortal,
        refreshData: fetchBasicData,
        focusedEntityId,
        focusedEntityType,
        focusedEntityData,
        setFocusedEntity,
        visibleDocument,
        setVisibleDocument,
        documentViewerConfig,
        setDocumentViewerConfig: setDocumentViewerConfigCallback,
        stakeholderColors,
        documentViewerRef,
        leftPaneRef,
    }), [
        flowType,
        isNew,
        isProcessing,
        isActionNeeded,
        flowState,
        setFlowState,
        api,
        errors,
        setError,
        clearError,
        clearErrors,
        getErrorInfo,
        hasError,
        flowStep,
        navigateToStep,
        navigateToPortal,
        fetchBasicData,
        focusedEntityId,
        focusedEntityType,
        focusedEntityData,
        setFocusedEntity,
        visibleDocument,
        setVisibleDocument,
        documentViewerConfig,
        setDocumentViewerConfigCallback,
        stakeholderColors,
    ]);

    return (
        <FlowContext.Provider value={contextValue}>
            {!isReady && <Loader center size={SizesEnums.XLARGE} />}
            {isReady && children}
        </FlowContext.Provider>
    );
}
