import { v4 as uuid } from 'uuid';
import { TemplateInfo } from '~models/template.models';
import { AddDocumentsReducer } from './document.reducers.flow';
import { FlowActionType, FlowReducerFunc } from '../flow.types';

export type FlowReducerTemplateActionPayloads = {
    [FlowActionType.SetTemplate]: TemplateInfo;
    [FlowActionType.RemoveTemplate]: void;
    [FlowActionType.SetTemplateName]: string;
}

export const SetTemplateReducer: FlowReducerFunc<FlowActionType.SetTemplate> = (
    state,
    payload,
) => {
    let newState = RemoveTemplateReducer(state);

    newState = AddDocumentsReducer(newState, payload.documents.map((el => ({
        ...el,
        localId: uuid(),
        templateFile: true,
    }))));

    newState.templateId = payload.id;
    newState.packageName = payload.packageName;

    return newState;
};
export const RemoveTemplateReducer: FlowReducerFunc<FlowActionType.RemoveTemplate> = (
    state,
) => {
    return {
        ...state,
        infoDocuments: state.infoDocuments.filter((el) => !el.templateFile),
        templateId: undefined,
        packageName: undefined,
    };
};

export const SetTemplateNameReducer: FlowReducerFunc<FlowActionType.SetTemplateName> = (
    state,
    payload,
) => {
    return {
        ...state,
        templateName: payload,
    };
};
