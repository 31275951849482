import { useMemo } from 'react';
import { ContactType, ContactTypeI18n, Permission } from '~constants';
import { PortalTableFiltersDefinition } from '~containers/portal-table/portal-table-filters/portal-table-filters';
import { useHasPermission } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';

export const useContactsTableFilters = () => {
    const { t: tBase } = useTranslation('base');
    const { t: tContacts } = useTranslation('contacts');
    const hasViewSharedContactsPermission = useHasPermission(Permission.General_ActionViewSharedContact);
    const settings = useSettings();
    const { google, microsoft } = settings.cloudSettings;

    return useMemo<PortalTableFiltersDefinition>(() => {
        const baseFilterTypes = [ContactType.Personal];

        const filters: PortalTableFiltersDefinition = {
            searchAll: {
                type: 'single',
                key: 'searchString',
                placeholder: tBase('search-input-placeholder'),
            },
            dropdowns: [],
        };


        if (hasViewSharedContactsPermission) {
            baseFilterTypes.push(ContactType.Shared);
        }

        // Conditionally add ContactType.Cloud if either Google or Microsoft is enabled
        if (google.oauthSettings.isEnabled || microsoft.oauthSettings.isEnabled) {
            baseFilterTypes.push(ContactType.Cloud);
        }

        if (baseFilterTypes.length > 1) {
            filters.dropdowns?.push({
                type: 'multi',
                key: 'types',
                options: baseFilterTypes.map((type) => ({
                    value: type,
                    label: tContacts(ContactTypeI18n[type]),
                })),
                defaultOption: '*',
                translations: {
                    label: tContacts('contact-type-label'),
                    allItems: tContacts('contact-type-all'),
                    none: tContacts('contact-type-none'),
                },
            });
        }

        return filters;
    }, [
        google.oauthSettings.isEnabled,
        hasViewSharedContactsPermission,
        microsoft.oauthSettings.isEnabled,
        tBase,
        tContacts,
    ]);
};
