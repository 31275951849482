/**
 * Contains any utility function that does not belong to any other utility class. In other words, generic stuff.
 * Ideally, it should not use any import since that would make the utility function too specialized to put in here.
 */
export namespace CommonUtils {

    /** Options used in `delayFunc` */
    export interface DelayFuncOptions {
        /**
         * When no executions happen within this time-period, only then the inner function is executed. Use only
         * this to have the classical approach for debouncing: only execute after everything was settled.
         */
        debounce?: number;

        /**
         * Minimum time to wait between executions of the inner function. Using only this might result in inconsistent
         * states since the last update might be skipped over. Using both `debounce` and `throttle` allows for regular
         * updates to the UI while guaranteeing you will eventually come into a consistent state.
         */
        throttle?: number;

        /**
         * Whenever there is a longer pause and the delay is triggered again, we won't execute the inner function to
         * mimic the behavior of a 'normal' debounce. If you do want the execution immediately after a pause, set this
         * to `true`.
         *
         * Note: a pause is defined as approximate `2 * throttle + debounce`.
         */
        executeAfterRest?: boolean;
    }

    export type DelayFunc = (func: () => void, immediate?: boolean) => void;

    /**
     * @deprecated use lodash throttle or debounce instead
     * Creates a function that can be used to delay the execution of another function. Make sure to use the same instance of the returned function.
     * @param options Options for debounce and throttle times
     * @returns A function that delays the given function
     * @example
     * // Create the delay function outside of the event listener
     * const delay = delayFunc({ debounce: 150, throttle: 200 });
     * // Use the delay function inside the event listener to delay its execution
     * window.addEventListener('scroll', () => {
     *   delay(() => console.log(document.scrollTop));
     * });
     */
    export function delayFunc(options?: DelayFuncOptions): DelayFunc {
        const opts = {
            ...delayFunc.defaultOptions,
            ...options,
        };

        // Keep object with data outside of function to use it to hold data between calls
        const data: { handle?: NodeJS.Timeout, lastUpdate: number; } = { lastUpdate: Date.now() };

        return (func, immediate = false) => {
            const execute = () => {
                func();
                data.lastUpdate = Date.now();
            };

            const now = Date.now();

            // Make sure throttle isn't triggered if the last event was 'a long time ago' by updating the lastUpdate
            if (opts.executeAfterRest && opts.throttle != null && data.lastUpdate + 2 * opts.throttle + (opts.debounce ?? 0) > now) {
                data.lastUpdate = now;
            }

            // Always clear our timeout on a new execution
            if (opts.debounce != null && data.handle) {
                clearTimeout(data.handle);
                data.handle = undefined;
            }

            if (immediate) {
                // Ignore options and execute immediately when specified
                execute();
            } else if (opts.throttle > 0 && data.lastUpdate + opts.throttle <= new Date().valueOf()) {
                // If throttle is specified and options.throttle time has passed since the last execution
                execute();
            } else if (opts.debounce != null) {
                // Otherwise, we'll set a debounce to execute the function after options.debounce ms
                data.handle = setTimeout(() => execute(), opts.debounce);
            } else {
                // No options given? Just execute the function directy then I suppose
                execute();
            }
        };
    }

    delayFunc.defaultOptions = {
        debounce: 0,
        throttle: 0,
        executeAfterRest: false,
    } as Required<DelayFuncOptions>;

    let _remPixels = 0;

    export function remPixels(): number {
        if (_remPixels) {
            return _remPixels;
        }
        _remPixels = parseFloat(getComputedStyle(document.documentElement).fontSize);

        return _remPixels;
    }

    export function toPx(value: string | number): number {
        if (typeof value === 'number') {
            return value;
        }
        if (value.endsWith('rem')) {
            return parseFloat(value) * remPixels();
        }

        return parseFloat(value);

    }
}
