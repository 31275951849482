import { NdsInputChangeEvent, NdsInputProps } from '@gonitro/rcl';
import { StatesEnum } from '@gonitro/rcl/lib/_types/designsystem.enums';
import { useCallback } from 'react';
import { Control, useFormState } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import { InternalFieldName } from 'react-hook-form/dist/types/fields';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';
import { FieldPath } from 'react-hook-form/dist/types/path';
import { EnhancedRegisterOptions, UseEnhancedFormRegister } from './enhanced-register';

export type UseFormRegisterNdsInput<TFieldValues extends FieldValues> = <TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    name: TFieldName,
    options?: EnhancedRegisterOptions<TFieldValues, TFieldName>,
    inputProps?: RegisterNdsInputComponentProps,
) => UseFormRegisterNdsInputReturn<TFieldName>;

export interface UseFormRegisterNdsInputReturn<TFieldName extends InternalFieldName = InternalFieldName>
    extends Omit<UseFormRegisterReturn<TFieldName>, 'onChange'>, // onChange is replaced with onNdsChange
        Pick<NdsInputProps, 'state' | 'hintText'> { // state and hintText are needed to display errors
    onNdsChange: (e: NdsInputChangeEvent) => void;
}

// state and hintText will be overwritten by this hook's callback if passed directly in props before this hook callback
// to prevent that they should be explicitly passed to registerNdsInput
export type RegisterNdsInputComponentProps = {
    state?: NdsInputProps['state'];
    hintText?: NdsInputProps['hintText'];
}

export const useRegisterNdsInput = <TFieldValues extends FieldValues, TContext = any>(
    register: UseEnhancedFormRegister<TFieldValues>,
    control: Control<TFieldValues, TContext>,
) => {
    const { errors } = useFormState({ control });

    // TODO think about submit on enter like in normal form
    // const handleKeyPress = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    //     if (e.key === 'Enter') {
    //         e.preventDefault();
    //         // Call the function to handle form submission
    //         formSubmitOnClick();
    //     }
    // }, []);

    return useCallback<UseFormRegisterNdsInput<TFieldValues>>(
        (
            inputType,
            registerOptions,
            inputProps,
        ) => {
            const { noErrorTranslation, ...options } = registerOptions || {};

            const { onChange, translatedError, ...rest } = register(inputType, options);

            let { state = StatesEnum.DEFAULT, hintText } = inputProps || {};

            const error = errors[inputType];

            if (error && error.type === 'empty') {
                state = StatesEnum.ERROR;
                hintText = '';
            } else if (error) {
                state = StatesEnum.ERROR;

                if (!noErrorTranslation) {
                    hintText = translatedError;
                }
            }

            return {
                ...rest,
                onNdsChange: (e: NdsInputChangeEvent) => {
                    e.target.value = e.detail.value;
                    onChange(e);
                },
                state,
                hintText,
            };
        }, [errors, register],
    );
};