import { NdsIconFont } from '@gonitro/rcl';
import { Icon } from '@progress/kendo-react-common';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { ReactNode, useMemo } from 'react';
import Typography, { TypographyToken } from '~components/typography';
import { useFlowContext } from '~contexts/flow';
import { FlowError } from '~contexts/flow/flow.error';
import { FlowEntityType, FlowErrorStateType } from '~contexts/flow/flow.types';
import { useTranslation } from '~contexts/i18n';
import './flowEditorErrors.scss';

export interface FlowEditorErrorCardProps {
    title: string;
    text: string;
    icon?: string;
    onClick: () => void
}

function FlowEditorErrorCard({ title, text, icon, onClick }: FlowEditorErrorCardProps) {
    return (
        <div className={'c-flow-editor-error-card'} onClick={onClick}>
            <div className={'c-flow-editor-error-card__icon-container'}>
                {icon && <Icon className={icon} />}
            </div>
            <div className={'c-flow-editor-error-card__text-container'}>
                <Typography
                    token={TypographyToken.DesktopDescriptionSm}
                    text={title}
                />
                <Typography
                    className={'c-flow-editor-error-card__text-container-error-text'}
                    token={TypographyToken.DesktopDescriptionSm}
                    text={text}
                />
            </div>
            <div className={'c-flow-editor-error-card__arrow-container'}>
                <NdsIconFont fontName='fa-solid-arrow-right' />
            </div>
        </div>
    );
}

export interface FlowEditorErrorsProps {

}

interface FlowEditorError {
    errorKey: FlowError;
    errorInfo: any;
}

function FlowEditorErrors(props: FlowEditorErrorsProps) {
    const { errors, setFocusedEntity } = useFlowContext();
    const { t: tFlow } = useTranslation('flow');

    const { packageErrors, stakeholderErrors, elementErrors } = useMemo(() => {
        const pErrors: FlowEditorError[] = [];
        const sErrors: FlowEditorError[] = [];
        const eErrors: FlowEditorError[] = [];

        Object.keys(errors).forEach((errorKey) => {
            const errorInfo = errors[errorKey as FlowError];

            if (errorInfo.type === FlowErrorStateType.Package) {
                pErrors.push({
                    errorKey: errorKey as FlowError,
                    errorInfo,
                });
            } else if (errorInfo.type === FlowErrorStateType.Stakeholder) {
                sErrors.push({
                    errorKey: errorKey as FlowError,
                    errorInfo,
                });
            } else if (errorInfo.type === FlowErrorStateType.Element) {
                eErrors.push({
                    errorKey: errorKey as FlowError,
                    errorInfo,
                });
            }
        });

        return {
            packageErrors: pErrors,
            stakeholderErrors: sErrors,
            elementErrors: eErrors,
        };
    }, [errors]);

    // rendering each error group as array items due to weird panelbar proptype check error
    const renderedErrors: ReactNode[] = [];

    if (packageErrors.length) {
        renderedErrors.push(
            <PanelBarItem
                key={'packageErrors'}
                title={`${packageErrors.length} Package errors`}
                expanded
            >
                <div className={'c-flow-editor-errors-cards-container'}>
                    {packageErrors.map((error, index) => {
                        return (
                            <FlowEditorErrorCard
                                key={index}
                                title={error.errorInfo?.errorTitle ? tFlow(error.errorInfo?.errorTitle) : ''}
                                text={error.errorInfo?.text ? tFlow(error.errorInfo?.text) : ''}
                                onClick={() => {
                                    setFocusedEntity(FlowEntityType.Package);
                                }}
                            />
                        );
                    })}
                </div>
            </PanelBarItem>,
        );
    }
    if (stakeholderErrors.length > 0) {
        renderedErrors.push(
            <PanelBarItem
                key={'stakeholderErrors'}
                title={`${stakeholderErrors.length} Recipient errors`}
                expanded
            >
                <div className={'c-flow-editor-errors-cards-container'}>
                    {stakeholderErrors.map((error, index) => {
                        return (
                            <FlowEditorErrorCard
                                key={index}
                                title={error.errorInfo?.errorTitle ?? ''}
                                text={error.errorInfo?.text ? tFlow(error.errorInfo?.text) : ''}
                                onClick={() => {
                                    setFocusedEntity(FlowEntityType.Stakeholder, error.errorInfo?.entityId);
                                }}
                            />
                        );
                    })}
                </div>
            </PanelBarItem>,
        );
    }
    if (elementErrors.length > 0) {
        renderedErrors.push(
            <PanelBarItem
                key={'elementErrors'}
                title={`${elementErrors.length} Field errors`}
                expanded
            >
                <div className={'c-flow-editor-errors-cards-container'}>
                    {elementErrors.map((error, index) => {
                        return (
                            <FlowEditorErrorCard
                                key={index}
                                title={error.errorInfo?.errorTitle ? tFlow(error.errorInfo?.errorTitle) : ''}
                                text={error.errorInfo?.text ? tFlow(error.errorInfo?.text) : ''}
                                icon={error.errorInfo?.icon ?? ''}
                                onClick={() => {
                                    setFocusedEntity(error.errorInfo?.entityType, error.errorInfo?.entityId);
                                }}
                            />
                        );
                    })}
                </div>
            </PanelBarItem>,
        );
    }

    return (
        <div className={'c-flow-editor-errors'}>
            <div className={'c-flow-editor-errors__header'}>
                <Typography
                    token={TypographyToken.DesktopDescriptionSm}
                    text={'Some fields and/or recipients have missing properties.'}
                />
            </div>
            {renderedErrors.length > 0 && (
                <PanelBar>
                    {renderedErrors}
                </PanelBar>
            )}
        </div>
    );
}

export default FlowEditorErrors;
