import { CheckboxProps } from '@gonitro/rcl';
import { useCallback } from 'react';
import { Control, useFormState, useWatch } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import { InternalFieldName } from 'react-hook-form/dist/types/fields';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';
import { FieldPath } from 'react-hook-form/dist/types/path';
import { EnhancedRegisterOptions, UseEnhancedFormRegister } from './enhanced-register';

export type UseFormRegisterCheckbox<TFieldValues extends FieldValues> = <TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    name: TFieldName,
    options?: EnhancedRegisterOptions<TFieldValues, TFieldName>,
    inputProps?: RegisterCheckboxComponentProps,
) => UseFormRegisterCheckboxReturn<TFieldName>;

export interface UseFormRegisterCheckboxReturn<TFieldName extends InternalFieldName = InternalFieldName>
    extends Omit<UseFormRegisterReturn<TFieldName>, 'onChange'>,
        Pick<CheckboxProps, 'onChange' | 'description' | 'error'> {

}

// error and description will be overwritten by this hook's callback if passed directly in props before this hook callback
// to prevent that they should be explicitly passed to registerCheckbox
export type RegisterCheckboxComponentProps = {
    error?: boolean;
    description?: string;
}

export const useRegisterCheckbox = <TFieldValues extends FieldValues, TContext = any>(
    register: UseEnhancedFormRegister<TFieldValues>,
    control: Control<TFieldValues, TContext>,
) => {
    const { errors } = useFormState({ control });
    const watched = useWatch({ control });

    return useCallback<UseFormRegisterCheckbox<TFieldValues>>(
        (
            inputType,
            registerOptions,
            inputProps,
        ) => {
            const { noErrorTranslation, ...options } = registerOptions || {};
            const { translatedError, ...rest } = register(inputType, options);

            let { error: hasError = false, description } = inputProps || {};
            const error = errors[inputType];

            if (error) {
                hasError = true;

                if (!noErrorTranslation) {
                    description = translatedError;
                }
            }

            return {
                ...rest,
                value: watched[inputType],
                error: hasError,
                description,
            };
        }, [errors, register, watched],
    );
};