import { useCallback, useContext, useMemo } from 'react';
import { ContactDiscriminator, ContactGroupDiscriminator } from '~constants';
import { FlowActionType } from '~contexts/flow/flow.types';
import { useDefaultLanguageCodes } from '~contexts/i18n';
import { PromiseUtils, StringUtils } from '~lib';
import {
    ContactBucketItemModel,
    FlowStakeholderModel,
    FlowStakeholderUndecidedModel,
    Guid,
    StakeholderType,
} from '~models';
import { FlowContext } from '../flow.context';

export const useAddStakeholder = () => {
    const { api, setFlowState, flowState: { stakeholders } } = useContext(FlowContext);
    const { defaultUserLanguage } = useDefaultLanguageCodes();

    return useCallback(async (item: ContactBucketItemModel): Promise<Guid> => {
        let exists: FlowStakeholderModel | undefined;
        let newData: any;

        switch (item.discriminator) {
            case ContactGroupDiscriminator.ContactGroup: {
                exists = stakeholders.find((el) => {
                    return 'contactGroupId' in el && el.contactGroupId === item.contactGroupId;
                });
                newData = { contactGroupId: item.contactGroupId };
                break;
            }
            case ContactDiscriminator.PortalContact: {
                exists = stakeholders.find((el) => {
                    return 'contactId' in el && el.contactId === item.contactId;
                });

                newData = { contactId: item.contactId! };
                break;
            }
            case ContactDiscriminator.CloudContact: {
                exists = stakeholders.find((el) => {
                    return 'emailAddress' in el && !('contactId' in el) && el.emailAddress === item.emailAddress;
                });

                newData = {
                    firstName: item.firstName,
                    lastName: item.lastName!,
                    emailAddress: item.emailAddress,
                    language: defaultUserLanguage,
                };

                break;
            }
        }

        if (exists) {
            return exists.localId;
        }

        if (!newData) {
            throw new Error('unknown contact data type');
        }
        const result = await api.createStakeholder(newData);
        const localId = StringUtils.guid();

        setFlowState(FlowActionType.AddStakeholder, {
            ...result,
            localId,
        });

        return localId;
    }, [api, defaultUserLanguage, setFlowState, stakeholders]);
};

export const useRefreshStakeholders = () => {
    const { api, setFlowState } = useContext(FlowContext);

    return useCallback(async (signal?: AbortSignal) => {
        const result = await api.getStakeholders(signal);

        setFlowState(FlowActionType.SetStakeholders, result);
        await PromiseUtils.wait();
    }, [api, setFlowState]);
};

export type useGetStakeholderInfoCallback = (localId?: Guid) => Exclude<FlowStakeholderModel, FlowStakeholderUndecidedModel> | undefined;
export const useGetStakeholderInfo = () => {
    const { flowState: { stakeholders } } = useContext(FlowContext);

    // allowing undefined as localId to simplify usages
    return useCallback<useGetStakeholderInfoCallback>((localId) => {
        if (!localId) {
            return undefined;
        }
        const found = stakeholders.find((el) => el.localId === localId);

        return (!found || found.type === StakeholderType.Undecided) ? undefined : found;
    }, [stakeholders]);
};

export const useGetStakeholderColorClass = () => {
    const { stakeholderColors } = useContext(FlowContext);

    return useCallback((localId?: Guid) => {
        const color = localId && stakeholderColors[localId] !== undefined ? stakeholderColors[localId] + 1 : 0;

        return `c-flow-editor-stakeholder-palette--${color}`;
    }, [stakeholderColors]);
};

export const useStakeholderColorClass = (localId?: Guid) => {
    const { stakeholderColors } = useContext(FlowContext);

    return useMemo(() => {
        const color = localId && stakeholderColors[localId] !== undefined ? stakeholderColors[localId] + 1 : 0;

        return `c-flow-editor-stakeholder-palette--${color}`;
    }, [localId, stakeholderColors]);
};

export const useUndecidedStakeholderId = () => {
    const { flowState: { stakeholders } } = useContext(FlowContext);

    return stakeholders.find((el) => el.type === StakeholderType.Undecided)?.localId;
};
