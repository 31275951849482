import FlowViewWrapper from 'components/flow/flow-view-wrapper';
import { useCallback, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { PortalsApi } from '~api/portals.api';
import { TemplateApi } from '~api/template.api';
import FlowDocumentsContent from '~components/flow/flow-documents/flow-documents-content';
import Typography, { TypographyToken } from '~components/typography';
import { ApiErrors } from '~constants';
import { useApi } from '~contexts/api';
import { useAppContext } from '~contexts/app';
import { useFlowContext } from '~contexts/flow';
import { FlowError } from '~contexts/flow/flow.error';
import { FlowActionType, FlowStep } from '~contexts/flow/flow.types';
import { useTranslation } from '~contexts/i18n';
import { HttpError } from '~lib/http/http.error';

export default function TemplateDocumentsView() {
    const navigate = useNavigate();
    const portalsApi = useApi(PortalsApi);
    const templateApi = useApi(TemplateApi);
    const { lastPortalPath } = useAppContext();
    const { t } = useTranslation('flow');
    const {
        api,
        isNew,
        isProcessing,
        setProcessing,
        setFlowState,
        navigateToStep,
        clearError,
        setError,
        hasError,
        flowState: {
            flowId,
            infoDocuments,
            deletedDocuments,
            templateName,
            workspaceId,
        },
    } = useFlowContext();

    const occupiedTemplateNames = useRef<string[]>([]);
    const nameWasSet = useRef<boolean>(false);

    useEffect(() => {
        if (infoDocuments.some(doc => doc.error)) {
            setError(FlowError.DocumentProcessingFailure);
        } else {
            clearError(FlowError.DocumentProcessingFailure);
        }
    }, [clearError, infoDocuments, setError]);
    useEffect(() => {
        if (!nameWasSet.current && !templateName?.length && infoDocuments.length) {
            setFlowState(FlowActionType.SetTemplateName, `template-${infoDocuments[0].documentName}`);
        }
    }, [infoDocuments, setFlowState, templateName?.length]);

    useEffect(() => {
        if (!templateName) {
            return;
        }
        nameWasSet.current = true;

        if (occupiedTemplateNames.current.includes(templateName)) {
            setError(FlowError.TemplateNameNotUnique);
        } else {
            clearError(FlowError.TemplateNameNotUnique);
        }
    }, [clearError, setError, templateName]);

    const onNextClick = useCallback(async () => {

        if (!templateName) {
            return;
        }
        setProcessing(true);

        if (isNew) {
            try {
                const { flowId } = await templateApi.create({
                    name: templateName,
                    workspaceId: workspaceId!,
                });

                await api.deleteDocuments(flowId, deletedDocuments);
                const { success, erroredDocsLocalIds } = await api.saveDocuments(flowId, infoDocuments);

                if (success) {
                    setFlowState(FlowActionType.SetFlowId, flowId);
                    navigateToStep(FlowStep.Processing, { flowId });
                } else {
                    // TODO not sure how to make it occur without violating rules and handle it aka can be edge case
                    setError(FlowError.DocumentUploadFailure, erroredDocsLocalIds);
                    await portalsApi.deleteTemplate({ templateId: flowId }); // delete template to allow creation process be started again
                }
            } catch (e) {
                if (e instanceof HttpError && e.getErrorCode()?.startsWith(ApiErrors.TemplateNameNotUnique)) {
                    setError(FlowError.TemplateNameNotUnique);

                    if (!occupiedTemplateNames.current.includes(templateName)) {
                        occupiedTemplateNames.current.push(templateName);
                    }

                    setProcessing(false);

                    return;
                }
            }
        } else if (flowId) {
            await api.deleteDocuments(flowId, deletedDocuments);
            await api.saveDocuments(flowId, infoDocuments);
            navigateToStep(FlowStep.Processing);
        } else {
            throw new Error('Flow id not defined');
        }
        setProcessing(false);
    }, [
        setProcessing,
        templateName,
        isNew,
        flowId,
        templateApi,
        workspaceId,
        api,
        deletedDocuments,
        infoDocuments,
        setFlowState,
        navigateToStep,
        setError,
        portalsApi,
    ]);

    const onBackClick = useCallback(() => {
        if (isNew) {
            navigate(lastPortalPath);
        } else {
            navigate(-1);
        }
    }, [isNew, lastPortalPath, navigate]);
    const onCloseClick = useCallback(() => {
        navigate(lastPortalPath);
    }, [lastPortalPath, navigate]);

    const nextButtonEnabled =
        !isProcessing
        && (
            infoDocuments.length === 0
            || infoDocuments.every(({ documentName }) => documentName.length)
        )
        && !!(templateName?.length)
        && !hasError();

    return (
        <FlowViewWrapper
            scrollable
            headerCenter={
                <Typography
                    token={TypographyToken.DesktopDescriptionBoldMd}
                    text={t(isNew ? 'create-new-template-title' : 'edit-template-title')}
                />
            }
            onCloseClick={onCloseClick}
        >
            <FlowDocumentsContent
                useTemplateNameInput
                nextButtonEnabled={nextButtonEnabled}
                onNextClick={onNextClick}
                onBackClick={onBackClick}
            />
        </FlowViewWrapper>
    );
}
