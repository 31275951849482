import { HTMLAttributes } from 'react';
import './footer.scss';

export interface FooterProps extends HTMLAttributes<HTMLDivElement> {
}

function Footer(props: FooterProps) {
    return (
        <div className={'c-footer'}></div>
    );
}

export default Footer;