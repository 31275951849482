import { NdsButton, NdsIconFont } from '@gonitro/rcl';
import { NdsButtonStylesEnum, NdsButtonTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { useMemo } from 'react';
import PackageRecipientDropdown from '~components/package-recipient-dropdown';
import { ActorType } from '~constants';
import { useFlowContext, useSetFlowState } from '~contexts/flow';
import { useAddActor } from '~contexts/flow/flow.hooks';
import { FlowActionType, FlowEntityType } from '~contexts/flow/flow.types';
import { FlowUtils } from '~contexts/flow/flow.utils';
import { useTranslation } from '~contexts/i18n';
import './flowEditorApproverProperties.scss';

export interface FlowEditorApproverPropertiesProps {

}

function FlowEditorApproverProperties(props: FlowEditorApproverPropertiesProps) {
    const { focusedEntityId, setFocusedEntity, flowState: { stakeholders, flow } } = useFlowContext();
    const addActorCallback = useAddActor();
    const setFlowState = useSetFlowState();

    const found = useMemo(() => {
        if (!focusedEntityId) {
            return;
        }

        return FlowUtils.findActor(flow, focusedEntityId)?.actor ?? null;
    }, [flow, focusedEntityId]);

    if (!found) {
        return null;
    }

    return (
        <div className={'c-flow-editor-approver-properties c-flow-editor-right-pane-component'}>
            <PanelBar>
                <PanelBarItem
                    title={'Recipient'}
                    expanded
                >
                    <PackageRecipientDropdown
                        stakeholders={stakeholders}
                        selectedStakeholderLocalId={found?.stakeholderLocalId}
                        onRecipientChange={(stakeholder) => {
                            // TODO this should be fixed to show contacts
                            const existingApprover = FlowUtils.findStakeholderActors(flow, stakeholder.localId).filter((actorResult) => (actorResult.actor.actorType === ActorType.Approver));
                            const existingFormFiller = FlowUtils.findStakeholderActors(flow, stakeholder.localId).filter((actorResult) => (actorResult.actor.actorType === ActorType.FormFiller));

                            if (existingFormFiller.length > 0) {
                                addActorCallback(ActorType.Approver, stakeholder.localId, existingFormFiller[0].stepIndex);
                            } else if (existingApprover.length === 0 && existingFormFiller.length === 0) {
                                addActorCallback(ActorType.Approver, stakeholder.localId, 'last');
                            }

                            const originalActor = FlowUtils.findActorByElementId(flow, found.localId);

                            if (originalActor) {
                                setFlowState(FlowActionType.DeleteActor, { actorId: originalActor.actor.localId });
                            }

                            found.stakeholderLocalId = stakeholder.localId;
                        }}
                    />
                </PanelBarItem>
            </PanelBar>
            <div className={'c-flow-editor-signing-field-properties-footer'}>
                <div className={'c-flow-editor-signing-field-properties-footer-button-container'}>
                    <NdsButton
                        size={SizesEnums.SMALL}
                        label={'Remove approval'}
                        buttonType={NdsButtonTypesEnum.SECONDARY}
                        buttonStyle={NdsButtonStylesEnum.ALT}
                        leftIcon='fa-light-trash'
                        onClick={() => {
                            setFlowState(FlowActionType.DeleteActor, { actorId: found?.localId });

                            setFocusedEntity(FlowEntityType.Package);
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

const Header = () => {
    const { t } = useTranslation('flow');

    return (
        <div className={'c-flow-editor-right-pane-component-header'}>
            <div className={'c-flow-editor-right-pane-component-header__icon'}>
                <NdsIconFont fontName={'fa-regular-circle-check'} />
            </div>
            <div className={'c-flow-editor-right-pane-component-header__title'}>
                {t('field-object-label-approval')}
            </div>
            <div className={'c-flow-editor-right-pane-component-header__action'}></div>
        </div>
    );
};

FlowEditorApproverProperties.Header = Header;
export default FlowEditorApproverProperties;
