import { Checkbox, NdsButton, NdsIconFont } from '@gonitro/rcl';
import { NdsButtonStylesEnum, NdsButtonTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import { Input } from '@progress/kendo-react-inputs';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { useMemo } from 'react';
import PackageRecipientDropdown  from '~components/package-recipient-dropdown';
import Typography, { TypographyToken } from '~components/typography';
import { ActorType } from '~constants';
import { useFlowContext, useSetFlowState } from '~contexts/flow';
import { FlowError } from '~contexts/flow/flow.error';
import { useAddActor } from '~contexts/flow/flow.hooks';
import { FlowActionType, FlowEntityType } from '~contexts/flow/flow.types';
import { FlowUtils } from '~contexts/flow/flow.utils';
import { useTranslation } from '~contexts/i18n';
import { FlowCheckboxFieldModel } from '~models';
import './flowEditorCheckboxFieldProperties.scss';

export interface FlowEditorCheckboxFieldPropertiesProps {

}

function FlowEditorCheckboxFieldProperties(props: FlowEditorCheckboxFieldPropertiesProps) {
    const { focusedEntityId, flowState: { stakeholders, elements, flow }, hasError, getErrorInfo, setFocusedEntity } = useFlowContext();
    const setFlowState = useSetFlowState();
    const { t: tFlow } = useTranslation('flow');
    const found = elements.find(el => el.localId === focusedEntityId) as FlowCheckboxFieldModel;
    const addActorCallback = useAddActor();

    const actorStakeholderLocalId = useMemo(() => {
        return FlowUtils.findActorByElementId(flow, found?.localId)?.actor.stakeholderLocalId;
    }, [found, flow]);

    const uniqueNameError = useMemo<{ text: any }>(() => {
        if (focusedEntityId) {
            if (hasError(FlowError.FieldMissingUniqueName, focusedEntityId)) {
                return getErrorInfo(FlowError.FieldMissingUniqueName, focusedEntityId);
            }
        }
    }, [focusedEntityId, hasError, getErrorInfo]);

    return (
        <div className={'c-flow-editor-checkbox-field-properties c-flow-editor-right-pane-component'}>
            <PanelBar>
                <PanelBarItem
                    title={'Recipient'}
                    expanded
                >
                    <PackageRecipientDropdown
                        stakeholders={stakeholders}
                        selectedStakeholderLocalId={actorStakeholderLocalId}
                        onRecipientChange={(stakeholder) => {
                            const existing = FlowUtils.findStakeholderActors(flow, stakeholder.localId).filter((actorResult) => actorResult.actor.actorType === ActorType.FormFiller);
                            const originalActor = FlowUtils.findActorByElementId(flow, found.localId);

                            if (existing.length > 0) {
                                setFlowState(FlowActionType.AddActorElements, {
                                    actorId: existing[0].actor.localId,
                                    elementIds: [found.localId],
                                });
                            } else {
                                addActorCallback(ActorType.FormFiller, stakeholder.localId, 'last', { elements: [found.localId] });
                            }

                            if (originalActor) {
                                setFlowState(FlowActionType.DeleteActorElements, {
                                    actorId: originalActor.actor.localId,
                                    elementIds: [found.localId],
                                });
                            }

                            found.stakeholderLocalId = stakeholder.localId;
                        }}
                    />
                </PanelBarItem>
                <PanelBarItem
                    title={'Unique name'}
                    expanded
                >
                    <Input
                        value={found?.name}
                        onChange={(e) => {
                            setFlowState(FlowActionType.UpdateElement, {
                                ...found,
                                name: e.value,
                            });
                        }}
                    />
                    {uniqueNameError &&
                        <Typography
                            className={'flow-error-text'}
                            text={tFlow(uniqueNameError.text)}
                            token={TypographyToken.DesktopDescriptionSm}
                        />
                    }
                </PanelBarItem>
                <PanelBarItem
                    title={'Label'}
                    expanded
                >
                    <Input
                        value={found?.label}
                        onChange={(e) => {
                            setFlowState(FlowActionType.UpdateElement, {
                                ...found,
                                label: e.value,
                            });
                        }}
                    />
                </PanelBarItem>
                <PanelBarItem
                    title={'Tooltip'}
                    expanded
                >
                    <Input
                        value={found?.toolTipLabel}
                        onChange={(e) => {
                            setFlowState(FlowActionType.UpdateElement, {
                                ...found,
                                toolTipLabel: e.value,
                            });
                        }}
                    />
                </PanelBarItem>
            </PanelBar>
            <div className={'c-flow-editor-checkbox-field-properties-footer'}>
                <div className={'c-flow-editor-text-field-properties-footer-checkbox'}>
                    <Checkbox
                        name={'IsCheckedByDefault'}
                        label={'Checked by default'}
                        value={found?.checkedByDefault}
                        onChange={(e) => {
                            setFlowState(FlowActionType.UpdateElement, {
                                ...found,
                                checkedByDefault: !found.checkedByDefault,
                            });
                        }}
                    />
                </div>
                <div className={'c-flow-editor-text-field-properties-footer-checkbox'}>
                    <Checkbox
                        name={'isRequired'}
                        label={'Required'}
                        value={found?.isRequired}
                        onChange={(e) => {
                            setFlowState(FlowActionType.UpdateElement, {
                                ...found,
                                isRequired: !found.isRequired,
                            });
                        }}
                    />
                </div>
                <div className={'c-flow-editor-checkbox-field-properties-footer-button-container'}>
                    <NdsButton
                        size={SizesEnums.SMALL}
                        label={'Remove Checkbox field'}
                        buttonType={NdsButtonTypesEnum.SECONDARY}
                        buttonStyle={NdsButtonStylesEnum.ALT}
                        leftIcon='fa-light-trash'
                        onClick={() => {
                            setFlowState(FlowActionType.DeleteElement, {
                                localId: found.localId,
                                type: found.type,
                            });

                            setFocusedEntity(FlowEntityType.Package);
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

FlowEditorCheckboxFieldProperties.Header = () => {
    return (
        <div className={'c-flow-editor-right-pane-component-header'}>
            <div className={'c-flow-editor-right-pane-component-header__icon'}>
                <NdsIconFont fontName={'fa-regular-square-check'} />
            </div>
            <div className={'c-flow-editor-right-pane-component-header__title'}>
                Checkbox
            </div>
            <div className={'c-flow-editor-right-pane-component-header__action'}></div>
        </div>
    );
};
export default FlowEditorCheckboxFieldProperties;
