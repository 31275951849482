import { Modal, ModalContent, ModalFooter, NdsButton } from '@gonitro/rcl';
import { NdsButtonTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import { StatesEnum } from '@gonitro/rcl/lib/_types/designsystem.enums';
import { useCallback, useMemo, useState } from 'react';
import Warning from '~components/warning';
import { FlowContextType } from '~contexts/flow';
import { FlowStep } from '~contexts/flow/flow.types';
import { useTranslation } from '~contexts/i18n';
import { useAsyncEffect } from '~hooks/effects';
import { ActionNeededValueType, FlowActionsDocument } from '~models';
import FlowActionNeededModalTable from './flow-action-needed-modal-table';
import Typography, { TypographyToken } from '../../typography';
import './flowActionNeededModal.scss';

export interface FlowActionNeededModalProps {
    onCloseClick: () => void;
    context: FlowContextType;
}


function FlowActionNeededModal({ onCloseClick, context }: FlowActionNeededModalProps) {
    const { t } = useTranslation('flow');
    const { t: tBase } = useTranslation('base');
    const [documents, setDocuments] = useState<FlowActionsDocument[]>([]);
    const [values, setValues] = useState<ActionNeededValueType[]>([]);
    const [loading, setLoading] = useState(false);

    const onConfirmClick = useCallback(async () => {
        setLoading(true);
        try {
            await context.api.saveDocumentActions(values);
            onCloseClick();
            context.navigateToStep(FlowStep.Detail, { replace: true });
        } catch (e) {
            setLoading(false);
            throw e;
        }
    }, [context, onCloseClick, values]);

    useAsyncEffect(() => async (signal: AbortSignal) => {
        try {
            const result = await context.api.getDocumentActions(signal);

            setDocuments(result.documents);
            setValues(result.documents.map((doc) => ({
                documentId: doc.id,
                keepSignatures: doc.hasSignatures ? false : null,
                keepFormFields: doc.hasFormFields ? true : null,
                keepSigningFields: doc.hasSigningFields ? true : null,
            })));
        } catch (e) {
            if (signal.aborted) {
                return;
            }
            throw e;
        }
    }, [context.api]);

    const hasAnySignatures = useMemo(() => (
        documents.some(doc => doc.hasSignatures)
    ), [documents]);

    return (
        <Modal
            size={SizesEnums.LARGE}
            withClose={!loading}
            className={'c-flow-action-needed-modal'}
            headerLabel={t('need-action-modal-title')}
            onCloseClick={onCloseClick}
            renderContent={
                <ModalContent className={'c-flow-action-needed-modal__content'}>
                    <Typography
                        className={'c-flow-action-needed-modal__description'}
                        token={TypographyToken.DesktopDescriptionLg}
                        text={t('need-action-modal-description')}
                        tagName={'div'}
                    />
                    {hasAnySignatures && (
                        <Warning
                            className={'c-flow-action-needed-modal__warning'}
                            text={t('need-action-modal-warning')}
                        />
                    )}
                    <FlowActionNeededModalTable documents={documents} values={values} setValues={setValues} />
                </ModalContent>
            }

            renderFooter={
                <ModalFooter>
                    <NdsButton
                        size={SizesEnums.SMALL}
                        label={tBase('cancel')}
                        buttonType={NdsButtonTypesEnum.NEUTRAL}
                        onClick={onCloseClick}
                        state={loading ? StatesEnum.DISABLED : StatesEnum.DEFAULT}
                    />
                    <NdsButton
                        state={loading ? StatesEnum.DISABLED : StatesEnum.DEFAULT}
                        size={SizesEnums.SMALL}
                        label={tBase('upload')}
                        onClick={onConfirmClick}
                    />
                </ModalFooter>
            }
        />
    );
}

export default FlowActionNeededModal;
