import { throttle } from 'lodash';
import { RefObject } from 'react';
import { useAbortEffect } from '~hooks/effects';
import { Offset } from '~models';

export function useScrollTracker<T extends HTMLElement = HTMLDivElement>(
    ref: RefObject<T>,
    onScroll: (offset: Offset) => void,
    debounceTime: number = 200,
) {
    useAbortEffect((signal) => {
        const callback = throttle(onScroll, debounceTime, { leading: false });

        if (ref.current) {
            onScroll({
                top: ref.current.scrollTop,
                left: ref.current.scrollLeft,
            });
        }
        window.addEventListener('resize', (event: Event) => {
            if (!ref.current) {
                return;
            }
            const target = ref.current as T;

            callback({
                top: target.scrollTop,
                left: target.scrollLeft,
            });
        }, { signal });
        ref.current?.addEventListener('scroll', (event: Event) => {
            const target = event.currentTarget as T;

            callback({
                top: target.scrollTop,
                left: target.scrollLeft,
            });
        }, { signal });
    }, [debounceTime, onScroll, ref]);
}
