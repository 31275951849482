import { LanguageDataLabel } from '~models';

export enum ImportContactProperties {
    Title = 'title',
    FirstName = 'firstName',
    LastName = 'lastName',
    BirthDate = 'birthDate',
    EmailAddress = 'emailAddress',
    PhoneNumber = 'phoneNumber',
    Language = 'language',
    ExternalReference = 'externalReference',
    BeLawyer = 'beLawyer',
    EID = 'eID',
    Owner = 'owner',
    IsShared = 'isShared',
}

export interface ImportColumnProperties {
    keyTranslation: LanguageDataLabel<'contacts'>;
    key: string;
    example: string;
}

export const ImportContactFields: Record<ImportContactProperties, ImportColumnProperties> = {
    [ImportContactProperties.EmailAddress]: {
        keyTranslation: 'contact-field-email-address',
        key: 'emailAddress',
        example: 'email@example.com',
    },
    [ImportContactProperties.LastName]: {
        keyTranslation: 'contact-field-last-name',
        key: 'lastName',
        example: 'Doe',
    },
    [ImportContactProperties.FirstName]: {
        keyTranslation: 'contact-field-first-name',
        key: 'firstName',
        example: 'John',
    },
    [ImportContactProperties.Title]: {
        keyTranslation: 'contact-field-title',
        key: 'title',
        example: 'Mr.',
    },

    [ImportContactProperties.BirthDate]: {
        keyTranslation: 'contact-field-birthDate',
        key: 'birthDate',
        example: 'YYYY-MM-DD',
    },
    [ImportContactProperties.PhoneNumber]: {
        keyTranslation: 'contact-field-phone-number',
        key: 'phoneNumber',
        example: '+37261872631',
    },
    [ImportContactProperties.Language]: {
        keyTranslation: 'contact-field-language',
        key: 'language',
        example: 'en',
    },
    [ImportContactProperties.ExternalReference]: {
        keyTranslation: 'external-reference-label',
        key: 'externalReference',
        example: 'refernce-1',
    },
    [ImportContactProperties.BeLawyer]: {
        keyTranslation: 'contact-field-beLawyer',
        key: 'beLawyer',
        example: 'a123b45c-d67e-34re-ggh2-i134567j891k',
    },
    [ImportContactProperties.EID]: {
        keyTranslation: 'contact-field-eID',
        key: 'eID',
        example: '23020100000',
    },
    [ImportContactProperties.Owner]: {
        keyTranslation: 'contact-field-owner',
        key: 'owner',
        example: 'owner-email@example.com',
    },
    [ImportContactProperties.IsShared]: {
        keyTranslation: 'contact-field-shared',
        key: 'isShared',
        example: 'false',
    },
};
