import { Checkbox, NdsIconFont } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { Button } from '@progress/kendo-react-buttons';
import { SvgIcon } from '@progress/kendo-react-common';
import { ComboBox, ListItemProps } from '@progress/kendo-react-dropdowns';
import { InputPrefix } from '@progress/kendo-react-inputs';
import { searchIcon } from '@progress/kendo-svg-icons';
import classNames from 'classnames';
import { MouseEvent, useCallback, useRef, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { ContactsApi } from '~api/contacts.api';
import ContactForm from '~components/contact-form';
import { FormValues } from '~components/contact-form/contactForm';
import Typography, { TypographyToken } from '~components/typography';
import { ActorType } from '~constants';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { UseFormRegisterCheckbox } from '~hooks/enhanced-form/register-checkbox';
import { useVirtualizedCombobox } from '~hooks/virtualizedCombobox';
import { ContactModel } from '~models/contacts.models';
import { PageableListParams, PageableListResult } from '~models/pageable-list.models';
import { SubstituteTabContactsListItem } from '../out-of-office-tab';
import './substitute-panel.scss';

export interface SubstitutePanelProps {
    registerCheckbox: UseFormRegisterCheckbox<FieldValues>;
    isOutOfOfficeEnabled: boolean | undefined;
    contactListOnChange: (contact: SubstituteTabContactsListItem | null) => void;
    extractFormValues: () => {
        formfiller: boolean;
        approver: boolean;
        signer: boolean;
        receiver: boolean;
    };
    selectedContact: SubstituteTabContactsListItem | null;
    checkIfRoleAlreadyTaken: () => string[],
    handleRemove: (event: MouseEvent<HTMLButtonElement>) => void,
    handleAddSub: (event: MouseEvent<HTMLButtonElement>) => void,
    handleCancel: (event: MouseEvent<HTMLButtonElement>) => void,
    fetchData: (data: PageableListParams, signal?: AbortSignal) => Promise<PageableListResult<SubstituteTabContactsListItem>>,
    setSelectedContact: (contact: SubstituteTabContactsListItem | null) => void;
}

const PAGE_SIZE = 6;

const SubstitutePanel = ({
    registerCheckbox,
    isOutOfOfficeEnabled,
    contactListOnChange,
    selectedContact,
    setSelectedContact,
    extractFormValues,
    checkIfRoleAlreadyTaken,
    handleRemove,
    handleAddSub,
    handleCancel,
    fetchData,
}: SubstitutePanelProps) => {

    const [addContact, setAddContact] = useState<boolean>(false);
    const [contactToEdit, setContactToEdit] = useState<ContactModel>();

    const listRef = useRef<{ reset: () => void; }>(null);


    const { t } = useTranslation('user-management');
    const contactsApi = useApi(ContactsApi);


    const RenderItem = (itemProps: ListItemProps) => (

        <div className={'substitute-panel__contact-item'}>
            <NdsIconFont className='substitute-panel__contact-icon' fontName='fa-solid-user' />
            <div>
                <div className='substitute-panel__contact-item-title'>
                    <Typography
                        className='substitute-panel__contact-item-name'
                        token={TypographyToken.UiMenuMd}
                        text={`${itemProps.dataItem.fullName}`}
                    ></Typography>
                </div>
                <div className={'substitute-panel__contact-item-subtitle'}>
                    <Typography
                        token={TypographyToken.DesktopBylineXs}
                        text={itemProps.dataItem.emailAddress}
                    ></Typography>
                </div>
            </div>
        </div>
    );


    const comboboxProps = useVirtualizedCombobox<SubstituteTabContactsListItem>({
        pageSize: PAGE_SIZE,
        apiFilterKey: 'searchString',
        fetchData,
        valueKey: 'contactId',
        labelKey: 'fullName',
        onChange: contactListOnChange,
        value: selectedContact,
        RenderItem,
        ref: listRef,
    });

    const openContact = useCallback(() => {
        setAddContact(true);
    }, []);

    const closeContact = useCallback((contactData: FormValues | undefined) => {
        setAddContact(false);
        setContactToEdit(undefined);

        if (contactData) {
            setSelectedContact({
                fullName: `${contactData.firstName} ${contactData.lastName}`,
                contactId: contactData.contactId,
                emailAddress: contactData.emailAddress,
            });
            listRef?.current?.reset();
        }
    }, [setSelectedContact]);

    const handleOnEditClick = useCallback(async (contactId: string) => {
        const contact = await contactsApi.getContact({ contactId });

        setContactToEdit(contact);
        setAddContact(true);

    }, [contactsApi]);


    return (
        <div className={classNames('substitute-panel__configure-substitutes-section', 'substitute-panel__section-generic')}>
            {selectedContact ?
                <div className='substitute-panel__selected-contact'>
                    <div className={'substitute-panel__avatar'}>
                        <Typography token={TypographyToken.UiButtonsMd}>{selectedContact?.fullName[0]}</Typography>
                    </div>
                    <Typography className='substitute-panel__contact-name' token={TypographyToken.UiFormsLabelSm}>
                        {selectedContact.fullName}
                    </Typography>

                    <Typography className='substitute-panel__contact-email' token={TypographyToken.UiFormsLabelSm}>
                        {selectedContact.emailAddress}
                    </Typography>
                    <NdsIconFont
                        onClick={() => handleOnEditClick(selectedContact.contactId as string)}
                        className='substitute-panel__edit-contact-btn'
                        size={SizesEnums.SMALL}
                        fontName='fa-solid-edit' />
                    <NdsIconFont
                        className='substitute-panel__edit-contact-btn'
                        onClick={() => setSelectedContact(null)}
                        size={SizesEnums.SMALL}
                        fontName='fa-solid-minus-circle' />
                </div> :
                <>
                    <div className='substitute-panel__select-substitute'>
                        <Typography
                            token={TypographyToken.DesktopDescriptionSm}
                            text={t('out-of-office-add-substitute')}
                            tagName={'label'}
                            className={'substitute-panel__add-substitute-label'}
                        />
                        <ComboBox
                            suggest
                            {...comboboxProps}
                            prefix={() => (
                                <InputPrefix orientation='horizontal'>
                                    <SvgIcon
                                        icon={searchIcon}
                                    />
                                </InputPrefix>

                            )}
                        />
                    </div>
                </>}
            {!selectedContact && <div className='substitute-panel__contact-btn-group'>
                <Button
                    type='button'
                    themeColor={'secondary'}
                    onClick={openContact}
                >{t('out-of-office-add-contacts')} </Button>
            </div>}
            <div className={classNames('substitute-panel__assign-roles', 'substitute-panel__section-generic')}>
                <Typography
                    token={TypographyToken.DesktopDescriptionSm}
                    text={t('out-of-office-assign-roles')}
                    tagName={'header'}
                    className={'substitute-panel__assign-roles-sub-header'}
                />
                <div className='substitute-panel__assign-roles-checkbox-container'>
                    <div><Checkbox
                        {...registerCheckbox('formfiller')}
                        disabled={!isOutOfOfficeEnabled || ((checkIfRoleAlreadyTaken().includes(ActorType.FormFiller)))}
                        label={t('out-of-office-form-filler')} /></div>
                    <div><Checkbox
                        {...registerCheckbox('approver')}
                        disabled={!isOutOfOfficeEnabled || ((checkIfRoleAlreadyTaken().includes(ActorType.Approver)))}
                        label={t('out-of-office-approver')} /></div>
                    <div><Checkbox
                        {...registerCheckbox('signer')}
                        disabled={!isOutOfOfficeEnabled || ((checkIfRoleAlreadyTaken().includes(ActorType.Signer)))}
                        label={t('out-of-office-signer')} /></div>
                    <div><Checkbox
                        {...registerCheckbox('receiver')}
                        disabled={!isOutOfOfficeEnabled || ((checkIfRoleAlreadyTaken().includes(ActorType.Receiver)))}
                        label={t('out-of-office-receiver')} /></div>
                    {(checkIfRoleAlreadyTaken().includes(ActorType.FormFiller) ||
                        checkIfRoleAlreadyTaken().includes(ActorType.Approver) ||
                        checkIfRoleAlreadyTaken().includes(ActorType.Signer) ||
                        checkIfRoleAlreadyTaken().includes(ActorType.Receiver))
                        && (
                            <div className='substitute-panel__roles-assigned-error-container'>
                                <Typography className='substitute-panel__roles-assigned-error' token={TypographyToken.UiFormsLabelXs}>
                                    {t('out-of-office-roles-assigned-error')}
                                </Typography>
                            </div>
                        )
                    }
                </div>

            </div>
            <div className={classNames('substitute-panel__allow-reassign', 'substitute-panel__section-generic')}>
                <Checkbox
                    {...registerCheckbox('reassign')}
                    name='reassign'
                    label={t('out-of-office-allow-reassign')}
                    disabled={!isOutOfOfficeEnabled}
                />
            </div>
            <div className='substitute-panel__action-button-container'>
                <Button type='button' onClick={handleRemove} fillMode={'flat'}>
                    {t('out-of-office-remove-button')}
                </Button>
                <div>
                    <Button
                        type='button'
                        onClick={(event) => handleCancel(event)}
                        fillMode={'flat'}>
                        {t('out-of-office-substitute-cancel')}
                    </Button>
                    <Button
                        type='button'
                        disabled={
                            !selectedContact ||
                            !(extractFormValues().formfiller ||
                                extractFormValues().approver ||
                                extractFormValues().signer ||
                                extractFormValues().receiver)}
                        onClick={(event) => handleAddSub(event)}
                        fillMode={'outline'}>
                        {t('out-of-office-add-button')}
                    </Button>
                </div>
            </div>
            <OverlayPortal type={'panel'} visible={addContact}>
                {() => (
                    <ContactForm
                        open={true}
                        onClose={closeContact}
                        contact={contactToEdit}
                    />
                )}
            </OverlayPortal>
        </div >);

};

export default SubstitutePanel;
