import { NdsButton, NdsIconFont } from '@gonitro/rcl';
import { NdsButtonStylesEnum, NdsButtonTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { DateTime } from 'luxon';
import { useCallback, useMemo, useState } from 'react';
import { ContactsApi } from '~api/contacts.api';
import ContactForm from '~components/contact-form';
import ContactGroupForm from '~components/contact-group-form';
import Typography, { TypographyToken } from '~components/typography';
import { useApi } from '~contexts/api';
import { useFlowContext, useSetFlowState } from '~contexts/flow';
import { FlowActionType, FlowEntityType } from '~contexts/flow/flow.types';
import { useLanguages } from '~contexts/i18n';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { StakeholderGroupModel, StakeholderPersonModel, StakeholderType } from '~models';
import { ContactGroupModel } from '~models/contact-group.model';
import { ContactModel } from '~models/contacts.models';
import './flowEditorContactProperties.scss';

export interface FlowEditorContactPropertiesProps {

}

const PersonContactProperties = (person: StakeholderPersonModel) => {
    const { availableLanguages } = useLanguages();
    const language = availableLanguages.find((x) => x.isoCultureCode === person?.language);
    const dateString = person.birthDate ? DateTime.fromJSDate(new Date(person.birthDate)).toFormat('dd/MM/yyyy') : '';

    return (
        <PanelBar>
            <PanelBarItem
                title={'Contact Properties'}
                expanded
            >
                <FieldWrapper>
                    <Label>
                        {'Email'}
                    </Label>
                    <Input
                        value={person.emailAddress}
                        disabled={true}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Label>
                        {'First name'}
                    </Label>
                    <Input
                        value={person.firstName}
                        disabled={true}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Label>
                        {'Last name'}
                    </Label>
                    <Input
                        value={person.lastName}
                        disabled={true}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Label>
                        {'Personal title'}
                    </Label>
                    <Input
                        value={person.title}
                        disabled={true}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Label>
                        {'Date of Birth'}
                    </Label>
                    <Input
                        value={dateString}
                        disabled={true}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Label>
                        {'Phone number for SMS OTP'}
                    </Label>
                    <Input
                        value={person.phoneNumber}
                        disabled={true}
                    />
                </FieldWrapper>
                <FieldWrapper>
                    <Label>
                        {'Preferred Language'}
                    </Label>
                    <Input
                        value={language?.nativeName}
                        disabled={true}
                    />
                </FieldWrapper>
            </PanelBarItem>
        </PanelBar>
    );
};

const GroupContactProperties = (group: StakeholderGroupModel) => {
    return (
        <PanelBar>
            <PanelBarItem
                title={'Members'}
                expanded
            >
                <div className={'c-flow-editor-contact-properties-members-container'} >
                    {group.members && group.members.map((member, index) => {
                        return (
                            <div key={index } className={'c-flow-editor-contact-properties-members-container_member'}>
                                <div>
                                    <NdsIconFont fontName={'fa-solid-user'} />
                                    <Typography
                                        token={TypographyToken.MobileDescriptionSm}
                                        text={`${member.firstName ?? ''} ${member.lastName}`}
                                        tagName={'label'}
                                    />
                                </div>
                            </div>
                        );
                    })}
                    {(group.members == null || (group.members && group.members.length === 0)) &&
                        <Typography
                            token={TypographyToken.MobileDescriptionSm}
                            text={'No members yet in this group'}
                            tagName={'label'}
                        />
                    }
                </div>
            </PanelBarItem>
        </PanelBar>
    );
};

function FlowEditorContactProperties(props: FlowEditorContactPropertiesProps) {
    const { focusedEntityId, focusedEntityData, setFocusedEntity, flowState: { stakeholders } } = useFlowContext();
    const found = stakeholders.find(el => el.localId === focusedEntityId);
    const selectedActor = focusedEntityData?.selectedActor;
    const setFlowState = useSetFlowState();

    return (
        <div className={'c-flow-editor-contact-properties c-flow-editor-right-pane-component'}>
            {found?.type === StakeholderType.Person && (
                PersonContactProperties(found)
            )}
            {found?.type === StakeholderType.Group && (
                GroupContactProperties(found)
            )}
            <div className={'c-flow-editor-contact-properties-footer'}>
                {selectedActor && <div className={'c-flow-editor-contact-properties-footer-button-container'}>
                    <NdsButton
                        size={SizesEnums.SMALL}
                        label={'Remove recipient'}
                        buttonType={NdsButtonTypesEnum.SECONDARY}
                        buttonStyle={NdsButtonStylesEnum.ALT}
                        leftIcon='fa-light-trash'
                        onClick={() => {
                            setFlowState(FlowActionType.DeleteActor, {
                                actorId: selectedActor,
                                deleteElementsFromViewer: true,
                            });
                            setFocusedEntity(FlowEntityType.Package);
                        }}
                    />
                </div>}
            </div>
        </div>
    );
}

const Header = () => {
    const contactsApi = useApi(ContactsApi);
    const { focusedEntityId, flowState: { stakeholders } } = useFlowContext();
    const found = stakeholders.find(el => el.localId === focusedEntityId);
    const setFlowState = useSetFlowState();

    const [openContactEditModal, setOpenContactEditModal] = useState(false);
    const [openContactGroupEditModal, setOpenContactGroupEditModal] = useState(false);
    const [contactToEdit, setContactToEdit] = useState<ContactModel>();
    const [contactGroupToEdit, setContactGroupToEdit] = useState<ContactGroupModel>();

    const canEdit = useMemo(() => {
        if (found?.type === StakeholderType.Person) {
            const contact = found as any;

            if (contact.contactId != null && contact.permission === 'Edit') {
                return true;
            }
        } else if (found?.type === StakeholderType.Group) {
            const contactGroup = found as any;

            if (contactGroup.contactGroupId != null && contactGroup.permission === 'Edit') {
                return true;
            }
        }

        return false;
    }, [found]);

    const refreshStakeholder = useCallback(async () => {
        if (found?.type === StakeholderType.Person) {
            const contact = found as any;

            if (contact.contactId != null) {
                const c = await contactsApi.getContact({ contactId: contact.contactId });

                setFlowState(FlowActionType.UpdateStakeholder, {
                    ...found,
                    emailAddress: c.emailAddress,
                    title: c.title,
                    firstName: c.firstName,
                    lastName: c.lastName,
                    language: c.language ?? '',
                    phoneNumber: c.phoneNumber,
                    birthDate: c.birthDate ?? '',
                    contactId: Number(c.contactId),
                    isShared: c.isShared,
                });
            }
        } else if (found?.type === StakeholderType.Group) {
            const contactGroup = found as any;

            if (contactGroup.contactGroupId != null) {
                const c = await contactsApi.getContactGroup({
                    id: contactGroup.contactGroupId,
                    anonymiseData: false,
                });

                setFlowState(FlowActionType.UpdateStakeholder, {
                    ...found,
                    name: c.name,
                    members: c.contacts?.map((contact) => {
                        return {
                            contactId: Number(contact.contactId),
                            isShared: contact.isShared,
                            title: contact.title,
                            emailAddress: contact.emailAddress,
                            firstName: contact.firstName,
                            lastName: contact.lastName,
                            language: contact.language ?? '',
                            phoneNumber: contact.phoneNumber,
                            birthDate: contact.birthDate ?? '',
                            properties: contact.properties ?? [],
                        };
                    }) ?? [],
                    isShared: c.isShared,
                });
            }
        }
    }, [contactsApi, found, setFlowState]);

    return (
        <div className={'c-flow-editor-right-pane-component-header'}>
            <div className={'c-flow-editor-right-pane-component-header__icon'}>
                {found?.type === StakeholderType.Person && (
                    <NdsIconFont fontName={'fa-solid-user'} />
                )}
                {found?.type === StakeholderType.Group && (
                    <NdsIconFont fontName={'fa-regular-users'} />
                )}
            </div>
            <div className={'c-flow-editor-right-pane-component-header__title'}>
                {found?.type === StakeholderType.Person && `${found.firstName ?? ''} ${found.lastName}`}
                {found?.type === StakeholderType.Group && found.name}
            </div>
            <div className={'c-flow-editor-right-pane-component-header__action'}>
                {canEdit &&
                    <NdsIconFont
                        fontName={'fa-solid-edit'}
                        onClick={async () => {
                            if (found?.type === StakeholderType.Person) {
                                const contact = found as any;

                                if (contact.contactId != null) {
                                    const c = await contactsApi.getContact({ contactId: contact.contactId });

                                    setOpenContactEditModal(true);
                                    setContactToEdit(c);
                                }
                            } else if (found?.type === StakeholderType.Group) {
                                const contactGroup = found as any;

                                if (contactGroup.contactGroupId != null) {
                                    const c = await contactsApi.getContactGroup({
                                        id: contactGroup.contactGroupId,
                                        anonymiseData: false,
                                    });

                                    setOpenContactGroupEditModal(true);
                                    setContactGroupToEdit(c);
                                }
                            }
                        }}
                    />
                }
                {
                    <OverlayPortal type={'panel'} visible={openContactEditModal}>
                        {() => (
                            <ContactForm
                                open={openContactEditModal}
                                onClose={async (e)=> {
                                    await refreshStakeholder();

                                    setOpenContactEditModal(false);
                                    setContactToEdit(undefined);
                                }}
                                contact={contactToEdit}
                            />
                        )}
                    </OverlayPortal>
                }
                {found?.type === StakeholderType.Group &&
                    <OverlayPortal type={'panel'} visible={openContactGroupEditModal}>
                        {() => (
                            <ContactGroupForm
                                open={openContactGroupEditModal}
                                onClose={async () => {
                                    await refreshStakeholder();

                                    setOpenContactGroupEditModal(false);
                                    setContactGroupToEdit(undefined);
                                }}
                                contactGroup={contactGroupToEdit}
                            />
                        )}
                    </OverlayPortal>
                }
            </div>
        </div>
    );
};


FlowEditorContactProperties.Header = Header;
export default FlowEditorContactProperties;
