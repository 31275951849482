import { SortDirectionEnum } from '@gonitro/rcl/lib/_types';
import {
    TableColumnSpecificWidth,
    TableDefinition,
    TableFilterValueSubType,
    TableFilterValueType,
} from '~contexts/table/table.types';

export const UsersTableDefinition: TableDefinition<'email'> = {
    rowIdKey: 'email', // userId is null for invited users so it's not unique. Email is probably unique
    itemsPerPage: 20,
    itemsPerPageOptions: [20, 50, 100],
    initialSorting: {
        key: 'registrationDate',
        direction: SortDirectionEnum.DESC,
    },
    columns: [
        {
            key: 'name',
            i18nLabel: {
                ns: 'user-management',
                key: 'user-management-portal-table-col-title-name',
            },
            width: TableColumnSpecificWidth.Auto,
            useEllipsis: true,
            sortable: true,
        },
        {
            key: 'email',
            dataKey: 'email',
            i18nLabel: {
                ns: 'user-management',
                key: 'user-management-portal-table-col-title-email',
            },
            width: TableColumnSpecificWidth.Auto,
            useEllipsis: true,
            sortable: true,
        },
        {
            key: 'registrationDate',
            i18nLabel: {
                ns: 'user-management',
                key: 'user-management-portal-table-col-title-registration-date',
            },
            width: TableColumnSpecificWidth.Auto,
            useEllipsis: true,
            sortable: true,
        },
        {
            key: 'status',
            i18nLabel: {
                ns: 'user-management',
                key: 'user-management-portal-table-col-title-status',
            },
            width: TableColumnSpecificWidth.Auto,
            sortable: true,
        },
        {
            key: 'action',
            width: TableColumnSpecificWidth.Button,
        },
    ],
    filters: [
        {
            type: TableFilterValueType.Primitive,
            subType: TableFilterValueSubType.String,
            key: 'status',
        },
        {
            type: TableFilterValueType.Object,
            subType: TableFilterValueSubType.String,
            key: 'dateRange.start',
            queryParamKey: 'afterDate',
            apiKey: 'afterDate',
            apiTransform: (value) => value ? `${value}T00:00:00` : value,
        },
        {
            type: TableFilterValueType.Object,
            subType: TableFilterValueSubType.String,
            key: 'dateRange.end',
            queryParamKey: 'beforeDate',
            apiKey: 'beforeDate',
            apiTransform: (value) => value ? `${value}T23:59:59` : value,
        },
        {
            type: TableFilterValueType.Primitive,
            subType: TableFilterValueSubType.String,
            key: 'name',
        },
    ],
};