import { ReadSettingsModels, ReadSigningTypesModels } from '~api/settings.models';
import { SigningBehavior } from '~constants/signing-behavior';
import { LocalesResource, SettingsModel } from '~models';
import { KeyPairs } from '~models/key-pair.models';

export interface BasicSigningTypeData {
    /** The behavior name. It's the first part of its `name` */
    readonly behavior: SigningBehavior;

    /** If we can quicksign using this signing type */
    readonly allowsQuickSign: boolean;

    /** Localized set of descriptions */
    readonly descriptions: LocalesResource<string>;

    /** Localized set of display names */
    readonly displayNames: LocalesResource<string>;

    /** The icon to display for this signing type */
    readonly icon?: string;

    /** Can be started in bulk session? */
    readonly isBulkSignAvailable: boolean;

    /** Should we use this signing method? */
    readonly isEnabled: boolean;

    /** Possible certificate key pairs to choose on method */
    readonly keyPairs: KeyPairs;

    // manualCombination: unknown; // not used in portal
    // manualInputTypes: unknown; // not used in portal

    /** signingType's name = behavior:method */
    readonly name: string;

    /** if type has mandated validation enabled this contain required signer properties (phone, beid number, ...) */
    readonly requiredProperties: string[];
}


export class SigningType implements BasicSigningTypeData {

    /** The behavior name. It's the first part of its `name` */
    readonly behavior: SigningBehavior;

    /** If we can quicksign using this signing type */
    readonly allowsQuickSign: boolean;

    /** Localized set of descriptions */
    readonly descriptions: LocalesResource<string>;

    /** Localized set of display names */
    readonly displayNames: LocalesResource<string>;

    /** The icon to display for this signing type */
    readonly icon?: string;

    /** Can be started in bulk session? */
    readonly isBulkSignAvailable: boolean;

    /** Should we use this signing method? */
    readonly isEnabled: boolean;

    /** Possible certificate key pairs to choose on method */
    readonly keyPairs: KeyPairs;

    /** signingType's name = behavior:method */
    readonly name: string;

    /** if type has mandated validation enabled this contain required signer properties (phone, beid number, ...) */
    readonly requiredProperties: string[];

    readonly infoFromSettings: any;

    constructor(data: BasicSigningTypeData, extraInfo?: any) {
        this.behavior = data.behavior;
        this.descriptions = data.descriptions;
        this.displayNames = data.displayNames;
        this.icon = data.icon;
        this.isBulkSignAvailable = data.isBulkSignAvailable;
        this.isEnabled = data.isEnabled;
        this.keyPairs = data.keyPairs;
        this.descriptions = data.descriptions;
        this.name = data.name;
        this.requiredProperties = data.requiredProperties;
        this.allowsQuickSign = data.allowsQuickSign;

        this.infoFromSettings = extraInfo;
    }

    static signingTypesMapper(basicData: ReadSigningTypesModels.Result, settings: ReadSettingsModels.Result) {
        return basicData.map((data) => {
            return new SigningType({
                behavior: data.behaviour.key,
                descriptions: data.descriptions,
                displayNames: data.displayNames,
                icon: data.icon,
                isBulkSignAvailable: data.isBulkSignAvailable,
                isEnabled: data.isEnabled,
                keyPairs: data.keyPairs,
                name: data.name,
                requiredProperties: data.requiredProperties,
                allowsQuickSign: data.behaviour.allowsQuickSign,
            }, this.getInfoFromSettings(data.behaviour.key, data.name, settings.signingOptionsSettings));
        });
    }

    private static getInfoFromSettings(behavior: SigningBehavior, name: string, settings: SettingsModel.SigningOptionsSettings) {
        switch (behavior) {
            case SigningBehavior.Manual:
                return settings.manualSigningOptions;
                // TODO rest of find behavior and assign
        }

        return null;
    }
}

export type SigningTypes = SigningType[];