import { PropsWithChildren } from 'react';
import { useTranslation } from '~contexts/i18n';
import { FlowFieldModel } from '~models';
import Typography, { TypographyToken } from '../../../../typography';
import './flowEditorSigningFieldObject.scss';

export interface FlowEditorSigningFieldObjectProps extends PropsWithChildren {
    data: FlowFieldModel,
}

function FlowEditorSigningFieldObject({ children }: FlowEditorSigningFieldObjectProps) {
    const { t } = useTranslation('flow');

    return (
        <div className={'c-flow-editor-signing-field-object c-flow-editor-field-object'}>
            {/* TODO left till discussed about fields design */}
            {/*<div className={'c-flow-editor-field-object__grip'}>*/}
            {/*    <i className='fa-solid fa-grip-lines-vertical'></i>*/}
            {/*</div>*/}
            <div className={'c-flow-editor-signing-field-object__content'}>
                <Typography
                    className={'c-flow-editor-field-object__text'}
                    token={TypographyToken.UiFormsLabelSm}
                    text={t('field-object-label-signature')}
                />
            </div>
            {children}
        </div>
    );
}

export default FlowEditorSigningFieldObject;
