import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { changeLanguage, i18n } from 'i18next';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import Loader from '~components/loader';
import { SetLanguageFunction } from '~contexts/i18n/i18n.types';
import { useSettings } from '~contexts/settings';
import { useAsyncEffect } from '~hooks/effects';
import { configureI18next } from '~lib/i18next/i18next';
import { Language, LanguageProfile, LanguageProfiles } from '~models';
import { I18nContext, I18nContextType } from './i18n.context';

export interface I18nContextProviderProps {
    children: ReactNode;
    i18next: i18n;
}

const runtimeNamespace = 'runtime-' + Date.now();

export function I18nContextProvider({ children, i18next }: I18nContextProviderProps) {
    const { localizationSettings } = useSettings();
    const [language, setLanguage] = useState<LanguageProfile>({} as LanguageProfile);

    const availableLanguages = useMemo<LanguageProfiles>(() => (
        localizationSettings?.languageOptions.languageProfiles
            .filter(lang => lang.enabled)
            .sort((a, b) => a.nativeName < b.nativeName ? -1 : 1)
    ), [localizationSettings]);
    const masterLanguage = useMemo<Language>(() => (
        localizationSettings?.languageOptions.masterLanguage
    ), [localizationSettings]);

    const isLangEnabledCallback = useCallback((lang: Language | LanguageProfile) => {
        const lookupLang = typeof lang === 'string' ? lang : lang.isoCultureCode;

        return availableLanguages.find((profile) => profile.isoCultureCode === lookupLang) !== null;
    }, [availableLanguages]);

    useAsyncEffect(() => async () => {
        if (!availableLanguages || i18next.isInitialized) {
            return;
        }
        await configureI18next(
            i18next, availableLanguages.map(lang => lang.isoCultureCode), masterLanguage,
        );
        const newLang = availableLanguages.find(lang => lang.isoCultureCode === i18next.resolvedLanguage)!;

        setLanguage(newLang);
    }, [
        masterLanguage,
        localizationSettings,
        availableLanguages,
        i18next.isInitialized,
        i18next.resolvedLanguage,
        i18next,
    ]);

    const setLanguageCallback = useCallback<SetLanguageFunction>(async (newLang: Language | LanguageProfile, useDefaultIfNotAvailable: boolean = false) => {
        const newLangCode = typeof newLang === 'string' ? newLang : newLang.isoCultureCode;
        let newLangProfile = availableLanguages.find((profile) => profile.isoCultureCode === newLangCode);

        if (!newLangProfile) {
            if (useDefaultIfNotAvailable) {
                newLangProfile = availableLanguages.find((lang: LanguageProfile) => lang.isMasterLanguage)!; // master language is fallback language
            } else {
                throw new Error('Language');
            }
        }
        await changeLanguage(newLangProfile.isoCultureCode);
        setLanguage(newLangProfile);

        return newLangProfile;
    }, [availableLanguages]);

    const registerLocaleCallback = useCallback((key: string, translations: Record<Language, string>) => {
        for (const [lang, label] of Object.entries(translations)) {
            if (!isLangEnabledCallback(lang)) {
                continue; // ignore disabled languages
            }
            i18next.addResource(
                lang, runtimeNamespace, key, label, { keySeparator: 'NO-KEY-SEPARATOR' },
            );
        }

    }, [i18next, isLangEnabledCallback]);

    const contextValue = useMemo<I18nContextType>(() => ({
        i18next,
        language,
        availableLanguages,
        isLanguageAvailable: isLangEnabledCallback,
        setLanguage: setLanguageCallback,
        registerLocale: registerLocaleCallback,
    }), [
        availableLanguages,
        i18next,
        isLangEnabledCallback,
        language,
        registerLocaleCallback,
        setLanguageCallback,
    ]);

    if (!i18next.isInitialized || !language) {
        return <Loader size={SizesEnums.XLARGE} center />;
    }

    return (
        <I18nContext.Provider value={contextValue}>
            {children}
        </I18nContext.Provider>
    );
}
