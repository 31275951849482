import { StringUtils } from '~lib';

export namespace ObjectUtils {
    export function camelCaseProperties<T>(data: any, removeNullish = false): T {
        if (data instanceof Array) {
            return data.map((v) => camelCaseProperties(v)) as T;
        } else if (data instanceof Object) {
            const camelCasedObject: Record<string, any> = {};

            for (const [key, value] of Object.entries(data)) {
                const parsed = camelCaseProperties(value);

                if (parsed !== null && parsed !== undefined) {
                    camelCasedObject[StringUtils.unCapitalizeFirstLetter(key)] = parsed;
                }
            }

            return camelCasedObject as T;
        }

        return data as T;
    }

    export function camelCaseValue(value: string) {
        return value[0].toLowerCase() + value.substring(1);
    }

    export function compareValue(a: any, b: any): boolean {
        if (typeof a !== typeof b) {
            return false;
        }

        if (typeof a === 'object') {
            // checking type of 'a' is enough as would be same as type of 'b'
            if (Array.isArray(a) || Array.isArray(b)) {
                return ObjectUtils.compareArray(a, b);
            } else {
                // if any of a or b is array so both are objects
                if (Object.keys(a).length !== Object.keys(b).length) {
                    return false;
                }

                return Object.keys(a).reduce((acc, next) => acc && ObjectUtils.compareValue(a[next], b[next]), true);
            }
        }

        return a === b;
    }

    export function compareArray(a: any, b: any): boolean {
        if (!Array.isArray(a) || !Array.isArray(b)) {
            return false;
        }
        if (a.length !== b.length) {
            return false;
        }

        return a.reduce((acc, cur, curIndex) => acc && ObjectUtils.compareValue(cur, b[curIndex]), true);
    }
}
