import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import './overlay.scss';

export interface OverlayProps extends HTMLAttributes<HTMLDivElement> {
    visible?: boolean;
    position?: 'absolute' | 'fixed';
}

function Overlay({ visible = true, position = 'fixed', ...props }: OverlayProps) {
    return (
        <div
            {...props}
            className={classNames(`c-overlay c-overlay--${position}`, { 'c-overlay--visible': visible }, props.className)}
        />

    );
}

export default Overlay;