import { useMemo } from 'react';
import { PortalTableFiltersDefinition } from '~containers/portal-table/portal-table-filters/portal-table-filters';
import { useTranslation } from '~contexts/i18n';


export const useDocumentGroupsTableFilters = () => {
    const { t: tBase } = useTranslation('base');

    return useMemo<PortalTableFiltersDefinition>(() => {
        return {
            searchAll: {
                type: 'single',
                key: 'name',
                placeholder: tBase('search-input-placeholder'),
            },
        };
    }, [tBase]);
};
