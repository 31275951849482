import { NdsIconFont } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { filterBy, groupBy } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { ComboBox, ComboBoxFilterChangeEvent, ListItemProps } from '@progress/kendo-react-dropdowns';
import { FieldRenderProps } from '@progress/kendo-react-form';
import { InputSuffix } from '@progress/kendo-react-inputs';
import { searchIcon } from '@progress/kendo-svg-icons';
import { cloneElement, ReactElement, useCallback, useEffect, useState } from 'react';
import Typography, { TypographyToken } from '~components/typography';
import { useTranslation } from '~contexts/i18n';
import './reassignCombobox.scss';

interface CustomEvent {
    value: {
        id: string;
    };
}

function ReassignCombobox(fieldRenderProps: FieldRenderProps) {
    const { t: tBase } = useTranslation('base');
    const {
        placeholder,
        groupByField,
        label,
        name,
        data,
        additionalInfo,
        groupedCombobox,
        textField,
        required,
        onChange,
        onFocus,
        onBlur,
    } = fieldRenderProps;
    const [state, setState] = useState({ groupedData: groupBy(data, [{ field: groupByField }]).flatMap((group: any) => group.items) });
    const [typedValue, setTypedValue] = useState('');

    useEffect(() => {
        const groupedData = groupBy(data, [{ field: groupByField }]).flatMap((group: any) => group.items);

        setState({ groupedData: groupedData });
    }, [data, groupByField]);

    const itemRender = (li: ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
        const item = (
            <>
                {additionalInfo
                    ? (
                        <div className='c-reassign-combobox__addressbook-item'>
                            {itemProps.dataItem.isShared ? <NdsIconFont fontName='fa-light-user' /> : <NdsIconFont fontName='fa-solid-user' />}
                            <Typography
                                token={TypographyToken.DesktopBylineXs}
                                text={itemProps.dataItem.emailAddress}
                            ></Typography>
                        </div>
                    )
                    : (
                        <div className={'c-reassign-combobox__recipient-item'}>
                            <div className='c-reassign-combobox__recipient-item-title'>
                                <Typography
                                    token={TypographyToken.UiMenuMd}
                                    text={`${itemProps.dataItem.firstName} ${itemProps.dataItem.lastName}`}
                                ></Typography>
                            </div>
                            <div className={'c-reassign-combobox__recipient-item-subtitle'}>
                                <Typography
                                    token={TypographyToken.DesktopBylineXs}
                                    text={itemProps.dataItem.emailAddress}
                                ></Typography>
                            </div>
                        </div>
                    )}
            </>
        );

        return cloneElement(li, li.props, item);
    };

    const listNoDataRender = (element: ReactElement<HTMLDivElement>, text: string, additionlInfo?: boolean) => {
        const noData = (
            <div className='not-found'>
                <div className={'not-found__icon-wrapper'}>
                    <div className={'not-found__icon'}>
                        <NdsIconFont fontName={'fa-solid-question'} size={SizesEnums.MEDIUM} />
                    </div>
                </div>
                <Typography
                    token={TypographyToken.DesktopDescriptionMd}
                    text={
                        typedValue && typedValue !== ''
                            ? `${tBase('no-results-search')} "${text}"`
                            : tBase('no-results')
                    }
                    className={'not-found__not-found-message'}
                />
                {additionlInfo && (
                    <>
                        <Typography
                            token={TypographyToken.DesktopBylineXs}
                            text={tBase('no-results-search-additional-info')}
                            className={''}
                        />
                    </>
                )}
            </div>
        );

        return cloneElement(element, { ...element.props }, noData);
    };

    const onValueChange = useCallback(
        (e: CustomEvent) => {
            if (e.value) {
                onChange({ value: e.value.id });
            } else {
                onChange({ value: undefined });
            }
        },
        [onChange],
    );

    const onFilterChangeNewRecipient = (event: ComboBoxFilterChangeEvent) => {
        setTypedValue(event.filter.value);
        onChange({ value: event.filter.value });
        const filteredData = groupBy(filterBy(data, event.filter), [{ field: groupByField }]).flatMap(
            (group: any) => group.items,
        );

        setState({ groupedData: filteredData });
    };

    return (
        <div onFocus={onFocus} onBlur={onBlur}>
            <div>
                <Typography token={TypographyToken.UiFormsLabelSm} text={label} />
                {required && (
                    <Typography
                        type='span'
                        token={TypographyToken.UiFormsLabelSm}
                        text={'*'}
                        className='c-reassign-combobox__required-field'
                    />
                )}
            </div>
            <ComboBox
                data={state.groupedData}
                name={name}
                clearButton={false}
                textField={textField}
                groupField={groupedCombobox ? groupByField : undefined}
                groupMode='modern'
                onChange={onValueChange}
                listNoDataRender={(el) => listNoDataRender(el, typedValue, additionalInfo)}
                placeholder={placeholder}
                onFilterChange={onFilterChangeNewRecipient}
                filterable={true}
                allowCustom={true}
                itemRender={itemRender}
                prefix={() => (
                    <InputSuffix orientation='vertical'>
                        <Button
                            type='button'
                            fillMode={'flat'}
                            rounded={null}
                            svgIcon={searchIcon}
                        />
                    </InputSuffix>
                )}
            />
        </div>
    );
}

export default ReassignCombobox;
