import { NdsButton, NdsGroup, NdsInput, NdsSelect, NdsSelectChangeEvent, NdsSelectOption } from '@gonitro/rcl';
import {
    NdsButtonStylesEnum,
    NdsButtonTypesEnum,
    NdsInputTypes,
    SizesEnums,
    StatesEnum,
} from '@gonitro/rcl/lib/_types';
import { useEffect, useState } from 'react';
import PanelDropdown from '~components/panel-dropdown';
import { useTranslation } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import { ContactCustomField } from '../contactForm';
import './additionalContactProperties.scss';

export interface AdditionalContactPropertiesProps {
    inputFields: ContactCustomField[];
    viewMode?: boolean;
    onAddField: (field: ContactCustomField) => void;
    onInputChange: (index: number, value: string) => void;
    onRemoveField: (index: number) => void;
}

const AdditionalContactProperties: React.FC<AdditionalContactPropertiesProps> = ({
    inputFields,
    viewMode,
    onAddField,
    onInputChange,
    onRemoveField,
}) => {
    const { t } = useTranslation('contacts');
    const [addContactType, setAddContactType] = useState(false);
    const [selectedField, setSelectedField] = useState('');
    const settings = useSettings();
    const contactProps = settings.contactPropertiesSettings.contactProperties;
    const [contactProperties, setContactProperties] = useState(contactProps);

    const handleSelectChange = (event: NdsSelectChangeEvent) => {
        const selected = event.detail.value[0];

        setSelectedField(selected);

        const newField: ContactCustomField = {
            name: selected,
            value: '',
        };

        onAddField(newField);
        setSelectedField('');
        setAddContactType(false);
    };

    useEffect(() => {
        const updatedContactProps = contactProperties.filter(
            (item) => !inputFields.some((element) => element.name === item.name),
        );

        setContactProperties(updatedContactProps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputFields]);

    const onRemoveInputField = (index: number) => {
        const item = contactProps.filter((el) => el.name === inputFields[index].name)[0];

        setContactProperties((prev) => [...prev, item]);
        onRemoveField(index);
    };

    return (
        <div className={'c-aditional-contact-properties'}>
            <PanelDropdown headerText={t('additional-contact-title')}>
                <div className={'c-aditional-contact-properties__wrapper'}>
                    {inputFields.map((field: ContactCustomField, index: number) => (
                        <div key={index} className='c-aditional-contact-properties__add-field'>
                            <div className='c-aditional-contact-properties__full-width'>
                                <NdsInput
                                    label={field.name}
                                    size={SizesEnums.SMALL}
                                    inputType={NdsInputTypes.TEXT}
                                    value={field.value}
                                    state={viewMode? StatesEnum.DISABLED: StatesEnum.DEFAULT}
                                    onNdsChange={(e) => onInputChange(index, e.target.value)}
                                />
                            </div>
                            <div>
                                <NdsButton
                                    size={SizesEnums.SMALL}
                                    buttonType={NdsButtonTypesEnum.NEUTRAL}
                                    buttonStyle={NdsButtonStylesEnum.ALT}
                                    state={viewMode? StatesEnum.DISABLED: StatesEnum.DEFAULT}
                                    leftIcon='fa-regular-circle-minus'
                                    onClick={() => onRemoveInputField(index)}
                                />
                            </div>
                        </div>
                    ))}
                    {addContactType && (
                        <div className='c-aditional-contact-properties__add-field'>
                            <NdsSelect
                                size={SizesEnums.SMALL}
                                value={selectedField}
                                label={t('select-contact-field-label')}
                                content-align='bottom-left'
                                onNdsChange={(e) => handleSelectChange(e)}
                                className='c-aditional-contact-properties__full-width'
                                state={viewMode? StatesEnum.DISABLED: StatesEnum.DEFAULT}
                            >
                                <NdsGroup>
                                    {contactProperties.map((prop) => (
                                        <NdsSelectOption key={prop.name} label={prop.name} value={prop.name} />
                                    ))}
                                </NdsGroup>
                            </NdsSelect>

                            <div className='c-aditional-contact-properties__delete-item-select'>
                                <NdsButton
                                    size={SizesEnums.SMALL}
                                    buttonType={NdsButtonTypesEnum.NEUTRAL}
                                    buttonStyle={NdsButtonStylesEnum.ALT}
                                    state={viewMode? StatesEnum.DISABLED: StatesEnum.DEFAULT}
                                    leftIcon='fa-regular-circle-minus'
                                    onClick={(e) => {
                                        setAddContactType(false);
                                    }}
                                />
                            </div>
                        </div>
                    )}
                    {contactProps.length > inputFields.length && (
                        <NdsButton
                            size={SizesEnums.SMALL}
                            label={t('add-custom-field-button')}
                            buttonType={NdsButtonTypesEnum.SECONDARY}
                            buttonStyle={NdsButtonStylesEnum.ALT}
                            state={viewMode? StatesEnum.DISABLED: StatesEnum.DEFAULT}
                            leftIcon='fa-solid-plus'
                            onClick={() => setAddContactType(true)}
                        />
                    )}
                </div>
            </PanelDropdown>
        </div>
    );
};

export default AdditionalContactProperties;
