import { NdsButton, NdsIconFont } from '@gonitro/rcl';
import { NdsButtonTypesEnum } from '@gonitro/rcl/lib/_types';
import { SizesEnums } from '@gonitro/rcl/lib/_types/designsystem.enums';
import { useEffect } from 'react';
import { useLinkClickHandler } from 'react-router-dom';
import Typography, { TypographyToken } from '~components/typography';
import { useNavWorkspaces } from '~contexts/app';
import { useTranslation } from '~contexts/i18n';
import './notFound.view.scss';

export interface NotFoundProps {
    type?: 'portal' | 'settings';
}

function NotFound({ type = 'portal' }: NotFoundProps) {
    const { t } = useTranslation('base');

    const { myWorkspace } = useNavWorkspaces();

    const onGoToDocumentsClick = useLinkClickHandler(myWorkspace?.documentsPath ?? '/', { replace: true });

    useEffect(() => {
        const historyState = window.history.state?.usr || {};

        if ('issuedWorkspaceId' in historyState) {
            console.warn('User tried to access workspace with id =', historyState.issuedWorkspaceId);
        }
        if ('issuedPermission' in historyState) {
            console.warn('User tried to access page without permission =', historyState.issuedPermission);
        }
    }, []);

    return (
        <div className={'v-not-found'}>
            <div className={'v-not-found__icon-wrapper'}>
                <div className={'v-not-found__icon'}>
                    <NdsIconFont fontName={'fa-solid-question'} size={SizesEnums.MEDIUM} />
                </div>
            </div>
            <div className={'v-not-found__info'}>
                <Typography
                    tagName={'div'}
                    token={TypographyToken.MobileHeaderSm}
                    largeScreenToken={TypographyToken.DesktopHeaderSm}
                >
                    {type === 'settings' ?
                        t('page-not-found-title-settings')
                        : t('page-not-found-title')}
                </Typography>
                <Typography
                    tagName={'div'}
                    token={TypographyToken.MobileDescriptionMd}
                    largeScreenToken={TypographyToken.DesktopDescriptionMd}
                >
                    {type === 'settings' ?
                        t('page-not-found-description-settings')
                        : t('page-not-found-description')}
                </Typography>
            </div>
            <div className={'v-not-found-action'}>
                {myWorkspace?.hasDocumentsPermission
                    ? (
                        <NdsButton
                            label={'Go to My Documents'}
                            buttonType={NdsButtonTypesEnum.SECONDARY}
                            size={SizesEnums.SMALL}
                            onClick={onGoToDocumentsClick}
                        />
                    )
                    : (
                        <Typography
                            tagName={'div'}
                            token={TypographyToken.MobileDescriptionMd}
                            largeScreenToken={TypographyToken.DesktopDescriptionMd}
                        >
                            {t('page-not-found-alternate-action')}
                        </Typography>
                    )}
            </div>
        </div>
    );
}

export default NotFound;
