import { Button } from '@progress/kendo-react-buttons';
import { Skeleton } from '@progress/kendo-react-indicators';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import FlowActionNeededModal from '~components/flow/flow-action-needed-modal';
import FlowEditorWrapper from '~components/flow/flow-editor/flow-editor-wrapper';
import FlowViewWrapper from '~components/flow/flow-view-wrapper';
import Typography, { TypographyToken } from '~components/typography';
import { useAppContext } from '~contexts/app';
import { useFlowContext } from '~contexts/flow';
import { useSaveFlowDetails, useSaveFlowWorkflow } from '~contexts/flow/flow.hooks/save.flow.hooks';
import { useTranslation } from '~contexts/i18n';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import './template-editor.scss';

export default function TemplateEditorView() {
    const navigate = useNavigate();
    const context = useFlowContext();
    const { isActionNeeded, flowState: { templateName } } = context;
    const { lastPortalPath } = useAppContext();
    const { t } = useTranslation('flow');

    const onCloseClick = useCallback(() => {
        navigate(lastPortalPath);
    }, [lastPortalPath, navigate]);

    const saveDetails = useSaveFlowDetails();
    const saveWorkflow = useSaveFlowWorkflow();
    const onConfirmClick = useCallback(async () => {
        await saveDetails();
        await saveWorkflow();
        navigate(lastPortalPath);
    }, [lastPortalPath, navigate, saveDetails, saveWorkflow]);

    return (
        <FlowViewWrapper
            scrollable
            blurred={isActionNeeded}
            className={'v-template-flow'}
            headerCenter={
                isActionNeeded ?
                    <Skeleton shape={'text'} animation={{ type: 'wave' }} style={{ width: '10rem' }} />
                    : <Typography token={TypographyToken.UiButtonsSm} text={templateName} />
            }
            onCloseClick={onCloseClick}
            footer={(
                <div className={'v-template-editor__footer'}>
                    <Button
                        themeColor={'primary'}
                        size={'medium'}
                        onClick={onConfirmClick}
                    >{t('save-template')}</Button>
                </div>
            )}
        >
            <FlowEditorWrapper />
            <OverlayPortal type={'modal'} visible={isActionNeeded}>
                {({ close }) => (
                    <FlowActionNeededModal
                        onCloseClick={() => {
                            close();
                            onCloseClick();
                        }}
                        context={context}
                    />
                )}
            </OverlayPortal>
        </FlowViewWrapper>
    );
}
