import FlowViewWrapper from 'components/flow/flow-view-wrapper';
import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { PackageApi } from '~api/package.api';
import { CreateFlowModel } from '~api/package.models';
import { PortalsApi } from '~api/portals.api';
import FlowDocumentsContent from '~components/flow/flow-documents/flow-documents-content';
import Typography, { TypographyToken } from '~components/typography';
import { DocumentGroupPermission } from '~constants';
import { useApi } from '~contexts/api';
import { useAppContext } from '~contexts/app';
import { useHasDocumentGroupPermissionFunc } from '~contexts/auth';
import { useFlowContext } from '~contexts/flow';
import { FlowError } from '~contexts/flow/flow.error';
import { FlowActionType, FlowStep } from '~contexts/flow/flow.types';
import { useTranslation } from '~contexts/i18n';
import { FlowInfoExistingDocument } from '~models';

export default function PackageDocumentsView() {
    const navigate = useNavigate();
    const portalsApi = useApi(PortalsApi);
    const { lastPortalPath } = useAppContext();
    const { t } = useTranslation('flow');
    const {
        api,
        isNew,
        isProcessing,
        setProcessing,
        setFlowState,
        navigateToStep,
        clearError,
        setError,
        hasError,
        flowState: {
            flowId,
            infoDocuments,
            deletedDocuments,
            packageName,
            workspaceId,
            templateId,
        },
    } = useFlowContext();
    const packageApi = useApi(PackageApi);

    const hasDocGroupPermission = useHasDocumentGroupPermissionFunc();

    useEffect(() => {
        if (infoDocuments.some(doc => doc.error)) {
            setError(FlowError.DocumentProcessingFailure);
        } else {
            clearError(FlowError.DocumentProcessingFailure);
        }
    }, [clearError, infoDocuments, setError]);

    const findPackageName = useCallback<() => string>(() => {
        if (packageName?.length) {
            return packageName;
        }
        if (!infoDocuments.length) {
            throw new Error('No documents uploaded. Cannot set package name');
        }
        if (infoDocuments.length === 1) {
            return infoDocuments[0].documentName;
        }

        return `package-${infoDocuments[0].documentName}`;
    }, [infoDocuments, packageName]);


    const onNextClick = useCallback(async () => {
        setProcessing(true);

        if (isNew) {
            const templateOptions: CreateFlowModel.TemplateOptions | undefined = templateId
                ? {
                    templateId,
                    documents:
                        (infoDocuments.filter(doc => doc.templateFile) as FlowInfoExistingDocument[])
                            .map((doc) => ({
                                documentName: doc.documentName,
                                documentId: doc.id,
                                isOptional: doc.isOptional,
                            })),
                }
                : undefined;
            const { flowId } = await packageApi.create({
                name: findPackageName(),
                workspaceId: workspaceId!,
                templateOptions,
            });

            await api.deleteDocuments(flowId, deletedDocuments);
            const { success, erroredDocsLocalIds } = await api.saveDocuments(flowId, infoDocuments);

            if (success) {
                setFlowState(FlowActionType.SetFlowId, flowId);
                navigateToStep(FlowStep.Processing, { flowId });
            } else {
                // TODO not sure how to make it occur without violating rules and handle it aka can be edge case
                setError(FlowError.DocumentUploadFailure, erroredDocsLocalIds);
                await portalsApi.deletePackage({ packageId: flowId }); // delete package to allow creation process be started again
            }
        } else if (flowId) {
            await api.deleteDocuments(flowId, deletedDocuments);
            await api.saveDocuments(flowId, infoDocuments);
            navigateToStep(FlowStep.Processing);
        } else {
            throw new Error('Flow id not defined');
        }
        setProcessing(false);
    }, [
        api,
        deletedDocuments,
        findPackageName,
        flowId,
        infoDocuments,
        isNew,
        navigateToStep,
        packageApi,
        portalsApi,
        setError,
        setFlowState,
        setProcessing,
        templateId,
        workspaceId,
    ]);

    const onBackClick = useCallback(() => {
        if (isNew) {
            navigate(lastPortalPath);
        } else {
            navigate(-1);
        }
    }, [isNew, lastPortalPath, navigate]);
    const onCloseClick = useCallback(() => {
        navigate(lastPortalPath);
    }, [lastPortalPath, navigate]);

    const nextButtonEnabled =
        !isProcessing
        && infoDocuments.length > 0
        && infoDocuments.every(({ documentName }) => documentName.length)
        && !hasError();
    const useTemplateSelector =
        isNew
        && !!workspaceId
        && hasDocGroupPermission(workspaceId, DocumentGroupPermission.ViewTemplate);

    return (
        <FlowViewWrapper
            scrollable
            headerCenter={
                <Typography
                    token={TypographyToken.DesktopDescriptionBoldMd}
                    text={t(isNew ? 'create-new-package-title' : 'edit-package-title')}
                />
            }
            onCloseClick={onCloseClick}
        >
            <FlowDocumentsContent
                useTemplateSelector={useTemplateSelector}
                nextButtonEnabled={nextButtonEnabled}
                onNextClick={onNextClick}
                onBackClick={onBackClick}
            />
        </FlowViewWrapper>
    );
}
