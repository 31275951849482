import { useCallback } from 'react';
import { ContactBasicModel } from '~models/contact.model';
import { ComboboxContactModel, ContactModel } from '~models/contacts.models';

const useMapContactToComboboxModel = () => {

    const transformModel = useCallback(
        (original: ContactModel | ContactBasicModel): ComboboxContactModel => {
            const baseModel = {
                id: original.contactId.toString(),
                firstName: original.firstName,
                lastName: original.lastName,
                emailAddress: original.emailAddress,
                displayedSelection: `${original.firstName} ${original.lastName}`,
                isShared: original.isShared,
            };

            return baseModel;
        },
        [],
    );

    return { transformModel };
};

export default useMapContactToComboboxModel;