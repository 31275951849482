import { useEffect, useState } from 'react';
import { LanguageDataLabel } from '~models';

export interface DocumentGroupToggle {
    hasPermission: boolean,
    permissionId: number,
    permission: string,
    label: LanguageDataLabel<'user-management'> | any; //TODO remove any when translations done
    disabled: boolean;
}

const useDocumentGroupToggles = (initialToggles: DocumentGroupToggle[]) => {

    const [documentGroupToggles, setDocumentGroupToggles] = useState<DocumentGroupToggle[]>(initialToggles);


    useEffect(() => {
        const updatedToggles = updateDisabledToggles(initialToggles);

        setDocumentGroupToggles(updatedToggles);

    }, [initialToggles]);

    const handleDocumentGroupToggleChange = (selectedToggle: DocumentGroupToggle): void => {
        setDocumentGroupToggles((prevToggles) => {
            let updatedToggles = prevToggles.map((toggle) =>
                selectedToggle.permission === toggle.permission
                    ? {
                        ...toggle,
                        hasPermission: !toggle.hasPermission,
                    }
                    : toggle);

            updatedToggles = updateDisabledToggles(updatedToggles);

            return updatedToggles;
        });
    };

    const updateDisabledToggles = (updatedToggles: DocumentGroupToggle[]) => {
        // Update based on Audit permission
        updatedToggles = updatedToggles.map((toggle) => {
            const hasPermission = updatedToggles.some(
                (tog) => (tog.permission === 'Audit' || tog.permission === 'Delete') && tog.hasPermission,
            );

            if (toggle.permission === 'View' && hasPermission) {
                return {
                    ...toggle,
                    hasPermission: true,
                    disabled: true,
                };
            }

            return toggle;
        });

        updatedToggles = updatedToggles.map((toggle) => {
            const hasPermission = updatedToggles.some(
                (tog) => (tog.permission === 'Audit' || tog.permission === 'Delete') && tog.hasPermission,
            );

            if (toggle.permission === 'View' && hasPermission) {
                return {
                    ...toggle,
                    hasPermission: true,
                    disabled: true,
                };
            }

            return toggle;
        });

        updatedToggles = updatedToggles.map((toggle) => {
            const hasPermission = updatedToggles.some(
                (tog) => (tog.permission === 'Upload' || tog.permission === 'Download') && tog.hasPermission,
            );

            if ((toggle.permission === 'Audit') && hasPermission) {
                return {
                    ...toggle,
                    hasPermission: true,
                    disabled: true,
                };
            }

            return toggle;
        });

        updatedToggles = updatedToggles.map((toggle) => {
            const hasPermission = updatedToggles.some(
                (tog) => (tog.permission === 'Upload' || tog.permission === 'Download') && tog.hasPermission,
            );

            if ((toggle.permission === 'Audit') && !hasPermission) {
                return {
                    ...toggle,
                    disabled: false,
                };
            }

            return toggle;
        });

        updatedToggles = updatedToggles.map((toggle) => {
            const hasPermission = updatedToggles.some(
                (tog) => (tog.permission === 'Audit') && tog.hasPermission,
            );

            if ((toggle.permission === 'View') && hasPermission) {
                return {
                    ...toggle,
                    hasPermission: true,
                    disabled: true,
                };
            }

            return toggle;
        });

        // Update based on Audit and Delete permission
        updatedToggles = updatedToggles.map((toggle) => {
            const hasPermission = updatedToggles.some(
                (tog) => (tog.permission === 'Audit' || tog.permission === 'Delete') && tog.hasPermission,
            );

            if (toggle.permission === 'View' && !hasPermission) {
                return {
                    ...toggle,
                    disabled: false,
                };
            }

            return toggle;
        });

        // Update based on ViewTemplate and Delete/Create permission
        updatedToggles = updatedToggles.map((toggle) => {
            const hasPermission = updatedToggles.some(
                (tog) => (tog.permission === 'DeleteTemplate' || tog.permission === 'CreateTemplate') && tog.hasPermission,
            );

            if (toggle.permission === 'ViewTemplate' && hasPermission) {
                return {
                    ...toggle,
                    hasPermission: true,
                    disabled: true,
                };
            }

            return toggle;
        });

        // Update based on ViewTemplate and no Delete/Create permission
        updatedToggles = updatedToggles.map((toggle) => {
            const hasPermission = updatedToggles.some(
                (tog) => (tog.permission === 'DeleteTemplate' || tog.permission === 'CreateTemplate') && tog.hasPermission,
            );

            if (toggle.permission === 'ViewTemplate' && !hasPermission) {
                return {
                    ...toggle,
                    disabled: false,
                };
            }

            return toggle;
        });

        return updatedToggles;
    };


    return {
        documentGroupToggles,
        handleDocumentGroupToggleChange,
    };
};

export default useDocumentGroupToggles;
