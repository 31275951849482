import { Checkbox, NdsButton, NdsIconFont } from '@gonitro/rcl';
import { NdsButtonStylesEnum, NdsButtonTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import { useMemo } from 'react';
import PackageRecipientDropdown  from '~components/package-recipient-dropdown';
import Typography, { TypographyToken } from '~components/typography';
import { ActorType } from '~constants';
import { useFlowContext, useSetFlowState } from '~contexts/flow';
import { FlowError } from '~contexts/flow/flow.error';
import { useAddActor } from '~contexts/flow/flow.hooks';
import { FlowActionType, FlowEntityType } from '~contexts/flow/flow.types';
import { FlowUtils } from '~contexts/flow/flow.utils';
import { useTranslation } from '~contexts/i18n';
import { StringUtils } from '~lib';
import { FlowRadioGroupFieldModel } from '~models';
import './flowEditorRadioFieldProperties.scss';


export interface FlowEditorRadioFieldPropertiesProps {

}

function FlowEditorRadioFieldProperties(props: FlowEditorRadioFieldPropertiesProps) {
    const { focusedEntityId, flowState: { stakeholders, elements, flow }, hasError, getErrorInfo, setFocusedEntity } = useFlowContext();
    const setFlowState = useSetFlowState();
    const { t: tFlow } = useTranslation('flow');
    const found = elements.find(el => el.localId === focusedEntityId) as FlowRadioGroupFieldModel;
    const addActorCallback = useAddActor();

    const actorStakeholderLocalId = useMemo(() => {
        return FlowUtils.findActorByElementId(flow, found?.localId)?.actor.stakeholderLocalId;
    }, [found, flow]);

    const uniqueNameError = useMemo<{ text: any }>(() => {
        if (focusedEntityId) {
            if (hasError(FlowError.FieldMissingUniqueName, focusedEntityId)) {
                return getErrorInfo(FlowError.FieldMissingUniqueName, focusedEntityId);
            }
        }
    }, [focusedEntityId, hasError, getErrorInfo]);

    return (
        <div className={'c-flow-editor-radio-field-properties c-flow-editor-right-pane-component'}>
            <PanelBar>
                <PanelBarItem
                    title={'Recipient'}
                    expanded
                >
                    <PackageRecipientDropdown
                        stakeholders={stakeholders}
                        selectedStakeholderLocalId={actorStakeholderLocalId}
                        onRecipientChange={(stakeholder) => {
                            const existing = FlowUtils.findStakeholderActors(flow, stakeholder.localId).filter((actorResult) => actorResult.actor.actorType === ActorType.FormFiller);
                            const originalActor = FlowUtils.findActorByElementId(flow, found.localId);

                            if (existing.length > 0) {
                                setFlowState(FlowActionType.AddActorElements, {
                                    actorId: existing[0].actor.localId,
                                    elementIds: [found.localId],
                                });
                            } else {
                                addActorCallback(ActorType.FormFiller, stakeholder.localId, 'last', { elements: [found.localId] });
                            }

                            if (originalActor) {
                                setFlowState(FlowActionType.DeleteActorElements, {
                                    actorId: originalActor.actor.localId,
                                    elementIds: [found.localId],
                                });
                            }

                            found.stakeholderLocalId = stakeholder.localId;
                        }}
                    />
                </PanelBarItem>
                <PanelBarItem
                    title={'Unique name'}
                    expanded
                >
                    <Input
                        value={found?.name}
                        onChange={(e) => {
                            setFlowState(FlowActionType.UpdateElement, {
                                ...found,
                                name: e.value,
                            });
                        }}
                    />
                    {uniqueNameError &&
                        <Typography
                            className={'flow-error-text'}
                            text={tFlow(uniqueNameError.text)}
                            token={TypographyToken.DesktopDescriptionSm}
                        />
                    }
                </PanelBarItem>
                <PanelBarItem
                    title={'Label'}
                    expanded
                >
                    <Input
                        value={found?.label}
                        onChange={(e) => {
                            setFlowState(FlowActionType.UpdateElement, {
                                ...found,
                                label: e.value,
                            });
                        }}
                    />
                </PanelBarItem>
                <PanelBarItem
                    title={'Tooltip'}
                    expanded
                >
                    <Input
                        value={found?.toolTipLabel}
                        onChange={(e) => {
                            setFlowState(FlowActionType.UpdateElement, {
                                ...found,
                                toolTipLabel: e.value,
                            });
                        }}
                    />
                </PanelBarItem>
                <PanelBarItem
                    title={'Options'}
                    expanded
                >
                    <div className={'c-flow-editor-radio-field-properties-options-container'}>
                        {
                            found?.options && found?.options.map((option, index) => {
                                return (
                                    <div key={index} className={'c-flow-editor-radio-field-properties-options-container-option'}>
                                        <Typography
                                            token={TypographyToken.MobileBylineXs}
                                            text={`Option ${index+1}`}
                                            tagName={'label'}
                                            className={'c-flow-editor-radio-field-properties-options-container-option_label'}
                                        />
                                        <div className={'c-flow-editor-radio-field-properties-options-container-option_input'}>
                                            <FieldWrapper>
                                                <Label>
                                                    {'Option name'}
                                                </Label>
                                                <Input
                                                    value={option.name}
                                                    onChange={(e) => {
                                                        const options = found.options.slice();

                                                        options[index].name = e.value;
                                                        setFlowState(FlowActionType.UpdateElement, {
                                                            ...found,
                                                            options,
                                                        });
                                                    }}
                                                />
                                            </FieldWrapper>
                                        </div>
                                        <div className={'c-flow-editor-radio-field-properties-options-container-option_input'}>
                                            <FieldWrapper>
                                                <Label>
                                                    {'Option label'}
                                                </Label>
                                                <Input
                                                    value={option.label}
                                                    onChange={(e) => {
                                                        const options = found.options.slice();

                                                        options[index].label = e.value;
                                                        setFlowState(FlowActionType.UpdateElement, {
                                                            ...found,
                                                            options,
                                                        });
                                                    }}
                                                />
                                            </FieldWrapper>
                                        </div>
                                        <Checkbox
                                            name={'isCheckedByDefault'}
                                            label={'Checked by default'}
                                            value={option.isSelected}
                                            onChange={(e) => {
                                                const options = found.options.slice();

                                                if (!option.isSelected) { // only one radio option can be selected by default
                                                    options.forEach((option) => option.isSelected = false);
                                                }
                                                options[index].isSelected = !option.isSelected;
                                                setFlowState(FlowActionType.UpdateElement, {
                                                    ...found,
                                                    options,
                                                });
                                            }}
                                        />
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className={'c-flow-editor-radio-field-properties-options-button-container'}>
                        <NdsButton
                            size={SizesEnums.XSMALL}
                            label={'Add an option'}
                            buttonType={NdsButtonTypesEnum.SECONDARY}
                            buttonStyle={NdsButtonStylesEnum.ALT}
                            leftIcon='fa-solid-plus'
                            onClick={(e) => {
                                const options = found.options.slice();

                                const location = { ...options[0].location };

                                if (location && location.left) {
                                    location.left = location.left + 50; // TODO: calculate proper location
                                }

                                options.push({
                                    localId: StringUtils.guid(),
                                    location,
                                    isSelected: false,
                                    name: '',
                                });

                                setFlowState(FlowActionType.UpdateElement, {
                                    ...found,
                                    options,
                                });
                            }}
                        />
                    </div>
                </PanelBarItem>
            </PanelBar>
            <div className={'c-flow-editor-radio-field-properties-footer'}>
                <div className={'c-flow-editor-radio-field-properties-footer-button-container'}>
                    <NdsButton
                        size={SizesEnums.SMALL}
                        label={'Remove radio group'}
                        buttonType={NdsButtonTypesEnum.SECONDARY}
                        buttonStyle={NdsButtonStylesEnum.ALT}
                        leftIcon='fa-light-trash'
                        onClick={() => {
                            setFlowState(FlowActionType.DeleteElement, {
                                localId: found.localId,
                                type: found.type,
                            });

                            setFocusedEntity(FlowEntityType.Package);
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

FlowEditorRadioFieldProperties.Header = () => {
    return (
        <div className={'c-flow-editor-right-pane-component-header'}>
            <div className={'c-flow-editor-right-pane-component-header__icon'}>
                <NdsIconFont fontName={'fa-regular-circle-dot'} />
            </div>
            <div className={'c-flow-editor-right-pane-component-header__title'}>
                Radio group
            </div>
            <div className={'c-flow-editor-right-pane-component-header__action'}></div>
        </div>
    );
};
export default FlowEditorRadioFieldProperties;
