import { v4 as uuid } from 'uuid';
import { ValidateUtils } from '~lib';
import { FlowInfoDocument } from '~models';
import { FlowActionType, FlowReducerFunc } from '../flow.types';

// these are for manage package documents page

export type FlowReducerDocumentActionPayloads = {
    [FlowActionType.SetDocuments]: FlowInfoDocument[];
    [FlowActionType.AddDocuments]: FlowInfoDocument[];
    [FlowActionType.DeleteDocument]: string;
    [FlowActionType.UpdateDocument]: {
        localId: string
    } & Partial<Pick<FlowInfoDocument, 'conversionTargetFormat' | 'documentName' | 'isOptional'>>;
}

export const SetDocumentsReducer: FlowReducerFunc<FlowActionType.AddDocuments> = (
    state,
    payload,
) => {
    return {
        ...state,
        infoDocuments: payload.map(({ localId, documentName, ...el }) => ({
            localId: localId || uuid(),
            documentName: documentName.trim().substring(0, ValidateUtils.documentNameMaxLength),
            ...el,
        })),
    };
};
export const AddDocumentsReducer: FlowReducerFunc<FlowActionType.AddDocuments> = (
    state,
    payload,
) => {
    return {
        ...state,
        infoDocuments: [
            ...state.infoDocuments,
            ...payload.map(({ localId, documentName, ...el }) => ({
                localId: localId || uuid(),
                documentName: documentName.trim().substring(0, ValidateUtils.documentNameMaxLength),
                ...el,
            })),
        ],
    };
};

export const DeleteDocumentsReducer: FlowReducerFunc<FlowActionType.DeleteDocument> = (
    state,
    payload,
) => {
    const doc = state.infoDocuments.find(doc => doc.localId === payload);

    if (doc && 'id' in doc && doc.id) {
        return {
            ...state,
            infoDocuments: state.infoDocuments.filter(doc => doc.localId !== payload),
            deletedDocuments: [...state.deletedDocuments, doc.id],
        };
    } else {
        return {
            ...state,
            infoDocuments: state.infoDocuments.filter(doc => doc.localId !== payload),
        };
    }
};

export const UpdateDocumentsReducer: FlowReducerFunc<FlowActionType.UpdateDocument> = (
    state,
    { localId, ...data },
) => {
    return {
        ...state,
        infoDocuments: state.infoDocuments.map(doc => {
            if (doc.localId !== localId) {
                return doc;
            } else {
                const newData = {
                    ...doc,
                    ...data,
                };

                // not trimming here as it's used for live update
                newData.documentName = newData.documentName.substring(0, ValidateUtils.documentNameMaxLength);

                return newData;
            }
        }),
    };
};
