import { createContext, useContext } from 'react';

export type DropdownContextType = {
    closeDropDown: () => void;
    contentAlign: 'left' | 'right';
    hasDropdown: boolean
}

export const DropdownContext = createContext<DropdownContextType>({} as DropdownContextType);

export const useDropdown = () => {
    const context = useContext(DropdownContext);

    return context;
};
