export enum SigningBehavior {
    Server = 'SERVER',
    Manual = 'MANUAL',
    MailOtp = 'MAILOTP',
    SmsCode = 'SMSCODE',
    OpenIdConnect = 'OPENIDCONNECT',
    Biometric = 'BIOMETRIC',
    Idin = 'IDIN',
    SmartCard = 'SMARTCARD',
    RemoteHashSigning = 'REMOTEHASHSIGNING',

    // Keytrade specific methods
    Password = 'PASSWORD',
    Hardkey = 'HARDKEY',
    Softkey = 'SOFTKEY',
}
