import { NdsButton } from '@gonitro/rcl';
import { NdsButtonStylesEnum, NdsButtonTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import Loader from '~components/loader';
import Typography, { TypographyToken } from '~components/typography';
import { useTranslation } from '~contexts/i18n';
import './importRecordsInformation.scss';

export interface CSVInfoModel {
    fileName: string;
    recordsCount: number;
}

export interface ImportRecordsInformationProps {
    cloudInfo?: any;
    csvInfo?: CSVInfoModel;
    isLoadingRecords?: boolean;
    deleteRecords?: () => void;
}

function ImportRecordsInformation({
    csvInfo,
    cloudInfo,
    isLoadingRecords,
    deleteRecords,
}: ImportRecordsInformationProps) {
    const { t } = useTranslation('contacts');

    return (
        <div className={'c-import-records-information'}>
            {csvInfo && (
                <div className={'c-import-records-information__csv-info'}>
                    <Typography tagName='div' token={TypographyToken.UiFormsLabelSm} text={csvInfo.fileName} />
                    <Typography
                        tagName='div'
                        token={TypographyToken.UiFormsLabelSm}
                        text={t('import-csv-records-count', { count: csvInfo.recordsCount })}
                    />
                    {isLoadingRecords
                        ? (
                            <div className={'c-import-records-information__loader'}>
                                <Loader size={SizesEnums.SMALL} />
                            </div>
                        )
                        : (
                            <NdsButton
                                size={SizesEnums.XSMALL}
                                buttonStyle={NdsButtonStylesEnum.ALT}
                                buttonType={NdsButtonTypesEnum.NEUTRAL}
                                leftIcon='fa-solid-trash'
                                onClick={deleteRecords}
                            />
                        )}
                </div>
            )}
            {cloudInfo && <>Cloud records information</>}
        </div>
    );
}

export default ImportRecordsInformation;
