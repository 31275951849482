import { NdsButton } from '@gonitro/rcl';
import { NdsButtonStylesEnum, NdsButtonTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import { useCallback, useRef, useState } from 'react';
import FileDropzone from '~components/files/file-dropzone';
import FileUpload, { FileUploadRef } from '~components/files/file-upload';
import Typography, { TypographyToken } from '~components/typography';
import { ImportContactFields, ImportContactProperties } from '~constants/import-contact-properties';
import { useTranslation } from '~contexts/i18n';
import { LanguageDataLabel } from '~models';
import ImportContactFormatTable from '../import-contact-format-table';
import ImportRecordsInformation, { CSVInfoModel } from '../import-records-information/importRecordsInformation';
import './importCsvContent.scss';

export interface ImportCsvContentProps {
    file: File | null;
    setFile: (file: File | null) => void;
    error: any;
    setError: (error: any) => void;
}

function ImportCsvContent({ file, setFile, error, setError }: ImportCsvContentProps) {
    const { t } = useTranslation('contacts');
    const [csvInfo, setCsvInfo] = useState<CSVInfoModel>({
        fileName: '',
        recordsCount: 0,
    });
    const fileInputRef = useRef<FileUploadRef>();
    const [columns, setColumns] = useState([ImportContactFields[ImportContactProperties.EmailAddress], ImportContactFields[ImportContactProperties.LastName]]);

    // Function to convert headers to a CSV needed format with delimeters
    const convertToCSV = (headers: Array<{ keyTranslation: LanguageDataLabel<'contacts'>; key: string }>): string => {
        const headerRow = headers.map((header) => `${t(header.keyTranslation)}:${header.key}`).join(';') + ';';

        return headerRow;
    };
    // Function to download an example CSV file to fill with contacts
    const downloadExampleCSV = () => {
        const csvData = convertToCSV(columns);
        const blob = new Blob([csvData], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.setAttribute('hidden', '');
        a.setAttribute('href', url);
        a.setAttribute('download', `${t('exampleCSV')}.csv`);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const processFile = useCallback(
        (file: File) => {
            const fileExtension = file.name.split('.').pop()?.toLowerCase();

            // Check if the file extension is allowed (for example, 'csv')
            if (fileExtension !== 'csv') {
                setError('invalid-csv');

                return;
            }

            const reader = new FileReader();

            reader.onload = (event) => {
                const content = event.target?.result as string;
                // Split by EOL and filter out empty lines
                const records = content.split(/\r\n|\n/)
                    .map(record => record.trim())
                    .filter(record => record && record.split(',').some(field => field.trim() !== '""'));

                // Update the state with file information
                setCsvInfo({
                    fileName: file.name,
                    recordsCount: records.length - 1, //The first line is the header
                });

                if (records.length === 1) {
                    setError('no-records-file');
                } else {
                    setError(undefined);
                }
            };

            reader.readAsText(file);
        },
        [setError],
    );

    // Function to handle browse button click
    const handleBrowseClick = () => {
        // Trigger the file input click if it exists
        fileInputRef.current?.openPrompt();
    };

    const handleDeleteFile = () => {
        /// Reset the CSV info state
        setFile(null);
        setCsvInfo({
            fileName: '',
            recordsCount: 0,
        });
    };

    const onLocalFileUpload = useCallback(
        (files: File[]) => {
            setFile(files[0]);

            if (files) {
                processFile(files[0]);
            }
        },
        [processFile, setFile],
    );

    return (
        <div className={'c-import-csv-content'}>
            {!file || error || csvInfo.recordsCount === 0
                ? (
                    <>
                        <div className={'c-import-csv-content__download-warning'}>
                            <div>
                                <Typography
                                    className={'c-import-csv-content__warning-message'}
                                    tagName='span'
                                    token={TypographyToken.DesktopBylineXs}
                                    text={t('csv-download-message')}
                                />
                            </div>

                            <NdsButton
                                size={SizesEnums.XSMALL}
                                buttonStyle={NdsButtonStylesEnum.DEFAULT}
                                buttonType={NdsButtonTypesEnum.SECONDARY}
                                leftIcon='fa-solid-download'
                                label={t('download-template-button')}
                                onClick={() => downloadExampleCSV()}
                            />
                        </div>
                        <div className={'c-import-csv-content__csv-template'}>
                            <ImportContactFormatTable columns={columns} setColumns={setColumns} />
                        </div>
                        <FileDropzone inputRef={fileInputRef}>
                            <div className={'c-import-csv-content__drag-and-drop'}>
                                <div className={'c-import-csv-content__drag-and-drop-actions'}>
                                    <Typography
                                        tagName='span'
                                        className={'c-import-csv-content__drag-and-drop-label'}
                                        token={TypographyToken.DesktopHeaderXs}
                                        text={t('drag-and-drop-label')}
                                    />
                                    <Typography
                                        tagName='span'
                                        className={'c-import-csv-content__drag-and-drop-secondary-color-label'}
                                        token={TypographyToken.DesktopDescriptionLg}
                                        text={t('drag-and-drop-or-label')}
                                    />
                                    <NdsButton
                                        size={SizesEnums.SMALL}
                                        buttonStyle={NdsButtonStylesEnum.ALT}
                                        buttonType={NdsButtonTypesEnum.PRIMARY}
                                        label={t('upload-button')}
                                        onClick={() => handleBrowseClick()}
                                    />
                                </div>
                                <Typography
                                    tagName='div'
                                    className={'c-import-csv-content__drag-and-drop-secondary-color-label'}
                                    token={TypographyToken.DesktopDescriptionSm}
                                    text={t('drag-and-drop-subtitle')}
                                />
                                <FileUpload
                                    onUpload={onLocalFileUpload}
                                    inputRef={fileInputRef}
                                    mimeTypes={['text/csv']}
                                />
                            </div>
                        </FileDropzone>
                    </>
                )
                : (
                    <ImportRecordsInformation csvInfo={csvInfo} deleteRecords={() => handleDeleteFile()} />
                )}
        </div>
    );
}

export default ImportCsvContent;
