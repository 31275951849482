export namespace DomUtils {
    export const findParent = (
        node: HTMLElement,
        filterCallback: (current: HTMLElement) => boolean,
        stopOnNode?: HTMLElement,
    ): HTMLElement | undefined => {
        let currentNode = node;
        let found = false;

        while (currentNode && currentNode.parentNode && currentNode !== stopOnNode) {
            found = filterCallback(currentNode);

            if (found) {
                return currentNode;
            }

            currentNode = currentNode.parentNode as HTMLElement;
        }

        return undefined;
    };

    export const b64ToSvg = (b64: string): string => {
        const svgContent = atob(b64);

        const scriptTagRegex = /<\s*script\b[^<]*(?:(?!<\s*\/script\s*>)<[^<]*)*<\s*\/script\s*>/gim;
        const strippedScripTagsSvgContent = svgContent.replace(scriptTagRegex, '');

        const evilAttributeRegex = /\s?on(?:\w+)=/gm;
        const sanitizedSvgContent = strippedScripTagsSvgContent.replace(evilAttributeRegex, () => '');

        return sanitizedSvgContent;
    };
}
