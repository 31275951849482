import { NdsButton } from '@gonitro/rcl';
import { NdsButtonStylesEnum, NdsButtonTypesEnum, SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types';
import { useEffect, useState } from 'react';
import { ContactsApi } from '~api/contacts.api';
import ContactSelector from '~components/contact-selector';
import { CloudContactProviderEnum } from '~constants/cloud-provider';
import { useApi } from '~contexts/api';
import { useSettings } from '~contexts/settings';
import { ContactBucketItemModel } from '~models';
import './selectNewContactReassign.scss';

export interface SelectNewContactReassignProps {
    contact: ContactBucketItemModel | null;
    tFlow: any;
    onContactChange: (contact: ContactBucketItemModel| null) => void;
    onAddContactClick: () => void;
}

const SelectNewContactReassign = ({ contact, tFlow, onContactChange, onAddContactClick }: SelectNewContactReassignProps) => {
    const contactsApi = useApi(ContactsApi);
    const settings = useSettings();
    const [disableSync, setDisableSync] = useState<boolean>(false);
    const { google, microsoft } = settings.cloudSettings;

    useEffect(() => {
        if (!google.oauthSettings.isEnabled || !microsoft.oauthSettings.isEnabled) {
            setDisableSync(true);
        }
    }, [google.oauthSettings.isEnabled, microsoft.oauthSettings.isEnabled]);

    const syncContacts = async () => {
        try {
            if (google.oauthSettings.isEnabled) {
                await contactsApi.syncContacts({ contactsProvider: CloudContactProviderEnum.Google });
            }
            if (microsoft.oauthSettings.isEnabled) {
                await contactsApi.syncContacts({ contactsProvider: CloudContactProviderEnum.Microsoft });
            }
        } catch (e) {
            console.warn(e);
            throw e;
        }
    };

    return (
        <div className='c-select-new-contact-reassign__new-recipient-wrapper'>
            <ContactSelector
                onChange={(contact) => onContactChange(contact)}
                value={contact}
                disabled={false}
                contactGroupTypes={[]}/>
            <div className='c-select-new-contact-reassign__new-recipient-buttons'>
                <NdsButton
                    size={SizesEnums.XSMALL}
                    label={tFlow('sync-contacts')}
                    buttonType={NdsButtonTypesEnum.SECONDARY}
                    buttonStyle={NdsButtonStylesEnum.ALT}
                    leftIcon='fa-solid-arrows-repeat'
                    state={disableSync ? StatesEnum.DISABLED : StatesEnum.DEFAULT}
                    onClick={() => syncContacts()}
                />
                <NdsButton
                    size={SizesEnums.XSMALL}
                    label={tFlow('add-contacts')}
                    buttonType={NdsButtonTypesEnum.PRIMARY}
                    buttonStyle={NdsButtonStylesEnum.ALT}
                    leftIcon='fa-solid-plus'
                    onClick={onAddContactClick}
                />
            </div>
        </div>
    );
};

export default SelectNewContactReassign;
