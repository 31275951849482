export enum ApiErrors {
    UserNotAuthenticated = 'User.NotAuthenticated',
    ImportCSVInvalidHeader = 'ImportContact.InvalidHeader',
    ImportContactDuplicateColumn = 'ImportContact.DuplicateColumn',
    DocumentPasswordProtected = 'Document.PasswordProtected',
    DocumentPdfPortfolio = 'Document.PdfPortfolio',
    DocumentInvalidSourceFileType = 'Document.InvalidSourceFileType',
    DocumentInvalidTargetFileType = 'Document.InvalidTargetFileType',
    DocumentUploadDoesNotComplyToSpec = 'Document.UploadDoesNotComplyToSpec',
    PackageInvalidStatusProcessing = 'Package.InvalidStatus:Processing',
    PackageInvalidStatusGeneric = 'Package.InvalidStatus',
    PackageInvalidStatusProcessingFailed = 'Package.InvalidStatus:ProcessingFailed',
    TemplateNameNotUnique = 'Template.NameNotUnique',
}
