import { NdsBadge } from '@gonitro/rcl';
import { NdsBadgeStatesEnum, NdsBadgeTypesEnum } from '@gonitro/rcl/lib/_types';
import { FlowStatusEnum, FlowStatusI18n } from '~constants/flow-status';
import { useTranslation } from '~contexts/i18n';


export interface FlowStatusBadgeProps {
    status: FlowStatusEnum;
    icon?: string;
}

function FlowStatusBadge({ status, icon }: FlowStatusBadgeProps) {

    const { t } = useTranslation('flow');

    const mapStatusToState: Record<FlowStatusEnum, NdsBadgeStatesEnum> = {
        [FlowStatusEnum.Ready]: NdsBadgeStatesEnum.PENDING,
        [FlowStatusEnum.PendingApproval]: NdsBadgeStatesEnum.PENDING,
        [FlowStatusEnum.PendingFormFill]: NdsBadgeStatesEnum.PENDING,
        [FlowStatusEnum.PendingSigning]: NdsBadgeStatesEnum.PENDING,
        [FlowStatusEnum.Received]: NdsBadgeStatesEnum.SUCCESS,
        [FlowStatusEnum.Signed]: NdsBadgeStatesEnum.SUCCESS,
        [FlowStatusEnum.Archived]: NdsBadgeStatesEnum.SUCCESS,
        [FlowStatusEnum.Ending]: NdsBadgeStatesEnum.PENDING,
        [FlowStatusEnum.Skipped]: NdsBadgeStatesEnum.SUCCESS,
        [FlowStatusEnum.Refused]: NdsBadgeStatesEnum.FAILURE,
        [FlowStatusEnum.Pending]: NdsBadgeStatesEnum.PENDING,
        [FlowStatusEnum.Finished]: NdsBadgeStatesEnum.SUCCESS,
        // This might be changed to NdsBadgeStatesEnum.NEUTRAL
        [FlowStatusEnum.Draft]: NdsBadgeStatesEnum.SECONDARY,
        [FlowStatusEnum.Failed]: NdsBadgeStatesEnum.FAILURE,
        [FlowStatusEnum.Rejected]: NdsBadgeStatesEnum.FAILURE,
        [FlowStatusEnum.Revoked]: NdsBadgeStatesEnum.FAILURE,
        [FlowStatusEnum.InProgress]: NdsBadgeStatesEnum.PENDING,
        [FlowStatusEnum.ProcessingFailed]: NdsBadgeStatesEnum.FAILURE,
        [FlowStatusEnum.All]: NdsBadgeStatesEnum.SECONDARY,
        [FlowStatusEnum.Expired]: NdsBadgeStatesEnum.WARNING,
        [FlowStatusEnum.Processing]: NdsBadgeStatesEnum.PENDING,
    };

    return (
        <NdsBadge
            text={t(FlowStatusI18n[status])}
            badgeType={NdsBadgeTypesEnum.STATUS}
            state={mapStatusToState[status]}
            icon={icon}
        />
    );
}

export default FlowStatusBadge;
