import { Guid } from '~models';
import { FlowActionType, FlowReducerFunc } from '../flow.types';

export type FlowReducerCommonActionPayloads = {
    [FlowActionType.SetFlowId]: Guid;
}

export const SetFlowIdReducer: FlowReducerFunc<FlowActionType.SetFlowId> = (
    state,
    payload,
) => {
    return {
        ...state,
        flowId: payload,
    };
};
