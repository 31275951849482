import { useMemo, useState } from 'react';
import Error from '~components/error';
import Typography, { TypographyToken } from '~components/typography';
import { useTranslation } from '~contexts/i18n';
import { UrlUtil } from '~lib';
import ResetPasswordForm from './reset-pasword-form';
import './reset-password.view.scss';

export interface ResetPasswordViewProps {
}

type ResetPasswordLocation = {
    id: string;
    token: string;
    expiryTag: string;
    error?: string;
}

function ResetPasswordView() {
    const { t } = useTranslation('unauthorized');
    const queryParams = useMemo(() =>
        UrlUtil.getLocationInfo<ResetPasswordLocation>(window.location.href).getQueryParams(),
    []);
    const [expiredError, setExpiredError] = useState('error' in queryParams);

    return (
        <div className='v-reset-password'>
            <div className={'v-reset-password__header'}>
                <Typography
                    tagName={'h1'}
                    token={TypographyToken.MobileHeaderLg}
                    mediumScreenToken={TypographyToken.DesktopHeaderLg}
                    text={t('title-reset-your password')}
                />
            </div>
            <div className={'v-reset-password__content'}>
                {queryParams.expiryTag && queryParams.token && queryParams.id && !expiredError
                    ? (
                        <ResetPasswordForm
                            expiryTag={queryParams.expiryTag}
                            token={queryParams.token}
                            userId={queryParams.id}
                            setExpiredError={() => setExpiredError(true)}
                        />
                    )
                    : (
                        <Error i18nKey={'reset-password-link-expired'} size={'sm'} />
                    )}
            </div>
        </div>
    );
}

export default ResetPasswordView;