import { NdsButton } from '@gonitro/rcl';
import { NdsButtonTypesEnum, SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types';
import Typography, { TypographyToken } from '~components/typography';
import { useFlowContext } from '~contexts/flow';
import { useTranslation } from '~contexts/i18n';
import FlowDocumentsList from '../flow-documents-list';
import FlowDocumentsTemplateName from '../flow-documents-template-name';
import FlowDocumentsTemplateSelector from '../flow-documents-template-selector';
import FlowDocumentsUpload from '../flow-documents-upload';
import FlowDocumentsWarnings from '../flow-documents-warnings';
import './flowDocumentsContent.scss';

export interface FlowDocumentsContentProps {
    useTemplateSelector?: boolean;
    useTemplateNameInput?: boolean;
    nextButtonEnabled?: boolean;
    onNextClick: () => void;
    onBackClick: () => void;
}

function FlowDocumentsContent({
    useTemplateSelector,
    useTemplateNameInput,
    nextButtonEnabled,
    onBackClick,
    onNextClick,
}: FlowDocumentsContentProps) {
    const { t } = useTranslation('flow');
    const { t: tBase } = useTranslation('base');
    const { isNew } = useFlowContext();

    return (
        <div className={'c-flow-documents-content'}>
            <Typography
                className={'c-flow-documents-content__title'}
                token={TypographyToken.DesktopHeaderLg}
                tagName={'h1'}
                text={t(isNew ? 'add-documents-title' : 'manage-documents-title')}
            />
            {useTemplateSelector && <FlowDocumentsTemplateSelector />}
            {useTemplateNameInput && <FlowDocumentsTemplateName />}
            <FlowDocumentsUpload />
            <FlowDocumentsWarnings />
            <FlowDocumentsList />
            <div className={'c-flow-documents-content__buttons'}>
                <NdsButton
                    buttonType={NdsButtonTypesEnum.NEUTRAL}
                    label={tBase('back')}
                    size={SizesEnums.SMALL}
                    onClick={onBackClick}
                />
                <NdsButton
                    label={tBase('next')}
                    size={SizesEnums.SMALL}
                    state={nextButtonEnabled ? StatesEnum.DEFAULT : StatesEnum.DISABLED}
                    onClick={onNextClick}
                />
            </div>
        </div>
    );
}

export default FlowDocumentsContent;
