import { createContext } from 'react';
import { IApi } from '~contexts/api/api.models';
import { HttpError } from '~lib/http/http.error';

export type ErrorProcessorFunction = (error: HttpError) => void | boolean;
export type ApiContextType = {
    apis: IApi[];
    /**
     * register global error processors, if processor func returns true it stops further processing
     */
    registerErrorProcessor: (id: string, processor: ErrorProcessorFunction) => void;
    unregisterErrorProcessor: (id: string) => void;
}
export const ApiContext = createContext<ApiContextType>({} as ApiContextType);

export default ApiContext;
