import { NdsButton } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import NoResultsCreatedYet from '~components/no-results-creted-yet';
import { useTranslation } from '~contexts/i18n';

export interface NoContactGroupCreatedYetProps {
    handleAddContactGroupClick: () => void;
}

const NoContactGroupCreatedYet = ({ handleAddContactGroupClick }: NoContactGroupCreatedYetProps) => {
    const { t } = useTranslation('contacts');

    return (
        <NoResultsCreatedYet description={t('no-contact-group-description')} title={t('no-contact-group-title')}>
            <NdsButton onClick={handleAddContactGroupClick} size={SizesEnums.SMALL} label={t('create-contact-group')} />
        </NoResultsCreatedYet>
    );
};

export default NoContactGroupCreatedYet;
