import { NdsIconFont } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import classNames from 'classnames';
import { PropsWithChildren, useState } from 'react';
import Typography, { TypographyToken } from '~components/typography';
import './panelDropdown.scss';

export interface PanelDropdownProps extends PropsWithChildren {
    headerText: string;
    openDropdown?: boolean
}

const PanelDropdown = ({ headerText, children, openDropdown }: PanelDropdownProps) => {
    const [isOpen, setIsOpen] = useState(openDropdown);

    return (
        <>
            <div className={classNames('c-panel-dropdown', { 'c-panel-dropdown--open': isOpen })} onClick={() => setIsOpen(!isOpen)}>
                <NdsIconFont
                    className='c-panel-dropdown__icon'
                    size={SizesEnums.SMALL}
                    fontName={'fa-solid-caret-right'}
                />
                <Typography token={TypographyToken.MobileTagSm} text={headerText} className={'c-panel-dropdown__header'}/>
            </div>
            {isOpen && <div className='c-panel-dropdown__content'>{children}</div>}
        </>
    );
};

export default PanelDropdown;