import { NdsButton } from '@gonitro/rcl';
import {
    NdsButtonStylesEnum,
    NdsButtonTypesEnum,
    SizesEnums,
} from '@gonitro/rcl/lib/_types';
import { ContactsApi } from '~api/contacts.api';
import Typography, { TypographyToken } from '~components/typography';
import { CloudContactProviderEnum } from '~constants/cloud-provider';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { DateUtil } from '~lib/date.utils';
import { CloudContactStatus } from '~models/contacts.models';
import './cloudAccountInfo.scss';

export interface CloudAccountInfoProps {
    cloudProvider: CloudContactProviderEnum;
    cloudStatus: CloudContactStatus;
    altLabel: string;
    icon: string;
    onDisconnectClick: () => void;
}

enum ActionTypeEnum {
    Sync,
    Disconnect
}

function CloudAccountInfo({
    altLabel,
    icon,
    cloudProvider,
    cloudStatus,
    onDisconnectClick,
}: CloudAccountInfoProps) {
    const contactsApi = useApi(ContactsApi);
    const { t } = useTranslation('contacts');
    const disconnectOrSyncContacts = async (actionType: ActionTypeEnum) => {
        try {
            if (actionType === ActionTypeEnum.Sync) {
                return await contactsApi.syncContacts({
                    contactsProvider: cloudProvider === CloudContactProviderEnum.Google
                        ? CloudContactProviderEnum.Google
                        : CloudContactProviderEnum.Microsoft,
                });
            } else {
                return await contactsApi.disconnectCloudProvider({
                    contactsProvider: cloudProvider === CloudContactProviderEnum.Google
                        ? CloudContactProviderEnum.Google
                        : CloudContactProviderEnum.Microsoft,
                });
            }
        } catch (e) {
            console.warn(e);
        }
        onDisconnectClick();
    };

    return (
        <div className={'c-cloud-account-info'}>
            <div className={'c-cloud-account-info__account-action'}>
                <div className={'c-cloud-account-info__cloud-account-type'}>
                    <img
                        src={icon}
                        alt={altLabel}
                    />
                    <Typography
                        className={'c-cloud-account-info__title'}
                        tagName='div'
                        token={TypographyToken.UiFormsLabelSm}
                        text={
                            cloudProvider === CloudContactProviderEnum.Google
                                ? t('import-type-google')
                                : t('import-type-microsoft')
                        }
                    />
                </div>
                <div className={'c-cloud-account-info__cloud-action-buttons'}>
                    <NdsButton
                        size={SizesEnums.XSMALL}
                        buttonStyle={NdsButtonStylesEnum.ALT}
                        buttonType={NdsButtonTypesEnum.PRIMARY}
                        leftIcon='fa-solid-rotate'
                        label={t('sync-button')}
                        onClick={() => disconnectOrSyncContacts(ActionTypeEnum.Sync)}
                    />

                    <NdsButton
                        size={SizesEnums.XSMALL}
                        buttonStyle={NdsButtonStylesEnum.ALT}
                        buttonType={NdsButtonTypesEnum.SECONDARY}
                        leftIcon='fa-solid-link-slash'
                        label={t('disconnect-button')}
                        onClick={() => disconnectOrSyncContacts(ActionTypeEnum.Disconnect)}
                    />
                </div>
            </div>
            <Typography
                className={'c-cloud-account-info__last-sync'}
                tagName='div'
                token={TypographyToken.UiFormsLabelXs}
                text={`${t('last-synced')}:  ${DateUtil.format(
                    cloudStatus.lastSuccessfulSync,
                    'dd/MM/yyyy HH:mm',
                )}`}
            />
        </div>
    );
}

export default CloudAccountInfo;
