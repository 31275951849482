import { useContext } from 'react';
import { OverlayContext } from './overlay.context';

export const useOverlayContext = () => {
    return useContext(OverlayContext);
};

export const useShowNotification = () => {
    const ctx = useContext(OverlayContext);

    return ctx.showNotification;
};
