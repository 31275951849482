export const BaseRoutes = {
    Index: '/',
    Contacts: '/contacts',
    ContactGroups: '/contact-groups',
    Profile: '/profile',
    TeamsPortal: '/teams-portal',
    DocumentPortal: '/document-portal',
    SignerPortal: '/signer-portal',
    TemplatePortal: '/template-portal',
    Package: '/package',
    Template: '/template',
    NotFound: '/not-found',
    NotFoundSettings: '/not-found-settings',
    Login: '/login',
    Register: '/register',
    RegisterCloud: '/register-cloud',
    LostPassword: '/lost-password',
    ResetPassword: '/reset-password',
    ConvertLocalAccountToCloud: '/convert-local-to-cloud',
    Example: '/example',
    Settings: '/settings',
};

export const NestedRoutes = {
    Users: 'users',
    Teams: 'teams',
    Workspaces: 'workspaces',
    UserGroups: 'user-groups',
    Documents: 'documents',
    Flow: 'flow',
    Processing: 'processing',
    DocumentGroups: 'document-groups',
    NotFound: 'not-found',
};

export const RouteSuffix = { IdParam: ':id' };
