import { PropsWithChildren, ReactNode, useContext, useEffect, useId, useRef } from 'react';
import { OverlayContext } from '../overlay.context';
import { ShowPanelReturn } from '../overlay.types';

export interface PanelPortalProps extends PropsWithChildren {
    visible?: boolean;
    onClose: () => void;
    footer?: ReactNode;
}

/** @deprecated */
export const PanelPortal = ({ visible, children, footer, onClose }: PanelPortalProps) => {
    const id = useId();
    const { showPanel } = useContext(OverlayContext);
    const closePanel = useRef<ShowPanelReturn>();

    useEffect(() => {
        if (visible) {
            closePanel.current = showPanel(children, {
                id,
                footer,
                onClose,
            });
        } else {
            closePanel.current?.();
        }
    },
    [
        visible,
        children,
        footer,
        onClose,
        showPanel,
        id,
    ]);

    return null;
};
