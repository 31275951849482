import { LanguageDataLabel } from '~models';

export enum FlowStatusEnum {
    Draft = 'DRAFT',
    Ready = 'READY',
    Processing = 'PROCESSING',
    ProcessingFailed = 'PROCESSING_FAILED',
    Pending = 'PENDING',
    PendingApproval = 'PENDING_APPROVAL',
    PendingFormFill = 'PENDING_FORMFILL',
    PendingSigning = 'PENDING_SIGNING',
    Revoked = 'REVOKED',
    Rejected = 'REJECTED',
    Expired = 'EXPIRED',
    Received = 'RECEIVED',
    Signed = 'SIGNED',
    Finished = 'FINISHED',
    Archived = 'ARCHIVED',
    InProgress = 'INPROGRESS',
    Ending = 'ENDING',
    Failed = 'FAILED',
    Skipped = 'SKIPPED',
    Refused = 'REFUSED',
    All = '*',
}

export const FlowStatusI18n: Record<FlowStatusEnum, LanguageDataLabel<'flow'>> = {
    [FlowStatusEnum.Draft]: 'status-draft',
    [FlowStatusEnum.Ready]: 'status-ready',
    [FlowStatusEnum.Processing]: 'status-processing',
    [FlowStatusEnum.ProcessingFailed]: 'status-processing-failed',
    [FlowStatusEnum.Pending]: 'status-pending',
    [FlowStatusEnum.PendingApproval]: 'status-pending-approval',
    [FlowStatusEnum.PendingFormFill]: 'status-pending-formfill',
    [FlowStatusEnum.PendingSigning]: 'status-pending-signing',
    [FlowStatusEnum.Revoked]: 'status-revoked',
    [FlowStatusEnum.Rejected]: 'status-rejected',
    [FlowStatusEnum.Expired]: 'status-expired',
    [FlowStatusEnum.Received]: 'status-received',
    [FlowStatusEnum.Signed]: 'status-finished',
    [FlowStatusEnum.Finished]: 'status-finished',
    [FlowStatusEnum.Archived]: 'status-archived',
    [FlowStatusEnum.InProgress]: 'status-inprogress',
    [FlowStatusEnum.Ending]: 'status-ending',
    [FlowStatusEnum.Failed]: 'status-failed',
    [FlowStatusEnum.Skipped]: 'status-skipped',
    [FlowStatusEnum.Refused]: 'status-refused',
    [FlowStatusEnum.All]: 'status-all',
};