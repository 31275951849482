import { NdsButton, NdsIconFont } from '@gonitro/rcl';
import { BkgModesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import MenuDropdown from '~components/menu-dropdown/menu-dropdown';
import MenuGroup from '~components/menu-dropdown/menu-group/menu-group';
import MenuItem from '~components/menu-dropdown/menu-item/menu-item';
import { ActorType } from '~constants';
import { useTranslation } from '~contexts/i18n';
import { Substitute } from '~models/user-management.models';
import './substitute-item.scss';


export interface SubstituteItemProps {
    substitute: Substitute;
    handleEditSubstitute: (sub: Substitute) => void;
    handleDeleteSubstitute: (sub: Substitute) => void;
}

const SubstituteItem = ({ substitute, handleEditSubstitute, handleDeleteSubstitute }: SubstituteItemProps) => {

    const { t } = useTranslation('user-management');

    return (
        <div className='c-added-substitute-item' key={substitute.id}>
            <div className='c-added-substitute-item__substitute-name'>
                {substitute.displayName}
            </div>
            <div className='c-added-substitute-item__icon-container'>
                <div className='c-added-substitute-item__actor-icons'>
                    {substitute.actionTypes.includes(ActorType.Approver) &&
                        <NdsIconFont className='c-added-substitute-item__approver-icon' fontName='fa-solid-circle-check' />}
                    {substitute.actionTypes.includes(ActorType.Signer) &&
                        <NdsIconFont className='c-added-substitute-item__sign-icon' fontName='fa-solid-pen-nib' />}
                    {substitute.actionTypes.includes(ActorType.FormFiller) &&
                        <NdsIconFont className='c-added-substitute-item__form-filler-icon' fontName='fa-solid-input-text' />}
                    {substitute.actionTypes.includes(ActorType.Receiver) &&
                        <NdsIconFont className='c-added-substitute-item__receiver-icon' fontName='fa-solid-eye' />}
                </div>
                <div className='c-added-substitute-item__more-icon'>
                    <MenuDropdown
                        button={(toggleDropdown => (<NdsButton
                            bgMode={BkgModesEnum.LIGHT}
                            size={SizesEnums.XSMALL}
                            left-icon='fa-solid-ellipsis-vertical'
                            slot='button'
                            button-type='neutral'
                            button-style='alt'
                            onClick={toggleDropdown}
                        />))}
                    >
                        <MenuGroup>
                            <MenuItem
                                primaryText={t('out-of-office-edit')}
                                onClick={() => handleEditSubstitute(substitute)}
                                icon='fa-solid-edit'
                            />
                            <MenuItem
                                primaryText={t('out-of-office-delete')}
                                onClick={() => handleDeleteSubstitute(substitute)}
                                icon='fa-solid-trash'
                            />
                        </MenuGroup>
                    </MenuDropdown>
                </div>
            </div>
        </div>);

};

export default SubstituteItem;
