import { useCallback, useContext, useMemo } from 'react';
import { DocumentGroupPermission, Permission } from '~constants';
import { AuthContext } from './auth.context';
import { DocumentGroup } from './auth.types';

export const useAuthContext = () => {
    return useContext(AuthContext)!;
};

export const useUserInfo = () => {
    const { info } = useAuthContext();

    return info;
};

/**
 *
 * @param {Permission} permission
 * @returns {boolean}
 */
export const useHasPermission = (permission: Permission): boolean => {
    const { permissions } = useAuthContext();

    return permissions.includes(permission);
};

export const useHasPermissionFunc = () => {
    const { permissions } = useAuthContext();

    return useCallback((permission: Permission) => {
        return permissions.includes(permission);
    }, [permissions]);
};

/**
 * Experimental hook that checks list of permissions, for more complex usages (e.g. like further mapping in loop)
 * Normally useHasPermissionFunc should be enough
 * do not pass list directly in component - define it outside of component function
 * @see useHasPermissionFunc
 * @param {Permission[]} list
 * @returns {Partial<Record<Permission, boolean>>}
 */
export const useHasPermissionBulk = (list: Permission[]): Partial<Record<Permission, boolean>> => {
    const { permissions } = useAuthContext();

    return useMemo(() => {
        const obj: Partial<Record<Permission, boolean>> = {};
        for (const perm of list) {
            obj[perm] = permissions.includes(perm);
        }

        return obj;
    }, [list, permissions]);
};

export const useLogin = () => {
    const { loginUser } = useAuthContext();

    return loginUser;
};

export const useLogout = () => {
    const { logoutUser } = useAuthContext();

    return logoutUser;
};

export const useIsAuthenticated = () => {
    return useAuthContext().isAuthenticated;
};

export const useDocumentGroups = () => {
    const { documentGroups } = useContext(AuthContext)!;

    return documentGroups;
};
export const useHasDocumentGroupPermissionFunc = () => {
    const { documentGroups } = useContext(AuthContext)!;

    return useCallback((group: DocumentGroup | number, permission: DocumentGroupPermission) => {
        if (typeof group === 'number') {
            const found = documentGroups.find(g => g.documentGroupId === group);

            if (!found) {
                console.warn(`Tried to find document group with id ${group}`);
            }
            group = found!;
        }

        return group.permissions.includes(permission) ?? false;
    }, [documentGroups]);
};
