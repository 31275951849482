import { PropsWithChildren, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { Permission } from '~constants';
import { useHasPermissionBulk } from '~contexts/auth';

export interface IfHasPermissionProps extends PropsWithChildren {
    permission: Permission | Permission[];
    noPermissionRender?: ReactNode;
    noPermissionRedirectUrl?: string;
}

export function IfHasPermission({
    children,
    permission,
    noPermissionRedirectUrl,
    noPermissionRender,
}: IfHasPermissionProps) {
    const hasPermission = Object.values(useHasPermissionBulk(
        Array.isArray(permission) ? permission : [permission],
    )).every(Boolean);

    if (hasPermission && children) {
        return <>{children}</>;
    } else if (noPermissionRedirectUrl) {
        return <Navigate to={{ pathname: noPermissionRedirectUrl }} state={{ issuedPermission: permission }} />;
    } else if (noPermissionRender != null) {
        return <>{noPermissionRender}</>;
    }

    return null;
}