import { Modal, ModalContent, ModalFooter, NdsButton, NdsIconFont } from '@gonitro/rcl';
import { NdsButtonTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import { Input } from '@progress/kendo-react-inputs';
import { useCallback, useState } from 'react';
import { useFlowContext, useSetFlowState } from '~contexts/flow';
import { FlowActionType } from '~contexts/flow/flow.types';
import { useCurrentLanguage, useTranslation } from '~contexts/i18n';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { useSigningTypes } from '~contexts/settings';
import { FlowSigningFieldModel, FlowStakeholderModel, SigningType, StakeholderType } from '~models';
import FlowSigningMethodCard from './flow-signing-method-card/flowSigningMethodCard';
import './flowSigningMethodModal.scss';

export interface FlowSigningMethodModalProps {
    onCloseClick: () => void;
    field: FlowSigningFieldModel;
    openSigningMethodModal: boolean;
}

// TODO: put this in some util or something...

function getStakeholderVisualisation(stakeholder: FlowStakeholderModel) {
    let visualisation = '-';

    if (stakeholder.type === StakeholderType.Person) {
        if (stakeholder.emailAddress != null) {
            visualisation = stakeholder.emailAddress;
        } else if (stakeholder.firstName != null || stakeholder.lastName != null) {
            visualisation = `${stakeholder.firstName ? stakeholder.firstName : ''} ${stakeholder.lastName}`;
        }
    } else if (stakeholder.type === StakeholderType.Group) {
        if (stakeholder.name != null) {
            visualisation = stakeholder.name;
        }
    }

    return visualisation;
}

interface SigningMethodContent {
    signingMethod: SigningType,
    keyPair: any,
    isSelected: boolean,
    isDisabled: boolean,
}

function isSigningMethodDisabled(signingMethod: string, selectedSigningMethodsLength: number, isServerSignSelected: boolean): boolean {
    const behaviour = signingMethod.split(':')[0];
    let disabled = false;

    if (behaviour.toUpperCase() === 'SERVER' && selectedSigningMethodsLength > 0 && !isServerSignSelected) {
        disabled = true;
    } else if (behaviour.toUpperCase() !== 'SERVER' && isServerSignSelected) {
        disabled = true;
    } // TODO: add other cases

    return disabled;
}

interface SigningMethodContainerProps {
    signingMethodsContent: SigningMethodContent[];
    setSigningMethods: (methods: any) => void;
    filter?: string;
}

function SigningMethodContainer({ signingMethodsContent, setSigningMethods, filter }: SigningMethodContainerProps) {
    const [state, setState] = useState({});

    return (
        <div className={'c-flow-signing-method-modal-signing-methods-container'}>
            {signingMethodsContent && signingMethodsContent.map((signingMethodInfo, index) => {

                return (
                    <div className={'c-flow-signing-method-modal-signing-methods-container__item'} key={index}>
                        <FlowSigningMethodCard
                            isSelected={signingMethodInfo.isSelected}
                            isDisabled={signingMethodInfo.isDisabled}
                            signingMethod={signingMethodInfo.signingMethod}
                            onSigningMethodClick={() => {
                                const sCopy = signingMethodsContent.slice();

                                sCopy[index].isSelected = !sCopy[index].isSelected;

                                const isServerSignSelected = signingMethodsContent.findIndex((signingMethodContent) => {
                                    if (signingMethodContent.isSelected) {
                                        const behaviour = signingMethodContent.signingMethod.name.split(':')[0];

                                        return behaviour.toUpperCase() === 'SERVER';
                                    }

                                    return false;
                                }) > -1;

                                sCopy.forEach((s) => {
                                    s.isDisabled = isSigningMethodDisabled(s.signingMethod.name, sCopy.filter(x => x.isSelected).length, isServerSignSelected);
                                });

                                setSigningMethods(sCopy);
                                setState({ ...state }); // TODO: check why a force rerender is needed here...?
                            }}
                        />
                    </div>
                );
            })}
        </div>
    );
}

function FlowSigningMethodModal({ onCloseClick, field, openSigningMethodModal }: FlowSigningMethodModalProps) {
    const { t: tBase } = useTranslation('base');

    const language = useCurrentLanguage();
    const signingTypes = useSigningTypes();

    const { flowState: { stakeholders } } = useFlowContext();
    const stakeholder = stakeholders.find(el => el.localId === field.stakeholderLocalId);
    const setFlowState = useSetFlowState();

    const [signingMethodsContent, setSelectedMethods] = useState<SigningMethodContent[]>(() => {
        const selectedSigningMethods = field.signingMethods;
        const sorted = signingTypes.filter((s) => s.isEnabled).sort((a, b) => {
            const compareA = a?.displayNames[language.languageCode] ?? '';
            const compareB = b?.displayNames[language.languageCode] ?? '';

            return compareA.localeCompare(compareB);
        });

        return sorted.map((i) => {
            const isServerSignSelected = selectedSigningMethods.findIndex((signingMethod) => {
                const behaviour = signingMethod.name.split(':')[0];

                return behaviour.toUpperCase() === 'SERVER';
            }) > -1;
            const index = selectedSigningMethods.findIndex((signingMethod) => signingMethod?.name.toUpperCase() === i?.name.toUpperCase());
            const keyPair = index > -1 ? selectedSigningMethods[index].keyPair : null;
            const isSelected =  index > -1;
            const isDisabled = isSigningMethodDisabled(i.name, selectedSigningMethods.length, isServerSignSelected);

            return {
                signingMethod: i,
                keyPair,
                isSelected,
                isDisabled,
            };
        });
    });

    const onConfirmClick = useCallback(() => {
        const signingMethods = signingMethodsContent.filter(x => x.isSelected).map(x => {
            return {
                name: x.signingMethod.name.toUpperCase(),
                keyPair: x.keyPair,
            };
        });

        setFlowState(FlowActionType.UpdateElement, {
            ...field,
            signingMethods,
        });
        onCloseClick();
    }, [field, onCloseClick, setFlowState, signingMethodsContent]);

    return (
        <OverlayPortal id={`signing-method-modal-${field?.localId}`} type={'modal'} visible={openSigningMethodModal}>
            {({ close }) => (
                <Modal
                    withClose
                    headerLabel={'Add signing methods'}
                    size={SizesEnums.LARGE}
                    className={'c-flow-signing-method-modal'}
                    onCloseClick={() => {
                        onCloseClick();
                        close();
                    }}
                    renderContent={
                        <ModalContent className={'c-flow-signing-method-modal__content'}>
                            <div className={'c-flow-signing-method-modal__content-header'}>
                                <div className={'c-flow-signing-method-modal__content-header-recipient'}>
                                    <div className={'c-flow-signing-method-modal__content-header-recipient__icon'}>
                                        {stakeholder?.type === StakeholderType.Person && (
                                            <NdsIconFont fontName={'fa-solid-user'} />
                                        )}
                                        {stakeholder?.type === StakeholderType.Group && (
                                            <NdsIconFont fontName={'fa-regular-users'} />
                                        )}
                                    </div>
                                    <div className={'c-flow-signing-method-modal__content-header-recipient__name'}>
                                        {stakeholder && getStakeholderVisualisation(stakeholder)}
                                    </div>
                                </div>
                                <div className={'c-flow-signing-method-modal__content-search'}>
                                    <Input
                                        placeholder={'Search'}
                                        prefix={'fa-regular-search'}
                                    />
                                </div>
                            </div>
                            <SigningMethodContainer signingMethodsContent={signingMethodsContent} setSigningMethods={setSelectedMethods}/>
                        </ModalContent>
                    }
                    renderFooter={
                        <ModalFooter>
                            <NdsButton
                                size={SizesEnums.SMALL}
                                label={tBase('cancel')}
                                buttonType={NdsButtonTypesEnum.NEUTRAL}
                                onClick={() => {
                                    onCloseClick();
                                    close();
                                }}
                            />
                            <NdsButton
                                size={SizesEnums.SMALL}
                                label={tBase('add')}
                                onClick={() => {
                                    onConfirmClick();
                                    close();
                                }}
                            />
                        </ModalFooter>
                    }
                />
            )}
        </OverlayPortal>
    );
}

export default FlowSigningMethodModal;
