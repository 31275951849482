import { isString } from '@progress/kendo-data-query/dist/npm/utils';

export namespace FileUtils {
    const loadedScripts: Record<string, boolean> = {};

    export const validateFileType = (file: File, allowedTypes: string[]) => {
        return allowedTypes.some((type) =>
            (type.startsWith('.') && file.name.endsWith(type)) || file.type === type);
    };

    export const loadScript = (id: string, url: string, dataset: DOMStringMap = {}, onLoad?: () => void) => {
        if (loadedScripts[id]) {
            return;
        }

        const scriptTag = document.createElement('script');

        scriptTag.id = id;
        scriptTag.type = 'text/javascript';
        for (const [key, value] of Object.entries(dataset)) {
            scriptTag.dataset[key] = value;
        }
        onLoad && (scriptTag.onload = onLoad);
        scriptTag.setAttribute('src', url);
        document.body.appendChild(scriptTag);

        loadedScripts[id] = true;
    };

    export enum FileSizeUnit {
        B = 0,
        kB = 1,
        MB = 2,
        GB = 3,
        TB = 4, // just to never ever touch it again
    }

    const FileSizeUnitSuffix = Object.values(FileSizeUnit).filter(el => !isString(el));

    export const convertFileSize = (size: number, targetUnit: FileSizeUnit, sourceUnit: FileSizeUnit = FileSizeUnit.B) => {
        let ret = size / Math.pow(2, 10 * (targetUnit - sourceUnit));

        if (sourceUnit > targetUnit) {
            ret = Math.round(ret); // when multiplying it's fine to round the result, for dividing it's too risky to do anything
        }

        return ret;
    };

    export const fileSizeToString = (size: number, sourceUnit: FileSizeUnit = FileSizeUnit.B) => {
        if (size === 0) {
            return '0B';
        }

        size = convertFileSize(size, FileSizeUnit.B, sourceUnit);

        const i = Math.min(Math.floor(Math.log(size) / Math.log(1024)), FileSizeUnitSuffix.length - 1); // calculate size suffix position
        const fileSize = convertFileSize(size, i, FileSizeUnit.B).toFixed(2); // file size calculation for suffix

        return `${+(fileSize)}${FileSizeUnit[i]}`;
    };
}
