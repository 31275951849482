import classNames from 'classnames';
import { DragEvent, HTMLAttributes, MutableRefObject, useCallback, useRef, useState } from 'react';
import { FileUploadRef } from '../file-upload/fileUpload';
import './fileDropzone.scss';

export interface FileDropzoneProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onDrop' | 'onDragEnter' | 'onDragLeave' | 'onDragOver' | 'onDragStart'> {
    multiple?: boolean;
    inputRef: MutableRefObject<FileUploadRef | undefined>;
}

function FileDropzone({ children, className, inputRef, ...props }: FileDropzoneProps) {
    const ref = useRef<HTMLDivElement>(null);
    const counter = useRef(0);
    const [isDragOver, setIsDragOver] = useState(false);

    const onDrop = useCallback((event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        counter.current = 0;
        setIsDragOver(false);

        if (!event.dataTransfer?.files) {
            return;
        }

        inputRef.current?._processFiles([...event.dataTransfer.files]);

    }, [inputRef]);

    const onDragEnter = useCallback((event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        if (!counter.current) {
            setIsDragOver(true);
        }
        counter.current++;
    }, []);
    const onDragLeave = useCallback((event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();

        counter.current--;

        if (!counter.current) {
            setIsDragOver(false);
        }
    }, []);
    const onDragOver = useCallback((event: DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        event.dataTransfer.dropEffect = 'copy';
    }, []);

    return (

        <div
            {...props}
            onDrop={onDrop}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDragOver={onDragOver}
            className={classNames(
                'c-file-dropzone',
                { 'c-file-dropzone--dragover': isDragOver },
                className,
            )}
            ref={ref}
        >
            {children}
        </div>
    );
}

export default FileDropzone;