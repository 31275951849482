import { useCallback, useMemo } from 'react';
import { ActorType } from '~constants';
import { useFlowContext, useFlowState, useSetFlowState } from '~contexts/flow';
import { useUndecidedStakeholderId } from '~contexts/flow/flow.hooks/stakeholder.flow.hooks';
import { FlowActionType } from '~contexts/flow/flow.types';
import { FlowUtils } from '~contexts/flow/flow.utils';
import { StringUtils } from '~lib';
import { FlowActorModel, Guid } from '~models';

export const useActorElements = (actor: FlowActorModel) => {
    const { elements } = useFlowState();

    return useMemo(() => {
        if (actor.actorType === ActorType.Receiver || actor.actorType === ActorType.Approver) {
            return [];
        }

        const elIds = actor.elements;

        return elements.filter(el => elIds.includes(el.localId));
    }, [elements, actor]);
};

type AddActorCallback = (type: ActorType, stakeholderLocalId: Guid | undefined, addIndex: number | 'last' | 'new', optionals?: {
    localId?: Guid,
    elements?: Guid[],
    id?: Guid
}) => {
    actorId: Guid,
    groupIndex: number,
    groupOffset: number,
    actorExists: boolean
}

export const useAddActor = () => {
    const context = useFlowContext();
    const setFlowState = useSetFlowState();
    const undecidedId = useUndecidedStakeholderId();

    return useCallback<AddActorCallback>((type: ActorType, stakeholderLocalId: Guid | undefined, addIndex: number | 'last' | 'new', optionals?: {
        localId?: Guid,
        elements?: Guid[],
        id?: Guid,
    }) => {
        const flow = context.flowState.flow;
        const localId = optionals?.localId ?? StringUtils.guid();

        let index: number = 0;
        let newGroup = false;

        const indexes = FlowUtils.findFlowActorGroupIndexes(flow, type);

        if (addIndex === 'new') {
            index = indexes.asNew;
            newGroup = true;
        } else if (addIndex === 'last') {
            if (indexes.last > -1) {
                index = indexes.last;
            } else {
                newGroup = true;
                index = indexes.asNew;
            }
        } else {
            if (addIndex === indexes.asNew) {
                index = indexes.asNew;
                newGroup = true;
            } else {
                if (addIndex < indexes.first || addIndex > indexes.last) {
                    throw new Error(`Wrong index provided to add new actor of type '${type}'. Allowed indexes are from range ${indexes.first} - ${indexes.last}`);
                }
                index = addIndex;
            }
        }

        if (!newGroup) {
            const existing = FlowUtils
                .findStakeholderActors(flow, stakeholderLocalId ?? undecidedId)
                .find(el => el.stepIndex === index && el.actor.actorType === type);

            if (existing) {
                // console.warn('tried to add new actor but there is existing actor in step for given stakeholder'); // left in case of debugging

                if ((type === ActorType.Signer || type === ActorType.FormFiller) && optionals?.elements) {
                    setFlowState(FlowActionType.AddActorElements, {
                        actorId: existing.actor.localId,
                        elementIds: optionals.elements,
                    });
                }

                return {
                    actorId: existing.actor.localId,
                    groupIndex: index,
                    groupOffset: indexes.first < 0 ? index : indexes.first,
                    actorExists: true,
                };
            }
        }

        switch (type) {
            case ActorType.Approver:
            case ActorType.Receiver:
                setFlowState(FlowActionType.AddActor, {
                    groupIndex: index,
                    newGroup,
                    actor: {
                        localId,
                        actorType: type,
                        id: optionals?.id,
                        stakeholderLocalId,
                    },
                });

                break;
            case ActorType.FormFiller:
            case ActorType.Signer:
                setFlowState(FlowActionType.AddActor, {
                    groupIndex: index,
                    newGroup,
                    actor: {
                        localId,
                        actorType: type,
                        stakeholderLocalId,
                        id: optionals?.id,
                        elements: optionals?.elements ?? [],
                    },
                });

                break;
        }

        return {
            actorId: localId,
            groupIndex: index,
            groupOffset: indexes.first < 0 ? index : indexes.first,
            actorExists: false,
        };

    }, [context.flowState, setFlowState, undecidedId]);
};

export const useGetActor = () => {
    const { flow } = useFlowState();

    return useCallback((actorId: Guid) => {
        return FlowUtils.findActor(flow, actorId);
    }, [flow]);
};

type DeleteActorCallback = (actorId: Guid, withElements?: boolean) => null | {
    actor: FlowActorModel,
    stepIndex: number,
    actorIndex: number,
    isAlone: boolean,
}
export const useDeleteActor = () => {
    const { flow } = useFlowState();
    const setFlowState = useSetFlowState();

    return useCallback<DeleteActorCallback>((actorId, withElements = false) => {
        const foundActor = FlowUtils.findActor(flow, actorId);

        if (!foundActor) {

            return null;
        }

        if (!withElements) {
            setFlowState(FlowActionType.DeleteActor, { actorId: actorId });

            return foundActor;
        } else if ('elements' in foundActor.actor && foundActor.actor.elements.length) {

            for (const fieldId of foundActor.actor.elements) {
                alert('todo remove element ' + fieldId);
            }
        }

        return foundActor;

    }, [flow, setFlowState]);
};

export const useMoveActor = () => {
    const { flow } = useFlowState();
    const setFlowState = useSetFlowState();


    return useCallback((actorId: Guid, targetIndex: number | 'new', useRelativeIndex = false) => {
        const foundActor = FlowUtils.findActor(flow, actorId);

        if (!foundActor) {
            return null;
        }
        const { actor, isAlone: wasAlone, stepIndex } = foundActor;
        const indexes = FlowUtils.findFlowActorGroupIndexes(flow, actor.actorType);

        if (wasAlone && targetIndex === 'new' && stepIndex === indexes.last) {
            return null;
        }

        setFlowState(FlowActionType.DeleteActor, { actorId: actorId });

        let groupIndex: number;

        if (targetIndex === 'new') {
            groupIndex = indexes.asNew - (wasAlone ? 1 : 0);
        } else {
            targetIndex += useRelativeIndex ? Math.max(indexes.first, 0) : 0;

            if (targetIndex < stepIndex) {
                groupIndex = targetIndex;
            } else {
                groupIndex = targetIndex - (wasAlone ? 1 : 0);
            }
        }

        setFlowState(FlowActionType.AddActor, {
            groupIndex,
            newGroup: targetIndex === 'new',
            actor,
        });

        return {
            wasAlone,
            groupIndex,
            groupOffset: indexes.first < 0 ? groupIndex : indexes.first,
        };
    }, [flow, setFlowState]);
};

export const useMoveActorElement = () => {
    const { flow } = useFlowState();
    const setFlowState = useSetFlowState();
    const moveActor = useMoveActor();

    return useCallback((actorId: Guid, elementId: Guid, targetIndex: number | 'new', useRelativeIndex = false) => {
        const foundActor = FlowUtils.findActor(flow, actorId);

        if (!foundActor) {
            return null;
        }

        const { actor, isAlone, stepIndex } = foundActor;

        if (actor.actorType === ActorType.Approver || actor.actorType === ActorType.Receiver) {
            return null;
        }

        const isElementAlone = isAlone && actor.elements.length === 1;

        if (actor.elements.length === 1 && actor.elements[0] === elementId) {
            return moveActor(actorId, targetIndex, useRelativeIndex);
        }

        const indexes = FlowUtils.findFlowActorGroupIndexes(flow, actor.actorType);

        let groupIndex: number;

        if (targetIndex === 'new') {
            groupIndex = indexes.asNew - (isElementAlone ? 1 : 0);
        } else if (targetIndex < stepIndex) {
            groupIndex = targetIndex + (useRelativeIndex ? Math.max(indexes.first, 0) : 0);
        } else {
            groupIndex = targetIndex - (isElementAlone ? 1 : 0) + (useRelativeIndex ? Math.max(indexes.first, 0) : 0);
        }

        setFlowState(FlowActionType.DeleteActorElements, {
            actorId,
            elementIds: [elementId],
        });

        if (targetIndex === 'new') {
            // create new actor
            setFlowState(FlowActionType.AddActor, {
                groupIndex,
                newGroup: targetIndex === 'new',
                actor: {
                    actorType: actor.actorType,
                    stakeholderLocalId: actor.stakeholderLocalId,
                    localId: StringUtils.guid(),
                    elements: [elementId],
                },
            });
        } else {
            const step = flow[groupIndex];

            const exists = step.find((el) => el.actorType === actor.actorType && el.stakeholderLocalId === actor.stakeholderLocalId);

            if (exists) {
                // add field to existing actor
                setFlowState(FlowActionType.AddActorElements, {
                    actorId: exists.localId,
                    elementIds: [elementId],
                });
            } else {
                // create new actor
                setFlowState(FlowActionType.AddActor, {
                    groupIndex,
                    newGroup: false,
                    actor: {
                        actorType: actor.actorType,
                        stakeholderLocalId: actor.stakeholderLocalId,
                        localId: StringUtils.guid(),
                        elements: [elementId],
                    },
                });
            }
        }

        return {
            wasAlone: isElementAlone,
            groupIndex,
            groupOffset: indexes.first < 0 ? groupIndex : indexes.first,
        };
    }, [flow, moveActor, setFlowState]);
};
