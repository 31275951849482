import { SidePanel } from '@gonitro/rcl';
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import { useCallback, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { AuthApi } from '~api/auth.api';
import NavTabs from '~components/nav-tabs';
import Typography, { TypographyToken } from '~components/typography';
import { UserManagementTabsEnum } from '~constants';
import { useApi } from '~contexts/api';
import { AbsencePeriod, UserInfo } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import { useEnhancedForm } from '~hooks/enhanced-form';
import useNavTabs from '~hooks/tabs';
import { Substitute } from '~models/user-management.models';
import AddSignatureTab from './add-signature-tab/add-signature-tab';
import EditProfileFooter from './edit-profile-footer/edit-profile-footer';
import EditProfileTab from './edit-profile-tab/edit-profile-tab';
import OutOfOfficeTab from './out-of-office-tab/out-of-office-tab';
import './edit-profile-panel.scss';

export const editProfilePanelTabs = [
    {
        key: UserManagementTabsEnum.Profile,
        i18n: {
            ns: 'user-management',
            key: 'user-management-edit-user-panel-tab-profile',
        },
    },
    {
        key: UserManagementTabsEnum.Signature,
        i18n: {
            ns: 'user-management',
            key: 'user-management-edit-user-panel-tab-signature',
        },
    },
    {
        key: UserManagementTabsEnum.OutOfOffice,
        i18n: {
            ns: 'user-management',
            key: 'user-management-edit-user-panel-tab-out-of-office',
        },
    },
];

export interface EditProfilePanelProps {
    userProfile: UserInfo;
    absences: AbsencePeriod[];
    closePanel: () => void;
}

export type FormValues = {
    cloudProviderName: string;
    company: string;
    defaultPhoneNumberCountry: string;
    emailAddress: string;
    firstName: string;
    language: string;
    lastName: string;
    startDate: Date;
    startTime: Date;
    endDate: Date;
    endTime: Date;
    formfiller: boolean;
    approver: boolean;
    signer: boolean;
    receiver: boolean;
    reassign: boolean;
};

const EditProfilePanel = ({ userProfile, absences, closePanel }: EditProfilePanelProps) => {
    const formRef = useRef<HTMLFormElement>(null);
    const authApi = useApi(AuthApi);
    const { t } = useTranslation('user-management');

    const [signatureImage, setSignatureImage] = useState<string>();
    const [croppedImage, setCroppedImage] = useState<string>();
    const [isSignatureEditMode, setSignatureEditMode] = useState(false);
    const [isToggleEnabled, setToggleEnabled] = useState(false);
    const [substitutes, setSubstitutes] = useState<Substitute[]>([]);

    const { customizationSettings: { isOutOfOfficeEnabled } } = useSettings();
    const filterTabConditions = {
        [UserManagementTabsEnum.OutOfOffice]: isOutOfOfficeEnabled,
        // Add more conditions here
    };
    const {
        currentTab,
        filteredTabs,
        selectTabHandler,
    } = useNavTabs(editProfilePanelTabs, filterTabConditions);


    const formMethods = useEnhancedForm<FormValues>({
        mode: 'all',
        defaultValues: async () => {
            const userExtended = {
                cloudProviderName: userProfile.cloudProviderName,
                company: userProfile.company,
                defaultPhoneNumberCountry: userProfile.defaultPhoneNumberCountry,
                emailAddress: userProfile.emailAddress,
                firstName: userProfile.firstName,
                language: userProfile.language,
                lastName: userProfile.lastName,
                startDate: new Date(absences[0]?.startDate || DateTime.now().toJSDate()),
                startTime: new Date(absences[0]?.startDate || DateTime.now().plus({ minutes: 5 }).toJSDate()),
                endDate: new Date(absences[0]?.endDate || DateTime.now().toJSDate()),
                endTime: new Date(absences[0]?.endDate || DateTime.now().plus({
                    hours: 1,
                    minutes: 5,
                }).toJSDate()),
                formfiller: false,
                approver: false,
                signer: false,
                receiver: false,
                reassign: false,
            };

            setSignatureImage(userProfile.signatureImageB64);
            setSubstitutes(absences[0]?.substitutes || []);
            setToggleEnabled(absences.length > 0);

            return userExtended;
        },
    });

    const { handleSubmit, formState: { errors } } = formMethods;


    const onSubmitCallback = useCallback(({ ...data }: FormValues) => {

        const combineDateAndTime = (date: Date, time: Date, timeZoneOffset = '+02:00') => {
            const startDateTime = DateTime.fromJSDate(date);
            const startTimeTime = DateTime.fromJSDate(time).set({
                year: startDateTime.year,
                month: startDateTime.month,
                day: startDateTime.day,
            });

            const combinedDateTime = startDateTime.set({
                hour: startTimeTime.hour,
                minute: startTimeTime.minute,
                second: startTimeTime.second,
                millisecond: 0,
            });

            const combinedDateTimeWithOffset = combinedDateTime.setZone(`UTC${timeZoneOffset}`);

            return combinedDateTimeWithOffset.toISO({ suppressMilliseconds: true });
        };

        let absencePeriods = [];

        if (isToggleEnabled) {
            absencePeriods = [
                {
                    substitutes: substitutes,
                    startDate: combineDateAndTime(formMethods.getValues().startDate, formMethods.getValues().startTime)!,
                    endDate: combineDateAndTime(formMethods.getValues().endDate, formMethods.getValues().endTime)!,
                    id: absences[0] && absences[0].id,
                },
            ];
        } else {
            absencePeriods = absences.slice(1);
        }

        authApi.updateCurrentUser({
            userProfile: {
                ...data,
                signatureImageB64: croppedImage || signatureImage!,
            },
            absencePeriods: absencePeriods,
        }).then(() => {
            closePanel();
        });
    }, [
        absences,
        authApi,
        isToggleEnabled,
        croppedImage,
        signatureImage,
        substitutes,
        formMethods,
        closePanel,
    ]);

    const handleSignatureOnload = useCallback((image: string) => {
        setSignatureImage(image);
    }, []);

    const handleCroppedImage = useCallback((image: string) => {
        setCroppedImage(image);
    }, []);

    const handleSignatureEditMode = useCallback((isEditMode: boolean) => {
        setSignatureEditMode(isEditMode);
    }, []);

    const handleSignatureContinue = useCallback((sigImage: string) => {
        setSignatureImage(sigImage);
        setSignatureEditMode(false);
    }, []);

    const removeSignature = useCallback(() => {
        setSignatureEditMode(false);
        setSignatureImage(undefined);
    }, []);

    const handleOnSaveClick = useCallback(() => {
        if (!Object.keys(errors).length) {
            formRef.current?.requestSubmit();
        } else {
            //setIsErrorModalOpen(true);
        }
    }, [errors]);

    const groupedErrors = errors && !isEmpty(errors)
        ? {
            [UserManagementTabsEnum.Profile]: !!(errors.firstName || errors.lastName),
            [UserManagementTabsEnum.OutOfOffice]: !!(errors.startDate || errors.startTime || errors.endDate || errors.endTime),
        }
        : null;

    return (
        <SidePanel className='edit-user-panel' onClosePanel={closePanel}>
            {
                <FormProvider {...formMethods} >
                    <form
                        className={'edit-user-panel__form'}
                        onSubmit={handleSubmit(onSubmitCallback)}
                        ref={formRef}
                    >
                        <Typography
                            token={TypographyToken.DesktopHeaderXs}
                            className={'edit-user-panel__title'}
                        >{t('user-management-edit-user-panel-title')}</Typography>
                        <NavTabs
                            navTabs={filteredTabs}
                            selectTabHandler={selectTabHandler}
                            currentTab={currentTab}
                            errors={groupedErrors}
                        />
                        {currentTab.key === UserManagementTabsEnum.Profile && (
                            <EditProfileTab />
                        )}
                        {currentTab.key === UserManagementTabsEnum.OutOfOffice && (
                            <OutOfOfficeTab
                                abscences={absences ?? []}
                                emailAddress={userProfile?.emailAddress}
                                closePanel={closePanel}
                                substitutes={substitutes}
                                setToggleEnabled={setToggleEnabled}
                                setSubstitutes={setSubstitutes}
                                isToggleEnabled={isToggleEnabled}
                            />
                        )}
                        {currentTab.key === UserManagementTabsEnum.Signature && (
                            <AddSignatureTab
                                signatureImage={signatureImage}
                                croppedImage={croppedImage}
                                setCroppedImage={handleCroppedImage}
                                isSignatureEditMode={isSignatureEditMode}
                                setSignatureEditMode={handleSignatureEditMode}
                                closePanel={closePanel}
                                handleSignatureContinue={handleSignatureContinue}
                                handleSignatureOnload={handleSignatureOnload}
                                removeSignature={removeSignature}
                            />
                        )}
                        <EditProfileFooter
                            closePanel={() => {
                                closePanel();
                            }}
                            handleOnSaveClick={handleOnSaveClick}
                        />
                    </form>
                </FormProvider>}
        </SidePanel>
    );
};

export default EditProfilePanel;
