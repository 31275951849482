import { createContext } from 'react';
import {
    HideModalFunction,
    HideOverlayElementFunction,
    HidePanelFunction, InternalNotificationOptions,
    ModalElement,
    OverlayElement,
    PanelElement,
    ShowModalFunction,
    ShowNotificationFunction,
    ShowOverlayElementFunction,
    ShowPanelFunction,
} from './overlay.types';

export type OverlayContextType = {
    modals: ModalElement[];
    panel?: PanelElement;
    elements: OverlayElement[];
    notifications: InternalNotificationOptions[];
    /** @deprecated */
    showModal: ShowModalFunction;
    /** @deprecated */
    hideModal: HideModalFunction;
    /** @deprecated */
    showPanel: ShowPanelFunction;
    /** @deprecated */
    hidePanel: HidePanelFunction;
    showElement: ShowOverlayElementFunction;
    hideElement: HideOverlayElementFunction;
    showNotification: ShowNotificationFunction;
}

export const OverlayContext = createContext<OverlayContextType>({} as OverlayContextType);
