import { LanguageDataLabel } from '~models';

export enum ImportType {
    CSV =  'CSV',
    Google = 'Google',
    Microsoft = 'Microsoft',
}

export const ImportTypeI18n: Record<ImportType, LanguageDataLabel<'contacts'>> = {
    [ImportType.CSV]: 'import-type-csv',
    [ImportType.Google]: 'import-type-google',
    [ImportType.Microsoft]: 'import-type-microsoft',
};

export const ImportTypeIcons: Record<ImportType, string> = {
    [ImportType.CSV]: 'fa-regular-file-arrow-up',
    [ImportType.Google]: 'fa-brands-google',
    [ImportType.Microsoft]: 'fa-brands-windows',
};