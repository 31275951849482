import { NormalizedDragEvent } from '@progress/kendo-react-common';
import { RefObject, useCallback } from 'react';
import { CommonUtils } from '~lib';
import { Offset, Size } from '~models';
import { DocumentViewerPageObjectResizeDirection, DocumentViewerPagePosition } from '../types';

export const useDocumentViewerPageObjectResizeCallback = (
    ref: RefObject<HTMLDivElement>,
    minSizeParam: Size,
    objectOffset: Offset,
    objectSize: Size,
    keepRatio?: number,
) => {
    return useCallback((
        event: NormalizedDragEvent,
        direction: DocumentViewerPageObjectResizeDirection,
        initialPosition: Offset,
        currentPagePosition: DocumentViewerPagePosition,
        scale: number,
    ) => {
        if (!ref?.current) {
            return;
        }

        if (!direction) {
            return;
        }

        // prepare needed data
        const initialSize: Size = {
            width: CommonUtils.toPx(ref.current.style.width),
            height: CommonUtils.toPx(ref.current.style.height),
        };
        const initialOffset: Offset = {
            top: CommonUtils.toPx(ref.current.style.top),
            left: CommonUtils.toPx(ref.current.style.left),
        };

        const newSize: Size = { ...initialSize };
        const newOffset: Offset = { ...initialOffset };

        const minSize: Size = {
            width: minSizeParam.width * scale,
            height: minSizeParam.height * scale,
        };

        const byMouseTop = event.clientY - initialPosition.top + event.scrollY;
        const byMouseLeft = event.clientX - initialPosition.left + event.scrollX;

        /** LIMITS */
        // vertical limits
        // resizing to top
        const minObjectTop = currentPagePosition.offset.top;
        const maxObjectTop = initialOffset.top + initialSize.height - minSize.height;
        // resizing to bottom
        const minHeightOnPage = minSize.height;
        const maxHeightOnPage = currentPagePosition.size.height + currentPagePosition.offset.top - initialOffset.top;

        // horizontal limits
        // resizing to left
        const minObjectLeft = currentPagePosition.offset.left;
        const maxObjectLeft = initialOffset.left + initialSize.width - minSize.width;
        // resizing to right
        const minWidthOnPage = minSize.width;
        const maxWidthOnPage = currentPagePosition.size.width + currentPagePosition.offset.left - initialOffset.left;


        /** BASIC RESIZING CALCULATIONS */
        // calculate vertical
        switch (direction) {
            case DocumentViewerPageObjectResizeDirection.TopLeft:
            case DocumentViewerPageObjectResizeDirection.TopRight: {

                const newTop = Math.min(Math.max(minObjectTop, byMouseTop) /* check if top isn't above page */, maxObjectTop); //check if top isn't below limit
                const topDifference = newTop - initialOffset.top; // top < 0 when mouse goes up, top > 0 when goes down

                newSize.height = initialSize.height - topDifference;
                newOffset.top = newTop;
                break;
            }
            case DocumentViewerPageObjectResizeDirection.BottomLeft:
            case DocumentViewerPageObjectResizeDirection.BottomRight: {

                const heightDif = byMouseTop - objectOffset.top;
                const newHeight = objectSize.height + heightDif;

                newSize.height = Math.min(Math.max(newHeight, minHeightOnPage), maxHeightOnPage);
                break;
            }
        }
        // calculate horizontal
        switch (direction) {
            case DocumentViewerPageObjectResizeDirection.TopLeft:
            case DocumentViewerPageObjectResizeDirection.BottomLeft: {
                const newLeft = Math.min(
                    Math.max(minObjectLeft, byMouseLeft),
                    maxObjectLeft,
                ); //check if left isn't out of page then check if is not going too much to right
                const leftDifference = newLeft - initialOffset.left; // top < 0 when mouse goes up, top > 0 when goes down

                newSize.width = initialSize.width - leftDifference;
                newOffset.left = newLeft;
                break;
            }
            case DocumentViewerPageObjectResizeDirection.TopRight:
            case DocumentViewerPageObjectResizeDirection.BottomRight: {

                const widthDif = byMouseLeft - objectOffset.left;
                const newWidth = objectSize.width + widthDif;

                newSize.width = Math.min(Math.max(newWidth, minWidthOnPage), maxWidthOnPage);
                break;
            }
        }


        /** KEEP RATIO ADJUSTMENTS */
        // it allows to resize by vertical or horizontal border for better precision
        if (keepRatio) {
            const ratio = keepRatio;

            if (newSize.height / initialSize.height > newSize.width / initialSize.width) { // height changed more than width so adjust width
                const ratioWidth = newSize.height / ratio;

                // mouse vertical movement
                switch (direction) {
                    case DocumentViewerPageObjectResizeDirection.TopRight:
                    case DocumentViewerPageObjectResizeDirection.BottomRight: {
                        if (ratioWidth <= maxWidthOnPage) {
                            newSize.width = ratioWidth;
                        } else {
                            newSize.width = maxWidthOnPage;
                            const topDif = maxWidthOnPage * ratio - initialSize.height;

                            newSize.height = initialSize.height + topDif;

                            if (direction === DocumentViewerPageObjectResizeDirection.TopRight) {
                                newOffset.top = initialOffset.top - topDif;
                            }
                        }
                        break;
                    }
                    case DocumentViewerPageObjectResizeDirection.TopLeft:
                    case DocumentViewerPageObjectResizeDirection.BottomLeft: {
                        const newLeft = newOffset.left + newSize.width - ratioWidth;

                        if (newLeft >= minObjectLeft) {
                            newSize.width = ratioWidth;
                            newOffset.left = newLeft;
                        } else {
                            newOffset.left = minObjectLeft;
                            newSize.width = initialSize.width + initialOffset.left - minObjectLeft;
                            newSize.height = newSize.width * ratio;

                            if (direction === DocumentViewerPageObjectResizeDirection.TopLeft) {
                                newOffset.top = initialOffset.top - (newSize.height - initialSize.height);
                            }
                        }
                        break;
                    }
                }
            } else { // width changed more than height - adjust height
                const ratioHeight = newSize.width * ratio;
                // mouse horizontal movement
                switch (direction) {
                    case DocumentViewerPageObjectResizeDirection.TopRight:
                    case DocumentViewerPageObjectResizeDirection.TopLeft: {
                        const newTop = newOffset.top + newSize.height - ratioHeight;

                        if (newTop >= minObjectTop) {
                            newSize.height = ratioHeight;
                            newOffset.top = newTop;
                        } else {
                            newOffset.top = minObjectTop;
                            newSize.height = initialSize.height + initialOffset.top - minObjectTop;
                            newSize.width = newSize.height / ratio;

                            if (direction === DocumentViewerPageObjectResizeDirection.TopLeft) {
                                newOffset.left = initialOffset.left - (newSize.width - initialSize.width);
                            }
                        }
                        break;
                    }
                    case DocumentViewerPageObjectResizeDirection.BottomRight:
                    case DocumentViewerPageObjectResizeDirection.BottomLeft: {
                        if (ratioHeight <= maxHeightOnPage) {
                            newSize.height = ratioHeight;
                        } else {
                            newSize.height = maxHeightOnPage;
                            const leftDif = maxHeightOnPage / ratio - initialSize.width;

                            newSize.width = initialSize.width + leftDif;

                            if (direction === DocumentViewerPageObjectResizeDirection.BottomLeft) {
                                newOffset.left = initialOffset.left - leftDif;
                            }
                        }
                        break;
                    }
                }
            }
        }


        ref.current.style.setProperty('--object-width', `${newSize.width}px`);
        ref.current.style.setProperty('--object-height', `${newSize.height}px`);
        ref.current.style.left = `${newOffset.left}px`;
        ref.current.style.top = `${newOffset.top}px`;
        ref.current.style.width = `${newSize.width}px`;
        ref.current.style.height = `${newSize.height}px`;
    }, [
        keepRatio,
        minSizeParam.height,
        minSizeParam.width,
        objectOffset.left,
        objectOffset.top,
        objectSize.height,
        objectSize.width,
        ref,
    ]);
};
