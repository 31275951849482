import { Icon, IconProps, SvgIcon, SvgIconProps } from '@progress/kendo-react-common';
import { facebookIcon, googleIcon } from '@progress/kendo-svg-icons';
import classNames from 'classnames';
import { ContactDiscriminator, ContactGroupDiscriminator } from '~constants';
import { ContactOrigin } from '~constants/contact-origin';

export interface ContactIconProps {
    contact: {
        origin?: ContactOrigin;
        discriminator?: ContactDiscriminator | ContactGroupDiscriminator;
        isShared?: boolean;
    }
    iconProps?: IconProps;
    svgIconProps?: Omit<SvgIconProps, 'icon'>;
}

function ContactIcon({ contact, iconProps, svgIconProps }: ContactIconProps) {
    if (contact.discriminator === ContactGroupDiscriminator.ContactGroup) {
        if (contact.isShared) {
            return <Icon {...iconProps} className={classNames(iconProps?.className, 'fa-regular fa-users')} />;
        } else {
            return <Icon {...iconProps} className={classNames(iconProps?.className, 'fa-solid fa-users')} />;
        }
    } else if (contact.discriminator === ContactDiscriminator.PortalContact) {
        if (contact.isShared) {
            return <Icon {...iconProps} className={classNames(iconProps?.className, 'fa-regular fa-user')} />;
        } else {
            return <Icon {...iconProps} className={classNames(iconProps?.className, 'fa-solid fa-user')} />;
        }
    } else if (contact.discriminator === ContactDiscriminator.CloudContact) {
        if (contact.origin === ContactOrigin.Google) {
            return <SvgIcon {...svgIconProps} icon={googleIcon} size='medium' />;
        } else if (contact.origin === ContactOrigin.Microsoft) {
            return <SvgIcon {...svgIconProps} icon={facebookIcon} size='medium' />;
        }
    }

    return null;
}

export default ContactIcon;
