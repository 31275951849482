import { NdsIconFont } from '@gonitro/rcl';
import { Avatar } from '@progress/kendo-react-layout';
import PanelDropdown from '~components/panel-dropdown';
import Typography, { TypographyToken } from '~components/typography';
import { useUserInfo } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { ApproverModel, FormFillerModel, ReceiverModel, SignerModel } from '~models';
import './packageDetailsDropdown.scss';

export interface PackageDetailsDropdownProps {
    title: string;
    userList: SignerModel[] | ReceiverModel[] | ApproverModel[] | FormFillerModel[];
    reassignedUser?: string;
    openDropDown?:boolean;
}

function PackageDetailsDropdown({ title, userList, reassignedUser, openDropDown }: PackageDetailsDropdownProps) {
    const userInfo = useUserInfo();
    const { t } = useTranslation('base');
    const { t: tflow } = useTranslation('flow');

    return (
        <div className="c-package-details-dropdown">
            <PanelDropdown headerText={title} openDropdown={openDropDown}>
                <ul className="c-package-details-dropdown__list">
                    {userList.map((user, index) => {
                        // Destructure necessary user properties
                        const { firstName, lastName, emailAddress, substitutes } = user;

                        // Check if the user is the current user
                        const isCurrentUser = emailAddress === userInfo?.emailAddress;

                        return (
                            <li className="c-package-details-dropdown__item" key={index}>
                                <div className="c-package-details-dropdown__user-content">
                                    <Avatar rounded="full" type="text" className="c-package-details-dropdown__avatar">
                                        {firstName? firstName?.[0] : t('no-recipient-defined').charAt(0)}
                                    </Avatar>
                                    <div className="c-package-details-dropdown__info">
                                        <Typography
                                            token={TypographyToken.UiMenuSm}
                                            text={firstName && lastName ? `${firstName} ${lastName} ${isCurrentUser ? tflow('you') : ''}` : t('no-recipient-defined')}
                                            tagName="div"
                                        />
                                        <Typography
                                            token={TypographyToken.UiMenuXs}
                                            text={emailAddress}
                                            tagName="div"
                                            className="c-package-details-dropdown__email"
                                        />
                                    </div>
                                </div>
                                {firstName && lastName && substitutes.length > 0 && (
                                    <div className="c-package-details-dropdown__banner">
                                        <NdsIconFont fontName="fa-solid-calendar-day" />
                                        <Typography
                                            token={TypographyToken.UiMenuXs}
                                            text={reassignedUser
                                                ? ' '
                                                : t('substitute-label', {
                                                    outOfOfficeUser: `${firstName} ${lastName}`,
                                                    substituteUser: substitutes[0],
                                                })}
                                            tagName="div"
                                        />
                                    </div>
                                )}
                            </li>
                        );
                    })}
                </ul>
            </PanelDropdown>
        </div>
    );
}

export default PackageDetailsDropdown;
