import { Dispatch, useCallback } from 'react';
import { FlowActionType, FlowReducerActionPayloads, FlowStateAction } from './flow.types';

export const useFlowDispatcher = (dispatch: Dispatch<FlowStateAction>) => {
    return useCallback(<T extends FlowActionType = FlowActionType>(type: T, payload: FlowReducerActionPayloads[T]): void => {
        dispatch({
            type,
            payload,
        });
    }, [dispatch]);
};
