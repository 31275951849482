import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { useTranslation } from '~contexts/i18n';
import { FlowFieldModel, FlowTextFieldModel } from '~models';
import Typography, { TypographyToken } from '../../../../typography';
import './flowEditorTextFieldObject.scss';

export interface FlowEditorTextFieldObjectProps extends PropsWithChildren {
    data: FlowFieldModel,
}

function FlowEditorTextFieldObject({ data: dataProp, children }: FlowEditorTextFieldObjectProps) {
    const { t } = useTranslation('flow');
    const data = dataProp as FlowTextFieldModel;

    return (
        <div className={'c-flow-editor-text-field-object'}>
            <div className={'c-flow-editor-text-field-object__ghost c-flow-editor-field-object'}>
                <div className={'c-flow-editor-text-field-object__content'}>
                    <Typography
                        className={'c-flow-editor-field-object__text'}
                        token={TypographyToken.UiFormsLabelSm}
                        text={data.name ?? t('field-object-label-input')}
                    />
                </div>
                {children}
            </div>
            <Typography
                token={TypographyToken.UiFormsInputSm}
                className={classNames(
                    'c-flow-editor-text-field-object__input',
                    { 'c-flow-editor-text-field-object__input--multiline': data.isMultiline },
                )}
            >
                {data.defaultValue
                    ? data.defaultValue
                    : data.isMultiline
                        ? <>{t('text-field-empty-value-text')}<br />{t('text-field-empty-value-text')}</>
                        : t('text-field-empty-value-text')
                }
            </Typography>
        </div>
    );
}

export default FlowEditorTextFieldObject;
