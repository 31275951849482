import { useMemo } from 'react';
import Typography, { TypographyToken } from '~components/typography';
import { useFlowContext } from '~contexts/flow';
import { useTranslation } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import { FileUtils } from '~lib';
import './flowDocumentsWarnings.scss';

export interface FlowDocumentsWarningsProps {

}

function FlowDocumentsWarnings(props: FlowDocumentsWarningsProps) {
    const { t } = useTranslation('flow');
    const { flowState: { infoDocuments } } = useFlowContext();
    const { warningsSettings: { isDisplayWarningSettingsEnabled, ...warningsSettings } } = useSettings();


    const warnings = useMemo(() => {
        const arr: string[] = [];
        const { recommendedNumberOfDocuments, recommendedDocumentSize, recommendedPackageSize } = warningsSettings;

        let totalSize = 0;
        for (const doc of infoDocuments) {
            totalSize += doc.size;

            if (FileUtils.convertFileSize(doc.size, FileUtils.FileSizeUnit.MB, FileUtils.FileSizeUnit.B) > recommendedDocumentSize) {
                arr.push(t('warning-document-exceeds-size', {
                    size: recommendedDocumentSize,
                    documentName: doc.documentName,
                }));
            }
        }

        if (FileUtils.convertFileSize(totalSize, FileUtils.FileSizeUnit.MB, FileUtils.FileSizeUnit.B) > recommendedPackageSize) {
            arr.push(t('warning-package-exceeds-size', { size: recommendedPackageSize }));
        }

        if (infoDocuments.length > recommendedNumberOfDocuments) {
            arr.push(t('warning-package-exceeds-documents-number', { quantity: recommendedNumberOfDocuments }));
        }

        return arr;
    }, [infoDocuments, t, warningsSettings]);

    if (!isDisplayWarningSettingsEnabled || !warnings.length) {
        return null;
    }

    return (
        <div className={'c-flow-documents-warnings'}>
            <ul className={'c-flow-documents-warnings__list'}>
                {warnings.map((text, i) => (
                    <Typography
                        key={i}
                        token={TypographyToken.DesktopDescriptionSm}
                        text={text}
                        tagName={'li'}
                    />
                ))}
            </ul>
        </div>
    );
}

export default FlowDocumentsWarnings;
