// do not change numbers, they are synced with backend
export enum FileTypeId {
    OCTET_STREAM = 0,
    DOC = 1,
    DOCX = 2,
    PDF = 3,
    TXT = 4,
    PNG = 5,
    JPG = 6
}

export class FileType {
    constructor(
        public readonly id: FileTypeId,
        public readonly mimeType: string,
        public readonly extensions: string[],
        // public icon: string, // if ever needed, this is the way
    ) {
    }

    static find(typeId: FileTypeId): FileType {
        return FileTypes.find(el => el.id === typeId) ?? FileType.find(FileTypeId.OCTET_STREAM);
    }

    static findExtensions(mimeTypes: string[]): string[] {
        const extensions: string[] = [];
        for (const type of mimeTypes) {
            const found = FileTypes.find((el) => el.mimeType === type)?.extensions;

            if (found) {
                extensions.push(...found);
            }
        }

        return extensions;
    }

    static findMimeTypeByExtension(extension: string): string {
        if (!extension.startsWith('.')) {
            extension = '.' + extension;
        }
        for (const fileType of FileTypes) {
            if (fileType.extensions.includes(extension)) {
                return fileType.mimeType;
            }
        }

        return 'application/octet-stream';
    }

    static findAndMerge(ids: FileTypeId[]): {extensions: string[]; mimeTypes: string[]} {
        const extensions = [];
        const mimeTypes = [];
        for (const id of ids) {
            const fileType = FileType.find(id);

            extensions.push(...fileType.extensions);
            mimeTypes.push(fileType.mimeType);
        }

        return {
            extensions,
            mimeTypes,
        };
    }
}

export const FileTypes = [
    new FileType(
        FileTypeId.OCTET_STREAM,
        'application/octet-stream',
        [],
    ),
    new FileType(
        FileTypeId.DOC,
        'application/msword',
        ['.doc'],
    ),
    new FileType(
        FileTypeId.DOCX,
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ['.docx'],
    ),
    new FileType(
        FileTypeId.PDF,
        'application/pdf',
        ['.pdf'],
    ),
    new FileType(
        FileTypeId.TXT,
        'text/plain',
        ['.txt'],
    ),
    new FileType(
        FileTypeId.PNG,
        'image/png',
        ['.png'],
    ),
    new FileType(
        FileTypeId.JPG,
        'image/jpeg',
        ['.jpeg', 'jpg'],
    ),
];
