import { createContext, RefObject } from 'react';
import { DocumentViewerRef } from '~components/document-viewer';
import { FlowEditorLeftPaneRef } from '~components/flow/flow-editor/flow-editor-left-pane/flowEditorLeftPane';
import {
    FlowDetailDocument,
    FlowFieldModel,
    FlowInfoDocument,
    FlowModel,
    FlowPropertiesModel,
    FlowStakeholderModel,
    Guid,
    LegalNoticeModel,
} from '~models';
import { FlowApiWrapper } from './flow.api-wrapper';
import { FlowError } from './flow.error';
import {
    FlowActionType,
    FlowEntityType,
    FlowFocusedEntityData,
    FlowNavigateToStep,
    FlowReducerActionPayloads,
    FlowStep,
    FlowType,
    SetFlowFocusedEntity,
} from './flow.types';

export type FlowStateType = {
    flowId?: Guid;
    workspaceId?: number;

    // used on documents view
    infoDocuments: FlowInfoDocument[];
    deletedDocuments: Guid[];
    templateId?: Guid; // only when creating new package

    // used on editor view
    packageName?: string;
    templateName?: string;
    properties?: FlowPropertiesModel;
    detailDocuments: FlowDetailDocument[];

    stakeholders: FlowStakeholderModel[];
    flow: FlowModel;
    elements: FlowFieldModel[];

    isLegalNoticeEnabled: boolean;
    legalNotices: LegalNoticeModel[];
}

export type FlowDocumentViewerConfigType = {
    zoomLevel: number;
    justifyPages: boolean;
    adjustViewerToScreen: boolean;
}

export interface FlowContextType {
    isNew?: boolean;
    isProcessing: boolean; // indicates that some process is ongoing and some actions should be locked
    setProcessing: (isProcessing: boolean) => void; // sets flow isProcessing value
    isActionNeeded: boolean;
    setFlowState: <T extends FlowActionType = FlowActionType>(type: T, payload: FlowReducerActionPayloads[T]) => void;
    flowState: FlowStateType;
    api: FlowApiWrapper;
    errors: Partial<Record<FlowError, any>>;
    setError: (error: FlowError, errorInfo?: Record<string, any> | string) => void;
    clearError: (error: FlowError, entityId?: string) => void;
    clearErrors: (error: FlowError, entityId?: string) => void;
    getErrorInfo: (error: FlowError, entityId?: string) => any;
    hasError: (error?: FlowError, entityId?: string) => boolean;
    navigateToStep: FlowNavigateToStep;
    navigateToPortal: () => void;
    step: FlowStep;
    refreshData: () => Promise<void>;
    focusedEntityType: FlowEntityType;
    focusedEntityId: Guid | null;
    focusedEntityData: FlowFocusedEntityData | null;
    setFocusedEntity: SetFlowFocusedEntity;
    visibleDocument?: FlowDetailDocument;
    setVisibleDocument: (doc: FlowDetailDocument) => void;
    documentViewerConfig: FlowDocumentViewerConfigType;
    setDocumentViewerConfig: (config: Partial<FlowDocumentViewerConfigType>) => void;
    stakeholderColors: Record<Guid, number>;
    documentViewerRef: RefObject<DocumentViewerRef>;
    leftPaneRef: RefObject<FlowEditorLeftPaneRef>;
    flowType: FlowType;
}

export const FlowContext = createContext<FlowContextType>({} as FlowContextType);

export default FlowContext;
