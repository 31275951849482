import { throttle } from 'lodash';
import { RefObject, useState } from 'react';
import { useAbortEffect } from '~hooks/effects';


export const useDocumentViewerResize = (
    containerRef: RefObject<HTMLDivElement>,
    contentWidth: number,
    zoom: number,
    horizontalGutter: number,
    adjustToScreen: boolean,
    debounceTime: number,
) => {
    const [scale, setScale] = useState(0);
    const [ghostWidth, setGhostWidth] = useState(0);
    const [leftOffset, setLeftOffset] = useState(0);


    useAbortEffect((signal) => {
        const calcScale = () => {
            if (!containerRef.current) {
                return;
            }
            const containerWidth = containerRef.current.offsetWidth;

            const baseWidth = adjustToScreen ? (containerWidth - horizontalGutter) : contentWidth;
            const zoomedWidth = baseWidth * zoom / 100;

            setScale(zoomedWidth / contentWidth);
            setGhostWidth(zoomedWidth);
            setLeftOffset((containerWidth - zoomedWidth) / 2);
        };

        const callback = throttle(calcScale, debounceTime);

        calcScale();
        window.addEventListener('resize', () => {
            callback();
        }, { signal });
    }, [
        debounceTime,
        contentWidth,
        containerRef,
        horizontalGutter,
        zoom,
        adjustToScreen,
    ]);


    return {
        scale,
        ghostWidth,
        leftOffset,
    };
};
