import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { HTMLAttributesAndClassName } from '~models';
import './content.scss';

export interface ContentProps extends HTMLAttributesAndClassName<HTMLDivElement>, PropsWithChildren {
    noDefaultPadding?: boolean;
    withScrollbar?: boolean;
}

function Content({ className, noDefaultPadding = false, withScrollbar = false, children, ...props }: ContentProps) {
    return (
        <main
            {...props}
            className={classNames('c-content', {
                'c-content--with-padding': !noDefaultPadding,
                'c-content--with-scrollbar': withScrollbar,
            }, className)}
        >
            {children}
        </main>
    );
}

export default Content;