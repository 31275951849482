import { NdsIconFont, TablePaginator } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { ComboBox, ListItemProps } from '@progress/kendo-react-dropdowns';
import { MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { PortalsApi } from '~api/portals.api';
import { UserManagementApi } from '~api/user-management.api';
import Loader from '~components/loader';
import Typography, { TypographyToken } from '~components/typography';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { useVirtualizedCombobox } from '~hooks/virtualizedCombobox';
import { PageableListParams } from '~models/pageable-list.models';
import { UserGroupUsersListModel } from '~models/user-management.models';
import SelectedUserItem from './selected-user-item/selected-user-item';
import './user-profile-tab.scss';

export interface UserProfileTabProps {
    userGroupId: number | undefined;
    editedMembersRef: MutableRefObject<UserGroupUsersListModel[]>;
    setSelectedUsersForUserGroup: (users: UserGroupUsersListModel[]) => void;
    selectedUsersForUserGroup: UserGroupUsersListModel[];
}

const UserProfileTab = ({
    userGroupId,
    editedMembersRef,
    setSelectedUsersForUserGroup,
    selectedUsersForUserGroup,
}: UserProfileTabProps) => {
    const userManagementApi = useApi(UserManagementApi);
    const portalsApi = useApi(PortalsApi);
    const { t } = useTranslation('user-management');
    const { t: tBase } = useTranslation('base');

    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [isLoading, setLoading] = useState(false);

    const PAGE_SIZE = 6;

    const listRef = useRef<{ reset: () => void; }>(null);

    const fetchSelectedUsersByUsergroup = useCallback(async () => {
        setLoading(true);
        const selectedUserResponse = await userManagementApi.getUsersByUserGroup({
            page: currentPage,
            maxQuantity: 8,
            userGroupId,
            isMember: true,
        });

        setTotalPages(Math.ceil(selectedUserResponse.totalItems / selectedUserResponse.itemsPerPage));
        const updatedMemberUsers = selectedUserResponse.items.map((user) => {
            const editedUser = editedMembersRef.current.find(selectedUsers => selectedUsers.userId === user.userId);

            if (editedUser?.isMember === false) {
                return {
                    ...user,
                    isMember: editedUser.isMember,
                };
            }

            return user;
        });

        const uniqueSelectedMembers =
            [...editedMembersRef.current, ...updatedMemberUsers]
                .reduce((acc: UserGroupUsersListModel[], current: UserGroupUsersListModel) => {
                    if (!acc.some(item => item.userId === current.userId)) {
                        acc.push(current);
                    }

                    return acc;
                }, []);

        if (currentPage === 1) {
            // only add newly selected users to first page
            setSelectedUsersForUserGroup(uniqueSelectedMembers);
        } else {
            setSelectedUsersForUserGroup(updatedMemberUsers);
        }
        setLoading(false);
    }, [
        currentPage,
        editedMembersRef,
        setSelectedUsersForUserGroup,
        userGroupId,
        userManagementApi,
    ]);

    useEffect(() => {
        if (userGroupId) {
            fetchSelectedUsersByUsergroup();
        }
    }, [fetchSelectedUsersByUsergroup, currentPage, userGroupId]);

    const trackSelectedUsers = useCallback((user: UserGroupUsersListModel | undefined) => {
        if (user) {
            editedMembersRef.current = editedMembersRef.current.filter((selectedFilter) => selectedFilter.userId !== user.userId);
            editedMembersRef.current.push({
                ...user,
                isMember: !user.isMember,
            });

            if (userGroupId) {
                fetchSelectedUsersByUsergroup();
            } else {
                setSelectedUsersForUserGroup(editedMembersRef.current);
            }
            listRef.current?.reset();
        }
    }, [editedMembersRef, fetchSelectedUsersByUsergroup, setSelectedUsersForUserGroup, userGroupId]);


    const fetchData = useCallback(async (data: PageableListParams, signal?: AbortSignal) => {
        let userResponse;

        if (userGroupId) {
            userResponse = await userManagementApi.getUsersByUserGroup({
                ...data,
                userGroupId,
            });
        } else {
            userResponse = await portalsApi.getUsers({
                ...data,
                filterValues: {
                    status: ['Active', 'InActive'],
                    name: data.filterValues!.name ? data.filterValues!.name : '',
                },
            });


            const convertedToUserGroupUsersList = userResponse.items.map(user => ({
                userId: user.userId,
                userGroupId: undefined,
                emailAddress: user.email,
                firstName: user.firstName,
                lastName: user.lastName,
                isMember: false,
            }));

            userResponse = {
                ...userResponse,
                items: convertedToUserGroupUsersList,
            };

        }

        const updatedMemberUsers = userResponse.items.map((user) => {
            const editedUser = editedMembersRef.current.find(selectedUsers => selectedUsers.userId === user.userId);

            if (editedUser) {
                return {
                    ...user,
                    isMember: editedUser.isMember,
                };
            }

            return user;
        });

        return {
            ...userResponse,
            items: updatedMemberUsers,
        };

    }, [editedMembersRef, portalsApi, userGroupId, userManagementApi]);

    const RenderItem = (itemProps: ListItemProps) => {
        return (
            <div className={'user-profile-tab__user-list-item'}>
                <div className='user-profile-tab__user-list-details'>
                    <NdsIconFont className='user-profile-tab__contact-icon' fontName='fa-solid-user' />
                    <div>
                        <div>
                            <Typography
                                className='user-profile-tab__user-item-name'
                                token={TypographyToken.UiMenuMd}
                                text={`${itemProps.dataItem.firstName} ${itemProps.dataItem.lastName}`}
                            ></Typography>
                        </div>
                        <div className={'user-profile-tab__user-item-subtitle'}>
                            <Typography
                                token={TypographyToken.DesktopBylineXs}
                                text={itemProps.dataItem.emailAddress}
                            ></Typography>
                        </div>
                    </div>
                </div>
                {itemProps.dataItem.isMember &&
                    <NdsIconFont
                        className='user-profile-tab__combo-check-icon'
                        fontName='fa-solid-check'
                    />}
            </div>);
    };


    const comboboxProps = useVirtualizedCombobox<any>({
        pageSize: PAGE_SIZE,
        apiFilterKey: 'name',
        fetchData,
        valueKey: 'userId',
        labelKey: 'emailAddress',
        onChange: (e) => trackSelectedUsers(e),
        value: '',
        RenderItem,
        ref: listRef,
    });

    const onPageChange = useCallback((page: number) => {
        setCurrentPage(page);
    }, []);

    return (
        <div className={'user-profile-tab'}>
            <Typography token={TypographyToken.UiFormsLabelSm} text={t('user-profile-tab-search-label')} />
            <ComboBox
                popupSettings={{ height: 198 }}
                className={'user-profile-tab__'}
                {...comboboxProps}
                clearButton={false}
                onClose={() => listRef.current?.reset()}
            />
            {isLoading
                ? <Loader center size={SizesEnums.XLARGE} />
                :
                <>
                    <div className='user-profile-tab__selected-user-list-container'>
                        {selectedUsersForUserGroup.map((selectedUser) => {
                            if (selectedUser.isMember) {
                                return <SelectedUserItem key={selectedUser.userId} user={selectedUser} onClick={trackSelectedUsers} />;
                            }

                            return null;
                        })}
                    </div>
                    {userGroupId && totalPages > 1 && <TablePaginator
                        positionBottomFixed={false}
                        totalPages={totalPages}
                        currentPage={currentPage!}
                        prefixLabel={''}
                        ofLabel={tBase('table-pagination-of')}
                        onPageChange={onPageChange}
                    />}
                </>}
        </div>
    );
};

export default UserProfileTab;
