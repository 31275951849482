import { SortDirectionEnum } from '@gonitro/rcl/lib/_types';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ObjectUtils } from '~lib';
import { Guid } from '~models';
import {
    TableDefinition,
    TableFilterArrayValue, TableFilterObjectValue, TableFilterValue, TableFilterValueSubType, TableFilterValueType,
} from './table.types';

export function useInternalTablePaging(definition: TableDefinition<any>) {
    const pageFilterParamKey = definition.filters?.find(f => f.key === 'page')?.queryParamKey ?? 'page';

    const [searchParams, setSearchParams] = useSearchParams();
    const pageSP = parseInt(searchParams.get(pageFilterParamKey) ?? '1');

    const [totalPages, setTotalPages] = useState<number>(1);

    const setPageInternalCallback = useCallback((num: number) => {
        setSearchParams((sp) => {
            const current = parseInt(searchParams.get(pageFilterParamKey) ?? '');

            if (
                1 !== num
                || isNaN(current)
                || current !== 1
            ) {
                sp.set(pageFilterParamKey, num.toString());
            }

            return sp;
        });
    }, [pageFilterParamKey, searchParams, setSearchParams]);

    const setPagePublicCallback = useCallback((num: number) => {
        if (num < 1 || num > totalPages) {
            return;
        }
        setPageInternalCallback(num);
    }, [setPageInternalCallback, totalPages]);

    return {
        page: pageSP,
        setPageInternalCallback,
        setPagePublicCallback,
        totalPages,
        setTotalPages,
    };
}

export function useInternalTableItemsPerPage(
    definition: TableDefinition<any>,
) {
    const showFilterParamKey = definition.filters?.find(f => f.key === 'show')?.queryParamKey ?? 'show';
    const pageFilterParamKey = definition.filters?.find(f => f.key === 'page')?.queryParamKey ?? 'page';

    const itemsPerPageOptions = useRef(definition.itemsPerPageOptions || [definition.itemsPerPage]);

    const [searchParams, setSearchParams] = useSearchParams();
    let showSP = parseInt(searchParams.get(showFilterParamKey) ?? '');

    showSP = itemsPerPageOptions.current.includes(showSP) ? showSP : definition.itemsPerPage;

    const setItemsPerPageCallback = useCallback((num: number) => {
        if (!itemsPerPageOptions.current.includes(num)) {
            num = definition.itemsPerPage;
        }
        setSearchParams((sp) => {
            const current = parseInt(searchParams.get(showFilterParamKey) ?? '');

            if (
                definition.itemsPerPage !== num
                || (
                    !isNaN(current)
                    && current !== num
                )
            ) {
                sp.set(showFilterParamKey, num.toString());

                const currentPage = parseInt(searchParams.get(pageFilterParamKey) ?? '');

                if (!isNaN(currentPage) && currentPage > 1) {
                    sp.set(pageFilterParamKey, '1');
                }
            }

            return sp;
        });
    }, [
        definition.itemsPerPage,
        pageFilterParamKey,
        searchParams,
        setSearchParams,
        showFilterParamKey,
    ]);

    useEffect(() => {
        if (!itemsPerPageOptions.current.includes(parseInt(searchParams.get(showFilterParamKey) ?? ''))) {
            setItemsPerPageCallback(definition.itemsPerPage);
        }
    }, [definition.itemsPerPage, searchParams, setItemsPerPageCallback, showFilterParamKey]);

    return {
        itemsPerPage: showSP,
        itemsPerPageOptions: itemsPerPageOptions.current,
        setItemsPerPageCallback,
    };
}

export function useInternalTableSorting({ initialSorting, columns, filters }: TableDefinition<any>) {
    const sortFilterDef = filters?.find(f => f.key === 'sort');

    const [searchParams, setSearchParams] = useSearchParams();
    const sortSP = searchParams.get(sortFilterDef?.queryParamKey ?? 'sort') ?? (initialSorting ? `${initialSorting.key}:${initialSorting?.direction}` : '');
    const [keySP, orderSP] = sortSP.split(':') as [string, SortDirectionEnum];

    const sortChangeCallback = useCallback((columnKey: string) => {
        let newKey = keySP;
        let newOrder = orderSP;

        if (keySP === columnKey) {
            newOrder = orderSP === SortDirectionEnum.DESC ? SortDirectionEnum.ASC : SortDirectionEnum.DESC;
        } else {
            const colDef = columns.find((el) => el.sortingKey === columnKey || el.key === columnKey)!;

            newOrder = colDef.initialSortOrder ?? SortDirectionEnum.ASC;
            newKey = columnKey;
        }
        setSearchParams((sp) => {
            sp.set(sortFilterDef?.queryParamKey ?? 'sort', `${newKey}:${newOrder}`);

            return sp;
        });
    }, [
        columns,
        keySP,
        orderSP,
        setSearchParams,
        sortFilterDef?.queryParamKey,
    ]);

    return {
        sortConfig: {
            key: keySP,
            direction: orderSP as SortDirectionEnum,
        },
        sortChange: sortChangeCallback,
    };
}

export function useInternalTableSelections() {
    const [selectedRows, setSelectedRows] = useState<(Guid | number)[]>([]);

    const selectRowsCallback = useCallback((ids: (Guid | number)[]) => {
        setSelectedRows((prevRows: (Guid | number)[]) => {
            const filtered = ids.filter((el: any) => !prevRows.includes(el));

            return [...prevRows, ...filtered];
        });
    }, []);

    const unselectRowsCallback = useCallback((ids: (Guid | number)[]) => {

        setSelectedRows((prevRows) => {
            return prevRows.filter((id) => !ids.includes(id));
        });
    }, []);

    const unselectAllCallback = useCallback(() => {
        setSelectedRows([]);
    }, []);

    return {
        selectedRows,
        selectRows: selectRowsCallback,
        unselectRows: unselectRowsCallback,
        unselectAllRows: unselectAllCallback,
    };
}

export function useInternalTableFilterValues({ filters }: TableDefinition<any>) {
    const [searchParams, setSearchParams] = useSearchParams();
    const prevFilters = useRef({});

    const filterValues = useMemo(() => {
        const filterDefs = filters?.filter(f => f.type !== TableFilterValueType.Reserved);
        const obj: Record<string, TableFilterValue> = {};

        if (!filterDefs) {
            return prevFilters.current;
        }

        for (const def of filterDefs) {
            const value = searchParams.getAll(def.queryParamKey || def.key)
                .map(val => {
                    if (def.subType === TableFilterValueSubType.String) {
                        return val;
                    }
                    try {
                        return JSON.parse(val);
                    } catch (e) {
                        return undefined;
                    }
                }).filter(e => e !== undefined);

            if (!value.length) {
                continue;
            }
            let parsed: TableFilterValue;

            const [key1, key2] = def.key.split('.');

            if (def.type === TableFilterValueType.Array) {
                obj[key1] = value;
            } else {
                try {
                    parsed = value[0];
                } catch (e) {
                    continue;
                }
                if (def.type === TableFilterValueType.Object) {
                    obj[key1] = Object.assign(
                        {},
                        obj[key1] || {} as TableFilterObjectValue,
                        { [key2]: parsed },
                    );
                } else {
                    obj[key1] = value[0];
                }
            }
        }

        if (ObjectUtils.compareValue(prevFilters.current, obj)) {
            return prevFilters.current;
        }
        prevFilters.current = obj;

        return obj;
    }, [filters, searchParams]);

    const setFilterValueCallback = useCallback((name: string, value: TableFilterValue) => {
        const filterDefs = filters?.filter(f => f.type !== TableFilterValueType.Reserved);

        if (!filterDefs) {
            throw new Error(`Trying to set filterValue for '${name}' but no filters are defined in table definition`);
        }
        setSearchParams((sp) => {
            const defs = filterDefs.filter(f => f.key.split('.')[0] === name); // split()[0] also satisfies situation when filter isn't nested object

            if (!defs.length) {
                throw new Error(`Trying to set filterValue for '${name}' but no such filter is defined in table definition`);
            }

            if (defs.length === 1 && defs[0].type !== TableFilterValueType.Object) {
                const {
                    key,
                    queryParamKey,
                    type,
                    subType,
                } = defs[0];

                if (value === undefined || (type === TableFilterValueType.Array && (!Array.isArray(value) || !value.length))) {
                    sp.delete(queryParamKey ?? key);
                } else {
                    if (type === TableFilterValueType.Array) {
                        let first = true;
                        for (const val of value as TableFilterArrayValue) {
                            const valToStore = subType === TableFilterValueSubType.String ? val as string : JSON.stringify(val);

                            if (first) {
                                sp.set(queryParamKey ?? key, valToStore);
                                first = false;
                            } else {
                                sp.append(queryParamKey ?? key, valToStore);
                            }
                        }
                    } else {
                        const valToStore = subType === TableFilterValueSubType.String ? value as string : JSON.stringify(value);

                        sp.set(queryParamKey ?? key, valToStore);
                    }
                }
            } else {
                for (const def of defs) {
                    const [, key2] = def.key.split('.');

                    if (!value || (value as TableFilterObjectValue)[key2] === undefined) {
                        sp.delete(def.queryParamKey!);
                    } else {
                        const valToStore = def.subType === TableFilterValueSubType.String
                            ? (value as TableFilterObjectValue)[key2] as string
                            : JSON.stringify(value);

                        sp.set(def.queryParamKey!, valToStore);
                    }
                }
            }


            return sp;
        });
    }, [filters, setSearchParams]);

    return useMemo(() => ({
        filterValues,
        setFilterValue: setFilterValueCallback,
    }), [filterValues, setFilterValueCallback]);
}
