import { NdsButton, NdsIconFont } from '@gonitro/rcl';
import { NdsInputTypes, StatesEnum } from '@gonitro/rcl/lib/_types/designsystem.enums';
import NdsInput from '@gonitro/rcl/lib/nds-input';
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthApi } from '~api/auth.api';
import Error from '~components/error';
import { HttpStatus } from '~constants';
import { BaseRoutes } from '~constants/routes';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { useEnhancedForm } from '~hooks/enhanced-form';
import { ValidateUtils } from '~lib';
import { HttpError } from '~lib/http/http.error';
import { LanguageDataLabel } from '~models';
import './reset-password-form.scss';

export interface ResetPasswordFormProps {
    token: string;
    expiryTag: string;
    userId: string;
    setExpiredError: () => void;
}

type FormValues = {
    password: string,
    confirmPassword: string;
};

function ResetPasswordForm({ token, expiryTag, userId, setExpiredError }: ResetPasswordFormProps) {
    const { t } = useTranslation('unauthorized');
    const authApi = useApi(AuthApi);
    const formRef = useRef<HTMLFormElement>(null);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<LanguageDataLabel<'error'>>();
    const navigate = useNavigate();

    const {
        registerNdsInput,
        handleSubmit,
        formState: { isValid },
    } = useEnhancedForm<FormValues>({ mode: 'onBlur' });


    const onSubmit = useCallback(async (data: FormValues) => {
        setLoading(true);
        try {
            await authApi.passwordReset({
                password: data.password,
                token,
                expiryTag,
                userId,
            });
            navigate(BaseRoutes.Login, { replace: true });
        } catch (error) {
            if (error instanceof HttpError) {

                if (error.hasJsonBody) {
                    const code = error.body[0]?.ErrorCode;

                    if (code === 'User.UserLockedOut') {
                        setError('reset-password-user-locked-out');

                        return;
                    }
                    if (code === 'User.PermissionDenied') {
                        setExpiredError();

                        return;
                    }
                } else if (error.response.status === HttpStatus.TooManyRequests) {
                    setError('reset-password-user-locked-out');

                    return;
                }
            }
            setError('something-went-wrong');
        } finally {
            setLoading(false);
        }
    }, [
        authApi,
        expiryTag,
        navigate,
        setExpiredError,
        token,
        userId,
    ]);

    return (
        <form
            className='reset-password-form'
            ref={formRef}
            onSubmit={handleSubmit(onSubmit)}
            noValidate
        >
            <div className={'reset-password-form__input-wrapper'}>
                <NdsInput
                    required
                    inputType={passwordVisible ? NdsInputTypes.TEXT : NdsInputTypes.PASSWORD}
                    // need placeholder for real icon to limit value width and show custom icon -.-
                    rightIcon={'fa-null'}
                    label={t('password')}
                    {...registerNdsInput('password', {
                        fieldLabel: t('password'),
                        required: true,
                        minLength: ValidateUtils.passwordMinLength,
                        maxLength: ValidateUtils.passwordMaxLength,
                        pattern: {
                            value: ValidateUtils.passwordRegex,
                            message: 'pattern-password',
                        },
                    })}
                />
                <NdsIconFont
                    className={'reset-password-form__eye-icon'}
                    fontName={passwordVisible ? 'fa-solid-eye' : 'fa-solid-eye-slash'}
                    onClick={() => {
                        setPasswordVisible(state => !state);
                    }}
                />
            </div>
            <div className={'reset-password-form__input-wrapper'}>
                <NdsInput
                    required
                    inputType={confirmPasswordVisible ? NdsInputTypes.TEXT : NdsInputTypes.PASSWORD}
                    // need placeholder for real icon to limit value width and show custom icon -.-
                    rightIcon={'fa-null'}
                    label={t('confirm-password')}
                    {...registerNdsInput('confirmPassword', {
                        required: true,
                        validate: (val: string, formValues: FormValues) => {
                            if (formValues.password !== val) {
                                return 'password-does-not-match';
                            }
                        },
                    })}
                />
                <NdsIconFont
                    className={'reset-password-form__eye-icon'}
                    fontName={confirmPasswordVisible ? 'fa-solid-eye' : 'fa-solid-eye-slash'}
                    onClick={() => {
                        setConfirmPasswordVisible(state => !state);
                    }}
                />
            </div>
            {error && <Error i18nKey={error} />}
            <NdsButton
                className='reset-password-form__button'
                label={t('btn-reset-your-password')}
                onClick={() => formRef?.current?.requestSubmit()}
                state={isValid && !loading ? StatesEnum.DEFAULT : StatesEnum.DISABLED}
                display-block
            />
        </form>
    );
}

export default ResetPasswordForm;