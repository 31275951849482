

import Typography, { TypographyToken } from '~components/typography';
import { CloudContactProviderEnum } from '~constants/cloud-provider';
import { useTranslation } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import { CloudContactStatus } from '~models/contacts.models';
import CloudAccountInfo from './cloud-account-info';
import './importCloudContactContent.scss';

export interface ImportCloudContactContentProps {
    icon: string;
    title: string;
    buttonIcon: string;
    buttonLabel: string;
    cloudProvider?: CloudContactProviderEnum;
    cloudStatus?: CloudContactStatus;
    onDisconnectClick: () =>  void;
}

function ImportCloudContactContent({
    icon,
    title,
    buttonIcon,
    buttonLabel,
    cloudStatus,
    cloudProvider,
    onDisconnectClick,
}: ImportCloudContactContentProps) {
    const settings = useSettings();

    const { t } = useTranslation('contacts');
    const { google, microsoft } = settings.cloudSettings;

    const getAltLabel = () => {
        return `${t('alt-icon-cloud-provider')} ${cloudProvider ? 'Google': 'Microsoft'}`;
    };

    return (
        <>
            {cloudStatus?.isAccountLinked
                ? <CloudAccountInfo
                    icon={buttonIcon}
                    altLabel={getAltLabel()}
                    cloudStatus={cloudStatus}
                    cloudProvider={cloudProvider!}
                    onDisconnectClick={onDisconnectClick}
                />
                : (
                    <div className={'c-import-cloud-contact-content__unlinked-account'}>
                        <div>
                            <img
                                src={icon}
                                alt={getAltLabel()}
                                height={48}
                                width={48}/>
                        </div>
                        <Typography
                            className={'c-import-cloud-contact-content__title'}
                            tagName="div"
                            token={TypographyToken.DesktopHeaderXs}
                            text={title}
                        />
                        <div>
                            <a
                                href={cloudProvider? google.oauthSettings.refreshOAuth2TokenUrl : microsoft.oauthSettings.office365OAuth2RefreshTokenUrl}
                                className={
                                    'c-import-cloud-contact-content__sign-in-button'
                                }
                            >
                                <img src={buttonIcon} alt={getAltLabel()}/>
                                <Typography
                                    token={TypographyToken.UiButtonsSm}
                                    text={buttonLabel}
                                />
                            </a>
                        </div>
                    </div>
                )}
        </>
    );
}

export default ImportCloudContactContent;
