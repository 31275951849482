import { Modal, ModalContent, ModalFooter } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { Button } from '@progress/kendo-react-buttons';
import { useTranslation } from '~contexts/i18n';
import Typography, { TypographyToken } from '../typography';
import './contactMissingInformationModal.scss';

export interface ContactMissingInformationModalProps {
    onCloseClick: () => void;
    missing: {
        lastName?: boolean;
        emailAddress?: boolean;
    }
}

function ContactMissingInformationModal({ onCloseClick, missing }: ContactMissingInformationModalProps) {
    const { t } = useTranslation('contacts');
    const { t: tBase } = useTranslation('base');

    return (
        <Modal
            headerLabel={t('missing-cloud-data-title')}
            size={SizesEnums.SMALL}
            className={'c-contact-missing-information-modal'}
            onCloseClick={onCloseClick}
            withClose
            renderContent={(
                <ModalContent className={'c-contact-missing-information-modal__content'}>
                    <Typography
                        token={TypographyToken.DesktopDescriptionBoldLg}
                        text={t('missing-cloud-data-reason')}
                        tagName={'p'}
                    />
                    <Typography
                        token={TypographyToken.DesktopDescriptionLg}
                        tagName={'ul'}
                        className={'c-contact-missing-information-modal__list'}
                    >
                        {missing.lastName && <li>{t('last-name-label')}</li>}
                        {missing.emailAddress && <li>{t('email-label')}</li>}
                    </Typography>
                    <Typography
                        token={TypographyToken.DesktopDescriptionLg}
                        text={t('missing-cloud-data-solution')}
                        tagName={'p'}
                    />
                </ModalContent>
            )}
            renderFooter={
                <ModalFooter>
                    <Button onClick={onCloseClick}>{tBase('ok-button')}</Button>
                </ModalFooter>
            }
        />
    );
}

export default ContactMissingInformationModal;
