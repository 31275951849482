import { useCallback, useMemo } from 'react';
import QuickActionsTable from '~components/quick-actions-table';
import { QuickAction } from '~components/quick-actions-table/quickActionsTable';
import { FlowAction, Permission } from '~constants';
import { useHasPermission } from '~contexts/auth';
import './contactGroupQuickActions.scss';

export interface ContactGroupQuickActionsProps {
    isShared: boolean;
    onEditClick: () => void;
    onDeleteClick: () => void;
}

function ContactGroupQuickActions({ isShared, onEditClick, onDeleteClick }: ContactGroupQuickActionsProps) {
    const hasDeleteSharedContactGroupPermission = useHasPermission(Permission.General_ActionDeleteSharedContactGroup);
    const hasEditSharedContactGroupPermission = useHasPermission(Permission.General_ActionEditSharedContactGroup);

    const quickActions = useMemo((): QuickAction<FlowAction>[][] => {
        const actions: QuickAction<FlowAction>[] = [];

        // Only push the edit action if the permission exists or it's not shared
        if (hasEditSharedContactGroupPermission || !isShared) {
            actions.push({
                icon: 'fa-solid-edit',
                type: FlowAction.Edit,
            });
        }

        // Only push the delete action if the permission exists or it's not shared
        if (hasDeleteSharedContactGroupPermission || !isShared) {
            actions.push({
                icon: 'fa-solid-trash',
                type: FlowAction.Delete,
            });
        }

        return [actions];
    }, [hasDeleteSharedContactGroupPermission, hasEditSharedContactGroupPermission, isShared]);

    const openQuickActionsModal = useCallback((actionType: FlowAction) => {
        switch (actionType) {
            case FlowAction.Edit:
                return onEditClick();
            case FlowAction.Delete:
                return onDeleteClick();
        }
    }, [onDeleteClick, onEditClick]);

    return (
        <div className={'c-contact-quick-actions'}>
            <QuickActionsTable actions={quickActions} handleActionClick={openQuickActionsModal} />
        </div>
    );
}

export default ContactGroupQuickActions;
