class AppStorage {

    constructor(private storageType: 'session' | 'local') {
    }

    get storage(): Storage {
        switch (this.storageType) {
            case 'session':
                return sessionStorage;
            case 'local':
                return localStorage;
        }
    }

    /**
     * Retrieve a certain key with value T from the local storage
     * @param key
     * @returns T
     */
    public get<T>(key: string): T | null {
        const value = this.storage.getItem(key);

        if (value == null) {
            return null;
        }

        return JSON.parse(value) as T;
    }

    /**
     * Remove a certain key from the local storage
     * @param key
     */
    public remove(key: string) {
        this.storage.removeItem(key);
    }

    /**
     * Set a value for a key in the local storage
     * @param key
     * @param value
     */
    public set(key: string, value: any) {
        const stringValue = JSON.stringify(value);

        this.storage.setItem(key, stringValue);
    }
}

export const AppSessionStorage = new AppStorage('session');
export const AppLocalStorage = new AppStorage('local');