import { Button } from '@progress/kendo-react-buttons';
import { Icon } from '@progress/kendo-react-common';
import classNames from 'classnames';
import { HTMLAttributes, useEffect, useRef, useState } from 'react';
import Typography, { TypographyToken } from '~components/typography';
import { NotificationOptions } from '~contexts/overlay';
import './toast.scss';

export interface ToastProps extends HTMLAttributes<HTMLDivElement> {
    type: NotificationOptions['type'];
    message?: string;
    title: string;
    /**
     * If defined default icon for type will be replaced
     */
    icon?: string;
    /**
     * should show close button
     */
    closeButton?: boolean;
    /**
     * called on close button click or after closeTimeoutMs (if defined)
     */
    onClose?: () => void;
    /**
     * percentage value of progressbar at the top of toast
     * overrides closeTimeoutMs rendering but not disables onClose event firing
     */
    progressValue?: number;
    /**
     * fires onClose after defined time and shows animated progressbar at the top (if progressValue is not defined)
     */
    closeTimeoutMs?: number;
}

const DefaultTypeIcon = {
    'success': <Icon className={'fa-regular fa-circle-check'} />,
    'warning': <Icon className={'fa-regular fa-triangle-exclamation'} />,
    'error': <Icon className={'fa-regular fa-circle-exclamation'} />,
    'general': <Icon className={'fa-regular fa-bell-on'} />,
    'pending': <Icon className={'fa-regular fa-arrows-repeat'} />,
};

function Toast({
    type,
    title,
    message,
    icon,
    closeButton = true,
    onClose,
    progressValue,
    closeTimeoutMs,
    children,
}: ToastProps) {
    const [timeCurrent, setTimeoutValue] = useState(Date.now());
    const timeStarted = useRef(Date.now());

    useEffect(() => {
        if (closeTimeoutMs === undefined) {
            return;
        }
        const timeout = setInterval(() => {
            setTimeoutValue(current => {
                const now = Date.now();

                if (now >= (timeStarted.current + closeTimeoutMs)) {
                    onClose?.();
                    clearInterval(timeout);
                }

                return now;
            });
        }, 10);

        return () => {
            clearInterval(timeout);
        };
    }, [closeTimeoutMs, onClose]);

    const currentProgressValue = progressValue !== undefined
        ? Math.max(Math.min(progressValue, 100), 0)
        : closeTimeoutMs !== undefined
            ? Math.min((timeCurrent - timeStarted.current) / closeTimeoutMs * 100, 100)
            : undefined;

    return (
        <div className={classNames(`c-toast c-toast--${type}`, { 'c-toast--with-progress': currentProgressValue !== undefined })}>
            {currentProgressValue !== undefined && (
                <div className={'c-toast__progress'}>
                    <div className={'c-toast__progress-indicator'} style={{ width: `${currentProgressValue}%` }}></div>
                </div>
            )}
            <div className={'c-toast__header'}>
                <div className={'c-toast__icon'}>
                    {icon ? <Icon className={icon} /> : DefaultTypeIcon[type]}
                </div>
                <div className={'c-toast__title'}>
                    <Typography token={TypographyToken.DesktopDescriptionBoldSm} text={title} />
                </div>
                {closeButton && (
                    <div className={'c-toast__close'}>
                        <Button
                            iconClass={'fa-solid fa-xmark'}
                            fillMode={'flat'}
                            size={'medium'}
                            onClick={onClose}
                        />
                    </div>
                )}
            </div>
            {message && (
                <div className={'c-toast__message'}>
                    <Typography token={TypographyToken.DesktopDescriptionSm} text={message} />
                </div>
            )}
            {children && (
                <div className={'c-toast__content'}>
                    {children}
                </div>
            )}
        </div>
    );
}

export default Toast;
