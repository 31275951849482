import { useCallback } from 'react';
import { FieldMarkerType } from '~constants';
import { FieldType } from '~constants/field-type';
import { useSetFlowState } from '~contexts/flow';
import { FlowActionType } from '~contexts/flow/flow.types';
import { StringUtils } from '~lib';
import { Guid, LocationModel } from '~models';

export const useAddElement = () => {
    const setFlowState = useSetFlowState();

    return useCallback((type: FieldType, actorLocalId: Guid, location?: LocationModel) => {
        const localId = StringUtils.guid();

        switch (type) {
            case FieldType.TextBox:
                setFlowState(FlowActionType.AddElement, {
                    actorLocalId,
                    element: {
                        localId,
                        type,
                        name: '',
                        isMultiline: false,
                        isOverridable: true, // TODO what's that?
                        markerType: FieldMarkerType.None,
                        location,
                    },
                });
                break;
            case FieldType.CheckBox:
                setFlowState(FlowActionType.AddElement, {
                    actorLocalId,
                    element: {
                        localId,
                        type,
                        name: '',
                        markerType: FieldMarkerType.None,
                        checkedByDefault: false,
                        location,
                    },
                });
                break;
            case FieldType.RadioGroup:
                setFlowState(FlowActionType.AddElement, {
                    actorLocalId,
                    element: {
                        localId,
                        type,
                        name: '',
                        markerType: FieldMarkerType.None,
                        options: [
                            {
                                localId: StringUtils.guid(),
                                name: '',
                                isSelected: true,
                                location,
                            },
                            {
                                localId: StringUtils.guid(),
                                name: '',
                                isSelected: false,
                                location,
                            },
                        ],
                    },
                });
                break;
            case FieldType.SigningField:
                setFlowState(FlowActionType.AddElement, {
                    actorLocalId,
                    element: {
                        localId,
                        type,
                        signingMethods: [],
                        markerType: FieldMarkerType.None,
                        location,
                    },
                });
                break;
        }

        return localId;
    }, [setFlowState]);
};
