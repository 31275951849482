import classNames from 'classnames';
import { IS_DEVELOPMENT_ENV } from '~constants';
import { DocumentViewerHeaderComponent, DocumentViewerPageModel, DocumentViewerPagePosition } from '../types';
import './documentViewerPage.scss';

export interface DocumentViewerPageProps {
    position: DocumentViewerPagePosition;
    page: DocumentViewerPageModel;
    pageData?: string;
    HeaderComponent?: DocumentViewerHeaderComponent;
}

function DocumentViewerPage({
    position,
    page,
    pageData,
    HeaderComponent,
}: DocumentViewerPageProps) {
    return (
        <div
            className={classNames('c-document-viewer-page', { 'c-document-viewer-page--loading': !pageData })}
            style={{
                top: `${position.offset.top}px`,
                left: `${position.offset.left}px`,
                width: `${position.size.width}px`,
                height: `${position.size.height}px`,
            }}
        >
            {HeaderComponent && (
                <div className={'c-document-viewer-page__header'} style={{ height: `${position.headerHeight}px` }}>
                    <HeaderComponent page={page} />
                </div>
            )}
            {pageData && (
                <img
                    draggable={false}
                    className={'c-document-viewer-page__img'}
                    src={`data:image/jpeg;base64,${pageData}`}
                    alt={`page ${page.pageIndex + 1} of ${page.documentId}`}
                />
            )}
            {IS_DEVELOPMENT_ENV && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        right: 'calc(100% + .5rem)',
                    }}
                >{page.absolutePageIndex + 1}</div>
            )}
        </div>
    );
}


export default DocumentViewerPage;
