import { TableCell, TableRow } from '@gonitro/rcl';
import { CheckboxValue } from '@gonitro/rcl/lib/checkbox';
import classNames from 'classnames';
import { ReactNode, useCallback } from 'react';
import { useTableContext } from '~contexts/table';
import { TableDataGeneric, TableDefinition } from '~contexts/table/table.types';
import './tableRowBuilder.scss';


export interface TableRowBuilderProps<
    TRowIdKey extends string, TData extends TableDataGeneric<TRowIdKey>> {
    definition: TableDefinition<TRowIdKey>;
    data: TData;
    renderColumns?: (data: TData) => Record<string, ReactNode>;
}

function TableRowBuilder<TRowIdKey extends string, TData extends TableDataGeneric<TRowIdKey>>({
    definition,
    data,
    renderColumns,
}: TableRowBuilderProps<TRowIdKey, TData>) {
    const { selectedRows, selectRows, unselectRows, setFocusedRow, isRowSelectable } = useTableContext();

    const onSelectRowClick = useCallback((_: any, value: CheckboxValue) => {
        if (value) {
            selectRows([data[definition.rowIdKey]]);
        } else {
            unselectRows([data[definition.rowIdKey]]);
        }
    }, [data, definition.rowIdKey, selectRows, unselectRows]);

    const onRowClick = useCallback(() => {
        setFocusedRow(data);
    }, [data, setFocusedRow]);

    const columns = renderColumns?.(data) || {};

    return (
        <TableRow
            className={classNames('c-table-row-builder', definition.headerClassName)}
            selectable={definition.selectable}
            isSelected={selectedRows.includes(data[definition.rowIdKey])}
            onRowSelectChange={onSelectRowClick}
            onRowClick={onRowClick}
            checkboxProps={{ disabled: isRowSelectable && !isRowSelectable(data) }}
        >
            {definition.columns.map((colDef, index) => {
                const content = columns[colDef.key] !== undefined
                    ? columns[colDef.key]
                    : colDef.dataKey
                        ? data[colDef.dataKey]
                        : data[colDef.key];

                return (
                    <TableCell
                        key={colDef.key}
                        useEllipsis={colDef.useEllipsis}
                        title={colDef.useEllipsis ? content : undefined}
                    >{content}</TableCell>
                );
            })}
        </TableRow>
    );
}

export default TableRowBuilder;
