import { NdsIconFont } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import Typography, { TypographyToken } from '~components/typography';
import './selected-name-item.scss';

export interface SelectedUserItemProps {
    alternateName: string;
    onClick: (alternateName: string) => void;
}

const SelectedNameItem = ({ alternateName, onClick }: SelectedUserItemProps) => {

    return (
        <div className='selected-name-item'>
            <Typography
                className='selected-name-item__list-item-name'
                token={TypographyToken.UiFormsLabelSm}
                text={`${alternateName}`}
            ></Typography>
            <NdsIconFont
                onClick={() => onClick(alternateName)}
                className='selected-name-item__delete-icon'
                fontName='fa-solid-minus-circle'
                size={SizesEnums.SMALL}
            />
        </div>
    );
};

export default SelectedNameItem;
