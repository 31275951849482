import { SortDirectionEnum } from '@gonitro/rcl/lib/_types';
import { SvgIcon } from '@progress/kendo-react-common';
import { ComboBox, ListItemProps } from '@progress/kendo-react-dropdowns';
import { InputPrefix } from '@progress/kendo-react-inputs';
import { searchIcon } from '@progress/kendo-svg-icons';
import { cloneElement, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { PortalsApi } from '~api/portals.api';
import { TemplateApi } from '~api/template.api';
import { useApi } from '~contexts/api';
import { useFlowContext } from '~contexts/flow';
import { FlowActionType } from '~contexts/flow/flow.types';
import { useCurrentLanguage, useTranslation } from '~contexts/i18n';
import { useLocationState } from '~hooks/router';
import { useVirtualizedCombobox } from '~hooks/virtualizedCombobox';
import { Guid } from '~models';
import { PageableListParams } from '~models/pageable-list.models';
import { TemplateListItemModel, TemplateListTableDataModel } from '~models/portals.models';
import { TemplateInfo } from '~models/template.models';
import './flowDocumentsTemplateSelector.scss';

export interface FlowDocumentsTemplateSelectorProps {

}

const PAGE_SIZE = 14;

const RenderItem = (itemProps: ListItemProps) => (
    <div className={'c-flow-documents-template-selector__list-item'}>
        <span className={'c-flow-documents-template-selector__template-name'}>{itemProps.dataItem.name}</span>
        <span className={'c-flow-documents-template-selector__template-code'}>{itemProps.dataItem.code}</span>
    </div>
);

function FlowDocumentsTemplateSelector(props: FlowDocumentsTemplateSelectorProps) {
    const portalApi = useApi(PortalsApi);
    const templateApi = useApi(TemplateApi);
    const { t } = useTranslation('flow');
    const { languageCode } = useCurrentLanguage();
    const { setFlowState, setProcessing, flowState: { workspaceId } } = useFlowContext();
    const locationState = useLocationState<{ templateToUse: TemplateListTableDataModel }>();

    const cachedTemplates = useRef<Record<Guid, TemplateInfo>>({});

    const [template, setTemplate] = useState<TemplateListItemModel | null>(null);

    const fetchData = useCallback(async (data: PageableListParams, signal?: AbortSignal) => {
        return portalApi.getTemplatesList({
            ...data,
            sortField: 'code',
            sortOrder: SortDirectionEnum.ASC,
            language: languageCode,
            workspaceId: workspaceId!,
        }, signal);
    }, [languageCode, portalApi, workspaceId]);

    const onTemplateChange = useCallback(async (value: TemplateListItemModel | null) => {
        setTemplate(value);

        if (!value) {
            setFlowState(FlowActionType.RemoveTemplate, undefined);
        } else {
            setProcessing(true);
            const response = cachedTemplates.current[value.templateId] ?? await templateApi.getBasicInfo({ flowId: value.templateId });

            cachedTemplates.current[value.templateId] = response;

            setProcessing(false);
            setFlowState(FlowActionType.SetTemplate, response);
        }
    }, [templateApi, setFlowState, setProcessing]);

    useEffect(() => {
        if (locationState.templateToUse) {
            onTemplateChange(locationState.templateToUse);
            delete locationState.templateToUse;
            window.history.replaceState(locationState, '');
        }
    }, [locationState, locationState.templateToUse, onTemplateChange]);

    const comboboxProps = useVirtualizedCombobox<TemplateListItemModel>({
        pageSize: PAGE_SIZE,
        apiFilterKey: 'searchString',
        fetchData,
        valueKey: 'templateId',
        labelKey: 'name',
        onChange: onTemplateChange,
        value: template,
        RenderItem,
    });

    return !workspaceId
        ? null
        : (
            <div className={'c-flow-documents-template-selector'}>
                <ComboBox
                    popupSettings={{
                        popupClass: 'c-flow-documents-template-selector__combobox-popup',
                        height: 203,
                    }}
                    placeholder={t('search-for-template')}
                    title={t('search-for-template')}
                    suggest
                    listNoDataRender={(element: ReactElement<HTMLDivElement>) =>
                        cloneElement(element, { ...element.props }, <div>{t('no-templates-found')}</div>)
                    }
                    prefix={() => (
                        <InputPrefix orientation='horizontal'>
                            <SvgIcon
                                icon={searchIcon}
                            />
                        </InputPrefix>

                    )}
                    {...comboboxProps}
                />
            </div>
        );
}

export default FlowDocumentsTemplateSelector;
