import { NdsButton } from '@gonitro/rcl';
import { NdsButtonTypesEnum } from '@gonitro/rcl/lib/_types';
import { SizesEnums } from '@gonitro/rcl/lib/_types/designsystem.enums';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { AuthApi } from '~api/auth.api';
import Error from '~components/error';
import Loader from '~components/loader';
import Typography, { TypographyToken } from '~components/typography';
import { useApi } from '~contexts/api';
import { useCurrentLanguage, useTranslation } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import LoginForm from './login-form';
import './login.view.scss';

export interface LoginViewProps {
}

function LoginView(props: LoginViewProps) {
    const { t } = useTranslation('unauthorized');
    const authApi = useApi(AuthApi);
    const { languageCode } = useCurrentLanguage();

    const [loading, setLoading] = useState(false);
    const [cloudLoginError, setCloudLoginError] = useState(false);
    const {
        applicationAuthenticationSettings: {
            isLocalRegistrationEnabled,
            cloudAccountProvidersSettings,
        },
    } = useSettings();

    const cloudProviders: { name: string, displayName: string; }[] = useMemo(() => {
        return cloudAccountProvidersSettings
            .filter((el) => el.enabled && el.isVisible)
            .map((el) => ({
                name: el.name,
                displayName: el.snippet.value,
            }));
    }, [cloudAccountProvidersSettings]);

    const onCloudLoginClick = useCallback(async (providerName: string) => {
        setLoading(true);
        try {
            window.location.href = await authApi.getCloudAccountProviderRedirectUrl({
                providerName,
                language: languageCode,
            });
        } catch (e) {
            setCloudLoginError(true);
            setLoading(false);
        }
    }, [authApi, languageCode]);

    useEffect(() => {
        if (!isLocalRegistrationEnabled && cloudProviders.length === 1) {
            onCloudLoginClick(cloudProviders[0].name);
        }
    }, [cloudProviders, isLocalRegistrationEnabled, onCloudLoginClick]);

    return (
        <div className='v-login'>
            <div className={'v-login__header'}>
                <Typography
                    tagName={'h1'}
                    token={TypographyToken.MobileHeaderLg}
                    mediumScreenToken={TypographyToken.DesktopHeaderLg}
                    text={t('title-welcome-back-to-nitro')}
                />
                <Typography
                    tagName={'p'}
                    token={TypographyToken.MobileDescriptionLg}
                    mediumScreenToken={TypographyToken.DesktopDescriptionLg}
                    text={t('log-in-to-continue')}
                />
            </div>
            {loading && (
                <Loader className={'v-login__loader'} size={SizesEnums.XLARGE} />
            )}
            <div className={classNames('v-login__content', { 'v-login__content--hidden': loading })}>
                {cloudLoginError && (
                    <Error i18nKey={'something-went-wrong'} />
                )}
                {cloudProviders.length > 0 && (
                    cloudProviders.map((provider) => (
                        <NdsButton
                            key={provider.name}
                            buttonType={NdsButtonTypesEnum.SECONDARY}
                            size={SizesEnums.SMALL}
                            displayBlock
                            label={t('login-with-sso', { providerName: provider.displayName })}
                            onClick={() => onCloudLoginClick(provider.name)}
                        />
                    ))
                )}
                {cloudProviders.length > 0 && isLocalRegistrationEnabled && (
                    <div className='v-login__divider'>{t('or')}</div>
                )}
                {isLocalRegistrationEnabled && ( // without local registration there is no possibility to log in with local account
                    <LoginForm loading={loading} setLoading={setLoading} />
                )}
            </div>
        </div>
    );
}

export default LoginView;