import { Switch } from '@progress/kendo-react-inputs';
import classNames from 'classnames';
import Typography, { TypographyToken } from '~components/typography';
import { useTranslation } from '~contexts/i18n';
import { GeneralToggle } from './useGeneralToggles';
import './general-tab.scss';


export interface GeneralTabProps {
    handleToggleChange: (selectedToggle: GeneralToggle) => void;
    toggles: GeneralToggle[];
}

const GeneralTab = ({ handleToggleChange, toggles }: GeneralTabProps) => {
    const { t } = useTranslation('user-management');

    return (
        <div className={'general-tab'}>
            <div className={'general-tab__toggle-container'}>
                {toggles.map((toggle) => (
                    <div key={toggle.permission} className='general-tab__toggle-section'>
                        <Typography
                            className={classNames('general-tab__toggle-label', { 'general-tab__toggle-label-parent': !toggle.parentId })}
                            token={TypographyToken.UiButtonsSm}>{t(toggle.label)}
                        </Typography>
                        <Switch
                            checked={toggle.hasPermission}
                            disabled={toggle.disabled}
                            onChange={() => handleToggleChange(toggle)} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default GeneralTab;
