import { useMemo, useState } from 'react';
import QuickActionsTable from '~components/quick-actions-table';
import { QuickAction } from '~components/quick-actions-table/quickActionsTable';
import { DocumentGroupPermission, FlowAction, FlowStatusEnum } from '~constants';
import { useWorkspaceId } from '~contexts/app';
import { useHasDocumentGroupPermissionFunc } from '~contexts/auth';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { useDocumentQuickActions } from '~hooks/useDocumentQuickActions';
import { PackagesTableDataModel } from '~models/portals.models';
import QuickActionsModal from '../../../../components/quick-actions-modal';

export interface DocumentPortalQuickActionsProps {
    data: PackagesTableDataModel;
}

function DocumentPortalQuickActions({ data }: DocumentPortalQuickActionsProps) {
    const workspaceId = useWorkspaceId()!;
    const hasDocGroupPermission = useHasDocumentGroupPermissionFunc();
    const [quickActionModalData, setQuickActionModalData] = useState<{
        data: PackagesTableDataModel | undefined, actionType: FlowAction | undefined;
    }>({
        data: undefined,
        actionType: undefined,
    });

    const closeModal = () => {
        setQuickActionModalData({
            data: undefined,
            actionType: undefined,
        });
    };
    const { handleActionClick, QuickActionsNoModal, WarningMessageDialogI18n,  AdditionalInformationI18n } = useDocumentQuickActions( data, closeModal);

    const quickActions = useMemo((): QuickAction<FlowAction>[][] => {
        // Pending status has different actions based on conditions (permissions, amount of signers).
        //  The group array help build the list
        const pendingParentGroup: QuickAction<FlowAction>[][] = [];

        const pendingGroup2: QuickAction<FlowAction>[] = [];

        const pendingGroup3: QuickAction<FlowAction>[] = [];

        switch (data.status.toUpperCase()) {
            case FlowStatusEnum.Processing:
                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Upload)) {
                    pendingParentGroup.push([
                        {
                            icon: 'fa-solid-edit',
                            type: FlowAction.Edit,
                        },
                    ]);
                }

                return pendingParentGroup;
            case FlowStatusEnum.ProcessingFailed:
            case FlowStatusEnum.Draft:
                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Upload)) {
                    pendingParentGroup.push([
                        {
                            icon: 'fa-solid-edit',
                            type: FlowAction.Edit,
                        },
                    ]);
                }
                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Delete)) {
                    pendingParentGroup.push([
                        {
                            icon: 'fa-solid-trash',
                            type: FlowAction.Delete,
                        },
                    ]);
                }

                return pendingParentGroup;
            case FlowStatusEnum.PendingApproval:
            case FlowStatusEnum.PendingFormFill:
                pendingParentGroup.push([
                    {
                        icon: 'fa-solid-eye',
                        type: FlowAction.Preview,
                    },
                ]);

                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Audit)) {
                    pendingGroup2.push({
                        type: FlowAction.RequestAuditTrail,
                        icon: 'fa-solid-file-lines',
                    });
                }
                pendingParentGroup.push(pendingGroup2);

                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Upload)) {
                    if (data.totalSigner && data.totalSigner > 0) {
                        pendingGroup3.push({
                            type: FlowAction.SendToSigners,
                            icon: 'fa-solid-paper-plane',
                        });
                    } else {
                        pendingGroup3.push({
                            type: FlowAction.End,
                            icon: 'fa-solid-circle-stop',
                        });
                    }

                    pendingParentGroup[0].push(
                        {
                            icon: 'fa-solid-bell',
                            type: FlowAction.SendReminder,
                        },
                        {
                            type: FlowAction.Reassign,
                            icon: 'fa-solid-arrow-right',
                        },
                    );

                    pendingGroup3.push({
                        type: FlowAction.Revoke,
                        icon: 'fa-solid-arrow-left-to-line',
                    });
                    pendingParentGroup.push(pendingGroup3);
                }

                return pendingParentGroup;
            case FlowStatusEnum.PendingSigning:
                pendingParentGroup.push([
                    {
                        icon: 'fa-solid-eye',
                        type: FlowAction.Preview,
                    },
                ]);

                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Upload)) {
                    pendingGroup3.push({
                        type: FlowAction.End,
                        icon: 'fa-solid-circle-up',
                    }, {
                        type: FlowAction.Revoke,
                        icon: 'fa-solid-arrow-left-to-line',
                    });
                    pendingParentGroup[0].push(
                        {
                            icon: 'fa-solid-bell',
                            type: FlowAction.SendReminder,
                        },
                        {
                            type: FlowAction.Reassign,
                            icon: 'fa-solid-arrow-right',
                        },
                    );
                }
                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Sign)) {
                    pendingParentGroup[0].push({
                        type: FlowAction.Sign,
                        icon: 'fa-solid-pen-nib',
                    });
                }
                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Audit)) {
                    pendingGroup2.push({
                        type: FlowAction.RequestAuditTrail,
                        icon: 'fa-solid-file-lines',
                    });
                }
                if (pendingGroup2.length) {
                    pendingParentGroup.push(pendingGroup2);
                }

                if (pendingGroup3.length) {
                    pendingParentGroup.push(pendingGroup3);
                }


                return pendingParentGroup;
            case FlowStatusEnum.Archived:
            case FlowStatusEnum.Finished: {
                const group: QuickAction<FlowAction>[] = [];

                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Download)) {
                    group.push({
                        icon: 'fa-solid-download',
                        type: FlowAction.Download,
                    });
                }

                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Audit)) {
                    group.push({
                        type: FlowAction.RequestAuditTrail,
                        icon: 'fa-solid-file-lines',
                    });
                }
                if (group.length) {
                    pendingParentGroup.push(group);
                }

                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Delete)) {
                    pendingParentGroup.push([
                        {
                            icon: 'fa-solid-trash',
                            type: FlowAction.Delete,
                        },
                    ]);
                }

                return pendingParentGroup;
            }
            case FlowStatusEnum.Expired:
                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Upload)) {
                    pendingParentGroup.push(
                        [
                            {
                                icon: 'fa-solid-calendar-circle-plus',
                                type: FlowAction.Extend,
                            },
                        ],
                        [
                            {
                                type: FlowAction.Revoke,
                                icon: 'fa-solid-arrow-left-to-line',
                            },
                        ],
                    );
                }
                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Audit)) {
                    pendingParentGroup.splice(1, 0, [
                        {
                            type: FlowAction.RequestAuditTrail,
                            icon: 'fa-solid-file-lines',
                        },
                    ]);
                }

                return pendingParentGroup;
            case FlowStatusEnum.Rejected:
            case FlowStatusEnum.Revoked:
                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Audit)) {
                    pendingParentGroup.push([
                        {
                            type: FlowAction.RequestAuditTrail,
                            icon: 'fa-solid-file-lines',
                        },
                    ]);
                }

                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Delete)) {
                    pendingParentGroup.push([
                        {
                            icon: 'fa-solid-trash',
                            type: FlowAction.Delete,
                        },
                    ]);
                }

                return pendingParentGroup;
            case FlowStatusEnum.Failed:
                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Audit)) {
                    pendingParentGroup.push([
                        {
                            type: FlowAction.RequestAuditTrail,
                            icon: 'fa-solid-file-lines',
                        },
                    ]);
                }

                if (hasDocGroupPermission(workspaceId, DocumentGroupPermission.Upload)) {
                    pendingParentGroup.push([
                        {
                            type: FlowAction.Revoke,
                            icon: 'fa-solid-arrow-left-to-line',
                        },
                    ]);
                }

                return pendingParentGroup;
            default:
                return [[]];
        }
    }, [data.status, data.totalSigner, hasDocGroupPermission, workspaceId]);

    const openQuickActionsModal = (actionType: FlowAction) => {
        if (QuickActionsNoModal.includes(actionType)) {
            handleActionClick(actionType);
        } else {
            setQuickActionModalData({
                data: data,
                actionType: actionType,
            });
        }
    };

    return (
        <>
            <QuickActionsTable actions={quickActions} handleActionClick={openQuickActionsModal} />
            <OverlayPortal id='documentPortalQuickActionsModal' type={'modal'} visible={quickActionModalData.data !== undefined}>
                {({ close }) =>
                    <QuickActionsModal
                        data={quickActionModalData.data}
                        actionType={quickActionModalData.actionType}
                        handleCloseModal={() => {
                            closeModal(); close();
                        }}
                        handleClickConfirm={() => {
                            handleActionClick(quickActionModalData.actionType!);
                        }}
                        additionalInformation={AdditionalInformationI18n[quickActionModalData.actionType!]}
                        warningMessage={WarningMessageDialogI18n[quickActionModalData.actionType!]}
                    />
                }
            </OverlayPortal>
        </>
    );
}

export default DocumentPortalQuickActions;
