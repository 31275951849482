import { useLayoutEffect, useState } from 'react';

export function useDocumentViewerRenderIndexes(
    visiblePageIndexes: number[],
    renderPagesQuantity: number,
    maxIndex: number,
) {
    // ensure that enough pages are rendered if renderPagesQuantity is too low or pages are small so many will fit in viewport
    const renderHalfQuantity = Math.ceil(Math.max(visiblePageIndexes.length * 2, renderPagesQuantity) / 2);

    const [renderIndexes, setRenderIndexes] = useState<[number, number, number]>([-1, -1, -1]);

    useLayoutEffect(() => {
        if (!visiblePageIndexes.length) {
            return;
        }
        const middlePageIndex = visiblePageIndexes[Math.floor(visiblePageIndexes.length / 2)];

        const firstPageIndex = Math.max(middlePageIndex - renderHalfQuantity, 0);
        const lastPageIndex = Math.min(middlePageIndex + renderHalfQuantity, maxIndex);

        setRenderIndexes([firstPageIndex, middlePageIndex, lastPageIndex]);
    }, [maxIndex, renderHalfQuantity, visiblePageIndexes]);

    return renderIndexes;
}
