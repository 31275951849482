import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { useCallback, useState } from 'react';
import { PortalsApi } from '~api/portals.api';
import PackageDetailsDropdown from '~components/package-details-dropdown';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { PanelPortal } from '~contexts/overlay/overlay.components/panelPortal.overlay.component';
import { useTableFocusedRow } from '~contexts/table';
import { useAsyncEffect } from '~hooks/effects';
import { ApproverModel, FormFillerModel, ReceiverModel, SignerModel } from '~models';
import { PackageDetailDocument, PackageDetails } from '~models/package.models';
import { PackageListItemModel } from '~models/portals.models';
import PackageDetailsContainer from './package-details-container';
import Header from './package-details-header';
import Loader from '../loader';
import './package-details-overview-panel.scss';

export interface PackageDetailsOverviewPanelProps {
}

const PackageDetailsOverviewPanel = () => {
    const portalsApi = useApi(PortalsApi);
    const { focusedRow: packageData, setFocusedRow } = useTableFocusedRow<'packageId', PackageListItemModel>();
    const { t } = useTranslation('flow');
    const [packageDetails, setPackageDetails] = useState<PackageDetails>();
    const [signingFlowDetails, setSigningFlowDetails] = useState<any>();
    const [selectedDocument, setSelectedDocument] = useState<PackageDetailDocument>();
    const {
        formFillers = [],
        approvers = [],
        signers = [],
        additionalReceivers = [],
    } = signingFlowDetails || {};


    const getDocumentsDetail = useCallback(async (id: string) => {
        const documentDetails = await portalsApi.getDocumentDetail({ documentSignigFlowId: id });

        setSigningFlowDetails(documentDetails);
    }, [portalsApi]);


    useAsyncEffect(() => async (signal) => {
        if (!packageData) {
            return;
        }
        const response = await portalsApi.getPackageDetails({ packageId: packageData.packageId }, signal);

        setSelectedDocument(response.documents[0]);
        getDocumentsDetail(response.documents[0].signingFlowId);
        setPackageDetails(response);
    }, [getDocumentsDetail, packageData, portalsApi]);

    const onClose = useCallback(() => {
        setFocusedRow(undefined);
        setPackageDetails(undefined);
        setSigningFlowDetails(undefined);
    }, [setFocusedRow]);

    const updateSigningFlowDetails = (document: any) => {
        setSelectedDocument(document);
        getDocumentsDetail(document.signingFlowId);
    };

    const renderDocumentUserDetails = (title: string, userList: SignerModel[] | ReceiverModel[] | ApproverModel[] | FormFillerModel[]) => {
        return userList.length > 0 && <PackageDetailsDropdown title={title} userList={userList} openDropDown={true}/>;
    };

    return (
        <PanelPortal visible={!!packageData} onClose={onClose}>
            {
                (packageDetails && packageData) ?
                    <div className='c-package-details-overview-panel'>
                        <Header
                            packageName={packageData.documentName}
                            packageStatus={packageData.status}
                            expiryDate={packageDetails.expirationTimestamp}
                        />
                        <PackageDetailsContainer
                            packageDetails={packageDetails}
                            packageData={packageData}
                            setDocument={(document) => updateSigningFlowDetails(document)}
                            selectedDocument={selectedDocument}
                        />
                        {renderDocumentUserDetails(t('form-fillers'), formFillers)}
                        {renderDocumentUserDetails(t('approvers'), approvers)}
                        {renderDocumentUserDetails(t('signers'), signers)}
                        {renderDocumentUserDetails(t('receivers'), additionalReceivers)}
                    </div>
                    :
                    <div className='c-package-details-overview-panel__loader'>
                        <Loader center size={SizesEnums.XLARGE} />
                    </div>
            }
        </PanelPortal>
    );
};

export default PackageDetailsOverviewPanel;
