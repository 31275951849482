import { Chip } from '@progress/kendo-react-buttons';
import classNames from 'classnames';
import { ChangeEvent, KeyboardEvent, useState } from 'react';
import Typography, { TypographyToken } from '~components/typography';
import './text-area-with-chips.scss';

export interface TextAreaWithChipsProps {
    onChange: (input: string[]) => void;
    value: string[];
    label?: string;
    validate?: (input: string) => boolean | undefined;
    errorMessage?: string;
    chips: string[];
}


function TextAreaWithChips({ onChange, value, label, validate, errorMessage, chips }: TextAreaWithChipsProps) {

    const [inputValue, setInputValue] = useState<string>('');
    const [error, setError] = useState<string>('');

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setError('');
        setInputValue(event.target.value);
    };

    const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {

        if (event.key !== 'Enter' || inputValue.trim() === '') {
            return;
        }

        event.preventDefault();

        if (validate && !validate(inputValue)) {
            if (errorMessage) {
                setError(errorMessage);
            }

            return;
        }

        onChange([...value, inputValue.trim()]);
        setInputValue('');
    };

    const handleInputBlur = () => {
        if (chips.length > 0 && inputValue.trim() === '') {
            return;
        }
        if (validate && !validate(inputValue)) {
            if (errorMessage) {
                setError(errorMessage);
            }

            return;
        }

        onChange([...value, inputValue.trim()]);
        setInputValue('');
    };

    const handleChipRemove = (index: number) => {
        onChange(value.filter((_, i) => i !== index));
    };

    return (
        <div className='c-text-area-with-chips'>
            <Typography
                token={TypographyToken.DesktopDescriptionMd}
                text={label}
                tagName={'label'}
                className={'c-text-area-with-chips__label'}
                htmlFor='text-with-chips-input'
            />
            <div className={classNames('c-text-area-with-chips__display', { 'c-text-area-with-chips__display--error': error })}>
                {value.map((chip, index) =>
                    <Chip
                        text={chip}
                        className='c-text-area-with-chips__chip'
                        onClick={() => handleChipRemove(index)}
                        key={index}
                        removable={true}
                    />)}
                <Typography
                    token={TypographyToken.UiFormsInputSm}
                    tagName={'input'}
                    className={'c-text-area-with-chips__text-input'}
                    id='text-with-chips-input'
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleInputKeyDown}
                    onBlur={handleInputBlur}
                />
            </div>
            {error
                ? <Typography
                    token={TypographyToken.DesktopDescriptionMd}
                    text={error}
                    className={'c-text-area-with-chips__error-hint'}
                />
                : <div className='c-text-area-with-chips__error-spacing' />}
        </div>
    );
}

export default TextAreaWithChips;
