import { IApi } from '~contexts/api';
import Http from '~lib/http';
import {
    CreateAlternateUserNameModel,
    CreateDocumentGroupModel,
    CreateUserGroupModel,
    DeleteAlternateNameModel,
    DeleteDocumentGroupModel,
    DeleteInvitedUserModel,
    DeleteUserGroupModel,
    DeleteUserModel,
    GetAlternateNamesModel,
    GetEditedDocumentGroupPermissionsModel,
    GetEditedThemesModel,
    GetEditedUserGroupPermissionsModel,
    GetMemberUserGroupsModel,
    GetUserGroupPermissionsModel,
    GetUserModel,
    GetUsersByUserGroupModel,
    ReinviteUserModel,
    UpdateDocumentGroupModel,
    UpdateDocumentGroupPermissionModel,
    UpdateMemberUserGroupModel,
    UpdateUserGroupMembersModel,
    UpdateUserGroupModel,
    UpdateUserGroupNameModel,
    UpdateUserGroupPermissionChangesInDocumentGroupModel,
    UpdateUserGroupPermissionModel,
    UpdateUserGroupThemeChangesInDocumentGroupModel,
    UpdateUserModel,
} from './user-management.models';

export class UserManagementApi implements IApi {
    constructor(private http: Http) {
    }

    async getUser({ userId }: GetUserModel.Params, signal?: AbortSignal): Promise<GetUserModel.Result> {
        const response = await this.http
            .get(`/portalapi/v1/config/users/${userId}`)
            .execute(signal);

        const parsed = await response.jsonAs<GetUserModel.Result>();

        parsed.absencePeriods.forEach(item => {
            item.substitutes.forEach(sub => {
                sub.actionTypes = sub.actionTypes.map((str: string) => str.toUpperCase());
            });
        });

        return parsed;
    }


    async updateUser(data: UpdateUserModel.Params, signal?: AbortSignal): Promise<UpdateUserModel.Result> {
        const {
            signatureImageB64,
            avatarb64,
            cloudProviderName,
            company,
            defaultPhoneNumberCountry,
            emailAddress,
            firstName,
            lastName,
            id,
            language,
            absencePeriods,
            isActive,
        } = data;
        const response = await this.http
            .put(`/portalapi/v1/config/users/${id}`)
            .withJsonBody<UpdateUserModel.Body>({
                Avatarb64: avatarb64,
                CloudProviderName: cloudProviderName,
                SignatureImageB64: signatureImageB64,
                Company: company,
                IsActive: isActive,
                DefaultPhoneNumberCountry: defaultPhoneNumberCountry,
                Email: emailAddress,
                FirstName: firstName,
                Id: id,
                Language: language,
                LastName: lastName,
                absencePeriods: absencePeriods,
            })
            .execute(signal);

        return await response.ok;
    }

    async updateUserGroup(data: UpdateUserGroupModel.Params, signal?: AbortSignal): Promise<UpdateUserGroupModel.Result> {
        try {
            const { userGroupId, userId, isMember } = data;
            const response = await this.http
                .put(`portalapi/v1/config/usergroups/${userGroupId}/users/${userId}`)
                .withJsonBody<UpdateUserGroupModel.Body>({ isMember })
                .execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }
    async deleteUser(userId: DeleteUserModel.Params, signal?: AbortSignal): Promise<DeleteUserModel.Result> {
        try {
            const response = await this.http.delete(`/portalapi/v1/config/users/${userId.userId}`).execute(signal);

            return response.ok;
        } catch (error) {
            console.warn(error);
            throw(error);
        }
    }

    async reinviteUser(userEmail: ReinviteUserModel.Params, signal?: AbortSignal) {
        const response = await this.http.put('/portalapi/v1/config/users/invite/resend').withJsonBody<ReinviteUserModel.Body>(userEmail).execute(signal);

        return response.ok;
    }

    async deleteInvitedUser(userEmail: DeleteInvitedUserModel.Params, signal?: AbortSignal) {
        const response = await this.http.delete('/portalapi/v1/config/users/invite').withJsonBody<DeleteInvitedUserModel.Body>(userEmail).execute(signal);

        return response.ok;
    }
    async getMemberUserGroups(
        userId: GetMemberUserGroupsModel.Params, signal?: AbortSignal,
    ): Promise<GetMemberUserGroupsModel.Result> {
        try {
            const response = await this.http
                .get(`/portalapi/v1/config/users/${userId.userId}/userGroups`)
                .execute(signal);
            const parsed = await response.jsonAs<GetMemberUserGroupsModel.Result>(true);

            return parsed;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async updateMemberUserGroups(
        data: UpdateMemberUserGroupModel.Params, signal?: AbortSignal,
    ): Promise<UpdateMemberUserGroupModel.Result> {
        try {
            const { userId, userGroupIds } = data;
            const response = await this.http
                .put(`/portalapi/v1/config/users/${userId}/userGroups`)
                .withJsonBody<UpdateMemberUserGroupModel.Body>({ userGroupIds })
                .execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async createUserGroup(
        name: CreateUserGroupModel.Params, signal?: AbortSignal,
    ): Promise<CreateUserGroupModel.Result> {
        try {
            const response = await this.http
                .post('/portalapi/v1/config/usergroups')
                .withJsonBody<CreateUserGroupModel.Body>(name)
                .execute(signal);

            const parsed = await response.jsonAs<CreateUserGroupModel.Result>(true);

            return parsed;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async getUserGroupPermissions(
        userGroupId: GetUserGroupPermissionsModel.Params, signal?: AbortSignal,
    ): Promise<GetUserGroupPermissionsModel.Result> {
        try {
            const response = await this.http
                .get(`/portalapi/v1/config/userGroups/${userGroupId.userGroupId}/permissions`)
                .execute(signal);

            const parsed = await response.jsonAs<GetUserGroupPermissionsModel.Result>(true);

            return parsed;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async getUsersByUserGroup(
        data: GetUsersByUserGroupModel.Params, signal?: AbortSignal,
    ): Promise<GetUsersByUserGroupModel.Result> {
        try {
            const response = await this.http
                .get(`/portalapi/v1/config/usergroups/${data.userGroupId}/users`)
                .withParams({
                    continuationToken: data.page - 1,
                    maxQuantity: data.maxQuantity,
                    isMember: data.isMember,
                    ...data.filterValues,
                })
                .execute(signal);
            const parsed = await response.jsonAs<GetUsersByUserGroupModel.Response>(true);

            return {
                nextPage: parseInt(parsed.continuationToken),
                items: parsed.items,
                totalItems: parsed.total,
                itemsPerPage: parsed.maxQuantity,
            };
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async updateUserGroupMember(
        data: UpdateUserGroupMembersModel.Params, signal?: AbortSignal,
    ): Promise<UpdateUserGroupMembersModel.Result> {
        try {
            const { userId, userGroupId, isMember } = data;
            const response = await this.http
                .put(`/portalapi/v1/config/usergroups/${userGroupId}/users/${userId}`)
                .withJsonBody<UpdateUserGroupMembersModel.Body>({ isMember })
                .execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }
    async updateUserGroupPermission(
        data: UpdateUserGroupPermissionModel.Params, signal?: AbortSignal,
    ): Promise<UpdateUserGroupPermissionModel.Result> {
        try {
            const { permissionId, userGroupId, hasPermission } = data;
            const response = await this.http
                .put(`/portalapi/v1/config/usergroups/${userGroupId}/permissions/${permissionId}`)
                .withJsonBody<UpdateUserGroupPermissionModel.Body>({ hasPermission })
                .execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async createDocumentGroup(
        data: CreateDocumentGroupModel.Params, signal?: AbortSignal,
    ): Promise<CreateDocumentGroupModel.Result> {
        try {
            const { name } = data;
            const response = await this.http
                .post('/portalapi/v1/config/documentgroups')
                .withJsonBody<CreateDocumentGroupModel.Body>({ name })
                .execute(signal);

            return response.jsonAs<CreateDocumentGroupModel.Result>(true);
        } catch (error) {

            console.warn(error);
            throw error;
        }
    }

    async updateDocumentGroup(
        { name, documentGroupId }: UpdateDocumentGroupModel.Params, signal?: AbortSignal,
    ): Promise<boolean> {
        try {
            const response = await this.http
                .put(`/portalapi/v1/config/documentgroups/${documentGroupId}`)
                .withJsonBody<UpdateDocumentGroupModel.Body>({ name })
                .execute(signal);

            return response.ok;
        } catch (error) {

            console.warn(error);
            throw error;
        }
    }

    async deleteDocumentGroup(
        { documentGroupId }: DeleteDocumentGroupModel.Params, signal?: AbortSignal,
    ): Promise<boolean> {
        try {
            const response = await this.http
                .delete(`/portalapi/v1/config/documentgroups/${documentGroupId}`)
                .execute(signal);

            return response.ok;
        } catch (error) {

            console.warn(error);
            throw error;
        }
    }

    async getUserGroupsByDocumentGroup(
        { documentGroupId, page, maxQuantity }: GetEditedUserGroupPermissionsModel.Params,
        signal?: AbortSignal,
    ): Promise<GetEditedUserGroupPermissionsModel.Result> {
        try {
            const response = await this.http
                .get(`/portalapi/v1/config/documentgroups/${documentGroupId}/usergroups`)
                .withParams({
                    isMember: true,
                    continuationToken: page - 1,
                    maxQuantity: maxQuantity,
                })
                .execute(signal);

            return response.jsonAs<GetEditedUserGroupPermissionsModel.Result>(true);
        } catch (error) {
            console.warn(error);
            throw error;
        }
    }

    async getThemesByDocumentGroup(
        { documentGroupId }: GetEditedThemesModel.Params,
        signal?: AbortSignal,
    ): Promise<GetEditedThemesModel.Result> {
        try {
            const response = await this.http
                .get(`/portalapi/v1/config/documentgroups/${documentGroupId}/themes`)
                .execute(signal);

            return response.jsonAs<GetEditedThemesModel.Result>(true);
        } catch (error) {
            console.warn(error);
            throw error;
        }
    }


    async updateUserGroupPermissionChangesInDocumentGroup(
        data: UpdateUserGroupPermissionChangesInDocumentGroupModel.Params, signal?: AbortSignal,
    ): Promise<UpdateUserGroupPermissionChangesInDocumentGroupModel.Result> {
        try {
            const { documentGroupId, permissionId, userGroupId, hasPermission } = data;
            const response = await this.http
                .put(`/portalapi/v1/config/documentgroups/${documentGroupId}/usergroups/${userGroupId}/permissions/${permissionId}`)
                .withJsonBody<UpdateUserGroupPermissionChangesInDocumentGroupModel.Body>({ hasPermission })
                .execute(signal);

            return response.ok;
        } catch (error) {
            console.warn(error);
            throw error;
        }
    }

    async updateUserGroupThemeChangesInDocumentGroup(
        data: UpdateUserGroupThemeChangesInDocumentGroupModel.Params, signal?: AbortSignal,
    ): Promise<UpdateUserGroupThemeChangesInDocumentGroupModel.Result> {
        const { documentGroupId, defaultTheme, themes } = data;

        try {
            const response = await this.http
                .put(`/portalapi/v1/config/documentgroups/${documentGroupId}/themes`)
                .withJsonBody<UpdateUserGroupThemeChangesInDocumentGroupModel.Body>({
                    defaultTheme: { themeId: defaultTheme.themeId },
                    themes: themes.map((theme) => {
                        return {
                            name: theme.name,
                            themeId: theme.themeId,
                        };
                    }),
                })
                .execute(signal);

            return response.ok;
        } catch (error) {
            console.warn(error);
            throw error;
        }
    }

    async updateDocumentGroupPermission(
        data: UpdateDocumentGroupPermissionModel.Params, signal?: AbortSignal,
    ): Promise<UpdateDocumentGroupPermissionModel.Result> {
        try {
            const { permissionId, userGroupId, documentGroupId, hasPermission } = data;
            const response = await this.http
                .put(`/portalapi/v1/config/usergroups/${userGroupId}/documentgroups/${documentGroupId}/permissions/${permissionId}`)
                .withJsonBody<UpdateDocumentGroupPermissionModel.Body>({ hasPermission })
                .execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async createAlternateUserName(
        data: CreateAlternateUserNameModel.Params, signal?: AbortSignal,
    ): Promise<CreateAlternateUserNameModel.Result> {
        const { name, userGroupId } = data;
        try {
            const response = await this.http
                .post(`/portalapi/v1/config/usergroups/${userGroupId}/names`)
                .withJsonBody<CreateAlternateUserNameModel.Body>({ name })
                .execute(signal);

            return response.ok;

        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async updateUserGroupName(
        data: UpdateUserGroupNameModel.Params, signal?: AbortSignal,
    ): Promise<UpdateUserGroupNameModel.Result> {
        try {
            const { userGroupId, name } = data;
            const response = await this.http
                .put(`/portalapi/v1/config/usergroups/${userGroupId}`)
                .withJsonBody<UpdateUserGroupNameModel.Body>({ name })
                .execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async getEditedDocumentGroupPermissions(
        data: GetEditedDocumentGroupPermissionsModel.Params, signal?: AbortSignal,
    ): Promise<GetEditedDocumentGroupPermissionsModel.Result> {
        try {
            const response = await this.http

                .get(`/portalapi/v1/config/usergroups/${data.userGroupId}/documentgroups`)
                .withParams({
                    continuationToken: data.page - 1,
                    maxQuantity: data.maxQuantity,
                    ...data.filterValues,
                })
                .execute(signal);
            const parsed = await response.jsonAs<GetEditedDocumentGroupPermissionsModel.Response>(true);

            return {
                nextPage: parseInt(parsed.continuationToken),
                items: parsed.items,
                totalItems: parsed.total,
                itemsPerPage: parsed.maxQuantity,
            };
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async getAlternateNames(
        userGroupId: GetAlternateNamesModel.Params, signal?: AbortSignal,
    ): Promise<GetAlternateNamesModel.Result> {
        try {
            const response = await this.http
                .get(`/portalapi/v1/config/usergroups/${userGroupId.userGroupId}/names`)
                .execute(signal);

            const parsed = await response.jsonAs<GetAlternateNamesModel.Result>(true);

            return parsed;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async deleteAlternateName(
        data: DeleteAlternateNameModel.Params, signal?: AbortSignal,
    ): Promise<DeleteAlternateNameModel.Result> {
        const { userGroupId, name } = data;
        try {
            const response = await this.http
                .delete(`/portalapi/v1/config/usergroups/${userGroupId}/names/${name}`)
                .execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async deleteUserGroup(
        userGroupId: DeleteUserGroupModel.Params,
        signal?: AbortSignal,
    ): Promise<DeleteUserGroupModel.Result> {
        try {
            const response = await this.http.delete(`portalapi/v1/config/usergroups/${userGroupId.userGroupId}`).execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }
}
