import { RefObject, useCallback, useMemo, useRef } from 'react';
import { Offset, Size } from '~models';
import DocumentViewerPageObject from './documentViewerPageObject';
import { DocumentViewerPageModel, DocumentViewerPageObjectModel, DocumentViewerPagePosition } from '../types';


export interface DocumentViewerPageObjectsProps {
    containerRef: RefObject<HTMLDivElement>,
    pageObjects: DocumentViewerPageObjectModel[];
    pages: DocumentViewerPageModel[];
    pagePositions: DocumentViewerPagePosition[];
    firstRenderIndex: number;
    middleRenderIndex: number;
    lastRenderIndex: number;
    scale: number;
    justifyPages: boolean;
}

function DocumentViewerPageObjects({
    containerRef,
    pageObjects,
    pages,
    pagePositions,
    firstRenderIndex,
    lastRenderIndex,
    scale,
    justifyPages,
}: DocumentViewerPageObjectsProps) {
    const currentDragged = useRef<string>();

    const indexedPageCache: Record<string, DocumentViewerPageModel> = useMemo(() => {
        const obj: Record<string, DocumentViewerPageModel> = {};
        for (const page of pages) {
            obj[page.index] = page;
        }

        return obj;
    }, [pages]);

    const onDragStart = useCallback((id: string) => {
        currentDragged.current = id;
    }, []);
    const onDragEnd = useCallback(() => {
        currentDragged.current = undefined;
    }, []);

    const objectsToRender = useMemo(() => {
        if (Object.keys(indexedPageCache).length === 0) {
            return null;
        }
        const arr: {
            pageObject: DocumentViewerPageObjectModel,
            currentPage: number;
            position: { offset: Offset, size: Size }
        }[] = [];

        for (const obj of pageObjects) {
            const index = `${obj.position.document}-${obj.position.page}`;
            const page = indexedPageCache[index];

            if (
                (
                    page // if page is found - would be weird if wasn't
                    && page.absolutePageIndex >= firstRenderIndex  // if page is in rendered pages scope
                    && page.absolutePageIndex <= lastRenderIndex // if page is in rendered pages scope
                )
                // or if actual element is actually dragged (as it's initial page can go out of rendered pages scope and would disappear)
                || currentDragged.current === obj.id
            ) {
                const pagePosition = pagePositions[page.absolutePageIndex];

                if (!pagePosition.size.height || !obj.position.size.height) {
                    continue;
                }
                const localScale = justifyPages ? scale * page.scale : scale;

                arr.push({
                    pageObject: obj,
                    currentPage: page.absolutePageIndex,
                    position: {
                        offset: {
                            top: pagePosition.offset.top + obj.position.offset.top * localScale,
                            left: pagePosition.offset.left + obj.position.offset.left * localScale,
                        },
                        size: {
                            width: obj.position.size.width * localScale,
                            height: obj.position.size.height * localScale,
                        },
                    },
                });
            }
        }

        return arr.length ? arr : null;
    }, [
        firstRenderIndex,
        indexedPageCache,
        lastRenderIndex,
        pageObjects,
        pagePositions,
        scale,
        justifyPages,
    ]);

    return (
        <>
            {objectsToRender && objectsToRender.map(data => (
                data.position.size.width && data.position.size.height && (
                    <DocumentViewerPageObject
                        key={data.pageObject.id}
                        currentPage={data.currentPage}
                        position={data.position}
                        pageObject={data.pageObject}
                        pages={pages}
                        pagePositions={pagePositions}
                        justifyPages={justifyPages}
                        scale={scale}
                        containerRef={containerRef}
                        onDragStart={onDragStart}
                        onDragEnd={onDragEnd}
                    />
                )
            ))}
        </>
    );
}

export default DocumentViewerPageObjects;
