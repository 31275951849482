import { NdsButton } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types/designsystem.enums';
import { Link } from 'react-router-dom';
import NoResultsCreatedYet from '~components/no-results-creted-yet';
import { DocumentGroupPermission } from '~constants';
import { BaseRoutes, NestedRoutes } from '~constants/routes';
import { useWorkspaceId } from '~contexts/app';
import { useHasDocumentGroupPermissionFunc } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';

const NoDocumentsCreatedYet = () => {
    const { t } = useTranslation('portals');
    const workspaceId = useWorkspaceId()!;
    const hasDocGroupPermission = useHasDocumentGroupPermissionFunc();

    const hasUploadPermission = hasDocGroupPermission(workspaceId, DocumentGroupPermission.Upload);

    return (
        <NoResultsCreatedYet
            description={hasUploadPermission
                ? t('no-documents-created-description-with-perm')
                : t('no-documents-created-description-without-perm')}
            title={t('no-documents-created-title')}
        >
            {hasUploadPermission && (
                <Link
                    to={`${BaseRoutes.Package}/${NestedRoutes.Documents}`}
                    state={{ workspaceId }}
                >
                    <NdsButton size={SizesEnums.SMALL} label={t('btn-upload-documents')} />
                </Link>
            )}
        </NoResultsCreatedYet>
    );

};

export default NoDocumentsCreatedYet;
