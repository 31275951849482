import { NdsButton } from '@gonitro/rcl';
import { NdsButtonTypesEnum } from '@gonitro/rcl/lib/_types';
import { useTranslation } from '~contexts/i18n';

interface FooterProps {
    closePanel: () => void;
    handleOnSaveClick: () => void;
}

const Footer = ({ closePanel, handleOnSaveClick }: FooterProps) => {
    const { t } = useTranslation('user-management');

    return (
        <div
            className={'footer-container'}
        >
            <NdsButton
                buttonType={NdsButtonTypesEnum.NEUTRAL}
                label={t('user-management-edit-user-profile-tab-cancel-button-label')}
                onClick={closePanel}
            />
            <NdsButton
                label={t('user-management-edit-user-profile-tab-save-button-label')}
                onClick={handleOnSaveClick}
            />
        </div>
    );
};

export default Footer;
