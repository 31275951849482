import { RefObject, useCallback } from 'react';
import {
    DocumentViewerPageModel,
    DocumentViewerPageObjectModel,
    DocumentViewerPagePosition,
} from '~components/document-viewer';

export const useDocumentViewerScrollToPageObjectCallback = (
    ref: RefObject<HTMLDivElement>,
    pages: DocumentViewerPageModel[],
    pagePositions: DocumentViewerPagePosition[],
    justifyPages: boolean,
    pageObjects?: DocumentViewerPageObjectModel[],
) => {

    return useCallback((id: string, behavior: ScrollBehavior = 'smooth') => {
        if (!pageObjects || !ref.current) {
            return;
        }
        const obj = pageObjects.find(el => el.id === id);

        if (!obj) {
            return;
        }

        const page = pages.find((el) => el.documentIndex === obj.position.document && el.pageIndex === obj.position.page);

        if (!page) {
            return;
        }
        const pagePos = pagePositions[page.absolutePageIndex];

        const scale = justifyPages ? page.scale : 1;

        const top = pagePos.offset.top + obj.position.offset.top - ref.current.clientHeight / 2 + obj.position.size.height / 2 * scale;
        const left = pagePos.offset.left + obj.position.offset.left - ref.current.clientWidth / 2 + obj.position.size.width / 2 * scale;

        ref.current.scrollTo({
            top,
            left,
            behavior,
        });
    }, [
        pageObjects,
        ref,
        pages,
        pagePositions,
        justifyPages,
    ]);
};
