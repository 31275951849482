import { Context, Dispatch, useCallback, useContext, useMemo } from 'react';
import { Guid } from '~models';
import { TableContext, TableContextType } from './table.context';
import { TableDataGeneric, TableFilterValue } from './table.types';

export const useTableContext = <TRowIdKey extends string, TData extends TableDataGeneric<TRowIdKey>>() => {
    return useContext<TableContextType<TRowIdKey, TData>>(TableContext as unknown as Context<TableContextType<TRowIdKey, TData>>);
};

export const useTableDefinition = <TRowIdKey extends string>() => {
    const { definition } = useTableContext<TRowIdKey, any>();

    return definition;
};

export const useTableData = <TRowIdKey extends string, TData extends TableDataGeneric<TRowIdKey> = any>() => {
    const { pageData, data } = useTableContext<TRowIdKey, TData>();

    return {
        pageData,
        data,
    };
};

export const useTableSelections = () => {
    const ctx = useContext<TableContextType>(TableContext);

    const getSelectedRowsData = useCallback(<TRowIdKey extends string, TData extends TableDataGeneric<TRowIdKey>>() => {
        return ctx.pageData.filter((el) => ctx.selectedRows.includes(el[ctx.definition.rowIdKey])) as TData[];
    }, [ctx.definition.rowIdKey, ctx.pageData, ctx.selectedRows]);

    return {
        selectedRows: ctx.selectedRows,
        selectRows: ctx.selectRows,
        unselectRows: ctx.unselectRows,
        unselectAllRows: ctx.unselectAllRows,
        getSelectedRowsData,
    };
};

export const useTableFilters = () => {
    const { setFilterValue, filterValues } = useContext<TableContextType>(TableContext);

    const clearFilterValue = useCallback((name: string) => {
        setFilterValue(name, undefined);
    }, [setFilterValue]);

    const setFilterValueCallback = useCallback(<TValue extends TableFilterValue = TableFilterValue>(name: string, value: TValue | ((prev: TValue | undefined) => TValue)) => {
        if (typeof value === 'function') {
            setFilterValue(name, value(filterValues[name] as TValue));
        } else {
            setFilterValue(name, value);
        }
    }, [filterValues, setFilterValue]);

    return {
        filterValues: filterValues,
        setFilterValue: setFilterValueCallback,
        clearFilterValue,
    };
};

export const useTablePaging = () => {
    const ctx = useContext<TableContextType>(TableContext);

    return {
        totalPages: ctx.totalPages,
        page: ctx.page,
        setPage: ctx.setPage,
    };
};

export const useTableItemsPerPage = () => {
    const ctx = useContext<TableContextType>(TableContext);

    return {
        itemsPerPage: ctx.itemsPerPage,
        setItemsPerPage: ctx.setItemsPerPage,
        itemsPerPageOptions: ctx.itemsPerPageOptions,
    };
};

export const useTableSorting = () => {
    const { sortConfig, sortChange } = useContext<TableContextType>(TableContext);

    return {
        sortConfig,
        sortChange,
    };
};

export const useTableExtraDetails = () => {
    const ctx = useContext<TableContextType>(TableContext);

    const thisPageSelections = useMemo(() =>
        ctx.pageData.filter((el) => ctx.selectedRows.includes(el[ctx.definition.rowIdKey])),
    [ctx.definition.rowIdKey, ctx.pageData, ctx.selectedRows]);

    return {
        selectedRowsTotal: ctx.selectedRows.length,
        selectedRowsOnPage: thisPageSelections.length,
        itemsOnPage: ctx.pageData.length,
        totalItems: ctx.totalItems,
    };
};

export const useTableFocusedRow = <TRowIdKey extends string, TData extends TableDataGeneric<TRowIdKey>>() => {
    const { focusedRow, setFocusedRow } = useTableContext<TRowIdKey, TData>();

    return {
        focusedRow,
        setFocusedRow,
    };
};

export const useTableRowsInProgress = () => {
    const { rowsInProgress, setRowsInProgress } = useTableContext();

    return {
        rowsInProgress: rowsInProgress as Guid[],
        setRowsInProgress: setRowsInProgress as Dispatch<Guid[]>,
    };
};
