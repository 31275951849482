import { Button } from '@progress/kendo-react-buttons';
import 'cropperjs/dist/cropper.css';
import { MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Cropper } from 'react-cropper';
import FileDropzone from '~components/files/file-dropzone';
import FileUpload, { FileUploadRef } from '~components/files/file-upload';
import Typography, { TypographyToken } from '~components/typography';
import { FileType, FileTypeId } from '~constants';
import { useTranslation } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import './add-signature-tab.scss';

export interface AddSignatureTabProps {
    closePanel: () => void;
    signatureImage: string | undefined;
    croppedImage: string | undefined;
    setCroppedImage: (image: string) => void;
    setSignatureEditMode: (mode: boolean) => void;
    isSignatureEditMode: boolean;
    removeSignature: () => void;
    handleSignatureContinue: (image: string) => void;
    handleSignatureOnload: (image: string) => void;
}

const BACKGROUND = false; // Removes cropper background
const RESPONSIVE = true; // Re-render the cropper when resizing the window
const AUTO_CROP_AREA = 0.92; // Define the automatic cropping area size
const MIN_CONTAINER_HEIGHT = 200; // sets the min container height
const GUIDES = false; // sets whether guidlines are shown

const AddSignatureTab = ({
    croppedImage,
    signatureImage,
    setCroppedImage,
    isSignatureEditMode,
    setSignatureEditMode,
    removeSignature,
    handleSignatureContinue,
    handleSignatureOnload,
}: AddSignatureTabProps) => {

    const cropperRef = useRef<any>(null);

    const { t: tUser } = useTranslation('user-management');
    const { t } = useTranslation('flow');
    const { customizationSettings } = useSettings();

    const inputRef = useRef<FileUploadRef>();
    const btnRef = useRef(null);
    const { current: { extensions, mimeTypes } } = useRef(FileType.findAndMerge([FileTypeId.JPG, FileTypeId.PNG]));

    const [dropZoneError, setDropZoneError] = useState(false);


    const bulletPoints =
        useMemo(() => [tUser('signature-bullet-adjust-cropping'), tUser('signature-bullet-scroll-to-zoom'), tUser('signature-bullet-click-and-drag')],
            [tUser]);


    useEffect(() => {
        return () => setSignatureEditMode(false);
    }, [setSignatureEditMode]);

    const resetCrop = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (cropperRef.current) {
            cropperRef.current.reset();
            cropperRef.current.zoom(-0.1);
        }
    }, []);


    const getCropData = useCallback(() => {
        if (typeof cropperRef.current !== 'undefined') {
            setCroppedImage(cropperRef.current.getCroppedCanvas({ fillColor: '#fff' }).toDataURL('image/jpeg', 0.5));
        }
    }, [setCroppedImage]);

    const onFileUpload = useCallback(
        (files: File[]) => {

            const fileSizeInKB = files[0].size / 1024;

            if (fileSizeInKB > customizationSettings.maximumImageSize) {
                setDropZoneError(true);

                return;
            }


            const reader = new FileReader();

            reader.onload = () => {
                setDropZoneError(false);

                if (reader.result) {
                    const base64String = reader.result;

                    handleSignatureOnload(base64String as string);
                }
            };
            reader.onerror = (error) => {
                setDropZoneError(true);
                console.error('Error reading file:', error);
            };
            try {
                reader.readAsDataURL(files[0]);
            } catch (error) {
                console.error('Error initiating file read:', error);
            }

        },
        [handleSignatureOnload, customizationSettings],
    );

    const handleOpenPrompt = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        inputRef.current?.openPrompt();
    }, []);

    const renderDropZone = (
        <FileDropzone inputRef={inputRef}>
            <div className={'c-flow-documents-upload__dropzone-inner'}>
                <Typography
                    token={TypographyToken.DesktopHeaderMd}
                    tagName={'h2'}
                    text={t('drop-your-files-here')}
                />
                <Typography token={TypographyToken.DesktopDescriptionLg} text={t('or')} />
                <Button
                    ref={btnRef}
                    themeColor={'secondary'}
                    size={'large'}
                    iconClass={'fa-regular fa-file-arrow-up'}
                    onClick={handleOpenPrompt}
                >{t('upload-a-file')}</Button>
                <FileUpload
                    onUpload={onFileUpload}
                    inputRef={inputRef}
                    mimeTypes={mimeTypes}
                />
                <Typography
                    token={TypographyToken.DesktopDescriptionMd}
                    className={'c-flow-documents-upload__dropzone-hint'}
                >
                    <div>{t('supported-formats-list', { formats: extensions.join(', ') })}</div>
                </Typography>
                {dropZoneError && <Typography
                    token={TypographyToken.DesktopDescriptionSm}
                    className={'c-flow-documents-upload__dropzone-error'}
                >
                    <div>{'TODO: unable to upload error'}</div>
                </Typography>}
            </div>
        </FileDropzone>
    );

    const renderImageMode = (
        <>
            <div className='add-signature-tab__signature-image-container'>
                <img alt='signature' src={signatureImage} />
            </div>
            <div className='add-signature-tab__control-container'>
                <Button
                    className='add-signature-tab__action-button'
                    size={'medium'}
                    iconClass={'fa-solid fa-pencil'}
                    onClick={() => setSignatureEditMode(true)}
                >
                    {tUser('user-management-action-edit')}
                </Button>
                <Button
                    className='add-signature-tab__action-button'
                    size={'medium'}
                    iconClass={'fa-solid fa-trash'}
                    onClick={removeSignature}
                >
                    {tUser('user-management-action-remove')}
                </Button>
            </div>
        </>
    );

    const onReady = useCallback(() => {
        cropperRef.current.zoom(-0.1);
    }, []);


    const renderEditMode = (
        <>
            <div className='add-signature-tab__cropper-container'>
                <Cropper
                    src={signatureImage}
                    viewMode={1}
                    background={BACKGROUND}
                    responsive={RESPONSIVE}
                    autoCrop // Defines the automatic cropping area size
                    autoCropArea={AUTO_CROP_AREA}
                    ready={onReady}
                    checkOrientation={false}
                    minContainerHeight={MIN_CONTAINER_HEIGHT}
                    onInitialized={(instance) => {
                        cropperRef.current = instance;
                    }}
                    zoom={getCropData}
                    crop={getCropData}
                    center
                    guides={GUIDES}
                />
            </div>
            <ul className='add-signature-tab__bullets-list'>
                {bulletPoints.map((point, index) => (
                    <li className='add-signature-tab__bullets' key={index}>{point}</li>
                ))}
            </ul>
            <div className='add-signature-tab__edit-action-button-container'>
                <Button
                    onClick={resetCrop}
                    themeColor={'base'}
                    size={'medium'}
                    iconClass={'fa-solid fa-rotate'}
                >{tUser('signature-reset')}</Button>
                <Button
                    className='add-signature-tab__confirm-button'
                    onClick={() => handleSignatureContinue(croppedImage!)}
                    themeColor={'secondary'}
                    size={'medium'}
                >{tUser('signature-confirm')}</Button>
            </div>

        </>
    );


    return (
        <>
            <div className='add-signature-tab'>
                <div className='add-signature-tab__layout-wrapper'>
                    {signatureImage && (isSignatureEditMode ? renderEditMode : renderImageMode)}
                    {!signatureImage && renderDropZone}
                </div>
            </div>
        </>
    );
};


export default AddSignatureTab;
