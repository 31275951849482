import { FileType } from '~constants';
import { FileUtils } from '~lib';
import { CloudStorageScenario, CloudStorageSelectedFile } from './cloud-storage.scenario';

export default class DropboxScenario extends CloudStorageScenario {

    initialize() {
        FileUtils.loadScript(
            'dropboxjs',
            'https://www.dropbox.com/static/api/2/dropins.js',
            { appKey: this.cloudSettings.dropBox.dropBoxKey },
        );
    }

    showFilePicker() {
        const options: Dropbox.Options = {
            multiselect: this.allowMultipleFiles,
            extensions: FileType.findExtensions(this.allowedMimeTypes).join(','),
            linkType: 'direct',
            success: (files) => this.handleFileSelection(files),
            cancel: () => null,
        };

        if (window.Dropbox.isBrowserSupported()) {
            window.Dropbox.choose(options);
        }
    }

    handleFileSelection(files: Dropbox.SelectedFile[]) {
        const selectedFiles: CloudStorageSelectedFile[] = files.map((file: Dropbox.SelectedFile) => {
            const mimeType = FileType.findMimeTypeByExtension(file.name.substring(file.name.lastIndexOf('.')));

            file.url = file.link;
            file.authToken = '';

            return {
                name: file.name,
                size: file.bytes,
                mimeType,
                token: '',
                url: file.link,
            };
        });

        this.notifyFileSelection(selectedFiles);
    }

}