
export enum DocumentGroupPermission {
    Audit = 'Audit',
    CreateTemplate = 'CreateTemplate',
    Delete = 'Delete',
    DeleteTemplate = 'DeleteTemplate',
    Download = 'Download',
    Sign = 'Sign',
    Upload = 'Upload',
    View = 'View',
    ViewTemplate = 'ViewTemplate',
}

export type DocumentGroupPermissionCodes = `${DocumentGroupPermission}`[]