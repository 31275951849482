import { NdsButton } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { Link } from 'react-router-dom';
import NoResultsCreatedYet from '~components/no-results-creted-yet';
import { DocumentGroupPermission } from '~constants';
import { BaseRoutes, NestedRoutes } from '~constants/routes';
import { useWorkspaceId } from '~contexts/app';
import { useHasDocumentGroupPermissionFunc } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';

const NoTemplatesCreatedYet = () => {
    const { t } = useTranslation('portals');
    const workspaceId = useWorkspaceId()!;
    const hasDocGroupPermission = useHasDocumentGroupPermissionFunc();

    const hasUploadPermission = hasDocGroupPermission(workspaceId, DocumentGroupPermission.CreateTemplate);

    return (
        <NoResultsCreatedYet
            description={hasUploadPermission
                ? t('no-templates-created-description-with-perm')
                : t('no-templates-created-description-without-perm')}
            title={t('no-templates-created-title')}
        >
            {hasUploadPermission &&
                <Link
                    to={`${BaseRoutes.Template}/${NestedRoutes.Documents}`}
                    state={{ workspaceId }}
                >
                    <NdsButton size={SizesEnums.SMALL} label={t('btn-create-new-template')} />
                </Link>
            }
        </NoResultsCreatedYet>
    );

};

export default NoTemplatesCreatedYet;
