import { BaseRoutes } from './routes';

export enum ContactTabsEnum {
    Contacts = 'Signature',
    ContactGroups = 'OutOfOffice'
  }

export const CONTACT_TABS = [
    {
        key:  ContactTabsEnum.Contacts,
        i18n: {
            ns: 'contacts',
            key: 'contacts-title',
        },
        url: BaseRoutes.Contacts,
    },
    {
        key:  ContactTabsEnum.ContactGroups,
        i18n: {
            ns: 'contacts',
            key: 'contact-groups-title',
        },
        url: BaseRoutes.ContactGroups,
    },
];
