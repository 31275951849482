import { Button } from '@progress/kendo-react-buttons';
import { TextBox, TextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { plusIcon } from '@progress/kendo-svg-icons';
import { useCallback, useState } from 'react';
import { UserManagementApi } from '~api/user-management.api';
import Typography, { TypographyToken } from '~components/typography';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { useAsyncEffect } from '~hooks/effects';
import SelectedNameItem from './selected-name-item/selected-name-item';
import './alternate-name-tab.scss';


export interface AlternateNameTabProps {
    userGroupId: number | undefined;
    alternateNames: string[];
    handleSetAlternateNames: (name: string) => void;
    setInitialAlternateNames: (names: string[]) => void;
    handleHasLoadedAlternateNames: (loaded: boolean) => void;
    hasLoadedAlternateNames: boolean;
}


const AlternateNameTab = ({
    alternateNames,
    handleSetAlternateNames,
    userGroupId,
    setInitialAlternateNames,
    hasLoadedAlternateNames,
    handleHasLoadedAlternateNames,
}: AlternateNameTabProps) => {

    const { t } = useTranslation('user-management');
    const userManagementApi = useApi(UserManagementApi);
    const [alternateName, setAlternateName] = useState('');

    useAsyncEffect(() => async (signal) => {
        if (userGroupId && !hasLoadedAlternateNames) {
            const alternateNamesResponse = await userManagementApi.getAlternateNames({ userGroupId });

            setInitialAlternateNames(alternateNamesResponse);
            handleHasLoadedAlternateNames(true);
        }

    }, [
        userManagementApi,
        userGroupId,
        setInitialAlternateNames,
        hasLoadedAlternateNames,
        handleHasLoadedAlternateNames,
    ]);

    const handleNameChange = useCallback((e: TextBoxChangeEvent) => {
        if (typeof e.value === 'string') {
            setAlternateName(e.value);
        }
    }, [setAlternateName]);

    const handleAddAlternateName = useCallback(() => {
        if (alternateName.length && !alternateNames.includes(alternateName)) {
            handleSetAlternateNames(alternateName);
            setAlternateName('');
        }
    }, [alternateName, alternateNames, handleSetAlternateNames]);


    return (
        <div className='alternate-name-tab'>
            <fieldset>
                <Typography token={TypographyToken.UiFormsLabelSm} text={t('alternate-name-tab-title')} />
                <div className='alternate-name-tab__add-name-container'>
                    <TextBox
                        value={alternateName}
                        onChange={handleNameChange}
                    />
                    <Button
                        type='button'
                        fillMode={'solid'}
                        themeColor={'dark'}
                        svgIcon={plusIcon}
                        onClick={handleAddAlternateName}
                    >
                        <span className='alternate-name-tab__add-btn-label'>Todo: Add</span>
                    </Button>
                </div>
            </fieldset>
            <div className='alternate-name-tab__selected-item-container'>
                {alternateNames.map((name, index) => (
                    <SelectedNameItem key={index} alternateName={name} onClick={handleSetAlternateNames} />
                ))}
            </div>
        </div>
    );
};

export default AlternateNameTab;
