import { NdsProgress } from '@gonitro/rcl';
import { NdsProgressIndicatorStyles, SizesEnums } from '@gonitro/rcl/lib/_types';
import { NdsProgressIndicatorTypes, NdsProgressModes } from '@gonitro/rcl/lib/_types/designsystem.enums';
import { Link } from 'react-router-dom';
import { useAppContext } from '~contexts/app';
import { useTranslation } from '~contexts/i18n';
import Typography, { TypographyToken } from '../../typography';
import './flowProcessing.scss';

export interface FlowProcessingProps {

}

function FlowProcessing(props: FlowProcessingProps) {
    const { lastPortalPath } = useAppContext();
    const { t } = useTranslation('flow');

    return (
        <div className={'c-flow-processing'}>
            <div className={'c-flow-processing__content'}>
                <h1 className={'c-flow-processing__title'}>
                    {t('wait-while-processing-documents')}
                </h1>
                <NdsProgress
                    className={'c-flow-processing__loader'}
                    size={SizesEnums.MEDIUM}
                    indicatorType={NdsProgressIndicatorTypes.LINEAR}
                    indicatorMode={NdsProgressModes.INDETERMINATE}
                    indicatorStyle={NdsProgressIndicatorStyles.ALT}
                    showLabel={false}
                />
                <Link to={lastPortalPath} className={'c-flow-processing__link'}>
                    <Typography token={TypographyToken.DesktopDescriptionLg}>{t('back-to-documents')}</Typography>
                </Link>
            </div>
        </div>
    );
}

export default FlowProcessing;
