import { useMemo } from 'react';
import BulkActions from '~components/bulk-actions';
import { FlowAction, FlowActionI18n } from '~constants/flow-action';
import { useTranslation } from '~contexts/i18n';
import { useTableDefinition, useTableSelections } from '~contexts/table';
import { TableDataGeneric } from '~contexts/table/table.types';
import { Guid } from '~models';

type BulkActionDefinition<TRowIdKey extends string, TRowData extends TableDataGeneric<TRowIdKey>> = {
    type: FlowAction,
    iconName: string,
    canRun: (data: TRowData) => boolean;
};


export interface PortalBulkActionsHeaderButtonProps<TRowIdKey extends string, TData extends TableDataGeneric<TRowIdKey>> {
    actionsDef: BulkActionDefinition<TRowIdKey, TData>[][];
    onClick: (type: FlowAction, ids: Guid[]) => void;
}

function PortalBulkActionsHeaderButton<TRowIdKey extends string, TRowData extends TableDataGeneric<TRowIdKey>>({
    actionsDef,
    onClick,
}: PortalBulkActionsHeaderButtonProps<TRowIdKey, TRowData>) {
    const { getSelectedRowsData } = useTableSelections();
    const definition = useTableDefinition<TRowIdKey>();
    const { t } = useTranslation('flow');
    const { t: tBase } = useTranslation('base');

    const bulkActions = useMemo(() => {
        const selectedRowsData = getSelectedRowsData<TRowIdKey, TRowData>();

        return actionsDef.map((actionGroup) => {
            return actionGroup.map(({ type, iconName, canRun }) => {
                const ids = selectedRowsData.filter((el) => canRun(el)).map((el) => el[definition.rowIdKey]);
                const availableNumber = ids.length;
                const totalNumberSelected = selectedRowsData.length;

                // if (!ids.length) { TODO when kendo component used just remove action with 0 items
                //     continue;
                // }

                return {
                    type: type,
                    label: t(FlowActionI18n[type]),
                    availableNumber,
                    totalNumberSelected,
                    iconName,
                    ids,
                };
            });
        });

    }, [actionsDef, definition.rowIdKey, getSelectedRowsData, t]);

    if (!bulkActions.flat().some(({ availableNumber }) => availableNumber > 0)) {
        return null;
    }

    return (
        <BulkActions label={tBase('bulk-actions-button-label')} items={bulkActions} onItemClick={onClick} />
    );

}

export default PortalBulkActionsHeaderButton;