import { DropDownButton, DropDownButtonItem, DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useFlowContext } from '~contexts/flow';
import { useTranslation } from '~contexts/i18n';
import Typography, { TypographyToken } from '../../../typography';
import './flowEditorDocumentViewerControls.scss';

export interface FlowEditorDocumentViewerControlsProps {
    disabled?: boolean;
}

const LEVELS = [
    25,
    50,
    75,
    100,
    125,
    150,
    175,
    200,
];

function FlowEditorDocumentViewerControls({ disabled }: FlowEditorDocumentViewerControlsProps) {
    const { t } = useTranslation('flow');
    const {
        documentViewerConfig: {
            justifyPages,
            zoomLevel,
            adjustViewerToScreen,
        },
        setDocumentViewerConfig,
    } = useFlowContext();

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const onClick = useCallback(({ itemIndex }: DropDownButtonItemClickEvent) => {
        if (itemIndex === 0) { // first item is 'Fit to width'
            setDocumentViewerConfig({
                adjustViewerToScreen: true,
                zoomLevel: 100,
            });
        } else if (itemIndex === LEVELS.length + 1) { // last item is 'Actual proportions'
            setDocumentViewerConfig({ justifyPages: !justifyPages });
        } else { // rest is just zoom levels
            setDocumentViewerConfig({
                adjustViewerToScreen: false,
                zoomLevel: LEVELS[itemIndex - 1],
            });
        }
    }, [justifyPages, setDocumentViewerConfig]);

    return (
        <div className={'c-flow-editor-document-viewer-controls'}>
            <DropDownButton
                text={
                    <Typography
                        token={TypographyToken.UiFormsInputSm}
                        text={adjustViewerToScreen ? t('fit-to-width') : `${zoomLevel}%`}
                    />
                }
                disabled={disabled}
                endIcon={(
                    <span>
                        <i className={classNames('fa fa-regular fa-chevron-down', { 'fa-rotate-180': isDropdownOpen })} />
                    </span>
                )}
                onOpen={() => setIsDropdownOpen(true)}
                onClose={() => setIsDropdownOpen(false)}
                fillMode={'flat'}
                onItemClick={onClick}
                popupSettings={{
                    popupClass: 'c-flow-editor-document-viewer-controls__popup',
                    anchorAlign: {
                        horizontal: 'right',
                        vertical: 'bottom',
                    },
                    popupAlign: {
                        horizontal: 'right',
                        vertical: 'top',
                    },
                }}
            >
                <DropDownButtonItem
                    text={t('fit-to-width')}
                    selected={adjustViewerToScreen}
                />
                {LEVELS.map((level) => (
                    <DropDownButtonItem
                        key={level}
                        text={`${level}%`}
                        selected={level === zoomLevel && !adjustViewerToScreen}
                    />
                ))}
                <DropDownButtonItem
                    text={t('actual-proportions')}
                    render={(props: any) => (
                        <>
                            <span className={'k-menu-link-text'}>{props.item.text}</span>
                            <span
                                className={classNames(
                                    'k-icon k-font-icon fa fa-solid fa-check c-flow-editor-document-viewer-controls__proportions-check',
                                    { 'c-flow-editor-document-viewer-controls__proportions-check--selected': !justifyPages },
                                )}
                                role='presentation'
                            />
                        </>
                    )}

                />
            </DropDownButton>
        </div>
    );
}

export default FlowEditorDocumentViewerControls;
