import { NdsButton, NdsDivider } from '@gonitro/rcl';
import {
    BkgModesEnum,
    NdsButtonTypesEnum,
    SizesEnums,
    StatesEnum,
} from '@gonitro/rcl/lib/_types';
import { Fragment } from 'react';
import MenuDropdown from '~components/menu-dropdown/menu-dropdown';
import MenuGroup from '~components/menu-dropdown/menu-group/menu-group';
import MenuItem from '~components/menu-dropdown/menu-item/menu-item';
import { FlowAction } from '~constants';
import { Guid } from '~models';
import './bulkActions.scss';

export interface BulkActionItem {
    type: FlowAction;
    label: string;
    availableNumber: number;
    totalNumberSelected: number;
    iconName: string;
    ids: Guid[];
}

export interface BulkActionsProps {
    label: string;
    items: BulkActionItem[][];
    menuWidth?: number;
    onItemClick: (action: FlowAction, ids: Guid[]) => void;
}

function BulkActions({ label, items, menuWidth, onItemClick }: BulkActionsProps) {
    return (
        <div className={'c-bulk-actions'}>
            <MenuDropdown
                button={(toggleDropdown: () => void) => (
                    <NdsButton
                        slot='button'
                        label={label}
                        buttonType={NdsButtonTypesEnum.SECONDARY}
                        size={SizesEnums.SMALL}
                        rightIcon='fa-solid-chevron-down'
                        onClick={toggleDropdown}
                    ></NdsButton>
                )}
            >
                {items.map((group, idx) => (
                    <Fragment key={idx}>
                        <MenuGroup
                            menuWidth={menuWidth ? menuWidth : 240}
                        >
                            {group.map((item: BulkActionItem, id: number) => (
                                <MenuItem
                                    key={id}
                                    size={SizesEnums.MEDIUM}
                                    primaryText={item.label}
                                    icon={item.iconName}
                                    onClick={() => onItemClick(item.type, item.ids)}
                                    state={item.availableNumber === 0 ? StatesEnum.DISABLED : StatesEnum.DEFAULT}
                                >
                                    <div className='c-bulk-actions__package-count' slot='badge'>
                                        {item.availableNumber}/{item.totalNumberSelected}
                                    </div>
                                </MenuItem>
                            ))}
                        </MenuGroup>
                        {idx < items.length - 1 && (
                            <NdsDivider bgMode={BkgModesEnum.LIGHT} slot={'menu'} />
                        )}
                    </Fragment>))}
            </MenuDropdown>
        </div>
    );
}

export default BulkActions;
