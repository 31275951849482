import FlowProcessing from 'components/flow/flow-processing';
import { useCallback, useRef } from 'react';
import { FlowStatusEnum } from '~constants';
import { useFlowContext } from '~contexts/flow';
import { FlowStep } from '~contexts/flow/flow.types';
import { useAbortEffect } from '~hooks/effects';

export default function TemplateProcessingView() {
    const initiallyFetched = useRef(false);
    const {
        api,
        navigateToStep,
    } = useFlowContext();

    const fetchStatus = useCallback(async (signal: AbortSignal): Promise<void> => {
        try {
            const resp = await api.getStatus(signal);

            initiallyFetched.current = true;

            if (resp.status === FlowStatusEnum.ProcessingFailed) {
                navigateToStep(FlowStep.Documents);
            }
            if (resp.status === FlowStatusEnum.Draft) {
                navigateToStep(FlowStep.Detail, { state: { isActionNeeded: resp.isActionNeeded } });
            }
            if (resp.status === FlowStatusEnum.Ready) {
                navigateToStep(FlowStep.Detail, { state: { isActionNeeded: resp.isActionNeeded } });
            }
        } catch (e) {
            if (signal.aborted) {
                return;
            }
        }
    }, [api, navigateToStep]);


    useAbortEffect((signal) => {
        if (!initiallyFetched.current) {
            fetchStatus(signal);
        }
        const interval = setInterval(() => fetchStatus(signal), 5000);

        return () => clearInterval(interval);
    }, [fetchStatus]);

    return <FlowProcessing />;

}
