import { TableColumnSpecificWidth } from '~contexts/table/table.types';

export namespace TableBuilderHelpers {

    const ColSpecificWidthToRems = {
        [TableColumnSpecificWidth.Auto]: 'auto',
        [TableColumnSpecificWidth.Button]: '4rem',
        [TableColumnSpecificWidth.Icon]: 'auto',
        [TableColumnSpecificWidth.Selection]: 'auto',
    };

    export function colWidth(width: number | TableColumnSpecificWidth) {

        return typeof width === 'number' ? width : ColSpecificWidthToRems[width];
    }
}