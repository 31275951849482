import { StatesEnum } from '@gonitro/rcl/lib/_types/designsystem.enums';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FieldError } from 'react-hook-form';
import { CommonUtils } from '~lib';
import { PageableListResult } from '~models/pageable-list.models';
import { useErrorTranslator } from './error';


export interface NdsInputErrorWrapperOptions {
    state?: StatesEnum;// = StatesEnum.DEFAULT
    hintText?: string;
}

export const useRegisterNdsInputError = () => {
    const t = useErrorTranslator();

    return (error?: FieldError, {
        state = StatesEnum.DEFAULT,
        hintText = undefined,
    }: NdsInputErrorWrapperOptions | undefined = {}) => {
        if (!error) {
            return {
                state,
                hintText,
            };
        }

        if (error.message) {
            return {
                state: StatesEnum.ERROR,
                hintText: t(error.message),
            };
        }

        return { state: StatesEnum.ERROR };
    };
};

const debounce = CommonUtils.delayFunc({ debounce: 200 });


interface LazyLoadOptions<T extends object> {
    pageSize: number;
    visible: boolean;
    fetchData: (page: number, pageSize: number, filterValues: {
        [filter: string]: string;
    }) => Promise<PageableListResult<T>>;
    filterKey?: string;
    initialFilter?: string;
    selector: string;
    bottomItemOffset?: number;
}

const useKendoLazyLoad = <T extends object>({
    pageSize,
    visible,
    filterKey = 'name',
    initialFilter = '',
    fetchData,
    selector,
    bottomItemOffset = 2,
}: LazyLoadOptions<T>) => {
    const lazyLoadingRef = useRef(false);
    const popupRef = useRef<HTMLElement | null>(null);
    const contRef = useRef<number | null>(0);
    const previousScrollTop = useRef(0);
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
    const loadedData = useRef(0);

    const [data, setData] = useState<T[]>([]);
    const [total, setTotal] = useState(0);
    const [dataLoading, setDataLoading] = useState(false);

    const loadMoreData = useCallback(async (filter = initialFilter) => {
        if (lazyLoadingRef.current) {
            return;
        }
        lazyLoadingRef.current = true;

        try {
            const response = await fetchData(
                contRef.current! + 1,
                pageSize,
                { [filterKey]: filter },
            );

            loadedData.current = loadedData.current + response.items.length;
            setData((prevData) => [...prevData, ...response.items]);
            setTotal(response.totalItems);
            contRef.current = response.nextPage ? response.nextPage : null;
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            lazyLoadingRef.current = false;
            setDataLoading(false);
        }
    }, [pageSize, filterKey, initialFilter, fetchData]);

    useEffect(() => {
        if (visible) {
            loadMoreData();
        }

        return (() => {
            !visible && clearTimeout(timeoutRef.current);
        });
    }, [visible, loadMoreData]);

    const onFilterChange = useCallback((value: string) => {
        debounce(() => {
            setDataLoading(true);
            setData([]);
            contRef.current = 0;

            if (popupRef.current) {
                popupRef.current.scrollTop = 0;
            }
            loadMoreData(value);
        });
    }, [loadMoreData]);


    const handleScroll = useCallback(() => {

        if (!popupRef.current || loadedData.current === total) {
            return;
        }


        const { scrollHeight, scrollTop, clientHeight } = popupRef.current;

        const nestedItems = popupRef.current.querySelectorAll('li');

        let bottomOffset: number;

        if (nestedItems.length > 0) {
            const firstItemHeight = nestedItems[0].offsetHeight;

            bottomOffset = firstItemHeight * bottomItemOffset;
        }


        if (scrollTop > previousScrollTop.current && scrollTop + clientHeight >= scrollHeight - bottomOffset!) {
            loadMoreData();
        }

        previousScrollTop.current = scrollTop;
    }, [total, loadMoreData, bottomItemOffset]);


    const onOpen = useCallback(() => {
        timeoutRef.current = setInterval(() => {
            popupRef.current = document.querySelector(selector);

            if (popupRef.current) {
                popupRef.current.addEventListener('scroll', handleScroll);
                clearInterval(timeoutRef.current);
            }
        }, 50);
    }, [handleScroll, selector]);

    return {
        data,
        onFilterChange,
        onOpen,
        dataLoading,
    };
};

export default useKendoLazyLoad;
