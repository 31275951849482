import { FieldType } from '~constants/field-type';
import { FlowFieldModel, FlowRadioGroupFieldModel, FlowRadioGroupFieldOptionModel, Guid } from '~models';
import { FlowActionType, FlowReducerFunc } from '../flow.types';

export type FlowReducerPageObjectActionPayloads = {
    [FlowActionType.MovePageObject]: {
        localId: Guid;
        optionId?: Guid; // used with radios
        type: FieldType,
        position: {
            documentNumber: number
            pageNumber: number
            height: number
            left: number
            top: number
            width: number
        }
    };
}

// it covers both reposition and resize case
export const MovePageObjectReducer: FlowReducerFunc<FlowActionType.MovePageObject> = (
    state,
    { localId, optionId, type, position },
) => {
    const elements = state.elements;
    const elementIndex = elements.findIndex((el) => el.localId === localId);

    if (elementIndex < 0) {
        return { ...state };
    }
    const element = elements[elementIndex];
    let newElement: FlowFieldModel;

    if (type === FieldType.RadioGroup) {
        const options = (element as FlowRadioGroupFieldModel).options as FlowRadioGroupFieldOptionModel[];
        const optionIndex = options.findIndex((el) => el.localId === optionId);

        if (optionIndex < 0 || options[optionIndex].location?.isLocked) {
            return { ...state };
        }
        const option = options[optionIndex];
        const newOption: FlowRadioGroupFieldOptionModel = {
            ...option,
            location: {
                ...option.location || {},
                documentNumber: position.documentNumber,
                height: position.height,
                left: position.left,
                pageNumber: position.pageNumber,
                top: position.top,
                width: position.width,
            },
        };

        options.splice(optionIndex, 1, newOption);

        newElement = {
            ...element,
            options: [...options],
        } as FlowRadioGroupFieldModel;
    } else {
        if (element.location?.isLocked) {
            return { ...state };
        }
        newElement = {
            ...element,
            location: {
                ...element.location || {},
                documentNumber: position.documentNumber,
                height: position.height,
                left: position.left,
                pageNumber: position.pageNumber,
                top: position.top,
                width: position.width,
            },
        } as FlowFieldModel;
    }
    elements.splice(elementIndex, 1, newElement);

    return {
        ...state,
        elements: [...elements],
    };
};
