import { SortDirectionEnum } from '@gonitro/rcl/lib/_types';
import {
    TableColumnSpecificWidth,
    TableDefinition,
    TableFilterValueSubType,
    TableFilterValueType,
} from '~contexts/table/table.types';

export const PackagesTableDefinition: TableDefinition<'packageId'> = {
    rowIdKey: 'packageId',
    itemsPerPage: 20,
    itemsPerPageOptions: [20, 50, 100],
    selectable: true,
    initialSorting: {
        key: 'createdOn',
        direction: SortDirectionEnum.DESC,
    },
    columns: [
        {
            key: 'packageName',
            dataKey: 'documentName',
            i18nLabel: {
                ns: 'portals',
                key: 'portal-table-col-title-name',
            },
            sortable: true,
            width: TableColumnSpecificWidth.Auto,
            useEllipsis: true,
        },
        {
            key: 'sharedWith',
            i18nLabel: {
                ns: 'portals',
                key: 'portal-table-col-title-shared-with',
            },
            width: 20,
            useEllipsis: true,
        },
        {
            key: 'createdOn',
            i18nLabel: {
                ns: 'portals',
                key: 'portal-table-col-title-added-on',
            },
            width: 20,
            sortable: true,
            useEllipsis: true,
        },
        {
            key: 'reassignedAndSubstitutes',
            label: '',
            width: 5,
            sortable: false,
        },
        {
            key: 'status',
            i18nLabel: {
                ns: 'portals',
                key: 'portal-table-col-title-status',
            },
            width: 20,
            sortable: true,
        },
        {
            key: 'action',
            width: TableColumnSpecificWidth.Button,
        },
    ],
    filters: [
        {
            type: TableFilterValueType.Primitive,
            subType: TableFilterValueSubType.String,
            key: 'status',
        },
        {
            type: TableFilterValueType.Object,
            subType: TableFilterValueSubType.String,
            key: 'dateRange.start',
            queryParamKey: 'afterDate',
            apiKey: 'afterDate',
            apiTransform: (value) => value ? `${value}T00:00:00` : value,
        },
        {
            type: TableFilterValueType.Object,
            subType: TableFilterValueSubType.String,
            key: 'dateRange.end',
            queryParamKey: 'beforeDate',
            apiKey: 'beforeDate',
            apiTransform: (value) => value ? `${value}T23:59:59` : value,
        },
        {
            type: TableFilterValueType.Primitive,
            subType: TableFilterValueSubType.String,
            key: 'name',
        },
    ],
};