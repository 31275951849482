import { MutableRefObject } from 'react';
import { Offset, Size } from '~models';
import { DocumentViewerPagePosition } from '../types';

export namespace DocumentViewerPageObjectHelpers {

    export function getOptimizedTop(
        objectTop: number,
        objectSize: Size,
        currentPageRef: MutableRefObject<number>,
        pagePositions: DocumentViewerPagePosition[],
        mouseY: number,
        container: HTMLDivElement,
    ) {
        const currentPageIndex = currentPageRef.current;
        const containerMinTop = pagePositions[0].offset.top;
        const lastPagePosition = pagePositions[pagePositions.length - 1];
        const containerMaxTop = lastPagePosition.offset.top + lastPagePosition.size.height - objectSize.height;

        const currentPagePosition = pagePositions[currentPageIndex];

        const minObjectTopOnPage = currentPageRef.current === 0
            ? containerMinTop
            : currentPagePosition.offset.top;
        const maxObjectTopOnPage = currentPageRef.current === pagePositions.length - 1
            ? containerMaxTop
            : currentPagePosition.offset.top + currentPagePosition.size.height - objectSize.height;

        switch (true) {
            case objectTop < containerMinTop: {
                return containerMinTop;
            }
            case objectTop > containerMaxTop: {
                return containerMaxTop;
            }
            case objectTop < minObjectTopOnPage: { // moving to page above
                // it must exist because it was checked if new position isn't above first page in previous cases
                const previousPagePosition = pagePositions[currentPageIndex - 1];

                const rect = container.getBoundingClientRect();

                const currentPageTop = rect.top + currentPagePosition.offset.top - container.scrollTop;
                const previousPageBottom = rect.top + previousPagePosition.offset.top + previousPagePosition.size.height - container.scrollTop;

                if (currentPageTop - mouseY > mouseY - previousPageBottom) { // change page
                    currentPageRef.current = currentPageIndex - 1;

                    return previousPagePosition.offset.top + previousPagePosition.size.height - objectSize.height;
                }

                return minObjectTopOnPage;
            }
            case objectTop > maxObjectTopOnPage: { // moving to page below
                // it must exist because it was checked if new position isn't below last page in previous cases
                const nextPagePosition = pagePositions[currentPageIndex + 1];

                const rect = container.getBoundingClientRect();

                const currentPageBottom = rect.top + currentPagePosition.offset.top + currentPagePosition.size.height - container.scrollTop;
                const nextPageTop = rect.top + nextPagePosition.offset.top - container.scrollTop;

                if (nextPageTop - mouseY < mouseY - currentPageBottom) { // change page
                    currentPageRef.current = currentPageIndex + 1;

                    return nextPagePosition.offset.top;
                }

                return maxObjectTopOnPage;
            }
            default:
                return objectTop;
        }
    }

    export function getOptimizedLeft(
        eventLeft: number,
        objectSize: Size,
        currentPage: MutableRefObject<number>,
        pagePositions: DocumentViewerPagePosition[],
    ) {
        const pagePosition = pagePositions[currentPage.current];
        const minPageLeft = pagePosition.offset.left;
        const maxPageLeft = pagePosition.offset.left + pagePosition.size.width - objectSize.width;

        switch (true) {
            case eventLeft < minPageLeft: {
                return minPageLeft;
            }
            case eventLeft > maxPageLeft: {
                return maxPageLeft;
            }
            default:
                return eventLeft;
        }
    }

    export function offsetsDistance(o1: Offset, o2: Offset) {
        return Math.sqrt(Math.pow(o1.left - o2.left, 2) + Math.pow(o1.top - o2.top, 2));
    }
}
