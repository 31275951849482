import { useMemo, useState } from 'react';
import QuickActionsModal from '~components/quick-actions-modal';
import QuickActionsTable from '~components/quick-actions-table';
import { QuickAction } from '~components/quick-actions-table/quickActionsTable';
import { FlowAction, FlowStatusEnum } from '~constants';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { useSignerQuickActions } from '~hooks/useSignerQuickActions';
import { SigningSessionTableDataModel } from '~models/portals.models';

export interface SignerPortalQuickActionsProps {
    data: SigningSessionTableDataModel;
}

function SignerPortalQuickActions({ data }: SignerPortalQuickActionsProps) {
    const [quickActionModalData, setQuickActionModalData] = useState<SigningSessionTableDataModel | undefined>(undefined);
    const closeModal = () => {
        setQuickActionModalData(undefined);
    };
    const { runQuickActionClick, WarningMessageDialogI18n,  AdditionalInformationI18n } = useSignerQuickActions(closeModal, data);
    const quickActions = useMemo((): QuickAction<FlowAction>[][] => {
        switch (data.status.toUpperCase()) {
            case FlowStatusEnum.PendingFormFill:
                return [
                    [
                        {
                            icon: 'fa-solid-eye',
                            type: FlowAction.Preview,
                        },
                        {
                            icon: 'fa-solid-input-text',
                            type: FlowAction.FillOutForms,
                        },
                    ],
                ];
            case FlowStatusEnum.PendingApproval:
                return [
                    [
                        {
                            icon: 'fa-solid-eye',
                            type: FlowAction.Preview,
                        },
                        {
                            icon: 'fa-solid-circle-check',
                            type: FlowAction.Approve,
                        },
                    ],
                ];
            case FlowStatusEnum.PendingSigning:
                return [
                    [
                        {
                            icon: 'fa-solid-eye',
                            type: FlowAction.Preview,
                        },
                        {
                            icon: 'fa-solid-signature',
                            type: FlowAction.Sign,
                        },
                    ],
                ];
            case FlowStatusEnum.Received:
            case FlowStatusEnum.Archived:
            case FlowStatusEnum.Finished:
                return [
                    [
                        {
                            icon: 'fa-solid-download',
                            type: FlowAction.Download,
                        },
                    ],
                    [
                        {
                            icon: 'fa-solid-trash',
                            type: FlowAction.Delete,
                        },
                    ],
                ];
            default:
                return [
                    [
                        {
                            icon: 'fa-solid-eye',
                            type: FlowAction.Preview,
                        },
                    ],
                ];
        }
    }, [data.status]);

    const handleActionClick = (actionType: FlowAction) => {
        if (actionType === FlowAction.Delete) {
            setQuickActionModalData(data);
        } else {
            runQuickActionClick(actionType);
        }
    };

    return (
        <>
            <QuickActionsTable actions={quickActions} handleActionClick={handleActionClick} />
            <OverlayPortal id='documentPortalQuickActionsModal' type={'modal'} visible={quickActionModalData !== undefined}>
                {({ close }) =>
                    <QuickActionsModal
                        data={quickActionModalData}
                        actionType={FlowAction.SoftDelete}
                        handleCloseModal={() => {
                            closeModal(); close();
                        }}
                        handleClickConfirm={() => {
                            runQuickActionClick(FlowAction.Delete);
                        }}
                        additionalInformation={AdditionalInformationI18n[FlowAction.Delete]}
                        warningMessage={WarningMessageDialogI18n[FlowAction.Delete]}
                    />
                }
            </OverlayPortal>
        </>
    );
}

export default SignerPortalQuickActions;
