import { TOptionsBase } from 'i18next';
import { useCallback, useContext, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import { useAbortEffect } from '~hooks/effects';
import { I18nextNamespaceName, LanguageDataLabel } from '~models';
import { I18nContext, TranslationFunction } from '../';


interface UseTranslationResult<TNamespaceName extends I18nextNamespaceName> {
    t: TranslationFunction<TNamespaceName>;
    ready: boolean;
}

export function useTranslation<TNamespaceName extends I18nextNamespaceName>(namespace: TNamespaceName): UseTranslationResult<TNamespaceName> {
    const { i18next } = useContext(I18nContext);
    const [ready, setReady] = useState(i18next.hasLoadedNamespace(namespace) ?? false);

    // When namespace wasn't loaded yet -> load it
    useAbortEffect((signal) => {
        if (!i18next.isInitialized == null || i18next.hasLoadedNamespace(namespace)) {
            return;
        }
        i18next.loadNamespaces([namespace]).then(() => {
            if (signal.aborted) {
                return;
            }
            setReady(true);
        });

    }, [i18next, namespace]);

    const translate = useCallback<TranslationFunction<TNamespaceName>>((
        label: LanguageDataLabel<TNamespaceName>,
        values?: any,
        options?: TOptionsBase,
    ): any => {
        if (!ready) {
            return '';
        }

        const text = i18next.t(label as string, {
            ...options,
            ...values,
            ns: namespace,
        });

        return sanitizeHtml(text as any as string);
    }, [i18next, namespace, ready]);

    return {
        t: translate,
        ready,
    };
}
