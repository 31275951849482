import { NdsSelectChangeEvent, NdsSelectProps } from '@gonitro/rcl';
import { NdsSelectTypesEnums, StatesEnum } from '@gonitro/rcl/lib/_types/designsystem.enums';
import { useCallback } from 'react';
import { Control, PathValue, useFormState } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types';
import { InternalFieldName } from 'react-hook-form/dist/types/fields';
import { UseFormRegisterReturn, UseFormSetValue } from 'react-hook-form/dist/types/form';
import { FieldPath } from 'react-hook-form/dist/types/path';
import { EnhancedRegisterOptions, UseEnhancedFormRegister } from './enhanced-register';

export type UseFormRegisterNdsSelect<TFieldValues extends FieldValues> = <TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
    name: TFieldName,
    options?: EnhancedRegisterOptions<TFieldValues, TFieldName>,
    inputProps?: RegisterNdsSelectComponentProps,
) => UseFormRegisterNdsSelectReturn<TFieldName>;

export interface UseFormRegisterNdsSelectReturn<TFieldName extends InternalFieldName = InternalFieldName>
    extends Omit<UseFormRegisterReturn<TFieldName>, 'onChange'>, // onChange is replaced with onNdsChange
        Pick<NdsSelectProps, 'state' | 'hintText' | 'selectType' | 'onNdsChange' | 'value'> { // state and hintText are needed to display errors
}

// state and hintText will be overwritten by this hook's callback if passed directly in props before this hook callback
// to prevent that they should be explicitly passed to registerNdsSelect
export type RegisterNdsSelectComponentProps = {
    state?: NdsSelectProps['state'];
    hintText?: NdsSelectProps['hintText'];
    selectType?: NdsSelectProps['selectType'];
}

export const useRegisterNdsSelect = <TFieldValues extends FieldValues, TContext = any>(
    register: UseEnhancedFormRegister<TFieldValues>,
    control: Control<TFieldValues, TContext>,
    setValue: UseFormSetValue<TFieldValues>,
) => {
    const { errors, defaultValues } = useFormState({ control });

    return useCallback<UseFormRegisterNdsSelect<TFieldValues>>(
        <TFieldName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>>(
            inputType: TFieldName,
            registerOptions?: EnhancedRegisterOptions<TFieldValues, TFieldName>,
            inputProps?: RegisterNdsSelectComponentProps,
        ): UseFormRegisterNdsSelectReturn<TFieldName> => {
            const { noErrorTranslation, ...options } = registerOptions || {};
            const { onChange, translatedError, ...rest } = register(inputType, options);

            let {
                state = StatesEnum.DEFAULT,
                hintText,
            } = inputProps || {};
            const { selectType = NdsSelectTypesEnums.SINGLE } = inputProps || {};

            const error = errors[inputType];

            if (error) {
                state = StatesEnum.ERROR;

                if (!noErrorTranslation) {
                    hintText = translatedError;
                }
            }

            return {
                ...rest,
                onNdsChange: (e: NdsSelectChangeEvent) => {
                    if (selectType === NdsSelectTypesEnums.MULTI) {
                        setValue<TFieldName>(
                            inputType,
                            e.detail.value as PathValue<TFieldValues, TFieldName>,
                            { shouldDirty: true },
                        );
                    } else {
                        setValue<TFieldName>(
                            inputType,
                            e.detail.value[0] as PathValue<TFieldValues, TFieldName>,
                            { shouldDirty: true },
                        );
                    }
                },
                value: defaultValues?.[inputType] ?? '',
                state,
                hintText,
                selectType,
            };
        }, [defaultValues, errors, register, setValue],
    );
};