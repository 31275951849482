import { NdsButton } from '@gonitro/rcl';
import { BkgModesEnum, NdsButtonTypesEnum } from '@gonitro/rcl/lib/_types';
import { SizesEnums } from '@gonitro/rcl/lib/_types/designsystem.enums';
import classNames from 'classnames';
import { HTMLAttributes } from 'react';
import { useSettings } from '~contexts/settings';
import HeaderMenu from '../header-menu';
import Logo from '../logo';
import './header.scss';

export interface HeaderProps extends HTMLAttributes<HTMLElement> {
}

function Header({ className, ...props }: HeaderProps) {
    const { environmentSettings: { portalHelpUrl } } = useSettings();

    return (
        <header {...props} className={classNames('c-header', className)}>
            <div className={'c-header__area c-header__area--left'}>
                <NdsButton
                    className={'c-header__hamburger'}
                    slot={'button'}
                    leftIcon={'fa-solid-bars'}
                    bgMode={BkgModesEnum.DARK}
                    buttonType={NdsButtonTypesEnum.NEUTRAL}
                    size={SizesEnums.XSMALL}
                />
                <Logo treatAsHomeLink />
            </div>
            <div className={'c-header__area c-header__area--right'}>
                <a
                    href={portalHelpUrl}
                    target={'_blank'}
                    className={'c-header__support-link'}
                    rel='noreferrer'
                >
                    <NdsButton
                        leftIcon={'fa-solid-circle-question'}
                        label={'Support'}
                        bgMode={BkgModesEnum.DARK}
                        buttonType={NdsButtonTypesEnum.NEUTRAL}
                        size={SizesEnums.XSMALL}
                    />
                </a>
                <HeaderMenu />
            </div>
        </header>
    );
}

export default Header;