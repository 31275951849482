import { createContext } from 'react';
import { SettingsModel, SigningTypes } from '~models';

export type SettingsContextType = {
    settings: SettingsModel;
    signingTypes: SigningTypes;
}

export const SettingsContext = createContext<SettingsContextType>({} as SettingsContextType);

export default SettingsContext;