import { FileType } from '~constants';
import { CloudStorageScenario } from './cloud-storage.scenario';


export default class OneDriveScenario extends CloudStorageScenario {

    // onedrive.js has to be loaded in index.html as it's initializes on DOMContentLoaded event, adding it later won't work :(

    showFilePicker() {
        const options: OneDrive.Options = {
            clientId: this.cloudSettings.microsoft.oneDrive.key,
            action: 'download',
            multiSelect: this.allowMultipleFiles,
            advanced: { filter: FileType.findExtensions(this.allowedMimeTypes).join(',') },
            success: (data) => {
                this.handleFilesSelected(data);
            },
            cancel: () => null,
            error: (e: any) => {
                this.notifyFileSelectionFailure(e);
            },
        };

        window.OneDrive.open(options);
    }

    handleFilesSelected(data: OneDrive.SuccessParams) {
        const files = data.value;

        const selectedFiles = files.map((file: OneDrive.SelectedFile) => {
            const mimeType = FileType.findMimeTypeByExtension(file.name.substring(file.name.lastIndexOf('.')));

            return {
                name: file.name,
                size: file.size,
                mimeType,
                url: file['@microsoft.graph.downloadUrl'],
                token: '',
            };
        });

        this.notifyFileSelection(selectedFiles);
    }
}
