import { LanguageDataLabel } from '~models';

export enum FlowAction {
    Approve,
    Delete,
    Download,
    FillOutForms,
    Edit,
    End,
    Extend,
    Preview,
    Reassign,
    RequestAuditTrail,
    Revoke,
    SendReminder,
    SendToSigners,
    Sign,
    Use,
    Duplicate,
    SoftDelete,
    View
}

export const FlowActionI18n: Record<FlowAction, LanguageDataLabel<'flow'>> = {
    [FlowAction.Approve]: 'action-approve',
    [FlowAction.Delete]: 'action-delete',
    [FlowAction.Download]: 'action-download',
    [FlowAction.FillOutForms]: 'action-fill-out-forms',
    [FlowAction.Edit]: 'action-edit',
    [FlowAction.End]: 'action-end',
    [FlowAction.Extend]: 'action-extend',
    [FlowAction.Preview]: 'action-preview',
    [FlowAction.Reassign]: 'action-reassign',
    [FlowAction.RequestAuditTrail]: 'action-request-audit-trail',
    [FlowAction.Revoke]: 'action-revoke',
    [FlowAction.SendReminder]: 'action-send-reminder',
    [FlowAction.SendToSigners]: 'action-send-to-signers',
    [FlowAction.Sign]: 'action-sign',
    [FlowAction.Use]: 'action-use',
    [FlowAction.Duplicate]: 'action-duplicate',
    [FlowAction.SoftDelete]: 'action-delete',
    [FlowAction.View]: 'action-view',
};

export enum DocumentActionType {
    ProgressiveActions = 'Progressive actions',
    DownloadActions = 'Download actions',
    DestructiveActions = 'Destructive actions'
}