import { Checkbox, NdsButton, NdsGroup, NdsIconFont, NdsInput, NdsSelect, NdsSelectOption } from '@gonitro/rcl';
import { NdsInputTypes, SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types/designsystem.enums';
import { useCallback, useRef, useState } from 'react';
import { AuthApi } from '~api/auth.api';
import Error from '~components/error';
import { useApi } from '~contexts/api';
import { useLanguages, useTranslation } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import { useEnhancedForm } from '~hooks/enhanced-form';
import { ValidateUtils } from '~lib';
import { HttpStatus } from '~lib/http/http.enums';
import { HttpError } from '~lib/http/http.error';
import { LanguageDataLabel } from '~models';
import './register-form.scss';

export interface RegisterFormProps {
    email: string,
    company?: string,
    token: string,
    setExpiredError: () => void;
}

type FormValues = {
    firstName: string,
    lastName: string,
    email: string,
    password: string;
    company: string;
    confirmPassword: string,
    language: string,
    consent: boolean,
};

export default function RegisterForm({ email = '', company = '', token, setExpiredError }: RegisterFormProps) {
    const { t } = useTranslation('unauthorized');
    const { availableLanguages, language } = useLanguages();
    const { environmentSettings: { registrationTermsOfUseUrl: consentUrl } } = useSettings();
    const authApi = useApi(AuthApi);
    const formRef = useRef<HTMLFormElement>(null);

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
    const [error, setError] = useState<LanguageDataLabel<'error'>>();
    const [loading, setLoading] = useState(false);

    const {
        registerCheckbox,
        registerNdsInput,
        registerNdsSelect,
        handleSubmit,
        formState: { isValid },
    } = useEnhancedForm<FormValues>({
        mode: 'all',
        defaultValues: {
            company,
            email,
            language: language?.isoCultureCode,
        },
    });

    const onSubmitCallback = useCallback(async ({ consent, ...data }: FormValues) => {
        setLoading(true);
        try {
            await authApi.registerLocalAccount({
                ...data,
                token,
            });
            document.location.reload(); // fully reload page (index.html) so BE can set auth cookie, then by internal logic user will go to default authorized route
        } catch (error) {
            if (error instanceof HttpError) {
                if (error.hasJsonBody) {
                    const code = error.body[0]?.ErrorCode;

                    if (code === 'UserInvitation.NotFound' || code === 'User.UnableToActivate') {
                        setExpiredError();

                        return;
                    }
                } else if (error.response.status === HttpStatus.TooManyRequests) {
                    setError('reset-password-user-locked-out');

                    return;
                }
            }
            setError('something-went-wrong');
        } finally {
            setLoading(false);
        }
    }, [authApi, setExpiredError, token]);

    return (
        <form
            ref={formRef}
            onSubmit={handleSubmit(onSubmitCallback)}
            noValidate
            className={'register-form'}
            autoComplete='off'
        >
            {error && <Error i18nKey={error} />}

            <NdsInput
                {...registerNdsInput('firstName', {
                    minLength: 1,
                    maxLength: 150,
                    required: true,
                    fieldLabel: t('first-name'),
                })}
                label={t('first-name')}
                inputType={NdsInputTypes.TEXT}
                required
            />
            <NdsInput
                {...registerNdsInput('lastName', {
                    minLength: 1,
                    maxLength: 150,
                    required: true,
                    fieldLabel: t('last-name'),
                })}
                label={t('last-name')}
                inputType={NdsInputTypes.TEXT}
                required
            />
            <NdsInput
                {...registerNdsInput('company', {
                    maxLength: 150,
                    fieldLabel: t('company'),
                })}
                label={t('company')}
                inputType={NdsInputTypes.TEXT}
            />
            <NdsSelect
                {...registerNdsSelect('language')}
                size={SizesEnums.MEDIUM}
                label={t('preferred-language')}
                content-align='bottom-left'
            >
                <NdsGroup>
                    {availableLanguages.map(lang =>
                        <NdsSelectOption
                            key={lang.isoCultureCode}
                            label={lang.nativeName}
                            value={lang.isoCultureCode}
                        />)}
                </NdsGroup>
            </NdsSelect>
            <NdsInput
                {...registerNdsInput('email', {
                    required: true,
                    maxLength: 256,
                    pattern: {
                        value: ValidateUtils.emailRegex,
                        message: 'pattern-email',
                    },
                    deps: 'password',
                    fieldLabel: t('email'),
                }, { state: email?.length ? StatesEnum.DISABLED : StatesEnum.DEFAULT })}
                label={t('email')}
                inputType={NdsInputTypes.EMAIL}
                required
            />
            <div className={'register-form__input-wrapper'}>
                <NdsInput
                    required
                    // need placeholder for real icon to limit value width and show custom icon as icon doesn't have click handler
                    rightIcon={'fa-null'}
                    {...registerNdsInput('password', {
                        fieldLabel: t('password'),
                        required: true,
                        minLength: ValidateUtils.passwordMinLength,
                        maxLength: ValidateUtils.passwordMaxLength,
                        pattern: {
                            value: ValidateUtils.passwordRegex,
                            message: 'pattern-password',
                        },
                        validate: (value, formValues) => {
                            if (value === formValues.email) {
                                return 'password-same-as-email';
                            }
                        },
                    })}
                    label={t('password')}
                    inputType={passwordVisible ? NdsInputTypes.TEXT : NdsInputTypes.PASSWORD}
                />
                <NdsIconFont
                    className={'register-form__eye-icon'}
                    fontName={passwordVisible ? 'fa-solid-eye' : 'fa-solid-eye-slash'}
                    onClick={() => {
                        setPasswordVisible(state => !state);
                    }}
                />
            </div>
            <div className={'register-form__input-wrapper'}>
                <NdsInput
                    required
                    // same -.-
                    rightIcon={'fa-null'}
                    {...registerNdsInput('confirmPassword', {
                        required: true,
                        validate: (val: string, formValues: FormValues) => {
                            if (formValues.password !== val) {
                                return 'password-does-not-match';
                            }
                        },
                    })}
                    label={t('confirm-password')}
                    inputType={confirmPasswordVisible ? NdsInputTypes.TEXT : NdsInputTypes.PASSWORD}
                />
                <NdsIconFont
                    className={'register-form__eye-icon'}
                    fontName={confirmPasswordVisible ? 'fa-solid-eye' : 'fa-solid-eye-slash'}
                    onClick={() => {
                        setConfirmPasswordVisible(state => !state);
                    }}
                />
            </div>

            <Checkbox
                className={'register-form__consent'}
                label={t('register-consent', { consentUrl })}
                labelAsHtml
                {...registerCheckbox('consent', { required: 'required-terms-conditions' })}
            />
            <NdsButton
                className='register-form__button'
                onClick={() => formRef?.current?.requestSubmit()}
                label={t('btn-create-your-account')}
                state={isValid && !loading ? StatesEnum.DEFAULT : StatesEnum.DISABLED}
                display-block
            />
        </form>
    );
}
