import { Table } from '@gonitro/rcl';
import classNames from 'classnames';
import { useTableContext } from '~contexts/table';
import { TableDataGeneric } from '~contexts/table/table.types';
import TableHeaderBuilder from './table-header-builder';
import TableRowBuilder, { TableRowBuilderProps } from './table-row-builder';
import { TableBuilderHelpers } from './tableBuilder.helpers';
import NoResultsFound from '../no-results-found';
import './tableBuilder.scss';

export interface TableBuilderProps<TRowIdKey extends string, TData extends TableDataGeneric<TRowIdKey>> {
    noHeader?: boolean;
    renderColumns?: TableRowBuilderProps<TRowIdKey, TData>['renderColumns'];
}

function TableBuilder<TRowIdKey extends string, TData extends TableDataGeneric<TRowIdKey>>({
    noHeader = false,
    renderColumns,
}: TableBuilderProps<TRowIdKey, TData>) {
    const { pageData, definition, totalItems } = useTableContext<TRowIdKey, TData>();

    const showNoResults = totalItems === 0;

    return (
        <Table
            className={classNames('c-table-builder', { 'c-table-builder--full-height': showNoResults })}
            header={!noHeader && <TableHeaderBuilder<TRowIdKey, TData> definition={definition} />}
            selectable={definition.selectable}
            widths={definition.columns.map(({ width }) => TableBuilderHelpers.colWidth(width))}
        >
            {showNoResults && (
                <tr>
                    <td
                        colSpan={definition.columns.length + (definition.selectable ? 1 : 0)}
                        className={'c-table-builder__no-results-cell'}
                    >
                        <div className={'c-table-builder__no-results-wrapper'}>
                            <NoResultsFound className={'c-table-builder__no-results'} />
                            {/* empty div for space-around justifying so message is more on top and it looks better*/}
                            <div />
                        </div>
                    </td>
                </tr>

            )}
            {!showNoResults && pageData.map((rowData: TData) =>
                <TableRowBuilder<TRowIdKey, TData>
                    key={rowData[definition.rowIdKey]}
                    definition={definition}
                    data={rowData}
                    renderColumns={renderColumns}
                />)
            }
        </Table>
    );
}

export default TableBuilder;
