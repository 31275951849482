export const initialDocumentGroupToggles = [
    {
        hasPermission: false,
        permissionId: 1,
        permission: 'Upload',
        label: 'document-group-tab-toggle-upload-documents',
        disabled: false,
    },
    {
        hasPermission: false,
        permissionId: 2,
        permission: 'Sign',
        label: 'document-group-tab-toggle-sign-documents',
        disabled: false,
    },
    {
        hasPermission: false,
        permissionId: 4,
        permission: 'Download',
        label: 'document-group-tab-toggle-download-documents',
        disabled: false,
    },
    {
        hasPermission: false,
        permissionId: 8,
        permission: 'Delete',
        label: 'document-group-tab-delete-toggle-documents',
        disabled: false,
    },
    {
        hasPermission: false,
        permissionId: 16,
        permission: 'View',
        label: 'document-group-tab-toggle-view-documents',
        disabled: false,
    },
    {
        hasPermission: false,
        permissionId: 32,
        permission: 'Audit',
        label: 'document-group-tab-toggle-request-audit-trail',
        disabled: false,
    },
    {
        hasPermission: false,
        permissionId: 64,
        permission: 'ViewTemplate',
        label: 'document-group-tab-toggle-view-templates',
        disabled: false,
    },
    {
        hasPermission: false,
        permissionId: 128,
        permission: 'CreateTemplate',
        label: 'document-group-tab-toggle-create-new-templates',
        disabled: false,
    },
    {
        hasPermission: false,
        permissionId: 256,
        permission: 'DeleteTemplate',
        label: 'document-group-tab-toggle-delete-templates',
        disabled: false,
    },
];
