import { useMemo } from 'react';
import { PackageApi } from '~api/package.api';
import {
    CreateStakeholderModel,
    SaveWorkflowModel as SavePackageWorkflowModel,
} from '~api/package.models';
import { SettingsApi } from '~api/settings.api';
import { TemplateApi } from '~api/template.api';
import { SaveWorkflowModel as SaveTemplateWorkflowModel } from '~api/template.models';
import { FlowStatusEnum } from '~constants';
import { useDefaultLanguageCodes } from '~contexts/i18n';
import { useActiveUserLanguage } from '~hooks/activeUserLanguage';
import {
    ActionNeededValueType,
    FieldModel,
    FlowActionsDocument,
    FlowInfoModel, FlowPropertiesModel,
    Guid, Language,
    WorkflowModel,
    WorkflowValidationModel,
} from '~models';
import { LegalNoticeModel } from '~models/legal-notice.models';
import { PackageDraftDetails } from '~models/package.models';
import { StakeholderModel } from '~models/stakeholder.models';
import { useApi } from '../api';
import { FlowStateType } from './flow.context';
import { FlowType } from './flow.types';

export interface FlowApiWrapper {
    getBasicInfo: (signal?: AbortSignal) => Promise<FlowInfoModel>;
    getDetails: (signal?: AbortSignal) => Promise<PackageDraftDetails & { language: Language }>;
    getStatus: (signal?: AbortSignal) => Promise<{ status: FlowStatusEnum, isActionNeeded?: boolean }>;
    getStakeholders: (signal?: AbortSignal) => Promise<StakeholderModel[]>;
    getElements: (signal?: AbortSignal) => Promise<FieldModel[]>;
    getWorkflow: (signal?: AbortSignal) => Promise<{
        validationResult: WorkflowValidationModel,
        queryResult: WorkflowModel,
    }>;
    getAvailableLegalNotices: (signal?: AbortSignal) => Promise<{
        isLegalNoticeEnabled: boolean;
        legalNotices: LegalNoticeModel[]
    }>;
    createFlow?: never; // specific for each flow type
    deleteDocuments: (flowId: Guid, documentIds: Guid[]) => Promise<boolean>;
    saveDocuments: (flowId: Guid, param: FlowStateType['infoDocuments'], signal?: AbortSignal) => Promise<{
        success: boolean;
        erroredDocsLocalIds: Guid[];
    }>;
    getDocumentActions: (signal?: AbortSignal) => Promise<{ documents: FlowActionsDocument[] }>;
    saveDocumentActions: (values: ActionNeededValueType[], signal?: AbortSignal) => Promise<boolean>;
    getDocumentPages: (documentId: Guid, startIndex: number, amount: number, signal?: AbortSignal) => Promise<string[]>;
    createStakeholder: (data: CreateStakeholderModel.Params['info'], signal?: AbortSignal) => Promise<StakeholderModel>;
    saveDetails: (data: FlowPropertiesModel & {
        packageName?: string,
        templateName?: string
    }, signal?: AbortSignal) => Promise<boolean>;
    saveWorkflow: (workflow: SavePackageWorkflowModel.Body | SaveTemplateWorkflowModel.Body, signal?: AbortSignal) => Promise<boolean>;
}

// this hook unifies different flow type apis into one that can be used in provider
// not all calls are used there, some are too specific and stays in view or other flowType specific component
// when some data or entire flowState is needed pass it to specific method, it's wrong to have flowState as dependency of memo

export const useFlowApiWrapper = (flowType: FlowType, flowId?: Guid): FlowApiWrapper => {
    const settingsApi = useApi(SettingsApi);
    const packageApi = useApi(PackageApi);
    const templateApi = useApi(TemplateApi);
    const userLanguage = useActiveUserLanguage();
    const { defaultUserLanguage } = useDefaultLanguageCodes();

    const api: PackageApi | TemplateApi = useMemo(() => {
        switch (flowType) {
            case FlowType.Package:
                return packageApi;
            case FlowType.Template:
                return templateApi;
        }
    }, [flowType, packageApi, templateApi]);

    // there is extra abstraction layer to do some extra mappings for different flow type apis

    return useMemo<FlowApiWrapper>(() => {
        return {
            async getBasicInfo(signal?: AbortSignal) {
                return await api.getBasicInfo({ flowId: flowId! }, signal);
            },
            async getDetails(signal?: AbortSignal) {
                const result = await api.getDetails({ flowId: flowId! }, signal);

                return {
                    ...result,
                    language: result.documents[0]?.documentLanguage || defaultUserLanguage,
                };
            },
            async getStatus(signal?: AbortSignal) {
                return await api.getFlowStatus({ flowId: flowId! }, signal);
            },
            async getStakeholders(signal?: AbortSignal) {
                const ret = await api.getStakeholders({ flowId: flowId! }, signal);

                return ret.map((el) => ({
                    ...el,
                    type: el.type.toLowerCase(),
                } as StakeholderModel));
            },
            async getWorkflow(signal?: AbortSignal) {
                return await api.getWorkflow({ flowId: flowId! }, signal);
            },
            async getElements(signal?: AbortSignal) {
                if (flowType === FlowType.Template) {
                    const ret = await Promise.all([
                        await api.getElements({
                            flowId: flowId!,
                            type: 'SigningField',
                        }, signal),
                        await api.getElements({
                            flowId: flowId!,
                            type: 'FormField',
                        }, signal),
                    ]);

                    return ret[0].concat(ret[1]);
                } else {
                    return await api.getElements({ flowId: flowId! }, signal);
                }
            },
            async deleteDocuments(flowId: Guid, documentIds: Guid[], signal?: AbortSignal) {
                try {
                    for (const documentId of documentIds) {
                        await api.deleteDocument({
                            flowId,
                            documentId,
                        }, signal);
                    }

                    return true;
                } catch (e) {
                    if (signal?.aborted) {
                        return false;
                    }

                    throw e;
                }
            },
            async saveDocuments(flowId: Guid, infoDocuments, signal?: AbortSignal) {
                let savedFiles: number = 0;
                let orderIndex: number = 0;
                const erroredDocsLocalIds: Guid[] = [];

                for (const doc of infoDocuments) {
                    if (doc.templateFile) {
                        orderIndex++;
                        savedFiles++;
                    } else if (doc.isNew) {
                        try {
                            if ('file' in doc) {
                                await api.uploadDocument({
                                    flowId: flowId!,
                                    orderIndex: ++orderIndex,
                                    documentName: doc.documentName,
                                    conversionTargetFormat: doc.conversionTargetFormat,
                                    isOptional: doc.isOptional,
                                    language: userLanguage,
                                    file: doc.file,
                                }, signal);
                            } else if ('isCloud' in doc && doc.isCloud) {
                                await api.uploadCloudDocument({
                                    flowId: flowId!,
                                    orderIndex: ++orderIndex,
                                    documentName: doc.documentName,
                                    conversionTargetFormat: doc.conversionTargetFormat,
                                    isOptional: doc.isOptional,
                                    language: userLanguage,
                                    fileUrl: doc.fileUrl,
                                    fileToken: doc.fileToken,
                                }, signal);
                            }
                            savedFiles++;
                        } catch (e) {
                            orderIndex--;
                            erroredDocsLocalIds.push(doc.localId);
                        }
                    } else {
                        try {
                            await api.updateDocument({
                                flowId: flowId!,
                                documentId: doc.id,
                                orderIndex: ++orderIndex,
                                documentName: doc.documentName,
                                isOptional: doc.isOptional,
                            }, signal);
                        } catch (e) {
                            orderIndex--;
                            erroredDocsLocalIds.push(doc.localId);
                        }
                    }
                }

                return {
                    success: savedFiles === infoDocuments.length,
                    erroredDocsLocalIds,
                };
            },
            async getDocumentActions(signal?: AbortSignal) {
                return api.getDocumentActions({ flowId: flowId! }, signal);
            },
            async saveDocumentActions(values: ActionNeededValueType[], signal?: AbortSignal) {
                return api.saveDocumentActions({
                    flowId: flowId!,
                    values: values.map(val => ({
                        documentId: val.documentId,
                        keepSignatures: val.keepSignatures ?? false,
                        keepFormFields: val.keepFormFields ?? false,
                        keepSigningFields: val.keepSigningFields ?? false,
                    })),
                }, signal);
            },
            async getAvailableLegalNotices(signal?: AbortSignal) {
                return settingsApi.getAvailableLegalNotices(signal);
            },
            async getDocumentPages(documentId, startIndex, amount, signal?: AbortSignal) {
                return (await api.getDocumentPages({
                    documentId,
                    startIndex,
                    amount,
                }, signal)).pages;
            },
            async createStakeholder(data, signal) {
                const result = await api.createStakeholder({
                    flowId: flowId!,
                    info: data,
                }, signal);

                return {
                    ...result,
                    type: result.type.toLowerCase(),
                } as StakeholderModel;
            },
            async saveWorkflow(workflow, signal) {
                return api.saveWorkflow({
                    flowId: flowId!,
                    workflow,
                }, signal);
            },
            async saveDetails(data, signal) {
                if (flowType === FlowType.Package) {
                    return packageApi.saveDetails({
                        flowId: flowId!,
                        data: {
                            ...data,
                            description: data.packageName!,
                        },
                    }, signal);
                } else {
                    return templateApi.saveDetails({
                        flowId: flowId!,
                        data,
                    }, signal);
                }
            },
        };
    }, [
        api,
        defaultUserLanguage,
        flowId,
        flowType,
        packageApi,
        settingsApi,
        templateApi,
        userLanguage,
    ]);
};
