import { FlowStateType } from './flow.context';
import { FlowActionType, FlowReducerActionPayloads, FlowStateAction } from './flow.types';
import {
    AddActorElementsReducer,
    AddActorReducer,
    AddDocumentsReducer,
    AddElementReducer,
    AddStakeholderReducer,
    DeleteActorElementsReducer,
    DeleteActorReducer,
    DeleteDocumentsReducer,
    DeleteElementReducer,
    MovePageObjectReducer,
    RemoveTemplateReducer, SetActorStakeholder,
    SetDocumentsReducer,
    SetFlowIdReducer,
    SetInitialDetailsReducer,
    SetInitialFlowReducer, SetInitialLegalNoticeConfigReducer, SetStakeholdersReducer,
    SetTemplateNameReducer,
    SetTemplateReducer,
    UpdateDocumentsReducer,
    UpdateElementReducer,
    UpdatePackageNameReducer,
    UpdatePropertiesReducer,
    UpdateStakeholderReducer,
    UpdateTemplateNameReducer,
} from './reducers';

export const flowReducer = <T extends FlowActionType>(state: FlowStateType, action: FlowStateAction<T>) => {

    switch (action.type) {
        case FlowActionType.SetFlowId:
            return SetFlowIdReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.SetFlowId]);
        // upload documents
        case FlowActionType.SetDocuments:
            return SetDocumentsReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.SetDocuments]);
        case FlowActionType.AddDocuments:
            return AddDocumentsReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.AddDocuments]);
        case FlowActionType.DeleteDocument:
            return DeleteDocumentsReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.DeleteDocument]);
        case FlowActionType.UpdateDocument:
            return UpdateDocumentsReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.UpdateDocument]);
        case FlowActionType.SetTemplate:
            return SetTemplateReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.SetTemplate]);
        case FlowActionType.RemoveTemplate:
            return RemoveTemplateReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.RemoveTemplate]);
        case FlowActionType.SetTemplateName:
            return SetTemplateNameReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.SetTemplateName]);
        // editor initials
        case FlowActionType.SetInitialDetails:
            return SetInitialDetailsReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.SetInitialDetails]);
        case FlowActionType.SetInitialLegalNoticeConfig:
            return SetInitialLegalNoticeConfigReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.SetInitialLegalNoticeConfig]);
        case FlowActionType.SetInitialFlow:
            return SetInitialFlowReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.SetInitialFlow]);
        // properties
        case FlowActionType.UpdateProperties:
            return UpdatePropertiesReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.UpdateProperties]);
        case FlowActionType.UpdatePackageName:
            return UpdatePackageNameReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.UpdatePackageName]);
        case FlowActionType.UpdateTemplateName:
            return UpdateTemplateNameReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.UpdateTemplateName]);
        // page objects
        case FlowActionType.MovePageObject:
            return MovePageObjectReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.MovePageObject]);
        // elements (add/delete/update)
        case FlowActionType.AddElement:
            return AddElementReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.AddElement]);
        case FlowActionType.DeleteElement:
            return DeleteElementReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.DeleteElement]);
        case FlowActionType.UpdateElement:
            return UpdateElementReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.UpdateElement]);
        // stakeholders
        case FlowActionType.AddStakeholder:
            return AddStakeholderReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.AddStakeholder]);
        case FlowActionType.UpdateStakeholder:
            return UpdateStakeholderReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.UpdateStakeholder]);
        case FlowActionType.SetStakeholders:
            return SetStakeholdersReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.SetStakeholders]);
        // workflow (shuffle)
        case FlowActionType.AddActor:
            return AddActorReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.AddActor]);
        case FlowActionType.DeleteActor:
            return DeleteActorReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.DeleteActor]);
        case FlowActionType.AddActorElements:
            return AddActorElementsReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.AddActorElements]);
        case FlowActionType.DeleteActorElements:
            return DeleteActorElementsReducer(state, action.payload as FlowReducerActionPayloads[FlowActionType.DeleteActorElements]);
        case FlowActionType.SetActorStakeholder:
            return SetActorStakeholder(state, action.payload as FlowReducerActionPayloads[FlowActionType.SetActorStakeholder]);
    }

    return { ...state };
};
