import { SortTableData } from '@gonitro/rcl/lib/_types';
import { createContext, Dispatch } from 'react';
import { Guid } from '~models';
import { TableDataGeneric, TableDefinition, TableFilterValue } from './table.types';


export type TableContextType<
    TRowIdKey extends string = any,
    TData extends TableDataGeneric<TRowIdKey> = TableDataGeneric<TRowIdKey>
> = {
    definition: TableDefinition<TRowIdKey>;
    data: TData[]; // all data (already fetched)
    pageData: TData[]; // actual data to render
    totalItems: number; // total items in table

    refresh: () => void; // tells provider to refresh page data asap

    // visible items
    itemsPerPage: number;
    itemsPerPageOptions: number[]
    setItemsPerPage: (num: number) => void;

    // paging
    totalPages: number; // starting from 1
    page: number; // starting from 1
    setPage: (num: number) => void;

    // sorting
    sortConfig: SortTableData;
    sortChange: (columnKey: string) => void;

    // rows selection, more detailed selection (select all on actual screen can be realized in hooks
    selectRows: (ids: TData[TRowIdKey][]) => void; // select rows based on index or it's id
    unselectRows: (ids: TData[TRowIdKey][]) => void; // unselect rows based on index or it's id
    unselectAllRows: () => void; // useful after performing bulk action
    selectedRows: (Guid | number)[];
    isRowSelectable?: (data: TData) => boolean;

    // filtering
    filterValues: Record<string, TableFilterValue>;
    setFilterValue: (name: string, value: TableFilterValue) => void;

    // row focusing happens on click of row, it allows to do extra stuff like show details panel
    focusedRow?: TData | undefined;
    setFocusedRow: (prop: Guid | number | TData | undefined) => void;

    rowsInProgress: TData[TRowIdKey][];
    setRowsInProgress: Dispatch<TData[TRowIdKey][]>;
}

export const TableContext = createContext<TableContextType>({} as TableContextType);
