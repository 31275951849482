import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { BaseRoutes } from '~constants/routes';
import { PropsWithClassNames } from '~models';
import defaultLogoLight from '~resources/logo-white.svg';
import defaultLogoDark from '~resources/logo.svg';
import './logo.scss';

export interface LogoProps extends PropsWithClassNames {
    treatAsHomeLink?: boolean;
    dark?: boolean;
}

function Logo({ className, treatAsHomeLink, dark }: LogoProps) {
    const defaultLogo = dark ? defaultLogoDark : defaultLogoLight;
    const logo = /* TODO get logo from theme or */ defaultLogo;
    const logoComp = <img src={logo} alt={'App logo'} />;

    if (treatAsHomeLink) {
        return (
            <Link to={BaseRoutes.Index} className={classNames('c-logo', className)}>
                {logoComp}
            </Link>
        );
    }

    return (
        <div className={classNames('c-logo', className)}>
            {logoComp}
        </div>
    );
}

export default Logo;