import { NdsProgress, NdsProgressProps } from '@gonitro/rcl';
import { NdsProgressIndicatorStyles, SizesEnums } from '@gonitro/rcl/lib/_types';
import { NdsProgressIndicatorTypes, NdsProgressModes } from '@gonitro/rcl/lib/_types/designsystem.enums';
import classNames from 'classnames';
import { HtmlHTMLAttributes } from 'react';
import './loader.scss';

export interface LoaderProps extends HtmlHTMLAttributes<HTMLDivElement> {
    size?: NdsProgressProps['size'];
    center?: boolean;
}

// TODO should evolve to whole page loader only as NdsProgress is there , actually it's simple copy paste from wysiwys
function Loader({ size = SizesEnums.MEDIUM, center = false, children, className, ...props }: LoaderProps) {
    return (
        <div
            {...props}
            className={classNames(
                `c-loader c-loader--${size}`, { 'c-loader--center': center }, className,
            )}
        >
            <NdsProgress
                size={size}
                indicatorType={NdsProgressIndicatorTypes.CIRCULAR}
                indicatorMode={NdsProgressModes.INDETERMINATE}
                indicatorStyle={NdsProgressIndicatorStyles.DEFAULT}
                rounded
                showLabel={false}
            />
            {/* TODO clean loader from wysiwys stuff (code and styles) in Q2 2024*/}
            {/*<div className='c-loader__container'>*/}
            {/*    <div className='c-loader__dot c-loader__dot--1' />*/}
            {/*    <div className='c-loader__dot c-loader__dot--2' />*/}
            {/*    <div className='c-loader__dot c-loader__dot--3' />*/}
            {/*    <div className='c-loader__dot c-loader__dot--4' />*/}
            {/*</div>*/}
        </div>
    );
}

export default Loader;