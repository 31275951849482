import { Checkbox, Modal, ModalContent, ModalFooter } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { Button } from '@progress/kendo-react-buttons';
import { Icon } from '@progress/kendo-react-common';
import { DropDownList, ListItemProps } from '@progress/kendo-react-dropdowns';
import { cloneElement, ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import ButtonWithLoader from '~components/button-with-loader';
import ContactForm from '~components/contact-form';
import ContactMissingInformationModal from '~components/contact-missing-information-modal';
import ContactSelector from '~components/contact-selector';
import { FlowGroupIcon, FlowGroupTitleI18nKey, FlowGroupType } from '~contexts/flow/flow.types';
import { useTranslation } from '~contexts/i18n';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { ContactBucketContactModel, ContactBucketItemModel } from '~models';
import './flowEditorAddRecipientModal.scss';

export interface FlowEditorAddRecipientModalProps {
    type?: FlowGroupType;
    fixedType?: boolean;
    canAddRecipientWithoutContactInfo?: boolean;
    onCloseClick: () => void;
    addClickCallback: (type: FlowGroupType, refreshStakeholders: boolean, contact?: ContactBucketItemModel) => Promise<void>;
}

function FlowEditorAddRecipientModal({
    type,
    fixedType = false,
    onCloseClick: onCloseClickProp,
    canAddRecipientWithoutContactInfo,
    addClickCallback,
}: FlowEditorAddRecipientModalProps) {
    const [typeValue, setTypeValue] = useState<FlowGroupType>(type ?? FlowGroupType.Signers);
    const { t } = useTranslation('flow');
    const { t: tBase } = useTranslation('base');
    const [contact, setContact] = useState<ContactBucketItemModel | null>(null);
    const [isLoading, setLoading] = useState(false);
    const [isContactSelectionDisabled, setIsContactSelectionDisabled] = useState(false);
    const [openAddContact, setOpenAddContact] = useState<boolean>(false);
    const shouldRefreshStakeholders = useRef(false);

    const valueRender = useCallback((
        span: ReactElement<HTMLSpanElement>,
        value: any,
    ) => {
        return cloneElement(span, span.props, (
            <span className={'c-flow-editor-add-recipient-modal__recipient-type-item'}>
                <Icon className={FlowGroupIcon[value as FlowGroupType]} />
                <span dangerouslySetInnerHTML={{ __html: t(FlowGroupTitleI18nKey[value as FlowGroupType]) }} />
            </span>
        ));
    }, [t]);

    const itemRender = useCallback((
        li: ReactElement<HTMLLIElement>,
        itemProps: ListItemProps,
    ) => {
        return cloneElement(li, li.props, (
            <span className={'c-flow-editor-add-recipient-modal__recipient-type-item'}>
                <Icon className={FlowGroupIcon[itemProps.dataItem as FlowGroupType]} />
                <span dangerouslySetInnerHTML={{ __html: t(FlowGroupTitleI18nKey[itemProps.dataItem as FlowGroupType]) }} />
            </span>
        ));
    }, [t]);

    const onContactChange = useCallback((data: ContactBucketItemModel | null, wasContactsEdited: boolean) => {
        setContact(data);
        shouldRefreshStakeholders.current = shouldRefreshStakeholders.current || wasContactsEdited;
    }, []);

    const onAddClick = useCallback(async () => {
        if (!contact && !canAddRecipientWithoutContactInfo) {
            return;
        }

        setLoading(true);
        try {
            if (!contact) {
                await addClickCallback(typeValue, shouldRefreshStakeholders.current);
            } else {
                await addClickCallback(typeValue, shouldRefreshStakeholders.current, contact);
            }
        } catch (e) {
            console.warn(e);
        }
        setLoading(false);
    }, [addClickCallback, canAddRecipientWithoutContactInfo, contact, typeValue]);

    const onMissingModalCloseClick = useCallback(() => {
        setContact(null);
    }, []);

    const contactMissingInfo = useMemo(() => {
        return (
            !contact
            || ('contactId' in contact && contact.contactId)
            || ('contactGroupId' in contact && contact.contactGroupId)
        )
            ? null
            : {
                lastName: !(contact as ContactBucketContactModel).lastName,
                emailAddress: !(contact as ContactBucketContactModel).emailAddress,
            };
    }, [contact]);

    const addBtnEnabled = (canAddRecipientWithoutContactInfo === true) || (contact !== null && !contactMissingInfo);

    return (
        <>
            <Modal
                headerLabel={t('add-recipient-title')}
                size={SizesEnums.SMALL}
                className={'c-flow-editor-add-recipient-modal'}
                onCloseClick={onCloseClickProp}
                withClose
                renderContent={(
                    <ModalContent className={'c-flow-editor-add-recipient-modal__content'}>
                        <DropDownList
                            disabled={fixedType}
                            className={'c-flow-editor-add-recipient-modal__recipient-type'}
                            value={typeValue}
                            data={Object.values(FlowGroupType)}
                            valueRender={valueRender}
                            itemRender={itemRender}
                            onChange={(e) => setTypeValue(e.value)}
                        />
                        <ContactSelector
                            onChange={onContactChange}
                            value={contact}
                            disabled={isContactSelectionDisabled}
                            showAddContactButton
                            showCloudButton
                        />
                        {canAddRecipientWithoutContactInfo && (
                            <Checkbox
                                value={isContactSelectionDisabled}
                                size={SizesEnums.SMALL}
                                name={'without-recipient'}
                                label={t('add-recipient-without-contact-info')}
                                onChange={() => {
                                    if (!isContactSelectionDisabled) {
                                        setContact(null);
                                    }

                                    setIsContactSelectionDisabled(!isContactSelectionDisabled);
                                }}
                            />
                        )}
                    </ModalContent>
                )}
                renderFooter={
                    <ModalFooter>
                        <Button onClick={onCloseClickProp}>{tBase('cancel')}</Button>
                        <ButtonWithLoader
                            themeColor={'primary'}
                            disabled={!addBtnEnabled}
                            showLoader={isLoading}
                            onClick={onAddClick}
                            loaderProps={{ themeColor: 'light' }}
                        >{tBase('add')}</ButtonWithLoader>
                    </ModalFooter>
                }
            />
            <OverlayPortal type={'modal'} visible={!!contactMissingInfo}>
                {() => <ContactMissingInformationModal
                    onCloseClick={onMissingModalCloseClick}
                    missing={contactMissingInfo ?? {}}
                />}
            </OverlayPortal>
            <OverlayPortal type={'panel'} visible={openAddContact}>
                {() => <ContactForm
                    open={true}
                    onClose={() => setOpenAddContact(false)}
                />}
            </OverlayPortal>
        </>
    );
}

export default FlowEditorAddRecipientModal;
