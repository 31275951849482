import { Checkbox, NdsBadge, NdsIconFont, Table, TableCell, TableRow } from '@gonitro/rcl';
import { NdsBadgeStatesEnum } from '@gonitro/rcl/lib/_types';
import { Skeleton } from '@progress/kendo-react-indicators';
import { Dispatch, SetStateAction } from 'react';
import { PdfTargetFormat, PdfTargetFormatLabel } from '~constants';
import { useTranslation } from '~contexts/i18n';
import { ActionNeededValueType, FlowActionsDocument, Guid } from '~models';
import FlowActionNeededModalTableHeader from './flow-action-needed-modal-table-header';
import Typography, { TypographyToken } from '../../../typography';
import './flowActionNeededModalTable.scss';

export interface FlowActionNeededModalTableProps {
    documents: FlowActionsDocument[];
    values: ActionNeededValueType[];
    setValues: Dispatch<SetStateAction<ActionNeededValueType[]>>;
}

interface CellCheckboxProps {
    name: string;
    value: boolean | null;
    onChange: (value: boolean) => void;
    disabled?: boolean;
}

const CellCheckbox = ({ name, value, onChange, disabled }: CellCheckboxProps) => {
    const { t } = useTranslation('base');

    return value !== null
        ? (
            <Checkbox
                name={name}
                value={value}
                onChange={(_, val) => onChange(val)}
                disabled={disabled}
            />
        )
        : (
            <Typography
                token={TypographyToken.UiFormsLabelSm}
                className={'c-flow-action-needed-modal-table__none'}
                text={t('none')}
            />
        );
};

const COLUMN_WIDTHS = [40, 20, 20, 20];

function FlowActionNeededModalTable({ documents, values, setValues }: FlowActionNeededModalTableProps) {

    const onChange = (id: Guid, name: keyof ActionNeededValueType, value: boolean) => {
        setValues((current: ActionNeededValueType[]) => (
            current.map(row => {
                if (row.documentId === id) {
                    return {
                        ...row,
                        [name]: value,
                    };
                }

                return row;
            })
        ));
    };

    return (
        <Table
            className={'c-flow-action-needed-modal-table'}
            header={<FlowActionNeededModalTableHeader values={values} setValues={setValues} />}
            widths={COLUMN_WIDTHS}
        >
            {documents.length === 0 && (
                <tr>
                    <td colSpan={4}>
                        <Skeleton
                            shape={'rectangle'}
                            animation={{ type: 'pulse' }}
                            style={{
                                width: '100%',
                                height: '2rem',
                            }}
                        />
                    </td>
                </tr>
            )}
            {documents.length > 0 && (
                documents.map((document) => {
                    const rowValues = values.find(doc => doc.documentId === document.id)!;

                    return (
                        <TableRow key={document.id}>
                            <TableCell className={'c-flow-action-needed-modal-table__document'}>
                                <NdsIconFont fontName={'fa-regular-file'} />
                                <span className={'c-flow-action-needed-modal-table__document-name'}>{document.name}</span>
                                {document.conversionTargetFormat && (
                                    <span className={'c-flow-action-needed-modal-table__document-target'}>
                                        <NdsBadge
                                            state={NdsBadgeStatesEnum.NEUTRAL}
                                            text={
                                                rowValues.keepSignatures === true
                                                    ? PdfTargetFormatLabel[PdfTargetFormat.PDF] // if keep signatures is selected document won't be converted
                                                    : PdfTargetFormatLabel[document.conversionTargetFormat] // otherwise show value as defined in doc
                                            }
                                        />
                                    </span>
                                )}
                            </TableCell>
                            <TableCell>
                                <CellCheckbox
                                    name={`${document.id}-signingFields`}
                                    value={rowValues.keepSigningFields}
                                    onChange={(value) => onChange(document.id, 'keepSigningFields', value)}
                                />
                            </TableCell>
                            <TableCell>
                                <CellCheckbox
                                    name={`${document.id}-formFields`}
                                    value={rowValues.keepFormFields}
                                    onChange={(value) => onChange(document.id, 'keepFormFields', value)}
                                    disabled={rowValues.keepSignatures === true}
                                />
                            </TableCell>
                            <TableCell>
                                <CellCheckbox
                                    name={`${document.id}-signatures`}
                                    value={rowValues.keepSignatures}
                                    onChange={(value) => onChange(document.id, 'keepSignatures', value)}
                                    disabled={rowValues.keepFormFields === true}
                                />
                            </TableCell>
                        </TableRow>
                    );
                })
            )}
        </Table>
    );
}

export default FlowActionNeededModalTable;
