import { Permission } from '~constants';
import { GeneralToggle } from './useGeneralToggles';

export const initialToggles: GeneralToggle[] = [
    {
        permission: Permission.General_ActionCreateSharedContactGroup,
        label: 'general-tab-create-new-shared-contact-groups-toggle',
        hasPermission: false,
        disabled: false,
        relatedIds: ['sharedContactGroup'],
    },
    {
        permission: Permission.General_ActionCreateSharedContact,
        label: 'general-tab-create-new-shared-contacts-toggle',
        hasPermission: false,
        disabled: false,
        relatedIds: ['sharedContacts'],
    },
    {
        permission: Permission.General_ActionDeleteSharedContactGroup,
        label: 'general-tab-delete-existing-shared-contact-groups-toggle',
        hasPermission: false,
        disabled: false,
        relatedIds: ['sharedContactGroup'],
    },
    {
        permission: Permission.General_ActionDeleteSharedContact,
        label: 'general-tab-delete-existing-shared-contacts-toggle',
        hasPermission: false,
        disabled: false,
        relatedIds: ['sharedContacts'],
    },
    {
        permission: Permission.General_ActionEditSharedContactGroup,
        label: 'general-tab-edit-existing-shared-contacts-groups-toggle',
        hasPermission: false,
        disabled: false,
        relatedIds: ['sharedContactGroup'],
    },
    {
        permission: Permission.General_ActionEditSharedContact,
        label: 'general-tab-edit-existing-shared-contacts-toggle',
        hasPermission: false,
        disabled: false,
        relatedIds: ['sharedContacts'],
    },
    {
        permission: Permission.General_ActionViewSharedContactGroup,
        label: 'general-tab-view-shared-contact-groups-toggle',
        hasPermission: false,
        disabled: false,
        relatedIds: ['sharedContactGroup'],
    },
    {
        permission: Permission.General_ActionViewSharedContact,
        label: 'general-tab-view-shared-contacts-toggle',
        hasPermission: false,
        disabled: false,
        relatedIds: ['sharedContacts', 'sharedContactGroup'],
    },
    {
        permission: Permission.AccessDocumentPortal,
        label: 'general-tab-access-to-document-portal-toggle',
        hasPermission: false,
        disabled: false,
    },
    {
        permission: Permission.AccessDocumentPortal_ActionChangeKeyPairPerSigningField,
        label: 'general-tab-change-keypair-per-signing-field-toggle',
        hasPermission: false,
        disabled: false,
        parentId: Permission.AccessDocumentPortal,
    },
    {
        permission: Permission.AccessSignerPortal,
        label: 'general-tab-access-to-the-signer-portal-toggle',
        hasPermission: true,
        disabled: true,
    },
    {
        permission: Permission.AccessSignerPortal_ActionBulkSignDocument,
        label: 'general-tab-signer-portal-bulk-sign-documents-toggle',
        hasPermission: false,
        disabled: false,
        parentId: Permission.AccessSignerPortal,
    },
    {
        permission: Permission.AccessTemplatesPortal,
        label: 'general-tab-access-to-template-portal-toggle',
        hasPermission: false,
        disabled: false,
    },
    {
        permission: Permission.AccessUserManagementPortal,
        label: 'general-tab-access-to-user-management-portal-toggle',
        hasPermission: false,
        disabled: false,
    },
    {
        permission: Permission.AccessUserManagementPortal_ActionCreateDocumentGroup,
        label: 'general-tab-create-new-document-groups-toggle',
        hasPermission: false,
        disabled: false,
        parentId: Permission.AccessUserManagementPortal,
    },
    {
        permission: Permission.AccessUserManagementPortal_ActionCreateUser,
        label: 'general-tab-invite-users-toggle',
        hasPermission: false,
        disabled: false,
        parentId: Permission.AccessUserManagementPortal,
        relatedIds: ['viewUserGroups', 'viewUsers'],
    },
    {
        permission: Permission.AccessUserManagementPortal_ActionCreateUserGroup,
        label: 'general-tab-create-new-user-groups-toggle',
        hasPermission: false,
        disabled: false,
        parentId: Permission.AccessUserManagementPortal,
        relatedIds: ['viewUserGroups'],
    },
    {
        permission: Permission.AccessUserManagementPortal_ActionDeleteDocumentGroup,
        label: 'general-tab-delete-existing-document-groups-toggle',
        hasPermission: false,
        disabled: false,
        parentId: Permission.AccessUserManagementPortal,
    },
    {
        permission: Permission.AccessUserManagementPortal_ActionDeleteUser,
        label: 'general-tab-delete-existing-users-toggle',
        hasPermission: false,
        disabled: false,
        parentId: Permission.AccessUserManagementPortal,
        relatedIds: ['viewUsers'],
    },
    {
        permission: Permission.AccessUserManagementPortal_ActionDeleteUserGroup,
        label: 'general-tab-delete-user-groups-toggle',
        hasPermission: false,
        disabled: false,
        parentId: Permission.AccessUserManagementPortal,
        relatedIds: ['viewUserGroups'],
    },
    {
        permission: Permission.AccessUserManagementPortal_ActionEditDocumentGroup,
        label: 'general-tab-edit-existing-document-groups-toggle',
        hasPermission: false,
        disabled: false,
        parentId: Permission.AccessUserManagementPortal,
        relatedIds: ['viewDocumentGroups'],
    },
    {
        permission: Permission.AccessUserManagementPortal_ActionEditUser,
        label: 'general-tab-edit-users-toggle',
        hasPermission: false,
        disabled: false,
        parentId: Permission.AccessUserManagementPortal,
        relatedIds: ['viewUsers'],
    },
    {
        permission: Permission.AccessUserManagementPortal_ActionEditUserGRoup,
        label: 'general-tab-edit-existing-user-groups-toggle',
        hasPermission: false,
        disabled: false,
        parentId: Permission.AccessUserManagementPortal,
        relatedIds: ['viewUserGroups'],
    },
    {
        permission: Permission.AccessUserManagementPortal_ActionViewDocumentGroup,
        label: 'general-tab-view-document-groups-toggle',
        hasPermission: false,
        disabled: false,
        parentId: Permission.AccessUserManagementPortal,
    },
    {
        permission: Permission.AccessUserManagementPortal_ActionViewUser,
        label: 'general-tab-view-users-toggle',
        hasPermission: false,
        disabled: false,
        parentId: Permission.AccessUserManagementPortal,
    },
    {
        permission: Permission.AccessUserManagementPortal_ActionViewUserGroup,
        label: 'general-tab-view-user-groups-toggle',
        hasPermission: false,
        disabled: false,
        parentId: Permission.AccessUserManagementPortal,
    },
    {
        permission: Permission.AccessSettings,
        label: 'general-tab-access-settings-toggle',
        hasPermission: false,
        disabled: false,
    },
    {
        permission: Permission.AccessSettings_ActionManageTenant,
        label: 'general-tab-manage-tenant-toggle',
        hasPermission: false,
        disabled: false,
        parentId: Permission.AccessSettings,
    },
];
