import { NdsButton, NdsInput } from '@gonitro/rcl';
import { NdsInputTypes, StatesEnum } from '@gonitro/rcl/lib/_types/designsystem.enums';
import { useCallback, useRef, useState } from 'react';
import Error from '~components/error';
import { useTranslation } from '~contexts/i18n';
import { useEnhancedForm } from '~hooks/enhanced-form';
import { ValidateUtils } from '~lib';
import { HttpStatus } from '~lib/http/http.enums';
import { HttpError } from '~lib/http/http.error';
import { LanguageDataLabel } from '~models';
import './lost-password-form.scss';

export interface LostPasswordFormProps {
    onSubmit: (email: string) => Promise<void>;
}

type FormValues = {
    email: string,
};

function LostPasswordForm({ onSubmit }: LostPasswordFormProps) {
    const { t } = useTranslation('unauthorized');
    const formRef = useRef<HTMLFormElement>(null);

    const [error, setError] = useState<LanguageDataLabel<'error'>>();

    const {
        registerNdsInput,
        handleSubmit,
        formState: { isValid },
    } = useEnhancedForm<FormValues>({ mode: 'onChange' });

    const onSubmitCallback = useCallback(async (values: FormValues) => {
        try {
            await onSubmit(values.email);
        } catch (e) {
            if (e instanceof HttpError) {
                if (e.response.status === HttpStatus.TooManyRequests) {
                    setError('too-many-retries');

                    return;
                }
            }
            setError('something-went-wrong');
        }
    }, [onSubmit]);

    return (
        <form
            className='lost-password-form'
            ref={formRef}
            onSubmit={handleSubmit(onSubmitCallback)}
            noValidate
        >
            {error && <Error i18nKey={error} />}
            <NdsInput
                {...registerNdsInput('email', {
                    required: true,
                    pattern: {
                        value: ValidateUtils.emailRegex,
                        message: 'pattern-email',
                    },
                })}
                label={t('email')}
                inputType={NdsInputTypes.EMAIL}
                required
            />
            <NdsButton
                className='lost-password-form__button'
                label={t('btn-reset-password')}
                onClick={() => formRef.current?.requestSubmit()}
                state={isValid ? StatesEnum.DEFAULT : StatesEnum.DISABLED}
                displayBlock
            />
        </form>
    );
}

export default LostPasswordForm;