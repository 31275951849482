import { NdsGroup, NdsInput, NdsSelect, NdsSelectChangeEvent, NdsSelectOption } from '@gonitro/rcl';
import { SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types';
import { useFormContext } from 'react-hook-form';
import { countryInfoList } from '~constants';
import { useLanguages, useTranslation } from '~contexts/i18n';
import { useEnhancedFormContext } from '~hooks/enhanced-form/enhanced-form-context';
import './edit-profile-tab.scss';

export interface EditProfileTabProps {
}

const EditProfileTab = () => {
    const { t } = useTranslation('user-management');

    const { formState: { defaultValues }, setValue } = useFormContext();
    const { registerNdsInput, registerNdsSelect } = useEnhancedFormContext();

    const { availableLanguages } = useLanguages();

    return (
        <div
            className={'edit-user-profile-tab'}
        >
            <NdsInput
                {...registerNdsInput('firstName', { required: true })}
                label={t('user-management-edit-user-profile-tab-first-name')}
                size={SizesEnums.SMALL}
                required
            />
            <NdsInput
                {...registerNdsInput('lastName', { required: true })}
                label={t('user-management-edit-user-profile-tab-last-name')}
                size={SizesEnums.SMALL}
                required
            />
            <NdsInput
                {...registerNdsInput('emailAddress')}
                state={StatesEnum.DISABLED}
                label={t('user-management-edit-user-profile-tab-email')}
                size={SizesEnums.SMALL}
                hintText={defaultValues?.cloudProviderName && t('user-management-edit-user-profile-tab-email-hint-text', { cloudProviderName: defaultValues?.cloudProviderName })}
            />
            <NdsInput
                {...registerNdsInput('company')}
                state={StatesEnum.DEFAULT}
                label={t('user-management-edit-user-profile-tab-company')}
                size={SizesEnums.SMALL}
            />
            <NdsSelect
                {...registerNdsSelect('language', {
                    onChange: (event: NdsSelectChangeEvent) => {
                        setValue('language', event.target.value);
                    },
                })}
                label={t('user-management-edit-user-profile-tab-language')}
                hintText={t('user-management-edit-user-profile-tab-language-hint-text')}
                size={SizesEnums.SMALL}
            >
                <NdsGroup>
                    {
                        availableLanguages.map(lang =>
                            <NdsSelectOption
                                key={lang.isoCultureCode}
                                label={lang.nativeName}
                                value={lang.isoCultureCode}
                            />)
                    }
                </NdsGroup>
            </NdsSelect>
            <NdsSelect
                {...registerNdsSelect('defaultPhoneNumberCountry')}
                label={t('user-management-edit-user-profile-tab-prefix')}
                hintText={t('user-management-edit-user-profile-tab-prefix-hint-text')}
                size={SizesEnums.SMALL}
            >
                <NdsGroup>
                    {
                        countryInfoList.map(countryInfo =>
                            <NdsSelectOption
                                key={countryInfo.countryCode}
                                label={`${countryInfo.countryName} ${countryInfo.phonePrefix}`}
                                value={countryInfo.countryCode}
                            />)
                    }
                </NdsGroup>
            </NdsSelect>
        </div>
    );
};

export default EditProfileTab;
