import { RefObject, useCallback } from 'react';
import { DocumentViewerPageModel, DocumentViewerPagePosition } from '../types';

export function useDocumentViewerScrollToPageCallback(
    ref: RefObject<HTMLDivElement>,
    pages: DocumentViewerPageModel[],
    pagePositions: DocumentViewerPagePosition[],
    pageGutterPx: number,
) {
    return useCallback((index: number | {
        document: number | string;
        page: number
    }, behavior: ScrollBehavior = 'smooth') => {
        let foundPage: DocumentViewerPageModel | undefined;

        if (typeof index === 'object') {
            const { document, page } = index;

            if (typeof document === 'string') {
                foundPage = pages.find(el => el.documentId === document && el.pageIndex === page);
            } else {
                foundPage = pages.find(el => el.documentIndex === document && el.pageIndex === page);
            }
        } else {
            if (isNaN(index)) {
                return;
            }
            foundPage = pages.find(page => page.absolutePageIndex === index);
        }

        if (foundPage === undefined) {
            return;
        }

        const foundIndex: number = foundPage.absolutePageIndex;
        const position = pagePositions[foundIndex];
        const top = foundIndex === 0
            ? 0 :
            position.offset.top - position.headerHeight - pageGutterPx + 1;

        ref.current?.scrollTo({
            top,
            left: 0,
            behavior,
        });
    }, [pagePositions, pageGutterPx, ref, pages]);
}
