import { Checkbox, NdsButton, NdsGroup, NdsInput, NdsSelect, NdsSelectOption } from '@gonitro/rcl';
import { NdsInputTypes, SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types/designsystem.enums';
import { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthApi } from '~api/auth.api';
import { BaseRoutes } from '~constants/routes';
import { useApi } from '~contexts/api';
import { useIsLanguageAvailable, useLanguages, useTranslation } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import { useEnhancedForm } from '~hooks/enhanced-form';
import { HttpError } from '~lib/http/http.error';
import './register-cloud-form.scss';

export interface RegisterCloudFormProps {
    state: string,
    token?: string,
    conversionToken?: string;
    defaultValues: {
        avatarb64?: string;
        company?: string;
        email: string;
        firstName?: string;
        language?: string;
        lastName?: string;
    }
    goToRegisterPage?: () => void;
}

type FormValues = {
    avatarb64: string;
    firstName: string;
    lastName: string;
    language: string;
    company: string;
    email: string;
    consent: boolean;
};

function RegisterCloudForm({
    state,
    token,
    conversionToken,
    goToRegisterPage,
    defaultValues: defaultValuesFromProps,
}: RegisterCloudFormProps) {
    const { t } = useTranslation('unauthorized');
    const { availableLanguages, language } = useLanguages();
    const isLanguageAvailable = useIsLanguageAvailable();
    const { environmentSettings: { registrationTermsOfUseUrl: consentUrl } } = useSettings();
    const authApi = useApi(AuthApi);
    const formRef = useRef<HTMLFormElement>(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const defaultValues = useRef<Partial<FormValues>>({
        ...defaultValuesFromProps,
        language: defaultValuesFromProps.language && isLanguageAvailable(defaultValuesFromProps.language)
            ? defaultValuesFromProps.language
            : language.isoCultureCode,
        consent: false,
    });

    const {
        register,
        registerCheckbox,
        registerNdsInput,
        registerNdsSelect,
        handleSubmit,
        formState: { isValid },
    } = useEnhancedForm<FormValues>({
        mode: 'all',
        defaultValues: defaultValues.current,
    });

    const onSubmitCallback = useCallback(async (data: FormValues) => {
        setLoading(true);

        try {
            await authApi.registerCloudAccount({
                token,
                conversionToken,
                data: {
                    ...data,
                    state,
                },
            });
            document.location.reload(); // fully reload page (index.html) so BE can set auth cookie, then by internal logic user will go to default authorized route
        } catch (e) {
            if (e instanceof HttpError) {
                const code = e.getErrorCode();

                if (code === 'User.AccessDenied') {
                    goToRegisterPage?.();
                }

                if (e.response.status >= 500) {
                    navigate(BaseRoutes.Login);
                }
            }
            setLoading(false);
        }
    }, [
        authApi,
        conversionToken,
        goToRegisterPage,
        navigate,
        state,
        token,
    ]);

    return (
        <form
            ref={formRef}
            noValidate
            onSubmit={handleSubmit(onSubmitCallback)}
            className='register-cloud-form'
            autoComplete='off'
        >
            {/* TODO add avatar field instead hidden one */}
            <input type={'hidden'} {...register('avatarb64')} />
            <NdsInput
                {...registerNdsInput('firstName', {
                    minLength: 1,
                    maxLength: 150,
                    required: true,
                    fieldLabel: t('first-name'),
                })}
                label={t('first-name')}
                inputType={NdsInputTypes.TEXT}
                required
            />
            <NdsInput
                {...registerNdsInput('lastName', {
                    minLength: 1,
                    maxLength: 150,
                    required: true,
                    fieldLabel: t('last-name'),
                })}
                label={t('last-name')}
                inputType={NdsInputTypes.TEXT}
                required
            />
            <NdsInput
                {...registerNdsInput('company', {
                    maxLength: 150,
                    fieldLabel: t('company'),
                })}
                label={t('company')}
                inputType={NdsInputTypes.TEXT}
            />
            <NdsSelect
                {...registerNdsSelect('language')}
                size={SizesEnums.MEDIUM}
                label={t('preferred-language')}
                content-align='bottom-left'
            >
                <NdsGroup>
                    {availableLanguages.map(lang =>
                        <NdsSelectOption
                            key={lang.isoCultureCode}
                            label={lang.nativeName}
                            value={lang.isoCultureCode}
                        />)}
                </NdsGroup>
            </NdsSelect>
            <NdsInput
                {...registerNdsInput('email')}
                state={StatesEnum.DISABLED}
                label={t('email')}
                inputType={NdsInputTypes.EMAIL}
                required
            />
            <Checkbox
                className={'register-cloud-form__consent'}
                label={t('register-consent', { consentUrl })}
                labelAsHtml
                {...registerCheckbox('consent', { required: 'required-terms-conditions' })}
            />
            <NdsButton
                className='register-cloud-form__button'
                onClick={() => formRef?.current?.requestSubmit()}
                label={t('btn-create-your-account')}
                state={isValid && !loading ? StatesEnum.DEFAULT : StatesEnum.DISABLED}
                display-block
            />
        </form>
    );
}

export default RegisterCloudForm;
