import { NdsIconFont } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { ComboBox, ListItemProps } from '@progress/kendo-react-dropdowns';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { PortalsApi } from '~api/portals.api';
import { UserManagementApi } from '~api/user-management.api';
import Typography, { TypographyToken } from '~components/typography';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { useVirtualizedCombobox } from '~hooks/virtualizedCombobox';
import { PageableListParams } from '~models/pageable-list.models';
import { DocumentGroupsListItemModel, EditedDocumentGroupPermissionsModel } from '~models/user-management.models';
import { initialDocumentGroupToggles } from './document-group-toggle-definition';
import { SelectedDocumentGroupsState } from '../user-group-panel';
import ToggleGroup from './toggle-group/toggle-group';
import './document-group-tab.scss';


export interface DocumentGroupTabProps {
    userGroupId: number | undefined;
    selectedDocumentGroups: DocumentGroupsListItemModel[];
    setSelectedDocumentGroups: Dispatch<SetStateAction<DocumentGroupsListItemModel[]>>;
}

const PAGE_SIZE = 8;

const DocumentGroupTab = ({ selectedDocumentGroups, setSelectedDocumentGroups, userGroupId }: DocumentGroupTabProps) => {

    const userManagementApi = useApi(UserManagementApi);
    const portalsApi = useApi(PortalsApi);
    const { t } = useTranslation('user-management');

    const [initialEditedDocPermissions, setInitialEditedDocPermissions] = useState<EditedDocumentGroupPermissionsModel[]>([]);

    const fetchData = useCallback(async (data: PageableListParams, signal?: AbortSignal) => {
        const userResponse = await portalsApi.getDocumentGroups({ ...data });

        return userResponse;

    }, [portalsApi]);


    const fetchEditedData = useCallback(async (data: PageableListParams, signal?: AbortSignal) => {
        const userResponse = await userManagementApi.getEditedDocumentGroupPermissions({
            ...data,
            userGroupId,
        });

        setInitialEditedDocPermissions((prev) => {
            const newDocs = userResponse.items;

            const uniqueDocs = [...prev, ...newDocs].reduce((acc: typeof prev, item) => {
                if (!acc.some(existingItem => existingItem.documentGroupId === item.documentGroupId)) {
                    acc.push(item);
                }

                return acc;
            }, []);

            return uniqueDocs;
        });

        return userResponse;

    }, [userGroupId, userManagementApi]);

    const RenderItem = (itemProps: ListItemProps) => {
        return (
            <div className={'document-group-tab__document-group-item'}>
                <Typography
                    className='document-group-tab__document-group-name'
                    token={TypographyToken.UiMenuSm}
                    text={`${itemProps.dataItem.name}`}
                />
                {selectedDocumentGroups.map((group) => group.documentGroupId === itemProps.dataItem.documentGroupId &&
                    <NdsIconFont
                        key={group.documentGroupId}
                        className='document-group-tab__combo-check-icon'
                        fontName='fa-solid-check'
                        size={SizesEnums.SMALL}
                    />)}
            </div>);
    };

    const handleSetSelectedDocumentGroups = useCallback((docGroup: SelectedDocumentGroupsState | null) => {

        if (docGroup) {
            if (userGroupId) {
                const group = initialEditedDocPermissions.find((docgrp) => docgrp.documentGroupId === docGroup.documentGroupId);

                if (group) {
                    const updatedPermissions = initialDocumentGroupToggles.map((toggle) => {
                        const matchingPermission = group.permissions.find(
                            (permissionObj) => permissionObj.permissionId === toggle.permissionId,
                        );

                        return {
                            ...toggle,
                            hasPermission: matchingPermission ? matchingPermission.hasPermission : toggle.hasPermission,
                        };
                    });

                    group.permissions = updatedPermissions;
                }
                docGroup.permissions = group?.permissions.map((permissions) => permissions);
                docGroup.initialPermissions = group?.permissions.map((permissions) => permissions);
            }

            const groupAlreadyExists = selectedDocumentGroups.some((group) => group.documentGroupId === docGroup.documentGroupId);
            let updatedGroupList;

            if (groupAlreadyExists) {
                updatedGroupList = selectedDocumentGroups.filter((group) => group.documentGroupId !== docGroup.documentGroupId);
            } else {
                updatedGroupList = [...selectedDocumentGroups, docGroup];
            }

            setSelectedDocumentGroups(updatedGroupList);
        }

    }, [initialEditedDocPermissions, selectedDocumentGroups, setSelectedDocumentGroups, userGroupId]);


    const comboboxProps = useVirtualizedCombobox<any>({
        pageSize: PAGE_SIZE,
        apiFilterKey: 'name',
        fetchData: userGroupId ? fetchEditedData : fetchData,
        valueKey: 'documentGroupId',
        labelKey: 'name',
        onChange: (e) => handleSetSelectedDocumentGroups(e),
        value: '',
        RenderItem,
    });

    return (
        <div className={'document-group-tab'}>
            <Typography token={TypographyToken.UiFormsLabelSm} text={t('document-group-tab-search-label')} />
            <ComboBox
                popupSettings={{ height: 184 }}
                className={'document-group-tab__'}
                {...comboboxProps}
                clearButton={false}
            />
            <div className={'document-group-tab__toggle-container'}>
                {selectedDocumentGroups.length > 0 && selectedDocumentGroups.map((docGroup) => (
                    <ToggleGroup
                        userGroupId={userGroupId}
                        documentGroup={docGroup}
                        key={docGroup.documentGroupId}
                        setSelectedDocumentGroups={setSelectedDocumentGroups}
                        selectedDocumentGroups={selectedDocumentGroups} />
                ))}
            </div>
        </div>
    );
};

export default DocumentGroupTab;
