import { PdfTargetFormat } from '~constants';
import { WorkflowActorModel, WorkflowStepModel } from '~models';
import { FlowApiAbstract } from './flow.api.abstract';
import {
    CreateFlowModel, CreateStakeholderModel,
    DeleteDocumentModel,
    GetBasicInfoModel,
    GetDetailsModel,
    GetDocumentActionsModel,
    GetDocumentPagesModel,
    GetElementsModel,
    GetFlowStatusModel, GetStakeholdersModel, GetWorkflowModel, SaveDetailsModel,
    SaveDocumentActionsModel, SaveWorkflowModel, UpdateDocumentModel,
    UploadCloudDocumentModel,
    UploadDocumentModel,
} from './template.models';


export class TemplateApi extends FlowApiAbstract {

    async getBasicInfo(data: GetBasicInfoModel.Params, signal?: AbortSignal): Promise<GetBasicInfoModel.Result> {
        try {
            const response = await this.http.get(`/portalapi/v1/templates/${data.flowId}/info`)
                .execute(signal);

            return response.jsonAs<GetBasicInfoModel.Result>(true);
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async getDetails(data: GetDetailsModel.Params, signal?: AbortSignal): Promise<GetDetailsModel.Result> {
        // errors handled specifically in usage place
        const response = await this.http.get(`/portalapi/v1/templates/${data.flowId}`)
            .execute(signal);

        return response.jsonAs<GetDetailsModel.Result>(true);
    }


    async create(data: CreateFlowModel.Params, signal?: AbortSignal): Promise<CreateFlowModel.Result> {
        try {
            const response = await this.http.post('/portalapi/v1/templates')
                .withJsonBody<CreateFlowModel.Body>({
                    TemplateName: data.name,
                    DocumentGroupId: data.workspaceId,
                })
                .execute(signal);

            const parsed = await response.jsonAs<CreateFlowModel.ParsedResponse>(true);

            return { flowId: parsed.templateId };
        } catch (e) {
            if (!signal?.aborted) {
                console.warn(e);
            }
            throw e;
        }
    }

    async deleteDocument(data: DeleteDocumentModel.Params, signal?: AbortSignal): Promise<DeleteDocumentModel.Result> {
        try {
            const response = await this.http.delete(`portalapi/v1/templates/${data.flowId}/documents/${data.documentId}`)
                .execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async uploadDocument(data: UploadDocumentModel.Params, signal?: AbortSignal): Promise<UploadDocumentModel.Result> {
        try {
            const formData = new FormData();

            formData.append('documentData', data.file);
            formData.append('documentLanguage', data.language);
            formData.append('documentName', data.documentName);
            formData.append('targetType', PdfTargetFormat[data.conversionTargetFormat]);
            formData.append('isOptional', data.isOptional.toString());

            const response = await this.http.post(`/portalapi/v1/templates/${data.flowId}/documents/${data.orderIndex}/async`)
                .withFormDataBody(formData)
                .resetHeader('content-type')
                .execute(signal);

            return response.ok;
        } catch (e) {
            if (!signal?.aborted) {
                console.warn(e);
            }
            throw e;
        }
    }

    async uploadCloudDocument(data: UploadCloudDocumentModel.Params, signal?: AbortSignal): Promise<UploadCloudDocumentModel.Result> {
        try {
            const response = await this.http.post(`/portalapi/v1/templates/${data.flowId}/documents/${data.orderIndex}/externalstorage/async`)
                .withJsonBody<UploadCloudDocumentModel.Body>({
                    DocumentName: data.documentName,
                    FileUrl: data.fileUrl,
                    Token: data.fileToken,
                    TargetType: PdfTargetFormat[data.conversionTargetFormat],
                    IsOptional: data.isOptional,
                    DocumentLanguage: data.language,
                })
                .execute(signal);

            return response.ok;
        } catch (e) {
            if (!signal?.aborted) {
                console.warn(e);
            }
            throw e;
        }
    }

    async updateDocument(data: UpdateDocumentModel.Params, signal?: AbortSignal): Promise<UpdateDocumentModel.Result> {
        try {
            const response = await this.http.put(`/portalapi/v1/templates/${data.flowId}/documents/${data.documentId}`)
                .withJsonBody<UpdateDocumentModel.Body>({
                    DocumentName: data.documentName,
                    IsOptional: data.isOptional,
                    OrderIndex: data.orderIndex,
                })
                .execute(signal);

            return response.ok;
        } catch (e) {
            if (!signal?.aborted) {
                console.warn(e);
            }
            throw e;
        }
    }

    async getFlowStatus(data: GetFlowStatusModel.Params, signal?: AbortSignal): Promise<GetFlowStatusModel.Result> {
        try {
            const response = await this.http.get(`/portalapi/v1/templates/${data.flowId}/status`)
                .execute(signal);

            const parsed = await response.jsonAs<GetFlowStatusModel.Response>(true);

            return {
                isActionNeeded: parsed.isActionNeeded,
                status: parsed.status.toUpperCase(),
            } as GetFlowStatusModel.Result;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async getDocumentActions(data: GetDocumentActionsModel.Params, signal?: AbortSignal): Promise<GetDocumentActionsModel.Result> {
        try {
            const response = await this.http.get(`portalapi/v1/templates/${data.flowId}/documents/actions`)
                .execute(signal);

            return response.jsonAs<GetDocumentActionsModel.Result>(true);
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async saveDocumentActions(data: SaveDocumentActionsModel.Params, signal?: AbortSignal): Promise<SaveDocumentActionsModel.Result> {
        try {
            const response = await this.http.post(`portalapi/v1/templates/${data.flowId}/documents/actions`)
                .withJsonBody<SaveDocumentActionsModel.Body>({ Documents: data.values })
                .execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async getStakeholders(data: GetStakeholdersModel.Params, signal?: AbortSignal): Promise<GetStakeholdersModel.Result> {
        try {
            const response = await this.http.get(`portalapi/v1/templates/${data.flowId}/stakeholders`)
                .execute(signal);

            return response.jsonAs<GetStakeholdersModel.Result>(true);
        } catch (e) {
            throw e;
        }
    }

    async getWorkflow(data: GetWorkflowModel.Params, signal?: AbortSignal): Promise<GetWorkflowModel.Result> {
        try {
            const response = await this.http.get(`portalapi/v1/templates/${data.flowId}/workflow`)
                .execute(signal);

            const result = await response.jsonAs<GetWorkflowModel.Result>(true);

            return {
                validationResult: result.validationResult,
                queryResult: result.queryResult.map((step: WorkflowStepModel) =>
                    step.map((el: WorkflowActorModel) => ({
                        ...el,
                        type: el.type.toUpperCase(),
                    } as unknown as WorkflowActorModel))),
            };
        } catch (e) {
            throw e;
        }
    }

    async getElements(data: GetElementsModel.Params, signal?: AbortSignal): Promise<GetElementsModel.Result> {
        try {
            const response = await this.http.get(`portalapi/v1/templates/${data.flowId}/elements`)
                .withParams({ type: data.type })
                .execute(signal);

            return response.jsonAs<GetElementsModel.Result>(true);
        } catch (e) {
            throw e;
        }
    }

    async getDocumentPages(data: GetDocumentPagesModel.Params, signal?: AbortSignal): Promise<GetDocumentPagesModel.Result> {
        try {
            const response = await this.http.get(`portalapi/v1/rendering/template/${data.documentId}`)
                .withParams({
                    startPage: data.startIndex + 1, // one-based indexes
                    amount: data.amount,
                })
                .execute(signal);

            return response.jsonAs<GetDocumentPagesModel.Result>(true);
        } catch (e) {
            if (!signal?.aborted) {
                console.warn(e);
            }
            throw e;
        }
    }

    async createStakeholder({
        flowId,
        info,
    }: CreateStakeholderModel.Params, signal?: AbortSignal): Promise<CreateStakeholderModel.Result> {
        try {
            const response = await this.http.post(`/portalapi/v1/templates/${flowId}/stakeholders`)
                .withJsonBody<CreateStakeholderModel.Body>(info)
                .execute(signal);

            return response.jsonAs<CreateStakeholderModel.Result>(true);
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async saveWorkflow(data: SaveWorkflowModel.Params, signal?: AbortSignal): Promise<SaveWorkflowModel.Result> {
        try {
            const response = await this.http.put(`portalapi/v2/templates/${data.flowId}/workflow`)
                .withJsonBody<SaveWorkflowModel.Body>(data.workflow)
                .execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    async saveDetails({
        flowId,
        data,
    }: SaveDetailsModel.Params, signal?: AbortSignal): Promise<SaveDetailsModel.Result> {
        try {
            const response = await this.http.put(`portalapi/v2/templates/${flowId}`)
                .withJsonBody<SaveDetailsModel.Body>(data)
                .execute(signal);

            return response.ok;
        } catch (e) {
            console.warn(e);
            throw e;
        }
    }

    /// $ADD_API_TEMPLATE_MARKER
}
