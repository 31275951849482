export enum CloudProvider {
    Dropbox = 1,
    Google = 2,
    Microsoft = 3,
}

export enum CloudContactProviderEnum {
    Google = 1,
    Microsoft = 2,
}
