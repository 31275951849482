import { NdsIconFont } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { useCallback, useState } from 'react';
import { PortalsApi } from '~api/portals.api';
import Content from '~components/content';
import FlowStatusBadge from '~components/flow-status-badge';
import PortalBulkActionsHeaderButton from '~components/portal-header-button';
import { FlowAction } from '~constants';
import { PortalTableContainer } from '~containers/portal-table';
import { useApi } from '~contexts/api';
import { useCurrentLanguage, useTranslation } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import { TableContextProvider } from '~contexts/table';
import { FetchTableDataDelegate } from '~contexts/table/table.types';
import { useRedirectFunc } from '~hooks/redirect';
import { DateUtil } from '~lib/date.utils';
import { Guid } from '~models';
import { SigningSessionTableDataModel } from '~models/portals.models';
import { useBulkActionsDefinition } from './bulk-action-definition';
import NoDocumentsSharedYet from './no-documents-shared-yet';
import SignerPortalBulkModal from './signer-portal-bulk-modal';
import { SignerPortalBulkActionData } from './signer-portal-bulk-modal/signerPortalBulkModal';
import SignerPortalDetailsPanel from './signer-portal-details-panel/signer-portal-details-panel';
import SignerPortalQuickActions from './signer-portal-quick-actions';
import { SignerTableDefinition } from './table-definition-signer';
import { useSignerTableFilters } from './use-signer-table-filters';

export interface SignerPortalViewProps {}

function SignerPortalView() {
    const { t: tBase } = useTranslation('base');
    const portalsApi = useApi(PortalsApi);
    const { languageCode } = useCurrentLanguage();
    const { customizationSettings } = useSettings();

    const filters = useSignerTableFilters();

    const fetchData: FetchTableDataDelegate<SigningSessionTableDataModel> = useCallback(
        async (params, signal?: AbortSignal) => {
            return portalsApi.getSigningSessionList(
                {
                    language: languageCode,
                    ...params,
                },
                signal,
            );
        },
        [languageCode, portalsApi],
    );

    const bulkActionsDef = useBulkActionsDefinition();
    const [bulkActionData, setBulkActionData] = useState<SignerPortalBulkActionData>();

    const redirectFunc = useRedirectFunc();
    const handleBulkSignClick = useCallback(
        async (actionIds: Guid[]) => {
            const response: {
                sessionId: string;
                token: string;
            } = await portalsApi.createBulkSignSession({ actionIds });

            redirectFunc(`public/bulksigninit?type=BulkSign&sessionId${response.sessionId}&token${response.token}`);
        },
        [portalsApi, redirectFunc],
    );

    const onBulkActionClick = useCallback(
        (action: FlowAction, ids: Guid[]) => {
            if (action === FlowAction.Sign) {
                handleBulkSignClick(ids);
            } else {
                setBulkActionData({
                    action,
                    ids,
                });
            }
        },
        [handleBulkSignClick],
    );

    return (
        <Content noDefaultPadding>
            <TableContextProvider<'actionId', SigningSessionTableDataModel>
                definition={SignerTableDefinition}
                fetchData={fetchData}
            >
                <SignerPortalBulkModal
                    bulkActionData={bulkActionData}
                    onModalClosed={() => setBulkActionData(undefined)}
                />
                <PortalTableContainer<'actionId', SigningSessionTableDataModel>
                    pageTitle={tBase('nav-link-shared-with-me')}
                    filtersDefinition={filters}
                    headerButton={
                        <PortalBulkActionsHeaderButton<'actionId', SigningSessionTableDataModel>
                            actionsDef={bulkActionsDef}
                            onClick={onBulkActionClick}
                        />
                    }
                    renderColumns={(data) => {
                        return {
                            createdOn: DateUtil.format(data.createdOn, 'dd/MM/yyyy'),
                            status: <FlowStatusBadge status={data.status} />,
                            reassignedAndSubstitutes: (
                                <>
                                    <Tooltip
                                        content={(props) => <TooltipContent title={props.title} />}
                                        anchorElement='target'
                                        position='bottom'
                                        parentTitle={true}
                                    >
                                        {customizationSettings.isReassignEnabled && data.isReassigned && (
                                            <div title={`reason:${data.reassignmentReason}`}>
                                                <NdsIconFont
                                                    fontName={'fa-solid-turn-down-left'}
                                                    size={SizesEnums.SMALL}
                                                />
                                            </div>
                                        )}
                                        {customizationSettings.isOutOfOfficeEnabled &&
                                            (data.hasActiveSubstitutes || data.originalPerson) && (
                                            <div title={`substitute:${data.substituteName ? data.substituteName  : data.originalPerson}`}>
                                                <NdsIconFont
                                                    fontName={'fa-solid-calendar-clock'}
                                                    size={SizesEnums.SMALL}
                                                />
                                            </div>
                                        )}
                                    </Tooltip>
                                </>
                            ),
                            action: <SignerPortalQuickActions data={data} />,
                        };
                    }}
                    renderNoItemsYet={<NoDocumentsSharedYet />}
                />
                <SignerPortalDetailsPanel />
            </TableContextProvider>
        </Content>
    );
}

const TooltipContent = ({ title }: any) => {
    const { t: tBase } = useTranslation('base');
    const tooltipInfo = Object.fromEntries(title.split(';').map((pair: any) => pair.split(':')));

    // Create the final object
    const data = {
        reason: tooltipInfo.reason,
        substitute: tooltipInfo.substitute,
    };

    return (
        <div>
            {data.reason ?
                <>
                    <div>
                        <strong>{tBase('reason')}: </strong>
                        {data.reason}
                    </div>
                </>
                :
                <div>
                    <strong>{tBase('substitute')}: </strong>
                    {data.substitute}
                </div>
            }

        </div>
    );
};

export default SignerPortalView;
