import { NdsBadge } from '@gonitro/rcl';
import { NdsBadgeStatesEnum, NdsBadgeTypesEnum } from '@gonitro/rcl/lib/_types';
import { useMemo } from 'react';
import FlowStatusBadge from '~components/flow-status-badge/flow-status-badge';
import { FlowStatusEnum } from '~constants/flow-status';
import { useTranslation } from '~contexts/i18n';
import Typography, { TypographyToken } from '../../typography';
import './package-details-header.scss';

export interface PackageDetailsHeaderProps {
    packageName: string;
    packageStatus: FlowStatusEnum;
    expiryDate?: string;
}

const PackageDetailsHeader = ({ packageName, packageStatus, expiryDate }: PackageDetailsHeaderProps) => {
    const { t } = useTranslation('flow');

    const formatDate = useMemo(() => {
        if (expiryDate) {

            const dateObj = new Date(expiryDate);
            const options: any = {
                month: 'short',
                day: 'numeric',
                year: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            };

            const formattedDate = dateObj.toLocaleString('en-US', options);

            return formattedDate;
        }
    }, [expiryDate]);

    return (
        <div className='c-package-details-header'>
            {packageStatus === FlowStatusEnum.Expired
                ? (
                    <NdsBadge
                        text={`${t('expired-on')} ${formatDate}`}
                        badgeType={NdsBadgeTypesEnum.STATUS}
                        state={NdsBadgeStatesEnum.WARNING}
                        icon={'fa-solid fa-warning'}
                    />
                )
                : expiryDate
                    ? (
                        <div className='c-package-details-header__with-expiry-date'>
                            <FlowStatusBadge status={packageStatus} icon={'fa-solid fa-circle'} />
                            <NdsBadge
                                text={`${t('expires-on')} ${formatDate}`}
                                badgeType={NdsBadgeTypesEnum.STATUS}
                                state={NdsBadgeStatesEnum.SECONDARY}
                                icon={'fa-solid fa-clock'}
                            />
                        </div>
                    )
                    : (
                        <FlowStatusBadge status={packageStatus} icon={'fa-solid fa-circle'} />
                    )}

            <Typography token={TypographyToken.DesktopHeaderXs} text={packageName} tagName={'h1'} />
        </div>
    );
};

export default PackageDetailsHeader;
