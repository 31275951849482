import { useContext, useEffect, useId, useRef } from 'react';
import { Guid } from '~models';
import { OverlayContext } from '../overlay.context';
import { OverlayElementComponent, OverlayElementOptions } from '../overlay.types';

export interface OverlayPortalProps {
    id?: Guid;
    readonly type: OverlayElementOptions['type'];
    visible?: boolean;
    onClose?: (id: Guid) => void;
    onOpen?: (id: Guid) => void;
    children: OverlayElementComponent;
}

export const OverlayPortal = ({ visible, children, id: idProp, onClose, onOpen, type }: OverlayPortalProps) => {
    const localId = useId();
    const { showElement, hideElement } = useContext(OverlayContext);
    const id = idProp || localId;
    const prevVisible = useRef<boolean>(false);

    useEffect(() => {
        if (visible && !prevVisible.current) {
            showElement(children, {
                id,
                type,
            });
            onOpen?.(id);
        }
        if (!visible && prevVisible.current) {
            hideElement(id);
            onClose?.(id);
        }
        prevVisible.current = visible ?? false;
    }, [
        children,
        hideElement,
        id,
        onClose,
        onOpen,
        showElement,
        type,
        visible,
    ]);

    return null;
};
