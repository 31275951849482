import { Checkbox, NdsButton } from '@gonitro/rcl';
import { NdsButtonStylesEnum, NdsButtonTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import { ListView, ListViewItemProps } from '@progress/kendo-react-listview';
import { ChangeEvent, Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import Typography, { TypographyToken } from '~components/typography';
import { Permission } from '~constants';
import { ImportColumnProperties, ImportContactFields } from '~constants/import-contact-properties';
import { useHasPermission } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import './importContactFormatTable.scss';

export interface ImportContactFormatTableProps {
    columns: ImportColumnProperties[];
    setColumns: Dispatch<SetStateAction<ImportColumnProperties[]>>;
}

function ImportContactFormatTable({ columns, setColumns }: ImportContactFormatTableProps) {
    const { t } = useTranslation('contacts');
    const [openAvailableColumns, setOpenAvailableColumns] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [availableColumns, setAvailableColumns] = useState<ImportColumnProperties[] | undefined>(
        Object.values(ImportContactFields),
    );
    const hasManageServerPermission = useHasPermission(Permission.AccessSettings_ActionManageServer);
    const hasManageTenantPermission = useHasPermission(Permission.AccessSettings_ActionManageTenant);
    const hasCreateSharedContactPermission = useHasPermission(Permission.General_ActionCreateSharedContact);

    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setOpenAvailableColumns(false);
        }
    };

    useEffect(() => {
        // Create a temporary array with the values from ImportContactFields
        let temp = Object.values(ImportContactFields).filter((el) => el.key !== 'emailAddress' && el.key !== 'lastName');

        // If the user does not have manage server permission, filter out the 'owner' field
        if (!hasManageServerPermission || !hasManageTenantPermission) {
            temp = temp.filter((el) => el.key !== 'owner');
        }
        // If the user does not have create shared contact permission, filter out the 'isShared' field
        if (!hasCreateSharedContactPermission) {
            temp = temp.filter((el) => el.key !== 'isShared');
        }
        // Update the available columns state with the filtered fields
        setAvailableColumns(temp);
    }, [hasCreateSharedContactPermission, hasManageServerPermission, hasManageTenantPermission]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Function to handle changes in checkbox state
    const handleCheckboxChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { name } = event.target;

            setColumns((currentColumns) => {
                const columnExists = currentColumns.find((col) => col.key === name);

                if (columnExists) {
                    // If it is, remove it from the selected columns
                    return currentColumns.filter((col) => col.key !== name);
                } else {
                    const newColumn = availableColumns?.find(
                        (col: ImportColumnProperties) => col.key === name,
                    );

                    if (newColumn) {
                        // If it isn't, add it to the selected columns
                        return [...currentColumns, newColumn];
                    }

                    // If newColumn is not found, return current state
                    return currentColumns;
                }
            });
        },
        [availableColumns, setColumns],
    );

    const listItem = (props: ListViewItemProps) => {
        const { key, keyTranslation } = props.dataItem;

        return (
            <div className={'c-import-contact-format-table__filter-list'}>
                <Checkbox
                    key={key}
                    name={key}
                    value={!!columns.find((col) => col.key === key)}
                    label={t(keyTranslation)}
                    onChange={handleCheckboxChange}
                />
            </div>
        );
    };

    return (
        <div className={'c-import-contact-format-table'}>
            <table className='c-import-contact-format-table__content'>
                <thead>
                    <tr>
                        <th
                            className={
                                'c-import-contact-format-table__add-column c-import-contact-format-table__table-header'
                            }
                        >
                            <NdsButton
                                slot='button'
                                buttonType={NdsButtonTypesEnum.NEUTRAL}
                                buttonStyle={NdsButtonStylesEnum.ALT}
                                size={SizesEnums.XSMALL}
                                rightIcon='fa-solid-plus'
                                onClick={() => setOpenAvailableColumns(true)}
                            />
                            {openAvailableColumns && (
                                <div
                                    className={'c-import-contact-format-table__available-columns-wrapper'}
                                    ref={dropdownRef}
                                >
                                    <ListView data={availableColumns} item={listItem} />
                                </div>
                            )}
                        </th>
                        {columns.map((column) => (
                            <th className={'c-import-contact-format-table__table-header'} key={column.key}>
                                <Typography
                                    className={'c-import-contact-format-table__header-content'}
                                    tagName='p'
                                    token={TypographyToken.DesktopTagXs}
                                    text={t(column.keyTranslation)}
                                />
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td
                            className={
                                'c-import-contact-format-table__add-column c-import-contact-format-table__table-data'
                            }
                        ></td>
                        {columns.map((column) => (
                            <td key={column.key} className={'c-import-contact-format-table__table-data'}>
                                <Typography
                                    className={'c-import-contact-format-table__columns'}
                                    tagName='p'
                                    token={TypographyToken.DesktopTagXs}
                                    text={column.example}
                                />
                            </td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default ImportContactFormatTable;
