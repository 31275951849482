import { DateTime } from 'luxon';

export namespace DateUtil {
    /**
     * Formats a date into the specified format. This should not be used in the long term, but a locale should
     * be provided instead.
     *
     * @param date The date to be formatted
     * @param formatStr
     */
    export function format(date: Date | string, formatStr: string = 'dd/MM/yyyy HH:mm') {
        let luxonDate: DateTime;

        if (date instanceof Date) {
            luxonDate = DateTime.fromJSDate(date);
        } else {
            luxonDate = DateTime.fromISO(date);
        }

        return luxonDate!.toFormat(formatStr);
    }


    export function formatToIso(date: string = 'dd/MM/yyyy') {
        const [day, month, year] = date.split('/');

        const dateTime = DateTime.fromObject({
            year: parseInt(year),
            month: parseInt(month),
            day: parseInt(day),
        });

        const utcDateTime = dateTime.toUTC();

        return utcDateTime.toISO()!;
    }
}
