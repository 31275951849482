import classNames from 'classnames';
import { PropsWithChildren, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import useClickOutside from '~hooks/useClickOutside';
import { DropdownContext } from './menu-dropdown.context';
import './menu-dropdown.scss';


export interface MenuDropdownProps extends PropsWithChildren {
    button?: (toggleDropdown: () => void) => ReactNode;
    contentAlign?: 'left' | 'right';
}

function MenuDropdown({ button, children, contentAlign = 'right' }: MenuDropdownProps) {

    const [isOpen, setIsOpen] = useState(false);
    const dropDownContainerRef = useRef<HTMLDivElement>(null);
    const buttonContainerRef = useRef<HTMLDivElement>(null);
    const [isInBottomHalf, setIsInBottomHalf] = useState(false);

    useClickOutside(dropDownContainerRef, setIsOpen);

    useEffect(() => {
        if (isOpen && buttonContainerRef.current) {
            const boundingRect = buttonContainerRef.current.getBoundingClientRect();
            const windowHeight = window.innerHeight;

            if (boundingRect.top > windowHeight / 1.5) {
                setIsInBottomHalf(true);
            } else {
                setIsInBottomHalf(false);
            }
        }
    }, [isOpen]);

    const toggleDropdown = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const closeDropDown = useCallback(() => {
        setIsOpen(false);
    }, []);

    return (
        <div ref={dropDownContainerRef} className={'c-menu-dropdown'}>
            <div className='c-menu-dropdown__menu-container'>
                <div ref={buttonContainerRef}>
                    {button && button(toggleDropdown)}
                </div>
                {(isOpen) && (
                    <DropdownContext.Provider
                        value={{
                            closeDropDown,
                            contentAlign,
                            hasDropdown: true,
                        }}
                    >
                        <div
                            className={
                                classNames(
                                    'c-menu-dropdown__list',
                                    { 'c-menu-dropdown__menu-up': isInBottomHalf },
                                    {
                                        'c-menu-dropdown__menu-right': contentAlign === 'right',
                                        'c-menu-dropdown__menu-left': contentAlign === 'left',
                                    },
                                )}
                        >
                            {children}
                        </div>
                    </DropdownContext.Provider>
                )}
            </div>
        </div>
    );

}

export default MenuDropdown;
