import { NdsDivider, NdsIconFont } from '@gonitro/rcl';
import { BkgModesEnum } from '@gonitro/rcl/lib/_types';
import { SizesEnums } from '@gonitro/rcl/lib/_types/designsystem.enums';
import classNames from 'classnames';
import { Fragment, HTMLAttributes } from 'react';
import { Permission } from '~constants';
import { BaseRoutes } from '~constants/routes';
import { useAppContext } from '~contexts/app';
import { Workspace } from '~contexts/app/app.types';
import { useHasPermission } from '~contexts/auth';
import { useTranslation } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import SidebarLink from './sidebar-link';
import './sidebar.scss';

export interface SidebarProps extends HTMLAttributes<HTMLElement> {
}

function Sidebar(props: SidebarProps) {
    const { expandedNavWorkspaces, toggleNavWorkspace, myWorkspace, sharedWorkspaces } = useAppContext();
    const canAccessSignerPortal = useHasPermission(Permission.AccessSignerPortal);
    const { t } = useTranslation('base');
    const settings = useSettings();

    return (
        <nav {...props} className={classNames('c-sidebar', props.className)}>
            <div className={'c-sidebar__group c-sidebar__group--main'}>
                <div className={'c-sidebar__group-head'}>
                    <span className={'c-sidebar__group-name'}>{t('nav-my-nitro')}</span>
                </div>
                <div className={'c-sidebar__group-links'}>
                    {/** Future feature - Dashboard page (path '/') exists for catching any unmatched path and redirect to docportal */}
                    {/*<SidebarLink*/}
                    {/*    icon={'fa-solid-grid'}*/}
                    {/*    to={'/'}*/}
                    {/*    title={t('nav-link-dashboard')}*/}
                    {/*/>*/}
                    {myWorkspace?.hasDocumentsPermission && (
                        <SidebarLink
                            to={myWorkspace.documentsPath}
                            title={t('nav-link-my-documents')}
                            icon={'fa-regular-file'}
                            end
                        />
                    )}
                    {canAccessSignerPortal && <SidebarLink
                        to={BaseRoutes.SignerPortal}
                        title={'Shared with me'}
                        icon={'fa-regular-paper-plane'}
                    />}
                    {myWorkspace?.hasTemplatesPermission && (
                        <SidebarLink
                            to={myWorkspace.templatesPath}
                            title={t('nav-link-my-templates')}
                            icon={'fa-regular-square-pen'}
                            end
                        />
                    )}
                </div>
            </div>
            <NdsDivider bgMode={BkgModesEnum.LIGHT} className={'c-sidebar__divider'} />
            {sharedWorkspaces.length > 0 && (
                <div className={'c-sidebar__groups'}>
                    {sharedWorkspaces.map(
                        (
                            {
                                workspaceName,
                                workspaceId,
                                documentsPath,
                                templatesPath,
                                hasDocumentsPermission,
                                hasTemplatesPermission,
                                linksQuantity,
                            }: Workspace,
                            index,
                        ) => {
                            return (
                                <Fragment key={workspaceId}>
                                    <div
                                        className={classNames(
                                            'c-sidebar__group c-sidebar__group--workspace',
                                            { 'c-sidebar__group--expanded': expandedNavWorkspaces.includes(workspaceId) },
                                        )}
                                    >
                                        <button
                                            type={'button'}
                                            className={'c-sidebar__group-head'}
                                            tabIndex={0}
                                            onClick={() => toggleNavWorkspace(workspaceId)}
                                        >
                                            <NdsIconFont
                                                className={'c-sidebar__group-expand'}
                                                size={SizesEnums.XSMALL}
                                                fontName={'fa-solid-caret-right'}
                                            />
                                            <span className={'c-sidebar__group-name'}>{workspaceName}</span>
                                        </button>
                                        <div
                                            className={classNames('c-sidebar__group-links', {
                                                'c-sidebar__group-links--single': linksQuantity === 1,
                                                'c-sidebar__group-links--double': linksQuantity === 2,
                                            })}
                                        >
                                            {hasDocumentsPermission &&
                                                <SidebarLink to={documentsPath} title={t('nav-link-documents')} />}
                                            {hasTemplatesPermission &&
                                                <SidebarLink to={templatesPath} title={t('nav-link-templates')} />}
                                        </div>
                                    </div>
                                    {index < sharedWorkspaces.length - 1 && (
                                        <NdsDivider bgMode={BkgModesEnum.LIGHT} className={'c-sidebar__divider'} />
                                    )}
                                </Fragment>
                            );
                        },
                    )}
                </div>
            )}
            <div className={'c-sidebar__footer'}>
                <a
                    className={'c-sidebar__footer_link'}
                    href={settings.environmentSettings.privacyPolicyUrl}
                >{t('privacy-policy')}</a>
                <span>&#183;</span>
                <a
                    className={'c-sidebar__footer_link'}
                    href={settings.environmentSettings.cookiePolicyUrl}
                >{t('cookie-policy')}</a>
            </div>
        </nav>
    );
}

export default Sidebar;
