import { TablePaginator } from '@gonitro/rcl';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { FieldType } from '~constants/field-type';
import { useFlowContext, useFlowState } from '~contexts/flow';
import { FlowEntityType } from '~contexts/flow/flow.types';
import { useTranslation } from '~contexts/i18n';
import { DefinedLocationModel, FlowFieldModel, Size } from '~models';
import DocumentViewer, {
    DocumentViewerHeaderComponent,
    DocumentViewerPageObjectModel,
    DocumentViewerPaginatorComponent,
    OnVisiblePageChangeParams,
} from '../../../document-viewer';
import Typography, { TypographyToken } from '../../../typography';
import FlowEditorPageObject from '../flow-editor-page-object';
import './flowEditorDocumentViewer.scss';

export interface FlowEditorDocumentViewerProps {

}

const FieldMinSize: Record<FieldType, Size> = {
    [FieldType.SigningField]: {
        width: 133,
        height: 82,
    },
    [FieldType.TextBox]: {
        width: 32,
        height: 16,
    },
    [FieldType.CheckBox]: {
        width: 10,
        height: 10,
    },
    [FieldType.RadioGroup]: {
        width: 10,
        height: 10,
    },
};
const FieldFixedRatio: Record<FieldType, number | undefined> = {
    [FieldType.SigningField]: 0.62,
    [FieldType.TextBox]: undefined,
    [FieldType.CheckBox]: 1,
    [FieldType.RadioGroup]: 1,
};

const Paginator: DocumentViewerPaginatorComponent = ({ currentPage, onPageChange, totalPages }) => {
    const { t } = useTranslation('base');

    return (
        <TablePaginator
            positionBottomFixed={false}
            totalPages={totalPages}
            currentPage={currentPage}
            prefixLabel={t('table-pagination-page')}
            ofLabel={t('table-pagination-of')}
            onPageChange={onPageChange}
        />
    );
};

const PageHeader: DocumentViewerHeaderComponent = ({ page }) => {
    const { t } = useTranslation('base');

    return (
        <Typography
            token={TypographyToken.MobileDescriptionSm}
            tagName={'div'}
            className={'c-flow-editor-document-viewer__page-header'}
        >
            <span
                className={'c-flow-editor-document-viewer__page-header-name'}
                title={page.documentName}
            >{page.documentName}</span>
            <span className={'c-flow-editor-document-viewer__page-header-pages'}>{t('page-x-of-y', {
                current: page.pageIndex + 1,
                total: page.documentTotalPages,
            })}</span>
        </Typography>
    );
};

function FlowEditorDocumentViewer(props: FlowEditorDocumentViewerProps) {
    const { detailDocuments, elements } = useFlowState();
    const {
        documentViewerRef,
        documentViewerConfig,
        setDocumentViewerConfig,
        api,
        visibleDocument,
        setVisibleDocument,
        setFocusedEntity,
    } = useFlowContext();
    const prevVal = useRef(visibleDocument);

    const documents = useMemo(() => {
        return detailDocuments.map(doc => ({
            name: doc.documentName,
            size: doc.documentSize,
            id: doc.documentId,
            pageSizes: doc.documentDimensions.pageSizes,
        }));
    }, [detailDocuments]);

    useEffect(() => {
        if (visibleDocument?.documentId === prevVal.current?.documentId) {
            return;
        }
        documentViewerRef.current?.scrollToPage({
            document: visibleDocument!.documentId,
            page: 0,
        });
    }, [documentViewerRef, prevVal, visibleDocument]);

    const onVisiblePageChange = useCallback(({ visiblePages, fullyVisiblePages }: OnVisiblePageChangeParams) => {
        const pageToLookup = fullyVisiblePages[0] ?? (visiblePages[visiblePages.length - 1] ?? null);

        if (pageToLookup === null) {
            return;
        }
        const doc = detailDocuments.find(el => el.documentId === pageToLookup.documentId)!;

        prevVal.current = doc;
        setVisibleDocument(doc);
    }, [detailDocuments, setVisibleDocument]);

    const onDocViewerClick = useCallback(() => {
        setFocusedEntity(FlowEntityType.Package);
    }, [setFocusedEntity]);

    const parsedElements = useMemo<DocumentViewerPageObjectModel<FlowFieldModel>[]>(() => (
        elements.reduce((arr: DocumentViewerPageObjectModel<FlowFieldModel>[], element: FlowFieldModel) => {
            if (element.type === FieldType.RadioGroup) {
                for (const option of element.options) {
                    if (!option.location) {
                        continue;
                    }
                    const location = option.location as DefinedLocationModel;

                    arr.push({
                        id: option.localId,
                        data: element,
                        Component: FlowEditorPageObject,
                        position: {
                            document: location.documentNumber - 1, // document viewer indexes are zero-based while flow data is one-based
                            page: location.pageNumber - 1, // document viewer indexes are zero-based while flow data is one-based
                            size: {
                                width: location.width,
                                height: location.height,
                            },
                            offset: {
                                top: location.top,
                                left: location.left,
                            },
                        },
                        canMove: !location.isLocked,
                        minSize: FieldMinSize[FieldType.RadioGroup],
                        keepRatio: FieldFixedRatio[FieldType.RadioGroup],
                    });
                }
            } else if (element.location) {
                const location = element.location as DefinedLocationModel;

                arr.push({
                    id: element.localId,
                    data: element,
                    Component: FlowEditorPageObject,
                    position: {
                        document: location.documentNumber - 1, // document viewer indexes are zero-based while flow data is one-based
                        page: location.pageNumber - 1, // document viewer indexes are zero-based while flow data is one-based
                        size: {
                            width: location.width,
                            height: location.height,
                        },
                        offset: {
                            top: location.top,
                            left: location.left,
                        },
                    },
                    canMove: !location.isLocked,
                    minSize: FieldMinSize[element.type],
                    keepRatio: FieldFixedRatio[element.type],
                });
            }

            return arr;
        }, [])
    ), [elements]);

    const onReady = useCallback(() => {
        if (!documentViewerRef.current || !documentViewerRef.current.container) {
            console.warn(`Document viewer reported it's ready but its ${documentViewerRef.current ? 'container' : 'ref'} is empty`);

            return;
        }
        if (documentViewerRef.current.container.clientWidth < documentViewerRef.current.container.scrollWidth) {
            setDocumentViewerConfig({ adjustViewerToScreen: true });
        }
    }, [documentViewerRef, setDocumentViewerConfig]);

    return (
        <div className={'c-flow-editor-document-viewer'}>
            <DocumentViewer
                documents={documents}
                onVisiblePageChange={onVisiblePageChange}
                ref={documentViewerRef}
                fetchDocumentPages={api.getDocumentPages}
                Paginator={Paginator}
                zoom={documentViewerConfig.zoomLevel}
                justifyPages={documentViewerConfig.justifyPages}
                adjustToScreen={documentViewerConfig.adjustViewerToScreen}
                preDocumentGutter={'1.75rem'}
                prePageGutter={'1.75rem'}
                PageHeaderComponent={PageHeader}
                DocumentHeaderComponent={PageHeader}
                pageObjects={parsedElements}
                onClick={onDocViewerClick}
                onReady={onReady}
            />
        </div>
    );
}

export default FlowEditorDocumentViewer;
