import { useMemo } from 'react';
import { DocumentGroupPermission, FlowAction, FlowStatusEnum } from '~constants';
import { useWorkspaceId } from '~contexts/app';
import { useHasDocumentGroupPermissionFunc } from '~contexts/auth';
import { useSettings } from '~contexts/settings';
import { PackagesTableDataModel } from '~models/portals.models';

export const useBulkActionsDefinition = () => {

    const { auditToolsSettings } = useSettings();

    const workspaceId = useWorkspaceId()!;
    const hasDocGroupPermission = useHasDocumentGroupPermissionFunc();


    return useMemo(() => {
        const bulkActionGroups = [];

        const group1 = [];


        group1.push(
            {
                type: FlowAction.SendReminder,
                iconName: 'fa-solid-bell',
                canRun: (data: PackagesTableDataModel) => [FlowStatusEnum.PendingSigning, FlowStatusEnum.PendingApproval, FlowStatusEnum.PendingFormFill].includes(data.status)
                        && hasDocGroupPermission(workspaceId, DocumentGroupPermission.Upload),
            },

            {
                type: FlowAction.Extend,
                iconName: 'fa-solid-calendar-circle-plus',
                canRun: (data: PackagesTableDataModel) => [FlowStatusEnum.Expired].includes(data.status)
                        && hasDocGroupPermission(workspaceId, DocumentGroupPermission.Upload),
            },
        );

        bulkActionGroups.push(group1);


        const group2 = [];

        if (auditToolsSettings.auditTrail.isEnabled) {
            group2.push({
                type: FlowAction.RequestAuditTrail,
                iconName: 'fa-solid-file-lines',
                canRun: (data: PackagesTableDataModel) => [
                    FlowStatusEnum.Finished,
                    FlowStatusEnum.Archived,
                    FlowStatusEnum.Rejected,
                    FlowStatusEnum.Revoked,
                    FlowStatusEnum.Failed,
                ].includes(data.status)
                        && hasDocGroupPermission(workspaceId, DocumentGroupPermission.Audit),
            });
        }
        group2.push({
            type: FlowAction.Download,
            iconName: 'fa-solid-download',
            canRun: (data: PackagesTableDataModel) => [FlowStatusEnum.Finished, FlowStatusEnum.Archived].includes(data.status)
                    && hasDocGroupPermission(workspaceId, DocumentGroupPermission.Download),
        });

        bulkActionGroups.push(group2);

        const group3 = [];

        group3.push({
            type: FlowAction.SendToSigners,
            iconName: 'fa-solid-paper-plane',
            canRun: (data: PackagesTableDataModel) =>
                [FlowStatusEnum.PendingFormFill, FlowStatusEnum.PendingApproval].includes(data.status)
                        && hasDocGroupPermission(workspaceId, DocumentGroupPermission.Upload)
                        && data.totalSigner > 0,
        },
        {
            type: FlowAction.Revoke,
            iconName: 'fa-solid-arrow-left-to-line',
            canRun: (data: PackagesTableDataModel) => [FlowStatusEnum.PendingSigning, FlowStatusEnum.PendingApproval, FlowStatusEnum.PendingFormFill].includes(data.status)
                        && hasDocGroupPermission(workspaceId, DocumentGroupPermission.Upload),

        },
        {
            type: FlowAction.End,
            iconName: 'fa-solid-stop-circle',
            canRun: (data: PackagesTableDataModel) =>
                ([FlowStatusEnum.PendingSigning].includes(data.status)
                            || ([FlowStatusEnum.PendingApproval, FlowStatusEnum.PendingFormFill].includes(data.status) && !data.totalSigner))
                        && hasDocGroupPermission(workspaceId, DocumentGroupPermission.Upload),

        },
        {
            type: FlowAction.Delete,
            iconName: 'fa-solid-trash',
            canRun: (data: PackagesTableDataModel) =>
                [
                    FlowStatusEnum.ProcessingFailed,
                    FlowStatusEnum.Draft,
                    FlowStatusEnum.Finished,
                    FlowStatusEnum.Archived,
                    FlowStatusEnum.Rejected,
                    FlowStatusEnum.Revoked,
                ].includes(data.status)
                        && hasDocGroupPermission(workspaceId, DocumentGroupPermission.Delete),
        });
        bulkActionGroups.push(group3);

        return bulkActionGroups.filter(el => el.length)!;
    }
    , [auditToolsSettings.auditTrail.isEnabled, hasDocGroupPermission, workspaceId]);
};
