import { cloneElement, ReactElement } from 'react';
import { DocumentViewerPageObjectResizeDirection } from './types';

export type DocumentViewerResizerButtonWrapperProps = {
    children: ReactElement;
    direction: DocumentViewerPageObjectResizeDirection
};
const DocumentViewerResizerButtonWrapper = ({
    direction,
    children,
}: DocumentViewerResizerButtonWrapperProps) => {
    return cloneElement(children, { 'data-direction': direction });
};

export default DocumentViewerResizerButtonWrapper;
