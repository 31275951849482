import classNames from 'classnames';
import { useCallback, useImperativeHandle } from 'react';
import { FieldType } from '~constants/field-type';
import { useFlowContext } from '~contexts/flow';
import { useGetStakeholderColorClass } from '~contexts/flow/flow.hooks';
import { FlowActionType, FlowEntityType } from '~contexts/flow/flow.types';
import { FlowFieldModel } from '~models';
import FlowEditorCheckboxFieldObject from './flow-editor-checkbox-field-object';
import FlowEditorRadioFieldObject from './flow-editor-radio-field-object';
import FlowEditorSigningFieldObject from './flow-editor-signing-field-object';
import FlowEditorTextFieldObject from './flow-editor-text-field-object';
import { FlowEditorFieldDeleteButton, FlowEditorFieldResizerButton } from './flowEditorFieldButtons';
import {
    DocumentViewerPageObjectComponent,
    DocumentViewerPageObjectComponentCallbacksRef,
    DocumentViewerPageObjectComponentProps,
    DocumentViewerPageObjectResizeDirection,
} from '../../../document-viewer';
import './flowEditorFieldObject.scss';
import './flowEditorPageObject.scss';

export interface FlowEditorPageObjectProps<T = unknown> extends DocumentViewerPageObjectComponentProps<T> {
}

const FieldTypeToFlowEntityType = {
    [FieldType.SigningField]: FlowEntityType.SigningField,
    [FieldType.TextBox]: FlowEntityType.TextField,
    [FieldType.CheckBox]: FlowEntityType.CheckboxField,
    [FieldType.RadioGroup]: FlowEntityType.RadioField,
};

const FieldTypeComponent = {
    [FieldType.SigningField]: FlowEditorSigningFieldObject,
    [FieldType.TextBox]: FlowEditorTextFieldObject,
    [FieldType.CheckBox]: FlowEditorCheckboxFieldObject,
    [FieldType.RadioGroup]: FlowEditorRadioFieldObject,
};


const FlowEditorPageObject: DocumentViewerPageObjectComponent<FlowFieldModel> = ({
    id,
    data,
    isLocked,
    activeResizing,
    callbacksRef,
    isDragged,
    isPressed,
}: FlowEditorPageObjectProps<FlowFieldModel>) => {
    const {
        setFocusedEntity,
        focusedEntityId,
        focusedEntityType,
        setFlowState,
    } = useFlowContext();

    const onClick = useCallback(() => {
        setFocusedEntity(FieldTypeToFlowEntityType[data.type], data.localId);
    }, [data.localId, data.type, setFocusedEntity]);

    const onDeleteClick = useCallback(() => {
        setFlowState(FlowActionType.DeleteElement, {
            localId: id,
            type: data.type,
        });

        setFocusedEntity(FlowEntityType.Package);
    }, [data.type, id, setFlowState, setFocusedEntity]);


    const onPositionChange = useCallback<DocumentViewerPageObjectComponentCallbacksRef['onPositionChange']>(({
        id,
        page,
        size,
        offset,
    }) => {
        setFlowState(FlowActionType.MovePageObject, {
            localId: data.localId,
            optionId: id,
            type: data.type,
            position: {
                documentNumber: page.documentIndex + 1, // document viewer indexes are zero-based while flow data is one-based (as be is, be doesn't know why it's one-based too)
                pageNumber: page.pageIndex + 1, // document viewer indexes are zero-based while flow data is one-based (as be is, be doesn't know why it's one-based too)
                height: size.height,
                width: size.width,
                left: offset.left,
                top: offset.top,
            },
        });
    }, [data.localId, data.type, setFlowState]);

    useImperativeHandle(callbacksRef, () => {
        return {
            onClick,
            onPositionChange,
        };
    }, [onClick, onPositionChange]);

    const Component = FieldTypeComponent[data.type];

    // active - user is doing some action on specific object
    // selected - user clicked that entity - but for radio group all options are selected so active elevates it more
    const isActive =
        activeResizing
        || isDragged
        || isPressed;
    const isSelected = (focusedEntityId === data.localId && focusedEntityType === FieldTypeToFlowEntityType[data.type]);

    const getColorClass = useGetStakeholderColorClass();

    return (
        <div
            className={classNames('c-flow-editor-page-object', getColorClass(data.stakeholderLocalId), {
                'c-flow-editor-page-object--active': isActive,
                'c-flow-editor-page-object--selected': isSelected,
                'c-flow-editor-page-object--is-resizing': activeResizing,
                'c-flow-editor-page-object--is-dragging': isDragged,
                'c-flow-editor-page-object--is-pressed': isPressed,
            })}
        >

            <Component data={data} id={id}>
                {!isLocked && (
                    <>
                        <FlowEditorFieldResizerButton direction={DocumentViewerPageObjectResizeDirection.BottomLeft} />
                        <FlowEditorFieldResizerButton direction={DocumentViewerPageObjectResizeDirection.TopRight} />
                        <FlowEditorFieldResizerButton direction={DocumentViewerPageObjectResizeDirection.BottomRight} />
                    </>
                )}
                <FlowEditorFieldDeleteButton onClick={onDeleteClick} />
            </Component>
        </div>
    );
};

export default FlowEditorPageObject;
