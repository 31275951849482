import { useContext } from 'react';
import { SigningType } from '~models';
import { SettingsContext } from './settings.context';

export const useSettingsContext = () => {
    return useContext(SettingsContext);
};

/** returns app settings, authorized settings model by default */
export const useSettings = () => {
    const { settings } = useContext(SettingsContext);

    return settings;
};

export const useSigningTypes = () => {
    const { signingTypes } = useContext(SettingsContext);

    return signingTypes;
};

export const useSigningType = (signingTypeName: string): SigningType => {
    const signingTypes = useSigningTypes();
    const found = signingTypes.find((el) => el.name === signingTypeName);

    // this should never happen in runtime. But if occurred, figure out proper fallback.
    if (!found) {
        throw new Error(`Signing type of name '${signingTypeName}' not found.`);
    }

    return found;
};

// some labels use company name which by theming probably need to be different. Let fetch it explicitly by this hook for easy look up
// TODO theming - verify company name
export const useCompanyName = () => {
    const { settings } = useContext(SettingsContext);

    return settings.customizationSettings.defaultCompanyName || 'Nitro';
};