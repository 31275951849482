import { Language, SettingsModel } from '~models';

export type CloudStorageSelectedFileData = Dropbox.SelectedFile | OneDrive.SelectedFile;

export interface CloudStorageSelectedFile {
    name: string;
    size: number;
    mimeType: string;
    url: string;
    token: string;
}

export abstract class CloudStorageScenario {
    constructor(
        protected allowedMimeTypes: string[] = [],
        protected allowMultipleFiles: boolean = false,
        protected cloudSettings: SettingsModel.CloudSettings,
        protected onUpload: (files: any) => void,
        protected onError?: (error: any) => void,
    ) {
        this.initialize();
    }


    abstract showFilePicker(language?: Language): void;

    protected initialize(): void {
        return;
    }

    protected notifyFileSelection(files: CloudStorageSelectedFile[]) {
        this.onUpload(files);
    }

    protected notifyFileSelectionFailure(error: any) {
        this.onError?.(error);
    }

}
