import { PropsWithChildren, useContext, useEffect, useId, useRef } from 'react';
import { Guid } from '~models';
import { OverlayContext } from '../overlay.context';
import { ShowModalReturn } from '../overlay.types';

export interface ModalPortalProps extends PropsWithChildren {
    id?: Guid;
    visible?: boolean;
    hideExisting?: boolean;
}

/** @deprecated */
export const ModalPortal = ({ visible, children, hideExisting, id: idProp }: ModalPortalProps) => {
    const localId = useId();
    const { showModal } = useContext(OverlayContext);
    const id = idProp || localId;
    const closeModal = useRef<ShowModalReturn>();

    useEffect(() => {
        if (visible) {
            closeModal.current = showModal(children, {
                id: id,
                hideExisting,
            });
        } else {
            closeModal.current?.();
        }
    }, [
        children,
        hideExisting,
        id,
        localId,
        showModal,
        visible,
    ]);

    return null;
};
