import { Checkbox, TableCell, TableRow } from '@gonitro/rcl';
import { CheckboxDetailedValue, CheckboxValue } from '@gonitro/rcl/lib/checkbox';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from '~contexts/i18n';
import { ActionNeededValueType } from '~models';
import './flowActionNeededModalTableHeader.scss';

export interface FlowActionNeededModalTableHeaderProps {
    values: ActionNeededValueType[];
    setValues: Dispatch<SetStateAction<ActionNeededValueType[]>>;
}

function FlowActionNeededModalTableHeader({ values, setValues }: FlowActionNeededModalTableHeaderProps) {
    const { t } = useTranslation('flow');

    const onChange = (name: keyof ActionNeededValueType, value: boolean) => {
        setValues((current) => (
            current.map((row) => {
                if (row[name] !== null) {
                    let newValue = value;

                    if (
                        (name === 'keepSignatures' && row['keepFormFields'] === true)
                        || (name === 'keepFormFields' && row['keepSignatures'] === true)
                    ) {
                        newValue = false;
                    }

                    return {
                        ...row,
                        [name]: newValue,
                    };
                }

                return row;
            })
        ));
    };

    // memo wouldn't help here as source of rerender is values which would be dep for memo
    const keepSigningFields: CheckboxValue =
        values.every(v => v.keepSigningFields === false || v.keepSigningFields === null)
            ? false
            : values.every(v => v.keepSigningFields === true || v.keepSigningFields === null)
                ? true
                : CheckboxDetailedValue.Indeterminate;

    const keepFormFields: CheckboxValue =
        values.every(v => v.keepFormFields === false || v.keepFormFields === null)
            ? false
            : values.every(v => v.keepFormFields === true || v.keepFormFields === null || v.keepSignatures === true)
                ? true
                : CheckboxDetailedValue.Indeterminate;
    const keepSignatures: CheckboxValue =
        values.every(v => v.keepSignatures === false || v.keepSignatures === null)
            ? false
            : values.every(v => v.keepSignatures === true || v.keepSignatures === null || v.keepFormFields)
                ? true
                : CheckboxDetailedValue.Indeterminate;


    return (
        <TableRow header>
            <TableCell header>
                {t('need-action-modal-documents')}
            </TableCell>
            <TableCell header>
                <Checkbox
                    name={'header-signingFields'}
                    disabled={values.every(v => v.keepSigningFields === null)}
                    value={keepSigningFields}
                    onChange={(_, value) => onChange('keepSigningFields', value)}
                />
                {t('need-action-modal-signing-fields')}
            </TableCell>
            <TableCell header>
                <Checkbox
                    name={'header-formFields'}
                    disabled={values.every(v => v.keepFormFields === null || v.keepSignatures === true)}
                    value={keepFormFields}
                    onChange={(_, value) => onChange('keepFormFields', value)}
                />
                {t('need-action-modal-form-fields')}
            </TableCell>
            <TableCell header>
                <Checkbox
                    name={'header-signatures'}
                    disabled={values.every(v => v.keepSignatures === null || v.keepFormFields === true)}
                    value={keepSignatures}
                    onChange={(_, value) => onChange('keepSignatures', value)}
                />
                {t('need-action-modal-signatures')}
            </TableCell>
        </TableRow>
    );
}

export default FlowActionNeededModalTableHeader;
