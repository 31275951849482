import { useMemo } from 'react';
import { Permission, UserManagementPortalTabsEnum } from '~constants';
import { useHasPermission } from '~contexts/auth';

const useFilterTabConditions = () => {
    const hasViewDocumentGroupPermission = useHasPermission(Permission.AccessUserManagementPortal_ActionViewDocumentGroup);
    const hasViewUserGroupPermission = useHasPermission(Permission.AccessUserManagementPortal_ActionViewUserGroup);
    const hasViewUserPermission = useHasPermission(Permission.AccessUserManagementPortal_ActionViewUser);

    return useMemo(() => ({
        [UserManagementPortalTabsEnum.DocumentGroups]: hasViewDocumentGroupPermission,
        [UserManagementPortalTabsEnum.UserGroups]: hasViewUserGroupPermission,
        [UserManagementPortalTabsEnum.Users]: hasViewUserPermission,
        // Add more conditions here
    }), [hasViewDocumentGroupPermission, hasViewUserGroupPermission, hasViewUserPermission]);
};

export default useFilterTabConditions;
