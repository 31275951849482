import { NdsIconFont } from '@gonitro/rcl';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { cloneElement, ReactElement } from 'react';
import { FlowStakeholderModel, StakeholderType } from '~models';
import './packageRecipientDropdown.scss';

export interface PackageRecipientDropdownProps {
    stakeholders: FlowStakeholderModel[];
    selectedStakeholderLocalId?: string;
    onRecipientChange: (stakeholder: FlowStakeholderModel) => void
}

function getStakeholderVisualisation(stakeholder: FlowStakeholderModel) {
    let visualisation = '-';

    if (stakeholder.type === StakeholderType.Person) {
        if (stakeholder.emailAddress != null) {
            visualisation = stakeholder.emailAddress;
        } else if (stakeholder.firstName != null || stakeholder.lastName != null) {
            visualisation = `${stakeholder.firstName ? stakeholder.firstName : ''} ${stakeholder.lastName}`;
        }
    } else if (stakeholder.type === StakeholderType.Group) {
        if (stakeholder.name != null) {
            visualisation = stakeholder.name;
        }
    }

    return visualisation;
}

function PackageRecipientDropdown(props: PackageRecipientDropdownProps) {
    const { stakeholders, selectedStakeholderLocalId, onRecipientChange } = props;

    const sortedStakeholders = stakeholders.sort((a, b) => {
        const compareA = getStakeholderVisualisation(a);
        const compareB = getStakeholderVisualisation(b);

        return compareA.localeCompare(compareB);
    });

    const recipientValueRender = (
        span: ReactElement<HTMLSpanElement>,
        value: any,
    ) => {
        const stakeholder = value;

        return cloneElement(span, span.props, (
            <span>
                {stakeholder?.type === StakeholderType.Person && (
                    <><NdsIconFont fontName={'fa-solid-user'} /> {getStakeholderVisualisation(stakeholder)}</>
                )}
                {stakeholder?.type === StakeholderType.Group && (
                    <><NdsIconFont fontName={'fa-regular-users'} /> {getStakeholderVisualisation(stakeholder)}</>
                )}
            </span>
        ));
    };

    const recipientItemRender = (
        span: ReactElement<HTMLSpanElement>,
        value: any,
    ) => {
        const stakeholder = value.dataItem;

        return cloneElement(span, span.props, (
            <span>
                {stakeholder?.type === StakeholderType.Person && (
                    <><NdsIconFont fontName={'fa-solid-user'} /> {getStakeholderVisualisation(stakeholder)}</>
                )}
                {stakeholder?.type === StakeholderType.Group && (
                    <><NdsIconFont fontName={'fa-regular-users'} /> {getStakeholderVisualisation(stakeholder)}</>
                )}
            </span>
        ));
    };

    return (
        <DropDownList
            data={sortedStakeholders}
            value={stakeholders.find((stakeholder) => stakeholder.localId === selectedStakeholderLocalId)}
            valueRender={recipientValueRender}
            itemRender={recipientItemRender}
            dataItemKey='localId'
            onChange={(e) => {
                onRecipientChange(e.value);
            }}
        />
    );
}

export default PackageRecipientDropdown;
