import { DropDownList } from '@progress/kendo-react-dropdowns';
import { FieldWrapper } from '@progress/kendo-react-form';
import { Switch, TextArea } from '@progress/kendo-react-inputs';
import { Label } from '@progress/kendo-react-labels';
import { useMemo } from 'react';
import Typography, { TypographyToken } from '~components/typography';
import { useFlowState } from '~contexts/flow';
import { useCurrentLanguage } from '~contexts/i18n';
import { LegalNoticeModel } from '~models';
import './legalNotice.scss';

export interface LegalNoticeProps {
    legalNoticeCode?: string | undefined;
    legalNoticeText?: string | undefined;
    onLegalNoticeChange: (legalNotice?: { legalNoticeCode?: string, legalNoticeText?: string }) => void;
}

function getLegalNoticeTranslation(legalNotice: LegalNoticeModel, currentLanguage: string): string {
    return legalNotice.translations[currentLanguage] ? legalNotice.translations[currentLanguage] : legalNotice.translations['en'] ? legalNotice.translations['en'] : '';
}

function LegalNotice({ legalNoticeCode, legalNoticeText, onLegalNoticeChange }: LegalNoticeProps) {
    const { legalNotices } = useFlowState();
    const language = useCurrentLanguage();
    const currentLanguageLegalNotices = useMemo(() => {
        const notices = legalNotices.map((legalNotice) => {
            return {
                legalNoticeCode: legalNotice.legalNoticeCode,
                translation: getLegalNoticeTranslation(legalNotice, language.languageCode),
            };
        });

        if (legalNoticeText != null) {
            const found = notices.find((l) => l.translation === legalNoticeText);

            if (!found) {
                notices.push({
                    legalNoticeCode: legalNoticeText,
                    translation: legalNoticeText,
                });
            }
        }

        return notices;
    }, [legalNotices, language, legalNoticeText]);

    const selectedLegalNotice = useMemo(() => {
        return currentLanguageLegalNotices.find((currentLanguageLegalNotice) => {
            return currentLanguageLegalNotice.legalNoticeCode === legalNoticeCode || currentLanguageLegalNotice.legalNoticeCode === legalNoticeText;
        });
    }, [currentLanguageLegalNotices, legalNoticeCode, legalNoticeText]);

    return (
        <div className={'c-legal-notice'}>
            <div className={'c-legal-notice-switch-container'}>
                <Switch
                    checked={legalNoticeCode != null || legalNoticeText != null}
                    size={'small'}
                    onChange={() => {
                        if (legalNoticeCode == null && legalNoticeText == null) {
                            onLegalNoticeChange({ // default to first item
                                legalNoticeText: undefined,
                                legalNoticeCode: currentLanguageLegalNotices[0].legalNoticeCode,
                            });
                        } else {
                            onLegalNoticeChange(); // reset
                        }
                    }}
                />
                <Typography
                    token={TypographyToken.MobileDescriptionMd}
                    text={'Add a legal notice'}
                    className={'c-legal-notice-switch-container__label'}
                />
            </div>
            {
                (legalNoticeCode != null || legalNoticeText != null) &&
                <div>
                    <div className={'c-legal-notice-field-container'}>
                        <FieldWrapper>
                            <Label>
                                {'Choose a legal notice'}
                            </Label>
                            <DropDownList
                                data={currentLanguageLegalNotices}
                                dataItemKey='legalNoticeCode'
                                textField='translation'
                                value={selectedLegalNotice}
                                onChange={(e) => {
                                    onLegalNoticeChange({
                                        legalNoticeText: undefined,
                                        legalNoticeCode: e.value.legalNoticeCode,
                                    });
                                }}
                            />
                        </FieldWrapper>
                    </div>
                    <div className={'c-legal-notice-field-container'}>
                        <FieldWrapper>
                            <Label>
                                {'Edit the legal notice'}
                            </Label>
                            <TextArea
                                rows={4}
                                size='medium'
                                value={legalNoticeText != null ? legalNoticeText : selectedLegalNotice?.translation}
                                onChange={(e) => {
                                    onLegalNoticeChange({ legalNoticeText: e.value });
                                }}
                            ></TextArea>
                        </FieldWrapper>
                    </div>
                </div>
            }
        </div>
    );
}

export default LegalNotice;
