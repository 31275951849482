import { NdsIconFont } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import Typography, { TypographyToken } from '~components/typography';
import { UserGroupUsersListModel } from '~models/user-management.models';
import './selected-user-item.scss';

export interface SelectedUserItemProps {
    user: UserGroupUsersListModel;
    onClick: (user: UserGroupUsersListModel) => void;
}

const SelectedUserItem = ({ user, onClick }: SelectedUserItemProps) => {

    return (
        <div className='selected-user-item'>
            <Typography
                className='selected-user-item__list-item-name'
                token={TypographyToken.UiFormsLabelSm}
                text={`${user.firstName} ${user.lastName}`}
            ></Typography>
            <div className='selected-user-item__email-column'>
                <Typography
                    className='selected-user-item__list-item-email'
                    token={TypographyToken.UiFormsLabelSm}
                    text={`${user.emailAddress}`}
                ></Typography>
            </div>
            <NdsIconFont
                onClick={() => onClick(user)}
                className='selected-user-item__delete-icon'
                fontName='fa-solid-minus-circle'
                size={SizesEnums.SMALL}
            />
        </div>
    );
};

export default SelectedUserItem;
