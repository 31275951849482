import { NdsButton, NdsDivider } from '@gonitro/rcl';
import { BkgModesEnum, NdsButtonTypesEnum } from '@gonitro/rcl/lib/_types';
import { SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types/designsystem.enums';
import { NavLink } from 'react-router-dom';
import MenuDropdown from '~components/menu-dropdown/menu-dropdown';
import MenuGroup from '~components/menu-dropdown/menu-group/menu-group';
import MenuItem from '~components/menu-dropdown/menu-item/menu-item';
import { Permission } from '~constants';
import { BaseRoutes } from '~constants/routes';
import { useHasPermission, useLogout, useUserInfo } from '~contexts/auth';
import { useLanguages, useTranslation } from '~contexts/i18n';
import { LanguageProfile } from '~models';
import Typography, { TypographyToken } from '../typography';
import './headerMenu.scss';

export interface HeaderMenuProps {

}

function HeaderMenu(props: HeaderMenuProps) {
    const { t } = useTranslation('base');
    const hasUserManagementPermission = useHasPermission(Permission.AccessUserManagementPortal);
    const {
        availableLanguages,
        language,
        setLanguage,
    } = useLanguages();
    const userInfo = useUserInfo();
    const logout = useLogout();
    const avatar = userInfo?.firstName?.[0] ?? userInfo?.lastName[0];
    const name = `${userInfo?.firstName ?? ''} ${userInfo?.lastName}`.trim();

    return (
        <MenuDropdown
            button={(toggleDropdown: () => void) => (
                <NdsButton
                    slot={'button'}
                    leftIcon={'fa-solid-user'}
                    rightIcon={'fa-solid-chevron-down'}
                    label={userInfo?.firstName ?? userInfo?.lastName}
                    bgMode={BkgModesEnum.DARK}
                    buttonType={NdsButtonTypesEnum.NEUTRAL}
                    size={SizesEnums.XSMALL}
                    onClick={toggleDropdown}
                />
            )}
        >
            <div className={'c-header-menu__header'} slot={'header'}>
                <div className={'c-header-menu__avatar'}>
                    <Typography token={TypographyToken.UiButtonsMd}>{avatar}</Typography>
                </div>
                <div className={'c-header-menu__user'}>
                    <div className={'c-header-menu__user-name'}>
                        <Typography token={TypographyToken.UiMenuMd}>{name}</Typography>
                    </div>
                    <div className={'c-header-menu__user-email'}>
                        <Typography token={TypographyToken.UiMenuSm}>{userInfo?.emailAddress}</Typography>
                    </div>
                </div>
            </div>
            <NdsDivider slot={'menu'} bgMode={BkgModesEnum.LIGHT} />
            <MenuGroup>
                <NavLink to={BaseRoutes.Profile} slot={'items'} className={'c-header-menu__link'}>
                    {({ isActive }) => (
                        <MenuItem
                            icon={'fa-solid-cog'}
                            state={isActive ? StatesEnum.SELECTED : StatesEnum.DEFAULT}
                            primaryText={t('header-menu-account-settings')}
                            size={SizesEnums.MEDIUM}
                        />
                    )}
                </NavLink>
                <NavLink slot={'items'} to={BaseRoutes.Contacts} className={'c-header-menu__link'}>
                    {({ isActive }) => (
                        <MenuItem
                            icon={'fa-solid-address-book'}
                            state={isActive ? StatesEnum.SELECTED : StatesEnum.DEFAULT}
                            primaryText={t('header-menu-contacts')}
                            size={SizesEnums.MEDIUM}
                        />
                    )}
                </NavLink>
                {hasUserManagementPermission && (
                    <NavLink
                        slot={'items'}
                        to={BaseRoutes.Settings}
                        className={'c-header-menu__link'}
                    >
                        {({ isActive }) => (
                            <MenuItem
                                icon={'fa-solid-users'}
                                state={isActive ? StatesEnum.SELECTED : StatesEnum.DEFAULT}
                                primaryText={t('header-menu-user-management')}
                                size={SizesEnums.MEDIUM}
                            />
                        )}
                    </NavLink>
                )}
                <MenuItem
                    icon={'fa-solid-globe'}
                    primaryText={t('language')}
                    size={SizesEnums.MEDIUM}
                    subMenu
                >
                    {availableLanguages.map((lang: LanguageProfile) => (
                        <MenuItem
                            subMenu
                            key={lang.isoCultureCode}
                            primaryText={lang.nativeName}
                            state={lang.isoCultureCode === language?.isoCultureCode ? StatesEnum.SELECTED : StatesEnum.DEFAULT}
                            onClick={() => setLanguage(lang)}
                        />
                    ))}
                </MenuItem>
            </MenuGroup>
            <NdsDivider slot={'menu'} bgMode={BkgModesEnum.LIGHT} />
            <MenuGroup>
                <MenuItem
                    icon={'fa-solid-door-closed'}
                    primaryText={t('header-menu-log-out')}
                    size={SizesEnums.MEDIUM}
                    onClick={() => logout()}
                />
            </MenuGroup>
        </MenuDropdown>
    );
}

export default HeaderMenu;
