import { useMemo } from 'react';
import { CloudProvider } from '~constants/cloud-provider';
import { useTranslation } from '~contexts/i18n';
import { useCloudStorageProviderState } from '~hooks/cloud-provider';
import dropboxLogo from '~resources/cloud/Dropbox.svg';
import googleDriveLogo from '~resources/cloud/GoogleDrive.svg';
import oneDriveLogo from '~resources/cloud/OneDrive.svg';

export const useFlowDocumentUploadButtonItems = () => {
    const states = useCloudStorageProviderState();
    const { t } = useTranslation('base');

    return useMemo(() => {
        const list = [];

        if (states[CloudProvider.Dropbox]) {
            list.push({
                text: t('dropbox'),
                imageUrl: dropboxLogo,
                provider: CloudProvider.Dropbox,
            });
        }
        if (states[CloudProvider.Microsoft]) {
            list.push({
                text: t('onedrive'),
                imageUrl: oneDriveLogo,
                provider: CloudProvider.Microsoft,
            });
        }
        if (states[CloudProvider.Google]) {
            list.push({
                text: t('google-drive'),
                imageUrl: googleDriveLogo,
                provider: CloudProvider.Google,
            });
        }

        if (list.length) {
            list.unshift({
                text: t('desktop'),
                iconClass: 'fa-solid fa-laptop',
            });
        }

        return list;
    }, [states, t]);
};
