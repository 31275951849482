import { useMemo } from 'react';
import { AppLocalStorage } from '~lib';
import { FlowStakeholderModel, Guid, StakeholderType } from '~models';

const COLORS_QUANTITY = 10;
const STORED_MAPS_NUMBER = 5;
type StakeholderColorStoredModel = { lastUsed: number, colors: Record<Guid, number> };
export const useFlowInternalStakeholderColors = (flowId: Guid | undefined, stakeholders: FlowStakeholderModel[]) => {

    return useMemo(() => {
        if (!flowId || !stakeholders.length) {
            return {};
        }
        // localIds are temporary, ids are permanent so use them first
        const ids = stakeholders.filter((s) => s.type !== StakeholderType.Undecided).map(s => s.id ?? s.localId);

        const stored = AppLocalStorage.get<Record<Guid, StakeholderColorStoredModel>>('stakeholderColors') ?? {};
        const current = stored[flowId]
            ? {
                ...stored[flowId],
                lastUsed: Date.now(),
            }
            : stored[flowId] = {
                lastUsed: Date.now(),
                colors: {},
            };

        // storing up to 10 maps so remove 10th one to make place for current flow
        if (Object.keys(stored).length >= STORED_MAPS_NUMBER) {
            const entries = Object.entries(stored).filter(el => el[0] !== flowId).sort((a, b) => a[1].lastUsed < b[1].lastUsed ? 1 : -1);

            for (let i = STORED_MAPS_NUMBER; i < entries.length; i++) {
                delete stored[entries[i][0]];
            }
            stored[flowId] = current;
        }

        // remove colors from non-existing stakeholders
        for (const id of Object.keys(current.colors)) {
            if (!ids.includes(id)) {
                delete current.colors[id];
            }
        }
        let usedColors: number[] = [];

        // leaving for reference, it's better to keep duplicated color instead changing to not confuse user

        // // remove duplicates from existing. It won't work well for more stakeholders than COLORS_QUANTITY.
        // // It should check if duplicated colors aren't necessary and should stay
        // for (const [id, color] of Object.entries(current.colors).slice(usedColors.length)) {
        //     if (!usedColors.includes(color)) {
        //         usedColors.push(color);
        //     } else {
        //         for (let i = 0; i < COLORS_QUANTITY; i++) {
        //             if (!usedColors.includes(i)) {
        //                 usedColors.push(i);
        //                 current.colors[id] = i;
        //                 break;
        //             }
        //         }
        //     }
        //     if (usedColors.length === COLORS_QUANTITY) {
        //         usedColors = [];
        //     }
        // }
        for (const id of ids) {
            // stakeholder has color assigned
            if (current.colors[id] !== undefined) {
                usedColors.push(current.colors[id]);
            } else { // no color so find unused one
                for (let i = 0; i < COLORS_QUANTITY; i++) {
                    if (!usedColors.includes(i)) {
                        usedColors.push(i);
                        current.colors[id] = i;
                        break;
                    }
                }
            }
            // reach colors quantity so reuse colors
            if (usedColors.length === COLORS_QUANTITY) {
                usedColors = [];
            }
        }

        AppLocalStorage.set('stakeholderColors', stored);
        const ret: Record<Guid, number> = {};
        for (const [id, color] of Object.entries(current.colors)) {
            ret[stakeholders.find(s => s.id === id)?.localId ?? id] = color;
        }

        return ret;
    }, [flowId, stakeholders]);
};
