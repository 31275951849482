import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import Footer, { FooterProps } from '~components/footer';
import Header, { HeaderProps } from '~components/header';
import Layout from '~components/layout';
import Sidebar, { SidebarProps } from '~components/sidebar';
import { PropsWithClassNames } from '~models';
import { OverlayContainer } from '../overlay';
import './layout.container.scss';

export interface LayoutContainerProps extends PropsWithClassNames, PropsWithChildren {
    headerProps?: HeaderProps;
    sidebarProps?: SidebarProps;
    footerProps?: FooterProps;
    hideHeader?: boolean;
    hideSidebar?: boolean;
    hideFooter?: boolean;
}

// This container provides application layout, so it's UI focused one. While some elements are common and lives in its
// own place all the time some are driven by props.
export const LayoutContainer = ({
    headerProps = {},
    sidebarProps = {},
    footerProps = {},
    className,
    hideHeader,
    hideSidebar,
    hideFooter,
    children,
}: LayoutContainerProps) => {
    // order is important so no z-indexes are needed and:
    // content is behind everything
    // footer is small and over content - no expandable elements would be in it
    // sidebar can go over content in case of some tooltips or sth like that
    // header - especially on mobile can be expanded to cover whole page, so it's on top of typical page content
    // overlay is on top of everything
    return (
        <Layout
            className={classNames('ct-layout', {
                'ct-layout--hide-header': hideHeader,
                'ct-layout--hide-sidebar': hideSidebar,
            }, className)}
        >
            <Layout.Content>
                {children}
            </Layout.Content>
            <Layout.Footer>
                <Footer {...footerProps} hidden={hideFooter} />
            </Layout.Footer>
            <Layout.Sidebar>
                <Sidebar {...sidebarProps} hidden={hideSidebar} />
            </Layout.Sidebar>
            <Layout.Header>
                <Header {...headerProps} hidden={hideHeader} />
            </Layout.Header>
            <Layout.Overlay>
                <OverlayContainer />
            </Layout.Overlay>
        </Layout>
    );
};
