import { NdsInput } from '@gonitro/rcl';
import { NdsInputTypes, SizesEnums } from '@gonitro/rcl/lib/_types';
import { DateTime } from 'luxon';
import { RefObject, useCallback, useEffect, useState } from 'react';
import Error from '~components/error';
import Typography, { TypographyToken } from '~components/typography';
import { useTranslation } from '~contexts/i18n';
import { useEnhancedForm } from '~hooks/enhanced-form';
import { ValidateUtils } from '~lib';
import { DateUtil } from '~lib/date.utils';
import { LanguageDataLabel } from '~models';
import './quickActionExtend.scss';

export interface QuickActionExtendProps {
    onSubmit: (expirationTimestamp: string) => void;
    formRef: RefObject<HTMLFormElement>;
}

export type ExtendFormValues = {
    date: string;
    hour: string;
    minutes: string;
};

function QuickActionExtend({ onSubmit, formRef }: QuickActionExtendProps) {
    const now = new Date();

    now.setHours(now.getHours() + 1);
    const { t: tBase } = useTranslation('base');
    const { t: tFlow } = useTranslation('flow');
    const {
        registerNdsInput,
        handleSubmit,
        formState: { isValid },
    } = useEnhancedForm<ExtendFormValues>({
        mode: 'all',
        defaultValues: {
            date: DateUtil.format(now, 'dd/MM/yyyy'),
            hour: now.getHours().toString().padStart(2, '0'),
            minutes: now.getMinutes().toString().padStart(2, '0'),
        },
    });
    const [error, setError] = useState<LanguageDataLabel<'error'>>();

    useEffect(() => {
        if (!isValid) {
            setError('extend-error-message-validation');
        } else {
            setError(undefined);
        }
    }, [isValid]);

    const onValidateAndSubmitCallback = useCallback(
        (data: ExtendFormValues) => {
            const { date, hour, minutes } = data;

            const expirationTime = DateTime.fromFormat(`${date} ${hour} ${minutes}`, 'dd/MM/yyyy HH mm').toUTC();

            if (expirationTime.toMillis() > Date.now() && isValid) {
                setError(undefined);

                onSubmit(expirationTime.toISO()!);
            } else {
                setError('extend-error-message-date');
            }
        },
        [isValid, onSubmit],
    );

    return (
        <div className={'c-extend-quick-action'}>
            {error && <Error className={'c-extend-quick-action__error-text'} i18nKey={error} />}
            <Typography text={tFlow('extend-message')} token={TypographyToken.DesktopDescriptionSm} />
            <form
                ref={formRef}
                onSubmit={handleSubmit(onValidateAndSubmitCallback)}
                className={'c-extend-quick-action__extended-param'}
                autoComplete='off'
            >
                <div>
                    <NdsInput
                        {...registerNdsInput('date', {
                            required: true,
                            pattern: {
                                value: ValidateUtils.dateRegex,
                                message: 'invalid-date',
                            },
                        })}
                        label={tBase('date')}
                        leftIcon='fa-regular-calendar'
                        placeholder={tBase('date-placeholder')}
                        size={SizesEnums.SMALL}
                        inputType={NdsInputTypes.TEXT}
                        required
                    />
                </div>
                <div className={'c-extend-quick-action__hours-minute-container'}>
                    <div className={'c-extend-quick-action__hour-minute-select'}>
                        <NdsInput
                            {...registerNdsInput('hour', {
                                required: true,
                                pattern: {
                                    value: ValidateUtils.hourRegex,
                                    message: 'invalid-hour',
                                },
                            })}
                            label={tBase('hour')}
                            leftIcon='fa-regular-calendar'
                            placeholder={tBase('hour-placeholder')}
                            size={SizesEnums.SMALL}
                            inputType={NdsInputTypes.TEXT}
                            required
                        />
                    </div>
                    <div className={'c-extend-quick-action__hour-minute-select '}>
                        <NdsInput
                            {...registerNdsInput('minutes', {
                                required: true,
                                pattern: {
                                    value: ValidateUtils.minuteRegex,
                                    message: 'invalid-minutes',
                                },
                            })}
                            label={tBase('minutes')}
                            leftIcon='fa-regular-calendar'
                            placeholder={tBase('minutes-placeholder')}
                            size={SizesEnums.SMALL}
                            inputType={NdsInputTypes.TEXT}
                            required
                        />
                    </div>
                </div>
            </form>
        </div>
    );
}

export default QuickActionExtend;
