/* eslint-disable no-console */ //TODO delete this line after the deleting of console.log
import { NdsButton } from '@gonitro/rcl';
import { SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types/designsystem.enums';
import NdsInput from '@gonitro/rcl/lib/nds-input';
import { SvgIcon } from '@progress/kendo-react-common';
import { ComboBox } from '@progress/kendo-react-dropdowns';
import {
    InputClearValue,
    InputSeparator,
    TextBox,
} from '@progress/kendo-react-inputs';
import { cancelIcon } from '@progress/kendo-svg-icons';
import { useCallback, useState } from 'react';
import AuthExample from '~components/example/auth';
import Typography, { TypographyToken } from '~components/typography';
import { Translation, useCurrentLanguage, useSetLanguage } from '~contexts/i18n';
import { useTranslation } from '~contexts/i18n/i18n.hooks/translation.i18n.hook';
import { useSettings } from '~contexts/settings';
import ExampleForm from './Example.forms';
import './example.scss';

export interface ExampleProps {
    label: string;
}

function Example({ label }: ExampleProps) {
    const { t } = useTranslation('base');
    const settings = useSettings();
    const setLanguage = useSetLanguage();
    const { languageCode } = useCurrentLanguage();

    const [count, setCount] = useState(0);
    const sports = [
        'Baseball',
        'Basketball',
        'Cricket',
        'Field Hockey',
        'Football',
        'Table Tennis',
        'Tennis',
        'Volleyball',
    ];

    const [value, setValue] = useState('');
    const handleChange = useCallback((event: any) => {
        setValue(event.target.value);
    }, []);
    const handleClear = useCallback(() => {
        setValue('');
    }, []);

    return (
        <>
            <div
                className={'example'}
                style={{
                    marginBottom: '1rem',
                    lineHeight: '1.5',
                    padding: '1rem',
                }}
            >
                <div>{label}</div>
                <div>I18n hook: {t('title', {
                    count,
                    count2: languageCode,
                })}</div>
                <div>I18n component:<Translation
                    namespace={'base'}
                    label={'title'}
                    values={{
                        count,
                        count2: languageCode,
                    }}
                /></div>
                <div style={{ display: 'flex' }}>
                    <NdsButton
                        size={SizesEnums.XSMALL}
                        label={'Change lang'}
                        onClick={() => setLanguage(languageCode === 'en' ? 'de' : 'en')}
                    />
                    <NdsButton
                        size={SizesEnums.XSMALL}
                        label={'+ or -'}
                        onClick={() => setCount(current => current + (languageCode === 'en' ? 1 : -1))}
                    />
                </div>
            </div>
            <div className={'example'}>
                <ExampleForm />
                <div>World of {settings.customizationSettings.defaultCompanyName || 'cats'}</div>
                <NdsButton label={'Rcl buttons'} onClick={() => alert('clicked')} />
                <NdsInput state={StatesEnum.DEFAULT} onNdsChange={(ev) => console.log(ev.detail.value)} />
                <Typography
                    token={TypographyToken.DesktopDisplaySm}
                    mediumScreenToken={TypographyToken.DesktopDisplayLg}
                >Typography</Typography>
            </div>
            <AuthExample silent />
            <div className={'example'}>
                <TextBox
                    value={value}
                    onChange={handleChange}
                    placeholder='John Smith'
                    suffix={() => (
                        <>
                            {value !== '' && (
                                <InputClearValue onClick={handleClear}>
                                    <SvgIcon icon={cancelIcon} />
                                </InputClearValue>
                            )}
                            <InputSeparator />
                        </>
                    )}
                />
                <ComboBox
                    data={sports}
                    id='sport'
                    defaultValue='Football'
                    allowCustom={true}
                />
            </div>
        </>
    );
}

export default Example;