import { NdsButton } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import NoResultsCreatedYet from '~components/no-results-creted-yet';
import { useTranslation } from '~contexts/i18n';

export interface NoContactsCreatedYetProps {
    handleAddContactClick: () => void;
}

const NoContactsCreatedYet = ({ handleAddContactClick }: NoContactsCreatedYetProps) => {
    const { t } = useTranslation('contacts');

    return (
        <NoResultsCreatedYet description={t('no-contacts-description')} title={t('no-contacts-title')}>
            <NdsButton onClick={handleAddContactClick} size={SizesEnums.SMALL} label={t('add-contact-button')} />
        </NoResultsCreatedYet>
    );
};

export default NoContactsCreatedYet;
