import { NdsInput } from '@gonitro/rcl';
import { StatesEnum } from '@gonitro/rcl/lib/_types/designsystem.enums';
import { DevTool } from '@hookform/devtools';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import './exampleForms.scss';

function ExampleForm() {
    const { register, handleSubmit, control, formState } = useForm();
    const { errors } = formState;
    const [data, setData] = useState('');

    return (
        <div>
            <form
                className={'example-form-container'}
                onSubmit={handleSubmit((data) => setData(JSON.stringify(data)))}
                noValidate
            >
                <input {...register('firstName')} placeholder='First name' />
                <Controller // nothing smarter so far :/
                    control={control}
                    name='lastName2'
                    render={({ field: { onChange, ...rest } }) => (
                        <NdsInput
                            state={StatesEnum.DEFAULT}
                            onNdsChange={(event) => onChange(event.detail.value)} // send value to hook form
                            {...rest}
                        />
                    )}
                />
                <input
                    type='email'
                    id='email'
                    {...register('email',
                        {
                            required: true,
                            pattern: {
                                value:
                                    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                                message: 'not valid',
                            },
                        })}
                    placeholder='Email'
                />
                <div className='email-error'>{errors.email?.message as string}</div>
                <select {...register('category', {
                    required: true,
                    validate: (value) => {
                        return (
                            value !== 'A' || 'You selected a wrong value'
                        );
                    },
                })}>
                    <option value=''>Select...</option>
                    <option value='A'>Option A</option>
                    <option value='B'>Option B</option>
                </select>
                <div className='email-error'>{errors.category?.message as string}</div>
                <textarea {...register('aboutYou')} placeholder='About you' />
                <p>{data}</p>
                <input type='submit' />
            </form>
            <DevTool control={control} />
        </div>
    );
}

export default ExampleForm;