import { PathParam } from '@remix-run/router/utils';
import { useCallback, useMemo, useState } from 'react';
import { generatePath, useMatches, useNavigate } from 'react-router-dom';
import { BaseRoutes, NestedRoutes } from '~constants/routes';
import { useAppContext } from '~contexts/app';
import { Guid } from '~models';
import { FlowContextType, FlowStateType } from './flow.context';
import { FlowError } from './flow.error';
import { FlowEntityType, FlowFocusedEntityData, FlowNavigateToStep, FlowStep, FlowType, SetFlowFocusedEntity } from './flow.types';

export const useFlowInternalErrors = () => {
    const [errors, setErrors] = useState<FlowContextType['errors']>({});

    const setError = useCallback<FlowContextType['setError']>((error: FlowError, errorInfo?: Record<string, any> | string) => {
        const id = typeof errorInfo === 'string' ? errorInfo : (errorInfo || {}).entityId;
        const errorKey = id ? `${error}:${id}` : error;

        setErrors((errors) => (
            {
                ...errors,
                [errorKey]: typeof errorInfo === 'object' ? errorInfo : true,
            }
        ));
    }, []);

    const clearError = useCallback<FlowContextType['clearError']>((error: FlowError, entityId?: string) => {
        setErrors((errors) => {
            const newErrors = { ...errors };
            const key = entityId ? `${error}:${entityId}` as unknown as FlowError : error;

            delete newErrors[key];

            return newErrors;
        });
    }, []);

    const clearErrors = useCallback(() => {
        setErrors({});
    }, [setErrors]);

    const hasError = useCallback((error?: FlowError, entityId?: string) => {
        if (error) {
            return Object.keys(errors).some((err) => {
                const [key, errId] = err.split(':');
                const has = key === error as unknown as string;

                return has && errId === entityId;
            });
        }

        return Object.keys(errors).length > 0;

    }, [errors]);

    const getErrorInfo = useCallback((error?: FlowError, entityId?: string) => {
        if (error) {
            let errorInfo: any = undefined;

            Object.keys(errors).forEach((err) => {
                const [key, errId] = err.split(':');
                const has = key === error as unknown as string;

                if (has && errId === entityId) {
                    errorInfo = errors[err as FlowError];
                }
            });

            return errorInfo;
        }
    }, [errors]);

    return {
        setError,
        clearError,
        clearErrors,
        getErrorInfo,
        hasError,
        errors,
    };
};


export const useFlowInternalTypeAndStep: () => { flowType: FlowType, flowStep: FlowStep } = () => {
    const matches = useMatches();

    return useMemo(() => {
        const [type, step] = [...matches].pop()!.id.split('-');

        if (!type || !step) {
            throw new Error('Invalid hook usage. Hook has to be used inside of Flow Routes');
        }

        return {
            flowType: FlowType[type as keyof typeof FlowType],
            flowStep: FlowStep[step as keyof typeof FlowStep],
        };
    }, [matches]);
};


export const useFlowInternalNavigateToPortal = ({ flowState, flowType }: {
    flowState: FlowStateType,
    flowType: FlowType,
}) => {
    const navigate = useNavigate();
    const { lastPortalPath } = useAppContext();

    return useCallback(() => {
        if (flowState.properties?.documentGroupId) {
            if (flowType === FlowType.Package) {
                navigate(BaseRoutes.DocumentPortal + '/' + flowState.properties?.documentGroupId);
            } else {
                navigate(BaseRoutes.TemplatePortal + '/' + flowState.properties?.documentGroupId);
            }
        } else {
            navigate(lastPortalPath);
        }
    }, [flowState.properties?.documentGroupId, flowType, lastPortalPath, navigate]);
};

export const useFlowInternalNavigateToStep = ({ flowState, flowTypePath }: {
    flowState: FlowStateType,
    flowTypePath: string,
}) => {
    const navigate = useNavigate();

    return useCallback<FlowNavigateToStep>((
        step,
        { state, flowId: explicitFlowId, replace } = {},
    ) => {
        const generatePathParams: Record<PathParam<'/:flowTypePath/:stepPath/:flowId?'>, string | null> = {
            flowTypePath,
            stepPath: null,
            flowId: null,
        };

        if (explicitFlowId) {
            generatePathParams.flowId = explicitFlowId;
        } else if (flowState.flowId) {
            generatePathParams.flowId = flowState.flowId;
        }

        switch (step) {
            case FlowStep.Documents:
                generatePathParams.stepPath = NestedRoutes.Documents;
                break;
            case FlowStep.Processing:
                generatePathParams.stepPath = NestedRoutes.Processing;
                break;
            case FlowStep.Detail:
                generatePathParams.stepPath = NestedRoutes.Flow;
                break;
        }
        navigate(generatePath(
            '/:flowTypePath/:stepPath/:flowId?',
            generatePathParams,
        ), {
            state,
            replace,
        });
    }, [flowState.flowId, flowTypePath, navigate]);
};

export const useFlowInternalFocusedEntity = () => {
    const [focusedEntityId, setFocusedEntityId] = useState<Guid | null>(null);
    const [focusedEntityType, setFocusedEntityType] = useState<FlowEntityType>(FlowEntityType.Package);
    const [focusedEntityData, setFocusedEntityData] = useState<FlowFocusedEntityData | null >(null);

    const setFocusedEntity = useCallback<SetFlowFocusedEntity>((entityType, entityId, optionalData) => {
        setFocusedEntityType(entityType);
        setFocusedEntityId(entityId ?? null);
        setFocusedEntityData(optionalData ?? null);
    }, []);

    return {
        focusedEntityId,
        focusedEntityType,
        focusedEntityData,
        setFocusedEntity,
    };
};
