import { useMemo } from 'react';
import { StringUtils } from '~lib';
import { DocumentViewerDocumentModel, DocumentViewerPageModel } from '../types';


export type DocumentViewerPagesInitValue = {
    pages: DocumentViewerPageModel[],
    contentWidth: number,
}

export function useDocumentViewerPagesInit(
    documents: DocumentViewerDocumentModel[],
    justifyPages: boolean,
): DocumentViewerPagesInitValue {
    return useMemo(() => {
        const flatDocs = documents
            .flatMap((el) => el.pageSizes);
        const maxWidth = flatDocs.reduce((max, size) => size.width > max ? size.width : max, 0);

        let absIdx = 0;
        const pages: DocumentViewerPageModel[] = new Array(flatDocs.length);

        documents.forEach((doc, docIdx) => {
            doc.pageSizes.forEach((size, pageIdx) => {
                const scale = maxWidth / size.width;

                pages[absIdx] = {
                    documentName: doc.name,
                    documentTotalPages: doc.pageSizes.length,
                    originalSize: size,
                    unifiedSize: !justifyPages
                        ? size
                        : {
                            width: size.width * scale,
                            height: size.height * scale,
                        },
                    scale,
                    documentId: doc.id,
                    pageId: StringUtils.guid(),
                    documentIndex: docIdx,
                    pageIndex: pageIdx,
                    absolutePageIndex: absIdx,
                    index: `${docIdx}-${pageIdx}`,
                };
                absIdx++;
            });

        });

        return {
            pages,
            contentWidth: maxWidth,
        };
    }, [documents, justifyPages]);
}
