import { NormalizedDragEvent, useDraggable } from '@progress/kendo-react-common';
import classNames from 'classnames';
import { MouseEvent, RefObject, useCallback, useEffect, useRef, useState } from 'react';
import { CommonUtils, DomUtils } from '~lib';
import { Offset, Size } from '~models';
import { useDocumentViewerPageObjectDragCallback } from './documentViewerPageObjectDrag.hook';
import { useDocumentViewerPageObjectResizeCallback } from './documentViewerPageObjectResize.hook';
import {
    DocumentViewerPageModel,
    DocumentViewerPageObjectComponentCallbacksRef,
    DocumentViewerPageObjectModel,
    DocumentViewerPageObjectResizeDirection,
    DocumentViewerPagePosition,
} from '../types';
import './documentViewerPageObject.scss';

const GRAB_LOCK_TRESHOLD_DISTANCE = 5;

export interface DocumentViewerPageObjectProps {
    containerRef: RefObject<HTMLDivElement>,
    pageObject: DocumentViewerPageObjectModel,
    // current page absolute index (when resting, dragging can change it and it will be sent in changePos event)
    currentPage: number;
    // real position (when resting)
    position: {
        offset: Offset,
        size: Size
    }
    pages: DocumentViewerPageModel[];
    pagePositions: DocumentViewerPagePosition[];
    justifyPages: boolean;
    scale: number;
    onDragStart?: (id: string, data: any) => void;
    onDragEnd?: (id: string, data: any) => void;
    onResizeStart?: (id: string, data: any) => void;
    onResizeEnd?: (id: string, data: any) => void;
    onClick?: (id: string, data: any) => void;
}

function DocumentViewerPageObject({
    containerRef,
    pageObject: {
        id,
        Component,
        data,
        minSize = {
            width: 10,
            height: 10,
        },
        canMove,
        keepRatio,
    },
    justifyPages,
    scale,
    currentPage: currentPageProp,
    position: { offset: objectOffset, size: objectSize },
    pages,
    pagePositions,
    onDragStart: onDragStartProp,
    onDragEnd: onDragEndProp,
    onResizeStart: onResizeStartProp,
    onResizeEnd: onResizeEndProp,
    onClick: onClickProp,
}: DocumentViewerPageObjectProps) {

    const ref = useRef<HTMLDivElement>(null);
    const resizingDirectionRef = useRef<DocumentViewerPageObjectResizeDirection>();
    const callbacksRef = useRef<DocumentViewerPageObjectComponentCallbacksRef>(null);

    const currentPageRef = useRef(currentPageProp);
    const dragTresholdExceededRef = useRef(false);
    // initial position stores position when dragging is started
    const [initialPosition, setInitialPosition] = useState<Offset | null>(null);
    const [pressed, setPressed] = useState<boolean>(false);
    const [resizePressed, setResizePressed] = useState<boolean>(false);
    const [dragged, setDragged] = useState<boolean>(false);

    useEffect(() => {
        if (!ref.current) {
            return;
        }
        // css vars can be useful for child components, positioning sets offset and size directly on ref because it's fastest
        ref.current.style.setProperty('--object-width', `${objectSize.width}px`);
        ref.current.style.setProperty('--object-height', `${objectSize.height}px`);
        ref.current.style.top = `${objectOffset.top}px`;
        ref.current.style.left = `${objectOffset.left}px`;
        ref.current.style.width = `${objectSize.width}px`;
        ref.current.style.height = `${objectSize.height}px`;
    }, [objectOffset.left, objectOffset.top, objectSize.height, objectSize.width]);

    useEffect(() => {
        currentPageRef.current = currentPageProp;
    }, [currentPageProp]);

    const onPress = useCallback((event: NormalizedDragEvent) => {
        event.originalEvent.stopPropagation();

        let isNoAction = false;
        let isResize = false;

        DomUtils.findParent(event.originalEvent.target as HTMLElement, (element: HTMLElement) => {
            const dataSet = (element as HTMLElement).dataset;

            // data-no-action attribute on html element  prevents drag handler from any action, it's easier than preventing pointerdown event everywhere
            if ('noAction' in dataSet) {
                isNoAction = true;

                return true;
            }

            // data-direction attribute on html element means resizing direction
            if ('direction' in dataSet) {
                resizingDirectionRef.current = dataSet.direction as DocumentViewerPageObjectResizeDirection;
                isResize = true;

                return true;
            }

            return false;
        }, event.originalEvent.currentTarget as HTMLElement);

        if (isNoAction) {
            return;
        }

        onClickProp?.(id, data);
        callbacksRef.current?.onClick();

        if (!canMove) {
            return;
        }
        if (isResize) {
            setResizePressed(true);
        } else {
            setPressed(true);
            dragTresholdExceededRef.current = false;
        }
    }, [canMove, data, id, onClickProp]);

    const onDragStart = useCallback((event: NormalizedDragEvent) => {
        if (!canMove) {
            return;
        }

        setInitialPosition({
            left: event.clientX - objectOffset.left,
            top: event.clientY - objectOffset.top,
        });

        if (pressed) {
            onDragStartProp?.(id, data);
        } else if (resizePressed) {
            onResizeStartProp?.(id, data);
        }
    }, [
        canMove,
        objectOffset.left,
        objectOffset.top,
        pressed,
        resizePressed,
        onDragStartProp,
        id,
        data,
        onResizeStartProp,
    ]);

    const onDragCallback = useDocumentViewerPageObjectDragCallback(
        ref,
        containerRef,
        currentPageRef,
        dragTresholdExceededRef,
        pages,
        justifyPages,
        objectOffset,
        setDragged,
        GRAB_LOCK_TRESHOLD_DISTANCE,
    );

    const onResizeCallback = useDocumentViewerPageObjectResizeCallback(
        ref,
        minSize,
        objectOffset,
        objectSize,
        keepRatio,
    );

    const onDrag = useCallback((event: NormalizedDragEvent) => {
        if (!initialPosition || !canMove) {
            return;
        }

        if (pressed) {
            onDragCallback(
                event,
                initialPosition,
                pagePositions,
            );
        } else if (resizePressed) {
            onResizeCallback(
                event,
                resizingDirectionRef.current!,
                initialPosition,
                pagePositions[currentPageRef.current],
                justifyPages ? scale * pages[currentPageRef.current].scale : scale,
            );
        }
    }, [
        initialPosition,
        canMove,
        pressed,
        resizePressed,
        onDragCallback,
        pagePositions,
        onResizeCallback,
        justifyPages,
        scale,
        pages,
    ]);

    const onDragEnd = useCallback((event: NormalizedDragEvent) => {
        if (!canMove) {
            return;
        }
        if (ref.current) {
            const page = pages[currentPageRef.current];
            const pagePosition = pagePositions[currentPageRef.current];
            const localScale = justifyPages ? scale * page.scale : scale;

            // dom element is perfect source of information
            const size: Size = {
                width: CommonUtils.toPx(ref.current.style.width) / localScale,
                height: CommonUtils.toPx(ref.current.style.height) / localScale,
            };
            const offset: Offset = {
                top: (CommonUtils.toPx(ref.current.style.top) - pagePosition.offset.top) / localScale,
                left: (CommonUtils.toPx(ref.current.style.left) - pagePosition.offset.left) / localScale,
            };

            callbacksRef.current?.onPositionChange({
                id,
                size,
                offset,
                page,
            });
        }
        if (pressed) {
            setDragged(false);
            onDragEndProp?.(id, data);
        } else if (resizePressed) {
            onResizeEndProp?.(id, data);
        }
        setInitialPosition(null);
    }, [
        canMove,
        pressed,
        resizePressed,
        pages,
        pagePositions,
        justifyPages,
        scale,
        id,
        onDragEndProp,
        data,
        onResizeEndProp,
    ]);

    const onRelease = useCallback((event: NormalizedDragEvent) => {
        if (!canMove) {
            return;
        }
        resizingDirectionRef.current = undefined;
        setResizePressed(false);
        setPressed(false);
        dragTresholdExceededRef.current = false;
    }, [canMove]);


    useDraggable(ref, {
        onPress: onPress,
        onDragStart: onDragStart,
        onDrag: onDrag,
        onDragEnd: onDragEnd,
        onRelease: onRelease,
    });

    // need to stopPropagation when clicking on page object element so document viewer click event is not triggered
    const onClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    }, []);

    return (
        <div
            onClick={onClick}
            ref={ref}
            data-id={data.id}
            className={classNames(
                'c-document-viewer-page-object',
                { 'c-document-viewer-page-object--is-pressed': pressed && !dragged },
                { 'c-document-viewer-page-object--is-resizing': resizePressed },
                { 'c-document-viewer-page-object--is-dragging': dragged },
                { 'c-document-viewer-page-object--is-draggable': canMove },
            )}
        >
            <Component
                id={id}
                data={data}
                isLocked={!canMove}
                callbacksRef={callbacksRef}
                activeResizing={resizingDirectionRef.current}
                isDragged={dragged}
                isPressed={pressed || resizePressed}
            />
        </div>
    );
}


export default DocumentViewerPageObject;
