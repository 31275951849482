import { useRef } from 'react';
import { AuthApi } from '~api/auth.api';
import { PAGE_BACK_REAUTH_COOLDOWN_MS } from '~constants';
import { useApi } from '~contexts/api';
import { useAbortEffect } from '~hooks/effects';
import { SimpleFunc } from '~models';
import { RefreshUserCallback } from './auth.types';


export const useAuthInternalPageBackRefresh = (
    currentUserId: number | undefined,
    refreshUser: RefreshUserCallback,
    resetUser: SimpleFunc,
) => {
    const authApi = useApi(AuthApi);
    const lastRun = useRef(Date.now());

    useAbortEffect((signal) => {
        document.addEventListener(
            'visibilitychange', async () => {
                if (document.visibilityState === 'hidden') {
                    lastRun.current = Date.now();

                    return;
                }
                if (PAGE_BACK_REAUTH_COOLDOWN_MS === 0 || Date.now() - lastRun.current < PAGE_BACK_REAUTH_COOLDOWN_MS) {
                    return;
                }
                try {
                    await refreshUser(signal);
                } catch (e) {
                    console.error(e);
                    resetUser();

                    return false;
                }

            }, { signal },
        );
    }, [authApi, currentUserId, refreshUser, resetUser]);
};