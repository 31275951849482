import { SortDirectionEnum } from '@gonitro/rcl/lib/_types';
import {
    TableColumnSpecificWidth,
    TableDefinition,
    TableFilterValueSubType,
    TableFilterValueType,
} from '~contexts/table/table.types';

export const ContactsTableDefinition: TableDefinition<'contactId'> = {
    rowIdKey: 'contactId',
    itemsPerPage: 20,
    itemsPerPageOptions: [20, 50, 100],
    selectable: true,
    pollInterval: 0,
    initialSorting: {
        key: 'firstName',
        direction: SortDirectionEnum.DESC,
    },
    columns: [
        {
            key: 'firstName',
            dataKey: 'displayName',
            i18nLabel: {
                ns: 'contacts',
                key: 'contacts-portal-table-col-title-name',
            },
            sortable: true,
            width: TableColumnSpecificWidth.Auto,
        },
        {
            key: 'emailAddress',
            i18nLabel: {
                ns: 'contacts',
                key: 'contacts-portal-table-col-title-email',
            },
            sortable: true,
            width: TableColumnSpecificWidth.Auto,
        },
        {
            key: 'type',
            i18nLabel: {
                ns: 'contacts',
                key: 'contacts-portal-table-col-title-type',
            },
            width: 20,
            sortable: false,
        },
        {
            key: 'action',
            width: TableColumnSpecificWidth.Button,
        },
    ],
    filters: [
        {
            type: TableFilterValueType.Array,
            subType: TableFilterValueSubType.String,
            key: 'types',
            queryParamKey: 'types',
            apiKey: 'types',
        },
        {
            type: TableFilterValueType.Primitive,
            subType: TableFilterValueSubType.String,
            key: 'searchString',
        },
    ],
};
