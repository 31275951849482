import Typography, { TypographyToken } from '~components/typography';
import { BaseRoutes } from '~constants/routes';
import { useTranslation } from '~contexts/i18n';
import { useAsyncEffect } from '~hooks/effects';
import { useNavigateWithQueryParams, useQueryParams } from '~hooks/router';
import ConvertLocalToCloudForm from './convert-local-to-cloud-form';
import './convert-local-to-cloud.view.scss';

export interface ConvertLocalToCloudViewProps {
}


function ConvertLocalToCloudView() {
    const { t } = useTranslation('unauthorized');
    const { state } = useQueryParams<{ token: string, state: string }>();
    const navigate = useNavigateWithQueryParams();

    useAsyncEffect(() => async (signal) => {
        if (!state) {
            navigate(BaseRoutes.Login);
        }
    }, [navigate, state]);

    return (
        <div className={'v-convert-local-to-cloud'}>
            <div className={'v-convert-local-to-cloud__header'}>
                <Typography
                    tagName={'h1'}
                    token={TypographyToken.MobileHeaderMd}
                    mediumScreenToken={TypographyToken.DesktopHeaderMd}
                    text={t('title-convert-local-account-to-cloud')}
                />
                <div className={'v-convert-local-to-cloud__info'}>
                    <Typography
                        tagName={'h6'}
                        token={TypographyToken.MobileDescriptionBoldMd}
                        mediumScreenToken={TypographyToken.DesktopDescriptionBoldMd}
                        text={t('convert-to-cloud-info-title')}
                    />
                    <Typography
                        tagName={'ul'}
                        token={TypographyToken.MobileDescriptionSm}
                        mediumScreenToken={TypographyToken.DesktopDescriptionSm}
                        className={'v-convert-local-to-cloud__info-list'}
                    >
                        <li>{t('convert-to-cloud-info-nothing-affected')}</li>
                        <li>{t('convert-to-cloud-info-no-more-password-login')}</li>
                    </Typography>
                </div>
                <Typography
                    tagName={'p'}
                    token={TypographyToken.MobileDescriptionLg}
                    mediumScreenToken={TypographyToken.DesktopDescriptionLg}
                    text={t('description-convert-local-account-to-cloud')}
                />
            </div>
            <div className='v-convert-local-to-cloud__content'>
                {state && <ConvertLocalToCloudForm state={state} />}
            </div>
        </div>
    );
}

export default ConvertLocalToCloudView;
