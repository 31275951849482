import { ActorType } from '~constants';
import { FieldType } from '~constants/field-type';
import { FlowEntityType } from '~contexts/flow/flow.types';


export const AnyTypeToFlowEntityType = {
    [FieldType.TextBox]: FlowEntityType.TextField,
    [FieldType.CheckBox]: FlowEntityType.CheckboxField,
    [FieldType.RadioGroup]: FlowEntityType.RadioField,
    [FieldType.SigningField]: FlowEntityType.SigningField,
    [ActorType.Approver]: FlowEntityType.Approver,
};
