import { NdsButton, NdsIconFont } from '@gonitro/rcl';
import { NdsButtonTypesEnum, SizesEnums } from '@gonitro/rcl/lib/_types';
import { TableContext, TableContextProvider } from 'contexts/table';
import { useCallback, useState } from 'react';
import { ContactsApi } from '~api/contacts.api';
import ContactGroupForm from '~components/contact-group-form';
import Content from '~components/content';
import NavTabs from '~components/nav-tabs';
import { ContactGroupType } from '~constants';
import { CONTACT_TABS } from '~constants/contact-tabs';
import { PortalTableContainer } from '~containers/portal-table';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { FetchTableDataDelegate } from '~contexts/table/table.types';
import useNavTabs from '~hooks/tabs';
import { ContactGroupModel } from '~models/contact-group.model';
import useFilterTabConditions from '~views/portals/user-management/hooks/useFilterTabConditions';
import ContactGroupsQuickActions from './contact-group-quick-actions';
import DeleteContactGroupModal from './delete-contact-group-modal';
import NoContactsCreatedYet from './no-contact-group-created-yet/noContactGroupCreatedYet';
import { ContactGroupsTableDefinition } from './table-definition';
import { useContactsTableFilters } from './use-contact-groups-table-filter';
import './contact-groups.view.scss';

const ContactGroupTableTypeLabel: any = {
    [ContactGroupType.Shared]: 'contact-group-type-shared-table-label',
    [ContactGroupType.Personal]: 'contact-group-type-personal-table-label',
};

export interface ContactGroupsViewProps {
}

export default function ContactGroupsView() {
    const contactsApi = useApi(ContactsApi);
    const { t } = useTranslation('contacts');
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [contactGroupToDelete, setContactGroupToDelete] = useState<ContactGroupModel | undefined>(undefined);

    const filterTabConditions = useFilterTabConditions();

    const { filteredTabs } = useNavTabs(CONTACT_TABS, filterTabConditions);
    const [openContactGroupForm, setOpenContactGroupForm] = useState(false);
    const [contactGroupToEdit, setContactGroupToEdit] = useState<ContactGroupModel>();

    const fetchData: FetchTableDataDelegate<ContactGroupModel> = useCallback(
        async (params, signal?: AbortSignal) => {
            return await contactsApi.getContactGroupList(params, signal);
        },
        [contactsApi],
    );

    const filters = useContactsTableFilters();

    const handleAddContactGroup = () => {
        setOpenContactGroupForm(true);
    };

    const closeContactGroupForm = useCallback((refresh: () => void) => {
        setOpenContactGroupForm(false);
        setContactGroupToEdit(undefined);
        refresh();
    }, []);

    const handleEditContactGroup = async(contactGroup: ContactGroupModel) => {
        try {
            const response = await contactsApi.getContactGroup({
                id: contactGroup.id,
                anonymiseData: false,
            });

            setContactGroupToEdit(response);
            setOpenContactGroupForm(true);
        } catch (error) {
            console.warn(error);
        }
    };

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
        setContactGroupToDelete(undefined);
    };

    const handleOnDeleteClick = (contactGroup: ContactGroupModel ) => {
        setContactGroupToDelete(contactGroup);
        setOpenDeleteModal(true);
    };

    return (
        <Content noDefaultPadding>
            <TableContextProvider<'id', ContactGroupModel>
                definition={ContactGroupsTableDefinition}
                fetchData={fetchData}
            >
                <PortalTableContainer<'id', ContactGroupModel>
                    pageTitle={t('contacts-title')}
                    filtersDefinition={filters}
                    tabs={<NavTabs navTabs={filteredTabs} />}
                    headerButton={
                        <>
                            <NdsButton
                                label={t('create-contact-group')}
                                size={SizesEnums.SMALL}
                                buttonType={NdsButtonTypesEnum.PRIMARY}
                                onClick={ () => handleAddContactGroup()}
                            />
                        </>
                    }
                    renderColumns={(data) => {
                        return {
                            type: (
                                <>
                                    <NdsIconFont
                                        fontName={data.isShared ? 'fa-solid-users' : 'fa-solid-user'}
                                        size={SizesEnums.SMALL}
                                    />
                                    {t(ContactGroupTableTypeLabel[data.isShared ? ContactGroupType.Shared: ContactGroupType.Personal])}
                                </>
                            ),
                            action: <ContactGroupsQuickActions
                                isShared={data.isShared}
                                onEditClick={() => handleEditContactGroup(data)}
                                onDeleteClick={() => handleOnDeleteClick(data)}/>,
                        };
                    }}
                    renderNoItemsYet={
                        <NoContactsCreatedYet handleAddContactGroupClick={() => alert('add Contact Group click')} />
                    }
                />
                <DeleteContactGroupModal onCloseModalClick={handleCloseDeleteModal} contactGroup={contactGroupToDelete} openDeleteModal={openDeleteModal}/>
                <TableContext.Consumer>
                    {({ refresh }) => (
                        <OverlayPortal type={'panel'} visible={openContactGroupForm}>
                            {() => (
                                <ContactGroupForm
                                    open={openContactGroupForm}
                                    onClose={() => closeContactGroupForm(refresh)}
                                    contactGroup={contactGroupToEdit}
                                />
                            )}
                        </OverlayPortal>
                    )}
                </TableContext.Consumer>
            </TableContextProvider>
        </Content>
    );
}
