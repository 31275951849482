import { Example } from '@gonitro/rcl';
import LocalExample from '~components/example';
import './example.scss';

export interface ExampleViewProps {
}

function ExampleView() {
    return (
        <div className={'v-example'}>
            Example from rcl:
            <div className={'v-example_group'}>
                <Example />
            </div>
            Example from local:
            <div className={'v-example_group'}>
                <LocalExample label={'Example view'} />
            </div>
        </div>
    );
}

export default ExampleView;