import { NdsButton } from '@gonitro/rcl';
import { BkgModesEnum, NdsButtonTypesEnum } from '@gonitro/rcl/lib/_types';
import { SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types/designsystem.enums';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import Content from '~components/content';
import Layout from '~components/layout';
import Logo from '~components/logo';
import MenuDropdown from '~components/menu-dropdown/menu-dropdown';
import MenuGroup from '~components/menu-dropdown/menu-group/menu-group';
import MenuItem from '~components/menu-dropdown/menu-item/menu-item';
import { useLanguages } from '~contexts/i18n';
import { DateUtil } from '~lib/date.utils';
import { LanguageProfile, PropsWithClassNames } from '~models';
import './simpleLayout.container.scss';

export interface SimpleLayoutContainerProps extends PropsWithClassNames, PropsWithChildren {
}

export const SimpleLayoutContainer = ({ className, children }: SimpleLayoutContainerProps) => {
    const {
        availableLanguages,
        language,
        setLanguage,
    } = useLanguages();

    return (
        <Layout className={classNames('ct-simple-layout', className)}>
            <div className={'ct-simple-layout__background'} />
            <div className={'ct-simple-layout__logo ct-simple-layout__logo--in-background'}>
                <Logo />
            </div>
            <div className={'ct-simple-layout__copy'}>
                <div>©{DateUtil.format(new Date(), 'yyyy')} Nitro Software, Inc. All rights reserved.</div>
            </div>
            <div className={'ct-simple-layout__content'}>
                <Content withScrollbar>
                    <div className={'ct-simple-layout__language'}>
                        <MenuDropdown
                            button={(toggleDropdown: () => void) => (
                                <NdsButton
                                    slot={'button'}
                                    leftIcon={'fa-solid-globe'}
                                    bgMode={BkgModesEnum.LIGHT}
                                    buttonType={NdsButtonTypesEnum.NEUTRAL}
                                    size={SizesEnums.XSMALL}
                                    onClick={toggleDropdown}
                                />
                            )}
                        >
                            <MenuGroup>
                                {availableLanguages.map((lang: LanguageProfile) => (
                                    <MenuItem
                                        key={lang.isoCultureCode}
                                        primaryText={lang.nativeName}
                                        state={lang.isoCultureCode === language?.isoCultureCode ? StatesEnum.SELECTED : StatesEnum.DEFAULT}
                                        onClick={() => setLanguage(lang)}
                                    />
                                ))}
                            </MenuGroup>
                        </MenuDropdown>
                    </div>
                    <div className={'ct-simple-layout__logo ct-simple-layout__logo--in-content'}>
                        <Logo dark />
                    </div>
                    {children}
                </Content>
            </div>
        </Layout>
    );
};
