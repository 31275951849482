import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { AuthApi } from '~api/auth.api';
import Loader from '~components/loader';
import { ApiErrors, PermissionCodes } from '~constants';
import { useApi, useApiErrorProcessor } from '~contexts/api';
import { useAsyncEffect } from '~hooks/effects';
import { SimpleFunc } from '~models';
import { AuthContext, AuthContextType } from './auth.context';
import { useAuthInternalPageBackRefresh } from './auth.hooks.internal';
import { AbsencePeriod, DocumentGroup, RefreshUserCallback, UserInfo } from './auth.types';

export interface AuthContextProviderProps {
    children: ReactNode;
}

export function AuthContextProvider({ children, ...props }: AuthContextProviderProps) {
    const [ready, setReady] = useState(false);
    const [info, setInfo] = useState<UserInfo | null>(null);
    const [permissions, setPermissions] = useState<PermissionCodes>([]);
    const [absences, setAbsences] = useState<AbsencePeriod[]>([]);
    const [documentGroups, setDocumentGroups] = useState<DocumentGroup[]>([]);
    const authApi = useApi(AuthApi);
    const isRefreshingUser = useRef<boolean>(false);

    useApiErrorProcessor((error) => {
        if (error.getErrorCode() === ApiErrors.UserNotAuthenticated) {
            if (!isRefreshingUser.current) {
                window.location.reload();
            }
        }
    });

    const resetUser = useCallback<SimpleFunc>(() => {
        setInfo(null);
        setAbsences([]);
        setPermissions([]);
        setDocumentGroups([]);
    }, []);

    // api call callbacks
    const refreshUser = useCallback<RefreshUserCallback>(async (signal, user) => {
        try {
            isRefreshingUser.current = true;
            const userResponse = user ?? await authApi.getCurrentUser(signal);
            const documentGroupsResponse = (await authApi.getCurrentDocumentGroups(signal));

            setInfo({
                id: userResponse.id,
                avatarB64: userResponse.avatarb64,
                cloudProviderName: userResponse.cloudProviderName,
                company: userResponse.company,
                defaultPhoneNumberCountry: userResponse.defaultPhoneNumberCountry,
                emailAddress: userResponse.emailAddress,
                firstName: userResponse.firstName,
                language: userResponse.language,
                lastName: userResponse.lastName,
                signatureImageB64: userResponse.signatureImageB64,
            });

            setAbsences(userResponse.absencePeriods);
            setPermissions(userResponse.permissions);
            setDocumentGroups(documentGroupsResponse.items);

            isRefreshingUser.current = false;

            return true;
        } catch (e) {
            console.error(e);
            resetUser();

            return false;
        }
    }, [authApi, resetUser]);


    useAuthInternalPageBackRefresh(
        info?.id, refreshUser, resetUser,
    );

    // login is here as it's coupled with refreshUser callback on success
    const loginUser = useCallback(async (
        email: string, password: string, signal?: AbortSignal,
    ) => {
        const response = await authApi.login({
            email,
            password,
        }, signal);

        if (response) {
            await refreshUser(signal);

            return true;
        }

        return false;
    }, [authApi, refreshUser]);

    // logout is here as it's coupled with resetUser callback on success
    const logoutUser = useCallback(async (signal?: AbortSignal) => {
        const response = await authApi.logout(signal);

        if (response) {
            resetUser();

            return true;
        }

        return false;
    }, [authApi, resetUser]);


    useAsyncEffect(() => async (signal) => {
        await refreshUser(signal);

        if (!signal.aborted) {
            setReady(true);
        }
    }, [refreshUser]);


    const contextValue = useMemo<AuthContextType>(() => {
        return {
            loginUser,
            logoutUser,
            refreshUser,
            absences,
            permissions,
            documentGroups,
            info,
            isAuthenticated: info !== null,
        };
    }, [
        info,
        loginUser,
        logoutUser,
        refreshUser,
        absences,
        documentGroups,
        permissions,
    ]);

    return !ready
        ? <Loader size={SizesEnums.XLARGE} center />
        : (
            <AuthContext.Provider value={contextValue}>
                {children}
            </AuthContext.Provider>
        );
}
