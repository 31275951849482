import { Suspense } from 'react';
import Loader from '~components/loader';
import { AppContainer } from '~containers/app';

function App() {
    return (
        <Suspense fallback={<Loader/>}>
            <AppContainer/>
        </Suspense>
    );
}

export default App;
