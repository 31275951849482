import { IS_PRODUCTION_ENV } from '~constants';
import Typography, { TypographyToken } from '../../../typography';
import FlowEditorDocumentViewer from '../flow-editor-document-viewer';
import FlowEditorLeftPane from '../flow-editor-left-pane';
import FlowEditorRightPane from '../flow-editor-right-pane';
import './flowEditorWrapper.scss';

export interface FlowEditorWrapperProps {

}

function FlowEditorWrapper(props: FlowEditorWrapperProps) {
    return (
        <div className={'c-flow-editor-wrapper'}>
            <div className={'c-flow-editor-wrapper__left'}>
                <FlowEditorLeftPane />
            </div>
            <div className={'c-flow-editor-wrapper__center'}>
                <FlowEditorDocumentViewer />
            </div>
            <div className={'c-flow-editor-wrapper__right'}>
                <FlowEditorRightPane />
            </div>
            {IS_PRODUCTION_ENV && (
                <div className={'c-flow-editor-wrapper__use-desktop'}>
                    <Typography token={TypographyToken.DesktopHeaderMd}>
                        Please use device with wider screen to edit package
                    </Typography>
                </div>
            )}
        </div>
    );
}

export default FlowEditorWrapper;
