import { ActorType } from '~constants';
import { Guid, LanguageDataLabel } from '~models';
import { FlowStateType } from './flow.context';
import {
    FlowReducerActorActionPayloads,
    FlowReducerCommonActionPayloads,
    FlowReducerDocumentActionPayloads,
    FlowReducerEditorInitActionPayloads,
    FlowReducerElementActionPayloads,
    FlowReducerPageObjectActionPayloads,
    FlowReducerPropertiesActionPayloads,
    FlowReducerStakeholderActionPayloads, FlowReducerTemplateActionPayloads,
} from './reducers';

export enum FlowType {
    Package = 'Package',
    Template = 'Template',
}

export enum FlowErrorStateType {
    Package = 'Package',
    Stakeholder = 'Stakeholder',
    Element = 'Element',
}

export enum FlowStep {
    Documents = 'Documents',
    Processing = 'Processing',
    Detail = 'Detail',
}

export enum FlowActionType {
    SetFlowId,
    SetDocuments, // overrides whole document list
    AddDocuments,
    DeleteDocument,
    UpdateDocument,
    SetTemplate,
    RemoveTemplate,
    SetTemplateName,
    SetInitialDetails,
    SetInitialLegalNoticeConfig,
    SetInitialFlow,
    UpdateProperties,
    UpdatePackageName,
    UpdateTemplateName,
    MovePageObject,
    AddElement,
    DeleteElement,
    UpdateElement,
    AddStakeholder,
    UpdateStakeholder,
    SetStakeholders,
    AddActor,
    DeleteActor,
    AddActorElements,
    DeleteActorElements,
    SetActorStakeholder,
}

export type FlowReducerActionPayloads =
    FlowReducerActorActionPayloads
    & FlowReducerCommonActionPayloads
    & FlowReducerDocumentActionPayloads
    & FlowReducerEditorInitActionPayloads
    & FlowReducerElementActionPayloads
    & FlowReducerPageObjectActionPayloads
    & FlowReducerPropertiesActionPayloads
    & FlowReducerStakeholderActionPayloads
    & FlowReducerTemplateActionPayloads
    ;

export interface FlowStateAction<T extends FlowActionType = FlowActionType> {
    type: T,
    payload: FlowReducerActionPayloads[T],
}

export type FlowReducerFunc<T extends FlowActionType> = (state: FlowStateType, payload: FlowReducerActionPayloads[T]) => FlowStateType;

export type FlowNavigateToStep = (
    step: FlowStep,
    params?: {
        state?: any;
        flowId?: Guid;
        replace?: boolean;
    },
) => void;

export enum FlowEntityType {
    Package,
    SigningField,
    TextField,
    CheckboxField,
    RadioField,
    Stakeholder,
    Approver,
}

export type FlowFocusedEntityData = {
    selectedActor?: Guid;
}

export type SetFlowFocusedEntity = (entityType: FlowEntityType, entityId?: Guid, optionalData?: FlowFocusedEntityData) => void;

export enum FlowGroupType {
    FormFillersApprovers = 'ffa',
    Signers = 's',
    Receivers = 'r'
}

export const FlowGroupTitleI18nKey: Record<FlowGroupType, LanguageDataLabel<'flow'>> = {
    'ffa': 'recipient-title-formfiller-approver',
    's': 'recipient-title-signers',
    'r': 'recipient-title-receivers',
};
export const FlowGroupIcon: Record<FlowGroupType, string> = {
    'ffa': 'fa-regular fa-pen-field',
    's': 'fa-regular fa-signature',
    'r': 'fa-solid fa-envelope',
};

export const ActorTypeToFlowGroupType = {
    [ActorType.Approver]: FlowGroupType.FormFillersApprovers,
    [ActorType.FormFiller]: FlowGroupType.FormFillersApprovers,
    [ActorType.Signer]: FlowGroupType.Signers,
    [ActorType.Receiver]: FlowGroupType.Receivers,
};
