import {
    TableFilterArrayValue,
    TableFilterObjectValue,
    TableFiltersDefinition,
    TableFilterValue,
    TableFilterValueType,
} from './table.types';


export const createFilterValuesDto = (filterValues: Record<string, TableFilterValue>, filtersDef?: TableFiltersDefinition[]) => {
    if (!filtersDef) {
        return {};
    }
    const defs = filtersDef.filter(def => def.type !== TableFilterValueType.Reserved);

    if (!defs.length) {
        return {};
    }
    const values: Record<string, any> = {};
    for (const def of defs) {
        if (def.type === TableFilterValueType.Object) {
            const [sourceKey1, sourceKey2] = def.key.split('.');
            const [targetKey1, targetKey2] = (def.apiKey ?? def.key).split('.');

            const sourceObj = filterValues[sourceKey1] as TableFilterObjectValue;

            if (!sourceObj || sourceObj[sourceKey2] === undefined || sourceObj[sourceKey2] === null) {
                continue;
            }

            const targetValue = def.apiTransform ? def.apiTransform(sourceObj[sourceKey2]) : sourceObj[sourceKey2];

            if (!targetKey2) { // object is destructed to single values
                values[targetKey1] = targetValue;
            } else { // object is preserved
                values[targetKey1] = {
                    ...values[targetKey1] || {},
                    [targetKey2]: targetValue,
                };
            }
        } else if (def.type === TableFilterValueType.Array) {
            if (!filterValues[def.key] || !Array.isArray(filterValues[def.key])) {
                continue;
            }

            if (typeof def.apiTransform === 'function') {
                values[def.apiKey ?? def.key] = (filterValues[def.key] as TableFilterArrayValue).map(val => def.apiTransform!(val));
            } else {
                values[def.apiKey ?? def.key] = filterValues[def.key];
            }
        } else {
            if (filterValues[def.key] === undefined || filterValues[def.key] === null) {
                continue;
            }
            values[def.apiKey ?? def.key] = filterValues[def.key];
        }
    }

    return values;
};