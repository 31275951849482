import { useCallback, useMemo } from 'react';
import { FlowAction, FlowStatusEnum, Permission } from '~constants';
import { useHasPermission } from '~contexts/auth';
import { useSettings } from '~contexts/settings';
import { SigningSessionTableDataModel } from '~models/portals.models';

export const useBulkActionsDefinition = () => {
    const { signingOptionsSettings: { isBulkSignEnabled } } = useSettings();
    const hasBulkSignPermission = useHasPermission(Permission.AccessSignerPortal_ActionBulkSignDocument);

    const isEligibleForDownload = useCallback(
        (data: SigningSessionTableDataModel) =>
            [FlowStatusEnum.Signed, FlowStatusEnum.Finished, FlowStatusEnum.Received, FlowStatusEnum.Archived].includes(data.status),
        [],
    );

    const isEligibleForDelete = useCallback(
        (data: SigningSessionTableDataModel) =>
            [
                FlowStatusEnum.Rejected,
                FlowStatusEnum.Signed,
                FlowStatusEnum.Finished,
                FlowStatusEnum.Received,
                FlowStatusEnum.Archived,
            ].includes(data.status),
        [],
    );
    const isEligibleForSign = useCallback((data: SigningSessionTableDataModel) => [FlowStatusEnum.PendingSigning].includes(data.status), []);

    return useMemo(() => {
        const items = [];

        if (isBulkSignEnabled && hasBulkSignPermission) {
            items.push([
                {
                    type: FlowAction.Sign,
                    iconName: 'fa-solid-signature',
                    canRun: isEligibleForSign,
                },
            ]);
        }
        items.push([
            {
                type: FlowAction.Delete,
                iconName: 'fa-solid-trash',
                canRun: isEligibleForDelete,
            },
            {
                type: FlowAction.Download,
                iconName: 'fa-solid-download',
                canRun: isEligibleForDownload,
            },
        ]);


        return items;
    }, [
        hasBulkSignPermission,
        isBulkSignEnabled,
        isEligibleForDelete,
        isEligibleForDownload,
        isEligibleForSign,
    ]);
};
