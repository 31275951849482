import { Dispatch, MouseEvent, MutableRefObject, SetStateAction, useCallback } from 'react';
import { FieldValues, UseFormGetValues, UseFormReset } from 'react-hook-form';
import { ActorType } from '~constants';
import { Substitute } from '~models/user-management.models';
import { SubstituteTabContactsListItem } from './out-of-office-tab';

export interface UseHandleAddSubProps {
    selectedContact: SubstituteTabContactsListItem | null;
    setSelectedContact: (contact: SubstituteTabContactsListItem | null) => void;
    setShowSubPanel: (bool: boolean) => void;
    setSubstitutes: Dispatch<SetStateAction<Substitute[]>>;
    substitutes: Substitute[];
    setContacts: Dispatch<SetStateAction<SubstituteTabContactsListItem[]>>;
    isEditMode: boolean;
    setEditMode: (bool: boolean) => void;
    previousSub: MutableRefObject<number | undefined>;
    getValues: UseFormGetValues<FieldValues>
    reset: UseFormReset<FieldValues>
}

const useHandleAddSub = ({
    selectedContact,
    setSelectedContact,
    setShowSubPanel,
    setSubstitutes,
    substitutes,
    setContacts,
    isEditMode,
    setEditMode,
    previousSub,
    getValues,
    reset,
}: UseHandleAddSubProps) => {
    const { formfiller, approver, signer, receiver, reassign } = getValues();

    const handleAddSub = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (!selectedContact) {
            return;
        }

        const actorTypeArray = [
            {
                key: ActorType.FormFiller,
                value: formfiller,
            },
            {
                key: ActorType.Approver,
                value: approver,
            },
            {
                key: ActorType.Signer,
                value: signer,
            },
            {
                key: ActorType.Receiver,
                value: receiver,
            },
        ];

        const newSub: Substitute = {
            contactId: selectedContact?.contactId,
            emailAddress: selectedContact?.emailAddress,
            type: 'contact',
            displayName: selectedContact?.fullName,
            actionTypes: actorTypeArray.filter(item => item.value).map(item => item.key),
            allowedToReassign: reassign,
        };

        if (isEditMode) {
            const newSubstitutes: Substitute[] = substitutes.map((sub) =>
                sub.contactId === previousSub.current ? newSub : sub);

            setSubstitutes(newSubstitutes);
            setEditMode(false);
            previousSub.current = undefined;
        } else {
            setSubstitutes((prev: Substitute[]) => [...prev, newSub]);
        }

        setContacts((prevContacts) =>
            prevContacts.map((contact) =>
                contact.contactId === selectedContact?.contactId
                    ? {
                        ...contact,
                        disabled: true,
                    }
                    : contact));

        setShowSubPanel(false);
        setSelectedContact(null);
        const currentFieldValues = getValues();

        reset({
            ...currentFieldValues,
            formfiller: false,
            approver: false,
            reassign: false,
            signer: false,
            receiver: false,
        }, { keepErrors: true });
    }, [
        approver,
        formfiller,
        getValues,
        isEditMode,
        reassign,
        receiver,
        reset,
        selectedContact,
        signer,
        substitutes,
        setSelectedContact,
        setShowSubPanel,
        setSubstitutes,
        setContacts,
        setEditMode,
        previousSub,
    ]);

    return handleAddSub;
};

export default useHandleAddSub;
