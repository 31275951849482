import { NdsButton } from '@gonitro/rcl';
import { NdsButtonStylesEnum, NdsButtonTypesEnum, SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types';
import { Field } from '@progress/kendo-react-form';
import { useCallback, useEffect, useState } from 'react';
import { ContactsApi } from '~api/contacts.api';
import ContactForm from '~components/contact-form';
import { ContactType } from '~constants';
import { CloudContactProviderEnum } from '~constants/cloud-provider';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { useSettings } from '~contexts/settings';
import useMapContactToComboboxModel from '~hooks/contact-combobox';
import { useAsyncEffect } from '~hooks/effects';
import { ComboboxContactModel, ContactModel } from '~models/contacts.models';
import SlectedContactToGroupCombobox from '../slected-contact-to-group-combobox';
import AddContactToGroupCombobox from './add-contact-to-group-combobox/addContactToGroupCombobox';
import './addContactToGroup.scss';

export interface AddContactToGroupProps {
    hasViewSharedContactPermission: boolean;
    setSelectedContacts: (selectedContacts: ComboboxContactModel[]) => void;
    selectedContacts?: ComboboxContactModel[]
}

function AddContactToGroup({ hasViewSharedContactPermission, setSelectedContacts, selectedContacts }: AddContactToGroupProps) {
    const contactsApi = useApi(ContactsApi);
    const settings = useSettings();
    const { t: tFlow } = useTranslation('flow');
    const { t: tContacts } = useTranslation('contacts');
    const [disableSync, setDisableSync] = useState<boolean>(false);
    const [openContactForm, setOpenContactForm] = useState<boolean>(false);
    const [contacts, setContacts] = useState<ComboboxContactModel[]>();
    const [contactToEdit, setContactToEdit] = useState<ContactModel>();
    const { google, microsoft } = settings.cloudSettings;
    const { transformModel } = useMapContactToComboboxModel();

    useEffect(() => {
        if (!google.oauthSettings.isEnabled || !microsoft.oauthSettings.isEnabled) {
            setDisableSync(true);
        }
    }, [google.oauthSettings.isEnabled, microsoft.oauthSettings.isEnabled]);

    const syncContacts = async () => {
        try {
            if (google.oauthSettings.isEnabled) {
                await contactsApi.syncContacts({ contactsProvider: CloudContactProviderEnum.Google });
            }
            if (microsoft.oauthSettings.isEnabled) {
                await contactsApi.syncContacts({ contactsProvider: CloudContactProviderEnum.Microsoft });
            }
        } catch (e) {
            console.warn(e);
            throw e;
        }
    };

    const getContactTypes = useCallback(() => {
        const contactTypes = [ContactType.Personal];

        if (hasViewSharedContactPermission) {
            contactTypes.push(ContactType.Shared);
        }

        return contactTypes;
    }, [hasViewSharedContactPermission]);

    const getContacts = useCallback(
        async (searchString?: string) => {
            // if it's a number it's a selection else it's a search string
            if ((typeof searchString === 'number' || !isNaN(Number(searchString))) && contacts && contacts?.length > 0) {
                const selectedContact = contacts?.find((contact) => contact.id === searchString);

                if (selectedContact) {
                    const isContactPresent = selectedContacts?.some((contact) => contact.id === selectedContact.id);

                    // set the contacts to the selected contacts to add to group
                    if (!isContactPresent) {
                        setSelectedContacts([...(selectedContacts || []), selectedContact]);
                    }
                }
            } else {
                try {
                    const response = await contactsApi.getContactBucket({
                        searchString: searchString,
                        contactTypes: getContactTypes(),
                    });

                    await setContacts(response.items.map(transformModel));

                } catch (error) {
                    console.error('Failed to get contacts:', error);
                }
            }
        },
        [
            contacts,
            contactsApi,
            getContactTypes,
            selectedContacts,
            setSelectedContacts,
            transformModel,
        ],
    );


    useAsyncEffect(
        () => async () => {
            try {
                getContacts();
            } catch (error) {
                console.error('Error fetching actors:', error);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const getContactFullInfo = async (contact: ComboboxContactModel) => {
        //get the full information to open and edit a contact
        const response = await contactsApi.getContactFullInformation({ contactId: contact?.id });

        setContactToEdit(response);
        setOpenContactForm(true);
    };


    return (

        <div className='c-add-contact-to-group__select-contact-wrapper'>
            {contacts &&
            <Field
                name={'contactId'}
                label={tContacts('contact-to-add-label')}
                placeholder={tFlow('select-new-contact-placeholder')}
                component={AddContactToGroupCombobox}
                onChange={(e) => getContacts(e.value)}
                groupByField={'contactId'}
                selectedContacts={selectedContacts}
                additionalInfo={true}
                data={contacts}
                textField={'displayedSelection'}
                required
            />
            }
            <div className='c-add-contact-to-group__sync-add-contact'>
                <NdsButton
                    size={SizesEnums.XSMALL}
                    label={tFlow('sync-contacts')}
                    buttonType={NdsButtonTypesEnum.SECONDARY}
                    buttonStyle={NdsButtonStylesEnum.ALT}
                    leftIcon='fa-solid-arrows-repeat'
                    state={disableSync ? StatesEnum.DISABLED : StatesEnum.DEFAULT}
                    onClick={() => syncContacts()}
                />
                <NdsButton
                    size={SizesEnums.XSMALL}
                    label={tFlow('add-contacts')}
                    buttonType={NdsButtonTypesEnum.PRIMARY}
                    buttonStyle={NdsButtonStylesEnum.ALT}
                    state={StatesEnum.DEFAULT}
                    leftIcon='fa-solid-plus'
                    onClick={() => setOpenContactForm(true)}
                />
            </div>
            <div  className={'c-add-contact-to-group__selected-contacts'}>

                {selectedContacts && selectedContacts?.length > 0 && (
                    selectedContacts.map((selectedContact, index) => (
                        <div key={index}>
                            <SlectedContactToGroupCombobox
                                contact={selectedContact}
                                handleUnselectContact={() => setSelectedContacts(selectedContacts.filter((el) => el.id !== selectedContact.id))}
                                handleEditContact={() => {
                                    getContactFullInfo(selectedContact);
                                }} />
                        </div>
                    ))

                )
                }
            </div>
            <OverlayPortal type={'panel'} visible={openContactForm}>
                {() => (
                    <ContactForm
                        open={true}
                        onClose={()=> {
                            setOpenContactForm(false); setContactToEdit(undefined);
                        }}
                        contact={contactToEdit}
                    />
                )}
            </OverlayPortal>

        </div>
    );
}
export default AddContactToGroup;
