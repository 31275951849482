import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { TextArea } from '@progress/kendo-react-inputs';
import { useCallback, useState } from 'react';
import { ContactsApi } from '~api/contacts.api';
import { PortalsApi } from '~api/portals.api';
import ContactForm from '~components/contact-form';
import Error from '~components/error';
import Loader from '~components/loader';
import Typography, { TypographyToken } from '~components/typography';
import { ActorType } from '~constants';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { OverlayPortal } from '~contexts/overlay/overlay.components/overlayPortal.overlay.component';
import { useAsyncEffect } from '~hooks/effects';
import { ObjectUtils } from '~lib';
import { ContactBucketItemModel, Guid, LanguageDataLabel } from '~models';
import { ContactBasicModel } from '~models/contact.model';
import { ComboboxContactModel, ContactModel } from '~models/contacts.models';
import { PackageListItemModel } from '~models/portals.models';
import { ReassignActorModel, RequiredDataReassign } from '~models/reassignActor.models';
import ReassignCombobox from './reassign-combobox/reassignCombobox';
import SelectNewContactReassign from './select-new-contact-reassign';
import SelectedContactReassign from './selected-contact-reassign';
import './reassignContentQuickAction.scss';

export interface MissingFields {
    error: string[];
    warning: string[];
}

export interface ReassignContentQuickActionProps {
    data: PackageListItemModel;
    submitForm: (reassignForm: ReassignFormData) => void;
    formRef: any;
}

export interface ReassignFormData {
    contactId: Guid;
    selectedRecipient: Guid;
    isCloudContact: boolean;
    reassignmentReason: string;
    packageId: string;
    selectedRecipientEmail: string;
}

// Define a type that represents the keys of ContactItemModel
type ContactItemModelKeys = keyof ContactBasicModel;

function ReassignContentQuickAction({ formRef, data, submitForm }: ReassignContentQuickActionProps) {
    const { t: tFlow } = useTranslation('flow');
    const portalsApi = useApi(PortalsApi);
    const contactsApi = useApi(ContactsApi);
    const [actors, setActors] = useState<ComboboxContactModel[]>();
    const [error, setError] = useState<LanguageDataLabel<'error'>>();
    const [selectedContact, setSelectedContact] = useState<ContactModel>();
    const [contact, setContact] = useState<ContactBucketItemModel | null>(null);
    const [requiredDataResponse, setRequiredDataResponse] = useState<RequiredDataReassign>();
    const [missingFields, setMissingFields] = useState<MissingFields>({
        error: [],
        warning: [],
    });

    const [openContactForm, setOpenContactForm] = useState<boolean>(false);

    // Validation set of the error and submit
    const handleValidationSubmit = (dataItem: any) => {
        const packageId = data.packageId;

        dataItem.contactId = selectedContact?.contactId.toString();

        if (hasValidationError(dataItem)) {
            setError('reassign-error-message-validation');
        } else {
            setError(undefined);
            dataItem.contactId = selectedContact?.contactId;

            submitForm({
                ...dataItem,
                selectedRecipientEmail: selectedContact?.emailAddress,
                isCloudContact: selectedContact?.origin !== 0,
                packageId,
            });
        }
    };

    //Validation of the form and required fields to reassign
    const hasValidationError = (dataItem: any) => {

        return (
            !dataItem.reassignmentReason ||
            !dataItem.selectedRecipient ||
            !selectedContact ||
            missingFields.error.length > 0 ||
            missingFields.warning.length > 0
        );
    };

    //The function determines whether the provided model is of type ReassignActorModel
    const isReassignActorModel = useCallback(
        (model: ReassignActorModel | ContactBasicModel): model is ReassignActorModel => {
            return (model as ReassignActorModel).stakeholder !== undefined;
        },
        [],
    );

    //Transforms an ReassignActorModel or ContactItemModel into a ComboboxContactModel.
    const transformModel = useCallback(
        (original: ReassignActorModel | ContactBasicModel): ComboboxContactModel => {
            const baseModel = {
                id: isReassignActorModel(original) ? original.id : original.contactId.toString(),
                firstName: isReassignActorModel(original) ? original.stakeholder.firstName : original.firstName,
                lastName: isReassignActorModel(original) ? original.stakeholder.lastName : original.lastName,
                emailAddress: isReassignActorModel(original)
                    ? original.stakeholder.emailAddress
                    : original.emailAddress,
                displayedSelection: isReassignActorModel(original)
                    ? `${original.stakeholder.firstName} ${original.stakeholder.lastName}`
                    : `${original.firstName} ${original.lastName}`,
                isShared: isReassignActorModel(original)? null : original.isShared,
            };

            if (isReassignActorModel(original)) {
                return {
                    ...baseModel,
                    type: original.type,
                };
            }

            return baseModel;
        },
        [isReassignActorModel],
    );

    // Function to update the missing fields state
    const addFieldToState = (field: string, type: keyof MissingFields) => {
        setMissingFields((prevState) => {
            const updatedState = { ...prevState };

            updatedState[type] = [...prevState[type], field];

            return updatedState;
        });
    };

    // Function to clear the missing fields state
    const clearMissingFieldsState = () => {
        setMissingFields({
            error: [],
            warning: [],
        });
    };

    // Loop to check the fields from required data response
    const checkFields = useCallback(
        (fields: string[], stateType: 'error' | 'warning', contactToCheck: ContactModel) => {
            fields.forEach((field) => {
                const key = ObjectUtils.camelCaseValue(field) as ContactItemModelKeys;

                if (contactToCheck && !contactToCheck[key]) {
                    addFieldToState(field, stateType);
                }
            });
        },
        [],
    );

    //Validation of the recipient to reassign
    const validateRecipientToReassign = useCallback(
        (contactToCheck: ContactModel, requiredDataResponse: any) => {
            clearMissingFieldsState();

            if (requiredDataResponse) {
                //Check for required and optional fields
                const { requiredFields, optionalFields } = requiredDataResponse;

                checkFields(requiredFields, 'error', contactToCheck);
                checkFields(optionalFields, 'warning', contactToCheck);
            } else {
                clearMissingFieldsState();
            }
        },
        [checkFields],
    );

    const getContactInformation =  useCallback(async(id: string) =>{
        try {
            const response = await contactsApi.getContactFullInformation({ contactId: id });

            setSelectedContact(response);
            validateRecipientToReassign(response, requiredDataResponse);
        } catch (error) {
            console.error('Failed to get contact:', error);
        }
    }, [contactsApi, requiredDataResponse, validateRecipientToReassign]);


    //Get addressbook at any change of the typing in "Select new contact"
    const handleContactChange = useCallback(
        async (contact: ContactBucketItemModel| null) => {
            // Type guard to check if the contact has a `contactId`
            if (contact && 'contactId' in contact && contact.contactId) {
                getContactInformation(contact.contactId.toString());
            }
        },
        [getContactInformation],
    );


    //Get initial data for the comboboxes
    useAsyncEffect(
        () => async () => {
            try {
                const { packageId, status } = data;

                const res = await portalsApi.getActorsReassign({
                    packageId,
                    status: status,
                });

                setActors(res.map(transformModel));
            } catch (error) {
                console.error('Error fetching actors:', error);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data, portalsApi, transformModel],
    );

    // Unselect the contact to show the combobox again
    const handleUnselectContact = () => {
        setContact(null);
        setSelectedContact(undefined);
        clearMissingFieldsState();
    };

    //Check required data for the selected recipient that have to be reassigned
    const checkRequiredData = async (e: { value: string }) => {
        const actor = actors?.find((actor: ComboboxContactModel) => actor.id === e.value);

        if (actor && actor.type?.toUpperCase() === ActorType.Signer) {
            try {
                const res = await portalsApi.getRequiredDataReassign({ actionId: actor.id });

                setRequiredDataResponse(res);

                if (selectedContact) {
                    validateRecipientToReassign(selectedContact, res);
                }
            } catch (error) {
                console.error('Error fetching required data:', error);
                setRequiredDataResponse(undefined);
            }
        } else {
            setRequiredDataResponse(undefined);
            clearMissingFieldsState();
        }
    };

    const closeModalAndUpdateContact = () => {
        setOpenContactForm(false);

        if(selectedContact) {
            setTimeout(()=> {
                getContactInformation(selectedContact.contactId.toString());
            }, 1000);
        }
    };

    return (
        <div className={'c-reassign-content-quick-action'}>
            {error && <Error className={'c-reassign-content-quick-action__error-text'} i18nKey={error} />}

            <Form
                ref={formRef}
                onSubmit={handleValidationSubmit}
                initialValues={{
                    selectedRecipient: '',
                    contactId: null,
                    reassignmentReason: '',
                }}
                render={() => (
                    <FormElement>
                        <div className={'c-reassign-content-quick-action__form'}>
                            {actors
                                ? (
                                    <>
                                        <Field
                                            name={'selectedRecipient'}
                                            placeholder={tFlow('select-recipient-reassign-placeholder')}
                                            label={tFlow('select-recipient-reassign-label')}
                                            component={ReassignCombobox}
                                            onChange={(e: any) => checkRequiredData(e)}
                                            groupedCombobox={true}
                                            groupByField={'type'}
                                            data={actors}
                                            required={true}
                                            textField={'displayedSelection'}
                                        />

                                        {selectedContact
                                            ? (
                                                <SelectedContactReassign
                                                    contact={selectedContact}
                                                    missingFields={missingFields}
                                                    handleEditContact={() => setOpenContactForm(true)}
                                                    handleUnselectContact={handleUnselectContact}
                                                />
                                            )
                                            : (
                                                <SelectNewContactReassign
                                                    contact={contact}
                                                    tFlow={tFlow}
                                                    onContactChange={(contact) => handleContactChange(contact)}
                                                    onAddContactClick={() => setOpenContactForm(true)}
                                                />
                                            )}
                                    </>
                                )
                                : (
                                    <Loader size={SizesEnums.MEDIUM} />
                                )}

                            <div>
                                <div>
                                    <Typography
                                        token={TypographyToken.UiFormsLabelSm}
                                        text={tFlow('reason-message-label')}
                                    />
                                    <Typography
                                        type='span'
                                        token={TypographyToken.UiFormsLabelSm}
                                        text={'*'}
                                        className='c-reassign-content-quick-action__required-field'
                                    />
                                    <Field name={'reassignmentReason'} rows={5} component={TextArea} />
                                </div>
                            </div>
                        </div>
                    </FormElement>
                )}
            />
            <OverlayPortal type={'panel'} visible={openContactForm}>
                {() => (
                    <ContactForm
                        open={openContactForm}
                        onClose={() => closeModalAndUpdateContact()}
                        contact={selectedContact}
                    />
                )}
            </OverlayPortal>
        </div>
    );
}

export default ReassignContentQuickAction;
