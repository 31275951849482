import { NdsIconFont } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types/designsystem.enums';
import classNames from 'classnames';
import { HTMLAttributes, PropsWithChildren } from 'react';
import { PropsWithClassNames } from '~models';
import Typography, { TypographyToken } from '../typography';
import './warning.scss';

export interface WarningProps extends PropsWithClassNames<HTMLAttributes<HTMLDivElement>> {
    /** if simple string has to be passed it can be done as param instead children to make code a bit cleaner */
    text?: string;
    icon?: string | false;
    size?: keyof typeof TokenMap;
}

const TokenMap = {
    'sm': TypographyToken.MobileDescriptionSm,
    'md': TypographyToken.MobileDescriptionMd,
    'xl': TypographyToken.MobileDescriptionXl,
};

const MediumTokenMap = {
    'sm': TypographyToken.DesktopDescriptionSm,
    'md': TypographyToken.DesktopDescriptionMd,
    'xl': TypographyToken.DesktopDescriptionXl,
};

function Warning({
    text,
    icon = 'fa-regular-triangle-exclamation',
    size = 'md',
    className,
    children,
    ...props
}: PropsWithChildren<WarningProps>) {

    return (
        <div className={classNames('c-warning', className)} {...props}>
            {icon && <NdsIconFont fontName={icon} size={SizesEnums.MEDIUM} className={'c-warning__icon'} />}
            <Typography
                tagName={'div'}
                token={TokenMap[size]}
                mediumScreenToken={MediumTokenMap[size]}
            >
                {text}{children}
            </Typography>
        </div>
    );
}

export default Warning;
