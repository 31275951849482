import { NdsIconFont } from '@gonitro/rcl';
import { SizesEnums } from '@gonitro/rcl/lib/_types';
import { Switch } from '@progress/kendo-react-inputs';
import { isEqual } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import Typography, { TypographyToken } from '~components/typography';
import { useTranslation } from '~contexts/i18n';
import { DocumentGroupsListItemModel } from '~models/user-management.models';
import { initialDocumentGroupToggles } from '../document-group-toggle-definition';
import useDocumentGroupToggles from '../useDocumentGroupToggles';
import './toggle-group.scss';


export interface ToggleGroupProps {
    userGroupId: number | undefined;
    selectedDocumentGroups: DocumentGroupsListItemModel[];
    setSelectedDocumentGroups: Dispatch<SetStateAction<DocumentGroupsListItemModel[]>>;
    documentGroup: DocumentGroupsListItemModel;
}

const ToggleGroup = ({ selectedDocumentGroups, setSelectedDocumentGroups, documentGroup, userGroupId }: ToggleGroupProps) => {
    const { t } = useTranslation('user-management');
    const { documentGroupToggles, handleDocumentGroupToggleChange } =
        useDocumentGroupToggles(userGroupId ? documentGroup.permissions : initialDocumentGroupToggles);

    const [isExpanded, setExpanded] = useState(false);


    useEffect(() => {
        setSelectedDocumentGroups((prev) => {
            const updatedDocGroups = prev.map((group) =>
                group.documentGroupId === documentGroup.documentGroupId
                    ? {
                        ...group,
                        permissions: documentGroupToggles,
                    }
                    : group);

            const hasChanged = !isEqual(updatedDocGroups, prev);

            return hasChanged ? updatedDocGroups : prev;
        });
    }, [documentGroup.documentGroupId, documentGroupToggles, setSelectedDocumentGroups]);


    useEffect(() => {
        setExpanded(false);
    }, [selectedDocumentGroups.length]);

    return (
        <div className={'toggle-group'}>
            <div className={'toggle-group__title'} onClick={() => setExpanded(prev => !prev)}>
                {isExpanded
                    ? (
                        <NdsIconFont fontName='fa-solid-chevron-up' size={SizesEnums.SMALL} />
                    )
                    : (
                        <NdsIconFont fontName='fa-solid-chevron-down' size={SizesEnums.SMALL} />
                    )}
                <Typography
                    className='toggle-group__document-group-name'
                    token={TypographyToken.UiMenuMd}
                    text={documentGroup.name}
                />
            </div>

            {isExpanded &&
                <div className='toggle-group__toggle-section-container'>
                    <>
                        <Typography
                            token={TypographyToken.DesktopTagXs}
                            text={t('toggle-document-permissions-label')}
                        />
                        {documentGroupToggles.map((toggle) => (
                            <div key={toggle.permission} className='toggle-group__toggle-section'>
                                <Typography
                                    className={'toggle-group__toggle-label'}
                                    token={TypographyToken.UiButtonsSm}>{t(toggle.label)}
                                </Typography>
                                <Switch
                                    checked={toggle.hasPermission}
                                    disabled={toggle.disabled}
                                    onChange={() => handleDocumentGroupToggleChange(toggle)} />
                            </div>
                        ))}
                    </>
                </div>}

        </div>
    );
};

export default ToggleGroup;
