import NoResultsCreatedYet from '~components/no-results-creted-yet';
import { useTranslation } from '~contexts/i18n';

const NoDocumentsSharedYet = () => {
    const { t } = useTranslation('portals');

    return (
        <NoResultsCreatedYet
            description={t('no-documents-shared-description')}
            title={t('no-documents-shared-title')}
        />
    );

};

export default NoDocumentsSharedYet;