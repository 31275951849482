export enum FlowError {
    GenericError = 'GenericError',
    DocumentUploadFailure = 'DocumentUploadFailure',
    DocumentProcessingFailure = 'DocumentProcessingFailure',
    TemplateNameNotUnique = 'TemplateNameNotUnique',
    MissingSmsOtpNumber = 'MissingSmsOtpNumber',
    PackageNameCannotBeEmpty = 'PackageNameCannotBeEmpty',
    PackageNameBetweenLength = 'PackageNameBetweenLength',
    PackageRedirectUrlValidUrl = 'PackageRedirectUrlValidUrl',
    PackageCallbackUrlValidUrl = 'PackageCallbackUrlValidUrl',
    PackageNotificationUrlValidUrl = 'PackageNotificationUrlValidUrl',
    StakeholderMissingPhoneNumberForAuthentication = 'StakeholderMissingPhoneNumberForAuthentication',
    FieldMissingUniqueName = 'FieldMissingUniqueName',
    StakeholderMissingPhoneNumberForSMSSigning = 'StakeholderMissingPhoneNumberForSMSSigning',
}

export const FlowErrorTitleI18n = {
    [FlowError.PackageNameCannotBeEmpty]: 'package-title',
    [FlowError.PackageNameBetweenLength]: 'package-title',
    [FlowError.PackageRedirectUrlValidUrl]: 'redirect-url-title',
    [FlowError.PackageCallbackUrlValidUrl]: 'callback-url-title',
    [FlowError.PackageNotificationUrlValidUrl]: 'notification-callback-url-title',
    [FlowError.FieldMissingUniqueName]: 'field-missing-unique-name-title',
    [FlowError.StakeholderMissingPhoneNumberForSMSSigning]: 'signature-title',
};

export const FlowErrorTextI18n = {
    [FlowError.PackageNameCannotBeEmpty]: 'package-name-cannot-be-empty',
    [FlowError.PackageNameBetweenLength]: 'package-name-between-length',
    [FlowError.PackageRedirectUrlValidUrl]: 'enter-valid-url',
    [FlowError.PackageCallbackUrlValidUrl]: 'enter-valid-url',
    [FlowError.PackageNotificationUrlValidUrl]: 'enter-valid-url',
    [FlowError.StakeholderMissingPhoneNumberForAuthentication]: 'recipient-missing-number-for-authentication',
    [FlowError.FieldMissingUniqueName]: 'field-missing-unique-name',
    [FlowError.StakeholderMissingPhoneNumberForSMSSigning]: 'recipient-missing-number-for-sms-signing',
};
