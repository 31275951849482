import { useMemo } from 'react';
import { useUserInfo } from '~contexts/auth';
import { useIsLanguageAvailable } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import { Language } from '~models';

export const useActiveUserLanguage: () => Language = () => {
    const userInfo = useUserInfo();
    const isLanguageAvailable = useIsLanguageAvailable();
    const { localizationSettings: { languageOptions: { defaultUserLanguage } } } = useSettings();

    return useMemo(() => {
        if (userInfo && isLanguageAvailable(userInfo.language)) {
            return userInfo.language;
        }

        return defaultUserLanguage;
    }, [isLanguageAvailable, defaultUserLanguage, userInfo]);
};
