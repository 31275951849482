import { NdsGroup, NdsHeading, NdsSelect, NdsSelectChangeEvent, NdsSelectOption, TablePaginator } from '@gonitro/rcl';
import {
    BkgModesEnum,
    RelativePositionsEnum,
    SizesEnums,
    StatesEnum,
} from '@gonitro/rcl/lib/_types/designsystem.enums';
import classNames from 'classnames';
import { useTableContext, useTableExtraDetails } from 'contexts/table';
import { HTMLAttributes, ReactNode, useCallback, useRef } from 'react';
import Loader from '~components/loader';
import TableBuilder, { TableBuilderProps } from '~components/table-builder';
import Typography, { TypographyToken } from '~components/typography';
import { useTranslation } from '~contexts/i18n';
import { TableDataGeneric } from '~contexts/table/table.types';
import { PropsWithClassNames } from '~models';
import PortalTableFilters from './portal-table-filters';
import { PortalTableFiltersDefinition } from './portal-table-filters/portal-table-filters';
import './portal-table.container.scss';


export interface PortalTableContainerProps<
    TRowIdKey extends string,
    TData extends TableDataGeneric<TRowIdKey>,
> extends PropsWithClassNames<HTMLAttributes<HTMLDivElement>> {
    tabs?: ReactNode; // for contact/user management
    headerButton?: ReactNode;
    pageTitle: string;
    renderColumns?: TableBuilderProps<TRowIdKey, TData>['renderColumns'];
    renderNoItemsYet?: ReactNode;
    filtersDefinition?: PortalTableFiltersDefinition;
}

const SHOW_NUMBER_OF_ROWS_TRANSLATION_KEY = 'show-number-of-rows';

export function PortalTableContainer<TRowIdKey extends string, TData extends TableDataGeneric<TRowIdKey>>({
    className,
    tabs,
    pageTitle,
    headerButton,
    renderColumns,
    renderNoItemsYet,
    filtersDefinition,
    ...props
}: PortalTableContainerProps<TRowIdKey, TData>) {
    const {
        definition,
        setPage,
        page,
        totalPages,
        setItemsPerPage,
        itemsPerPage,
        itemsPerPageOptions,
        filterValues,
    } = useTableContext<TRowIdKey, TData>();
    const { selectedRowsOnPage, selectedRowsTotal, totalItems, itemsOnPage } = useTableExtraDetails();
    const { t } = useTranslation('base');
    const { pageData } = useTableContext<TRowIdKey, TData>();

    // on init select fires event which is unwanted here
    const firstNumRowsChangeIgnored = useRef(false);

    const handleNumRowsSelectedChange = useCallback((e: NdsSelectChangeEvent) => {
        if (!firstNumRowsChangeIgnored.current) {
            firstNumRowsChangeIgnored.current = true;
        }
        setItemsPerPage(parseInt(e.detail.value[0]));
    }, [setItemsPerPage]);

    const noItemsCreatedYet = !!renderNoItemsYet && totalItems === 0 && Object.entries(filterValues).length === 0; // TODO extend this
    const initializing = totalItems < 0; // initially totalItems is set to -1, after first fetch it is set to real value - so it's initializing state

    return (
        <div className={classNames('ct-portal-table', className)} {...props}>
            <div className={'ct-portal-table__row ct-portal-table__row--header'}>
                <div className={'ct-portal-table__col ct-portal-table__col--left'}>
                    <NdsHeading size={SizesEnums.XXLARGE} text={pageTitle} icon={''} />
                </div>
                <div className={'ct-portal-table__col ct-portal-table__col--right'}>{headerButton}</div>
            </div>
            <div className={classNames('ct-portal-table__row')}>{tabs}</div>
            {filtersDefinition && (
                <div
                    className={classNames(
                        'ct-portal-table__row ct-portal-table__row--filters',
                        { 'ct-portal-table__row--hidden': noItemsCreatedYet || initializing },
                    )}
                >
                    <PortalTableFilters filters={filtersDefinition} />
                </div>
            )}
            <div className={classNames('ct-portal-table__row ct-portal-table__row--main')}>
                {initializing
                    ? (
                        <Loader center size={SizesEnums.XLARGE} />
                    )
                    : (
                        noItemsCreatedYet
                            ? renderNoItemsYet
                            : (
                                <div className={'ct-portal-table__table-wrapper'}>
                                    <TableBuilder<TRowIdKey, TData>
                                        renderColumns={renderColumns}
                                    />
                                </div>
                            )
                    )}
            </div>

            <div
                className={classNames(
                    'ct-portal-table__row ct-portal-table__row--footer',
                    { 'ct-portal-table__row--hidden': noItemsCreatedYet || initializing },
                )}
            >
                <div className={'ct-portal-table__col ct-portal-table__col--left'}>
                    {/* don't render selector if there is no possibility to change number of visible items, so at least two options must be there */}
                    {itemsPerPageOptions.length > 1 && pageData.length > 0 && (
                        <NdsSelect
                            onNdsChange={handleNumRowsSelectedChange}
                            state={StatesEnum.FILLED}
                            size={SizesEnums.SMALL}
                            bgMode={BkgModesEnum.LIGHT}
                            value={itemsPerPage.toString()}
                            displayLabel={false}
                            contentAlign={RelativePositionsEnum.BOTTOM_LEFT}
                        >
                            <NdsGroup>
                                {itemsPerPageOptions.map((rowAmount) => {
                                    return (
                                        <NdsSelectOption
                                            key={rowAmount}
                                            label={t(SHOW_NUMBER_OF_ROWS_TRANSLATION_KEY, { rowAmount })}
                                            value={rowAmount.toString()}
                                        />
                                    );
                                })}
                            </NdsGroup>
                        </NdsSelect>
                    )}
                </div>
                <div className={'ct-portal-table__col'}>
                    {page && totalPages
                        ? (
                            <TablePaginator
                                positionBottomFixed={false}
                                totalPages={totalPages}
                                currentPage={page}
                                prefixLabel={t('table-pagination-page')}
                                ofLabel={t('table-pagination-of')}
                                onPageChange={setPage}
                            />
                        )
                        : null}
                </div>
                <div className={'ct-portal-table__col ct-portal-table__col--right'}>
                    <Typography token={TypographyToken.UiFormsLabelXs}>Experimental:&nbsp;&nbsp;&nbsp;</Typography>
                    {definition.selectable && selectedRowsTotal
                        ? (
                            definition.multiPageSelectable
                                ? (
                                    <Typography token={TypographyToken.UiFormsLabelSm}>
                                        Selected {selectedRowsTotal} of {totalItems} items
                                    </Typography>
                                )
                                : (
                                    <Typography token={TypographyToken.UiFormsLabelSm}>
                                        Selected {selectedRowsOnPage} of {itemsOnPage} items
                                    </Typography>
                                )
                        )
                        : (
                            <Typography token={TypographyToken.UiFormsLabelSm}>
                                Showing {itemsOnPage} of {totalItems} items
                            </Typography>
                        )}
                    {/*List/Grid view changer (future)*/}
                </div>
                {/* <div className={'ct-portal-table__col ct-portal-table__col--right'}>List/Grid view changer (future)</div>  TODO should be in the upper div */}
            </div>
        </div>
    );
}
