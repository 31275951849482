import { useCallback, useRef } from 'react';
import { Location, NavigateOptions } from 'react-router';
import { To, useLocation, useNavigate } from 'react-router-dom';
import { UrlUtil } from '~lib';
import buildQueryParams = UrlUtil.buildQueryParams;


export const useLocationState = <T extends Record<string, any> = Record<string, never>>() => {
    const location: Location<Partial<T>> = useLocation();

    return location.state ?? {};
};

export interface NavigateWithQueryParams {
    (to: To, options?: NavigateOptions & { queryParams?: UrlUtil.QueryParams }): void;
}

export const useNavigateWithQueryParams = () => {
    const navigate = useNavigate();

    return useCallback<NavigateWithQueryParams>((to, { queryParams, ...options } = {}) => {
        const path = `${to}?${buildQueryParams(queryParams || {})}`;

        navigate(path, options);
    }, [navigate]);
};

export const useQueryParams = <T extends Record<string, any>>(url = window.location.href) => {
    const ref = useRef<Partial<T>>(
        UrlUtil.getLocationInfo<Partial<T>>(url).getQueryParams(),
    );

    return ref.current;
};