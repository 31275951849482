import { Diacritics, DocumentGroupPermission, Permission } from '~constants';
import { BaseRoutes } from '~constants/routes';
import { Workspace } from './app.types';
import { DocumentGroup } from '../auth';

const removeDiacritics = (str: string) => {
    for (const { base, letters } of Diacritics) {
        str = str.replace(letters, base);
    }

    return str;
};

export function mapDocumentGroupsToWorkspaces(
    groups: DocumentGroup[],
    hasPermission: (permission: Permission) => boolean,
    hasGroupPermission: (group: DocumentGroup, permission: DocumentGroupPermission) => boolean,
) {
    const canAccessDocuments = hasPermission(Permission.AccessDocumentPortal);
    const canAccessTemplates = hasPermission(Permission.AccessTemplatesPortal);
    const mappedGroups: Workspace[] = groups.map((group) => {
        const name = removeDiacritics(group.name)
            .replace(/\s/g, '-')
            .replace(/[^\w-]/g, '')
            .replace((/-+/g), '-')
            .toLowerCase();
        const pathId = `${group.documentGroupId}-${name}`;

        const hasDocumentsPermission = canAccessDocuments && hasGroupPermission(group, DocumentGroupPermission.View);
        const hasTemplatesPermission = canAccessTemplates && hasGroupPermission(group, DocumentGroupPermission.ViewTemplate);

        return {
            workspaceId: group.documentGroupId,
            workspaceName: group.name,
            isDefault: group.isDefault,
            workspaceIdPath: pathId,
            documentsPath: `${BaseRoutes.DocumentPortal}/${pathId}`,
            templatesPath: `${BaseRoutes.TemplatePortal}/${pathId}`,
            hasDocumentsPermission,
            hasTemplatesPermission,
            linksQuantity: (hasDocumentsPermission ? 1 : 0) + (hasTemplatesPermission ? 1 : 0),
        };
    });

    const defaultGroup = mappedGroups.find((group) => group.isDefault);
    const otherGroups = mappedGroups
        .filter((group) => !group.isDefault && group.linksQuantity > 0)
        .sort((groupA, groupB) => (
            groupA.workspaceName.toLowerCase() < groupB.workspaceName.toLowerCase() ? -1 : 1
        ));

    return {
        workspaces: mappedGroups,
        myWorkspace: defaultGroup,
        sharedWorkspaces: otherGroups,
    };
}
