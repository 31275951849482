import { LanguageDataLabel } from '~models';

export enum ContactGroupType {
    Shared = 'shared',
    Personal = 'personal',
}

export enum ContactGroupDiscriminator {
    ContactGroup = 'ContactGroup',
}

export const ContactGroupTypeI18n: Record<ContactGroupType, LanguageDataLabel<'contacts'>> = {
    [ContactGroupType.Shared]: 'contact-group-type-shared',
    [ContactGroupType.Personal]: 'contact-group-type-personal',
};
