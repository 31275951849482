import { NdsInput } from '@gonitro/rcl';
import { NdsInputTypes, SizesEnums } from '@gonitro/rcl/lib/_types';
import { RefObject, useEffect } from 'react';
import Error from '~components/error';
import Typography, { TypographyToken } from '~components/typography';
import { useTranslation } from '~contexts/i18n';
import { useEnhancedForm } from '~hooks/enhanced-form';
import { ValidateUtils } from '~lib';
import { DateUtil } from '~lib/date.utils';
import { LanguageDataLabel } from '~models';
import './extendActionBulk.scss';

export interface ExtendActionBulkProps {
    submitForm: (data: ExtendFormValues) => void;
    formRef: RefObject<HTMLFormElement>;
    handleIsValidForm: (isValid: boolean) => void;
    error: LanguageDataLabel<'error'> | undefined;
}

export type ExtendFormValues = {
    date: string;
    hour: string;
    minutes: string;
};

function ExtendActionBulk({ submitForm, handleIsValidForm, formRef, error }: ExtendActionBulkProps) {
    const now = new Date();

    now.setHours(now.getHours() + 1);
    const { t: tBase } = useTranslation('base');
    const { t: tFlow } = useTranslation('flow');
    const {
        registerNdsInput,
        handleSubmit,
        formState: { isValid },
    } = useEnhancedForm<ExtendFormValues>({
        mode: 'all',
        defaultValues: {
            date: DateUtil.format(now, 'dd/MM/yyyy'),
            hour: now.getHours().toString().padStart(2, '0'),
            minutes: now.getMinutes().toString().padStart(2, '0'),
        },
    });

    useEffect(() => {
        handleIsValidForm(isValid);
    }, [isValid, handleIsValidForm]);

    return (
        <div className={'c-extend-action-bulk'}>
            <div className={'c-extend-action-bulk__extend-action-content'}>
                {error && <Error className={'c-extend-quick-action__error-text'} i18nKey={error} />}
                <Typography text={tFlow('extend-message')} token={TypographyToken.DesktopDescriptionSm} />
                <form
                    ref={formRef}
                    noValidate
                    onSubmit={handleSubmit(submitForm)}
                    className={'c-extend-action-bulk__extended-param'}
                    autoComplete='off'
                >
                    <div>
                        <NdsInput
                            {...registerNdsInput('date', {
                                required: true,
                                pattern: {
                                    value: ValidateUtils.dateRegex,
                                    message: 'invalid-date',
                                },
                            })}
                            label={tBase('date')}
                            leftIcon='fa-regular-calendar'
                            placeholder={tBase('date-placeholder')}
                            size={SizesEnums.SMALL}
                            inputType={NdsInputTypes.TEXT}
                            required
                        />
                    </div>
                    <div className={'c-extend-action-bulk__hours-minute-container'}>
                        <div className={'c-extend-action-bulk__hour-minut-input'}>
                            <NdsInput
                                {...registerNdsInput('hour', {
                                    required: true,
                                    pattern: {
                                        value: ValidateUtils.hourRegex,
                                        message: 'invalid-hour',
                                    },
                                })}
                                label={tBase('hour')}
                                leftIcon='fa-regular-calendar'
                                placeholder={tBase('hour-placeholder')}
                                size={SizesEnums.SMALL}
                                inputType={NdsInputTypes.TEXT}
                                required
                            />
                        </div>
                        <div className={'c-extend-action-bulk__hour-minut-input'}>
                            <NdsInput
                                {...registerNdsInput('minutes', {
                                    required: true,
                                    pattern: {
                                        value: ValidateUtils.minuteRegex,
                                        message: 'invalid-minutes',
                                    },
                                })}
                                label={tBase('minutes')}
                                leftIcon='fa-regular-calendar'
                                placeholder={tBase('minutes-placeholder')}
                                size={SizesEnums.SMALL}
                                inputType={NdsInputTypes.TEXT}
                                required
                            />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ExtendActionBulk;
