import { HttpError } from '~lib/http/http.error';
import { AbortedError } from '~models';
import { HttpMethod } from './http.enums';
import { HttpHeadersManager } from './http.headers';
import { HttpResponse } from './http.response';

const MethodsWithBody = [HttpMethod.Put, HttpMethod.Post, HttpMethod.Patch];

interface ErrorProcessor {
    /**
     * @param error Error to be processed
     * @returns stop processing flag
     */
    (error: HttpError): boolean | void;
}

interface ResponseProcessor {
    /**
     * @param response Response to be processed
     * @returns stop processing flag
     */
    (response: HttpResponse): boolean | void;
}

/**
 * represent a HTTP request with its method, url, headers, .... that can be executed.
 */
export class HttpRequest {
    public readonly method: HttpMethod;
    public readonly url: string;

    /**
     * Optionally set serverUrl
     * If url already contains http://domain.ext/ part it's ignored
     */
    public readonly baseUrl?: string;

    private includeCredentials: boolean = false;
    /**
     * Headers store
     */
    private headers: HttpHeadersManager = new HttpHeadersManager();
    /**
     * Query params to be added to url
     */
    private params: Record<string, any> = {};
    /**
     * Body to attach to request
     */
    private body?: string | FormData = undefined;

    /**
     * Response processors list as LIFO queue.
     * Allows to set callbacks to process response outside api call function
     * Main use case: global processing of unknown errors by whole api context
     */
    private responseProcessor: ResponseProcessor[] = [];

    /**
     * Error processors list as LIFO queue.
     * In case of request error handlers are processed until handler returns true.
     * Rare use case: possible and known specific errors could be processed here explicitly in specific call
     * Main use case: global processing of unknown errors by whole api context
     */
    private errorProcessor: ErrorProcessor[] = [];

    public constructor(
        method: HttpMethod, url: string, baseUrl?: string,
    ) {
        this.method = method;
        this.url = url;
        this.baseUrl = baseUrl;
    }

    /**
     * set the url params for this request. this overwrites any previously set params
     * @param params
     * @returns
     */
    public withParams(params: { [index: string]: any }): HttpRequest {
        this.params = params;

        return this;
    }

    /**
     * set the request headers for this request. this overwrites any previously set headers
     * @param name
     * @param value
     * @returns
     */
    public withHeader(name: string, value: string): HttpRequest {
        this.headers.setHeader(name, value);

        return this;
    }

    /**
     * resets/removes the request headers for this request. Required for multipart/form-data contentType as fetch has bug in it
     * @param name
     * @returns
     */
    public resetHeader(name: string): HttpRequest {
        this.headers.resetHeader(name);

        return this;
    }

    /**
     * Use with caution, this will override all headers for this request, including common headers
     * @param headers
     * @returns
     */
    public withHeaders(headers: { [index: string]: string }): HttpRequest {
        this.headers = new HttpHeadersManager(headers);

        return this;
    }

    /**
     * set the "include cookies" flag for this request.
     * @returns
     */
    public withCredentials(): HttpRequest {
        this.includeCredentials = true;

        return this;
    }

    /**
     * set the "include cookies" flag for this request.
     * @returns
     */
    public addResponseProcessor(callback: ResponseProcessor): HttpRequest {
        this.responseProcessor.push(callback);

        return this;
    }

    /**
     * Adds callback to process request error (if happened).
     * @returns
     */
    public addErrorProcessor(callback: ErrorProcessor): HttpRequest {
        this.errorProcessor.push(callback);

        return this;
    }

    /**
     * set a FormData object as the request's body. this overwrites any previously set body (json or form data)
     * @param formData
     * @param ignoreWarning only few http methods can have a body. Trying to add body to method which is no on the MethodsWithBody list shows warning as it's usually unintended. This flag allows to disable this error in specific cases
     * @see MethodsWithBody
     * @returns
     */
    public withFormDataBody(formData: FormData, ignoreWarning: boolean = false): HttpRequest {
        if (!ignoreWarning && !MethodsWithBody.includes(this.method)) {
            console.warn(`Trying to add body to '${this.method}' call to url '${this.url}'. Didn't you mean to use one of the methods: ${MethodsWithBody.join(', ')}?`);
        }
        this.body = formData;

        return this;
    }

    /**
     * set a json object as the request's body. this overwrites any previously set body (json or form data)
     * @param body
     * @param ignoreWarning only few http methods can have a body. Trying to add body to method which is no on the MethodsWithBody list shows warning as it's usually unintended. This flag allows to disable this error in specific cases
     * @returns
     */
    public withJsonBody<TBody>(body: TBody, ignoreWarning: boolean = false): HttpRequest {
        if (!ignoreWarning && !MethodsWithBody.includes(this.method)) {
            console.warn(`Trying to add body to '${this.method}' call to url '${this.url}'. Didn't you mean to use one of the methods: ${MethodsWithBody.join(', ')}?`);
        }
        this.body = JSON.stringify(body);
        this.headers.setHeader(HttpHeadersManager.ContentType, 'application/json');

        return this;
    }

    /**
     * execute the request with its current configuration
     * @returns
     */
    public async execute(signal?: AbortSignal): Promise<HttpResponse> {
        const url = this.buildUrl();
        const options = this.buildOptions();

        if (signal) {
            options.signal = signal;
        }

        let error: HttpError;
        let response: HttpResponse;
        try { // do typical fetch
            const resp = await fetch(url, options);

            response = new HttpResponse(resp, this);

            // return on success
            if (response.ok) {
                for (const func of this.responseProcessor.slice().reverse()) {
                    if (func(response)) {
                        break;
                    }
                }

                return response;
            }

            // create error on backend error (so fetch function call succeeded returning any response)
            const body = await response.text(); // it will be tried to convert to json in ApiError

            error = new HttpError(response, body);
        } catch (err) { // handle fetch call errors like aborted
            if (signal?.aborted) {
                throw new AbortedError(signal);
            }
            throw err;
        }

        // success returned already, fetch error handled, backend error can be extra to be processed
        for (const func of this.errorProcessor.slice().reverse()) {
            if (func(error)) {
                break;
            }
        }
        throw error;
    }

    /**
     * It's fairly enough implementation for our use cases. It doesn't handle hash params nor hash sign in url at all as it shouldn't happen here
     * @private
     */
    private buildUrl(): string {
        const params = new URLSearchParams();

        for (const [key, value] of Object.entries(this.params)) {
            if (Array.isArray(value)) {
                value.forEach(value => params.append(`${key}[]`, value.toString()));
            } else if (value !== undefined && value !== null) {
                params.append(key, value.toString());
            }
        }
        const queryParamsString = new URLSearchParams(params).toString();

        // build final url with optional baseUrl so it results in absolute or relative url depending on provided data
        let url: string;

        if (this.url.startsWith('http') || !this.baseUrl) {
            url = this.url;
        } else {
            url = this.baseUrl.endsWith('/') ? this.baseUrl : this.baseUrl + '/';
            url += this.url.startsWith('/') ? this.url.substring(1) : this.url;
        }

        // add optional queryParams
        if (!queryParamsString) { // no queryParams provided (except ones directly in url)
            return url;
        } else if (this.url.includes('?')) { // url already contains queryParams, add more with &
            // Url already includes some query params
            return url + '&' + queryParamsString;
        } else { // url has no queryParams, add them after ?
            // Url has no query params yet
            return url + '?' + queryParamsString;
        }
    }

    private buildOptions(): RequestInit {
        const options: RequestInit = {
            method: this.method,
            body: this.body,
            headers: this.headers.getHeaders(),
        };

        if (this.includeCredentials) {
            options.credentials = 'include';
        }

        return options;
    }
}
