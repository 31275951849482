import { useCallback, useMemo, useState } from 'react';
import { NavTabModel } from '~models';

type FilterConditions = {
    [key: string]: boolean;
};

const useNavTabs = (tabs: NavTabModel[], filterConditions: FilterConditions) => {

    const filteredTabs = useMemo(() => {
        return tabs.filter((tab) => !(filterConditions[tab.key] === false));
    }, [tabs, filterConditions]);

    const [currentTab, setCurrentTab] = useState(filteredTabs[0]);

    const selectTabHandler = useCallback((type: string) => {
        const currentTab = filteredTabs.find(tab => tab.key === type);

        currentTab && setCurrentTab(currentTab);
    }, [filteredTabs]);

    return {
        currentTab,
        filteredTabs,
        selectTabHandler,
    };
};


export default useNavTabs;
