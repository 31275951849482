import { useCallback } from 'react';
import { SERVER_URL } from '~constants';

export function useRedirectFunc() {

    return useCallback((url: string, sameWindow = false) => {
        const win = sameWindow ? window : window.open('about:blank', '_blank');

        if (win) {
            const base = url.startsWith('http') ? '' : SERVER_URL.endsWith('?') ? SERVER_URL : SERVER_URL + '/';

            setTimeout(() => win.location.href = base + url);
        } else {
            if (sameWindow) {
                console.error('Unknown error occurred');

                return;
            }
            console.error('Failed to open new window.');
        }
    }, []);
}