import { Modal, ModalContent, ModalFooter, NdsButton } from '@gonitro/rcl';
import { BkgModesEnum, NdsButtonTypesEnum, SizesEnums, StatesEnum } from '@gonitro/rcl/lib/_types';
import { useState } from 'react';
import { ContactsApi } from '~api/contacts.api';
import Error from '~components/error';
import Typography, { TypographyToken } from '~components/typography';
import { useApi } from '~contexts/api';
import { useTranslation } from '~contexts/i18n';
import { ModalPortal } from '~contexts/overlay';
import { useTableContext } from '~contexts/table';
import { LanguageDataLabel } from '~models';
import { ContactBasicModel } from '~models/contact.model';
import { ContactsHeaderButtonActionType } from '../contacts-view-header-button/contactsViewHeaderButton';
import './copyShareContactsModal.scss';

export interface CopyShareContactsModalProps {
    contactsToProcess?: ContactBasicModel[];
    onCloseModalClik: () => void;
    actionType?: ContactsHeaderButtonActionType;
}

function CopyShareContactsModal({
    actionType,
    contactsToProcess,
    onCloseModalClik,
}: CopyShareContactsModalProps) {
    const contactsApi = useApi(ContactsApi);
    const { t } = useTranslation('contacts');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<LanguageDataLabel<'error'>>();
    const { refresh } = useTableContext();

    const processContacts = async () => {
        if (contactsToProcess) {
            const ids = contactsToProcess.map((el) => el.contactId);

            setLoading(true);

            if (actionType === ContactsHeaderButtonActionType.Copy) {
                try {
                    await contactsApi.copyToPersonal({ ids });
                    refresh();
                    setLoading(false);
                    onCloseModalClik();
                } catch (e) {
                    setLoading(false);
                    setError('something-went-wrong');
                    throw (e);
                }
            } else {
                try {
                    await contactsApi.sharePersonalContact({ ids });
                    refresh();
                    setLoading(false);
                    onCloseModalClik();
                } catch (e) {
                    setLoading(false);
                    setError('something-went-wrong');
                    throw (e);
                }
            }
        }
    };

    return (
        <ModalPortal visible={!!contactsToProcess}>
            {contactsToProcess && (
                <Modal
                    headerLabel={t(actionType === ContactsHeaderButtonActionType.Copy ? 'copy-contacts-title' : 'share-contacts-title')}
                    bkgMode={BkgModesEnum.LIGHT}
                    size={SizesEnums.SMALL}
                    onCloseClick={onCloseModalClik}
                    withClose={true}
                    className={'c-copy-share-contacts-modal'}
                    renderContent={
                        <ModalContent>
                            {error && <Error className={'c-copy-share-contacts-modal__error-text'} i18nKey={error} />}
                            <div className={'c-copy-share-contacts-modal__content'}>
                                <Typography
                                    className={'c-copy-share-contacts-modal__warning-message'}
                                    tagName='div'
                                    token={TypographyToken.DesktopDescriptionLg}
                                    text={actionType === ContactsHeaderButtonActionType.Copy ? t('copy-contacts-warning', { count: contactsToProcess.length }) : t('share-contacts-warning', { count: contactsToProcess.length })}
                                />
                                <div className='c-copy-share-contacts-modal__item-list'>
                                    {contactsToProcess.map((contact) => (
                                        <div key={contact.contactId} className={'c-copy-share-contacts-modal__item'}>
                                            <div className='c-copy-share-contacts-modal__item-label'>
                                                <Typography
                                                    className={'c-copy-share-contacts-modal__item-typography'}
                                                    token={TypographyToken.UiFormsLabelSm}
                                                    text={`${contact.firstName ? contact.firstName : ''}  ${contact.lastName}`}
                                                />
                                            </div>
                                            <div className='c-copy-share-contacts-modal__item-label'>
                                                <Typography
                                                    className={'c-copy-share-contacts-modal__item-typography'}
                                                    token={TypographyToken.UiFormsLabelSm}
                                                    text={`${contact.emailAddress}`}
                                                />
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </ModalContent>
                    }
                    renderFooter={
                        <ModalFooter>
                            <div
                                className={
                                    'c-copy-share-contacts-modal__footer-buttons'
                                }
                            >
                                <NdsButton
                                    size={SizesEnums.SMALL}
                                    label={t('cancel-button')}
                                    buttonType={NdsButtonTypesEnum.NEUTRAL}
                                    onClick={onCloseModalClik}
                                />
                                <NdsButton
                                    state={loading ? StatesEnum.DISABLED : StatesEnum.DEFAULT}
                                    size={SizesEnums.SMALL}
                                    label={t('confirm-button')}
                                    onClick={() => processContacts()}
                                />
                            </div>
                        </ModalFooter>
                    }
                />
            )}
        </ModalPortal>
    );
}

export default CopyShareContactsModal;
