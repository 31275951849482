import { v4 as uuid } from 'uuid';
import { Guid } from '~models';

export namespace StringUtils {
    export function unCapitalizeFirstLetter(str: string): string {
        return str[0].toLowerCase() + str.slice(1);
    }

    export function capitalizeFirstLetter(str: string): string {
        return str[0].toUpperCase() + str.slice(1);
    }

    export function guid(): Guid {
        return uuid();
    }
}
